/**
 * @description Will turn
 * @param phrase {string}
 * @returns {string}
 */
const toTitleCase = (phrase: string): string => {
  // regex is underscore OR spaces
  const words = phrase.split(/_|\s/);
  return words
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
    .reduce((prev, curr) => `${prev} ${curr}`);
};

export default toTitleCase;
