import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { CloseIcon } from 'assets';
import { useFeedPage } from 'hooks';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import ModalLayerProps from './ModalLayer.props';
import { BoxStyled, IconButtonStyled, ModalStyled, TitleContainerStyled } from './ModalLayer.style';

const ModalLayer = ({
  header,
  removePadding,
  toggle,
  afterClose,
  children
}: ModalLayerProps): React.ReactElement => {
  const [open, setOpen] = useState(toggle);
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { showBar } = useFeedPage();
  const listInnerRef = useRef<any>();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const totalHeightScrolled = scrollTop + clientHeight;
      const fullyScrolledDown = totalHeightScrolled === scrollHeight;
      setAtBottom(fullyScrolledDown);
    }
  };

  useEffect(() => {
    // hiding bar menus on load
    showBar(false);

    // restoring bar menus on leave
    return () => showBar(true);
  }, [showBar]);

  const handleClose = (_: any, reason: string) => {
    reason !== 'backdropClick' && setOpen(false);
    afterClose && afterClose();
  };

  const close = () => {
    setOpen(false);
    afterClose && afterClose();
  };

  return (
    <ModalStyled
      data-testid="modal-layer"
      open={open}
      onClose={handleClose}
      onScroll={onScroll}
      ref={listInnerRef}
    >
      <Grid
        display="grid"
        columnGap="0.5rem"
        justifyContent={isMobile ? 'space-between' : `center`}
        gridTemplateColumns={isMobile ? '1fr' : '1fr auto 1fr'}
        alignItems="flex-start"
        paddingTop={{ xs: 'auto', md: '1rem' }}
      >
        <TitleContainerStyled gridColumn={1}>{header && isMobile && header}</TitleContainerStyled>

        <BoxStyled
          gridColumn={isMobile ? '1/4' : 2}
          gridRow={isMobile ? 2 : 1}
          removePadding={removePadding}
        >
          {React.cloneElement(children as ReactElement, {
            atBottom,
            setAtBottom
          })}
        </BoxStyled>
        <Box
          display="flex"
          gridColumn={isMobile ? 2 : 3}
          height={{ xs: '4rem', md: 'auto' }}
          alignItems="center"
        >
          <IconButtonStyled onClick={close}>
            <CloseIcon />
          </IconButtonStyled>
        </Box>
      </Grid>
    </ModalStyled>
  );
};

export default ModalLayer;
