import { Button, Stack, useTheme } from '@mui/material';
import 'quill-paste-smart';
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import TextEditorProps from './TextEditor.props';
import BoxQuillEditorStyle from './TextEditor.style';
const TextEditor = React.forwardRef<ReactQuill, TextEditorProps>(
  (
    {
      name = '',
      autoFocus,
      value,
      handleCancelReply,
      handleReply,
      onChange,
      placeholder,
      showActions = true
    }: TextEditorProps,
    ref
  ): React.ReactElement => {
    const formats = [
      'header',
      'align',
      'video',
      'image',
      'link',
      'bold',
      'italic',
      'underline',
      'size',
      'list',
      'strike',
      'blockquote',
      'code-block',
      'clean',
      'script'
    ];
    const theme = useTheme();
    const [canSubmitReply, setCanSubmitReply] = useState(false);
    const stateRef = useRef<boolean>();
    const stateValueRef = useRef<string>(value);
    const textEditorRef = useRef<ReactQuill>((ref as MutableRefObject<ReactQuill>)?.current);
    stateRef.current = canSubmitReply;
    stateValueRef.current = value;
    useEffect(() => {
      setCanSubmitReply(value.length > 0 && value !== '<p><br></p>');
    }, [value]);

    useEffect(() => {
      if (autoFocus) {
        textEditorRef.current?.focus();
      }
    }, [autoFocus, ref]);

    const toolbarOptions = useMemo(
      () => [
        [
          { header: [1, 2, 3, 4, 5, 6, false] },
          'bold',
          'italic',
          'underline',
          'strike',
          { list: 'ordered' },
          { list: 'bullet' },
          { align: [] },
          'link',
          'blockquote',
          'code-block',
          { script: 'sub' },
          { script: 'super' },
          'clean'
        ]
      ],
      []
    );

    const modules = useMemo(
      () => ({
        toolbar: toolbarOptions
      }),
      [toolbarOptions]
    );

    return (
      <BoxQuillEditorStyle className="text-editor">
        <ReactQuill
          data-testid="quill-component"
          defaultValue={value}
          formats={formats}
          id={name ?? 'quill-component'}
          modules={modules}
          onChange={onChange}
          placeholder={placeholder}
          ref={textEditorRef}
        />
        {showActions && (
          <Stack
            direction="row"
            alignItems="center"
            padding={'1rem'}
            justifyContent={'flex-end'}
            gap={2}
            border={{ border: `1px solid ${theme.palette.grey[500]}` }}
            borderTop={0}
          >
            <Button variant="outlined" type="button" onClick={handleCancelReply}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={!canSubmitReply}
              onClick={() => handleReply && handleReply(stateValueRef.current || '')}
            >
              Reply
            </Button>
          </Stack>
        )}
      </BoxQuillEditorStyle>
    );
  }
);

export default TextEditor;
