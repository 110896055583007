import { Box, BoxProps, styled } from '@mui/material';

const FeedCardContentStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'truncateBody'
})<BoxProps & { truncateBody?: boolean }>(({ theme, truncateBody }) => ({
  width: 'auto',
  overflow: 'hidden',
  wordBreak: 'break-word',
  ...theme.typography.body1,
  '& p': {
    ...theme.typography.body1,
    margin: '1rem 0',
    overflow: truncateBody && 'hidden',
    display: truncateBody && '-webkit-box',
    WebkitBoxOrient: truncateBody && 'vertical',
    WebkitLineClamp: truncateBody && 4,
    WhiteSpace: truncateBody && 'pre-wrap'
  },
  '& a': {
    color: theme.palette.success.main
  },
  '& img': {
    borderRadius: '0.69rem',
    margin: '0 auto',
    maxHeight: '28.6875rem',
    maxWidth: '51rem'
  },
  '& ul': {
    listStylePosition: 'inside'
  },
  '& ol': {
    listStylePosition: 'inside'
  },
  '& h3': {
    margin: '1rem 0'
  },
  '& h4': {
    margin: '1.33rem 0'
  },
  [theme.breakpoints.down('sm')]: {
    maxHeight: '12.5rem',
    '& img': {
      borderRadius: '0.5rem',
      margin: '0 auto',
      maxHeight: '10rem',
      maxWidth: '21.4375rem'
    }
  },
  '& blockquote': {
    borderLeft: `0.25rem solid ${theme.palette.divider} !important`,
    marginBottom: '0.3125rem !important',
    marginTop: '0.3125rem !important',
    paddingLeft: '1rem !important'
  },
  '& pre': {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
    borderRadius: '0.1875rem !important',
    overflow: 'visible !important',
    padding: '0.3125rem !important',
    whiteSpace: 'pre-wrap !important'
  }
}));

export default FeedCardContentStyled;
