import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';
import ApiKeyTableRow from './ApiKeyTableRow';
import DaoDeveloperDashboardApiKeyTableProps from './DaoDeveloperDashboardApiKeyTable.props';

const DaoDeveloperDashboardApiKeyTable = ({
  apiKeys,
  onScroll,
  style
}: DaoDeveloperDashboardApiKeyTableProps): React.ReactElement => {
  const { THEAD_DESC, THEAD_CREATOR, THEAD_STATUS, THEAD_DATE } = DEV_DASHBOARD_LABELS;
  return (
    <TableContainer
      sx={{
        maxHeight: '37.5rem',
        overflowY: 'auto',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          width: '.125rem',
          opacity: 0.4
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'gray'
        },
        '&:MuiTable-root': {
          minWidth: 'unset'
        }
      }}
      style={style}
      onScroll={onScroll}
    >
      <Table aria-label="developer api key table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">{THEAD_DESC}</TableCell>
            <TableCell align="left">{THEAD_CREATOR}</TableCell>
            <TableCell align="left">{THEAD_DATE}</TableCell>
            <TableCell align="left">{THEAD_STATUS}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys.map((apiKey: any) => (
            <ApiKeyTableRow key={apiKey.id} apiKey={apiKey} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DaoDeveloperDashboardApiKeyTable;
