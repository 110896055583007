import { PatronageActivityDTO } from '@piefi-platform/types-lib';
import { AWARD_TYPE } from 'constants/dao-admin-nav.labels';

export const determineAwardType = (activity: PatronageActivityDTO): AWARD_TYPE => {
  const { variablePoint, dynamicAllocation } = activity;

  const isPercentage =
    variablePoint?.operator === 'MULTIPLY' &&
    variablePoint?.operandValue <= 1 &&
    variablePoint?.operandValue >= 0;

  if (dynamicAllocation) {
    return AWARD_TYPE.CURVED;
  } else if (variablePoint && isPercentage) {
    return AWARD_TYPE.PERCENTAGE;
  } else {
    return AWARD_TYPE.FLAT;
  }
};
