import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HouseIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '21px';
  const height = props.height ? `${props.height}px` : '21px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 21 21" {...props}>
      <path
        d="M13.2463 20.0288C12.6863 20.0288 12.1263 20.0088 11.5763 19.9988C11.0363 19.9788 10.5063 19.9688 9.98632 19.9688H1.56632C1.26632 19.9888 0.716325 20.0188 0.366325 19.5888C0.0363249 19.1888 0.0263249 18.5488 0.0563249 17.6088C0.0563249 17.4488 0.0663249 17.3088 0.0563249 17.2188C-0.0436751 15.2988 0.00632489 14.1188 0.0763249 12.6288C0.0963249 12.0788 0.126325 11.4888 0.146325 10.7888V7.26878C0.146325 6.62878 1.29632 5.51878 1.31632 5.50878C1.67632 5.18878 2.11632 4.94878 2.53632 4.70878C2.86632 4.52878 3.17632 4.34878 3.40632 4.16878C4.30632 3.45878 5.22632 2.78878 6.29632 2.06878C7.19632 1.46878 8.19632 0.828782 9.36632 0.108782C9.59632 -0.0312177 9.89633 -0.0412177 10.1363 0.108782L13.6863 2.16878L16.7263 4.39878L19.6663 6.27878C19.8763 6.41878 20.0063 6.64878 20.0063 6.90878L20.0463 11.8888L19.9763 15.6588C19.9663 16.3588 19.9663 17.0588 19.9763 17.7588V18.8188C19.9763 19.2088 19.6863 19.5288 19.2963 19.5688L14.9463 19.9788C14.3863 20.0288 13.8163 20.0488 13.2563 20.0488L13.2463 20.0288ZM1.69632 18.4688H9.98632C10.5263 18.4688 11.0763 18.4888 11.6263 18.4988C12.6863 18.5288 13.7863 18.5688 14.8063 18.4688L18.4763 18.1188V17.7488C18.4763 17.0388 18.4763 16.3288 18.4763 15.6188L18.5463 11.8688L18.5163 7.30878L15.8863 5.61878L12.8763 3.40878L9.77632 1.61878C8.78632 2.22878 7.91633 2.78878 7.13633 3.30878C6.09633 4.00878 5.20633 4.64878 4.33633 5.33878C4.00633 5.59878 3.63632 5.80878 3.26632 6.00878C2.89633 6.21878 2.54632 6.40878 2.29632 6.62878C2.18633 6.72878 1.79632 7.19878 1.64632 7.41878V10.7988C1.62632 11.5288 1.59632 12.1288 1.57632 12.6788C1.51632 14.1288 1.45632 15.2788 1.55632 17.1288C1.55632 17.2388 1.55632 17.4288 1.55632 17.6388C1.55632 17.8288 1.53632 18.1888 1.55632 18.4488C1.60632 18.4488 1.65633 18.4488 1.70633 18.4488L1.69632 18.4688Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M9.38591 16.1588C9.26591 16.1588 9.14591 16.1588 9.01591 16.1388C7.93591 15.9888 6.58591 15.0188 6.02591 14.3688C5.64591 13.9388 5.04591 13.0388 4.92591 12.3188C4.87591 12.0088 4.89591 11.7288 4.91591 11.4688L5.03591 9.82877C5.04591 9.67877 5.05591 9.49877 5.12591 9.29877C5.19591 9.07877 5.31591 8.89877 5.42591 8.75877C5.57591 8.37877 5.96591 8.04877 6.58591 7.61877C7.40591 7.04877 8.27591 6.66877 9.15591 6.49877C9.69591 6.39877 10.2259 6.36877 10.7559 6.41877C11.1559 6.45877 11.4759 6.52877 11.7559 6.64877C12.1059 6.79877 12.3859 7.01877 12.6159 7.19877C14.3459 8.52877 15.2259 10.2488 15.0459 11.9088C14.8759 13.4188 13.3359 14.8288 12.3959 15.3688C11.7559 15.7288 11.0459 15.8888 10.4759 16.0088C10.1659 16.0788 9.79591 16.1588 9.39591 16.1588H9.38591ZM6.81591 9.33877C6.78591 9.41877 6.74591 9.49877 6.68591 9.57877C6.63591 9.63877 6.55591 9.73877 6.54591 9.77877C6.54591 9.80877 6.52591 9.88877 6.52591 9.94877L6.40591 11.5888C6.39591 11.7688 6.37591 11.9388 6.40591 12.0688C6.45591 12.3488 6.82591 13.0088 7.15591 13.3888C7.59591 13.8888 8.63591 14.5688 9.22591 14.6588C9.49591 14.6988 9.82591 14.6288 10.1459 14.5588C10.6459 14.4488 11.2059 14.3288 11.6359 14.0788C12.4659 13.5988 13.4559 12.5088 13.5459 11.7588C13.7159 10.1488 12.3059 8.86877 11.6859 8.38877C11.5059 8.24877 11.3259 8.10877 11.1459 8.02877C11.0059 7.96877 10.8359 7.92877 10.6059 7.90877C10.2159 7.87877 9.82591 7.89877 9.42591 7.96877C8.75591 8.09877 8.08591 8.38877 7.48591 8.80877C7.05591 9.10877 6.88591 9.25877 6.81591 9.32877V9.33877Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
