import { timelineClasses } from '@mui/lab/Timeline';
import { timelineConnectorClasses } from '@mui/lab/TimelineConnector';
import { timelineContentClasses } from '@mui/lab/TimelineContent';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { CardContent, CardContentProps, styled } from '@mui/material';

export const CommentCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
  [`& .${timelineClasses.root}`]: {
    padding: theme.spacing(0, 0, 0, 0.875)
  },
  [`& .${timelineItemClasses.missingOppositeContent}:before`]: {
    content: 'none'
  },
  [`& .${timelineContentClasses.root}`]: {
    padding: theme.spacing(0, 0, 0, 1.5),
    '& .MuiBox-root': {
      '& p': { margin: theme.spacing(0) }
    }
  },
  [`& .${timelineConnectorClasses.root}`]: {
    width: '.0625rem',
    backgroundColor: theme.palette.grey[300]
  }
}));
