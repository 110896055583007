import * as Sentry from '@sentry/react';
import {
  AuthProvider,
  NotificationProvider,
  SettingsProvider,
  UserProvider,
  VerticalScrollProvider
} from 'contexts';
import { AppNavigationContextProvider } from 'contexts/AppNavigationContext';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/src/simplebar.css';
import App from './App';
import { APP_VERSION } from './constants/app-config';
import { ReferralContextProvider } from './contexts';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import GlobalStyles from './theme/GlobalStyles';
import ThemeConfig from './theme';
import ThemePrimaryColor from './theme/ThemePrimaryColor';

const sentryEnabled =
  process.env.REACT_APP_ENVIRONMENT !== 'local' && process.env.REACT_APP_ENVIRONMENT !== 'testing';

Sentry.init({
  dsn: 'https://5db61776820d458783c80ca60fbe77a3@o1295620.ingest.sentry.io/6521521',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  release: APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: sentryEnabled,
  tracesSampleRate: 0.5,
  autoSessionTracking: true,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 0.25 : 1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: process.env.REACT_APP_ENVIRONMENT === 'prod' ? 0.75 : 1
});

const container = document.getElementById('root') as Element;
const root = createRoot(container);

(async () => {
  if (!process.env.REACT_APP_LAUNCH_DARKLY_KEY) throw Error('Missing Launch Darkly Api Key');

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY
  });

  root.render(
    <ThemeConfig>
      <ThemePrimaryColor>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_CREDENTIAL!}
          useEnterprise={true}
          scriptProps={{ async: false, defer: false }}
        >
          <LDProvider>
            <BrowserRouter>
              <NotificationProvider>
                <ReferralContextProvider>
                  <AuthProvider>
                    <UserProvider>
                      <SettingsProvider>
                        <VerticalScrollProvider>
                          <AppNavigationContextProvider>
                            <GlobalStyles />
                            <App />
                          </AppNavigationContextProvider>
                        </VerticalScrollProvider>
                      </SettingsProvider>
                    </UserProvider>
                  </AuthProvider>
                </ReferralContextProvider>
              </NotificationProvider>
            </BrowserRouter>
          </LDProvider>
        </GoogleReCaptchaProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
