import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Badge(theme: Theme): object {
  return {
    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-standard': {
            '& .MuiIconButton-root': {
              backgroundColor: theme.palette.common.white,
              borderRadius: '50%',
              height: '1rem',
              width: '1rem',
              '& .MuiSvgIcon-root': {
                fontSize: '0.875rem'
              }
            }
          }
        },
        dot: {
          width: 10,
          height: 10,
          borderRadius: '50%'
        }
      }
    }
  };
}
