import { ValuationEventDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import ValuationService from './use-valuation-service.model';
import { valuationUrl } from './use-valuation-service.url';

const useValuationService = (): ValuationService => {
  const { get, post } = useHttp();

  const updateValuationRequest = useCallback(
    (companyId: string, daoId: string): Promise<AxiosResponse> => {
      return post(`${valuationUrl(companyId)}/requests`, { daoId });
    },
    [post]
  );

  const getMostRecentValuation = useCallback(
    (daoId: string, convertToBitPrice: boolean): Promise<AxiosResponse<ValuationEventDTO[]>> => {
      const url: string = `${valuationUrl(
        daoId
      )}?&page=0&size=1&orderBy=desc&convertToBitPrice=${convertToBitPrice}`;
      return get(url);
    },
    [get]
  );

  return {
    getMostRecentValuation,
    updateValuationRequest
  };
};

export default useValuationService;
