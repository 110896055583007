import { styled } from '@mui/material/styles';
import Page from 'components/page/Page';

const RootStyled = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: '28rem',
  minHeight: '100vh',
  padding: theme.spacing(12, 0)
}));

export { RootStyled, ContentStyled };
