import { Typography } from '@mui/material';
import {
  ChartBoxFilledIcon,
  ChartBoxIcon,
  ChartFlowFilledIcon,
  ChartFlowIcon,
  ConsoleFilledIcon,
  ConsoleIcon,
  FolderFilledIcon,
  FolderIcon,
  KeyFilledIcon,
  KeyIcon,
  SettingsFilledIcon,
  SettingsIcon,
  UsersFilledIcon,
  UsersIcon
} from 'assets/icons/hero-ui';
import { DAO_ADMIN_LABELS, DAO_ADMIN_PAGES } from 'constants/dao-admin-nav.labels';
import { ROUTES } from 'constants/routes';
import { useDao } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import { StyledNavigationSection } from '../../../base/layout/StyledNavigationSection';
import NavigationItem from '../navigation-item/NavigationItem';
import { NavigationItemProps } from '../navigation-item/NavigationItem.props';
import { AdminNavigationProps } from './AdminNavigation.props';

const AdminNavigation = ({ activePageName }: AdminNavigationProps): React.ReactElement => {
  const {
    adminControlPanelPage,
    adminInsightsPage,
    adminManagePage,
    adminMembersPage,
    daoProfileSettingsPage,
    developerDashboardPage,
    adminDocumentsPage
  } = useFlags();
  const { activeDaoMembership, currentDao, tabNotifications } = useDao();
  const { ACTIVITIES, INSIGHTS_DASHBOARD, MANAGE, MEMBERS, DEVELOPERS, SETTINGS, DOCUMENTS } =
    DAO_ADMIN_PAGES;
  const [pages, setPages] = useState<NavigationItemProps[]>([]);
  const isAtLeastAdmin = useRef(
    compareDaoRoles(UserDaoRole.ADMIN, activeDaoMembership?.role as UserDaoRole)
  );

  const adminPages = useCallback(async () => {
    if (!activeDaoMembership || !isAtLeastAdmin.current) return;

    const items = [];

    if (adminInsightsPage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <ChartBoxFilledIcon />,
        inActiveIcon: <ChartBoxIcon />,
        name: INSIGHTS_DASHBOARD,
        navId: `admin-${INSIGHTS_DASHBOARD}`,
        url: () => ROUTES.DAO_INSIGHTS_DASHBOARD(currentDao.id)
      } as NavigationItemProps);
    }

    if (adminDocumentsPage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <FolderFilledIcon />,
        inActiveIcon: <FolderIcon />,
        name: DOCUMENTS,
        navId: `admin-${DOCUMENTS}`,
        url: () => ROUTES.DAO_DOCUMENTS(currentDao.id)
      } as NavigationItemProps);
    }

    if (adminControlPanelPage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <ChartFlowFilledIcon />,
        inActiveIcon: <ChartFlowIcon />,
        name: ACTIVITIES,
        navId: `admin-${ACTIVITIES}`,
        url: () => ROUTES.DAO_ACTIVITIES(currentDao.id)
      } as NavigationItemProps);
    }

    if (developerDashboardPage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <ConsoleFilledIcon />,
        inActiveIcon: <ConsoleIcon />,
        name: DEVELOPERS,
        navId: `admin-${DEVELOPERS}`,
        url: () => ROUTES.DAO_DEVELOPER_DASHBOARD(currentDao.id)
      } as NavigationItemProps);
    }

    if (adminManagePage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <KeyFilledIcon />,
        inActiveIcon: <KeyIcon />,
        name: MANAGE,
        navId: `admin-${MANAGE}`,
        url: () => ROUTES.DAO_MANAGE(currentDao.id)
      } as NavigationItemProps);
    }

    if (adminMembersPage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <UsersFilledIcon />,
        inActiveIcon: <UsersIcon />,
        name: MEMBERS,
        navId: `admin-${MEMBERS}`,
        url: () => ROUTES.DAO_ADMIN_MEMBERS(currentDao.id)
      } as NavigationItemProps);
    }

    if (daoProfileSettingsPage) {
      items.push({
        daoId: currentDao.id,
        activeIcon: <SettingsFilledIcon />,
        inActiveIcon: <SettingsIcon />,
        name: SETTINGS,
        navId: `admin-${SETTINGS}`,
        url: () => ROUTES.DAO_PROFILE_SETTINGS(currentDao.id)
      } as NavigationItemProps);
    }
    setPages([...items]);
  }, [
    ACTIVITIES,
    INSIGHTS_DASHBOARD,
    MANAGE,
    MEMBERS,
    activeDaoMembership,
    adminDocumentsPage,
    adminControlPanelPage,
    adminInsightsPage,
    adminManagePage,
    adminMembersPage,
    daoProfileSettingsPage,
    developerDashboardPage,
    currentDao.id
  ]);

  const { ADMIN } = DAO_ADMIN_LABELS;

  useEffect(() => {
    if (!activeDaoMembership) return;

    isAtLeastAdmin.current = compareDaoRoles(
      UserDaoRole.ADMIN,
      activeDaoMembership?.role as UserDaoRole
    );

    adminPages();
  }, [activeDaoMembership, adminPages]);

  if (!isAtLeastAdmin.current) {
    return <></>;
  }

  return (
    <StyledNavigationSection data-testid="admin-navigation">
      <Typography
        color="text.secondary"
        data-testid="admin-navigation-title"
        fontWeight="bold"
        padding=".25rem .5rem"
        textAlign="right"
        variant="body2"
        sx={{ textAlign: { xs: 'left', md: 'left' } }}
      >
        {ADMIN}
      </Typography>
      {pages.map((item) => {
        return (
          <NavigationItem
            {...item}
            activePageName={activePageName}
            key={`admin-${item.name}`}
            notifications={tabNotifications[item.name]}
          />
        );
      })}
    </StyledNavigationSection>
  );
};

export default AdminNavigation;
