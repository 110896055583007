import { Stack, styled } from '@mui/material';

export const StyledMobileStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'showDaoNav'
})<{ showDaoNav: boolean }>(({ theme, showDaoNav }) => ({
  border: `.0625rem solid ${theme.palette.grey[300]}`,
  borderLeft: 'none',
  borderTop: 'none',
  display: showDaoNav ? 'unset' : 'none'
}));

export const StyledDesktopStack = styled(Stack)(({ theme }) => ({
  border: `.0625rem solid ${theme.palette.grey[300]}`,
  borderLeft: 'none',
  borderTop: 'none',
  height: '100%',
  width: '-webkit-fill-available',
  marginLeft: '0.5rem',
  maxWidth: '16rem',
  position: 'fixed',
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'unset'
}));
