import { BRAND } from 'theme/palette';
import hexToRgb from './hex-to-rgb';

const getContrastColor = (hexColor: string): string => {
  const rgb = hexToRgb(hexColor);
  if (!rgb) {
    return '';
  }

  const { r, g, b } = rgb;
  // https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5 ? BRAND.neutral[100] : BRAND.neutral[600];
};

export default getContrastColor;
