import { Stack, Typography } from '@mui/material';
import { DynamicNavIcon } from 'components';
import { FILTER_TABS } from 'constants/ui-labels';
import { useFeature } from 'flagged';
import { useDao } from 'hooks';
import React, { useMemo } from 'react';
import { PostFilterType } from 'types/enum';
import { FeedFilterTab } from 'types/feed-filter-tab';
import { BaseContentNavigationProps } from './BaseContentNavigation.props';
import { StackStyled } from './BaseContentNavigation.style';

export const filterNavItems = (
  activeFilter: PostFilterType,
  excludedFilter?: PostFilterType[]
): FeedFilterTab[] => {
  const IconStyle = { fontSize: '1.25rem' };

  return [
    {
      label: FILTER_TABS.SUMMARY,
      value: PostFilterType.Summary,
      icon: (
        <DynamicNavIcon
          active={activeFilter === PostFilterType.Summary}
          value="summary"
          style={IconStyle}
        />
      ),
      title: 'All'
    },
    {
      label: FILTER_TABS.POSTS,
      value: PostFilterType.Posts,
      icon: (
        <DynamicNavIcon
          active={activeFilter === PostFilterType.Posts}
          value="posts"
          style={IconStyle}
        />
      ),
      title: 'Posts'
    },
    {
      label: FILTER_TABS.PROPOSALS,
      value: PostFilterType.Proposals,
      icon: (
        <DynamicNavIcon
          active={activeFilter === PostFilterType.Proposals}
          value="polls"
          style={IconStyle}
        />
      ),
      title: 'Polls'
    },
    {
      label: FILTER_TABS.INFO,
      value: PostFilterType.Info,
      icon: (
        <DynamicNavIcon
          active={activeFilter === PostFilterType.Info}
          value="info"
          style={IconStyle}
        />
      ),
      title: 'Info'
    }
  ].filter(({ value }) => !excludedFilter?.includes(value));
};

const BaseContentNavigation = ({
  activeFilter,
  handleSelectFilter,
  type = 'feed'
}: BaseContentNavigationProps): React.ReactElement => {
  const isEnabled = useFeature('v2');
  const isBountyEnabled = useFeature('bounties');
  const mainNavigationItems = useMemo<FeedFilterTab[]>(
    () => filterNavItems(activeFilter),
    [activeFilter, isBountyEnabled]
  );
  const { activeDaoMembership } = useDao();

  if (!activeDaoMembership) return <></>;

  return (
    <Stack direction="row">
      {isEnabled && type === 'dao' && (
        <>
          {mainNavigationItems
            .filter((item) => [FILTER_TABS.CHAT].includes(item.label))
            .map(({ value, icon, title }) => (
              <StackStyled
                direction={'row'}
                key={value}
                value={value}
                activeFilter={activeFilter}
                spacing={1}
                onClick={(e) => handleSelectFilter(e, value)}
              >
                {icon}
                <Typography variant="body2">{title}</Typography>
              </StackStyled>
            ))}
          <span>/</span>
        </>
      )}
      <Stack direction="row">
        {mainNavigationItems
          .filter((item) => ![FILTER_TABS.INFO, FILTER_TABS.CHAT].includes(item.label))
          .map(({ value, icon, title }) => (
            <StackStyled
              direction={'row'}
              value={value}
              activeFilter={activeFilter}
              key={value}
              spacing={1}
              onClick={(e) => handleSelectFilter(e, value)}
            >
              {icon}
              <Typography variant="body2">{title}</Typography>
            </StackStyled>
          ))}
      </Stack>
      {type === 'dao' && (
        <>
          <span style={{ marginRight: '1.5rem' }}>/</span>
          {mainNavigationItems
            .filter((item) => [FILTER_TABS.INFO].includes(item.label))
            .map(({ value, icon, title }) => (
              <StackStyled
                direction={'row'}
                key={value}
                value={value}
                activeFilter={activeFilter}
                spacing={1}
                onClick={(e) => handleSelectFilter(e, value)}
              >
                {icon}
                <Typography variant="body2">{title}</Typography>
              </StackStyled>
            ))}
        </>
      )}
    </Stack>
  );
};

export default BaseContentNavigation;
