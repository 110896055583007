import { Box, BoxProps, styled } from '@mui/material';

export const AutoCompleteContainerStyled = styled(Box)(({ theme }) => ({
  display: 'none',
  minHeight: '4rem',
  position: 'relative',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  [theme.breakpoints.up('md')]: {
    display: 'block',
    minHeight: '2rem',
    '& .MuiAutocomplete-root': {
      maxWidth: '44.5rem',
      position: 'absolute',
      width: '44.5rem'
    }
  }
}));

export const SearchContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'none',
  maxWidth: '44.5rem',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));
