import { Stack, Typography, useTheme } from '@mui/material';
import ActivityCardFooter from 'components/footers/activity-card-footer/ActivityCardFooter';
import React from 'react';
import { stripHtml } from 'utils';
import BaseCardWrapper from '../base-card-wrapper/BaseCardWrapper';
import ActivityCardProps from './ActivityCard.props';
import { CardTypographyStyled } from './ActivityCard.style';

const ActivityCard = ({ activity, onClick }: ActivityCardProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <BaseCardWrapper
      includeHighlight={activity.isHighlighted}
      onClick={onClick}
      borderColor={activity.isHighlighted ? theme.palette.common.black : ''}
      borderWidth={activity.isHighlighted ? '2px' : ''}
      sx={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
    >
      <Stack spacing={3} justifyContent={'space-between'}>
        <Stack spacing={3}>
          <Typography variant="h6">{activity.name}</Typography>
          <CardTypographyStyled variant="body1">
            {stripHtml(activity.description || '')}
          </CardTypographyStyled>
        </Stack>
        <ActivityCardFooter activity={activity} />
      </Stack>
    </BaseCardWrapper>
  );
};

export default ActivityCard;
