import { Theme } from '@mui/material/styles';

//-----------------------------------------------

export default function FormControl(theme: Theme): object {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: '0.5rem',
          [theme.breakpoints.up('md')]: {
            gap: '0'
          },
          '& .MuiFormHelperText-root': {
            color: theme.palette.error.main
          }
        }
      }
    }
  };
}
