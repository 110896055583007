import { IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'assets';
import { MANUAL_DISTRIBUTION } from 'constants/dao-admin-nav.labels';
import { useDao, usePatronageActivityStepper } from 'hooks';
import { useCallback } from 'react';

const PatronageActivityStepperManualDistributionWrapper = (): React.ReactElement => {
  const { currentDao } = useDao();
  const { MANUAL_ACTIVITY_AWARD } = MANUAL_DISTRIBUTION;

  const { activityStepperActive, setActivityStepperActive, setActivityToEdit } =
    usePatronageActivityStepper();

  const hideForm = useCallback(() => {
    setActivityToEdit(undefined);
    setActivityStepperActive(!activityStepperActive);
  }, [activityStepperActive, setActivityStepperActive, setActivityToEdit]);

  if (!currentDao?.id) return <></>;

  return (
    <Stack direction="column">
      <Stack
        alignItems="center"
        direction="row"
        gap=".5rem"
        marginBottom="4rem"
        onClick={hideForm}
        style={{ cursor: 'pointer' }}
      >
        <IconButton style={{ marginLeft: '-.5rem' }} data-testid="back-btn">
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="h6">{MANUAL_ACTIVITY_AWARD}</Typography>
      </Stack>
    </Stack>
  );
};

export default PatronageActivityStepperManualDistributionWrapper;
