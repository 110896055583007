import { useEffect, useRef, useState } from 'react';

const useThrottle = <T>(value: T, interval = 1000): T => {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastExecuted = useRef(Date.now());

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);
    }

    return () => timerId && clearTimeout(timerId);
  }, [value, interval]);

  return throttledValue;
};

export default useThrottle;
