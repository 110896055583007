import { ManageableEntityDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useHttp } from 'hooks';
import { useCallback } from 'react';
import { FeedReportType, ReportCategory } from 'types/enum';
import ReportFeedService from './use-report-feed-service.model';
import { reportUrl } from './use-report-feed-service.url';

const useReportFeedService = (): ReportFeedService => {
  const { post } = useHttp();

  const reportFeed = useCallback(
    (
      daoId: string,
      roomId: string,
      entityId: string,
      feedType: FeedReportType,
      reportCategory: ReportCategory
    ): Promise<AxiosResponse<ManageableEntityDTO>> => {
      const entityType: string =
        Object.keys(FeedReportType)[Object.values(FeedReportType).indexOf(feedType)].toUpperCase();

      return post(reportUrl(daoId, roomId), {
        category: reportCategory,
        entityType: entityType,
        entityId: entityId
      });
    },
    [post]
  );

  return { reportFeed };
};

export default useReportFeedService;
