enum PostFilterType {
  Posts = 'plain-posts',
  Proposals = 'proposals',
  Summary = 'summary',
  Chat = 'chat',
  Info = 'info',
  Updates = 'updates'
}

export default PostFilterType;
