import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function AwsmLogoAndTextIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="84" height="19" viewBox="0 0 84 19" fill="none" {...props}>
      <path
        d="M38.3746 15.1005H34.1309L33.2406 17.8474H29.793L34.1118 5.16211H38.2983L42.7685 17.8474H39.2827L38.3734 15.1005H38.3746ZM37.7871 12.8493L36.7836 9.77853C36.556 9.01532 36.4166 8.22092 36.3665 7.39412H36.0448C36.0197 8.25932 35.8934 9.05372 35.6658 9.77853L34.7374 12.8493H37.7871Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M57.1652 8.30957L54.3992 17.8472H50.9134L49.9278 13.5368C49.8265 13.0796 49.7574 12.6116 49.7193 12.1352C49.6811 11.6588 49.6501 11.0264 49.6251 10.2368H49.3034C49.2783 11.0132 49.2461 11.6324 49.2092 12.0968C49.1711 12.5612 49.096 13.0412 48.9816 13.5368L47.9781 17.8472H44.4733L41.7073 8.30957H45.0787L45.8938 12.182C46.007 12.7676 46.0642 13.3268 46.0642 13.8608C46.0642 14.3948 46.0142 14.9804 45.9129 15.578L46.2537 15.6548C46.5695 14.2304 46.8472 13.0604 47.0867 12.1448L47.977 8.31077H50.9134L51.8036 12.164C52.0944 13.4744 52.3399 14.6372 52.5425 15.6548L52.8833 15.578C52.8201 15.0692 52.7892 14.5796 52.7892 14.1092C52.7892 13.6892 52.8392 13.1744 52.9405 12.5636L53.8307 8.30957H57.1652Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M61.2007 15.6811C61.4783 15.9031 61.8323 16.0147 62.2613 16.0147C62.6141 16.0147 62.9084 15.9415 63.142 15.7951C63.3756 15.6487 63.4924 15.4363 63.4924 15.1567C63.4924 14.9023 63.3851 14.7019 63.1706 14.5555C62.9561 14.4091 62.6462 14.2855 62.2422 14.1835L60.4427 13.7263C58.6241 13.2427 57.7149 12.3151 57.7149 10.9411C57.7149 10.3567 57.9067 9.84791 58.2928 9.41471C58.6778 8.9827 59.2021 8.6515 59.8647 8.4223C60.5273 8.1931 61.2698 8.0791 62.0909 8.0791C63.341 8.0791 64.3826 8.3647 65.2168 8.9371C66.0498 9.50951 66.536 10.3099 66.6755 11.3407H63.5114C63.4614 10.9339 63.2969 10.6159 63.0192 10.3867C62.7416 10.1575 62.4198 10.0435 62.0527 10.0435C61.6857 10.0435 61.3997 10.1167 61.1911 10.2631C60.9826 10.4095 60.8789 10.6099 60.8789 10.8643C60.8789 11.0803 60.973 11.2591 61.1625 11.3983C61.352 11.5387 61.6547 11.6587 62.0718 11.7607L63.8904 12.1999C64.7866 12.4291 65.4849 12.7627 65.9831 13.2019C66.4824 13.6411 66.7315 14.2351 66.7315 14.9851C66.7315 15.9391 66.3275 16.6927 65.5195 17.2459C64.7115 17.7991 63.6246 18.0751 62.2613 18.0751C60.898 18.0751 59.8492 17.7955 58.965 17.2363C58.0807 16.6771 57.5504 15.8371 57.374 14.7187H60.6894C60.7526 15.1387 60.923 15.4591 61.2007 15.6823V15.6811Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M82.559 8.92061C83.1334 9.47981 83.4206 10.3006 83.4206 11.3806V17.8474H80.1434V11.9914C80.1434 11.521 80.0206 11.1682 79.7739 10.933C79.5273 10.6978 79.2269 10.5802 78.8742 10.5802H78.7979C78.4058 10.5802 78.0841 10.711 77.8314 10.9714C77.5788 11.2318 77.4525 11.6098 77.4525 12.1066V17.8486H74.1752V12.031C74.1752 11.5354 74.0525 11.1694 73.8058 10.9342C73.5591 10.699 73.2468 10.5814 72.8679 10.5814H72.8107C72.4317 10.5814 72.1159 10.7122 71.8633 10.9726C71.6106 11.233 71.4843 11.6242 71.4843 12.1462V17.8498H68.207V8.31221H71.2567V8.36981C71.2567 8.64941 71.2126 8.94581 71.1244 9.25661C71.0362 9.56861 70.9039 9.94661 70.7263 10.3918C70.6882 10.5058 70.6441 10.633 70.5941 10.7734C70.544 10.9138 70.4928 11.0662 70.4427 11.2318L70.8026 11.3458L70.9349 10.8874C71.2757 9.83261 71.7119 9.10061 72.2422 8.69381C72.7725 8.28701 73.3982 8.08301 74.118 8.08301C74.8378 8.08301 75.4063 8.25221 75.8603 8.58821C76.3144 8.92541 76.542 9.40541 76.542 10.0282C76.542 10.3714 76.4597 10.8034 76.2953 11.3254L76.6361 11.4022C77.2165 9.18941 78.4666 8.08301 80.3865 8.08301C81.2576 8.08301 81.981 8.36261 82.5554 8.92181L82.559 8.92061Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M23.23 6.24482C22.6746 5.73122 21.8738 5.60402 21.1886 5.92202L15.8377 8.40123L11.5594 0.938403C11.2281 0.360001 10.6084 0 9.9446 0C9.28081 0 8.66111 0.360001 8.32981 0.938403L0.251081 15.0289C-0.15649 15.7405 -0.0611521 16.6261 0.488235 17.2333C0.85052 17.6341 1.35224 17.8477 1.86468 17.8477C2.12805 17.8477 2.395 17.7913 2.64645 17.6749L13.3684 12.708L11.936 10.2096L4.27436 13.7592L9.94579 3.86641L12.7487 8.75403L15.2716 13.1544L17.3869 16.8445C17.748 17.4745 18.4464 17.8393 19.1674 17.7757C19.8872 17.7109 20.5105 17.2273 20.756 16.5433L23.7234 8.26563C23.9796 7.55163 23.7865 6.75842 23.2312 6.24482H23.23ZM18.7932 13.5552L17.2702 10.8984L20.2387 9.52323L18.7932 13.5552Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
