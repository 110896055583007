import { ListItem, ListItemIcon } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileNavigationItemProps } from './ProfileNavigationItem.props';
import { ListItemTextStyled } from './ProfileNavigationItem.style';

const ProfileNavigationItem = ({
  icon,
  isActive,
  name,
  url
}: ProfileNavigationItemProps): React.ReactElement => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(url());
  }, [navigate, url]);

  return (
    <ListItem>
      <ListItemTextStyled data-testid={`profile-${name}`} onClick={handleClick} isActive={isActive}>
        {name}
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
      </ListItemTextStyled>
    </ListItem>
  );
};

export default ProfileNavigationItem;
