import { Box, Button, Stack, TextField, Theme, Typography } from '@mui/material';
import { DaoUpdatePayload } from '@piefi-platform/types-lib';
import { SizeContainer } from 'components/containers';
import { ErrorForm } from 'components/labels';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { EDIT_DAO_ABOUT } from 'constants/dao-admin-labels';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useDao, useNotification } from 'hooks';
import { useDaoService } from 'hooks/services';
import { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Severity } from 'types/enum';
import DaoAboutEditFormProps from './DaoAboutEditForm.props';

const DaoAboutEditForm = ({
  daoProfile,
  onCancel,
  onAfterSubmit
}: DaoAboutEditFormProps): React.ReactElement => {
  const { notify } = useNotification();
  const { currentDao } = useDao();
  const { updateDaoById } = useDaoService();
  const { CANCEL, SAVE } = BUTTON_LABELS;
  const { REQUIRED } = COMMON_VALIDATIONS;
  const { EDIT_DAO_ABOUT_TITLE, ERROR_UPDATING_DAO_ABOUT, DAO_ABOUT_UPDATED } = EDIT_DAO_ABOUT;

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<Pick<DaoUpdatePayload, 'rules'>>({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      rules: daoProfile.rules || ''
    }
  });

  const submit: SubmitHandler<Pick<DaoUpdatePayload, 'rules'>> = (data) => {
    updateDaoRules(data.rules || '');
  };

  const updateDaoRules = useCallback(
    async (rules?: string) => {
      if (currentDao.id && daoProfile && rules) {
        try {
          const { about, socialLinks, name } = daoProfile;
          const { linkedInLink, twitterLink, webLink } = socialLinks || {};
          const updatePayload: DaoUpdatePayload = {
            about,
            name,
            linkedInLink,
            twitterLink,
            webLink,
            rules
          };

          const response = await updateDaoById(currentDao.id, updatePayload);
          if (response.status === HTTP_STATUS.OK) {
            notify(DAO_ABOUT_UPDATED, { severity: Severity.Success });
            onAfterSubmit(rules);
          } else {
            notify(ERROR_UPDATING_DAO_ABOUT, { severity: Severity.Error });
          }
        } catch (error: any) {
          notify(error.response?.message, { severity: Severity.Error });
        }
      }
    },
    [
      currentDao,
      daoProfile,
      notify,
      onAfterSubmit,
      updateDaoById,
      DAO_ABOUT_UPDATED,
      ERROR_UPDATING_DAO_ABOUT
    ]
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack padding={(theme: Theme) => ({ xs: 2, md: theme.spacing(3, 6, 6, 6) })} gap={3}>
        <Stack direction="row" gap={1.5} alignItems="center">
          <Typography variant="h6" color="text.primary">
            {EDIT_DAO_ABOUT_TITLE}
          </Typography>
        </Stack>
        <SizeContainer width="100%">
          <Controller
            name="rules"
            control={control}
            rules={{ maxLength: 4096, required: REQUIRED }}
            render={({ field }) => (
              // render={({ field: { name, value, onChange, ref } }) => (
              // <TextEditor
              //   name={name}
              //   value={value || ''}
              //   showActions={false}
              //   placeholder={''}
              //   toolBarId="edit-dao-about-toolbar"
              //   onChange={onChange}
              //   ref={ref}
              // />
              <TextField
                {...field}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                placeholder=""
              />
            )}
          />
          {errors?.rules && <ErrorForm message={errors.rules.message} />}
        </SizeContainer>
        <Stack direction="row" alignItems="center" gap={3}>
          <Box width="50%">
            <Button
              onClick={onCancel}
              color="primary"
              fullWidth
              role="button"
              size="extraLarge"
              type="reset"
              variant="contained"
              data-testid="cancel"
            >
              {CANCEL}
            </Button>
          </Box>
          <Box width="50%">
            <Button
              color="primary"
              fullWidth
              role="button"
              size="extraLarge"
              type="submit"
              variant="contained"
              data-testid="submit"
            >
              {SAVE}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default DaoAboutEditForm;
