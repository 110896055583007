import { badgeClasses, Box, styled, svgIconClasses, Typography } from '@mui/material';

const DAOCardContainerStyled = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  display: 'flex',
  flexDirection: 'column',
  height: '10.8125rem',
  overflow: 'hidden',
  padding: '1rem',
  minWidth: '8.6875rem',
  gap: '0.5rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[200]
  },

  [`& .${badgeClasses.standard}`]: {
    backgroundColor: theme.palette.common.white,
    height: '.8331rem',
    minWidth: '.4165rem',
    padding: 0,
    transformOrigin: 'left top',
    width: '.8331rem',
    bottom: '0%',
    right: '50%',
    [`& .${svgIconClasses.root}`]: {
      fontSize: '.8331rem'
    }
  }
}));

const NameStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'maxTextLines'
})<{ maxTextLines: number }>(({ theme, maxTextLines }) => ({
  display: '-webkit-box !important',
  textAlign: 'center',
  transformOrigin: 'left top',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: maxTextLines,
  whiteSpace: 'normal',
  wordBreak: 'break-word'
}));

export { DAOCardContainerStyled, NameStyled };
