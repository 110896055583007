import { FeatureFlags } from 'flagged';

export const MIN_SCROLL_Y = 400;

export const APP_VERSION = '5.1.2';

export const CONNECT_URL = `https://connect${
  process.env.REACT_APP_ENVIRONMENT === 'prod' ? '' : `-${process.env.REACT_APP_ENVIRONMENT}`
}-api.awsm.com/`;

export const ENTITY_BLANK_ID = '-';

export const STREAM_CONFIG = {
  API_KEY: process.env.REACT_APP_STREAM_API_KEY || '',
  APP_ID: process.env.REACT_APP_STREAM_APP_ID || '',
  FEED_GROUP: process.env.REACT_APP_FEED_GROUP || `timeline_${process.env.REACT_APP_ENVIRONMENT}`,
  UNREAD_NOTIFICATION_TIMEOUT: 5400000 // messages will no longer show as unread after 90 minutes
};

export const PAGINATION = {
  DEFAULT_PAGE: 0,
  SMALL_PAGE: 5,
  PAGE_SIZE: 10,
  LARGE_PAGE_SIZE: 50,
  POST_COMMENTS: 15,
  PATRONAGE_ACTIVITIES: 20,
  LEADERBOARD: 30
};

export const SEARCH_PARAMS = {
  MIN_PHRASE_LENGTH: 3,
  SEARCH_DELAY_MS: 300
};

export const USER_ACTION_DELAY_MS = 750;

export const TEST_SCREEN_SIZES = {
  IPHONE_X: {
    WIDTH: 375
  },
  IPAD_MINI: {
    WIDTH: 768
  },
  DESKTOP_DEFAULT: {
    WIDTH: 1440
  }
};
export const MAX_LENGTH = {
  COMMENTS: 4096
};

export const MAX_OPTION_TEXT_LENGTH = 55;

export const PROPOSAL_RESTRICTION = {
  MIN_REQUIRED_OPTIONS: 2,
  MAX_OPTIONS: 10
};

export const MAX_FILE_SIZE_IN_MB = {
  ALLOCATION_AGREEMENT: 25 * 1024 * 1024
};

export const INPUT_VALIDATION = {
  MIN_NAME_LENGTH: 2,
  MIN_SHORT_TEXT_LENGTH: 5,
  MAX_SHORT_TEXT_LENGTH: 128,
  MAX_MEDIUM_TEXT_LENGTH: 256,
  MAX_LARGE_TEXT_LENGTH: 1024
};

export const REG_TEXT_EDITOR_VALIDATOR = new RegExp(/(?!^<p><br><\/p>$)(^.*$)/);

export const SOCIAL_LINK_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+\.)*[a-zA-Z0-9]+\.[a-zA-Z]{2,}\/?([a-zA-Z0-9]+\/?)*$/;

export const flaggedFeatures: FeatureFlags = {
  v2: false,
  bounties: false,
  search: false
};
