import { styled } from '@mui/material';

const DashedCircle = styled('div')(({ theme }) => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  border: `0.115rem dashed ${theme.palette.grey[600]}`,
  backgroundColor: theme.palette.grey[300],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 5.5s',
  '&:hover': {
    transform: 'rotate(360deg)'
  }
}));

export default DashedCircle;
