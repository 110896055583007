import { Stack } from '@mui/material';
import AvatarBuilder from 'components/avatar/AvatarBuilder';
import ActivityAwardLabel from 'components/labels/activity-award-label/ActivityAwardLabel';
import React from 'react';
import { AvatarSize } from 'types/enum';
import ActivityCardFooterProps from './ActivityCardFooter.props';
import { useDao } from 'hooks';

const ActivityCardFooter = ({ activity }: ActivityCardFooterProps): React.ReactElement => {
  const { currentDao } = useDao();
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      <AvatarBuilder
        src={currentDao.logoThumbnail}
        id={'collective-profile-avatar'}
        alt={currentDao.name}
        size={AvatarSize.SMALL}
      />
      <ActivityAwardLabel
        activity={activity}
        typographyProps={{ variant: 'body1', fontWeight: 'bold' }}
        caption
        captionProps={{ variant: 'caption' }}
      />
    </Stack>
  );
};

export default ActivityCardFooter;
