export enum DaoRequestState {
  Public = 'Join',
  Private = 'Request',
  Pending = 'Approval Pending',
  Approved = 'Leave DAO'
}

export enum DaoRequestStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}
