import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { SelectForm } from 'components/inputs';
import BaseModal from 'components/modals';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { DAO_UPDATE_PERMISSIONS } from 'constants/dao-admin-labels';
import { PERMISSIONS_LABELS } from 'constants/permissions-labels';
import { useDao, useNotification } from 'hooks';
import { useDaoService } from 'hooks/services';
import { UpdatePermissionsParam } from 'model';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Severity, UserDaoRole } from 'types/enum';
import { getCurrentDaoRoleTier, toTitleCase } from 'utils';
import UpdatePermissionsModalProps from './UpdateDaoPermissionsModal.props';

const UpdateDaoPermissionsModal = ({
  open,
  setModalState,
  onRoleUpdated,
  daoMembership
}: UpdatePermissionsModalProps): React.ReactElement => {
  const { activeDaoMembership, currentDao } = useDao();
  const { notify } = useNotification();
  const { PERMISSIONS_MODAL_TITLE, PERMISSIONS_MODAL_DESC } = DAO_UPDATE_PERMISSIONS;
  const { ROLE_CHANGED, UNSUCCESSFUL } = PERMISSIONS_LABELS;
  const { REQUIRED } = COMMON_VALIDATIONS;
  const { SAVE, CANCEL } = BUTTON_LABELS;
  const closeModal = (): void => {
    setModalState(false);
  };
  const { updateDaoMemberPermissions } = useDaoService();

  const roles = getCurrentDaoRoleTier[
    activeDaoMembership?.role as keyof typeof UserDaoRole
  ]?.filter((role: string) => role !== daoMembership.role && role !== UserDaoRole.SYSTEM);

  const { control, handleSubmit } = useForm<UpdatePermissionsParam>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      role: roles && roles[0]
    } as UpdatePermissionsParam
  });

  const onSubmit = async ({ role }: UpdatePermissionsParam) => {
    if (!currentDao?.id || !daoMembership.user.id || !role) return;

    const nameString = `${daoMembership.user.firstName} ${daoMembership.user.lastName}'s`;

    try {
      await updateDaoMemberPermissions(currentDao.id, role, daoMembership.id);
      notify(`${nameString} ${ROLE_CHANGED} ${role.toLowerCase()}`, {
        severity: Severity.Success
      });
      onRoleUpdated({ ...daoMembership, role });
      closeModal();
    } catch (error) {
      notify(`${nameString} ${UNSUCCESSFUL} ${role.toLowerCase()}`, {
        severity: Severity.Error
      });
      console.error(error);
    }
  };

  return (
    <BaseModal title={PERMISSIONS_MODAL_TITLE} toggle={open} close={closeModal}>
      <Typography variant="body1" marginBottom="1rem" marginTop="1rem">
        {PERMISSIONS_MODAL_DESC}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={8}>
          <Controller
            name="role"
            control={control}
            rules={{ required: REQUIRED }}
            render={({ field, fieldState }) => (
              <SelectForm
                id={field.name}
                data-testid="update-permissions-select"
                {...field}
                fullWidth
                invalid={!!fieldState.error}
              >
                {roles?.map((role: string) => {
                  return (
                    <MenuItem key={role} value={role} style={{ padding: '1rem' }}>
                      {toTitleCase(role)}
                    </MenuItem>
                  );
                })}
              </SelectForm>
            )}
          />
          <Stack direction="row" spacing={2}>
            <Button
              onClick={closeModal}
              role="button"
              type="button"
              color="primary"
              size="large"
              variant="outlined"
            >
              {CANCEL}
            </Button>
            <Button role="button" type="submit" color="primary" size="large" variant="contained">
              {SAVE}
            </Button>
          </Stack>
        </Stack>
      </form>
    </BaseModal>
  );
};

export default UpdateDaoPermissionsModal;
