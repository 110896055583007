import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Popover(theme: Theme): object {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {}
      }
    }
  };
}
