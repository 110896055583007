import { RoomMembershipDTO } from '@piefi-platform/types-lib';
import { useDao } from 'hooks';
import { useUserService } from 'hooks/services';
import { ReactNode, createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';

export interface UserRoomMembershipContextType {
  defaultRoom?: RoomMembershipDTO;
  activeRoom?: RoomMembershipDTO;
  userRoomMemberships: Array<RoomMembershipDTO>;
  loadUserRooms: (daoId: string) => void;
  setActiveRoom: (item?: RoomMembershipDTO) => void;
}

const initialState: UserRoomMembershipContextType = {
  userRoomMemberships: [],
  loadUserRooms: (daoId: string) => {},
  setActiveRoom: () => {}
};

const RoomMembershipContext = createContext(initialState);

interface RoomMembershipContextProviderProps {
  children: ReactNode;
}

const RoomMembershipContextProvider = ({ children }: RoomMembershipContextProviderProps): any => {
  const location = useLocation();
  const { roomId } = useParams();
  const { getUserRoomMemberships } = useUserService();
  const { activeDaoMembership } = useDao();
  const [userRoomMemberships, setUserRoomMemberships] = useState<RoomMembershipDTO[]>(
    initialState.userRoomMemberships
  );
  const setUserRoomMembershipsRef = useRef(setUserRoomMemberships);
  const [activeRoom, setActiveRoom] = useState<RoomMembershipDTO>();
  const [defaultRoom, setDefaultRoom] = useState<RoomMembershipDTO>();

  const updateCurrentRoom = useCallback((item?: RoomMembershipDTO) => {
    setActiveRoom(item);
  }, []);

  const loadUserRooms = useCallback(
    async (daoId: string): Promise<RoomMembershipDTO[] | undefined> => {
      if (!daoId) return;
      const roomMembershipResponse = await getUserRoomMemberships(daoId, {
        page: 0,
        size: 100
      });

      if (roomMembershipResponse?.data && roomMembershipResponse?.data.length > 0) {
        setUserRoomMembershipsRef.current(roomMembershipResponse.data);
        const room = roomMembershipResponse.data.find(
          (roomMembership: RoomMembershipDTO) =>
            roomMembership.daoId === daoId && roomMembership.room.isDefault
        );

        setDefaultRoom(room);

        return roomMembershipResponse?.data;
      }
    },
    [getUserRoomMemberships]
  );

  useEffect(() => {
    const loadDaoRooms = async () => {
      if (!activeDaoMembership) return;

      await loadUserRooms(activeDaoMembership.daoId);
    };
    loadDaoRooms();
  }, [activeDaoMembership, loadUserRooms]); // load rooms when dao changes

  useEffect(() => {
    if (location) {
      const activeMembership: RoomMembershipDTO | undefined = userRoomMemberships.find(
        (roomMembership: RoomMembershipDTO) => roomMembership.roomId === roomId
      );

      if (!activeMembership) return;

      setActiveRoom(activeMembership);
    }
  }, [location, userRoomMemberships, roomId]); // set activeRoom when location/route changes

  return (
    <RoomMembershipContext.Provider
      value={{
        activeRoom,
        defaultRoom,
        userRoomMemberships,
        setActiveRoom: updateCurrentRoom,
        loadUserRooms
      }}
    >
      {children}
    </RoomMembershipContext.Provider>
  );
};

export default RoomMembershipContext;
export { RoomMembershipContextProvider };
