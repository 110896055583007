import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Lists(theme: Theme): object {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.spacing(2)
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
          '& .MuiListItemText-primary': {
            color: theme.palette.common.white,
            fontWeight: 500
          },
          '& .MuiListItemText-secondary': {
            color: theme.palette.grey[700],
            fontWeight: 500
          }
        },
        multiline: {
          marginTop: 0,
          marginBottom: 0
        }
      }
    }
  };
}
