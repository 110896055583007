export const COLLECTIVE_LABELS = {
  MY_COLLECTIVES: 'My Collectives',
  NO_COLLECTIVES:
    'All ownership starts with a collective. Discover new opportunities, reach out to brands you love or even start your own!'
};

export const EARNINGS_HISTORY_LABELS = {
  NO_EVENT_HISTORY_ITEMS:
    'Every activity you complete is paid out in bits. Bits of ownership! See the history of the value you helped generate here.',
  RECENT_EARNINGS: 'Recent Earnings',
  VIEW_ALL: 'View All',
  EARNING_DETAILS: 'Earning Details',
  UP_TO_DATE: 'Up to Date'
};

export const TRANSACTION_LIST_LABELS = {
  ALLOCATION_AGREEMENTS: 'Allocation Agreements',
  EARNING_DETAILS: 'Earning Details'
};

export const AVAILABLE_ACTIVITIES_LABELS = {
  AVAILABLE_ACTIVITIES_TITLE: 'Available Activities'
};

export const OWNERSHIP_VALUE = {
  MY_VALUE: 'Current Ownership Value',
  MY_BITS: 'My Bits'
};
