import { Stack } from '@mui/material';
import { useEffect } from 'react';

const ReferralPage = (): React.ReactElement => {
  useEffect(() => {
    const queryString = window.location.search;

    // Removing the leading '?'
    const query = queryString[0] === '?' ? queryString.substr(1) : queryString;

    // Break into sections based on the delimiters
    const sections = query.split(/(?<=&|^)(affiliate=|act=|redirect=)/);

    // Remove empty strings from the array
    const filteredSections = sections.filter((section) => section);

    const map = new Map<string, string>();

    for (let i = 0; i < filteredSections.length; i += 2) {
      const key = filteredSections[i].replace('=', '');
      let value = filteredSections[i + 1];
      if (key === 'act' || key === 'affiliate') {
        if (value.endsWith('&')) {
          value = value.substring(0, value.length - 1);
        }
      }
      map.set(key, value);
    }

    window.location.href = map.get('redirect')!;
  }, []);

  return (
    <Stack justifyContent={'end'} alignItems={'center'} height={'100vh'}>
      <small>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </small>
    </Stack>
  );
};

export default ReferralPage;
