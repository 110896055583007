import { Grid, Stack, Typography } from '@mui/material';
import { DaoMembershipLeaderboardDTO } from '@piefi-platform/types-lib';
import { BaseCardWrapper, CaretMenu } from 'components';
import { PAGINATION } from 'constants/app-config';
import { DAO_ACTIONS_LABELS } from 'constants/dao-actions.labels';
import { useDao, useOnScreen } from 'hooks';
import { useDaoMembershipService } from 'hooks/services';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toTitleCase } from 'utils';
import { Container } from '../DaoActions.style';
import ActionsLeaderboardItem from './ActionsLeaderboardItem';

const ActionsLeaderboard = (): React.ReactElement => {
  const [isIntersecting, ref] = useOnScreen();
  const listInnerRef = useRef<any>();

  const { currentDao, activeDaoMembership, currentActiveSeasonForDao } = useDao();
  const tagOptions = useRef(
    [{ text: 'All', value: '' }].concat(
      activeDaoMembership?.tags?.map((i) => ({ text: toTitleCase(i.name), value: i.id })) || []
    )
  );
  const [lastList, setLastList] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<{ page: number; seasonId: string; tagId: string }>(
    { page: 0, seasonId: currentActiveSeasonForDao?.id || '', tagId: tagOptions.current[0].value }
  );

  const { getActionsLeaderboard } = useDaoMembershipService();
  const [currentMemberData, setCurrentMemberData] = useState<DaoMembershipLeaderboardDTO>();
  const [pagedData, setPagedData] = useState<DaoMembershipLeaderboardDTO[]>([]);

  const { LEADERBOARD } = DAO_ACTIONS_LABELS;

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setQueryParams({ ...queryParams, page: queryParams.page + 1 });
      }
    }
  };

  const getLeaderboardData = useCallback(
    async (tagId?: string) => {
      if (!currentDao || !currentActiveSeasonForDao) return;
      try {
        const { data } = await getActionsLeaderboard(
          currentDao.id,
          queryParams.page,
          PAGINATION.LEADERBOARD,
          queryParams.seasonId,
          tagId
        );

        setLastList(!data.pageData || data.pageData?.length < PAGINATION.LEADERBOARD);

        if (data.pageData) {
          setPagedData((prevActivities) => [...prevActivities, ...data.pageData]);
        }

        setCurrentMemberData(data.currentMemberData);
      } catch (error) {
        console.error(error);
      }
    },
    [getActionsLeaderboard, currentDao.id, queryParams]
  );

  useEffect(() => {
    if (currentDao.id && !lastList) getLeaderboardData(queryParams.tagId);
  }, [queryParams]);

  const handleTagFilter = (value: string) => {
    if (value === queryParams.tagId) return;
    setPagedData([]);
    setCurrentMemberData(undefined);
    setLastList(false);
    setQueryParams({ ...queryParams, page: 0, tagId: value });
  };

  const renderedList = useMemo(
    () =>
      pagedData.map((member: DaoMembershipLeaderboardDTO) => (
        <div
          ref={member.daoMembership.id === activeDaoMembership?.id ? ref : null}
          key={member.daoMembership.id}
        >
          <ActionsLeaderboardItem memberLeaderboardData={member} />
        </div>
      )),
    [pagedData]
  );

  return (
    <Grid item xs={12} height={'100%'}>
      <BaseCardWrapper>
        <Stack direction={'column'} spacing={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body2'}>{LEADERBOARD}</Typography>
            <CaretMenu
              options={tagOptions.current}
              onOptionSelected={(v: any) => handleTagFilter(queryParams.tagId || '')}
              defaultOption={tagOptions.current[0]}
            />
          </Stack>
          <Container onScroll={onScroll} ref={listInnerRef}>
            {renderedList}
          </Container>
        </Stack>
        {currentMemberData && !isIntersecting && (
          <div
            style={{
              position: 'absolute',
              bottom: '0',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              paddingBottom: '1rem',
              marginLeft: '-1rem',
              marginRight: '-1rem',
              background: 'white',
              width: '85%'
            }}
          >
            <ActionsLeaderboardItem memberLeaderboardData={currentMemberData} isolatedView />
          </div>
        )}
      </BaseCardWrapper>
    </Grid>
  );
};

export default ActionsLeaderboard;
