import { Box, styled } from '@mui/material';

export const CardListContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: '4rem',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  padding: '1rem',
  justifyContent: 'space-between',
  '&:hover, :focus-within': {
    backgroundColor: theme.palette.grey[300],
    marginLeft: '-0.5rem',
    marginRight: '0.5rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    width: 'calc(100% + 1rem)',
    '& [data-action="actions"]': {
      opacity: '1'
    }
  },
  [theme.breakpoints.up('md')]: {
    padding: 'initial'
  }
}));
