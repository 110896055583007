import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import TabContainerTemplateProps from './TabContainerTemplate.props';
import { HeaderStyled } from './TabContainerTemplate.style';

const TabContainerTemplate = ({
  children,
  title,
  rightAction,
  darkTitle,
  ...stackProps
}: TabContainerTemplateProps): ReactElement => {
  return (
    <Stack direction="column" justifyContent="center" {...stackProps}>
      <HeaderStyled direction="row" justifyContent="space-between" alignItems="center">
        {title && (
          <Typography
            data-testid="tab-container-title"
            variant={darkTitle ? 'body2' : 'subtitle2'}
            color={darkTitle ? 'text.primary' : 'text.secondary'}
          >
            {title}
          </Typography>
        )}
        {rightAction}
      </HeaderStyled>

      {children}
    </Stack>
  );
};

export default TabContainerTemplate;
