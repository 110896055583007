import { useCallback } from 'react';

import { Skeleton } from '@mui/material';
import IProposalResultProps from './ProposalResults.props';
import { useProposalService } from 'hooks/services';
import { ProposalOptionResult } from 'model';
import { useAsync } from 'hooks';
import LinearProgressWithLabel from 'components/v2/base/linear-progress-with-label/LinearProgressWithLabel';

const ProposalResults = ({
  daoId,
  roomId,
  feedItemId,
  proposalId,
  proposalOptions,
  selectedOptionLabel
}: IProposalResultProps): React.ReactElement => {
  const { getProposalResults } = useProposalService();

  /**
   * Get additional data for the current proposal
   */
  const getProposal = useCallback(async (): Promise<ProposalOptionResult[]> => {
    // TODO: check with BE if we can get the proposal with its results
    const results = await getProposalResults(daoId, roomId, feedItemId, proposalId);

    if (results?.data?.length > 0) {
      const proposalResults = results.data.map((item) => {
        const option = proposalOptions.find((x) => x.id === item.optionId);
        const proposalOptionResult: ProposalOptionResult = {
          optionId: item.optionId,
          optionCount: item.optionCount,
          percentage: item.percentage,
          optionText: option?.optionText,
          selected: (option && option?.votes?.length > 0) ?? false
        };
        return proposalOptionResult;
      });
      return proposalResults;
    }
    return [];
  }, [daoId, roomId, proposalId, feedItemId, proposalOptions, getProposalResults]);

  const { loading, value: proposalResults } = useAsync<ProposalOptionResult[]>(getProposal);

  return (
    <>
      {loading && (
        <>
          <Skeleton animation="wave" height={50} variant="rectangular" width="50rem" />
          <Skeleton animation="wave" height={40} variant="rectangular" width="30rem" />
          <Skeleton animation="wave" height={30} variant="rectangular" width="20rem" />
        </>
      )}
      {proposalResults?.map((option: ProposalOptionResult) => {
        const { percentage, optionText, selected, optionId } = option;
        return (
          <LinearProgressWithLabel
            key={optionId}
            value={percentage}
            variant="determinate"
            title={optionText}
            selected={selected}
            label={selectedOptionLabel}
          />
        );
      })}
    </>
  );
};

export default ProposalResults;
