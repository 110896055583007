import { Stack, Typography } from '@mui/material';
import { FeedMembershipDTO } from '@piefi-platform/types-lib';
import { HorizontalCard } from 'components';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useDao } from 'hooks';
import { useRoomService } from 'hooks/services';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { HorizontalCardVariant } from 'types/enum';

const RoomMemberList = (): ReactElement => {
  const { getRoomMemberships } = useRoomService();
  let { roomId } = useParams();
  const { currentDao } = useDao();
  const [members, setMembers] = useState<FeedMembershipDTO[]>([]);

  useEffect(() => {
    const getMembers = async () => {
      try {
        if (!currentDao.id || !roomId) return;
        const roomMembershipRes = await getRoomMemberships(currentDao.id, roomId);
        if (roomMembershipRes?.status === HTTP_STATUS.OK && roomMembershipRes?.data) {
          setMembers(roomMembershipRes.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getMembers();
  }, [currentDao, roomId, getRoomMemberships]);

  return (
    <>
      {members.map((membership: FeedMembershipDTO, index: number) => (
        <HorizontalCard
          key={`member-${index}`}
          title=""
          name={membership.user.firstName}
          body={
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="button" pr="auto">
                {membership.user.username}
              </Typography>
            </Stack>
          }
          img={membership.user.profileThumbnail}
          variant={HorizontalCardVariant.User}
        />
      ))}
    </>
  );
};

export default RoomMemberList;
