import { TableContainer, styled } from '@mui/material';

export const TableContainerWrapper = styled(TableContainer)(({ theme }) => ({
  maxHeight: '60rem',
  overflowY: 'auto',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '.125rem',
    opacity: 0.4
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey['500']
  }
}));
