import { Box, Grid, Stack, Typography } from '@mui/material';
import { AvatarBuilder } from 'components';
import { AvatarSize } from 'types/enum';
import { truncateId } from 'utils';
import ProfileCardConnectProps from './ProfileCardConnect.props';
import { CardListContainerStyled } from './ProfileCardConnect.style';

const ProfileCardConnect = ({
  cardTitle,
  accounts
}: ProfileCardConnectProps): React.ReactElement => {
  return (
    <Box paddingTop="3rem" data-testid="profile-connect-card">
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between" paddingBottom="0.5rem">
          <Typography variant="body2" fontWeight={800}>
            {cardTitle}
          </Typography>
        </Stack>
        <Grid container>
          <Stack direction="column" flexGrow={1}>
            {accounts?.map(({ dao, externalId, id }) => (
              <CardListContainerStyled key={id}>
                <Stack direction="row">
                  <Grid item paddingRight="1rem">
                    <AvatarBuilder
                      src={dao?.logoThumbnail}
                      alt={dao?.name}
                      id={'profile-card-connect-avatar'}
                      size={AvatarSize.MEDIUM}
                    />
                  </Grid>
                  <Grid item>
                    <Stack direction="column" justifyContent="center" height="100%">
                      <Typography variant="body1">{dao?.name}</Typography>
                    </Stack>
                  </Grid>
                </Stack>
                <Stack direction="row" alignItems="center">
                  {externalId && (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1" paddingLeft="0.25rem">
                        {truncateId(externalId)}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </CardListContainerStyled>
            ))}
          </Stack>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ProfileCardConnect;
