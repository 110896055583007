import { BaseTimePointResultsDTO } from '@piefi-platform/types-lib';
import DataType from 'types/enum/chart-data-types.enum';

export interface UserValueChartContextType {
  totalValue: number;

  areaDataForInterval: BaseTimePointResultsDTO[];

  onTimeSeriesDataTypeUpdated?: (event: any, typeSelected: string) => void;
  dataType?: string;
}

export const initUserValueChartContextType = (): UserValueChartContextType => ({
  totalValue: 0,
  areaDataForInterval: [],
  onTimeSeriesDataTypeUpdated: (event: any, typeSelected: string) => {},
  dataType: DataType.POINT_COUNT
});
