import { BaseQueryParams, ExternalReferenceDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useHttp } from 'hooks';
import { useCallback } from 'react';
import ConnectService from './use-connect-service.model';
import { connectURL } from './use-connect-service.url';

const useConnectService = (): ConnectService => {
  const { get } = useHttp();

  const getExternalReferences = useCallback(
    ({ page, size }: BaseQueryParams): Promise<AxiosResponse<ExternalReferenceDTO[]>> => {
      return get(`${connectURL()}/external-references?page=${page}&size=${size}`);
    },
    [get]
  );

  return { getExternalReferences };
};

export default useConnectService;
