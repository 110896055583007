import { Button, Skeleton } from '@mui/material';
import { captureException } from '@sentry/react';
import { DaoJoinRequestModal } from 'components';
import { useDao, useUser } from 'hooks';
import { useDaoJoinRequestService } from 'hooks/services';
import React, { useCallback, useRef, useState } from 'react';
import { DaoRequestStatus } from 'types/enum';

interface DAOJoinRequestButtonProps {
  ctaText: string;
}

const DAOJoinRequestButton = ({ ctaText }: DAOJoinRequestButtonProps): React.ReactElement => {
  const {
    currentDao,
    pendingJoinRequestForDao,
    updatePendingJoinRequestsForDao,
    activeDaoMembership,
    updateDaoMembership
  } = useDao();

  const { inviteProperties } = useUser();
  const isAcceptingInvite = useRef<boolean>(!!inviteProperties && !activeDaoMembership);
  const [showJoinRequestModal, setShowJoinRequestModal] = useState<boolean>(false);
  const { createDaoRequestToJoin } = useDaoJoinRequestService();
  const [loading, setLoading] = useState(false);

  const toggleRequestToJoinModal = useCallback(() => {
    setShowJoinRequestModal(!showJoinRequestModal);
  }, [showJoinRequestModal]);

  const submitRequest = useCallback(async () => {
    try {
      setLoading(true);
      let response = await createDaoRequestToJoin(currentDao.id);
      if (!currentDao.isPublic) toggleRequestToJoinModal();
      await updatePendingJoinRequestsForDao();

      if (response && response.data) {
        const joinRequestWasAccepted = response.data.currentStatus === DaoRequestStatus.Accepted;
        if (joinRequestWasAccepted) await updateDaoMembership();
      }
    } catch (error) {
      captureException(error);
    } finally {
      setLoading(false);
    }
  }, [
    createDaoRequestToJoin,
    currentDao.id,
    currentDao.isPublic,
    toggleRequestToJoinModal,
    updateDaoMembership,
    updatePendingJoinRequestsForDao
  ]);

  if (loading) {
    return (
      <Button
        style={{
          width: '5.625rem',
          padding: 0
        }}
      >
        <Skeleton animation="pulse" variant="rectangular" height={'100%'} width={'100%'} />
      </Button>
    );
  }

  return (
    <>
      {pendingJoinRequestForDao ? (
        <Button
          role="button"
          type="button"
          color="primary"
          size="large"
          variant="contained"
          style={{
            width: 'unset'
          }}
          disabled={true}
        >
          Pending
        </Button>
      ) : (
        <Button
          role="button"
          type="button"
          size="large"
          variant="contained"
          sx={{
            height: 'fitContent',
            border: 'unset',
            borderRadius: '0.5rem',
            backgroundColor: '#004EEB',
            '&:hover': {
              backgroundColor: '#3366FF !important'
            }
          }}
          onClick={async () => {
            await submitRequest();
          }}
          disabled={isAcceptingInvite.current}
        >
          {ctaText}
        </Button>
      )}
      {showJoinRequestModal && (
        <DaoJoinRequestModal
          open={showJoinRequestModal}
          onClose={toggleRequestToJoinModal}
          daoInfo={{ ...currentDao }}
        />
      )}
    </>
  );
};

export default DAOJoinRequestButton;
