import { Stack, Typography } from '@mui/material';
import { hexToRgb, numberFormatter } from 'utils';
import GraphLineItemProps from './GraphLineItem.props';
import { ColorSquare, LineItemContainer, StyledStack } from './GraphLineItem.style';

const GraphLineItem = ({
  title,
  primaryMetric,
  color,
  secondaryIcon,
  percentage,
  secondaryMetric,
  secondaryTitle
}: GraphLineItemProps): React.ReactElement => {
  const rgbColor = hexToRgb(color);

  return (
    <LineItemContainer data-testid={'graph-line-item'}>
      <StyledStack direction={'row'} colorobj={rgbColor} percentage={percentage}>
        <Stack direction={'row'} alignItems={'center'}>
          <ColorSquare color={color} />
          <Stack direction={'column'} alignItems={'center'}>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {title}
            </Typography>
            <Typography variant={'body2'} color={'secondary.main'} marginRight={'auto'}>
              {secondaryTitle}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          height={'2rem'}
          paddingRight={'1rem'}
        >
          <Typography variant={'body2'} fontWeight={'bold'} marginLeft={'auto'}>
            {numberFormatter.formatterWithZeroDecimals(primaryMetric)}
          </Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
            {secondaryIcon}
            <Typography
              variant={'body2'}
              color={'secondary.main'}
              paddingLeft={'0.25rem'}
              marginRight={'auto'}
            >
              {numberFormatter.formatterWithZeroDecimals(secondaryMetric)}
            </Typography>
          </Stack>
        </Stack>
      </StyledStack>
    </LineItemContainer>
  );
};

export default GraphLineItem;
