import { CreatePostForm, ModalLayer, ProposalForm } from 'components';
import { useDao, useFeed, useNotification } from 'hooks';
import { SimplifiedRoom } from 'model';
import { useRef } from 'react';
import { Severity, UserDaoRole } from 'types/enum';

const EditPostModal = (): React.ReactElement => {
  const { post, showEditMode, setShowEditForm } = useFeed();
  const postIsProposal = useRef(!!post?.proposal?.id);
  const { activeDaoMembership } = useDao();
  const userDaoRole = activeDaoMembership?.role as UserDaoRole;
  const { notify } = useNotification();

  if (!post) {
    notify('Post not found', { severity: Severity.Error });
    setShowEditForm(false);
    return <></>;
  }

  if (!postIsProposal.current) {
    return (
      <>
        {showEditMode && (
          <ModalLayer
            removePadding={true}
            toggle={showEditMode}
            afterClose={() => setShowEditForm(false)}
          >
            {userDaoRole && (
              <CreatePostForm
                editMode
                daoId={post.room.dao.id}
                room={{ id: post.room.id, isDefault: post.room.isDefault } as SimplifiedRoom}
                userDaoRole={userDaoRole}
              />
            )}
          </ModalLayer>
        )}
      </>
    );
  }

  return (
    <>
      {showEditMode && (
        <ModalLayer
          removePadding={true}
          toggle={showEditMode}
          afterClose={() => setShowEditForm(false)}
        >
          <ProposalForm
            editMode
            daoId={post.room.dao.id}
            room={{ id: post.room.id, isDefault: post.room.isDefault } as SimplifiedRoom}
          />
        </ModalLayer>
      )}
    </>
  );
};

export default EditPostModal;
