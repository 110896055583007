import { List, Paper, styled } from '@mui/material';

export const ProfileNavigationContainer = styled(Paper)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  flexDirection: 'column',
  right: '1rem',
  position: 'absolute',
  top: '1rem',
  height: '100vh',
  [theme.breakpoints.up('md')]: {
    left: '-7.25rem',
    right: 'auto',
    top: '5rem'
  }
}));

export const ProfileNavContainerStyled = styled(List)<{ component?: React.ElementType }>(
  ({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    fontWeight: 500,
    flexDirection: 'column',
    justifyContent: 'right',
    rowGap: '0.125rem',
    marginTop: '0!important',
    maxWidth: '15.875rem',
    paddingTop: '0',
    width: '15.875rem',
    '& .MuiListItemButton-root ': {
      flexDirection: 'row-reverse',
      padding: '0.25rem 0',
      '& .MuiListItemIcon-root': {
        marginRight: '0'
      }
    },
    '& .MuiListItem-root': {
      flexDirection: 'row-reverse',
      padding: '0 0.5rem',
      '& .MuiListItemIcon-root': {
        marginRight: '0'
      }
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '0.5rem',
      maxWidth: '11.375rem',
      width: '11.375rem',
      '& .MuiListItem-root': {
        flexDirection: 'row',
        justifyContent: 'right',
        '& .MuiTypography-root': {
          float: 'right',
          padding: '0.25rem 0.5rem'
        }
      }
    }
  })
);
