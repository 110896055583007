import { Button, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ApiKeyDTO, DaoDTO } from '@piefi-platform/types-lib';
import { ArrowRightIcon, BookWithCircle, CopyIcon, LightbulbWithCircle } from 'assets';
import BaseModal from 'components/modals';
import { CONNECT_URL, PAGINATION } from 'constants/app-config';
import { DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';
import { useDao, useNotification } from 'hooks';
import { useApiKeyService, useDaoService } from 'hooks/services';
import { useCallback, useEffect, useState } from 'react';
import { copyToClipboard } from 'utils';
import { SecondaryAddIcon } from '../dao-control-panel/DaoControlPanel.style';
import {
  DaoDevDashboardBoxWrapper,
  DaoDevDashboardBoxWrapperWithHover
} from './DaoDeveloperDashboard.style';
import ApiKeyCreateForm from './api-key-create-form/ApiKeyCreateForm';
import ApiKeyGettingStartedModal from './api-key-getting-started-modal/ApiKeyGettingStartedModal';
import DaoDeveloperDashboardApiKeyTable from './api-key-table/DaoDeveloperDashboardApiKeyTable';

const DaoDeveloperDashboard = (): React.ReactElement => {
  const [apiKeys, setApiKeys] = useState<ApiKeyDTO[]>([]);
  const { getApiKeysByDao } = useApiKeyService();
  const { getDaoInfo } = useDaoService();
  const { currentDao } = useDao();
  const [page, setPage] = useState<number>(0);
  const [onLastPage, setOnLastPage] = useState(false);
  const [creating, setCreating] = useState(false);
  const {
    COPIED,
    COMPANY_ID,
    CREATE_KEY,
    TAB_TITLE,
    DIVE_DEEP,
    GET_STARTED,
    MORE_INFO,
    STARTED_DESC,
    LOOKING_FOR_MORE,
    READ_DOCS
  } = DEV_DASHBOARD_LABELS;
  const [daoInfo, setDaoInfo] = useState<DaoDTO>();
  const { notify } = useNotification();
  const theme = useTheme();
  const [gettingStartedOpen, setGettingStartedOpen] = useState(false);

  const getApiKeys = useCallback(async () => {
    if (!currentDao?.id) return;

    try {
      const { data: responseKeys } = await getApiKeysByDao(currentDao.id, {
        page: page,
        size: PAGINATION.LARGE_PAGE_SIZE
      });

      if (responseKeys.length < PAGINATION.LARGE_PAGE_SIZE) setOnLastPage(true);

      setApiKeys((prevKeys) => [...prevKeys, ...responseKeys]);
    } catch (error) {
      console.error(error);
    }
  }, [currentDao, getApiKeysByDao, page]);

  /**
   * We need this to get more data about the dao that does not exist in `useDao` context
   */
  const configureDaoInfo = useCallback(async () => {
    const data = await getDaoInfo(currentDao.id);
    setDaoInfo({ ...data.data });
  }, [getDaoInfo, currentDao]);

  useEffect(() => {
    getApiKeys();
    configureDaoInfo();
  }, [getApiKeys, page]);

  const handleScroll = (e: any) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const atBottom = scrollHeight < clientHeight + scrollTop + 1;
    if (atBottom && !onLastPage) setPage(page + 1);
  };

  const toggleCreating = () => {
    setCreating(!creating);
  };

  const handleCreateClose = () => {
    setPage(0);
    setApiKeys([]);
    getApiKeys();
    setCreating(false);
  };

  const copyCompanyId = () => {
    copyToClipboard((daoInfo as any).companyId || '');
    notify(COPIED);
  };

  const toggleGettingStartedModal = () => {
    setGettingStartedOpen(!gettingStartedOpen);
  };

  return (
    <>
      {daoInfo && (
        <ApiKeyGettingStartedModal
          isOpen={gettingStartedOpen}
          onClose={toggleGettingStartedModal}
          companyId={daoInfo?.companyId}
        />
      )}
      <BaseModal toggle={creating} close={handleCreateClose}>
        <ApiKeyCreateForm isOpen={creating} onClose={handleCreateClose} />
      </BaseModal>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <DaoDevDashboardBoxWrapperWithHover
            direction={'column'}
            data-testid={'get-started'}
            onClick={toggleGettingStartedModal}
          >
            <Stack direction={'row'} gap={2}>
              <LightbulbWithCircle style={{ height: 48, width: 48 }} />
              <Stack direction={'column'}>
                <Typography variant={'body2'} fontWeight={'bold'}>
                  {GET_STARTED}
                </Typography>
                <Typography variant={'body2'} paddingTop={'0.25rem'}>
                  {STARTED_DESC}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} gap={1} paddingTop={'0.5rem'}>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    {MORE_INFO}
                  </Typography>
                  <ArrowRightIcon
                    fill={theme.palette.common.black}
                    sx={{
                      width: 8,
                      height: 8
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DaoDevDashboardBoxWrapperWithHover>
        </Grid>
        <Grid item sm={6}>
          <DaoDevDashboardBoxWrapperWithHover
            direction={'column'}
            data-testid={'dive-deep'}
            onClick={() => window.open(`${CONNECT_URL}api-docs`, '_blank')}
          >
            <Stack direction={'row'} gap={2}>
              <BookWithCircle style={{ height: 48, width: 48 }} />
              <Stack direction={'column'}>
                <Typography variant={'body2'} fontWeight={'bold'}>
                  {DIVE_DEEP}
                </Typography>
                <Typography variant={'body2'} paddingTop={'0.25rem'}>
                  {LOOKING_FOR_MORE}
                </Typography>
                <Stack direction={'row'} alignItems={'center'} gap={1} paddingTop={'0.5rem'}>
                  <Typography variant={'body2'} fontWeight={'bold'}>
                    {READ_DOCS}
                  </Typography>
                  <ArrowRightIcon
                    fill={theme.palette.common.black}
                    sx={{
                      width: 8,
                      height: 8
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DaoDevDashboardBoxWrapperWithHover>
        </Grid>
        <Grid item sm={6} width={'100%'}>
          <DaoDevDashboardBoxWrapper
            data-testid={'company-id'}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack direction={'column'}>
              <Typography variant={'body2'} fontWeight={'bold'}>
                {COMPANY_ID}
              </Typography>
              <Typography variant={'body2'}>{daoInfo?.companyId}</Typography>
            </Stack>
            <IconButton onClick={copyCompanyId}>
              <CopyIcon />
            </IconButton>
          </DaoDevDashboardBoxWrapper>
        </Grid>
      </Grid>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'2.5rem 0 0.5rem'}
      >
        <Typography variant={'body2'} fontWeight={'bold'}>
          {TAB_TITLE}
        </Typography>
        <Button
          id={'add-new-key'}
          onClick={toggleCreating}
          data-testid={'control-panel-add-button'}
          size={'medium'}
          variant={'contained'}
          color={'primary'}
        >
          <Typography variant={'body1'} fontWeight={'bolder'}>
            {CREATE_KEY}
          </Typography>
          <SecondaryAddIcon style={{ marginLeft: '.5rem' }} />
        </Button>
      </Stack>
      <DaoDeveloperDashboardApiKeyTable apiKeys={apiKeys} onScroll={handleScroll} />
    </>
  );
};

export default DaoDeveloperDashboard;
