import { MenuItem, Typography } from '@mui/material';
import { EllipsisMenu } from 'components/menus';
import { COMMENTS_ACTIONS } from 'constants/menu-actions.labels';
import React, { useEffect } from 'react';
import CommentMenuProps from './CommentMenu.props';
import { MenuItemDeleteStyled } from './CommentMenu.style';

const CommentMenu = ({
  authorId,
  comment,
  hasDeletePermissions,
  isDeleteDialogOpen,
  toggleReportModal,
  toggleDeleteModal,
  toggleEdit
}: CommentMenuProps): React.ReactElement => {
  const { REPORT, EDIT, DELETE } = COMMENTS_ACTIONS;
  const [menuItems, setMenuItems] = React.useState<
    {
      show: boolean;
      component: React.ReactElement;
    }[]
  >([]);

  useEffect(() => {
    const items = [
      {
        component: (
          <MenuItem data-testid="edit-option" key={EDIT} onClick={toggleEdit}>
            {EDIT}
          </MenuItem>
        ),
        show: comment.author.user.id === authorId
      },
      {
        component: (
          <MenuItem data-testid="report-option" key={REPORT} onClick={toggleReportModal}>
            {REPORT}
          </MenuItem>
        ),
        show: true
      },
      {
        component: (
          <MenuItemDeleteStyled
            data-testid="delete-option"
            key={DELETE}
            onClick={toggleDeleteModal}
          >
            <Typography color="error">{DELETE}</Typography>
          </MenuItemDeleteStyled>
        ),
        show: comment.author.user.id === authorId || hasDeletePermissions
      }
    ];
    setMenuItems(items);
  }, [
    DELETE,
    EDIT,
    REPORT,
    authorId,
    comment,
    hasDeletePermissions,
    isDeleteDialogOpen,
    toggleDeleteModal,
    toggleEdit,
    toggleReportModal
  ]);

  return (
    <EllipsisMenu data-testid="comment-menu" disabled={comment.deleted} darker={!comment.deleted}>
      {menuItems.map((item) => item.show && item.component)}
    </EllipsisMenu>
  );
};

export default CommentMenu;
