export const DAO_COMMON_PAGES = {
  HOME: 'Home',
  UPDATES: 'Updates',
  SEASONS: 'Seasons',
  MEMBERS: 'Members',
  MANAGE: 'Manage',
  ACTIVITIES: 'Activities'
};

export const DAO_COMMON_LABELS = {
  COMMONS: 'Commons',
  ESTABLISHED: 'est.',
  MEMBERS: 'members'
};

export const DAO_JOIN_LABELS = {
  ENTER_THE_DAO: 'Enter the collective',
  READ_AND_AGREE_DOCUMENTS:
    'Your journey to greatness starts here. We are excited to bring you onboard with us./n Please take some time to read and agree to the documents below.',
  READ_AND_AGREE: 'I have read and agree to the stuff up there',
  REQUEST_BEING_REVIEWED:
    'Your request is being reviewed by a collective admin. In the meantime, go do something useful.',
  THANKS: 'Thanks.',
  TOKEN_ASSOCIATION: 'Token Association',
  TOKEN_ASSOCIATION_DESCRIPTION:
    'This collective operates on Hedera which means we will need to associate the token ID with your wallet. This will not take any effort on your part, we will do it for you.',
  WELCOME_TO_DAO: 'Welcome to the collective 👋',
  DOCUMENT_ERROR_STATE: 'Document Error, please contact collective administrator'
};
