import {
  inputBaseClasses,
  outlinedInputClasses,
  Select,
  Stack,
  styled,
  svgIconClasses,
  Typography,
  TypographyProps
} from '@mui/material';

export const HeaderCommentsStackStyled = styled(Stack)(({ theme }) => ({
  borderTop: `.0625rem solid ${theme.palette.grey[300]}`,
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  position: 'relative'
}));

export const TypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isEnabled'
})<TypographyProps & { isEnabled: boolean }>(({ isEnabled }) =>
  !isEnabled
    ? {
        position: 'absolute',
        left: '1rem'
      }
    : undefined
);

export const StyledSelectStyled = styled(Select)(({ theme }) => ({
  [`&.${outlinedInputClasses.root}`]: {
    minHeight: '2rem'
  },
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(1),
    ...theme.typography.body2,
    [`&.${inputBaseClasses.input}`]: {
      paddingRight: theme.spacing(3)
    }
  },
  [`& .${svgIconClasses.root}`]: {
    fontSize: '1rem',
    right: 8
  }
}));
