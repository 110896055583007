import { Button, Stack, Typography } from '@mui/material';
import BaseModal from 'components/modals';
import { BUTTON_LABELS } from 'constants/button.labels';
import { useNotification } from 'hooks';
import { usePatronageActivityService } from 'hooks/services';
import React, { useEffect, useState } from 'react';
import { Severity } from 'types/enum';
import { PATRONAGE_ACTIVITY_FORM } from 'constants/dao-admin-nav.labels';
import DeletePatronageActivityProps from './DeletePatronageActivity.props';

const DeletePatronageActivityModal = ({
  onClose,
  daoId,
  activityId,
  isOpen
}: DeletePatronageActivityProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);
  const { DELETE_MODAL } = PATRONAGE_ACTIVITY_FORM;
  const { DELETE, KEEP } = BUTTON_LABELS;
  const { deleteActivity } = usePatronageActivityService();
  const { notify } = useNotification();

  const handleDeleteActivity = async () => {
    try {
      await deleteActivity(daoId, activityId);
      onClose(true);
      notify(DELETE_MODAL.SUCCESS, { severity: Severity.Success });
    } catch (error) {
      console.log(error);
      notify(DELETE_MODAL.FAILURE, { severity: Severity.Error });
    } finally {
      setOpen(false);
    }
  };

  const cancel = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <BaseModal title={DELETE_MODAL.DELETE_HEADER} toggle={open} close={cancel} width={450}>
      <Stack>
        <Typography data-testid="modal-delete-content" variant="body1">
          {DELETE_MODAL.ARE_YOU_SURE}
        </Typography>

        <Stack direction="row" spacing={2} marginTop="4rem">
          <Button
            data-testid="modal-cancel-button"
            variant="outlined"
            color="primary"
            size="large"
            onClick={cancel}
          >
            {KEEP}
          </Button>
          <Button
            data-testid="modal-delete-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleDeleteActivity}
          >
            {DELETE}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};
export default DeletePatronageActivityModal;
