import { Box, Chip, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const DividerContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'customColor'
})<{ customColor: string }>(({ theme, customColor }) => ({
  display: 'flex',
  padding: '1.5rem 0',
  '& .MuiChip-root': {
    height: '12px',
    '& .MuiChip-label': {
      color: 'white',
      backgroundColor: customColor,
      borderRadius: '0.25rem',
      fontSize: '0.5rem',
      lineHeight: '0.625rem'
    }
  },
  '& .MuiDivider-root': {
    color: customColor,
    display: 'contents',
    fontSize: '0.625rem',
    lineHeight: '0.813rem',
    width: '95%',
    '&:after': {
      borderTop: 'thin solid ' + customColor
    },
    '&:before': {
      borderTop: 'thin solid ' + customColor
    }
  }
}));

export interface HorizontalDividerProps {
  color?: string;
  label: string;
  tag?: string;
}

const HorizontalDivider = ({
  color = 'inherit',
  label,
  tag
}: HorizontalDividerProps): React.ReactElement => {
  return (
    <DividerContainerStyled customColor={color}>
      <Divider>{label}</Divider>
      {tag && <Chip label={tag} />}
    </DividerContainerStyled>
  );
};

export default HorizontalDivider;
