import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { ReactElement } from 'react';

const UpVoteIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon width="22" height="24" viewBox="0 0 22 24" fill="transparent" {...props}>
      <path
        d="M3.11898 15.1429H4.82143V21C4.82143 22.1046 5.71686 23 6.82143 23H15.1786C16.2831 23 17.1786 22.1046 17.1786 21V15.1429L18.881 15.1429C20.5713 15.1429 21.4994 13.1762 20.4249 11.8714L12.5439 2.30164C11.7438 1.33019 10.2562 1.33019 9.45614 2.30164L1.57512 11.8714C0.50063 13.1762 1.42874 15.1429 3.11898 15.1429Z"
        stroke={props.stroke || '#181818'}
        strokeWidth="2"
        fill={props.fill || 'transparent'}
      />
    </SvgIcon>
  );
};

export default UpVoteIcon;
