import { ButtonGroupProps, Stack, styled } from '@mui/material';
import SquareIconButton from '../square-icon-button/SquareIconButton';

export const StackStyled = styled(Stack, {
  shouldForwardProp: (props) => props !== 'active' && props !== 'value' && props !== 'activeFilter'
})<{ value: string; activeFilter: string }>(({ theme, value, activeFilter }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0px 1.5rem 1rem 0',

  '&:hover': {
    '& > svg > path': {
      fill: value !== activeFilter && theme.palette.grey[700]
    },
    '& > p': {
      color: value !== activeFilter && theme.palette.grey[700]
    }
  }
}));

export const FilterButtonStyled = styled(SquareIconButton, {
  shouldForwardProp: (props) =>
    props !== 'active' && props !== 'disableElevation' && props !== 'fullWidth'
})<ButtonGroupProps & { active: boolean }>(({ theme, active, value }) => ({
  minWidth: '2rem',
  minHeight: '2rem',
  padding: theme.spacing(0.5, 0.5),
  margin: theme.spacing(0, 0.5),

  '&:hover': {
    backgroundColor: theme.palette.grey[300]
  },
  '&.MuiButtonGroup-grouped': {
    minWidth: '2rem',
    padding: theme.spacing(0.5, 0.5)
  },
  '& svg': {
    width: '1.25rem',
    height: '1.25rem'
  },
  transition: 'all 0.3s ease'
}));
