import { ProfileEdit } from 'components';
import UserLayout from 'components/v2/base/layout/UserLayout';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import {
  AdminMembershipContextProvider,
  FeedItemProvider,
  FeedProvider,
  PatronageActivityProvider
} from 'contexts';
import { useDao, useUser } from 'hooks';
import useAuth from 'hooks/use-auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LogoOnlyLayout, LogoWithCoopOnlyLayout } from 'layouts';
import { Suspense, lazy } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { compareDaoRoles } from 'utils';
import DaoNav from '../components/v2/base/layout/DaoNavigation';
import { UserDaoRole } from '../types/enum';
import AuthenticationVerificationStepper from '../pages/auth/AuthenticationVerificationStepper';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );
};

const PrivateRoute = ({
  children,
  minDaoRole,
  isEnabled
}: {
  children: React.ReactNode;
  minDaoRole?: UserDaoRole;
  isEnabled?: boolean;
}): React.ReactElement => {
  const { activeDaoMembership, currentDao } = useDao();
  const { userInfo } = useUser();
  const { token } = useAuth();

  const hasTokenPresent = !!token;
  const daoMembershipPresent = !!activeDaoMembership?.id;
  const hasAtLeastMinRole =
    minDaoRole && daoMembershipPresent
      ? compareDaoRoles(minDaoRole, activeDaoMembership?.role as UserDaoRole)
      : true;

  if (!hasTokenPresent) {
    return <Navigate to={ROUTES.AUTH} replace />;
  }

  if (daoMembershipPresent && !isEnabled) {
    return <Navigate to={ROOT_ROUTE.DAO((currentDao as any)?.whiteLabelSubDomain)} />;
  }

  if (!hasAtLeastMinRole) {
    return <Navigate to={ROOT_ROUTE.DAO((currentDao as any)?.whiteLabelSubDomain)} />;
  }

  if (!isEnabled) {
    return <Navigate to={ROUTES.USER_PROFILE(userInfo?.id)} />;
  }

  return <>{children}</>;
};

export function AuthenticationRouter(): React.ReactElement | null {
  return useRoutes([
    {
      path: ROUTES.AUTH,
      element: <LogoWithCoopOnlyLayout />,
      children: [
        {
          path: '',
          element: <Authentication />
        },
        {
          path: ROUTES.FORGOT_PASSWORD,
          children: [
            {
              path: '',
              element: <ForgotPasswordForm />
            }
          ]
        },
        {
          path: ROUTES.USER_ACCOUNT_MANAGEMENT,
          children: [
            {
              path: '',
              element: <UserAccountManagementPage />
            }
          ]
        },
        {
          path: ROUTES.VERIFICATION,
          children: [
            {
              path: '',
              element: <AuthenticationVerificationStepper />
            }
          ]
        },
        {
          path: ROUTES.REGISTER,
          children: [
            {
              path: '',
              element: <RegisterForm />
            }
          ]
        }
      ]
    }
  ]);
}

export default function Router(): React.ReactElement | null {
  const {
    adminControlPanelPage,
    adminInsightsPage,
    adminManagePage,
    adminMembersPage,
    commonsUpdatesPage,
    commonsActionsPage,
    commonsMembersPage,
    daoProfileSettingsPage,
    userProfileHomePage,
    userProfileConnectPage,
    userProfileNotificationsPage,
    userProfileSettingsPage,
    userProfileHelpPage,
    developerDashboardPage,
    adminDocumentsPage,
    addNewPatronageActivity
  } = useFlags();
  const { userInfo } = useUser();

  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout showIcon={true} />,
      children: [
        {
          path: ROUTES.NOT_FOUND,
          element: <Navigate to={ROOT_ROUTE.USER(userInfo?.id)} replace />
        },
        { path: '*', element: <Navigate to={ROOT_ROUTE.USER(userInfo?.id)} replace /> }
      ]
    },
    {
      path: ROOT_ROUTE.USER(),
      element: <UserLayout />,
      children: [
        {
          path: ROUTES.USER_PROFILE(),
          element: (
            <PrivateRoute isEnabled={userProfileHomePage}>
              <UserProfileHomePage />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.USER_DAO_NOTIFICATION_SETTINGS(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={userProfileNotificationsPage}>
              <UserDaoNotificationSettingsPage />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.USER_TRANSACTION_LIST(),
          element: (
            <PrivateRoute isEnabled={true}>
              <EarningsTransactionList />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.CONNECT(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={userProfileConnectPage}>
              <UserProfileConnectPage />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.USER_PROFILE_SETTINGS(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={userProfileSettingsPage}>
              <ProfileEdit />
            </PrivateRoute>
          )
        }
      ]
    },
    {
      path: ROOT_ROUTE.DAO(),
      element: <DaoNav />,
      children: [
        {
          path: ROUTES.DAO_HOME(),
          children: [
            {
              element: (
                <PrivateRoute isEnabled={true}>
                  <DaoHomePage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_INVITE(),
          children: [
            {
              element: (
                <PrivateRoute isEnabled={true}>
                  <DaoHomePage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_ACTIVITIES(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminControlPanelPage}>
                  <PatronageActivityProvider>
                    <DaoControlPanelPage />
                  </PatronageActivityProvider>
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_DOCUMENTS(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminDocumentsPage}>
                  <DaoDocumentsPage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_DEVELOPER_DASHBOARD(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={developerDashboardPage}>
                  <DaoDeveloperDashboardPage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_ADMIN_ACTIVITY_FORM(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={addNewPatronageActivity}>
                  <DaoAdminActivityForm />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_INSIGHTS_DASHBOARD(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminInsightsPage}>
                  <FeedProvider>
                    <PatronageActivityProvider>
                      <DaoInsightsDashboardPage />
                    </PatronageActivityProvider>
                  </FeedProvider>
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_TRANSACTION_LIST(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminInsightsPage}>
              <AwardTransactionList />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.DAO_TOP_MEMBERS_LIST(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminInsightsPage}>
              <MemberLeaderboardList />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.DAO_TOP_ACTIVITIES_LIST(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminInsightsPage}>
              <ActivityLeaderboardList />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.DAO_PROFILE(),
          children: [
            {
              element: (
                <PrivateRoute isEnabled={true}>
                  <DaoHomePage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_UPDATES(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={commonsUpdatesPage}>
                  <FeedProvider>
                    <DaoUpdatesPage />
                  </FeedProvider>
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_MEMBERS(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={commonsMembersPage}>
                  <DaoMembersPage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_COMMONS_ACTIVITIES(),
          children: [
            {
              path: '',
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={commonsActionsPage}>
                  <DaoActionsPage />
                </PrivateRoute>
              )
            },
            {
              path: ROUTES.DAO_ACTIVITY_DETAILS_PAGE(),
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={true}>
                  <ActivityDetailsPage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_ADMIN_ACTIVITY_MANUAL_AWARD_PAGE(),
          element: (
            <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={true}>
              <DaoAdminManualAllocationPage />
            </PrivateRoute>
          )
        },
        {
          path: ROUTES.DAO_ADMIN_MEMBERS(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminMembersPage}>
                  <AdminMembershipContextProvider>
                    <DaoAdminMembersPage />
                  </AdminMembershipContextProvider>
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_ADMIN_MEMBERS_PROFILE(),
          children: [
            {
              element: (
                <PrivateRoute isEnabled={true}>
                  <DaoAdminMembersProfilePage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_MANAGE(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={adminManagePage}>
                  <DaoManagePage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_PROFILE_SETTINGS(),
          children: [
            {
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.ADMIN} isEnabled={daoProfileSettingsPage}>
                  <DaoSettingsPage />
                </PrivateRoute>
              )
            }
          ]
        },
        {
          path: ROUTES.DAO_ROOM_FEED(),
          children: [
            {
              path: '',
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={true}>
                  <FeedProvider>
                    <DaosFeed />
                  </FeedProvider>
                </PrivateRoute>
              )
            },
            {
              path: ROUTES.SINGLE_POST(),
              element: (
                <PrivateRoute minDaoRole={UserDaoRole.MEMBER} isEnabled={true}>
                  <FeedItemProvider>
                    <SinglePostPage />
                  </FeedItemProvider>
                </PrivateRoute>
              )
            }
          ]
        }
      ]
    },
    {
      path: ROUTES.REFERRAL,
      element: <Outlet />,
      children: [
        {
          path: '',
          element: <ReferralPage />
        }
      ]
    },
    {
      path: ROUTES.HELP,
      // element: <Layout />,
      children: [
        {
          element: (
            <PrivateRoute isEnabled={userProfileHelpPage}>
              <HelpPage />
            </PrivateRoute>
          )
        }
      ]
    },
    { path: '*', element: <Navigate to={`${ROOT_ROUTE.USER(userInfo?.id)}`} replace /> }
  ]);
}

// Pages
const Authentication = Loadable(lazy(() => import('pages/auth/AuthenticationPage')));
const RegisterForm = Loadable(lazy(() => import('components/v2/auth/register/RegisterForm')));
const ForgotPasswordForm = Loadable(
  lazy(() => import('components/v2/auth/forgot-password/ForgotPassword'))
);
const DaosFeed = Loadable(lazy(() => import('pages/dao-feed/DaoFeed')));
const UserAccountManagementPage = Loadable(
  lazy(() => import('pages/user-account-management-page/UserAccountManagement'))
);
const HelpPage = Loadable(lazy(() => import('pages/help-page/HelpPage')));
const DaoHomePage = Loadable(lazy(() => import('pages/dao-commons/DaoHomePage')));
const DaoControlPanelPage = Loadable(lazy(() => import('pages/dao-admin/DaoControlPanelPage')));
const DaoDocumentsPage = Loadable(lazy(() => import('pages/dao-document/DaoDocumentPage')));
const DaoDeveloperDashboardPage = Loadable(
  lazy(() => import('pages/dao-admin/DaoDeveloperDashboardPage'))
);
const DaoAdminActivityForm = Loadable(
  lazy(() => import('pages/dao-admin/dao-admin-activity-form/ActivityFormWrapper'))
);
const DaoInsightsDashboardPage = Loadable(
  lazy(() => import('pages/dao-admin/DaoInsightsDashboardPage'))
);
const AwardTransactionList = Loadable(
  lazy(
    () =>
      import(
        'components/profile/tab-profile/dao-insights-dashboard/title-card/award-transaction-list/AwardTransactionList'
      )
  )
);
const MemberLeaderboardList = Loadable(
  lazy(
    () =>
      import(
        'components/profile/tab-profile/dao-insights-dashboard/title-card/member-leaderboard-list/MemberLeaderboardList'
      )
  )
);
const ActivityLeaderboardList = Loadable(
  lazy(
    () =>
      import(
        'components/profile/tab-profile/dao-insights-dashboard/title-card/activity-leaderboard-list/ActivityLeaderboardList'
      )
  )
);
const DaoUpdatesPage = Loadable(lazy(() => import('pages/dao-commons/DaoUpdatesPage')));
const DaoActionsPage = Loadable(lazy(() => import('pages/dao-commons/DaoActionsPage')));
const DaoMembersPage = Loadable(lazy(() => import('pages/dao-commons/DaoMembersPage')));
const DaoAdminMembersPage = Loadable(lazy(() => import('pages/dao-admin/DaoMemberAdminPage')));
const DaoAdminManualAllocationPage = Loadable(
  lazy(() => import('pages/dao-admin/dao-admin-manual-allocation/DaoAdminManualAllocationPage'))
);
const ReferralPage = Loadable(lazy(() => import('pages/referral/ReferralPage')));
const DaoAdminMembersProfilePage = Loadable(
  lazy(() => import('pages/dao-admin/dao-admin-member-profile/DaoAdminMemberProfilePage'))
);
const DaoManagePage = Loadable(lazy(() => import('pages/dao-commons/DaoManagePage')));
const DaoSettingsPage = Loadable(lazy(() => import('pages/dao-commons/DaoSettingsPage')));
const ActivityDetailsPage = Loadable(
  lazy(() => import('pages/activity-details-page/ActivityDetailsPage'))
);
const SinglePostPage = Loadable(lazy(() => import('pages/single-post/SinglePostPage')));
const UserProfileConnectPage = Loadable(
  lazy(() => import('pages/user-profile/UserProfileConnectPage'))
);
const UserDaoNotificationSettingsPage = Loadable(
  lazy(() => import('pages/user-profile/UserProfileNotificationsPage'))
);
const UserProfileHomePage = Loadable(lazy(() => import('pages/user-profile/UserProfileHomePage')));
const EarningsTransactionList = Loadable(
  lazy(
    () =>
      import(
        'components/v2/profile/earnings-history/earnings-transaction-list/EarningsTransactionList'
      )
  )
);
