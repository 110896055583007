import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Curved(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M4 4H5.33333L6 8.52174L8.33333 12.6957L13.6667 16.5217L20 17.913V20H4V4Z"
        stroke={props.stroke || 'transparent'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'url(#paint0_linear_17379_104)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 19H20"
        stroke={props.stroke || '#424242'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.77734 4C7.19957 13.0588 11.4662 16.3529 19.9996 17.1765"
        stroke={props.stroke || '#155EEF'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17379_104"
          x1="12"
          y1="4"
          x2="12"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF4FF" />
          <stop offset="1" stopColor="#EFF4FF" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
