import { Box, IconButton, Modal, styled } from '@mui/material';

const ModalStyled = styled(Modal)(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  '& .MuiBackdrop-root': {
    background: theme.palette.common.white
  }
}));

const BoxStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'removePadding' })<{
  removePadding?: boolean;
}>(({ removePadding, theme }) => ({
  border: `0.063rem solid ${theme.palette.grey[300]}`,
  minHeight: '92vh',
  marginBottom: '5rem',
  overflowY: 'auto',
  Width: 'auto',
  [theme.breakpoints.up('md')]: {
    padding: removePadding ? 'initial' : '0rem 4.5rem 2rem 4.5rem',
    maxWidth: '44.5rem',
    width: '44.5rem'
  }
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  paddingRight: '1rem',
  [theme.breakpoints.up('md')]: {
    paddingRight: '0'
  }
}));

const TitleContainerStyled = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '0.25rem',
  width: 'auto',
  height: '4rem',
  paddingLeft: '1rem',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    height: 'auto',
    paddingLeft: 0
  }
}));

export { ModalStyled, BoxStyled, IconButtonStyled, TitleContainerStyled };
