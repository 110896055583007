import { Grid, MenuItem, Stack, Typography } from '@mui/material';
import { EllipsisMenu } from 'components/menus';
import { DAO_ADMIN_LABELS } from 'constants/dao-admin-nav.labels';
import { DAO_INVITE, DAO_INVITE_SETTINGS } from 'constants/dao-invite-labels';
import { useDao, useNotification } from 'hooks';
import { useDaoInviteService } from 'hooks/services';
import React, { useCallback, useMemo, useState } from 'react';
import { copyToClipboard, subtractDates } from 'utils';
import DaoInviteProps from './DaoInvite.props';
import { BoxStyled } from './DaoInvite.style';
import DaoInviteForm from './dao-invite-form/DaoInviteForm';

const DaoInvite = ({ manageableEntity }: DaoInviteProps): React.ReactElement => {
  const endDays: number = useMemo((): number => {
    const currentDate = new Date();
    const endDate = new Date(String(manageableEntity?.daoInviteEntity?.endDate));
    return subtractDates(endDate ?? currentDate, currentDate);
  }, [manageableEntity?.daoInviteEntity?.endDate]);
  const { THIS_INVITE_ENDS, THIS_INVITE_EXPIRED, MAX_USES_FOR_THIS_INVITE, DAYS, INVITE } =
    DAO_INVITE;
  const { NO_LIMIT } = DAO_INVITE_SETTINGS;
  const { COPY_LINK, EDIT, COPIED } = DAO_ADMIN_LABELS;
  const { notify } = useNotification();
  const { getDaoInviteLink } = useDaoInviteService();
  const { currentDao } = useDao();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const copyLink = useCallback(async () => {
    if (!manageableEntity?.daoInviteEntity?.id || !currentDao?.id) return;
    const inviteLinkResponse = await getDaoInviteLink(
      currentDao.id,
      manageableEntity?.daoInviteEntity?.id,
      manageableEntity.id
    );
    const { inviteLink } = inviteLinkResponse.data;
    copyToClipboard(inviteLink);
    notify(COPIED);
  }, [COPIED, notify]);

  const updateInviteLink = (days: number, maxInvites: number) => {
    const now = new Date();
    now.setDate(now.getDate() + days);
    if (manageableEntity && manageableEntity.daoInviteEntity) {
      manageableEntity.daoInviteEntity.endDate = now;
      manageableEntity.daoInviteEntity.maxInvites = maxInvites;
    }
  };

  return (
    <BoxStyled>
      <Stack direction={'row'} alignItems={'center'}>
        <Grid container>
          <Grid item md>
            <Typography color={'common.black'} variant={'body1'}>
              {INVITE}
            </Typography>
            <Typography variant={'caption'} sx={{ display: 'block' }}>
              {`${MAX_USES_FOR_THIS_INVITE} ${
                (manageableEntity?.daoInviteEntity?.maxInvites ?? 0) > 0
                  ? manageableEntity?.daoInviteEntity?.maxInvites
                  : NO_LIMIT
              }`}
            </Typography>
            <Typography variant={'caption'} sx={{ display: 'block' }}>
              {endDays <= 0
                ? THIS_INVITE_EXPIRED
                : `${THIS_INVITE_ENDS} ${endDays} ${DAYS(endDays)}`}
            </Typography>
          </Grid>
        </Grid>
        <EllipsisMenu>
          <MenuItem onClick={() => setOpenEditModal(true)}>{EDIT}</MenuItem>
          <MenuItem onClick={copyLink}>{COPY_LINK}</MenuItem>
        </EllipsisMenu>
      </Stack>
      {openEditModal && (
        <DaoInviteForm
          daoId={currentDao.id}
          currentInvite={manageableEntity}
          updateInviteLink={updateInviteLink}
          open={openEditModal}
          setOpen={setOpenEditModal}
        />
      )}
    </BoxStyled>
  );
};

export default DaoInvite;
