interface ColorPickerProps {
  defaultColor?: string;
  onColorSelected: (color: string) => void;
}

export interface ColorPickerItem {
  isSelected: boolean;
  color: string;
}

const COLOR_PICKER_COLORS = (): string[] => [
  '#DE241B',
  '#FF4500',
  '#C24E1E',
  '#FF8B1F',
  '#DEB91E',
  '#FFED03',
  '#80CF00',
  '#77E520',
  '#009BCC',
  '#3DAEFF',
  '#740FD6',
  '#8E1EFF',
  '#D3099A',
  '#FF35C6'
];

export { COLOR_PICKER_COLORS };
export default ColorPickerProps;
