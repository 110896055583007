import { styled, Box } from '@mui/material';

export const BoxStyled = styled(Box)<{}>(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderRadius: '0.25rem',
  padding: '1rem',
  marginTop: '3rem',
  position: 'fixed',
  right: '1rem',
  bottom: '2rem',
  maxWidth: '13.5rem',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    left: '1rem',
    right: '1rem'
  }
}));
