import { TimeIntervalOption } from 'types/enum';
import { TimeSeriesDropdownOption } from '../model';

export enum TimeSeriesValue {
  ONE_DAY = '1d',
  SEVEN_DAYS = '7d',
  ONE_MONTH = '1M',
  THREE_MONTHS = '3M',
  ALL_TIME = 'All'
}

export const TimeSeriesDropdownOptions: TimeSeriesDropdownOption[] = [
  { text: '1d', value: TimeSeriesValue.ONE_DAY, interval: TimeIntervalOption.ONE_HR },
  { text: '1w', value: TimeSeriesValue.SEVEN_DAYS, interval: TimeIntervalOption.ONE_DAY },
  { text: '1m', value: TimeSeriesValue.ONE_MONTH, interval: TimeIntervalOption.ONE_DAY },
  { text: '3m', value: TimeSeriesValue.THREE_MONTHS, interval: TimeIntervalOption.ONE_WEEK }
];

export const TimeSeriesDropdownOptionsAllTime: TimeSeriesDropdownOption[] = [
  { text: '1d', value: TimeSeriesValue.ONE_DAY, interval: TimeIntervalOption.ONE_HR },
  { text: '1w', value: TimeSeriesValue.SEVEN_DAYS, interval: TimeIntervalOption.ONE_DAY },
  { text: '1m', value: TimeSeriesValue.ONE_MONTH, interval: TimeIntervalOption.ONE_DAY },
  { text: '3m', value: TimeSeriesValue.THREE_MONTHS, interval: TimeIntervalOption.ONE_WEEK },
  { text: 'All Time', value: TimeSeriesValue.ALL_TIME, interval: TimeIntervalOption.ONE_DAY }
];

export const DefaultTimeSeriesDropdownOption = TimeSeriesDropdownOptions.find(
  (item) => item.value === TimeSeriesValue.SEVEN_DAYS
) as TimeSeriesDropdownOption;

export const DefaultTimeSeriesDropdownOptionAllTime = TimeSeriesDropdownOptionsAllTime.find(
  (item) => item.value === TimeSeriesValue.ALL_TIME
) as TimeSeriesDropdownOption;
