import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DownArrowIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="8" height="6" viewBox="0 0 8 6" fill="none" {...props}>
      <path
        d="M7.33248 1.11378C7.20758 0.989616 7.03861 0.919922 6.86248 0.919922C6.68636 0.919922 6.51739 0.989616 6.39249 1.11378L3.99915 3.47378L1.63915 1.11378C1.51424 0.989616 1.34528 0.919922 1.16915 0.919922C0.993028 0.919922 0.82406 0.989616 0.699152 1.11378C0.636666 1.17576 0.58707 1.24949 0.553224 1.33073C0.519378 1.41197 0.501953 1.49911 0.501953 1.58712C0.501953 1.67512 0.519378 1.76226 0.553224 1.8435C0.58707 1.92474 0.636666 1.99847 0.699152 2.06045L3.52582 4.88712C3.58779 4.9496 3.66153 4.9992 3.74277 5.03304C3.82401 5.06689 3.91114 5.08432 3.99915 5.08432C4.08716 5.08432 4.1743 5.06689 4.25554 5.03304C4.33678 4.9992 4.41051 4.9496 4.47249 4.88712L7.33248 2.06045C7.39497 1.99847 7.44457 1.92474 7.47841 1.8435C7.51226 1.76226 7.52968 1.67512 7.52968 1.58712C7.52968 1.49911 7.51226 1.41197 7.47841 1.33073C7.44457 1.24949 7.39497 1.17576 7.33248 1.11378Z"
        fill="#8A8A8A"
      />
    </SvgIcon>
  );
}
