import BaseModal from './base-modal/BaseModal';
import ConfirmationModal from './confirmation-modal/ConfirmationModal';
import {
  ActionsContainerStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  ModalContentStyled,
  ModalStyled,
  TitleStyled,
  SubTitleStyled
} from './confirmation-modal/ConfirmationModal.style';
import TosModal from './tos-modal/Tos';
import DeletePatronageActivityModal from './delete-patronage-activity/DeletePatronageActivity';

export default BaseModal;

export {
  DeletePatronageActivityModal,
  ConfirmationModal,
  ActionsContainerStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  ModalContentStyled,
  ModalStyled,
  TitleStyled,
  TosModal,
  SubTitleStyled
};
