import { Theme } from '@mui/material/styles';

export default function AppBar(theme: Theme): object {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          minHeight: theme.spacing(8),
          margin: '0 auto',
          backgroundColor: theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.divider}`,
          [theme.breakpoints.up('md')]: {
            minHeight: theme.spacing(7),
            borderBottom: 'none'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          margin: '0 auto',
          maxWidth: theme.breakpoints.values.lg,
          width: '100%',
          minHeight: theme.spacing(8),
          padding: theme.spacing(2, 2, 0, 2),
          [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 2, 0, 2)
          },
          [theme.breakpoints.up('md')]: {
            minHeight: theme.spacing(7),
            padding: theme.spacing(2, 4, 2, 4)
          }
        }
      }
    }
  };
}
