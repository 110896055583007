import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { NavigationItems } from 'components';
import { MobileDrawerNavigation } from 'components/drawer-navigation';
import { DaoContextProvider, RoomMembershipContextProvider } from 'contexts';
import { useAppNavigation } from 'hooks';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import MobileHeader from '../mobile-header/MobileHeader';

const DaoNav = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { userNavExpanded } = useAppNavigation();

  const [isInDao, setIsInDao] = useState<boolean>(false);
  const [activeNavItemName, setActiveNavItemName] = useState('');

  useEffect(() => {
    setIsInDao(location.pathname.includes('/c/'));
  }, [location]);

  return (
    <Container style={{ margin: 0, padding: 0, maxWidth: 'unset', height: '100%' }}>
      <DaoContextProvider>
        <RoomMembershipContextProvider>
          <Stack direction={'row'} height={isMobile ? 'auto' : '-webkit-fill-available'}>
            {!isMobile && <NavigationItems />}
            <Stack
              sx={{
                width: '100%',
                alignItems: isMobile ? 'inherit' : 'center',
                backgroundColor:
                  userNavExpanded && isMobile ? `${theme.palette.grey[700]}` : 'unset',
                height: '100vh',
                overflowY: 'auto',
                marginLeft: isMobile ? '0' : '16.5rem'
              }}
            >
              {isMobile && (
                <>
                  <MobileHeader isInDao={isInDao} />
                  <MobileDrawerNavigation
                    activeNavItemName={activeNavItemName}
                    setActiveNavItemName={setActiveNavItemName}
                    isInDao={isInDao}
                  />
                </>
              )}
              <Outlet />
            </Stack>
          </Stack>
        </RoomMembershipContextProvider>
      </DaoContextProvider>
    </Container>
  );
};

export default DaoNav;
