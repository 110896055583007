import { menuItemClasses } from '@mui/material';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme): object {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper': {
            backgroundColor: theme.palette.common.white,
            borderRadius: '0',
            minWidth: '4.25rem',
            '& .MuiButtonBase-root': {
              padding: theme.spacing(0.5),
              borderRadius: '0'
            },
            '& .Mui-selected': {
              backgroundColor: theme.palette.action.selected
            }
          },
          '& .actions-menu': {
            borderRadius: 0,
            backgroundColor: theme.palette.background.paper,
            minWidth: '10.75rem',
            [`.${menuItemClasses.root}`]: {
              padding: theme.spacing(2),
              borderRadius: 0,
              [`&:hover, &.${menuItemClasses.selected}`]: {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.common.black
              }
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          borderRadius: '0 !important',
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            'svg > path': {
              fill: theme.palette.common.white
            }
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[300]
          }
        }
      }
    }
  };
}
