import { LinkRenderer } from 'components';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { v4 as uuidv4 } from 'uuid';
import FeedCardContentProps from './FeedCardContent.props';
import FeedCardContentStyled from './FeedCardContent.style';

const FeedCardContent = ({
  body,
  truncateBody = false,
  children,
  onClick
}: FeedCardContentProps): React.ReactElement => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, 'text/html');
  const linesForChecklists = Array.from(doc.body.querySelectorAll('*'))
    .flatMap((el) => Array.from(el.childNodes))
    .filter((node) => node.nodeType === Node.TEXT_NODE && node?.textContent?.trim() !== '')
    .map((node) => node?.textContent?.trim());

  return (
    <>
      {children}
      <FeedCardContentStyled
        data-testid="feed-card-content"
        id={`feed-card-content-${uuidv4()}`}
        onClick={onClick && onClick}
        truncateBody={truncateBody}
      >
        <ReactMarkdown
          data-testid="reactMarkDownContent"
          components={{ a: LinkRenderer }}
          rehypePlugins={[rehypeRaw]}
          className="ql-editor"
          children={
            truncateBody && linesForChecklists.length > 2
              ? `${linesForChecklists.slice(0, 2).join('\n')}...`
              : body
          }
        />
      </FeedCardContentStyled>
    </>
  );
};

export default FeedCardContent;
