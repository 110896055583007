import { Stack, Typography } from '@mui/material';
import CountUp from 'react-countup';
import { MetricType } from 'types/enum';
import { MetricProps } from '../chart-header/ChartHeader.props';
import { BitIcon } from 'assets/icons/hero-ui';

const DashboardMetricDisplay = ({ totalMetricConfig }: MetricProps): React.ReactElement => {
  const { metricType, totalMetricValue } = totalMetricConfig;
  const COUNT_DURATION = 1;

  return (
    <Stack direction="row" alignItems={'center'} data-testid="metric-display-container">
      <CountUp
        start={0}
        end={totalMetricValue}
        duration={COUNT_DURATION}
        prefix={metricType === MetricType.CURRENCY ? '$' : ''}
        separator={','}
      >
        {({ countUpRef }) => <Typography ref={countUpRef} variant={'h4'} />}
      </CountUp>
      {metricType === MetricType.BITS && <BitIcon style={{ marginLeft: '0.5rem' }} />}
    </Stack>
  );
};

export default DashboardMetricDisplay;
