import { MemberSeasonDTO } from '@piefi-platform/types-lib';
import { TimeSeriesDropdownOption } from 'model';
import { TimeIntervalOption } from 'types/enum';
import { TimeSeriesValue } from './time-series-dropdown-options';

export const AllTimeOption = {
  text: 'All Time',
  value: TimeSeriesValue.ALL_TIME,
  interval: TimeIntervalOption.ONE_WEEK
};

export const SeasonsDropdownOptions = (
  seasonList: MemberSeasonDTO[]
): TimeSeriesDropdownOption[] => {
  const options = seasonList.map((season) => ({
    text: `s${season.season.seasonNumber}`,
    value: season.id,
    interval: TimeIntervalOption.ONE_HR
  }));
  return options.reverse();
};
