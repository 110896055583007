import { ListItemButton, styled } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => {
    return prop !== 'openDrawer' && prop !== 'activeNavItemName' && prop !== 'membership';
  }
})<{ openDrawer: boolean; activeNavItemName: string; membership: DaoMembershipDTO }>(
  ({ theme, openDrawer, activeNavItemName, membership }) => ({
    width: openDrawer ? 'auto' : '3rem',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: openDrawer ? 'initial' : 'center',
    transition: 'all 0.3s ease',
    backgroundColor:
      activeNavItemName === membership.id ? `${theme.palette.grey[300]} !important` : 'initial',
    borderRadius: activeNavItemName === membership.id ? '0.5rem' : 'initial',
    '&:hover': {
      borderRadius: '0.5rem',
      backgroundColor: theme.palette.grey[300],
      maxWidth: openDrawer ? 'none' : '3rem'
    }
  })
);

export const StyledListItemButtonNoCollective = styled(ListItemButton)(({ theme }) => ({
  borderRadius: '0.5rem',
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:hover': {
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.grey[100],
    cursor: 'default'
  }
}));
