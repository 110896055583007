import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LinkedinIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path
        d="M12.6464 0.333345H1.35305C1.2261 0.331582 1.10005 0.354844 0.982091 0.401801C0.864134 0.448759 0.756582 0.518492 0.665579 0.60702C0.574575 0.695547 0.501901 0.801135 0.451708 0.917752C0.401515 1.03437 0.374786 1.15973 0.373047 1.28668V12.7133C0.374786 12.8403 0.401515 12.9657 0.451708 13.0823C0.501901 13.1989 0.574575 13.3045 0.665579 13.393C0.756582 13.4815 0.864134 13.5513 0.982091 13.5982C1.10005 13.6452 1.2261 13.6684 1.35305 13.6667H12.6464C12.7733 13.6684 12.8994 13.6452 13.0173 13.5982C13.1353 13.5513 13.2428 13.4815 13.3338 13.393C13.4249 13.3045 13.4975 13.1989 13.5477 13.0823C13.5979 12.9657 13.6246 12.8403 13.6264 12.7133V1.28668C13.6246 1.15973 13.5979 1.03437 13.5477 0.917752C13.4975 0.801135 13.4249 0.695547 13.3338 0.60702C13.2428 0.518492 13.1353 0.448759 13.0173 0.401801C12.8994 0.354844 12.7733 0.331582 12.6464 0.333345V0.333345ZM4.39305 11.4933H2.39305V5.49334H4.39305V11.4933ZM3.39305 4.65334C3.11722 4.65334 2.85269 4.54377 2.65766 4.34874C2.46262 4.1537 2.35305 3.88917 2.35305 3.61334C2.35305 3.33752 2.46262 3.07299 2.65766 2.87795C2.85269 2.68292 3.11722 2.57335 3.39305 2.57335C3.53951 2.55673 3.68783 2.57125 3.8283 2.61593C3.96876 2.66062 4.09821 2.73447 4.20815 2.83265C4.3181 2.93083 4.40606 3.05113 4.46629 3.18567C4.52652 3.3202 4.55766 3.46594 4.55766 3.61334C4.55766 3.76075 4.52652 3.90649 4.46629 4.04102C4.40606 4.17556 4.3181 4.29586 4.20815 4.39404C4.09821 4.49222 3.96876 4.56607 3.8283 4.61076C3.68783 4.65544 3.53951 4.66995 3.39305 4.65334V4.65334ZM11.6064 11.4933H9.60638V8.27334C9.60638 7.46668 9.31971 6.94001 8.59305 6.94001C8.36816 6.94166 8.14917 7.0122 7.9656 7.14213C7.78204 7.27205 7.6427 7.45513 7.56638 7.66668C7.51421 7.82337 7.4916 7.98839 7.49971 8.15334V11.4867H5.49971C5.49971 11.4867 5.49971 6.03334 5.49971 5.48668H7.49971V6.33335C7.6814 6.01808 7.94565 5.75836 8.26401 5.58215C8.58236 5.40593 8.94276 5.31991 9.30638 5.33334C10.6397 5.33334 11.6064 6.19335 11.6064 8.04001V11.4933Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
