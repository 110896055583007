import { Stack, Typography } from '@mui/material';
import { AvatarBuilder } from 'components';
import { COLLECTIVE_LABELS } from 'constants/user-home.labels';
import { AvatarSize } from 'types/enum';

const NoCollectiveItem = (): React.ReactElement => {
  const { NO_COLLECTIVES } = COLLECTIVE_LABELS;

  return (
    <Stack alignItems={'center'}>
      <Stack
        direction={'column'}
        sx={{
          maxWidth: '15rem',
          minHeight: '17rem',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <AvatarBuilder id={'test'} size={AvatarSize.LARGE} src={''} isSquare={true} />
        <Typography variant={'body2'} textAlign={'center'} pt={'1rem'}>
          {NO_COLLECTIVES}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoCollectiveItem;
