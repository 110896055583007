import { MenuItem } from '@mui/material';
import { DeletePost, ReportEntityModal } from 'components';
import { EllipsisMenu } from 'components/menus';
import { MenuItemDeleteStyled } from 'components/v2/comments/comment-menu/CommentMenu.style';
import { POST_ACTIONS } from 'constants/menu-actions.labels';
import { useFeature } from 'flagged';
import { useDao, useFeed, useUser } from 'hooks';
import React, { useEffect, useState } from 'react';
import { FeedReportType, UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import EditPostModal from '../edit-post/EditPost';

const PostMenu = (): React.ReactElement => {
  const { showEditMode, setShowEditForm, post } = useFeed();
  const { userInfo } = useUser();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const { activeDaoMembership } = useDao();
  const [hasPostEditPermissions, setHasPostEditPermissions] = useState<boolean>(false);
  const [hasPostDeletePermissions, setHasPostDeletePermissions] = useState<boolean>(false);

  useEffect(() => {
    if (activeDaoMembership) {
      const role = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
      const isAtLeastAdmin = compareDaoRoles(UserDaoRole.ADMIN, role);

      const currentUserIsPostAuthor = post?.author.user.id === userInfo?.id;

      setHasPostEditPermissions(isAtLeastAdmin && currentUserIsPostAuthor);
      setHasPostDeletePermissions(isAtLeastAdmin || currentUserIsPostAuthor);
    }
  }, [activeDaoMembership, post, userInfo]);

  const { SHARE, REPORT, EDIT, DELETE } = POST_ACTIONS;
  const isEnabled = useFeature('v2');
  const showDeleteModal = () => setDeleteModal(true);
  const closeDeleteModal = () => setDeleteModal(false);
  const showEditModal = () => setShowEditForm(true);
  const toggleReportModal = () => setShowReportModal(!showReportModal);

  return (
    <>
      <EllipsisMenu center darker>
        <MenuItem onClick={toggleReportModal}>{REPORT}</MenuItem>
        {isEnabled && <MenuItem>{SHARE}</MenuItem>}
        {hasPostEditPermissions && (
          <MenuItem data-testid="edit-post" onClick={showEditModal}>
            {EDIT}
          </MenuItem>
        )}
        {hasPostDeletePermissions && (
          <MenuItemDeleteStyled data-testid="delete-option" onClick={showDeleteModal}>
            {DELETE}
          </MenuItemDeleteStyled>
        )}
      </EllipsisMenu>
      {deleteModal && post && (
        <DeletePost
          afterClose={closeDeleteModal}
          daoId={post.room.dao.id}
          roomId={post.room.id}
          post={post}
        />
      )}
      {showEditMode && <EditPostModal />}
      {showReportModal && post && (
        <ReportEntityModal
          entity={{ id: post.id, daoId: post.room.dao.id, roomId: post.room.id }}
          entityType={FeedReportType.Post}
          onReportSubmitted={toggleReportModal}
        />
      )}
    </>
  );
};

export default PostMenu;
