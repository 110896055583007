import { Stack, Typography, TypographyProps } from '@mui/material';
import { PatronageActivityDTO } from '@piefi-platform/types-lib';
import { UnitAmountIconDisplay } from 'components/texts';
import { AWARD_TYPE } from 'constants/dao-admin-nav.labels';
import { ACTIVITIES_SECTION_LABELS } from 'constants/home-page.labels';
import { useEffect, useRef, useState } from 'react';
import { determineAwardType, usdFormatter } from 'utils';
import { Trophy01 } from 'assets/icons/education';

interface ActivityAwardLabelProps {
  activity: PatronageActivityDTO;
  typographyProps?: TypographyProps;
  caption?: boolean;
  captionProps?: TypographyProps;
  includeAwardIcon?: boolean;
}

/**
 * @description Generate the display for the award of an activity based on the type of award
 * @param activity {PatronageActivityDTO}
 * @returns
 */
const ActivityAwardLabel = ({
  activity,
  typographyProps,
  caption = false,
  captionProps = { variant: 'caption' },
  includeAwardIcon
}: ActivityAwardLabelProps): React.ReactElement => {
  const [valueText, setValueText] = useState<string>('');
  const [captionText, setCaptionText] = useState<string>('');
  const { BACK, AVAILABLE } = ACTIVITIES_SECTION_LABELS;
  const awardType = useRef<AWARD_TYPE>(AWARD_TYPE.FLAT);

  useEffect(() => {
    awardType.current = determineAwardType(activity);
  }, []);

  useEffect(() => {
    switch (awardType.current) {
      case AWARD_TYPE.CURVED:
        if (caption) {
          setValueText(
            `${usdFormatter.format(activity.budgetConfig?.maxDollarAmountAllocated || 0)}`
          );
          setCaptionText(AVAILABLE);
        } else {
          setValueText(AWARD_TYPE.CURVED);
        }
        break;
      case AWARD_TYPE.PERCENTAGE:
        activity.variablePoint && setValueText(`${activity?.variablePoint?.operandValue * 100}%`);
        setCaptionText(BACK);
        break;
      case AWARD_TYPE.FLAT:
      default:
        setValueText(usdFormatter.format(activity.points || 0));
        break;
    }
  }, [activity]);

  return (
    <Stack direction={'row'} spacing={1}>
      {includeAwardIcon && <Trophy01 />}
      <Stack direction={'row'} alignItems={'center'} spacing={0.75}>
        <Typography variant={typographyProps?.variant || 'body2'} {...typographyProps}>
          {valueText}
        </Typography>
        {caption &&
          (awardType.current === AWARD_TYPE.FLAT ? (
            <UnitAmountIconDisplay
              amount={activity.amountInUnits || 0}
              typographyProps={captionProps}
              parentheses
            />
          ) : (
            <Typography {...captionProps}>{captionText}</Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default ActivityAwardLabel;
