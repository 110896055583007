import { Theme } from '@mui/material/styles';

//-----------------------------------------------

export default function Box(theme: Theme): object {
  return {
    MuiBox: {
      styleOverrides: {
        root: {
          border: '1px solid red'
        }
      }
    }
  };
}
