import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ChartBoxFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '20px';
  const height = props.height ? `${props.height}px` : '20px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 20 20" {...props}>
      <path
        d="M19.6708 7.81526C19.6308 6.14526 19.5908 4.46526 19.5908 2.79526C19.5908 1.12526 18.3108 0.0252596 16.5408 0.00525961C14.5908 -0.0147404 12.6608 0.0252596 11.0208 0.0752596L9.29083 0.12526C7.24083 0.18526 5.12083 0.24526 2.95083 0.24526C2.07083 0.24526 1.23083 0.67526 0.720828 1.39526C0.110828 2.24526 0.15083 3.39526 0.19083 4.31526C0.19083 4.53526 0.210828 4.74526 0.200828 4.93526L0.160829 8.89526C0.160829 9.78526 0.120829 10.6953 0.080829 11.5953C0.010829 13.4853 -0.059171 15.4353 0.080829 17.3453C0.190829 18.7753 1.56083 19.5253 2.79083 19.6253C3.63083 19.6853 4.48083 19.6653 5.31083 19.6553C5.83083 19.6453 6.35083 19.6253 6.86083 19.6453C7.96083 19.6653 9.06083 19.7253 10.1708 19.7853C11.5208 19.8653 12.9008 19.9453 14.2808 19.9453C15.0008 19.9453 15.7208 19.9253 16.4308 19.8653C18.3608 19.7153 19.5708 18.5753 19.6608 16.7953C19.8208 13.7953 19.7408 10.7653 19.6608 7.83526L19.6708 7.81526ZM16.2008 4.76526L14.4908 7.96526L12.1908 12.1253C12.0708 12.3353 11.8708 12.4753 11.6308 12.5053C11.3908 12.5353 11.1608 12.4553 10.9908 12.2753L8.36083 9.50526L6.73083 12.6553L5.14083 15.5853C5.00083 15.8353 4.75083 15.9753 4.48083 15.9753C4.36083 15.9753 4.24083 15.9453 4.12083 15.8853C3.76083 15.6853 3.62083 15.2353 3.82083 14.8653L5.41083 11.9553L7.51083 7.87526C7.62083 7.65526 7.83083 7.51526 8.07083 7.47526C8.31083 7.44526 8.55083 7.52526 8.72083 7.70526L11.3708 10.4953L13.1708 7.24526L14.8708 4.05526C15.0608 3.68526 15.5208 3.55526 15.8808 3.74526C16.2508 3.93526 16.3808 4.39526 16.1908 4.75526L16.2008 4.76526Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
