import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { firebaseStorage } from 'config/firebase.config';

/**
 * @description uploads
 * @param storageLocation {string} location to store file in
 * @param file {File}
 * @returns {Promise<string>}
 */
const uploadImage = async (storageLocation: string, file: File): Promise<string> => {
  const storageRef = ref(firebaseStorage, storageLocation);
  await uploadBytes(storageRef, file);
  const firebaseUrl = await getDownloadURL(storageRef);
  return firebaseUrl;
};

/**
 * @description download
 * @param storageLocation {string} location to store file in
 * @returns {Promise<string>}
 */
const downloadImage = async (storageLocation: string): Promise<string> => {
  const storageRef = ref(firebaseStorage, storageLocation);
  const firebaseUrl = await getDownloadURL(storageRef);
  return firebaseUrl;
};

export { uploadImage, downloadImage };
