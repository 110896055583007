import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Stack, Typography } from '@mui/material';
import { HorizontalCard } from 'components/v2/widget';
import { CONFIRMATION_LABELS, LANGUAGE } from 'constants/ui-labels';
import { useNotification } from 'hooks';
import { useDaoJoinRequestService, useDaoReportEntityService } from 'hooks/services';
import React, { useCallback, useState } from 'react';
import {
  HorizontalCardVariant,
  ReportedEntityState,
  Severity,
  UserDaoRole,
  UserRoomRole
} from 'types/enum';
import { ItemRequestRowProps } from './ItemRequestRow.props';

const ItemRequestRow = ({
  entity,
  daoId,
  handlePreviewEntity,
  handleEntitySubmission,
  title,
  backActions
}: ItemRequestRowProps): React.ReactElement => {
  const UsFormatter = new Intl.DateTimeFormat(LANGUAGE.US);
  const { updateJoinRequest } = useDaoJoinRequestService();
  const { updateReportEntity } = useDaoReportEntityService();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { notify } = useNotification();
  const { DAO_JOIN_SUCCESSFUL, REPORT_SUCCESSFUL } = CONFIRMATION_LABELS;
  const [userDaoRole] = useState<UserDaoRole>(UserDaoRole.MEMBER);
  const [userRoomRole] = useState<UserRoomRole>(UserRoomRole.MEMBER);

  const handleActionSelected = async (status: ReportedEntityState) => {
    const { joinRequestEntity, reportEntity } = entity;
    if (entity.id && joinRequestEntity?.id) {
      try {
        setIsSubmitted(true);
        const { data } = await updateJoinRequest(
          daoId,
          entity.id,
          joinRequestEntity.id ?? '',
          status,
          userDaoRole,
          userRoomRole
        );

        if (data?.joinRequestEntity) {
          notify(`${DAO_JOIN_SUCCESSFUL} ${status}`, {
            severity: Severity.Success
          });

          handleEntitySubmission(entity);
        }
      } catch (error) {
        setIsSubmitted(false);
        console.error(error);
      }
    }

    if (entity?.id && reportEntity?.id) {
      try {
        setIsSubmitted(true);
        const { data } = await updateReportEntity(daoId, entity.id, reportEntity.id ?? '', status);

        if (data?.reportEntity) {
          notify(`${REPORT_SUCCESSFUL} ${status}`, {
            severity: Severity.Success
          });

          handleEntitySubmission(entity);
        }
      } catch (error) {
        setIsSubmitted(false);
        console.error(error);
      }
    }
  };

  const handleReportEntity = useCallback(() => {
    handlePreviewEntity(entity);
    backActions && backActions.toggleBackButton && backActions?.toggleBackButton();
  }, [backActions, entity, handlePreviewEntity]);

  return (
    // make card clickable if it's a report comment card
    <HorizontalCard
      title=""
      name={
        entity?.joinRequestEntity?.author?.firstName ||
        entity?.reportEntity?.author?.user?.firstName ||
        ''
      }
      variant={HorizontalCardVariant.User}
      img={
        entity.joinRequestEntity?.author.profileThumbnail ||
        entity.reportEntity?.author.user.profileThumbnail
      }
      body={
        <Stack>
          <Typography data-testid={'title-test'} variant="button">
            {title}
          </Typography>
          <Typography variant="caption">
            {`By ${
              entity.joinRequestEntity
                ? entity.joinRequestEntity.author.username
                : entity.reportEntity?.author.user.username
            } at ${
              entity.createdAt &&
              new Date(entity.createdAt).toLocaleString(LANGUAGE.US, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })
            } on ${entity.createdAt && UsFormatter.format(new Date(entity.createdAt))}`}
          </Typography>
        </Stack>
      }
      actions={
        <Stack direction={'row'} justifyContent="flex-end" alignItems="center" gap=".5rem">
          {!entity.reportEntity && (
            <IconButton
              id={'declined-button'}
              onClick={() => {
                !isSubmitted && handleActionSelected(ReportedEntityState.Declined);
              }}
              disabled={isSubmitted}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
          {!entity.reportEntity && (
            <IconButton
              id={'accepted-button'}
              onClick={() => {
                !isSubmitted && handleActionSelected(ReportedEntityState.Accepted);
              }}
              disabled={isSubmitted}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          )}
          {entity.reportEntity && (
            <IconButton id={'view-button'} onClick={handleReportEntity} disabled={isSubmitted}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Stack>
      }
    />
  );
};

export default ItemRequestRow;
