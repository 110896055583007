import { Box, styled } from '@mui/material';

export const ScrimContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.20)',
  backdropFilter: 'blur(0.5rem)',
  borderRadius: '0.5rem',
  zIndex: 1000
});

export const ContentContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto'
});
