import { useMemo, ReactNode } from 'react';
// material
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
// hooks
import useSettings from 'hooks/use-settings';
//
import shape from './shape';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { ThemeMode } from '../types/settings';

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
  customMode?: ThemeMode;
};

export default function ThemeConfig({
  children,
  customMode = 'light'
}: ThemeConfigProps): JSX.Element {
  const { themeMode, themeDirection } = useSettings();
  const mode = customMode || themeMode;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: { ...palette[mode], mode: mode },
      shape,
      typography,
      breakpoints,
      direction: themeDirection,
      shadows: shadows[mode],
      customShadows: customShadows[mode]
    }),
    [mode, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
