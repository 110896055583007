import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Card(theme: Theme): object {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundColor: theme.palette.background.paper,
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          borderWidth: '1px',
          borderColor: theme.palette.grey[300],
          borderStyle: 'solid',
          padding: theme.spacing(3),
          [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6)
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) }
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0)
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': {
            margin: 0,
            paddingBottom: 0
          }
        }
      }
    }
  };
}
