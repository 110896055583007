import { Button, FormControl, InputLabel, MenuItem } from '@mui/material';
import { COMMENTS_ACTIONS } from 'constants/menu-actions.labels';
import { useFeature } from 'flagged';
import { ReactElement } from 'react';
import { SortOptions } from 'types/enum/sort-comments.enums';
import { CommentListHeaderProps } from './CommentListHeader.props';
import {
  HeaderCommentsStackStyled,
  StyledSelectStyled,
  TypographyStyled
} from './CommentListHeader.style';

const CommentListHeader = ({
  post,
  toggleEditor,
  handleSortChange,
  sort
}: CommentListHeaderProps): ReactElement => {
  const isEnabled = useFeature('v2');

  return (
    <HeaderCommentsStackStyled
      direction="row"
      justifyContent={isEnabled ? 'space-between' : 'center'}
      alignItems="center"
    >
      <TypographyStyled variant="body2" isEnabled={!!isEnabled}>
        {post._count.comments} {COMMENTS_ACTIONS.COMMENTS}
      </TypographyStyled>
      <Button variant="outlined" color="primary" size="medium" onClick={() => toggleEditor()}>
        {COMMENTS_ACTIONS.LEAVE_A_COMMENT}
      </Button>
      {isEnabled && (
        <FormControl>
          <StyledSelectStyled
            displayEmpty
            inputProps={{ 'aria-label': COMMENTS_ACTIONS.WITHOUT_LABEL }}
            onChange={handleSortChange}
            value={sort}
            disabled
          >
            <InputLabel>{COMMENTS_ACTIONS.SORT_BY}</InputLabel>
            {Object.keys(SortOptions).map((key) => (
              <MenuItem key={key} value={SortOptions[key as keyof typeof SortOptions]}>
                {SortOptions[key as keyof typeof SortOptions]}
              </MenuItem>
            ))}
          </StyledSelectStyled>
        </FormControl>
      )}
    </HeaderCommentsStackStyled>
  );
};

export default CommentListHeader;
