import { ListItem, ListItemIcon, useMediaQuery, useTheme } from '@mui/material';
import { useAppNavigation, useLocalStorage, useRoomMembership } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionRoom } from 'types';
import { addOrReplace } from 'utils';
import { NavigationItemProps } from './NavigationItem.props';
import { ListItemTextStyled, StatusIndicatorStyled } from './NavigationItem.style';

const NavigationItem = ({
  daoId,
  activeIcon,
  inActiveIcon,
  activePageName,
  name,
  navId,
  url,
  notifications
}: NavigationItemProps): React.ReactElement => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setNavigationOpen, setShowDaoNav } = useAppNavigation();
  const { setActiveRoom } = useRoomMembership();
  const [sessionRooms, setSessionRooms] = useLocalStorage<SessionRoom[]>('sessionRooms', []);
  const [icon, setIcon] = useState(inActiveIcon);

  useEffect(() => {
    if (activePageName === navId) {
      setIcon(activeIcon);
    } else {
      setIcon(inActiveIcon);
    }
  }, [activePageName]);

  const handleClick = useCallback(() => {
    const saveRoomToSession = (daoId: string, url: string) => {
      const filter: SessionRoom = { daoId: daoId, roomId: null, url };

      setSessionRooms(
        addOrReplace(sessionRooms, filter, (session: SessionRoom) => session.daoId !== daoId)
      );
    };
    setShowDaoNav(false);
    if (isMobile) {
      setNavigationOpen(false);
    }
    saveRoomToSession(daoId, url());
    setActiveRoom(undefined);
    navigate(url(), { state: { navigationId: navId } });
  }, [navigate, setActiveRoom, sessionRooms, setSessionRooms, daoId, url]);

  return (
    <ListItem
      sx={{
        paddingLeft: 0,
        width: '100%',
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
          borderRadius: '0.5rem',
          cursor: 'pointer'
        }
      }}
      onClick={handleClick}
    >
      <ListItemTextStyled data-testid={`nav-${name}`} activePageName={activePageName} navId={navId}>
        {icon && <ListItemIcon sx={{ marginRight: 'unset' }}>{icon}</ListItemIcon>}
        {name}
      </ListItemTextStyled>
      {notifications && notifications?.length > 0 && <StatusIndicatorStyled />}
    </ListItem>
  );
};

export default NavigationItem;
