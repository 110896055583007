import {
  BaseQueryParams,
  CommentPostDTO,
  CommentUpdatePayload,
  EntityVote,
  FeedPostDTO,
  VoteDTO
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { ProposalOptionParam } from 'model/proposal-options-param.model';
import { useCallback } from 'react';
import UsePostService from './use-post-service.model';
import {
  commentsUrl,
  deletePostUrl,
  deleteProposalUrl,
  postProposalUrl,
  postUrl,
  putProposalUrl
} from './use-post-service.url';

const usePostService = (): UsePostService => {
  const { post, get, put, _delete } = useHttp();

  const createPost = useCallback(
    (
      daoId: string,
      roomId: string,
      title: string,
      content?: string,
      isAnnouncement?: boolean,
      contentUrls?: any[]
    ): Promise<AxiosResponse<FeedPostDTO>> => {
      return post(postUrl(daoId, roomId), {
        title,
        content,
        contentUrls,
        isAnnouncement
      });
    },
    [post]
  );

  const createProposalPost = useCallback(
    (
      daoId: string,
      roomId: string,
      title: string,
      content?: string,
      contentUrls?: any[],
      proposal?: ProposalOptionParam
    ): Promise<AxiosResponse<FeedPostDTO>> => {
      return post(postProposalUrl(daoId, roomId), {
        title,
        content,
        contentUrls,
        proposal
      });
    },
    [post]
  );

  const createRootComment = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      content: string
    ): Promise<AxiosResponse<CommentPostDTO>> => {
      return post(`${postUrl(daoId, roomId)}/${postId}/comments`, {
        parentId: 'root',
        content,
        createdAt: new Date(),
        deleted: false,
        postId,
        reported: false,
        level: 0
      });
    },
    [post]
  );

  const createChildComment = useCallback(
    (
      daoId: string,
      roomId: string,
      authorId: string,
      postId: string,
      commentId: string,
      content: string,
      level: number
    ): Promise<AxiosResponse<CommentPostDTO>> => {
      return post(`${postUrl(daoId, roomId)}/${postId}/comments`, {
        parentId: commentId,
        content,
        createdAt: new Date(),
        deleted: false,
        postId,
        reported: false,
        level: level
      });
    },
    [post]
  );

  const getPostById = useCallback(
    (daoId: string, roomId: string, postId: string): Promise<AxiosResponse<FeedPostDTO>> => {
      return get<FeedPostDTO>(`${postUrl(daoId, roomId)}/${postId}`);
    },
    [get]
  );

  const createPostVote = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      entityVote: EntityVote
    ): Promise<AxiosResponse<VoteDTO>> => {
      const url = `${postUrl(daoId, roomId)}/${postId}/votes`;
      return put(url, { ...entityVote });
    },
    [put]
  );

  const getRootComments = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<CommentPostDTO[]>> => {
      return get<CommentPostDTO[]>(
        `${commentsUrl(daoId, roomId, postId)}/root/children?page=${page}&size=${size}`
      );
    },
    [get]
  );

  const getChildrenComments = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      commentId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<CommentPostDTO[]>> => {
      return get<CommentPostDTO[]>(
        `${commentsUrl(daoId, roomId, postId)}/${commentId}/children?page=${page}&size=${size}`
      );
    },
    [get]
  );

  const editComment = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      commentId: string,
      content: string
    ): Promise<AxiosResponse<CommentPostDTO>> => {
      return put<CommentUpdatePayload, AxiosResponse<CommentPostDTO>>(
        `${commentsUrl(daoId, roomId, postId)}/${commentId}`,
        {
          content
        }
      );
    },
    [put]
  );

  const updatePost = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      title: string,
      content?: string,
      isAnnouncement?: boolean // TODO: support isAnnouncement in the BE
    ): Promise<AxiosResponse<FeedPostDTO>> => {
      return put(`${postUrl(daoId, roomId)}/${postId}`, {
        title,
        content
      });
    },
    [put]
  );

  const updateProposal = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      proposalId: string,
      title: string,
      content?: string
    ): Promise<AxiosResponse<FeedPostDTO>> => {
      return put(`${putProposalUrl(daoId, roomId, postId)}/${proposalId}`, {
        title,
        content
      });
    },
    [put]
  );

  const deletePost = useCallback(
    (daoId: string, roomId: string, postId: string): Promise<AxiosResponse> => {
      const url: string = deletePostUrl(daoId, roomId, postId);
      return _delete(url);
    },
    [_delete]
  );

  const deleteProposal = useCallback(
    (daoId: string, roomId: string, postId: string, proposalId: string): Promise<AxiosResponse> => {
      const url: string = deleteProposalUrl(daoId, roomId, postId, proposalId);
      return _delete(url);
    },
    [_delete]
  );

  return {
    editComment,
    createPost,
    createProposalPost,
    createRootComment,
    createChildComment,
    getPostById,
    createPostVote,
    getRootComments,
    getChildrenComments,
    updatePost,
    updateProposal,
    deletePost,
    deleteProposal
  };
};

export default usePostService;
