import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { ReactElement } from 'react';

const TrophyIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        d="M21.3333 2.66667H18.6667V0H5.33333V2.66667H2.66667C1.2 2.66667 0 3.86667 0 5.33333V6.66667C0 10.0667 2.56 12.84 5.85333 13.2533C6.69333 15.2533 8.49333 16.76 10.6667 17.2V21.3333H5.33333V24H18.6667V21.3333H13.3333V17.2C15.5067 16.76 17.3067 15.2533 18.1467 13.2533C21.44 12.84 24 10.0667 24 6.66667V5.33333C24 3.86667 22.8 2.66667 21.3333 2.66667ZM2.66667 6.66667V5.33333H5.33333V10.4267C3.78667 9.86667 2.66667 8.4 2.66667 6.66667ZM12 14.6667C9.8 14.6667 8 12.8667 8 10.6667V2.66667H16V10.6667C16 12.8667 14.2 14.6667 12 14.6667ZM21.3333 6.66667C21.3333 8.4 20.2133 9.86667 18.6667 10.4267V5.33333H21.3333V6.66667Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
};

export default TrophyIcon;
