import { Avatar, styled } from '@mui/material';
import { keyframes } from '@emotion/react';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const AnimatedAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => {
    return prop !== 'hideAnimation' && prop !== 'isSquare';
  }
})<{
  hideAnimation?: boolean;
  isSquare?: boolean;
}>(({ hideAnimation, isSquare, theme }) => ({
  animation: `${hideAnimation ? '' : `${fadeIn} 1s ease-in-out;`} `,
  borderRadius: isSquare ? '0.5rem' : '50%'
}));
