import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function BountyIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17 3H15V1H5V3H3C1.9 3 1 3.9 1 5V6C1 8.55 2.92 10.63 5.39 10.94C6.02 12.44 7.37 13.57 9 13.9V17H5V19H15V17H11V13.9C12.63 13.57 13.98 12.44 14.61 10.94C17.08 10.63 19 8.55 19 6V5C19 3.9 18.1 3 17 3ZM3 6V5H5V8.82C3.84 8.4 3 7.3 3 6ZM10 12C8.35 12 7 10.65 7 9V3H13V9C13 10.65 11.65 12 10 12ZM17 6C17 7.3 16.16 8.4 15 8.82V5H17V6Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
