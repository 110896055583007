import { Box, Stack, Typography } from '@mui/material';
import { CommentPostDTO, EntityVote, VoteEntityDTO } from '@piefi-platform/types-lib';
import {
  CommentDeleteModal,
  CommentMeta,
  FeedCardContent,
  ReportEntityModal,
  TextEditor
} from 'components';
import { MAX_LENGTH } from 'constants/app-config';
import { HTTP_STATUS } from 'constants/http-statuses';
import { DELETE_OPTION } from 'constants/post-options-labels';
import { useDao, useFeed, useUser } from 'hooks';
import { useCommentService, usePostService } from 'hooks/services';
import React, { useCallback, useEffect, useState } from 'react';
import { EntityVoteEnum, FeedReportType, SocialEntityAuditAction, UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import CommentFooter from '../comment-footer/CommentFooter';
import CommentTimeline from '../comment-timeline/CommentTimeline';
import { CommentContentProps } from './CommentContent.props';
import { CommentCardContent } from './CommentContent.style';

const CommentContent = ({
  parentComment,
  level,
  mainPost,
  showFooter = true
}: CommentContentProps): React.ReactElement => {
  const { userInfo } = useUser();
  const { createCommentVote } = useCommentService();
  const { createChildComment, getChildrenComments, editComment } = usePostService();
  const { setPost, setComments } = useFeed();
  const { activeDaoMembership } = useDao();
  const [comment, setComment] = useState<CommentPostDTO>(parentComment);
  const [interactions, setInteractions] = useState<CommentPostDTO[]>();
  const [showInteractions, setShowInteractions] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [votes, setVotes] = useState(parentComment.upvoteCount - parentComment.downvoteCount || 0);
  const [hasDeletePermissions, setHasDeletePermissions] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const { CONTENT_DELETED } = DELETE_OPTION;
  const [, setCommentReported] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editedContent, setEditedContent] = useState<string>(comment.content);

  const {
    room: {
      id: roomId,
      dao: { id: daoId }
    }
  } = mainPost;

  useEffect(() => {
    if (activeDaoMembership) {
      const role = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
      const isAdmin = compareDaoRoles(UserDaoRole.ADMIN, role);

      if (isAdmin) setHasDeletePermissions(true);
    }
  }, [activeDaoMembership]);

  const toggleReportDialog = useCallback(() => {
    setIsDialogOpen((prevState) => !prevState);
  }, []);

  const toggleDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen((prevState) => !prevState);
  }, []);

  const toggleEdit = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const handleReportSubmitted = useCallback(() => {
    setCommentReported(true);
    toggleReportDialog();
  }, [toggleReportDialog]);

  const handleCommentToComment = useCallback(
    async (content: string, setStateCallback: (value: any) => void) => {
      try {
        if (mainPost?.room?.dao?.id && mainPost?.room?.id) {
          const childCommentRes = await createChildComment(
            mainPost?.room.dao.id,
            mainPost.room.id,
            mainPost.author.id,
            mainPost.id,
            comment.id,
            content,
            level
          );

          if (childCommentRes.status === HTTP_STATUS.OK) {
            const commentCreated: CommentPostDTO = {
              author: {
                id: mainPost.author.id,
                role: mainPost.author.role,
                user: userInfo!!
              },
              upvoteCount: 0,
              downvoteCount: 0,
              deleted: false,
              contentUrls: [],
              id: childCommentRes.data.id,
              level,
              content: childCommentRes.data.content,
              createdAt: childCommentRes.data.createdAt,
              updatedAt: '',
              parentId: childCommentRes.data.parentId,
              postId: childCommentRes.data.postId,
              _count: {
                children: 0
              },
              socialEntityAudits: []
            };
            setStateCallback(commentCreated);
            setShowReply(false);
            setShowInteractions(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      comment.id,
      createChildComment,
      level,
      mainPost.author.id,
      mainPost.author.role,
      mainPost.id,
      mainPost.room.dao.id,
      mainPost.room.id,
      userInfo
    ]
  );

  const handleReplyToComment = useCallback(
    (content: string) => {
      const setState = (commentCreated: CommentPostDTO) => {
        setInteractions((prevState) => [commentCreated, ...(prevState || [])]);
        setComment((prevState) => ({
          ...prevState,
          _count: { children: prevState._count.children + 1 }
        }));
        setPost((post) => ({ ...post!, _count: { comments: post!._count.comments + 1 } }));
      };
      handleCommentToComment(content, setState);
      setTextAreaValue('');
    },
    [handleCommentToComment, setPost]
  );

  const handleEditComment = useCallback(
    async (editedContent: string) => {
      try {
        if (mainPost?.room?.dao?.id && mainPost?.room?.id) {
          const editCommentRes = await editComment(
            mainPost?.room.dao.id,
            mainPost.room.id,
            mainPost.id,
            comment.id,
            editedContent
          );

          if (editCommentRes.status === HTTP_STATUS.OK) {
            setComment((prevState) => ({
              ...prevState,
              ...editCommentRes.data
            }));
            setPost((post) => ({
              ...post!,
              comments: post!.comments.map((comment) => {
                if (comment.id === editCommentRes.data.id) {
                  return {
                    ...comment,
                    ...editCommentRes.data
                  };
                }
                return comment;
              })
            }));
            setComments((comments) => [
              ...comments!.map((comment) => {
                if (comment.id === editCommentRes.data.id) {
                  return {
                    ...comment,
                    ...editCommentRes.data
                  };
                }
                return comment;
              })
            ]);
            toggleEdit();
          }
        }
      } catch (error) {
        console.log(error); // TODO: Integrate logs
      }
    },
    [
      comment.id,
      editComment,
      mainPost.id,
      mainPost.room.dao.id,
      mainPost.room.id,
      setComments,
      setPost,
      toggleEdit
    ]
  );

  const onCommentDeleted = useCallback(() => {
    setComment(
      (prevState): CommentPostDTO => ({
        ...prevState,
        content: CONTENT_DELETED,
        deleted: true
      })
    );
  }, [CONTENT_DELETED, setComment]);

  const getInteractions = async (comment: CommentPostDTO) => {
    try {
      const response = await getChildrenComments(
        mainPost?.room.dao.id!!,
        mainPost?.room.id!!,
        mainPost?.id!!,
        comment.id,
        {
          page: 0,
          size: comment._count.children
        }
      );
      //TODO: double-check if replies contain votes property when coming from BE response
      if (response && response.status === HTTP_STATUS.OK) {
        setInteractions(response.data);
        setShowInteractions((prevState) => !prevState);
      }
    } catch (error) {
      console.error(error); // TODO: Integrate logs
    }
  };

  const voteOnCommentReply = async (action: EntityVoteEnum): Promise<void> => {
    if (!daoId || !roomId || comment.deleted) return;

    try {
      const { data: newCount } = await createCommentVote(
        daoId,
        roomId,
        comment.postId,
        comment.id,
        {
          action
        } as EntityVote
      );

      const newVote = {
        id: comment.id,
        action: action === comment?.votes?.[0]?.action ? EntityVoteEnum.None : action,
        authorId: '',
        commentId: comment.id
      } as VoteEntityDTO;

      setComment((prev) => ({ ...prev, votes: [newVote] }));
      setVotes(() => newCount.upvoteCount - newCount.downvoteCount);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleShowInteractions = () => {
    if (interactions) {
      setShowInteractions((prevState) => !prevState);
    } else {
      getInteractions(comment);
    }
  };

  return (
    <>
      <CommentCardContent>
        <Stack gap={2}>
          <CommentMeta
            user={comment.author.user}
            createdAt={comment.createdAt}
            edited={
              comment.socialEntityAudits.length > 0 &&
              comment.socialEntityAudits[0].action === SocialEntityAuditAction.UPDATED
            }
            memberId={comment.author.id}
          />
          <CommentTimeline toggleShowInteractions={toggleShowInteractions}>
            <Stack direction="column" gap={2}>
              {!isEdit && !comment.deleted && <FeedCardContent body={comment.content} />}
              {!isEdit && comment.deleted && (
                <Typography color="secondary">{CONTENT_DELETED}</Typography>
              )}
              {isEdit && (
                <TextEditor
                  autoFocus
                  value={editedContent}
                  handleCancelReply={() => {
                    toggleEdit();
                    setEditedContent('');
                  }}
                  onChange={(newContent: string) => setEditedContent(newContent)}
                  handleReply={handleEditComment}
                  maxLength={MAX_LENGTH.COMMENTS}
                  toolBarId={`commentRichEditorToolbar-edit-${comment.id}`}
                />
              )}
              {showFooter && (
                <CommentFooter
                  {...{
                    comment,
                    getInteractions,
                    hasDeletePermissions,
                    isDeleteDialogOpen,
                    setShowReply,
                    showInteractions,
                    toggleDeleteDialog,
                    toggleEdit,
                    toggleReportDialog,
                    voteOnCommentReply,
                    votes
                  }}
                />
              )}

              {showReply && (
                <Box style={{ marginTop: '1rem', marginBottom: '0.63rem' }}>
                  <TextEditor
                    autoFocus={level > 0}
                    value={textAreaValue}
                    handleCancelReply={() => {
                      setShowReply(false);
                      setTextAreaValue('');
                    }}
                    onChange={(content: string) => setTextAreaValue(content)}
                    handleReply={handleReplyToComment}
                    maxLength={MAX_LENGTH.COMMENTS}
                    toolBarId={`commentRichEditorToolbar-${comment.id}`}
                  />
                </Box>
              )}

              {showInteractions && (
                <Stack gap={2}>
                  {interactions?.map((reply) => {
                    return (
                      <CommentContent
                        id={`reply-${reply.id}`}
                        key={`reply-${reply.id}`}
                        parentComment={reply}
                        level={level + 1}
                        mainPost={mainPost}
                        replyActionHandler={handleReplyToComment}
                      />
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </CommentTimeline>
        </Stack>
      </CommentCardContent>
      {isDialogOpen && (
        <ReportEntityModal
          entity={{ id: comment.id, daoId, roomId }}
          entityType={FeedReportType.Comment}
          onReportSubmitted={handleReportSubmitted}
        />
      )}
      {isDeleteDialogOpen && (
        <CommentDeleteModal
          entity={{ daoId, roomId, postId: comment.postId, commentId: comment.id }}
          open={isDeleteDialogOpen}
          onClose={toggleDeleteDialog}
          onCommentDeleted={onCommentDeleted}
        />
      )}
    </>
  );
};

export default CommentContent;
