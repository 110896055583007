import { IconButton, Stack, Typography } from '@mui/material';
import { EditIcon } from 'assets';
import { EditRoomModal, InnerPageContentBox, RoomMemberList } from 'components';
import { ROOM_INFO_TITLES } from 'constants/room.labels';
import { useDao, useRoomMembership } from 'hooks';
import { ReactElement, useState } from 'react';
import { UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';

const RoomInfo = (): ReactElement => {
  const { activeRoom } = useRoomMembership();
  const { activeDaoMembership } = useDao();
  const [editRoomModal, setEditRoomModal] = useState(false);
  const { ROOM_NAME, ROOM_DESC, ROOM_MEMBERS } = ROOM_INFO_TITLES;

  const daoRole = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
  const hasAdminAuthority = compareDaoRoles(UserDaoRole.ADMIN, daoRole);
  const userCanEditRoom = hasAdminAuthority;

  const editRoom = () => setEditRoomModal(!editRoomModal);

  return (
    <>
      <InnerPageContentBox>
        {activeRoom?.room?.name && (
          <Stack direction="column" justifyContent="center" marginBottom="2rem" spacing={1}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" color="text.secondary" fontWeight="800">
                {ROOM_NAME}
              </Typography>
              {userCanEditRoom && (
                <IconButton data-testid="btn-edit-room" role="button" onClick={editRoom}>
                  <EditIcon />
                </IconButton>
              )}
            </Stack>
            <Typography variant="body1">{activeRoom.room.name}</Typography>
          </Stack>
        )}
        {activeRoom?.room?.description && (
          <Stack direction="column" justifyContent="center" marginBottom="2rem">
            <Typography
              variant="body2"
              color="text.secondary"
              marginBottom="0.5rem"
              fontWeight="800"
            >
              {ROOM_DESC}
            </Typography>
            <Typography variant="body1">{activeRoom.room.description}</Typography>
          </Stack>
        )}
        <Stack direction="column" justifyContent="center">
          <Typography variant="body2" color="text.secondary" marginBottom="0.5rem" fontWeight="800">
            {ROOM_MEMBERS}
          </Typography>
          <RoomMemberList />
        </Stack>
      </InnerPageContentBox>

      {editRoomModal && activeRoom && (
        <EditRoomModal room={activeRoom?.room} afterClose={editRoom} />
      )}
    </>
  );
};

export default RoomInfo;
