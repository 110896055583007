import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ArrowNarrowUpRight(
  props: SvgIconProps & { size?: number }
): React.ReactElement {
  const sizeStyle = props.size ? { fontSize: `${props.size}px` } : {};

  return (
    <SvgIcon {...props} style={{ ...props.style, ...sizeStyle }} viewBox="0 0 24 24">
      <path
        d="M6 18L18 6M18 6H10M18 6V14"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
