import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { UserDaoRole } from 'types/enum';
import { toTitleCase } from 'utils';
import IDaoRoleSelector from './DaoRoleSelector.props';
import { StyledSelect } from './DaoRoleSelector.style';

const DaoRoleSelector = ({
  onRoleSelected,
  removeBorder = false
}: IDaoRoleSelector): React.ReactElement => {
  const [selectedRole, setSelectedRole] = useState<UserDaoRole>(UserDaoRole.MEMBER);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const role = UserDaoRole[event.target.value as keyof typeof UserDaoRole];

    setSelectedRole(role);
    onRoleSelected(role);
  };

  return (
    <StyledSelect
      removeBorder={removeBorder}
      labelId="dao-role-select-label"
      id="dao-role-select"
      value={selectedRole}
      onChange={handleChange}
    >
      {Object.keys(UserDaoRole)
        .filter((i) => i !== UserDaoRole.SYSTEM)
        .map((role: string) => {
          return (
            <MenuItem key={role} value={role}>
              {toTitleCase(role)}
            </MenuItem>
          );
        })}
    </StyledSelect>
  );
};

export default DaoRoleSelector;
