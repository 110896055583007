import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function UpsideDaoIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 24 18">
      <path
        d="M23.23 6.24482C22.6746 5.73122 21.8738 5.60402 21.1886 5.92202L15.8377 8.40123L11.5594 0.938403C11.2281 0.360001 10.6084 0 9.9446 0C9.28081 0 8.66111 0.360001 8.32981 0.938403L0.251081 15.0289C-0.15649 15.7405 -0.0611521 16.6261 0.488235 17.2333C0.85052 17.6341 1.35224 17.8477 1.86468 17.8477C2.12805 17.8477 2.395 17.7913 2.64645 17.6749L13.3684 12.708L11.936 10.2096L4.27436 13.7592L9.94579 3.86641L12.7487 8.75403L15.2716 13.1544L17.3869 16.8445C17.748 17.4745 18.4464 17.8393 19.1674 17.7757C19.8872 17.7109 20.5105 17.2273 20.756 16.5433L23.7234 8.26563C23.9796 7.55163 23.7865 6.75842 23.2312 6.24482H23.23ZM18.7932 13.5552L17.2702 10.8984L20.2387 9.52323L18.7932 13.5552Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
