import { User } from '@piefi-platform/types-lib';
import {
  Auth,
  MultiFactorError,
  UserCredential,
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { useAuth } from 'hooks';
import useUserService from '../user-service/use-user-service';
import UseAuthService from './use-auth-service.model';
import { ROUTES } from 'constants/routes';
export type LoginResult = {
  mfaNeeded: boolean;
  cred?: UserCredential;
  auth: Auth;
  error?: MultiFactorError;
};

const useAuthService = (): UseAuthService => {
  const { setRegisteringUser } = useAuth();
  const { createUser } = useUserService();

  const executeLogin = async (email: string, password: string): Promise<LoginResult> => {
    const auth = getAuth();
    try {
      const cred = await signInWithEmailAndPassword(auth, email, password);
      return { mfaNeeded: false, cred, auth };
    } catch (error: any) {
      if (error.code === 'auth/multi-factor-auth-required') {
        return {
          mfaNeeded: process.env.REACT_APP_ENVIRONMENT !== 'local',
          auth,
          error: error as MultiFactorError
        };
      }

      throw error;
    }
  };

  const resetPassword = (email: string) => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email, { url: window.location.href });
  };

  const executeRegister = async (
    data: User,
    email: string,
    password: string,
    file?: any
  ): Promise<void> => {
    const auth = getAuth();
    // creating a new user on database
    setRegisteringUser(true);
    const res = await createUserWithEmailAndPassword(auth, email, password);
    await createUser({
      ...data
    });

    await sendEmailVerification(res.user, {
      url: `${window.location.origin}/${ROUTES.VERIFICATION}`
    });

    setRegisteringUser(false);
  };

  return {
    executeLogin,
    executeRegister,
    resetPassword
  };
};

export default useAuthService;
