import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface Props extends SvgIconProps {
  iconColor?: string;
  onlyDollarSign?: boolean;
}
export default function USDIcon({ iconColor, onlyDollarSign, ...rest }: Props): React.ReactElement {
  return (
    <SvgIcon {...rest}>
      <rect width="32" height="32" rx="4" fill={rest.fill || '#FBFFCC'} />
      {!onlyDollarSign && (
        <path
          d="M9.75 20.3441C9.75 20.6253 9.525 20.7847 9.2625 20.7003C5.625 19.5378 3 16.1441 3 12.1222C3 8.10969 5.625 4.70657 9.2625 3.54407C9.53438 3.45969 9.75 3.61907 9.75 3.90032V4.60344C9.75 4.79094 9.60937 5.00657 9.43125 5.07219C6.55312 6.13157 4.5 8.89719 4.5 12.1222C4.5 15.3566 6.55312 18.1128 9.43125 19.1628C9.60937 19.2284 9.75 19.4441 9.75 19.6316V20.3441Z"
          fill={iconColor || '#E2D240'}
        />
      )}
      <path
        d="M12.7499 17.7471C12.7499 17.9533 12.5812 18.1221 12.3749 18.1221H11.6249C11.4187 18.1221 11.2499 17.9533 11.2499 17.7471V16.5658C9.60929 16.3408 8.81242 15.4314 8.59679 14.1752C8.55929 13.9596 8.72804 13.7721 8.94367 13.7721H9.79679C9.97492 13.7721 10.1249 13.9033 10.1624 14.0721C10.3218 14.8127 10.753 15.3846 12.0655 15.3846C13.0312 15.3846 13.7249 14.8408 13.7249 14.0346C13.7249 13.2283 13.3218 12.9189 11.8968 12.6846C9.79679 12.4033 8.80304 11.7658 8.80304 10.1252C8.80304 8.85957 9.76867 7.86582 11.2499 7.65957V6.49707C11.2499 6.29082 11.4187 6.12207 11.6249 6.12207H12.3749C12.5812 6.12207 12.7499 6.29082 12.7499 6.49707V7.6877C13.9593 7.90332 14.728 8.5877 14.9812 9.73145C15.028 9.94707 14.8593 10.1439 14.6343 10.1439H13.8468C13.678 10.1439 13.5374 10.0314 13.4905 9.87207C13.2749 9.1502 12.7593 8.83144 11.8593 8.83144C10.8655 8.83144 10.3499 9.30957 10.3499 9.98457C10.3499 10.6971 10.6405 11.0533 12.1687 11.2689C14.2312 11.5502 15.2999 12.1408 15.2999 13.8939C15.2999 15.2252 14.3062 16.3033 12.7593 16.5471V17.7471H12.7499Z"
        fill={iconColor || '#E2D240'}
      />
      {!onlyDollarSign && (
        <path
          d="M14.7375 20.7001C14.4656 20.7845 14.25 20.6251 14.25 20.3439V19.6407C14.25 19.4345 14.3719 19.2376 14.5687 19.172C17.4375 18.122 19.5 15.3564 19.5 12.1314C19.5 8.897 17.4469 6.14075 14.5687 5.09075C14.3906 5.02512 14.25 4.8095 14.25 4.622V3.91887C14.25 3.63762 14.475 3.47825 14.7375 3.56262C18.375 4.70637 21 8.1095 21 12.122C21 16.1439 18.375 19.5376 14.7375 20.7001Z"
          fill={iconColor || '#E2D240'}
        />
      )}
    </SvgIcon>
  );
}
