import {
  BaseTimePointResultsDTO,
  PatronageActivityAggregateResultsDTO,
  PatronageActivityDTO
} from '@piefi-platform/types-lib';
import { useUser } from 'hooks';
import { useUserService } from 'hooks/services';
import { ReactNode, createContext, useEffect, useState } from 'react';
import DataType from 'types/enum/chart-data-types.enum';
import {
  UserValueChartContextType,
  initUserValueChartContextType
} from '../model/contexts/user-value-chart-type.model';

export interface ActivityStatisticsResponse {
  activity: PatronageActivityDTO;
  statistics: PatronageActivityAggregateResultsDTO;
}

export interface AllPatronageData {
  areaData: BaseTimePointResultsDTO[];
  totalValue: number;
}
const initialState: UserValueChartContextType = initUserValueChartContextType();

export const UserValueChartContext = createContext(initialState);

const UserValueChartContextProvider = ({
  children
}: {
  children: ReactNode;
}): React.ReactElement => {
  const { userInfo } = useUser();
  const [allData, setAllData] = useState<AllPatronageData>({
    areaData: [],
    totalValue: 0
  });

  const { getValueHistory } = useUserService();
  const [dataType, setDataType] = useState<string>(DataType.POINT_COUNT);

  // When date window is updated refresh data
  useEffect(() => {
    const fetchUpdatedData = async () => {
      const aggArea = await getAggregatedData();
      const data: BaseTimePointResultsDTO[] = aggArea.map((i) => ({
        count: i.rollingSum,
        time: new Date(i.eventOccurrence)
      }));
      setAllData({ areaData: data, totalValue: aggArea[aggArea.length - 1]?.rollingSum || 0 });
    };
    fetchUpdatedData();
    // I only want this to trigger on dateWindow or dataType toggle update NOTHING ELSE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  /**
   * @description Gets the aggregated data across the entire time interval across all activities
   * @param daoId {string}
   */
  const getAggregatedData = async () => {
    const { data: queriedAreaData } = await getValueHistory({ userId: userInfo?.id || '' });
    console.log(queriedAreaData);
    return queriedAreaData;
  };

  const onTimeSeriesDataTypeUpdated = (event: any, typeSelected: string) => {
    typeSelected && setDataType(typeSelected);
  };

  return (
    <UserValueChartContext.Provider
      value={{
        areaDataForInterval: allData.areaData,
        totalValue: allData.totalValue,
        onTimeSeriesDataTypeUpdated,
        dataType
      }}
    >
      {children}
    </UserValueChartContext.Provider>
  );
};

export default UserValueChartContextProvider;
