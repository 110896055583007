import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Snackbar(theme: Theme): object {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {}
      }
    }
  };
}
