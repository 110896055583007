import { IconButton, styled } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

const modalStyled = (width: number): SxProps<Theme> => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width > -1 ? width : '100%',
    bgcolor: 'background.paper',
    borderRadius: '0.75rem',
    padding: '1.5rem',
    pt: 3,
    px: 3,
    pb: 3
  };
};

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  right: '0.75rem',
  top: '0.75rem'
});

export default modalStyled;
export { IconButtonStyled };
