import { Container, Stack } from '@mui/material';
import { WordMarkLogo } from 'assets';
import AnimatedLogo from 'assets/logomark_moving.json';
import { LottieOptions, useLottie } from 'lottie-react';
import { Outlet } from 'react-router-dom';
import { CoopHeaderLayoutContentStyled } from './CoopHeaderLayout.style';
import { RootStyled } from './LogoOnlyLayout.style';

const LogoWithCoopOnlyLayout = (): React.ReactElement => {
  const options: LottieOptions = {
    animationData: AnimatedLogo,
    loop: true,
    autoPlay: true,
    height: '12.5rem',
    width: '12.5rem'
  };
  const { setSpeed } = useLottie(options, {
    height: '2rem',
    width: '2rem'
  });
  setSpeed(0);

  return (
    <RootStyled title="SignIn | pieFi">
      <Container maxWidth="md" sx={{ paddingTop: '1rem' }}>
        <Stack
          direction={'row'}
          sx={{
            marginBottom: {
              xs: '4rem',
              md: '6rem'
            }
          }}
        >
          <WordMarkLogo sx={{ height: '2rem', width: '7rem', margin: '0 auto' }} />
        </Stack>
        <CoopHeaderLayoutContentStyled>
          <Outlet />
        </CoopHeaderLayoutContentStyled>
      </Container>
    </RootStyled>
  );
};

export default LogoWithCoopOnlyLayout;
