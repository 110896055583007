enum ReportCategory {
  SPAM = 'Spam',
  IMPERSONATION = 'Impersonation',
  PRIVATE_INFORMATION = 'Private Information',
  HATE = 'Hate',
  TERRORISM = 'Terrorism',
  HARASSMENT = 'Harassment',
  MINOR_SEXUALIZATION = 'Minor Sexualization',
  NUDITY = 'Nudity',
  THREATENING_VIOLENCE = 'Threatening Violence',
  SELF_HARM = 'Self Harm',
  OTHER = 'Other'
}

export default ReportCategory;
