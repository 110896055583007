import { IconButton } from '@mui/material';
import { DiscordIcon, InstagramIcon, InternetIcon, LinkedinIcon, TwitterIcon } from 'assets';
import { AvatarBuilder } from 'components';
import { DAO_ADMIN_LABELS } from 'constants/dao-admin-nav.labels';
import { useNotification } from 'hooks';
import { AvatarSize } from 'types/enum';
import { copyToClipboard } from 'utils';
import { UserProfileHeaderProps } from './UserProfileHeader.props';
import {
  ProfileContainerStyled,
  SegmentProfileInfoStyled,
  SocialMediaStackStyled,
  StyledHeaderStack,
  StyledStack,
  UserAboutTextStyled,
  UserNameStyled
} from './UserProfileHeader.style';

const UserProfileHeader = ({ userInfo }: UserProfileHeaderProps): React.ReactElement => {
  const { notify } = useNotification();
  const { COPIED } = DAO_ADMIN_LABELS;

  return (
    <ProfileContainerStyled data-testid="user-profile-header" tabIndex={0}>
      <StyledHeaderStack>
        <SegmentProfileInfoStyled item xs={12} md={10} id="profile-info-segment">
          <StyledStack>
            <AvatarBuilder
              src={userInfo?.profileThumbnail}
              id={'profile-avatar'}
              alt={userInfo?.username}
              size={AvatarSize.MEDIUM}
            />
            <UserNameStyled data-testid="userName" variant={'h1'}>
              {userInfo?.firstName} {userInfo?.lastName}
            </UserNameStyled>
          </StyledStack>
          {userInfo?.profile.bio && (
            <UserAboutTextStyled variant="body1">{userInfo?.profile.bio}</UserAboutTextStyled>
          )}
          {userInfo?.profile?.socialLinks && (
            <SocialMediaStackStyled direction="row" spacing="1rem" marginLeft={'-0.25rem'}>
              {userInfo?.profile?.socialLinks?.twitterLink && (
                <IconButton
                  target="_blank"
                  href={`https://twitter.com/${userInfo?.profile?.socialLinks?.twitterLink}`}
                  rel="noopener noreferrer"
                  aria-label={`${userInfo?.username} Twitter`}
                >
                  <TwitterIcon />
                </IconButton>
              )}
              {userInfo?.profile?.socialLinks?.linkedInLink && (
                <IconButton
                  target="_blank"
                  href={`${userInfo?.profile?.socialLinks?.linkedInLink}`}
                  rel="noopener noreferrer"
                  aria-label={`${userInfo?.username} Linkedin`}
                  style={{ marginLeft: 'unset' }}
                >
                  <LinkedinIcon />
                </IconButton>
              )}
              {userInfo?.profile?.socialLinks?.discordLink && (
                <IconButton
                  target="_blank"
                  onClick={() => {
                    copyToClipboard(`${userInfo?.profile?.socialLinks?.discordLink}`);
                    notify(COPIED);
                  }}
                  href={''}
                  rel="noopener noreferrer"
                  aria-label={`${userInfo?.username} discord`}
                  style={{ marginLeft: 'unset' }}
                >
                  <DiscordIcon />
                </IconButton>
              )}
              {userInfo?.profile?.socialLinks?.instagramLink && (
                <IconButton
                  target="_blank"
                  href={`https://instagram.com/${userInfo?.profile?.socialLinks?.instagramLink}`}
                  rel="noopener noreferrer"
                  aria-label={`${userInfo.username} instagram`}
                  style={{ marginLeft: 'unset' }}
                >
                  <InstagramIcon />
                </IconButton>
              )}
              {userInfo?.profile?.socialLinks?.webLink && (
                <IconButton
                  target="_blank"
                  href={userInfo?.profile?.socialLinks?.webLink}
                  rel="noopener noreferrer"
                  aria-label={`${userInfo?.username} internet`}
                  style={{ marginLeft: 'unset' }}
                >
                  <InternetIcon />
                </IconButton>
              )}
            </SocialMediaStackStyled>
          )}
        </SegmentProfileInfoStyled>
      </StyledHeaderStack>
    </ProfileContainerStyled>
  );
};

export default UserProfileHeader;
