import { Stack, styled } from '@mui/material';

const DaoDevDashboardBoxWrapper = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '1.5rem',
  borderRadius: 3
}));

const DaoDevDashboardBoxWrapperWithHover = styled(DaoDevDashboardBoxWrapper)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer'
  }
}));

export { DaoDevDashboardBoxWrapper, DaoDevDashboardBoxWrapperWithHover };
