import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ChartFlowFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M22.7202 17.7246C22.3402 16.4246 21.2302 15.4446 19.8202 15.1646C19.1402 15.0346 18.4502 15.0746 17.8202 15.2846L16.0102 13.6646L15.2602 12.9746C15.7102 12.7746 16.1602 12.5446 16.5902 12.2746C18.0002 11.3846 19.1602 10.1046 19.9502 8.58459C20.7102 7.12459 21.1502 5.35459 21.1502 3.75459C21.1502 2.92459 20.4802 2.25459 19.6602 2.24459C18.8302 2.24459 18.1602 2.91459 18.1602 3.73459C18.1602 4.86459 17.8302 6.15459 17.2902 7.20459C16.7502 8.25459 15.9602 9.12459 15.0002 9.73459C14.3802 10.1246 13.6802 10.4146 12.9502 10.6646L9.24016 6.94459C9.31016 6.73459 9.37016 6.51459 9.39016 6.28459C9.54016 5.03459 8.98016 3.67459 7.95016 2.81459C7.07016 2.07459 5.97016 1.81459 4.94016 2.13459C4.46016 2.27459 4.00016 2.55459 3.56016 2.95459C2.31016 4.10459 1.73016 5.79459 2.12016 7.17459C2.46016 8.35459 3.44016 9.17459 4.81016 9.42459C5.03016 9.46459 5.25016 9.48459 5.48016 9.48459C6.04016 9.48459 6.61016 9.35459 7.15016 9.11459L9.73016 11.7046C8.16016 12.2446 6.50016 13.0146 5.26016 14.4046C3.41016 16.4746 3.00016 19.3846 2.91016 20.9346C2.87016 21.7646 3.50016 22.4646 4.33016 22.5146C4.36016 22.5146 4.39016 22.5146 4.41016 22.5146C5.20016 22.5146 5.86016 21.8946 5.91016 21.0946C6.02016 19.1246 6.60016 17.4146 7.50016 16.4046C8.45016 15.3446 9.99016 14.7546 11.3702 14.3246C11.6202 14.2446 11.8702 14.1746 12.1202 14.0946L12.8502 14.8246L15.6102 17.3146C15.4202 17.7646 15.3102 18.2446 15.3102 18.7346C15.3102 20.0946 16.1502 21.3546 17.3902 21.8646C17.8502 22.0546 18.4302 22.1646 19.0202 22.1646C19.9902 22.1646 21.0302 21.8846 21.7702 21.2446C22.7202 20.4246 23.1102 19.0146 22.7302 17.7246H22.7202Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
