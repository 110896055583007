import { CaretMenu, InnerPageContentBox } from 'components';
import {
  DefaultTransactionListDropdownOption,
  TransactionListDropdownOptions
} from 'constants/transactions-list-dropdown-options';
import React from 'react';
import { TransactionListContainerProps } from './TransactionListContainer.props';

const TransactionListContainer = ({
  title,
  backActions,
  children
}: TransactionListContainerProps): React.ReactElement => {
  return (
    <InnerPageContentBox title={title} backActions={backActions}>
      <CaretMenu
        options={TransactionListDropdownOptions}
        onOptionSelected={() => {}}
        defaultOption={DefaultTransactionListDropdownOption}
      />
      {children}
    </InnerPageContentBox>
  );
};

export default TransactionListContainer;
