import {
  BaseQueryParams,
  BitValueHistoryTimePointDto,
  DaoMembershipDTO,
  FeedPostDTO,
  ManageableEntityDTO,
  PatronageActivityAggregateResultsDTO,
  PointAwardEventWithoutMemberSeasonDTO,
  RoomMembershipDTO,
  SearchResultDTO,
  User,
  UserDaoMembershipsQueryParams,
  UserEntityDTO,
  UserPatronageActivityResponseDTO,
  UserPointAwardEventQuery,
  UserPointAwardEventsSeasonStatisticDTO,
  UserPortfolioValueQuery,
  UserPostStatisticsDTO,
  UserProposalVoteStatisticsDTO,
  UserSimpleDTO,
  UserUpdate
} from '@piefi-platform/types-lib';
import UserPointsStatisticsDTO from '@piefi-platform/types-lib/dtos/user-point-statistics.dto';
import { AxiosResponse } from 'axios';
import { ActivityStatisticsResponse } from 'contexts/TimeSeriesPatronageStatisticsContext';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import { TimeIntervalOption } from 'types/enum';
import DataType from 'types/enum/chart-data-types.enum';
import usePatronageActivityService from '../patronage-activity-service/use-patronage-activity-service';
import UseUserService from './use-user-service.model';
import { userDaosUrl, userProfileUrl, userUrl } from './use-user-service.url';
import generateQueryString from 'utils/helper/query-builder';

const useUserService = (): UseUserService => {
  const { get, post, put } = useHttp();
  const { getPatronageActivities } = usePatronageActivityService();

  const getCurrentUser = useCallback((): Promise<AxiosResponse<UserEntityDTO>> => {
    return get<UserEntityDTO>(userUrl);
  }, [get]);

  const getUserById = useCallback(
    (userId: string): Promise<AxiosResponse<UserEntityDTO>> => {
      const url: string = `${userProfileUrl}/${userId}`;
      return get<UserEntityDTO>(url);
    },
    [get]
  );

  const getUsers = useCallback(
    (
      username: string,
      exactMatch: boolean,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<UserSimpleDTO[]>> => {
      const url: string = `${userProfileUrl}?username=${username}&exactMatch=${exactMatch}&page=${page}&size=${size}`;
      return get<UserSimpleDTO[]>(url);
    },
    [get]
  );

  const getUserDaoMemberships = useCallback(
    ({
      includeFinancials
    }: UserDaoMembershipsQueryParams): Promise<AxiosResponse<DaoMembershipDTO[]>> => {
      return get<Array<DaoMembershipDTO>>(
        `${userDaosUrl}/dao-memberships?${
          includeFinancials ? `&includeFinancials=${includeFinancials}` : ''
        }`
      );
    },
    [get]
  );

  const getAvailablePatronageActivities = useCallback((): Promise<
    AxiosResponse<UserPatronageActivityResponseDTO>
  > => {
    const url: string = `${userUrl}/patronage-activities`;
    return get(url);
  }, [get]);

  const getUserDaoMembershipByDaoId = useCallback(
    (daoId: string): Promise<AxiosResponse<DaoMembershipDTO>> => {
      return get<DaoMembershipDTO>(`${userDaosUrl}/${daoId}/dao-memberships`);
    },
    [get]
  );

  const getUserRoomMemberships = useCallback(
    (
      daoId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<RoomMembershipDTO[]>> => {
      return get<Array<RoomMembershipDTO>>(
        `${userDaosUrl}/${daoId}/rooms/room-memberships?&page=${page}&size=${size}`
      );
    },
    [get]
  );

  const getUserRoomMembershipByRoomId = useCallback(
    (daoId: string, roomId: string): Promise<AxiosResponse<RoomMembershipDTO>> => {
      return get<RoomMembershipDTO>(`${userDaosUrl}/${daoId}/rooms/${roomId}/room-memberships`);
    },
    [get]
  );

  const getUserDaoPoints = useCallback(
    (
      userId: string,
      seasonId?: string,
      daoMembershipId?: string
    ): Promise<AxiosResponse<UserPointsStatisticsDTO>> => {
      return get<UserPointsStatisticsDTO>(
        `${userUrl}/${userId}/points-awards/?seasonId=${seasonId}&daoMembershipId=${daoMembershipId}`
      );
    },
    [get]
  );

  const getUserPostStatistics = useCallback(
    (userId: string): Promise<AxiosResponse<UserPostStatisticsDTO>> => {
      return get<UserPostStatisticsDTO>(`${userUrl}/${userId}/posts/statistics`);
    },
    [get]
  );

  const getUserProposalStatistics = useCallback(
    (userId: string): Promise<AxiosResponse<UserProposalVoteStatisticsDTO>> => {
      return get<UserProposalVoteStatisticsDTO>(
        `${userUrl}/${userId}/posts/proposals/proposal-votes/statistics`
      );
    },
    [get]
  );

  const getMetaFeedByUser = useCallback(
    (
      { page, size }: BaseQueryParams,
      type?: string
    ): Promise<AxiosResponse<Array<FeedPostDTO>>> => {
      return get<Array<FeedPostDTO>>(
        `${userUrl}/meta-feed?page=${page}&size=${size}${type ? `&type=${type}` : ''}`
      );
    },
    [get]
  );

  const getUserSearchResults = useCallback(
    (
      { page, size }: BaseQueryParams,
      phrase: string
    ): Promise<AxiosResponse<SearchResultDTO[]>> => {
      return get<SearchResultDTO[]>(`${userUrl}/search?page=${page}&size=${size}&phrase=${phrase}`);
    },
    [get]
  );

  const getUserJoinRequestsByDao = useCallback(
    (daoId: string): Promise<AxiosResponse<ManageableEntityDTO[]>> => {
      return get<ManageableEntityDTO[]>(
        `${userUrl}/manageable-entities/-/join-requests?page=0&size=100&daoId=${daoId}&status=PENDING`
      );
    },
    [get]
  );

  const getUserPointsStatistics = useCallback(
    (
      userId: string,
      seasonId?: string,
      daoMemberId?: string,
      includeDaoAggregate?: boolean
    ): Promise<AxiosResponse<UserPointsStatisticsDTO>> => {
      return get<UserPointsStatisticsDTO>(
        `${userUrl}/${userId}/points-awards?seasonId=${seasonId}&includeDaoAggregate=${includeDaoAggregate}&daoMembershipId=${daoMemberId}`
      );
    },
    [get]
  );

  const getStatistics = useCallback(
    (
      daoId: string,
      startDate: Date,
      endDate: Date,
      interval: TimeIntervalOption,
      memberSeasonId: string,
      dataType: DataType,
      activityIds?: string[]
    ): Promise<AxiosResponse<PatronageActivityAggregateResultsDTO[]>> => {
      return post(
        `${userUrl}/point-award-events/statistics?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&interval=${interval}&dataType=${dataType}&daoId=${daoId}&memberSeasonId=${memberSeasonId}&getGrowingAggregate=true`,
        {
          activityIds
        }
      );
    },
    [post]
  );

  const getValueHistory = useCallback(
    (
      query: UserPortfolioValueQuery & { userId: string }
    ): Promise<AxiosResponse<BitValueHistoryTimePointDto[]>> => {
      return get(
        `${userUrl}/point-award-events/account-value?${
          query.memberSeasonId ? `memberSeasonId=${query.memberSeasonId}` : ''
        }`
      );
    },
    [get]
  );

  const getActivitiesWithStatistics = useCallback(
    async (
      daoId: string,
      startDate: Date,
      endDate: Date,
      interval: TimeIntervalOption,
      memberSeasonId: string,
      dataType: DataType
    ): Promise<ActivityStatisticsResponse[]> => {
      const { data: activities } = await getPatronageActivities(daoId, { page: 0, size: 128 });
      const activityIds = activities.map((activity) => {
        return activity.id;
      });
      const { data: activityStatistics } = await getStatistics(
        daoId,
        startDate,
        endDate,
        interval,
        memberSeasonId,
        dataType,
        activityIds
      );

      const statsData = activities.map((activity) => {
        const statistics = activityStatistics.find((stat) => {
          return stat.patronageActivityId === activity.id;
        });
        return {
          activity,
          statistics
        } as ActivityStatisticsResponse;
      });

      return statsData;
    },
    [getPatronageActivities, getStatistics]
  );

  const getUserPointAwardEvents = useCallback(
    (
      params: UserPointAwardEventQuery
    ): Promise<AxiosResponse<PointAwardEventWithoutMemberSeasonDTO[]>> => {
      const queryString = generateQueryString(params);

      return get<PointAwardEventWithoutMemberSeasonDTO[]>(
        `${userUrl}/point-award-events?${queryString}`
      );
    },
    [get]
  );

  const getUserPointAwardStatistics = useCallback(
    (memberSeasonId: string): Promise<AxiosResponse<UserPointAwardEventsSeasonStatisticDTO>> => {
      return get<UserPointAwardEventsSeasonStatisticDTO>(
        `${userUrl}/points-awards/statistics?memberSeasonId=${memberSeasonId}`
      );
    },
    [get]
  );

  const createUser = useCallback(
    (user: User): Promise<AxiosResponse<User>> => {
      return post<User>(`${userUrl}`, user);
    },
    [post]
  );

  const agreeToTos = useCallback((): Promise<AxiosResponse<void>> => {
    return post<void>(`${userUrl}/terms-of-services`);
  }, [post]);

  const createUserCryptoAsset = useCallback(
    (address: string, publicKey: string): Promise<AxiosResponse<{}>> => {
      return post<{}>(`${userUrl}/crypto-assets`, { address, publicKey, protocol: 'HEDERA' });
    },
    [post]
  );

  const updateUser = useCallback(
    (user: UserUpdate): Promise<AxiosResponse<UserEntityDTO>> =>
      put<UserUpdate, AxiosResponse<UserEntityDTO>>(`${userUrl}`, user),
    [put]
  );

  return {
    agreeToTos,
    getCurrentUser,
    getUserPointAwardEvents,
    getUserById,
    getUserDaoMemberships,
    getUserDaoMembershipByDaoId,
    getUserDaoPoints,
    getUserPointAwardStatistics,
    getAvailablePatronageActivities,
    getUserPostStatistics,
    getUserProposalStatistics,
    getUserRoomMemberships,
    getUserRoomMembershipByRoomId,
    getUsers,
    getMetaFeedByUser,
    getUserSearchResults,
    getUserJoinRequestsByDao,
    getUserPointsStatistics,
    createUser,
    updateUser,
    createUserCryptoAsset,
    getStatistics,
    getActivitiesWithStatistics,
    getValueHistory
  };
};

export default useUserService;
