import React from 'react';
import LinkRendererProps from './LinkRenderer.props';

const LinkRenderer = (props: Object): React.ReactElement => {
  const { children, href } = props as LinkRendererProps;

  return (
    <a aria-label={children} href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default LinkRenderer;
