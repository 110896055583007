import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ArrowRightIcon, CloseIcon } from 'assets';
import { DAO_ACTIONS_LABELS } from 'constants/dao-actions.labels';
import { useDao } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { getSessionStorage, setSessionStorage } from 'utils';
import { BoxStyled } from './SeasonZeroGlobalIndicator.style';
import SeasonZeroModal from './season-zero-modal/SeasonZeroModal';

const SeasonZeroGlobalIndicator = (): React.ReactElement => {
  const { LEARN_MORE, INTRO_ONE, INTRO_TWO, WELCOME } = DAO_ACTIONS_LABELS;
  const [showIndicator, setShowIndicator] = useState(false);
  const [showSeasonZeroModal, setShowSeasonZeroModal] = useState(false);
  const { currentActiveSeasonForDao } = useDao();
  const theme = useTheme();

  useEffect(() => {
    if (!currentActiveSeasonForDao || currentActiveSeasonForDao.seasonNumber !== 0) return;
    const indicatorSession = getSessionStorage('globalSeasonZeroIndicatorCancelled');
    setShowIndicator(!!!indicatorSession);
  }, []);

  const toggleSeasonZeroModal = useCallback(() => {
    setShowSeasonZeroModal(!showSeasonZeroModal);
  }, [showSeasonZeroModal]);

  const handleCloseSeasonZeroIndicator = useCallback(() => {
    setShowIndicator(false);
    setSessionStorage('globalSeasonZeroIndicatorCancelled');
  }, [setShowIndicator, setSessionStorage]);

  return (
    <>
      {showIndicator && currentActiveSeasonForDao && (
        <BoxStyled>
          <IconButton
            id={'close-s0-global-indicator'}
            onClick={handleCloseSeasonZeroIndicator}
            style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}
          >
            <CloseIcon fill={theme.palette.secondary.main} />
          </IconButton>
          <Stack direction={'column'}>
            <Typography variant={'body1'} fontWeight={'bold'} marginBottom={'1.5rem'}>
              {WELCOME}
            </Typography>
            <Stack direction={'column'} gap={2}>
              <Typography variant={'body2'} color="secondary">
                {INTRO_ONE}
              </Typography>
              <Typography variant={'body2'} color="secondary">
                {INTRO_TWO}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={1}
              marginTop={'1.5rem'}
              onClick={toggleSeasonZeroModal}
              sx={{
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Typography variant={'body2'} color="secondary">
                {LEARN_MORE}
              </Typography>
              <ArrowRightIcon
                fill={theme.palette.secondary.main}
                sx={{
                  width: 12,
                  height: 12
                }}
              />
            </Stack>
          </Stack>
        </BoxStyled>
      )}
      <SeasonZeroModal open={showSeasonZeroModal} onClose={toggleSeasonZeroModal} />
    </>
  );
};

export default SeasonZeroGlobalIndicator;
