import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { ChevronDownIcon } from 'assets/icons/hero-ui';
import { AvatarBuilder } from 'components';
import { useCallback, useState } from 'react';
import { TransactionListItemProps } from './TransactionListItem.props';

const TransactionListItem = ({
  sourceData,
  body,
  actions,
  details,
  memoLabel
}: TransactionListItemProps): React.ReactElement => {
  const theme = useTheme();
  const { avatarObj } = sourceData;
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleAccordionChange = useCallback(() => {
    if (!details) return;
    setExpanded(!expanded);
  }, [details, expanded]);

  return (
    <Accordion
      expanded={expanded}
      onClick={handleAccordionChange}
      sx={{
        cursor: details ? 'pointer' : 'default',
        '&.Mui-expanded': {
          boxShadow: 'none',
          margin: 0
        }
      }}
    >
      <AccordionSummary
        expandIcon={details && <ChevronDownIcon fill={theme.palette.secondary.main} />}
        aria-controls="transaction-content"
        id="transaction-list-header"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          padding: { xs: 'unset', sm: 'unset', md: 'unset' }
        }}
      >
        <Stack
          direction={'row'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack direction={'row'} width={'100%'} alignItems={'center'}>
            <AvatarBuilder
              id={avatarObj.id}
              size={avatarObj.size}
              src={avatarObj.src}
              isSquare={avatarObj.isSquare}
            />
            <Stack direction={'column'} paddingLeft={'0.5rem'}>
              {body}
            </Stack>
          </Stack>
          <Stack direction={'row'} alignItems={'center'}>
            {actions}
          </Stack>
        </Stack>
      </AccordionSummary>
      {details && (
        <AccordionDetails sx={{ padding: '0 1rem 2rem' }}>
          <Stack
            direction={'column'}
            padding={'1.5rem'}
            sx={{ backgroundColor: theme.palette.grey['100'], borderRadius: '0.5rem' }}
          >
            <Typography variant={'body2'} fontWeight={800} paddingBottom={'0.5rem'}>
              {memoLabel}
            </Typography>
            {details}
          </Stack>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default TransactionListItem;
