import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Backdrop(theme: Theme): object {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: [alpha(theme.palette.grey[900], 0.92)],
          '&.MuiBackdrop-invisible': {
            background: 'transparent'
          }
        }
      }
    }
  };
}
