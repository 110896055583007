import { styled } from '@mui/material';

const StatusIndicatorStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'connected'
})<{ connected: boolean }>(({ connected, theme }) => ({
  width: '.6rem',
  height: '.6rem',
  borderRadius: 100,
  backgroundColor: connected ? theme.palette.success.main : theme.palette.error.main,
  marginLeft: '.5rem'
}));

export default StatusIndicatorStyled;
