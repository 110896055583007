// AvatarSizeComponent.ts
import { AvatarSize } from 'types/enum';
import { useMemo } from 'react';

interface AvatarSizeProps {
  size: AvatarSize;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface AvatarStyle {
  width: string;
  height: string;
  fontSize: string;
  cursor?: string;
}

export const AvatarIconStyles = ({ size, onClick }: AvatarSizeProps): AvatarStyle | {} => {
  return useMemo(() => {
    switch (size) {
      case AvatarSize.XXX_LARGE:
        return {
          width: '10rem',
          height: '10rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.XX_LARGE:
        return {
          width: '7.5rem',
          height: '7.5rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.X_LARGE:
        return {
          width: '6.25rem',
          height: '6.25rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.LARGE:
        return {
          width: '3rem',
          height: '3rem',
          fontSize: '2rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.MEDIUM:
        return {
          width: '2rem',
          height: '2rem',
          fontSize: '1rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.SMALL:
        return {
          width: '1.5rem',
          height: '1.5rem',
          fontSize: '.75rem',
          cursor: onClick && 'pointer'
        };
      case AvatarSize.X_SMALL:
        return {
          width: '1.5rem',
          height: '1.5rem',
          fontSize: '.75rem',
          cursor: onClick && 'pointer'
        };
      default:
        return {};
    }
  }, [size, onClick]);
};
