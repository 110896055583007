import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import AddUserIcon from 'assets/AddUserIcon';
import BaseModal from 'components/modals';
import { AddMember } from 'components/v2/dao-room';
import { ROOM_ACTIONS } from 'constants/room.labels';
import { useDao } from 'hooks';
import React, { useState } from 'react';
import { UserDaoRole } from 'types/enum';
import { handleKeyEnterEvent } from 'utils';
import compareDaoRoles from 'utils/helper/compare-dao-roles';
import { DAORoomProps } from './DAORoomItem.props';
import { StackStyled } from './DAORoomItem.style';

const DAORoomItem = ({ disabled, room, onClick }: DAORoomProps): React.ReactElement => {
  const { activeDaoMembership } = useDao();
  const [openAddMemberFormModal, setOpenAddMemberFormModal] = useState(false);
  const { ADD_TO_ROOM } = ROOM_ACTIONS;

  const openAddMemberModal = () => setOpenAddMemberFormModal(true);
  const closeAddMemberModal = () => setOpenAddMemberFormModal(false);

  let hasAdminAuthority = false;
  if (activeDaoMembership && activeDaoMembership?.role) {
    const daoRole = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
    hasAdminAuthority = compareDaoRoles(UserDaoRole.ADMIN, daoRole);
  }

  return (
    <>
      <ListItem
        disabled={disabled}
        tabIndex={0}
        onKeyUp={(e) => handleKeyEnterEvent({ event: e, callBack: onClick })}
        sx={{ padding: 'unset', height: '2.5rem' }}
      >
        <StackStyled direction={'row'} isActive={room.isActive}>
          <ListItemText
            data-testid={room.roomId}
            onClick={onClick}
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <Typography
              variant={'body1'}
              color={room.roomId === 'add-room' ? 'secondary' : 'inherit'}
            >
              {room.room.name}
            </Typography>
          </ListItemText>
          <ListItemIcon>
            {room.isActive && hasAdminAuthority && (
              <IconButton
                color="inherit"
                data-testid="addUser"
                sx={{
                  height: 24,
                  width: 24
                }}
                onClick={openAddMemberModal}
              >
                <AddUserIcon style={{ height: '1.25rem', width: '1.25rem' }} />
              </IconButton>
            )}
          </ListItemIcon>
        </StackStyled>
      </ListItem>

      <BaseModal title={ADD_TO_ROOM} toggle={openAddMemberFormModal} close={closeAddMemberModal}>
        <AddMember handlerClose={closeAddMemberModal} />
      </BaseModal>
    </>
  );
};

export default DAORoomItem;
