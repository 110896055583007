import { Typography } from '@mui/material';
import { LinearProgressWithLabelProps } from './LinearProgressWithLabe.props';
import {
  LinearProgressContainerStyled,
  LinearProgressContentStackStyled,
  LinearProgressLabelStackStyled,
  LinearProgressOptionTitleStyled,
  LinearProgressStyled
} from './LinearProgressWithLabel.style';

const LinearProgressWithLabel = (props: LinearProgressWithLabelProps): React.ReactElement => {
  const title = props.title;
  const value = props.value || 0;

  return (
    <LinearProgressContainerStyled>
      <LinearProgressLabelStackStyled
        gap={0.5}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <LinearProgressContentStackStyled direction="row" alignItems="center" gap={0.5}>
          <LinearProgressOptionTitleStyled variant="subtitle2" fontWeight={'bold'}>
            {title}
          </LinearProgressOptionTitleStyled>
          {props.selected && (
            <Typography variant="body2" color="text.primary">
              {props.label}
            </Typography>
          )}
        </LinearProgressContentStackStyled>
        <Typography variant="subtitle2" fontWeight={'bold'}>{`${Math.round(value)}%`}</Typography>
      </LinearProgressLabelStackStyled>
      <LinearProgressStyled variant="determinate" {...props} value={value} title={title} />
    </LinearProgressContainerStyled>
  );
};

export default LinearProgressWithLabel;
