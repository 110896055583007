import { PatronageActivityDTO, RedirectActivityUpdatePayload } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { PatronageActivityCreatePayload } from 'model';
import { useCallback } from 'react';
import PatronageActivityService from './use-redirect-patronage-activity-service.model';
import { redirectPatronageActivityUrl } from './use-redirect-patronage-activity-service.url';

const useRedirectPatronageActivityService = (): PatronageActivityService => {
  const { get, post, put, _delete } = useHttp();

  const createRedirectActivity = useCallback(
    (
      daoId: string,
      payload: PatronageActivityCreatePayload
    ): Promise<AxiosResponse<PatronageActivityDTO>> => {
      return post(redirectPatronageActivityUrl(daoId, '-'), payload);
    },
    [post]
  );

  const getRedirectLink = useCallback(
    (daoId: string, patronageActivityId: string, redirectActivityId: string): Promise<any> => {
      return get(
        `${redirectPatronageActivityUrl(daoId, patronageActivityId)}/${redirectActivityId}/links`
      );
    },
    [get]
  );

  const updateRedirectActivity = useCallback(
    (
      activityId: string,
      daoId: string,
      redirectActivityId: string,
      payload: RedirectActivityUpdatePayload
    ): Promise<AxiosResponse<PatronageActivityDTO>> => {
      return put(
        `${redirectPatronageActivityUrl(daoId, activityId)}/${redirectActivityId}`,
        payload
      );
    },
    [put]
  );

  const deleteRedirectActivity = useCallback(
    async (daoId: string, activityId: string, redirectActivityId: string) => {
      return _delete(`${redirectPatronageActivityUrl(daoId, activityId)}/${redirectActivityId}`);
    },
    [_delete]
  );

  return {
    createRedirectActivity,
    updateRedirectActivity: updateRedirectActivity as any,
    deleteRedirectActivity,
    getRedirectLink
  };
};

export default useRedirectPatronageActivityService;
