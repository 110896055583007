import { styled } from '@mui/material/styles';
import Page from 'components/page/Page';

const RootStyled = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const CoopHeaderLayoutContentStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: '30rem',
  padding: '3rem',
  border: '1px solid #ECECEC',
  borderRadius: '0.5rem',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: '3rem 1rem'
  }
}));

export { RootStyled, CoopHeaderLayoutContentStyled };
