import { UserDaoRole } from 'types/enum';

const getCurrentDaoRoleTier = {
  [UserDaoRole.MEMBER]: [UserDaoRole.MEMBER.toString()],
  [UserDaoRole.ADMIN]: [UserDaoRole.MEMBER.toString(), UserDaoRole.ADMIN.toString()],
  [UserDaoRole.OWNER]: [
    UserDaoRole.MEMBER.toString(),
    UserDaoRole.ADMIN.toString(),
    UserDaoRole.OWNER.toString()
  ],
  [UserDaoRole.SYSTEM]: [
    UserDaoRole.MEMBER.toString(),
    UserDaoRole.ADMIN.toString(),
    UserDaoRole.OWNER.toString(),
    UserDaoRole.SYSTEM.toString()
  ]
};

export default getCurrentDaoRoleTier;
