import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

export interface AppNavigationContextType {
  isContentFilterVisible: boolean;
  navigationOpen: boolean;
  userNavExpanded: boolean;
  showDaoNav: boolean;
  setIsContentFilterVisible: (value: boolean) => void;
  setNavigationOpen: (value: boolean, userNavTrigger?: boolean, daoNavTrigger?: boolean) => void;
  setUserNavExpanded: (value: boolean) => void;
  setShowDaoNav: (value: boolean) => void;
}

interface AppNavigationContextProviderProps {
  children: ReactNode;
}

const initialState: AppNavigationContextType = {
  isContentFilterVisible: false,
  navigationOpen: false,
  userNavExpanded: false,
  showDaoNav: false,
  setIsContentFilterVisible: () => {},
  setNavigationOpen: () => {},
  setUserNavExpanded: () => {},
  setShowDaoNav: () => {}
};

const AppNavigationContext = createContext(initialState);

const AppNavigationContextProvider = ({ children }: AppNavigationContextProviderProps): any => {
  const [isContentFilterVisible, setIsContentFilterVisible] = useState<boolean>(
    initialState.isContentFilterVisible
  );

  const [navigationOpen, setNavigationOpen] = useState<boolean>(initialState.navigationOpen);

  const [userNavExpanded, setUserNavExpanded] = useState<boolean>(initialState.userNavExpanded);

  const [showDaoNav, setShowDaoNav] = useState<boolean>(initialState.showDaoNav);

  const updateIsContentFilterVisible = useCallback((value: boolean) => {
    setIsContentFilterVisible(value);
  }, []);

  const updateNavigationOpen = useCallback(
    (value: boolean, userNavTrigger?: boolean, daoNavTrigger?: boolean) => {
      if (value === false) {
        setNavigationOpen(false);
        setUserNavExpanded(false);
        setShowDaoNav(false);
      } else {
        if (!navigationOpen) {
          setNavigationOpen(true);
        }

        if (userNavTrigger !== undefined && userNavTrigger !== userNavExpanded) {
          setUserNavExpanded(userNavTrigger);
        }

        if (daoNavTrigger !== undefined) {
          if (daoNavTrigger) {
            setUserNavExpanded(false);
            setShowDaoNav(true);
          } else {
            setShowDaoNav(false);
          }
        }
      }
    },
    [
      navigationOpen,
      userNavExpanded,
      showDaoNav,
      setNavigationOpen,
      setUserNavExpanded,
      setShowDaoNav
    ]
  );

  const updateUserNavExpanded = useCallback((value: boolean) => {
    setUserNavExpanded(value);
  }, []);

  const updateShowDaoNav = useCallback((value: boolean) => {
    setShowDaoNav(value);
  }, []);

  const providerValue = useMemo(
    () => ({
      isContentFilterVisible,
      navigationOpen,
      userNavExpanded,
      showDaoNav,
      setIsContentFilterVisible: updateIsContentFilterVisible,
      setNavigationOpen: updateNavigationOpen,
      setUserNavExpanded: updateUserNavExpanded,
      setShowDaoNav: updateShowDaoNav
    }),
    [
      isContentFilterVisible,
      navigationOpen,
      userNavExpanded,
      showDaoNav,
      updateIsContentFilterVisible,
      updateNavigationOpen,
      updateUserNavExpanded,
      updateShowDaoNav
    ]
  );

  return (
    <AppNavigationContext.Provider value={providerValue}>{children}</AppNavigationContext.Provider>
  );
};

export default AppNavigationContext;
export { AppNavigationContextProvider };
