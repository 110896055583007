import { Stack, Typography } from '@mui/material';
import React from 'react';
import { UpsideDaoIcon } from 'assets';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MAINTENANCE } from 'constants/ui-labels';

const MaintenancePage = (): React.ReactElement => {
  const { maintenanceModeText } = useFlags();

  const { IN_PROGRESS, PATIENCE } = MAINTENANCE;
  return (
    <Stack alignItems={'center'} height={'100vh'} justifyContent={'center'}>
      <UpsideDaoIcon style={{ height: '5rem', width: '5rem' }} />
      <Stack spacing={2} justifyContent={'center'} alignItems={'center'} marginTop={'2rem'}>
        <Typography variant="h6">{IN_PROGRESS}</Typography>
        <Typography variant="body1">{maintenanceModeText}</Typography>
        <Typography variant="body1">{PATIENCE}</Typography>
      </Stack>
    </Stack>
  );
};

export default MaintenancePage;
