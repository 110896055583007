import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import React, { ReactElement } from 'react';
import { CommentTimelineProps } from './CommentTimeline.props';
import { TimelineItemStyled } from './CommentTimeline.style';

const CommentTimeline = ({
  children,
  toggleShowInteractions
}: CommentTimelineProps): ReactElement => {
  return (
    <Timeline position="right">
      <TimelineItemStyled>
        <TimelineSeparator style={{ flexDirection: 'column' }} onClick={toggleShowInteractions}>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>{children}</TimelineContent>
      </TimelineItemStyled>
    </Timeline>
  );
};

export default CommentTimeline;
