import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Variable(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M4 8.29436L8 5.21592L12 8.29436L16 5L16.8889 5.82353L18.2222 6.64706L20 8.29436V19.0003H4L4 8.29436Z"
        stroke={props.stroke || 'transparent'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'url(#paint0_linear_17379_105)'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 19H20"
        stroke={props.stroke || '#424242'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 8.29413L8 5L12 8.29413L16 5L20 8.29413"
        stroke={props.stroke || '#155EEF'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17379_105"
          x1="12"
          y1="5"
          x2="12"
          y2="19.0003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF4FF" />
          <stop offset="1" stopColor="#EFF4FF" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
