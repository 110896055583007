import React from 'react';
import { SelectProps } from '@mui/material';
import SelectFormStyled from './SelectForm.style';

const SelectForm = React.forwardRef(
  (props: SelectProps & { invalid: boolean }, ref): React.ReactElement => {
    return <SelectFormStyled {...props} inputRef={ref} />;
  }
);

export default SelectForm;
