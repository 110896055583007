import { styled, TextareaAutosize } from '@mui/material';
import { FONT_PRIMARY } from 'theme/typography';

const TextAreaStyled = styled(TextareaAutosize, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'error' && prop !== 'bold'
})<{ error?: boolean; bold?: boolean }>(({ theme, error = false, bold = false }) => ({
  resize: 'none',
  borderRadius: 'inherit',
  border: error
    ? `solid ${theme.palette.error.main} .063rem`
    : `solid ${theme.palette.grey[500]} .063rem`,
  fontFamily: FONT_PRIMARY,
  fontWeight: bold ? 'bold' : 'inherit',
  padding: '.875rem',
  '&:hover': {
    border: `solid ${theme.palette.action.hover} .063rem`
  },
  '&:focus-visible': {
    outline: 'none',
    border: `solid ${theme.palette.action.hover} .063rem`
  }
}));

export default TextAreaStyled;
