import { MenuItem, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ApiKeyDTO } from '@piefi-platform/types-lib';
import { AvatarBuilder, EllipsisMenu } from 'components';
import { useDao } from 'hooks';
import { useState } from 'react';
import { formatDateLong } from 'utils';
import { AvatarSize } from 'types/enum';
import DeactivateApiKeyModal from '../api-key-deactivate-modal/DeactivateApiKeyModal';
import EditApiKeyModal from '../api-key-edit-modal/EditApiKeyModal';
import ApiKeyTableRowProps from './ApiKeyTableRow.props';
import StatusIndicatorStyled from '../../dao-control-panel/patronage-activity-item/patronage-activity-status/PatronageActivityStatus.style';
import { DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';

const ApiKeyTableRow = ({ apiKey }: ApiKeyTableRowProps): React.ReactElement => {
  const { currentDao } = useDao();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentKey, setCurrentKey] = useState(apiKey);
  const { DEACTIVATE, EDIT_DESC } = DEV_DASHBOARD_LABELS;
  const API_KEY_DESC_LENGTH = 45;

  const handleDeleted = (itemDeleted?: boolean) => {
    setShowDeactivateModal(false);
    setCurrentKey({ ...currentKey, isActive: !itemDeleted || false });
  };

  const handleEdit = (updatedKey?: ApiKeyDTO) => {
    setShowEditModal(false);
    if (updatedKey) {
      setCurrentKey({ ...updatedKey });
    }
  };

  const toggleDisableModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  return (
    <>
      <EditApiKeyModal
        onClose={(updatedKey?: ApiKeyDTO) => handleEdit(updatedKey)}
        daoId={currentDao.id}
        apiKey={currentKey}
        isOpen={showEditModal}
      />
      <DeactivateApiKeyModal
        onClose={(itemDeleted?: boolean) => handleDeleted(itemDeleted)}
        daoId={currentDao.id}
        apiKeyId={currentKey.id}
        isOpen={showDeactivateModal}
      />
      <TableRow key={`${currentKey.id}`}>
        <TableCell align="left" style={{ width: '35%' }}>
          <Tooltip title={currentKey.description}>
            <Typography
              variant={'body2'}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '75%'
              }}
            >
              {currentKey.description ? currentKey.description.slice(0, API_KEY_DESC_LENGTH) : ''}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="left" style={{ width: '25%' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <AvatarBuilder
              id={`avatar-${currentKey.id}`}
              src={currentKey.creator?.user.profileThumbnail}
              size={AvatarSize.SMALL}
            />
            <Tooltip
              title={
                !currentKey.creator?.user.firstName
                  ? 'Unknown Unknown indicates this key was created on the legacy system. This key is still active and will work but it is not known who created this key.'
                  : ''
              }
            >
              <Typography variant="caption">
                {currentKey.creator?.user.firstName || 'Unknown'}{' '}
                {currentKey.creator?.user.lastName || 'Unknown'}
              </Typography>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell align="left" style={{ width: '20%' }}>
          <Tooltip
            title={`Last Updated: ${new Date(currentKey.updatedAt).toLocaleString('en-us', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              hour12: true
            })}`}
          >
            <Typography variant="caption" data-testid="date">
              {formatDateLong(new Date(currentKey.createdAt).toDateString())}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell align="left" style={{ width: '20%' }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <StatusIndicatorStyled connected={currentKey.isActive} />
            <Typography variant={'caption'}>
              {currentKey.isActive ? 'active' : 'inactive'}
            </Typography>
            {currentKey.isActive && (
              <EllipsisMenu style={{ marginLeft: '3rem' }}>
                <MenuItem onClick={toggleEditModal}>{EDIT_DESC}</MenuItem>
                <MenuItem onClick={toggleDisableModal}>{DEACTIVATE}</MenuItem>
              </EllipsisMenu>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ApiKeyTableRow;
