import { UserDaoRole } from 'types/enum';

// This list is not ideal for long term and should look for a better solution
const daoRoleTiers = {
  [UserDaoRole.MEMBER]: [
    UserDaoRole.MEMBER.toString(),
    UserDaoRole.ADMIN.toString(),
    UserDaoRole.OWNER.toString(),
    UserDaoRole.SYSTEM.toString()
  ],
  [UserDaoRole.ADMIN]: [
    UserDaoRole.ADMIN.toString(),
    UserDaoRole.OWNER.toString(),
    UserDaoRole.SYSTEM.toString()
  ],
  [UserDaoRole.OWNER]: [UserDaoRole.OWNER.toString(), UserDaoRole.SYSTEM.toString()],
  [UserDaoRole.SYSTEM]: [UserDaoRole.SYSTEM.toString()]
};

/**
 *
 * @description Verifies the roleProvided is at least the role required
 * @param roleRequired {@link UserDaoRole} the minimum role to check required
 * @param roleProvided {@link UserDaoRole} the role to compare to the required
 * @returns {boolean}
 */
const compareDaoRoles = (roleRequired: UserDaoRole, roleProvided: UserDaoRole): boolean => {
  const requiredRoles = daoRoleTiers[roleRequired];
  const providedMeetsRequired = requiredRoles.includes(roleProvided?.toString());
  return providedMeetsRequired;
};

export default compareDaoRoles;
