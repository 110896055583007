import { Box, List, ListItem, listItemTextClasses, styled, Typography } from '@mui/material';

const BoxMainContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  padding: '1.5rem 3rem 3rem 3rem',
  '& [data-icon="proposal"]': {
    '& path': {
      fill: theme.palette.common.black
    }
  }
}));

const ListStyled = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '.75rem',
  '& .MuiListItemIcon-root': {
    marginRight: '0.417rem'
  },
  '& svg': {
    fontSize: '1rem'
  },
  [`& .${listItemTextClasses.root} .${listItemTextClasses.primary}`]: {
    color: `${theme.palette.common.black} `
  },
  '& [data-item="add-proposal"]': {
    padding: '0.3125rem 0.5rem',
    width: 'fit-content',
    cursor: 'pointer',
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
      lineHeight: '1rem'
    }
  }
}));

const OptionListItemStyled = styled(ListItem)(({ theme }) => ({
  padding: '0',
  '& .MuiListItemText-root': {
    alignItems: 'center',
    color: `${theme.palette.text.secondary} !important`,
    display: 'flex',
    height: '100%',
    border: `1px solid ${theme.palette.text.secondary}`,
    paddingLeft: '1rem'
  },
  '& .MuiListItemIcon-root': {
    alignItems: 'center',
    display: 'flex',
    height: '1.5rem',
    justifyContent: 'center',
    marginLeft: '1rem',
    marginRight: '0',
    width: '1.5rem'
  }
}));

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 800
}));

export { BoxMainContainerStyled, ListStyled, OptionListItemStyled, TypographyStyled };
