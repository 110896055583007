export const DAO_INVITE = {
  INVITE: 'Invite',
  MAX_USES_FOR_THIS_INVITE: 'Max uses for this invite:',
  THIS_INVITE_ENDS: 'This invite ends in',
  THIS_INVITE_EXPIRED: 'This invite is expired',
  DAYS: (day: number): string => `${day === 1 ? 'day' : 'days'}`
};

export const DAO_INVITE_URL = {
  PRIMARY_TITLE: 'Invite Your People',
  SECONDARY_TITLE: 'Summon your friends, family, foes - whoever you want.',
  INVITE_BY_EMAIL: 'Invite by email',
  INVITE_BY_LINK: 'Invite by link',
  LINK_EXPIRATION_TIME: (days: string): string => `This link is good for ${days} days`,
  LINK_EDIT_SETTINGS: 'edit link settings'
};

export const DAO_INVITE_SETTINGS = {
  PRIMARY_TITLE: 'Invite Link Settings',
  EXPIRES_AFTER: 'Expires After',
  MAX_USES: 'Max Uses',
  NO_LIMIT: 'No Limit',
  SAVE_AND_UPDATE_LINK: 'Save & Update Link',
  INVITE_UPDATE_SUCCESSFUL: 'Invite updated successfully',
  INVITE_CREATED_ERROR: 'Error trying to create a new invite',
  ERROR_UPDATING_INVITE: 'Error trying to update current invite'
};
