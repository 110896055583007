import { Currency, LanguageDesignatorWithRegion } from 'types/enum';

export const usdFormatter = new Intl.NumberFormat(LanguageDesignatorWithRegion.EnglishUS, {
  style: 'currency',
  currency: Currency.USD
});

export const usdFormatterAbbrev = new Intl.NumberFormat(LanguageDesignatorWithRegion.EnglishUS, {
  style: 'currency',
  currency: Currency.USD,
  minimumFractionDigits: 0
});
