import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HouseFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '21px';
  const height = props.height ? `${props.height}px` : '21px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 21 21" {...props}>
      <path
        d="M20.0363 11.885L19.9963 6.905C19.9963 6.655 19.8663 6.415 19.6563 6.275L16.7163 4.395L13.6763 2.165L10.1363 0.105C9.89633 -0.035 9.60632 -0.035 9.36632 0.105C8.19632 0.815 7.18632 1.465 6.29632 2.065C5.22632 2.785 4.30632 3.455 3.40633 4.165C3.17633 4.345 2.86632 4.525 2.53632 4.705C2.11632 4.945 1.67632 5.185 1.31632 5.505C1.30632 5.505 0.146325 6.625 0.146325 7.265V10.785C0.126325 11.485 0.0963252 12.075 0.0763252 12.625C0.0063252 14.115 -0.0436753 15.295 0.0563247 17.215C0.0563247 17.305 0.0563247 17.445 0.0563247 17.605C0.0263247 18.535 0.0363242 19.185 0.366324 19.585C0.726324 20.015 1.27632 19.985 1.56632 19.965H9.98632C10.5063 19.965 11.0463 19.975 11.5763 19.995C12.1263 20.015 12.6863 20.025 13.2463 20.025C13.8063 20.025 14.3763 20.005 14.9363 19.955L19.2863 19.545C19.6663 19.505 19.9663 19.185 19.9663 18.795V17.735C19.9663 17.035 19.9663 16.335 19.9663 15.635L20.0363 11.865V11.885ZM14.2763 11.835C14.1563 12.935 12.9163 14.195 11.9963 14.725C11.4763 15.025 10.8763 15.155 10.2863 15.285C9.89633 15.365 9.49633 15.455 9.10633 15.395C8.29633 15.285 7.09632 14.485 6.57632 13.875C6.23632 13.485 5.73633 12.705 5.64633 12.195C5.60633 11.975 5.62633 11.755 5.64633 11.535C5.68633 10.985 5.72633 10.445 5.76633 9.895C5.76633 9.775 5.78632 9.665 5.81633 9.555C5.86633 9.395 5.97633 9.255 6.08633 9.115C6.08633 8.855 6.83633 8.345 7.03633 8.215C7.70633 7.745 8.46633 7.405 9.26633 7.245C9.71633 7.155 10.1863 7.125 10.6563 7.175C10.9163 7.195 11.1863 7.245 11.4263 7.345C11.6863 7.455 11.9063 7.625 12.1263 7.795C13.3363 8.735 14.4563 10.185 14.2763 11.835Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
