import { Button } from '@mui/material';
import React from 'react';
import IInputClipboardProps from './InputClipboard.props';
import { BUTTON_LABELS } from 'constants/button.labels';
import { UI_LABELS } from 'constants/ui-labels';
import { useNotification } from 'hooks';
import { TextFieldStyled, InputGroupStyled } from './InputClipboard.style';
import { copyToClipboard } from 'utils';

const InputClipboard = ({ value, backgroundColor }: IInputClipboardProps): React.ReactElement => {
  const { notify } = useNotification();
  const { COPY } = BUTTON_LABELS;
  const { COPIED } = UI_LABELS;
  const { endAdornment } = InputGroupStyled() as any;

  const copyUrl = () => {
    copyToClipboard(value);
    notify(COPIED);
  };

  return (
    <TextFieldStyled
      variant="outlined"
      fullWidth
      value={value}
      sx={{ backgroundColor }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <Button variant="contained" color="primary" onClick={copyUrl}>
            {COPY}
          </Button>
        ),
        classes: {
          adornedEnd: endAdornment
        }
      }}
    />
  );
};

export default InputClipboard;
