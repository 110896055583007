import { Stack, Typography, useTheme } from '@mui/material';
import { BaseCardWrapper, ViewAllButton } from 'components';
import { TitleCardWrapperProps } from './TitleCardWrapper.props';

const TitleCardWrapper = ({
  title,
  borderColor,
  onClick,
  children
}: TitleCardWrapperProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <BaseCardWrapper borderColor={borderColor}>
      <Stack direction={'column'} spacing={4}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          paddingBottom={'1.25rem'}
          borderBottom={`1px solid ${theme.palette.divider}`}
        >
          <Typography variant={'subtitle2'} fontWeight={'bold'}>
            {title}
          </Typography>
          {onClick && (
            <ViewAllButton size={'small'} fontStyles={{ fontWeight: 'bold' }} onClick={onClick} />
          )}
        </Stack>
      </Stack>
      {children}
    </BaseCardWrapper>
  );
};

export default TitleCardWrapper;
