import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Flat(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <rect x="4" y="5" width="16" height="14" fill="url(#paint0_linear_17379_106)" />
      <path
        d="M4 19H20"
        stroke={props.stroke || '#424242'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 5H20"
        stroke={props.stroke || '#155EEF'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_17379_106"
          x1="12"
          y1="5"
          x2="12"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EFF4FF" />
          <stop offset="1" stopColor="#EFF4FF" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
