import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function FolderFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '21px';
  const height = props.height ? `${props.height}px` : '17px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 21 17" {...props}>
      <path
        d="M2.58 11.21V8.18999C2.58 6.66999 3.81 5.43999 5.33 5.43999H8.55L11.92 5.48999L16.63 5.43999L19.33 5.47999C19.75 5.48999 20.14 5.60999 20.48 5.77999C20.45 5.20999 20.37 4.54999 20.02 3.97999C19.64 3.36999 18.98 2.92999 18.18 2.72999C17.77 2.62999 17.31 2.56999 16.78 2.54999C15.71 2.50999 14.11 2.54999 12.7 2.57999C11.91 2.59999 11.16 2.61999 10.61 2.63999L9.17 0.999988C8.23 -0.0600115 7.11 -0.0200116 6.12 0.00998841C5.93 0.00998841 5.73 0.0299884 5.53 0.0199884C4.18 0.0199884 4.1 0.0199884 2.75 0.0599884C2.25 0.0799884 1.33 0.109988 0.690001 0.729988C5.36442e-07 1.40999 0 2.39999 0 2.98999C0 5.43999 0.0400006 7.45999 0.110001 9.33999L0.129999 11.73L0.0900002 13.31C0.0900002 14.15 0.0699999 15.2 0.91 15.98C1.45 16.48 2 16.65 2.64 16.75C2.65 16.75 2.66 16.75 2.67 16.75L2.58 11.19V11.21Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M20.4998 8.41C20.4998 8.36 20.4998 8.31 20.4998 8.25C20.4998 8.25 20.4998 8.24 20.4998 8.23C20.4998 7.55 19.9698 7 19.2898 6.98L16.6198 6.94L11.9098 6.99L8.52982 6.94H5.31982C4.62982 6.94 4.06982 7.5 4.06982 8.19V11.19L4.15982 16.87C4.23982 16.87 4.31982 16.87 4.38982 16.87C4.74982 16.86 5.10983 16.83 5.46983 16.8C5.88983 16.77 6.31982 16.73 6.74982 16.73H13.7398C14.0298 16.73 14.3198 16.73 14.6098 16.74C15.7798 16.77 16.9898 16.79 18.1698 16.44C18.9298 16.21 19.4598 15.86 19.7998 15.37C20.1398 14.89 20.2398 14.33 20.3098 13.85C20.5198 12.32 20.5198 11.89 20.4898 10.3C20.4798 9.67 20.4898 9.04 20.4898 8.4L20.4998 8.41Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
