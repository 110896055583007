import { UserEntityDTO } from '@piefi-platform/types-lib';
import { ModalLayer } from 'components';
import { ProfileTemplate } from 'components/profile';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useAsync } from 'hooks';
import { useUserService } from 'hooks/services';
import React, { useCallback, useState } from 'react';
import UserPublicProfileProps from './UserPublicProfile.props';

const UserPublicProfile = ({ userId, afterClose }: UserPublicProfileProps): React.ReactElement => {
  const [open, setOpen] = useState(true);
  const { getUserById } = useUserService();

  // getting the user public profile info
  const { loading, value: publicUser } = useAsync<UserEntityDTO>(
    useCallback(async (): Promise<UserEntityDTO> => {
      const response = await getUserById(userId);

      if (response.status === HTTP_STATUS.OK) {
        return response.data;
      }
      return {} as UserEntityDTO;
    }, [getUserById, userId])
  );

  const closeModal = () => {
    setOpen(false);
    afterClose();
  };

  return (
    <ModalLayer toggle={open} removePadding afterClose={closeModal}>
      <ProfileTemplate
        isLoading={loading}
        genericProfile={{
          name: `${publicUser?.firstName} ${publicUser?.lastName}`,
          about: publicUser?.profile?.bio ?? '',
          profileThumbnail: publicUser?.profileThumbnail ?? '',
          shortDescription: '',
          socialLinks: publicUser?.profile.socialLinks
        }}
        tabComponents={[]}
      />
    </ModalLayer>
  );
};

export default UserPublicProfile;
