import React, { ReactElement } from 'react';
import BaseContentNavigation from '../base-content-navigation/BaseContentNavigation';
import { BaseContentNavigationProps } from '../base-content-navigation/BaseContentNavigation.props';
import { DesktopContentNavigationContainer } from './DesktopContentNavigation.style';

function DesktopContentNavigation(props: BaseContentNavigationProps): ReactElement {
  return (
    <DesktopContentNavigationContainer>
      <BaseContentNavigation {...props} />
    </DesktopContentNavigationContainer>
  );
}

export default DesktopContentNavigation;
