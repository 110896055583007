import { Box, Divider, Drawer, Slide, Stack, useTheme } from '@mui/material';
import { NavigationItems } from 'components';
import { useCallback } from 'react';
import { useAppNavigation } from '../../hooks';
import { DrawerNavigationProps } from './DrawerNavigation.props';
import CollectiveNavItem from './collective-nav-item/CollectiveNavItem';
import DashboardNavItem from './dashboard-nav-item/DashboardNavItem';
import MenuNavItem from './menu-nav-item/MenuNavItem';
import PortfolioNavItem from './portfolio-nav-item/PortfolioNavItem';

const MobileDrawerNavigation = ({
  activeNavItemName,
  setActiveNavItemName,
  isInDao
}: DrawerNavigationProps): React.ReactElement => {
  const theme = useTheme();
  const { userNavExpanded, navigationOpen, showDaoNav, setNavigationOpen } = useAppNavigation();

  const handleNavCLick = () => {
    // setNavigationOpen(navigationOpen, ?userNavExpanded, ?showDaoNav)
    setNavigationOpen(true, false, true);
  };

  const handleClose = useCallback(() => {
    setNavigationOpen(false);
  }, [setNavigationOpen]);

  return (
    <Drawer
      variant={'temporary'}
      anchor={'left'}
      open={navigationOpen}
      onClose={() => handleClose()}
      ModalProps={{
        onBackdropClick: () => handleClose()
      }}
      sx={{
        '& .MuiDrawer-paper': { width: '85%' }
      }}
    >
      <Stack direction={'row'}>
        <Box
          width={userNavExpanded ? '100%' : '20%'}
          height={'100vh'}
          borderRight={`1px solid ${theme.palette.divider}`}
        >
          <Stack direction={'column'} height={'100%'} justifyContent={'space-between'}>
            <Stack direction={'column'}>
              <DashboardNavItem setActiveNavItemName={setActiveNavItemName} />
              <Divider />
              <PortfolioNavItem setActiveNavItemName={setActiveNavItemName} />
              <Divider />
              <CollectiveNavItem
                setOpenDrawer={handleNavCLick}
                activeNavItemName={activeNavItemName}
                setActiveNavItemName={setActiveNavItemName}
              />
            </Stack>
            <MenuNavItem />
          </Stack>
        </Box>
        {isInDao && (
          <Slide direction={'right'} in={showDaoNav}>
            <Box
              sx={{
                height: '100vh',
                width: isInDao ? '80%' : '0px',
                overflowY: 'auto',
                background: theme.palette.background.paper
              }}
            >
              <NavigationItems />
            </Box>
          </Slide>
        )}
      </Stack>
    </Drawer>
  );
};

export default MobileDrawerNavigation;
