import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TagIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.99871 4.00017C4.80093 4.00017 4.60759 4.05882 4.44314 4.1687C4.27869 4.27859 4.15052 4.43476 4.07483 4.61749C3.99914 4.80022 3.97934 5.00128 4.01792 5.19526C4.05651 5.38925 4.15175 5.56743 4.2916 5.70728C4.43145 5.84713 4.60964 5.94237 4.80362 5.98096C4.9976 6.01954 5.19867 5.99974 5.38139 5.92405C5.56412 5.84837 5.7203 5.72019 5.83018 5.55574C5.94006 5.39129 5.99871 5.19796 5.99871 5.00017C5.99871 4.73496 5.89335 4.4806 5.70582 4.29307C5.51828 4.10553 5.26393 4.00017 4.99871 4.00017V4.00017ZM14.0787 7.14017L8.47204 1.52684C8.40975 1.46505 8.33587 1.41617 8.25465 1.38299C8.17342 1.34982 8.08645 1.333 7.99871 1.33351H1.99871C1.8219 1.33351 1.65233 1.40375 1.5273 1.52877C1.40228 1.65379 1.33204 1.82336 1.33204 2.00017V8.00017C1.33154 8.08791 1.34835 8.17489 1.38153 8.25611C1.4147 8.33734 1.46359 8.41121 1.52538 8.47351L7.13871 14.0802C7.51371 14.4547 8.02204 14.6651 8.55204 14.6651C9.08204 14.6651 9.59038 14.4547 9.96538 14.0802L14.0787 10.0002C14.4532 9.62517 14.6636 9.11684 14.6636 8.58684C14.6636 8.05684 14.4532 7.54851 14.0787 7.17351V7.14017ZM13.1387 9.02017L9.01871 13.1335C8.8938 13.2577 8.72483 13.3274 8.54871 13.3274C8.37259 13.3274 8.20362 13.2577 8.07871 13.1335L2.66538 7.72684V2.66684H7.72538L13.1387 8.08017C13.2005 8.14247 13.2494 8.21635 13.2826 8.29757C13.3157 8.3788 13.3326 8.46577 13.332 8.55351C13.3313 8.7284 13.2619 8.896 13.1387 9.02017V9.02017Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
