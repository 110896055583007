import { Typography } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import {
  ChartFlowFilledIcon,
  ChartFlowIcon,
  HouseFilledIcon,
  HouseIcon,
  NoteFilledIcon,
  NoteIcon,
  UsersFilledIcon,
  UsersIcon
} from 'assets/icons/hero-ui';
import { DAO_COMMON_LABELS, DAO_COMMON_PAGES } from 'constants/dao-common.labels';
import { ROUTES } from 'constants/routes';
import { useDao } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import { StyledNavigationSection } from '../../../base/layout/StyledNavigationSection';
import NavigationItem from '../navigation-item/NavigationItem';
import { NavigationItemProps } from '../navigation-item/NavigationItem.props';
import { CommonsNavigationProps } from './CommonsNavigation.props';

const CommonsNavigation = ({ activePageName }: CommonsNavigationProps): React.ReactElement => {
  const { commonsUpdatesPage, commonsActionsPage, commonsMembersPage } = useFlags();
  const { activeDaoMembership } = useDao();
  const { HOME, UPDATES, ACTIVITIES, MEMBERS } = DAO_COMMON_PAGES;

  const memberCommons = useCallback(
    (dao: DaoMembershipDTO) => {
      const items: NavigationItemProps[] = [
        {
          daoId: dao.daoId,
          activeIcon: <HouseFilledIcon />,
          inActiveIcon: <HouseIcon />,
          name: HOME,
          navId: `commons-${HOME}`,
          url: () => ROUTES.DAO_HOME(dao.daoId)
        } as NavigationItemProps
      ];

      if (commonsUpdatesPage) {
        items.push({
          daoId: dao.daoId,
          activeIcon: <NoteFilledIcon />,
          inActiveIcon: <NoteIcon />,
          name: UPDATES,
          navId: `commons-${UPDATES}`,
          url: () => ROUTES.DAO_UPDATES(dao.daoId)
        } as NavigationItemProps);
      }

      if (commonsActionsPage) {
        items.push({
          daoId: dao.daoId,
          activeIcon: <ChartFlowFilledIcon />,
          inActiveIcon: <ChartFlowIcon />,
          name: ACTIVITIES,
          navId: `commons-${ACTIVITIES}`,
          url: () => ROUTES.DAO_COMMONS_ACTIVITIES(dao.daoId)
        } as NavigationItemProps);
      }

      if (commonsMembersPage) {
        items.push({
          daoId: dao.daoId,
          activeIcon: <UsersFilledIcon />,
          inActiveIcon: <UsersIcon />,
          name: MEMBERS,
          navId: `commons-${MEMBERS}`,
          url: () => ROUTES.DAO_MEMBERS(dao.daoId)
        } as NavigationItemProps);
      }

      return items;
    },
    [HOME, commonsUpdatesPage, commonsActionsPage, commonsMembersPage, UPDATES, ACTIVITIES, MEMBERS]
  );

  const { COMMONS } = DAO_COMMON_LABELS;

  const getCommons = (): NavigationItemProps[] => {
    if (!activeDaoMembership || !activeDaoMembership?.role) {
      return [];
    }

    return [...memberCommons(activeDaoMembership)];
  };

  if (!activeDaoMembership) {
    return <></>;
  }

  return (
    <StyledNavigationSection data-testid="commons-navigation">
      <Typography
        color="text.secondary"
        data-testid="commons-navigation-title"
        fontWeight="bold"
        padding=".25rem .5rem"
        textAlign="right"
        variant="body2"
        sx={{ textAlign: { xs: 'left', md: 'left' } }}
      >
        {COMMONS}
      </Typography>
      {getCommons().map((item) => {
        return (
          <NavigationItem {...item} activePageName={activePageName} key={`commons-${item.name}`} />
        );
      })}
    </StyledNavigationSection>
  );
};

export default CommonsNavigation;
