import {
  BaseQueryParams,
  Document,
  DocumentDTO,
  DocumentSignatureDTO
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useHttp from '../../use-http';
import DaoDocumentService from './use-document-service.model';
import { daoDocumentsUrl } from './use-document-service.url';
import generateQueryString from '../../../utils/helper/query-builder';

const useDaoDocumentService = (): DaoDocumentService => {
  const { get, post, _delete } = useHttp();

  const getDocuments = useCallback(
    (daoId: string, params: BaseQueryParams): Promise<AxiosResponse<DocumentDTO[]>> => {
      return get<DocumentDTO[]>(`${daoDocumentsUrl(daoId)}?${generateQueryString(params)}`);
    },
    [get]
  );

  const getDocument = useCallback(
    (daoId: string, documentId: string): Promise<AxiosResponse<DocumentDTO>> => {
      return get<DocumentDTO>(`${daoDocumentsUrl(daoId)}/${documentId}`);
    },
    [get]
  );

  const createDocument = useCallback(
    (daoId: string, body: Document): Promise<AxiosResponse<DocumentDTO>> => {
      return post<DocumentDTO>(`${daoDocumentsUrl(daoId)}`, body as any);
    },
    [post]
  );

  const createDocumentSignature = useCallback(
    (daoId: string, documentId: string): Promise<AxiosResponse<DocumentSignatureDTO>> => {
      return post<DocumentSignatureDTO>(
        `${daoDocumentsUrl(daoId)}/${documentId}/document-signatures`
      );
    },
    [post]
  );

  const deleteDocument = useCallback(
    (daoId: string, documentId: string): Promise<AxiosResponse<void>> => {
      return _delete<void>(`${daoDocumentsUrl(daoId)}/${documentId}`);
    },
    [_delete]
  );

  return {
    getDocuments,
    createDocument,
    createDocumentSignature,
    deleteDocument,
    getDocument
  };
};

export default useDaoDocumentService;
