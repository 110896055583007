import { Box, BoxProps, Popover, PopoverProps, styled } from '@mui/material';

export const AutoCompleteContainerStyled = styled(Box)(({ theme }) => ({
  display: 'block',
  position: 'relative',
  minHeight: theme.spacing(8),
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  height: '95vh',
  width: '100%',
  '& .MuiAutocomplete-root': {},
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

export const SearchContainer = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

export const PopoverStyled = styled(Popover)<PopoverProps>(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',
    maxWidth: '100%',
    borderRadius: 0,
    top: '0 !important',
    left: '0 !important'
  }
}));
