import { Typography } from '@mui/material';
import React from 'react';
import { IconLabelProps } from './IconLabel.props';

const IconLabel = ({ icon, label }: IconLabelProps): React.ReactElement => {
  return (
    <>
      {icon}
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </>
  );
};

export default IconLabel;
