import { Button, Stack, Typography } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { DaoMemberSelectForm, InputForm } from 'components';
import { ErrorForm } from 'components/labels';
import {
  DAO_ADMIN_LABELS,
  MANUAL_DISTRIBUTION,
  PATRONAGE_ACTIVITY_FORM
} from 'constants/dao-admin-nav.labels';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { numberFormatter } from 'utils';
import { AllocationPayload } from './direct-allocation-wrapper/DirectAllocationWrapper';

interface DirectAllocationFormProps {
  onSubmit: (allocationPayload: AllocationPayload) => void;
  allocationPayload?: AllocationPayload;
}

const DirectAllocationForm = ({
  onSubmit,
  allocationPayload
}: DirectAllocationFormProps): React.ReactElement => {
  const [members, setMembers] = useState<DaoMembershipDTO[]>(allocationPayload?.members || []);
  const {
    control,
    setValue,
    handleSubmit,
    register,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isValid }
  } = useForm<{ amount?: string; memberIds: string[]; activityId: string; memo?: string }>({
    defaultValues: {
      memberIds: allocationPayload?.members ? allocationPayload.members.map((i) => i.id) : [],
      amount: allocationPayload?.amount
        ? `$${Intl.NumberFormat('en-US').format(allocationPayload.amount)}`
        : '$0',
      memo: allocationPayload?.memo || ''
    },
    shouldFocusError: true,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { MIN_MAX_LENGTH } = DAO_ADMIN_LABELS;

  const { POINT_AWARD_VALUE_SECTION } = PATRONAGE_ACTIVITY_FORM;
  const { SEND, TO_SECTION, NOTE_SECTION } = MANUAL_DISTRIBUTION;

  const submit = useCallback(
    async (item: { amount?: string; memberIds: string[]; memo?: string }) => {
      onSubmit({
        amount: +`${item?.amount}`.replace(/,/g, '').replace(/\$/g, ''),
        members,
        memo: item.memo
      });
    },
    [members]
  );

  const handleMemberSelect = useCallback((mems: DaoMembershipDTO[]) => {
    setMembers(mems);
    setValue(
      'memberIds',
      mems.map((i) => i.id)
    );
    if (mems.length === 0) {
      setError('memberIds', { type: 'min', message: 'You must select at least 1 member.' });
    } else {
      clearErrors('memberIds');
    }
  }, []);

  useEffect(() => {
    if (allocationPayload?.members?.length) {
      handleMemberSelect(allocationPayload.members);
    }
  }, [allocationPayload]);

  return (
    <form id={'manual-distribution-form'} onSubmit={handleSubmit(submit)}>
      <Stack direction={'column'} spacing={2}>
        <Controller
          name="amount"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              // Remove any non-numeric characters except the decimal point and commas
              const cleanedValue = value.replace(/[^0-9.]/g, '');

              // Early return if the value exceeds the upper limit
              if (parseFloat(cleanedValue.replace(/,/g, '')) > 999_999) return;

              if (!cleanedValue) {
                // If input is cleared, set it to "$0"
                field.onChange('$0');
                return;
              }

              // Determine if the input ends with a decimal or has incomplete decimals
              const endsWithDecimal = cleanedValue.endsWith('.');
              const decimalSplit = cleanedValue.split('.');
              const hasIncompleteDecimals = decimalSplit.length === 2 && decimalSplit[1].length < 2;

              if (endsWithDecimal || hasIncompleteDecimals) {
                // Format the number but keep it open for further decimal input
                const numberWithoutDecimals = parseFloat(decimalSplit[0].replace(/,/g, ''));
                const formattedNumber =
                  numberFormatter.formatterWithZeroDecimals(numberWithoutDecimals);
                field.onChange(
                  `$${formattedNumber}${endsWithDecimal ? '.' : `.${decimalSplit[1]}`}`
                );
                return;
              }

              // Parse the number from cleanedValue
              const parsedNumber = parseFloat(cleanedValue.replace(/,/g, ''));

              // Format number based on whether it is whole or has decimals
              const isWholeNumber = parsedNumber % 1 === 0;
              const formattedNumber = isWholeNumber
                ? numberFormatter.formatterWithZeroDecimals(parsedNumber)
                : numberFormatter.formatterWithTwoDecimals(parsedNumber);

              // Update the field with the formatted number including the dollar sign
              field.onChange(`$${formattedNumber}`);
            };

            return (
              <InputForm
                sx={{
                  border: 'none'
                }}
                id={field.name}
                {...field}
                InputProps={{
                  sx: {
                    fieldset: {
                      border: 'none'
                    },
                    fontSize: '3rem'
                  }
                }}
                inputProps={{
                  sx: {
                    padding: '2.5rem 0'
                  }
                }}
                onChange={handleNumberChange}
                placeholder={POINT_AWARD_VALUE_SECTION.PLACEHOLDER}
                hiddenLabel
                autoFocus
                fullWidth
                variant="outlined"
                bold={true}
                error={!!fieldState.error}
              />
            );
          }}
        />
        <Stack spacing={0.25}>
          <Typography variant="body2" fontWeight="medium">
            {TO_SECTION.HEADER}
          </Typography>
          <Controller
            name="memberIds"
            control={control}
            render={(_i) => {
              return (
                <DaoMemberSelectForm
                  onMemberListChange={handleMemberSelect}
                  membersSelected={allocationPayload?.members}
                />
              );
            }}
          />
          {errors?.memberIds && <ErrorForm message={(errors?.memberIds as any).message} />}
        </Stack>

        {errors?.amount && <ErrorForm message={errors?.amount.message} />}
        <Stack spacing={0.25}>
          <Typography variant="body2" fontWeight="medium">
            {NOTE_SECTION.HEADER}
          </Typography>
          <Controller
            name="memo"
            control={control}
            render={({ field, fieldState }) => (
              <InputForm
                id={field.name}
                {...field}
                {...register('memo', {
                  minLength: {
                    value: 0,
                    message: MIN_MAX_LENGTH('memo', 'min', 0)
                  },
                  maxLength: {
                    value: 4096,
                    message: MIN_MAX_LENGTH('memo', 'max', 4096)
                  }
                })}
                multiline
                rows={4}
                placeholder={NOTE_SECTION.PLACEHOLDER}
                hiddenLabel
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                error={!!fieldState.error}
                inputProps={{ maxLength: 128 }}
              />
            )}
          />
        </Stack>
        <Stack paddingTop={'2.5rem'}>
          <Button
            variant="contained"
            disabled={!isValid || getValues('memberIds').length === 0}
            type="submit"
            size={'large'}
          >
            <Typography variant={'subtitle2'} fontWeight={'bold'}>
              {SEND}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default DirectAllocationForm;
