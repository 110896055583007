import { ActionsLeaderboardProps } from './ActionsLeaderboard.props';

import { Stack, Typography, useTheme } from '@mui/material';
import { AvatarBuilder } from 'components';
import { useDao } from 'hooks';
import { useRef } from 'react';
import { AvatarSize } from 'types/enum';

const ActionsLeaderboardItem = ({
  memberLeaderboardData,
  isolatedView
}: ActionsLeaderboardProps): React.ReactElement => {
  const { activeDaoMembership } = useDao();
  const theme = useTheme();

  const isCurrentMember = useRef<boolean>(
    activeDaoMembership?.id === memberLeaderboardData.daoMembership.id
  );

  return (
    <Stack
      direction={'row'}
      key={memberLeaderboardData.daoMembership.id}
      justifyContent={'space-between'}
      padding={1}
      width={'98%'}
      marginY={isolatedView ? 0 : 1}
      style={
        isCurrentMember.current
          ? { border: `1px solid ${theme.palette.divider}`, borderRadius: 4 }
          : {}
      }
    >
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Typography variant="body2" fontWeight={'800'} paddingLeft={'0.5rem'}>
          {memberLeaderboardData.ranking}
        </Typography>
        <AvatarBuilder
          id={memberLeaderboardData.daoMembership.id}
          size={AvatarSize.SMALL}
          src={memberLeaderboardData.daoMembership.user.profileThumbnail}
        />
        <Typography variant="body2" fontWeight={'550'}>
          {`${memberLeaderboardData.daoMembership.user.firstName} ${memberLeaderboardData.daoMembership.user.lastName}`}
          {isCurrentMember.current && ' (me)'}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ActionsLeaderboardItem;
