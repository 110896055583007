import { DaoMemberCheckbox } from '../../components/tables/dao-admin/DaoAdminMemberRow.props';

export interface AdminMembershipContextType {
  members: DaoMemberCheckbox[];
  handleMemberDelete: (memberId: string) => void;
  updateMemberList: (mems: DaoMemberCheckbox[]) => void;
  isLastPage: boolean;
  isInMultiSelectState: boolean;
  anchorEl: HTMLElement | null;
  tagManagerEnabled: boolean;
  increasePage: () => void;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  deselectAll: () => void;
  toggleMember: (isSelected: boolean, member: DaoMemberCheckbox) => void;
  refreshList: () => void;
  toggleTagManager: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    element?: HTMLElement | undefined
  ) => void;
}

export const initAdminMembershipContextType = (): AdminMembershipContextType => ({
  members: [],
  isLastPage: false,
  isInMultiSelectState: false,
  refreshList: () => {},
  anchorEl: null,
  tagManagerEnabled: false,
  increasePage: () => {},
  setAnchorEl: () => {},
  deselectAll: () => {},
  updateMemberList: (mems) => {},
  toggleMember: () => {},
  toggleTagManager: () => {},
  handleMemberDelete: (memberId: string) => {}
});
