import { Stack, useTheme } from '@mui/material';
import { curveBasis } from '@visx/curve';
import { AnimatedAreaSeries, AnimatedLineSeries, Tooltip, XYChart } from '@visx/xychart';
import React, { useEffect } from 'react';
import { TimeSeriesChartPoint } from 'types';
import { formatDate } from 'utils';
import { ColorSquare } from '../patronage-activity-selector/PatronageActivitySelector.style';
import { MultiLineAreaChartProps } from './MultilineAreaChart.props';

const MultiLineAreaChart = ({
  areaData,
  childrenData,
  totalCount,
  height,
  width,
  margin
}: MultiLineAreaChartProps): React.ReactElement => {
  const theme = useTheme();
  const [showToolTip, setShowToolTip] = React.useState(true);

  useEffect(() => {
    if (totalCount === 0) setShowToolTip(false);
  }, [totalCount, showToolTip]);

  const accessors = {
    xAccessor: (d: TimeSeriesChartPoint) => d?.date ?? 0,
    yAccessor: (d: TimeSeriesChartPoint) => d.value
  };

  const renderChildren = () => {
    const items = childrenData.map((childrenDataPoints) => (
      <AnimatedLineSeries
        height={height}
        width={width}
        dataKey={childrenDataPoints.key}
        key={childrenDataPoints.key}
        data={childrenDataPoints.data}
        curve={curveBasis}
        colorAccessor={(d) => childrenDataPoints.color || 'white'}
        {...accessors}
      />
    ));

    return items as any;
  };

  return (
    <XYChart
      height={height}
      width={width}
      margin={margin}
      xScale={{ type: 'band' }}
      yScale={{
        type: 'linear'
      }}
    >
      <AnimatedAreaSeries
        dataKey="area"
        data={areaData}
        xAccessor={(d: TimeSeriesChartPoint) => d?.date ?? 0}
        yAccessor={(d: TimeSeriesChartPoint) => d.value}
        curve={curveBasis}
        fillOpacity={childrenData.length > 0 ? 0.4 : 1}
        fill={childrenData.length > 0 ? theme.palette.grey[300] : theme.palette.common.black}
        renderLine={false}
      />
      {renderChildren()}
      {showToolTip && (
        <Tooltip<TimeSeriesChartPoint>
          showVerticalCrosshair
          snapTooltipToDatumX
          snapTooltipToDatumY
          renderTooltip={({ tooltipData }) => {
            const toolTipStrings = Object.entries(tooltipData?.datumByKey || {}).map(
              ([key, value]) => {
                const activityName = childrenData.find((d) => d.key === key);
                return (
                  <Stack direction={'row'} key={key}>
                    <ColorSquare color={activityName?.color || theme.palette.common.black} />
                    <p>
                      {key === 'area' ? 'Aggregate' : activityName?.name}: {value.datum.value}
                    </p>
                  </Stack>
                );
              }
            );

            const toolTipHeader =
              tooltipData?.nearestDatum?.datum &&
              formatDate(new Date(tooltipData?.nearestDatum?.datum.date));

            return (
              <>
                {toolTipHeader}
                <br />
                {toolTipStrings}
              </>
            );
          }}
        />
      )}
    </XYChart>
  );
};

export default MultiLineAreaChart;
