/**
 * Language designators with regions
 *
 * A region designator is a code that represents a country (ISO 3166-1 standard, two-letter, capitalized code).
 * To specify a specific dialect, use a hyphen to combine a language designator with a region designator.
 * For example, to specify common English, use the en language designator as locale.
 * To specify the English language as it is used in the United Kingdom, use en-GB as the locale.
 */
enum LanguageDesignatorWithRegion {
  EnglishUS = 'en-US'
}

export { LanguageDesignatorWithRegion };
