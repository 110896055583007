import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function InternetIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox={'0 0 14 14'}>
      <path
        d="M13.2738 4.76C13.2738 4.76 13.2738 4.76 13.2738 4.72667C12.8041 3.44415 11.9517 2.33686 10.8321 1.55458C9.7125 0.772298 8.37962 0.352783 7.01379 0.352783C5.64796 0.352783 4.31508 0.772298 3.19546 1.55458C2.07585 2.33686 1.22352 3.44415 0.753789 4.72667C0.753789 4.72667 0.753789 4.72667 0.753789 4.76C0.229206 6.20727 0.229206 7.79274 0.753789 9.24C0.753789 9.24 0.753789 9.24 0.753789 9.27334C1.22352 10.5559 2.07585 11.6631 3.19546 12.4454C4.31508 13.2277 5.64796 13.6472 7.01379 13.6472C8.37962 13.6472 9.7125 13.2277 10.8321 12.4454C11.9517 11.6631 12.8041 10.5559 13.2738 9.27334C13.2738 9.27334 13.2738 9.27333 13.2738 9.24C13.7984 7.79274 13.7984 6.20727 13.2738 4.76V4.76ZM1.84046 8.33334C1.60927 7.45948 1.60927 6.54053 1.84046 5.66667H3.08046C2.97381 6.55236 2.97381 7.44765 3.08046 8.33334H1.84046ZM2.38712 9.66667H3.32046C3.47693 10.2612 3.7006 10.836 3.98712 11.38C3.33332 10.9346 2.78679 10.3494 2.38712 9.66667ZM3.32046 4.33334H2.38712C2.78104 3.65273 3.32058 3.06765 3.96712 2.62C3.68749 3.16483 3.47055 3.73962 3.32046 4.33334V4.33334ZM6.33379 12.1333C5.51497 11.5325 4.93989 10.6568 4.71379 9.66667H6.33379V12.1333ZM6.33379 8.33334H4.42712C4.30272 7.4488 4.30272 6.5512 4.42712 5.66667H6.33379V8.33334ZM6.33379 4.33334H4.71379C4.93989 3.34318 5.51497 2.46755 6.33379 1.86667V4.33334ZM11.6138 4.33334H10.6805C10.524 3.73877 10.3003 3.16396 10.0138 2.62C10.6676 3.06538 11.2141 3.65063 11.6138 4.33334ZM7.66712 1.86667C8.48595 2.46755 9.06102 3.34318 9.28712 4.33334H7.66712V1.86667ZM7.66712 12.1333V9.66667H9.28712C9.06102 10.6568 8.48595 11.5325 7.66712 12.1333V12.1333ZM9.57379 8.33334H7.66712V5.66667H9.57379C9.6982 6.5512 9.6982 7.4488 9.57379 8.33334ZM10.0338 11.38C10.3203 10.836 10.544 10.2612 10.7005 9.66667H11.6338C11.2341 10.3494 10.6876 10.9346 10.0338 11.38ZM12.1605 8.33334H10.9205C10.9747 7.89097 11.0014 7.44567 11.0005 7C11.0012 6.55434 10.9745 6.10905 10.9205 5.66667H12.1605C12.3916 6.54053 12.3916 7.45948 12.1605 8.33334Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
