import { Address } from '@piefi-platform/types-lib';
import { googlePlacesConfig } from 'config';
import { GooglePlacesSearch } from 'model';
import { useEffect, useRef, useState } from 'react';

const useGooglePlaces = (): GooglePlacesSearch => {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | undefined>();
  const searchInputRef = useRef<HTMLInputElement>();
  const autoComplete = useRef<google.maps.places.Autocomplete>();
  const {
    API_URL,
    API_SCRIPT_ID,
    PLACE_COMPONENTS: {
      STREET_NUMBER,
      ROUTE,
      POSTAL_CODE,
      LOCALITY,
      ADMINISTRATIVE_AREA_L1,
      COUNTRY
    }
  } = googlePlacesConfig;

  const getAddressFromPlaceResult = (
    place: google.maps.places.PlaceResult | undefined
  ): Address => {
    const address = {} as Address;

    if (!place) {
      return address;
    }

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case STREET_NUMBER: {
          address.line1 = component.long_name;
          break;
        }

        case ROUTE: {
          address.line1 += ` ${component.short_name}`;
          break;
        }

        case POSTAL_CODE: {
          address.postalCode = component.long_name;
          break;
        }

        case LOCALITY:
          address.city = component.long_name;
          break;

        case ADMINISTRATIVE_AREA_L1: {
          address.state = component.short_name;
          break;
        }

        case COUNTRY:
          address.country = component.short_name as 'US' | 'CA';
          break;
      }
    }
    return address;
  };

  const handleLocationSelected = () => {
    const place = autoComplete.current?.getPlace();
    setSelectedPlace(place);
  };

  useEffect(() => {
    const loadScript = (url: string, callback: VoidFunction) => {
      const script = document.createElement('script');
      script.id = API_SCRIPT_ID;
      script.type = 'text/javascript';
      script.onload = () => callback();
      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    };

    const handleScriptLoaded = (): void => {
      if (!searchInputRef.current) {
        return;
      }
      autoComplete.current = new window.google.maps.places.Autocomplete(searchInputRef.current, {
        types: [],
        componentRestrictions: { country: ['us', 'ca'] }
      });

      // we require a line1 property on each address. If that property is not present then do not autocomplete suggestion
      autoComplete.current.addListener('place_changed', () => {
        const place = autoComplete.current?.getPlace();

        // check if 'street_number' is included in the types of the selected place
        const hasStreetNumber = place?.address_components?.some((component) =>
          component.types.includes('street_number')
        );

        if (hasStreetNumber) {
          handleLocationSelected();
        }
      });
    };
    searchInputRef && loadScript(API_URL, () => handleScriptLoaded());

    return () => {
      document.getElementById(API_SCRIPT_ID)?.remove();
      autoComplete.current = undefined;
    };
  }, [searchInputRef, API_URL, API_SCRIPT_ID]);

  return {
    selectedPlace,
    searchInputRef,
    getAddressFromPlaceResult
  };
};

export default useGooglePlaces;
