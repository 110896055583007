import { Stack, Typography } from '@mui/material';
import { UnitAmountIconDisplay } from 'components/texts';
import { AvatarSize } from 'types/enum';
import { usdFormatter } from 'utils';
import AvatarBuilder from '../../avatar/AvatarBuilder';
import BaseCardWrapper from '../base-card-wrapper/BaseCardWrapper';
import { IconValueCardProps } from './IconValueCard.props';

const IconValueCard = ({
  imageDescriptor,
  leadingImageUrl,
  metricConfiguration
}: IconValueCardProps): React.ReactElement => {
  return (
    <BaseCardWrapper>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} width={'100%'} alignItems={'center'}>
          <AvatarBuilder
            id="image-config"
            size={AvatarSize.LARGE}
            src={leadingImageUrl}
            isSquare={true}
          />
          <Typography variant={'body2'} fontWeight={'medium'} paddingLeft={'0.75rem'}>
            {imageDescriptor}
          </Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'flex-end'}>
          <Typography variant={'body2'}>
            {usdFormatter.format(metricConfiguration.dollarAmount)}
          </Typography>
          <UnitAmountIconDisplay amount={metricConfiguration.bitAmount} />
        </Stack>
      </Stack>
    </BaseCardWrapper>
  );
};

export default IconValueCard;
