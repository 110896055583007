import { Stack, Typography } from '@mui/material';
import { ManageableEntityDTO, ManageableEntityQueryParams } from '@piefi-platform/types-lib';
import { CaretMenu, FeedReported } from 'components';
import { ItemRequestRow, ItemRequestSkeleton } from 'components/admin/row';
import { TabContainerTemplate } from 'components/containers';
import { DaoInvite } from 'components/profile';
import { DAO_ADMIN } from 'constants/dao-admin-labels';
import { UI_LABELS } from 'constants/ui-labels';
import { useDao, useRoomMembership } from 'hooks';
import { useDaoService } from 'hooks/services';
import useScrollToTop from 'hooks/use-vertical-scroll';
import { SimpleDropdownOption } from 'model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DaoRequestStatus, ManageableEntityType } from 'types/enum';
import { filterOptions } from 'types/enum/manageable-entity-type.enum';
import { DaoAdminProps } from './DaoAdmin.props';

const DaoAdmin = ({ backActions }: DaoAdminProps): React.ReactElement => {
  const entities = useRef<ManageableEntityDTO[]>([]);
  const { userRoomMemberships } = useRoomMembership();
  const [filteredEntities, setFilteredEntities] = useState<ManageableEntityDTO[]>([]);
  const { currentDao } = useDao();
  const { getManageableEntitiesByDaoId } = useDaoService();
  const [currentPage, setCurrentPage] = useState(0);
  const { scrolledDown, setScrolledDown, scrollRef } = useScrollToTop();
  const [isUpToDate, setIsUpToDate] = useState(false);
  const [showPreviewEntity, setShowPreviewEntity] = useState(false);
  const [entityPreviewSelected, setEntityPreviewSelected] = useState<ManageableEntityDTO>();
  const { FILTER, ITEMS_TO_REVIEW } = DAO_ADMIN;

  const handlePreviewReset = useCallback(
    (entity?: ManageableEntityDTO) => {
      setShowPreviewEntity(false);
      setEntityPreviewSelected(undefined);
      if (entity) {
        setFilteredEntities((prev) => prev.filter((e) => e.id !== entity.id));
      }
      if (!backActions) return;
      const { showBackButton, toggleBackButton } = backActions;
      if (showBackButton && toggleBackButton) toggleBackButton();
    },
    [backActions, setShowPreviewEntity, setEntityPreviewSelected]
  );

  useEffect(() => {
    if (!backActions?.showBackButton) {
      handlePreviewReset();
    }
  }, [backActions, handlePreviewReset]);

  const getEntities = useCallback(async () => {
    if (!currentDao?.id) return;
    const pageSize = 15;
    let recordsCount = 0;
    try {
      const { data } = await getManageableEntitiesByDaoId(currentDao.id, {
        page: currentPage,
        size: pageSize,
        status: DaoRequestStatus.Pending
      } as ManageableEntityQueryParams);

      if (data) {
        recordsCount = data.length || 0;
        entities.current = [...entities.current, ...data];

        filterEntities();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setScrolledDown(false);

      setIsUpToDate(recordsCount < pageSize);

      if (recordsCount === pageSize) setCurrentPage(currentPage + 1);
    }
  }, [
    currentDao,
    getManageableEntitiesByDaoId,
    isUpToDate,
    scrolledDown,
    setScrolledDown,
    currentPage,
    userRoomMemberships
  ]);

  useEffect(() => {
    getEntities();
  }, []);

  useEffect(() => {
    if (!isUpToDate && scrolledDown) {
      getEntities();
    }
  }, [isUpToDate, scrolledDown]);

  const handlePreviewEntity = useCallback(
    (entity: ManageableEntityDTO): void => {
      setShowPreviewEntity(true);
      setEntityPreviewSelected(entity);
    },
    [setShowPreviewEntity, setEntityPreviewSelected]
  );

  /**
   * This method changes the current filter according
   * the entity selected filter
   * @param e to get selected entity
   */

  const handleEntitySubmission = ({ id }: ManageableEntityDTO): void => {
    const unsubmittedFilteredEntities = filteredEntities.filter(
      ({ id: entityId }) => entityId !== id
    );
    setFilteredEntities(unsubmittedFilteredEntities);
  };

  const handleOptionSelected = (option: SimpleDropdownOption) => {
    filterEntities(option.value as ManageableEntityType);
  };

  /**
   * This method applies a filter on the list of entities
   */
  const filterEntities = (option?: ManageableEntityType) => {
    switch (option) {
      case ManageableEntityType.DaoInvites:
        setFilteredEntities(entities.current.filter((entity) => entity.daoInviteEntity));
        break;
      case ManageableEntityType.JoinRequests:
        setFilteredEntities(entities.current.filter((entity) => entity.joinRequestEntity));
        break;
      case ManageableEntityType.Reported:
        setFilteredEntities(entities.current.filter((entity) => entity.reportEntity));
        break;
      default:
        setFilteredEntities(entities.current);
        break;
    }
  };

  const renderEntity = (entity: ManageableEntityDTO, index: number): React.ReactElement => {
    if (entity.joinRequestEntity || entity.reportEntity) {
      let title: string = entity.joinRequestEntity ? UI_LABELS.MEMBERSHIP_REQUEST : '';
      if (entity.reportEntity) {
        title = entity.reportEntity.postId
          ? UI_LABELS.POST_REPORTED
          : entity.reportEntity.commentId
          ? UI_LABELS.COMMENT_REPORTED
          : '';
      }

      return (
        <ItemRequestRow
          key={`join-request-row-${entity.id ?? index}`}
          entity={entity}
          daoId={currentDao.id ?? ''}
          handlePreviewEntity={handlePreviewEntity}
          handleEntitySubmission={handleEntitySubmission}
          title={title}
          backActions={backActions}
        />
      );
    } else if (entity.daoInviteEntity) {
      return <DaoInvite manageableEntity={entity} key={`dao-invite-${entity.id ?? index}`} />;
    }

    return <div key={`row-${index}`}></div>;
  };

  return (
    <>
      {!showPreviewEntity && (
        <TabContainerTemplate
          title={ITEMS_TO_REVIEW}
          rightAction={
            <Stack direction="row">
              <Typography variant="body2" color="textSecondary">
                {FILTER}
              </Typography>
              <CaretMenu options={filterOptions} onOptionSelected={handleOptionSelected} />
            </Stack>
          }
        >
          {!showPreviewEntity && (
            <div id={scrollRef}>
              {filteredEntities.map((entity, index: number) => renderEntity(entity, index))}
              {!isUpToDate && scrolledDown && <ItemRequestSkeleton />}
            </div>
          )}
        </TabContainerTemplate>
      )}

      {showPreviewEntity && entityPreviewSelected && (
        <FeedReported
          entity={entityPreviewSelected}
          handleEntity={handlePreviewEntity}
          handlePreviewReset={handlePreviewReset}
        />
      )}
    </>
  );
};

export default DaoAdmin;
