import { useDao, usePatronageActivityStepper } from 'hooks';
import { ActivityFormStep } from 'model/contexts/patronage-activity-context-type.model';
import PatronageActivityStepperFormWrapper from './PatronageActivityStepperFormWrapper';
import PatronageActivityStepperManualDistributionWrapper from './PatronageActivityStepperManualDistributionWrapper';

const PatronageActivityStepper = (): React.ReactElement => {
  const { currentDao } = useDao();

  const { activeFormStep } = usePatronageActivityStepper();

  if (!currentDao?.id) return <></>;

  if (activeFormStep === ActivityFormStep.MANUAL_ALLOCATION)
    return <PatronageActivityStepperManualDistributionWrapper />;

  return <PatronageActivityStepperFormWrapper />;
};

export default PatronageActivityStepper;
