import {
  DaoMembershipLeaderboardDTO,
  DaoMembershipLeaderboardMemberViewDTO,
  DaoMembershipProfileDTO,
  TagDTO
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useHttp } from 'hooks';
import { useCallback } from 'react';
import DaoMembershipService from './use-dao-membership-service.model';
import { daoMemberUrl } from './use-dao-membership-service.url';

const useDaoMembershipService = (): DaoMembershipService => {
  const { get, post, patch } = useHttp();

  const getDaoMembershipById = useCallback(
    (daoId: string, memberId: string): Promise<AxiosResponse<DaoMembershipProfileDTO>> => {
      return get<DaoMembershipProfileDTO>(`${daoMemberUrl(daoId)}/${memberId}`);
    },
    [get]
  );

  const getActionsLeaderboard = useCallback(
    async (daoId: string, page: number, size: number, seasonId: string, tagId?: string) => {
      return get<DaoMembershipLeaderboardMemberViewDTO>(
        `${daoMemberUrl(
          daoId
        )}/leaderboard/personal?page=${page}&size=${size}&seasonId=${seasonId}${
          tagId ? `&tagId=${tagId}` : ''
        }`
      );
    },
    [get]
  );

  const getLeaderboard = useCallback(
    async (
      daoId: string,
      page: number,
      size: number,
      seasonId: string
    ): Promise<AxiosResponse<DaoMembershipLeaderboardDTO[]>> => {
      return get<DaoMembershipLeaderboardDTO[]>(
        `${daoMemberUrl(daoId)}/leaderboard?page=${page}&size=${size}&seasonId=${seasonId}`
      );
    },
    [get]
  );

  const createAndAttachTag = useCallback(
    (memberIds: string[], daoId: string, tag: Partial<TagDTO>) => {
      // if the tag has an id only pass the id otherwise pass the name and color and the tag will get created
      const tagData = tag.id ? { id: tag.id } : { name: tag.name, color: tag.color };

      return post(`${daoMemberUrl(daoId)}/tags`, {
        ids: memberIds,
        tagData
      });
    },
    [post]
  );

  const detachTag = useCallback(
    (memberIds: string[], daoId: string, tagId: string) => {
      return patch(`${daoMemberUrl(daoId)}/tags`, {
        ids: memberIds,
        tagData: {
          id: tagId
        }
      });
    },
    [patch]
  );

  return {
    getDaoMembershipById,
    getLeaderboard,
    createAndAttachTag,
    detachTag,
    getActionsLeaderboard
  };
};

export default useDaoMembershipService;
