import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

interface DescriptorInputProps {
  title: string;
  description: string;
  children: React.ReactNode;
  showOptionalText?: boolean;
}
const DescriptorInput = ({
  children,
  description,
  title,
  showOptionalText
}: DescriptorInputProps): React.ReactElement => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <Stack spacing={0} paddingRight={'1rem'}>
          <Stack direction={'row'} spacing={1}>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {title}
            </Typography>
            {showOptionalText && (
              <Typography variant={'body2'} fontWeight={'bold'}>
                (optional)
              </Typography>
            )}
          </Stack>
          <Typography variant={'body2'} sx={{ paddingBottom: { md: '1rem', lg: 'unset' } }}>
            {description}
          </Typography>{' '}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        {children}
      </Grid>
    </Grid>
  );
};

export default DescriptorInput;
