import { Button, ButtonProps, styled } from '@mui/material';

export const StyledRedButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme?.palette?.brand?.red?.primary ?? ''}`,
  color: theme.palette.text.primary,
  width: '100%',
  fontWeight: 700,
  fontSize: '1rem',
  lineHeight: '1.625rem',
  padding: '0.6875rem',
  minHeight: '2.375rem',
  height: 'auto',
  whiteSpace: 'normal',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme?.palette?.brand?.red?.primary ?? '',
    color: theme.palette.common.white
  }
}));
