import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export interface IScrollToTopProps {
  scrollRef: string;
  scrolledDown: boolean;
  offset: number;
  setScrolledDown: (value: boolean) => void;
  setScrollRef: (value: string) => void;
  setOffset: (value: number) => void;
  onScroll: VoidFunction;
}

const initialState: IScrollToTopProps = {
  scrollRef: 'scroll-ref-container',
  scrolledDown: false,
  offset: 0,
  setScrolledDown: () => {},
  setScrollRef: () => {},
  setOffset: () => {},
  onScroll: () => {}
};

const VerticalScrollContext = createContext(initialState);

interface VerticalScrollProviderProps {
  children: ReactNode;
}
const VerticalScrollProvider = ({ children }: VerticalScrollProviderProps): React.ReactElement => {
  const [scrolledDown, setScrolledDown] = useState(initialState.scrolledDown);
  const [offset, setOffset] = useState(initialState.offset);
  const [scrollRef, setScrollRef] = useState(initialState.scrollRef);

  const isBottom = (element: Element) => {
    return element && element.getBoundingClientRect().bottom <= window.innerHeight;
  };

  const onScroll = useCallback(() => {
    const element = document.getElementById(scrollRef);

    if (!scrolledDown && isBottom(element!!)) {
      setScrolledDown(true);
      setOffset(document.documentElement.scrollHeight);
    }
  }, [scrollRef, scrolledDown]);

  useEffect(() => {
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollRef, scrolledDown, onScroll]);

  const updateScrollDown = useCallback((value: boolean): void => {
    setScrolledDown(value);
  }, []);

  const updateScrollRef = useCallback((value: string): void => {
    setScrollRef(value);
  }, []);

  const updateOffset = useCallback((value: number): void => {
    setOffset(value);
  }, []);

  return (
    <VerticalScrollContext.Provider
      value={{
        scrollRef,
        scrolledDown,
        offset,
        setScrollRef: updateScrollRef,
        setScrolledDown: updateScrollDown,
        setOffset: updateOffset,
        onScroll
      }}
    >
      {children}
    </VerticalScrollContext.Provider>
  );
};

export default VerticalScrollProvider;
export { VerticalScrollContext };
