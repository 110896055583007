const postUrl = (daoId: string, roomId: string): string =>
  `${process.env.REACT_APP_BASE_URL}/daos/${daoId}/rooms/${roomId}/posts`;

const postProposalUrl = (daoId: string, roomId: string): string =>
  `${postUrl(daoId, roomId)}/-/proposals`;

const putProposalUrl = (daoId: string, roomId: string, postId: string): string =>
  `${postUrl(daoId, roomId)}/${postId}/proposals`;

const postBountyUrl = (daoId: string, roomId: string): string =>
  `${postUrl(daoId, roomId)}/-/bounties`;

const putBountyUrl = (daoId: string, roomId: string, postId: string, bountyId: string): string =>
  `${postUrl(daoId, roomId)}/${postId}/bounties/${bountyId}`;

const commentsUrl = (daoId: string, roomId: string, postId: string): string =>
  `${postUrl(daoId, roomId)}/${postId}/comments`;

const deletePostUrl = (daoId: string, roomId: string, postId: string): string =>
  `${postUrl(daoId, roomId)}/${postId}`;

const deleteProposalUrl = (
  daoId: string,
  roomId: string,
  postId: string,
  proposalId: string
): string => `${postUrl(daoId, roomId)}/${postId}/proposals/${proposalId}`;

export {
  postUrl,
  postProposalUrl,
  postBountyUrl,
  putBountyUrl,
  commentsUrl,
  putProposalUrl,
  deletePostUrl,
  deleteProposalUrl
};
