import { LinearProgress, linearProgressClasses, Stack, styled, Typography } from '@mui/material';

export const LinearProgressContainerStyled = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  width: '100%'
}));

export const LinearProgressStyled = styled(LinearProgress)<{ value: number }>(
  ({ theme, value }) => ({
    '&.MuiLinearProgress-root': {
      width: '100%',
      borderRadius: 0,
      height: '3rem',
      backgroundColor: 'transparent',
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: theme.palette.brand.red[200],
        borderRadius: 0
      }
    }
  })
);

export const LinearProgressLabelStackStyled = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  zIndex: 1,
  padding: '.6875rem 1rem'
}));

export const LinearProgressOptionTitleStyled = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

export const LinearProgressContentStackStyled = styled(Stack)(({ theme }) => ({
  position: 'relative',
  maxWidth: '75%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '90%'
  }
}));
