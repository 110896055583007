import { Stack, Typography, useTheme } from '@mui/material';
import CountUp from 'react-countup';
import { InfoBoxStructureProps } from './InfoBoxStructure.props';

const InfoBoxStructure = ({
  title,
  subTitle,
  icon,
  prefix,
  value
}: InfoBoxStructureProps): React.ReactElement => {
  const theme = useTheme();
  const COUNT_DURATION = 1;

  return (
    <Stack
      direction="column"
      padding="1rem 1rem 2rem"
      border={`1px solid ${theme.palette.divider}`}
      borderRadius={'0.1875rem'}
      height={'100%'}
    >
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" fontWeight={800} paddingBottom={'0.5rem'}>
          {title}
        </Typography>
        <Typography variant="body2" color={theme.palette.secondary.main}>
          {subTitle}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0.75} alignItems="center" paddingTop="0.25rem">
        {icon}
        <CountUp
          start={0}
          end={value}
          duration={COUNT_DURATION}
          separator={','}
          prefix={prefix || ''}
        >
          {({ countUpRef }) => (
            <Typography
              ref={countUpRef}
              variant="h6"
              fontWeight={800}
              data-testid="user-profile-info-box-value"
            />
          )}
        </CountUp>
      </Stack>
    </Stack>
  );
};

export default InfoBoxStructure;
