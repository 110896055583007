import { TotalEquityAllocationDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useHttp from '../../use-http';
import { daoUrl } from '../dao-service/use-dao-service.url';
import EquityAllocationService from './use-equity-allocation-service.model';

const useEquityAllocationService = (): EquityAllocationService => {
  const { get, post } = useHttp();

  const manageAllocationsRequest = useCallback(
    (daoId: string): Promise<AxiosResponse> => {
      return post(`${daoUrl}/${daoId}/equity-allocations/requests`);
    },
    [post]
  );

  const getTotalBitCountByDao = useCallback(
    (daoId: string): Promise<AxiosResponse<TotalEquityAllocationDTO>> => {
      return get<TotalEquityAllocationDTO>(`${daoUrl}/${daoId}/equity-allocations/statistics`);
    },
    [get]
  );

  return {
    getTotalBitCountByDao,
    manageAllocationsRequest
  };
};

export default useEquityAllocationService;
