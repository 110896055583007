import { IconButton, styled } from '@mui/material';

export const SideBarIconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: 'transparent',
  padding: theme.spacing(0.5),
  '&:focus-visible': {
    visibility: 'visible',
    opacity: 1
  },
  '& .MuiSvgIcon-root': {
    height: '1.25rem',
    width: '1.25rem'
  }
}));
