import { Button, Stack, Typography } from '@mui/material';
import { DaoInviteCreateDTO } from '@piefi-platform/types-lib';
import { DaoRoleSelector, InputClipboard } from 'components';
import { MultiEmailInput } from 'components/inputs';
import BaseModal from 'components/modals';
import { DaoInviteForm } from 'components/profile';
import { BUTTON_LABELS } from 'constants/button.labels';
import { DAO_UPDATE_PERMISSIONS } from 'constants/dao-admin-labels';
import { DAO_INVITE_URL } from 'constants/dao-invite-labels';
import { useAsync, useNotification } from 'hooks';
import { useDaoInviteService } from 'hooks/services';
import React, { useCallback, useEffect, useState } from 'react';
import { Severity, UserDaoRole } from 'types/enum';
import IDaoInviteUrlProps from './DaoInviteUrl.props';
import { StyledLink } from './DaoInviteUrl.style';

const DaoInviteUrl = ({
  daoId,
  close,
  onInvitationsCreated
}: IDaoInviteUrlProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [invitationLink, setInvitationLink] = useState('');
  const [expirationDays, setExpirationDays] = useState(7);
  const { createDaoInvite } = useDaoInviteService();
  const { PRIMARY_TITLE, SECONDARY_TITLE, INVITE_BY_EMAIL, INVITE_BY_LINK, LINK_EXPIRATION_TIME } =
    DAO_INVITE_URL;
  const { TEMPORARY_INVITATION } = DAO_UPDATE_PERMISSIONS;
  const { DONE, SEND_INVITE } = BUTTON_LABELS;
  const { LINK_EDIT_SETTINGS } = DAO_INVITE_URL;
  const [emailList, setEmailList] = useState<string[]>([]);
  const [roleOnCreate, setRoleOnCreate] = useState<UserDaoRole>(UserDaoRole.MEMBER);
  const { notify } = useNotification();

  const createNewInvite = useCallback(async (): Promise<DaoInviteCreateDTO> => {
    const defaultExpirationDays = 7;
    const noLimit = 0;
    const endDate = new Date();

    endDate.setDate(endDate.getDate() + defaultExpirationDays);
    const response = await createDaoInvite(daoId, {
      endDate,
      maxInvites: noLimit
    });
    return response.data;
  }, [createDaoInvite, daoId]);

  const sendInviteToEmails = useCallback(async () => {
    const defaultExpirationDays = 7;
    const noLimit = 0;
    const endDate = new Date();

    endDate.setDate(endDate.getDate() + defaultExpirationDays);

    try {
      setLoading(true);
      await createDaoInvite(daoId, {
        endDate,
        maxInvites: noLimit,
        temporaryInvitations: emailList.map((email) => ({
          email,
          roleOnCreate: roleOnCreate
        }))
      });
      notify(TEMPORARY_INVITATION.INVITATIONS_SENT);
      onInvitationsCreated();
      close();
    } catch (error) {
      notify(TEMPORARY_INVITATION.ERROR_SENDING, { severity: Severity.Error });
    } finally {
      setLoading(false);
    }
  }, [createDaoInvite, daoId, emailList, roleOnCreate, close, notify]);

  const { value: newInvite } = useAsync<DaoInviteCreateDTO>(createNewInvite);

  useEffect(() => {
    if (
      newInvite?.manageableEntity?.id &&
      newInvite?.inviteLinks &&
      newInvite?.inviteLinks.length > 0
    ) {
      setInvitationLink(newInvite?.inviteLinks[0].inviteUrl);
    }
  }, [newInvite, daoId]);

  const updateLinkDays = (days: number, _: any) => {
    setExpirationDays(days);
  };

  const closeModal = () => {
    if (emailList.length > 0) {
      sendInviteToEmails();
    }
    setOpen(false);
    close();
  };

  return (
    <>
      <DaoInviteForm
        daoId={daoId}
        currentInvite={newInvite?.manageableEntity}
        updateInviteLink={updateLinkDays}
        open={openEditModal}
        setOpen={setOpenEditModal}
      />
      <BaseModal title={PRIMARY_TITLE} toggle={open} close={close} width={600}>
        <Stack direction="column" spacing={3} sx={{ marginTop: '1rem' }}>
          <Typography variant="caption" style={{ fontSize: '0.781rem' }}>
            {SECONDARY_TITLE}
          </Typography>
          <Stack>
            <Typography variant="body1" style={{ fontSize: '0.781rem' }}>
              {INVITE_BY_LINK}
            </Typography>
            <InputClipboard value={invitationLink} />
            <Stack direction="row" spacing={1}>
              <Typography variant="caption">
                {LINK_EXPIRATION_TIME(expirationDays.toString())}
              </Typography>
              <StyledLink
                component="button"
                variant="body2"
                underline="none"
                onClick={() => setOpenEditModal(true)}
              >
                {LINK_EDIT_SETTINGS}
              </StyledLink>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body1" style={{ fontSize: '0.781rem' }}>
              {INVITE_BY_EMAIL}
            </Typography>
            <Stack direction={'row'} justifyContent={'center'}>
              <Stack direction={'row'} style={{ width: '100%' }}>
                <MultiEmailInput
                  onEmailListChange={(emails) => setEmailList((prev) => emails)}
                  placeholder="emails"
                  type="email"
                />
                <DaoRoleSelector onRoleSelected={(role) => setRoleOnCreate(role)} removeBorder />
              </Stack>
              <Button
                variant="contained"
                color="primary"
                size="extraLarge"
                disabled={emailList.length === 0 || loading}
                onClick={() => sendInviteToEmails()}
              >
                {SEND_INVITE}
              </Button>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            size="extraLarge"
            sx={{ width: '4.563rem', marginTop: '3.125rem !important' }}
            onClick={closeModal}
          >
            {DONE}
          </Button>
        </Stack>
      </BaseModal>
    </>
  );
};

export default DaoInviteUrl;
