import { SnackbarOrigin } from '@mui/material';

export enum Position {
  TopLeft = 'top|left',
  TopCenter = 'top|center',
  TopRight = 'top|right',
  BottomLeft = 'bottom|left',
  BottomCenter = 'bottom|center',
  BottomRight = 'bottom|right'
}

const placeToast = (toast: Position | undefined): SnackbarOrigin => {
  const [vertical, horizontal] = toast ? toast.split('|') : Position.BottomCenter.split('|');
  return { vertical, horizontal } as SnackbarOrigin;
};

export default Position;
export { placeToast };
