import {
  Box,
  Button,
  debounce,
  FormControl,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Address, UserUpdate } from '@piefi-platform/types-lib';
import { LogoutIcon } from 'assets';
import {
  AvatarBuilder,
  InnerPageContentBox,
  InputForm,
  InputFormLocation,
  ValidationInputForm
} from 'components';
import { LabelForm } from 'components/labels';
import { INPUT_VALIDATION, SEARCH_PARAMS } from 'constants/app-config';
import { COMPLETE_PROFILE, LOGIN } from 'constants/auth.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import {
  discordHelperText,
  discordLink,
  discordLinkRegex,
  discordLinkRegexString,
  instagramLink,
  instagramLinkHelperText,
  linkedInLink,
  linkedInLinkHelperText,
  linkedInLinkRegex,
  linkedInLinkRegexString,
  socialLinksRegex,
  socialLinksRegexString,
  twitterLink,
  twitterLinkHelperText
} from 'constants/social-links.constants';
import { SETUP_TABS, UI_LABELS } from 'constants/ui-labels';
import { useAuth, useGooglePlaces, useNotification } from 'hooks';
import { useUserService } from 'hooks/services';
import useUser from 'hooks/use-user';
import { UserProfileParam } from 'model/user';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { AvatarSize, Severity } from 'types/enum';
import { uploadImage } from 'utils/helper/image-uploader';
import { ContainerProfile, FileButton } from './ProfileEdit.style';

const ProfileEdit = (): React.ReactElement => {
  // regex to validate twitter, linkedin and website url
  const [user, setUser] = useState<UserUpdate>();
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, fetchAndSetUser } = useUser();
  const { selectedPlace, searchInputRef, getAddressFromPlaceResult } = useGooglePlaces();
  const { notify } = useNotification();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { updateUser, getUsers } = useUserService();
  const { logOut } = useAuth();
  const [isTwitterLinkValid, setIsTwitterLinkValid] = useState(true);
  const [isLinkedInLinkValid, setIsLinkedInLinkValid] = useState(true);
  const [isInstagramLinkValid, setIsInstagramLinkValid] = useState(true);
  const [isDiscordLinkValid, setIsDiscordLinkValid] = useState(true);
  const invalidUserNameRegex = /[^a-z0-9_-]*$/gi;

  const {
    ACCOUNT,
    CHANGE_AVATAR,
    FIRST_NAME,
    LAST_NAME,
    PROFILE_BIO,
    TWITTER,
    LINKEDIN,
    WEBSITE,
    DISCORD,
    SOCIAL_LINKS,
    PLEASE_INCLUDE_ENTIRE_LINK,
    FILL_OUT_MISSING_INFO,
    INVALID_IMAGE_ERROR,
    USERNAME,
    USERNAME_UNAVAILABLE,
    USERNAME_AVAILABLE,
    ADDRESS,
    INSTAGRAM,
    INVALID_ADDRESS,
    INVALID_TWITTER_HANDLE,
    INVALID_LINKEDIN_LINK,
    INVALID_INSTAGRAM_HANDLE,
    INVALID_DISCORD_USERNAME
  } = COMPLETE_PROFILE;
  const { MAX_LENGTH, MIN_LENGTH } = COMMON_VALIDATIONS;
  const { MAX_SHORT_TEXT_LENGTH, MIN_SHORT_TEXT_LENGTH, MIN_NAME_LENGTH, MAX_LARGE_TEXT_LENGTH } =
    INPUT_VALIDATION;
  const { LOGOUT } = LOGIN;
  const { SAVE, SAVED } = UI_LABELS;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const defaultValues = useMemo<DefaultValues<UserProfileParam>>(
    () => ({
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      username: userInfo?.username || '',
      address: userInfo?.address
        ? `${userInfo?.address?.line1}${
            userInfo?.address?.line2 ? ` ${userInfo?.address?.line2}` : ''
          } ${userInfo?.address?.city} ${userInfo?.address?.state} ${userInfo?.address?.country} ${
            userInfo?.address?.postalCode
          }`
        : '',
      bio: userInfo?.profile.bio || '',
      twitterLink: userInfo?.profile.socialLinks?.twitterLink || '',
      linkedInLink: userInfo?.profile.socialLinks?.linkedInLink || '',
      webLink: userInfo?.profile.socialLinks?.webLink || '',
      profileThumbnail: userInfo?.profileThumbnail,
      discordLink: userInfo?.profile.socialLinks?.discordLink || '',
      instagramLink: userInfo?.profile.socialLinks?.instagramLink || '',
      birthdate: userInfo?.birthdate
    }),
    [userInfo]
  );

  const hookFormEditProps = useForm<UserProfileParam>({
    defaultValues,
    shouldFocusError: true,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    getValues,
    watch,
    clearErrors,
    formState: { isDirty, isSubmitting, errors, isValid }
  } = hookFormEditProps;

  const handleAddressChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue('address', e.target.value);
      if (e.target.value !== selectedPlace?.formatted_address) {
        setError('isAddressValid', { type: 'value', message: INVALID_ADDRESS });
        setValue('isAddressValid', false);
      }
    },
    [INVALID_ADDRESS, selectedPlace, setError, setValue]
  );
  const logout = () => {
    try {
      setIsLoading(true);
      logOut();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCaptureImg = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    event.preventDefault();
    const { target } = event;
    if (target?.files?.length) {
      const file = target?.files[0];
      if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type)) {
        const fileReader = new FileReader();
        setFile(file);
        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
          onChange(e.target?.result as string);
        };
      } else {
        notify(INVALID_IMAGE_ERROR, { severity: Severity.Error });
      }
    }
  };

  const validateUserName = React.useMemo(
    () =>
      debounce(async (username: string) => {
        try {
          const response = await getUsers(username, true, { page: 0, size: 1 });

          if (response.data.length && username !== userInfo?.username) {
            setError('isUsernameValid', {
              type: 'value',
              message: USERNAME_UNAVAILABLE
            });
          } else {
            setValue('isUsernameValid', true);
            clearErrors('isUsernameValid');
          }
          setIsProcessing(false);
        } catch (error) {
          console.error(error);
        }
      }, SEARCH_PARAMS.SEARCH_DELAY_MS), // wait small amount of time before sending search request
    [clearErrors, setError, setValue, getUsers, setIsProcessing, USERNAME_UNAVAILABLE, userInfo]
  );

  const handleUsernameChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const userName = event.target.value.replace(invalidUserNameRegex, '')?.toLowerCase();
    setValue('username', userName);
    setValue('isUsernameValid', false);

    if (userName.length >= MIN_SHORT_TEXT_LENGTH) {
      setIsProcessing(true);
      validateUserName(userName);
    }
  };

  const handleSocialChanged = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    socialChanged: string
  ) => {
    const input = event.target.value;
    switch (socialChanged) {
      case twitterLink:
        setIsTwitterLinkValid(!input || socialLinksRegex.test(input));
        break;
      case linkedInLink:
        setIsLinkedInLinkValid(!input || linkedInLinkRegex.test(input));
        break;
      case instagramLink:
        setIsInstagramLinkValid(!input || socialLinksRegex.test(input));
        break;
      case discordLink:
        setIsDiscordLinkValid(!input || discordLinkRegex.test(input));
        break;
      default:
    }
  };

  useEffect(() => {
    setUser({
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      username: userInfo?.username,
      address: userInfo?.address as Address,
      bio: userInfo?.profile.bio,
      twitterLink: userInfo?.profile.socialLinks?.twitterLink,
      linkedInLink: userInfo?.profile.socialLinks?.linkedInLink,
      webLink: userInfo?.profile.socialLinks?.webLink,
      profileThumbnail: userInfo?.profileThumbnail,
      discordLink: userInfo?.profile.socialLinks?.discordLink,
      instagramLink: userInfo?.profile.socialLinks?.instagramLink,
      birthdate: userInfo?.birthdate
    });
    reset(defaultValues);
  }, [defaultValues, reset, userInfo]);

  useEffect(() => {
    // Assign formatted address text to address input when place is selected
    if (selectedPlace) {
      setValue('address', selectedPlace.formatted_address || '');
      setValue('isAddressValid', true);
      clearErrors('isAddressValid');
    }
  }, [selectedPlace, setValue, clearErrors]);

  const submitChanges: SubmitHandler<UserProfileParam> = async (updatedUser) => {
    try {
      setIsLoading(true);

      if (updatedUser.twitterLink && !socialLinksRegex.test(updatedUser.twitterLink)) {
        throw new Error(INVALID_TWITTER_HANDLE);
      }

      if (updatedUser.linkedInLink && !linkedInLinkRegex.test(updatedUser.linkedInLink)) {
        throw new Error(INVALID_LINKEDIN_LINK);
      }

      if (updatedUser.instagramLink && !socialLinksRegex.test(updatedUser.instagramLink)) {
        throw new Error(INVALID_INSTAGRAM_HANDLE);
      }

      if (updatedUser.discordLink && !discordLinkRegex.test(updatedUser.discordLink)) {
        throw new Error(INVALID_DISCORD_USERNAME);
      }

      if (!updatedUser || !userInfo?.id) return;
      const profileThumbnail =
        file && (await uploadImage(`users/${userInfo?.id}/profile-image`, file));

      let placeAddress: Address | null = null;
      if (selectedPlace) placeAddress = getAddressFromPlaceResult(selectedPlace);

      const userUpdateValue: UserUpdate = {
        bio: updatedUser.bio || undefined,
        twitterLink: updatedUser.twitterLink || (null as any),
        linkedInLink: updatedUser.linkedInLink || (null as any),
        webLink: updatedUser.webLink || (null as any),
        profileThumbnail,
        firstName: updatedUser.firstName || undefined,
        lastName: updatedUser.lastName || undefined,
        username: updatedUser.username || undefined,
        discordLink: updatedUser.discordLink || (null as any),
        instagramLink: updatedUser.instagramLink || (null as any),
        birthdate: updatedUser.birthdate || undefined,
        ...(placeAddress && { address: placeAddress })
      };

      await updateUser(userUpdateValue);
      const showLoadingState = false;
      const initiateCascadeLoading = false;
      await fetchAndSetUser(showLoadingState, initiateCascadeLoading);
      notify(SAVED, { severity: Severity.Success });
      setUser(userUpdateValue);
    } catch (error: any) {
      console.error({ error });
      notify(
        error.response.data.message ||
          error.response?.message ||
          error?.message ||
          'Error updating User',
        {
          severity: Severity.Error
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <InnerPageContentBox title={SETUP_TABS.PROFILE}>
      <ContainerProfile>
        <form onSubmit={handleSubmit(submitChanges)}>
          <Box>
            <Controller
              control={control}
              name="profileThumbnail"
              render={({ field: { value, onChange } }) => (
                <Stack
                  direction={'row'}
                  spacing={'1.5rem'}
                  alignItems={'center'}
                  style={{ marginTop: '1.5rem' }}
                >
                  <AvatarBuilder
                    src={value}
                    alt={`${user?.firstName} avatar`}
                    id={`${user?.firstName}-avatar`}
                    size={AvatarSize.X_LARGE}
                  />

                  <FileButton variant="contained" component="label">
                    {CHANGE_AVATAR}
                    <input
                      hidden
                      accept="image/png, image/jpg, image/jpeg, image/gif"
                      multiple
                      type="file"
                      onChange={(e) => handleCaptureImg(e, onChange)}
                    />
                  </FileButton>
                </Stack>
              )}
            />
            <Stack direction={isMobile ? 'column' : 'row'} spacing={'1rem'}>
              <FormControl fullWidth>
                <LabelForm>{FIRST_NAME}</LabelForm>
                <Controller
                  data-testid="profileEditFirstName"
                  control={control}
                  name="firstName"
                  rules={{
                    required: FILL_OUT_MISSING_INFO,
                    minLength: {
                      value: MIN_NAME_LENGTH,
                      message: MIN_LENGTH(MIN_NAME_LENGTH)
                    },
                    maxLength: {
                      value: MAX_SHORT_TEXT_LENGTH,
                      message: MAX_LENGTH(MAX_SHORT_TEXT_LENGTH)
                    }
                  }}
                  render={({ field }) => (
                    <InputForm
                      size="small"
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      {...field}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <LabelForm>{LAST_NAME}</LabelForm>
                <Controller
                  data-testid="profileEditLastName"
                  control={control}
                  name="lastName"
                  rules={{
                    required: FILL_OUT_MISSING_INFO,
                    minLength: {
                      value: MIN_NAME_LENGTH,
                      message: MIN_LENGTH(MIN_NAME_LENGTH)
                    },
                    maxLength: {
                      value: MAX_SHORT_TEXT_LENGTH,
                      message: MAX_LENGTH(MAX_SHORT_TEXT_LENGTH)
                    }
                  }}
                  render={({ field }) => (
                    <InputForm
                      size="small"
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      {...field}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack>
              <FormControl fullWidth>
                <LabelForm>{USERNAME}</LabelForm>
                <Controller
                  data-testid="profileEditUsername"
                  control={control}
                  name="username"
                  rules={{
                    required: FILL_OUT_MISSING_INFO,
                    minLength: {
                      value: MIN_SHORT_TEXT_LENGTH,
                      message: MIN_LENGTH(MIN_SHORT_TEXT_LENGTH)
                    },
                    maxLength: {
                      value: MAX_SHORT_TEXT_LENGTH,
                      message: MAX_LENGTH(MAX_SHORT_TEXT_LENGTH)
                    }
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <ValidationInputForm
                        id={field.name}
                        {...field}
                        fullWidth
                        hiddenLabel
                        inputProps={{
                          'data-testid': field.name,
                          style: {
                            marginRight: '-1.75rem',
                            paddingRight:
                              !!fieldState.error || errors?.isUsernameValid ? '2.5rem' : 'unset'
                          }
                        }}
                        error={!!fieldState.error || !!errors?.isUsernameValid}
                        valid={watch('isUsernameValid')}
                        placeholder={USERNAME}
                        size="small"
                        type="text"
                        variant="outlined"
                        helperText={
                          fieldState.error?.message ||
                          errors?.isUsernameValid?.message ||
                          (watch('isUsernameValid') && USERNAME_AVAILABLE)
                        }
                        onChange={(e) => {
                          field.onChange(e);
                          handleUsernameChange(e);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Stack>
            <Stack>
              <FormControl fullWidth>
                <LabelForm>{ADDRESS}</LabelForm>
                <Controller
                  data-testid="profileEditAddress"
                  control={control}
                  name="address"
                  rules={{ required: FILL_OUT_MISSING_INFO, maxLength: 64 }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputFormLocation
                        id={field.name}
                        data-testid={field.name}
                        {...field}
                        fullWidth
                        hiddenLabel
                        inputProps={{ maxLength: MAX_SHORT_TEXT_LENGTH }}
                        error={!!fieldState.error || !!errors?.isAddressValid}
                        placeholder={UI_LABELS.SEARCH}
                        size="small"
                        type="text"
                        variant="outlined"
                        helperText={fieldState.error?.message || errors?.isAddressValid?.message}
                        ref={searchInputRef}
                        onChange={(e) => {
                          field.onChange(e);
                          handleAddressChanged(e);
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </Stack>
            <Stack>
              <FormControl>
                <LabelForm>{PROFILE_BIO}</LabelForm>
                <Controller
                  data-testid="profileEditBio"
                  control={control}
                  name="bio"
                  rules={{
                    maxLength: {
                      value: MAX_LARGE_TEXT_LENGTH,
                      message: MAX_LENGTH(MAX_LARGE_TEXT_LENGTH)
                    }
                  }}
                  render={({ field }) => (
                    <InputForm
                      multiline
                      placeholder="Biography"
                      minRows={8}
                      maxRows={8}
                      error={!!errors.bio}
                      helperText={errors.bio?.message}
                      {...field}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack gap={3} paddingTop={2}>
              <Stack gap={0.5}>
                <Typography variant="body1">{SOCIAL_LINKS}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {PLEASE_INCLUDE_ENTIRE_LINK}
                </Typography>
              </Stack>
              <FormControl fullWidth>
                <LabelForm>{TWITTER}</LabelForm>
                <Controller
                  name={twitterLink}
                  control={control}
                  render={({ field }) => (
                    <InputForm
                      {...field}
                      size="small"
                      placeholder="ex: 'awsm'"
                      error={!isTwitterLinkValid}
                      helperText={isTwitterLinkValid ? '' : twitterLinkHelperText}
                      onChange={(e) => {
                        field.onChange(e);
                        handleSocialChanged(e, field.name);
                      }}
                      inputProps={{ pattern: socialLinksRegexString }}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <LabelForm>{LINKEDIN}</LabelForm>
                <Controller
                  name={linkedInLink}
                  control={control}
                  render={({ field }) => (
                    <InputForm
                      {...field}
                      placeholder="ex: 'https://www.linkedin.com/in/linkedInUsername'"
                      error={!isLinkedInLinkValid}
                      helperText={isLinkedInLinkValid ? '' : linkedInLinkHelperText}
                      onChange={(e) => {
                        field.onChange(e);
                        handleSocialChanged(e, field.name);
                      }}
                      inputProps={{ pattern: linkedInLinkRegexString }}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <LabelForm>{INSTAGRAM}</LabelForm>
                <Controller
                  name={instagramLink}
                  control={control}
                  render={({ field }) => (
                    <InputForm
                      {...field}
                      placeholder="ex: 'awsm'"
                      error={!isInstagramLinkValid}
                      helperText={isInstagramLinkValid ? '' : instagramLinkHelperText}
                      onChange={(e) => {
                        field.onChange(e);
                        handleSocialChanged(e, field.name);
                      }}
                      inputProps={{ pattern: socialLinksRegexString }}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <LabelForm>{DISCORD}</LabelForm>
                <Controller
                  name={discordLink}
                  control={control}
                  render={({ field }) => (
                    <InputForm
                      {...field}
                      placeholder="ex: 'awsm'"
                      error={!isDiscordLinkValid}
                      helperText={isDiscordLinkValid ? '' : discordHelperText}
                      onChange={(e) => {
                        field.onChange(e);
                        handleSocialChanged(e, field.name);
                      }}
                      inputProps={{ pattern: discordLinkRegexString }}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <LabelForm>{WEBSITE}</LabelForm>
                <Controller
                  name="webLink"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputForm
                      {...field}
                      placeholder="ex: 'https://awsm.com'"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack gap={3}>
              <Typography variant="body1">{ACCOUNT}</Typography>
              <Stack direction="row">
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={isLoading}
                  type="button"
                  onClick={logout}
                  endIcon={<LogoutIcon fill={theme.palette.common.black} />}
                >
                  {LOGOUT}
                </Button>
              </Stack>
            </Stack>
            <Stack paddingTop={3} gap={2}>
              <Stack direction="row" gap={2}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    !isDirty ||
                    isSubmitting ||
                    !isValid ||
                    isProcessing ||
                    isLoading ||
                    watch('isUsernameValid') === false ||
                    getValues('isAddressValid') === false
                  }
                >
                  {SAVE}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </form>
      </ContainerProfile>
    </InnerPageContentBox>
  );
};

export default ProfileEdit;
