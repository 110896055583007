import { styled } from '@mui/material';

const ColorSquareStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(({ theme, color }) => ({
  height: '0.5rem',
  width: '0.5rem',
  backgroundColor: color,
  marginRight: 5
}));

export default ColorSquareStyled;
