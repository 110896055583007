import { inputBaseClasses, outlinedInputClasses, styled, TextField } from '@mui/material';

const InputFormStyled = styled(TextField, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'error' && prop !== 'bold'
})<{ error?: boolean; bold?: boolean }>(({ theme, error, bold = false }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      borderWidth: '.0625rem',
      borderColor: theme.palette.grey[500],
      borderRadius: '0.5rem'
    }
  },
  [`& .${inputBaseClasses.root}`]: {
    fontWeight: bold ? 'bold' : 'inherit',
    border: error ? `solid ${theme.palette.error.main} .063rem` : 'none'
  }
}));

export default InputFormStyled;
