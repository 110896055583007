import { IconButton, Stack, Typography } from '@mui/material';
import { HamburgerMenuIcon } from 'assets';
import AwsmLogoAndTextIcon from 'assets/AwsmLogoAndTextIcon';
import { useAppNavigation, useDao } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

interface MobileHeaderProps {
  isInDao: boolean;
}

const MobileHeader = ({ isInDao }: MobileHeaderProps): React.ReactElement => {
  const location = useLocation();
  const { navigationOpen, setNavigationOpen } = useAppNavigation();
  const { currentDao } = useDao();

  useEffect(() => {
    setNavigationOpen(false);
  }, []);

  const handleMenuClick = useCallback(
    (e: any) => {
      e.preventDefault();
      // setNavigationOpen(navigationOpen, ?userNavExpanded, ?showDaoNav)
      setNavigationOpen(!navigationOpen, !isInDao, isInDao);
    },
    [navigationOpen, isInDao, setNavigationOpen]
  );

  return (
    <Stack direction={'row'} sx={{ display: 'flex', paddingBottom: '1rem' }}>
      <IconButton
        id={'mobile-menu-button'}
        onClick={(e: any) => handleMenuClick(e)}
        sx={{ margin: '1rem 0 0 1rem' }}
      >
        <HamburgerMenuIcon />
      </IconButton>
      {!location.pathname.includes('/c/') && (
        <AwsmLogoAndTextIcon
          sx={{
            margin: '1.35rem 0 0 0.25rem',
            height: 'fit-content',
            width: 'fit-content'
          }}
        />
      )}
      {location.pathname.includes('/c/') && currentDao && (
        <Typography
          variant={'body1'}
          sx={{ margin: '1rem 0 0 0.25rem', display: 'flex', alignItems: 'center' }}
        >
          {currentDao.name}
        </Typography>
      )}
    </Stack>
  );
};

export default MobileHeader;
