import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { ReactElement } from 'react';

const DownVoteIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon width="22" height="24" viewBox="0 0 22 24" fill="transparent" {...props}>
      <path
        d="M18.881 8.85714L17.1786 8.85714L17.1786 3C17.1786 1.89543 16.2831 1 15.1786 1L6.82143 1C5.71686 1 4.82143 1.89543 4.82143 3L4.82143 8.85714L3.11898 8.85714C1.42875 8.85714 0.500625 10.8238 1.57512 12.1286L9.45614 21.6984C10.2562 22.6698 11.7438 22.6698 12.5439 21.6984L20.4249 12.1286C21.4994 10.8238 20.5713 8.85714 18.881 8.85714Z"
        stroke={props.stroke || '#181818'}
        strokeWidth="2"
        fill={props.fill || 'transparent'}
      />
    </SvgIcon>
  );
};

export default DownVoteIcon;
