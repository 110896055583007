export const POST_OPTIONS = {
  DELETE: 'Delete',
  DELETE_POST: 'Delete Post',
  DELETE_PROPOSAL: 'Delete Proposal'
};

export const DELETE_OPTION = {
  DELETING_CONTENT_POST: 'Are you sure you want to delete this post?',
  DELETING_CONTENT_PROPOSAL: 'Are you sure you want to delete this proposal?',
  DELETE_SUBTITLE: 'Are you sure you want to delete this comment? This cannot be undone.',
  DELETE_SUCCESS: 'Comment deleted successfully.',
  CONTENT_DELETED: 'This item was deleted.',
  POST_DELETE_FAILURE: 'Failed to remove.',
  POST_DELETE_SUCCESS: 'Successfully removed.'
};
