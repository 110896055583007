import { AUTH_ERROR } from 'constants/auth.labels';

const {
  WRONG_PASSWORD,
  USER_NOT_FOUND,
  INVALID_EMAIL,
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL,
  EMAIL_ALREADY_IN_USE,
  UNKNOWN_FIREBASE_ERROR
} = AUTH_ERROR;

enum FirebaseAuthError {
  WrongPassword = 'auth/wrong-password',
  UserNotFound = 'auth/user-not-found',
  InvalidEmail = 'auth/invalid-email',
  AccountExistsWithDifferentCredential = 'auth/account-exists-with-different-credential',
  PopupClosedByUser = 'auth/popup-closed-by-user',
  EmailAlreadyInUse = 'auth/email-already-in-use'
}

/**
 * This method translates a firebase error code to a friendlier
 * error message for users
 * @param firebaseError {@link https://firebase.google.com/docs/auth/admin/errors}
 * @returns {string} Error message that is consumer friendly
 */
const formatFirebaseError = (firebaseError: FirebaseAuthError): string => {
  switch (firebaseError) {
    case FirebaseAuthError.WrongPassword:
      return WRONG_PASSWORD;
    case FirebaseAuthError.UserNotFound:
      return USER_NOT_FOUND;
    case FirebaseAuthError.InvalidEmail:
      return INVALID_EMAIL;
    case FirebaseAuthError.AccountExistsWithDifferentCredential:
      return ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL;
    case FirebaseAuthError.EmailAlreadyInUse:
      return EMAIL_ALREADY_IN_USE;
    default:
      return UNKNOWN_FIREBASE_ERROR;
  }
};

export default FirebaseAuthError;
export { formatFirebaseError };
