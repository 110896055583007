import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Typography(theme: Theme): object {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'inherit'
        },
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        },
        caption: {
          ...theme.typography.caption,
          color: theme.palette.text.secondary
        },
        body1: {
          ...theme.typography.body1
        },
        overline: {
          color: theme.palette.grey[500],
          fontWeight: '550'
        }
      }
    }
  };
}
