import { Stack, Typography, useTheme } from '@mui/material';
import { BitIcon } from 'assets/icons/hero-ui';
import { AvatarBuilder } from 'components';
import { AvatarSize } from 'types/enum';
import { CollectiveListItemProps } from './CollectiveListItem.props';
import { numberFormatter, usdFormatter } from 'utils';

const CollectiveListItem = ({ membership }: CollectiveListItemProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ margin: { xs: '1rem 0', sm: '1rem 0', md: '1rem 1.5rem' } }}
    >
      <Stack direction={'row'} width={'100%'} alignItems={'center'}>
        <AvatarBuilder
          id={membership.dao.id}
          size={AvatarSize.LARGE}
          src={membership.dao.logoThumbnail}
          isSquare={true}
        />
        <Typography variant={'body2'} fontWeight={'medium'} paddingLeft={'0.75rem'}>
          {membership.dao.name}
        </Typography>
      </Stack>
      <Stack direction={'column'} alignItems={'flex-end'}>
        <Typography variant={'body2'}>
          {usdFormatter.format(membership.score[membership.score.length - 1]?.totalBitValue || 0)}
        </Typography>

        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant={'body2'} color={'secondary'} paddingRight={'0.25rem'}>
            {numberFormatter.formatterWithTwoDecimals(
              membership.score[membership.score.length - 1]?.totalBitCount || 0
            )}
          </Typography>
          <BitIcon height={'10'} width={'10'} fill={theme.palette.secondary.main} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CollectiveListItem;
