import { Stack, StackProps, styled, Typography } from '@mui/material';

export const StackStyled = styled(Stack)<StackProps>(() => ({
  alignItems: 'center',
  gap: '.25rem',
  marginTop: '2rem !important'
}));

export const TypographyMetaStyled = styled(Typography)(() => ({
  transition: 'opacity 0.3s linear'
}));
