import { Box, styled } from '@mui/material';

export const BoxStyled = styled(Box)(({ theme }) => ({
  border: `.0625rem solid ${theme.palette.grey[300]}`,
  padding: '1rem 0 1.5rem 1rem',
  width: 'inherit',
  '&:first-of-type': {
    marginRight: '1rem'
  }
}));
