import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function SvgIcon(theme: Theme): object {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          height: '1rem',
          width: '1rem'
        },
        fontSizeSmall: {
          width: 16,
          height: 16,
          fontSize: 'inherit'
        },
        fontSizeLarge: {
          width: 32,
          height: 32,
          fontSize: 'inherit'
        }
      }
    }
  };
}
