import { IconButton, Stack, Typography } from '@mui/material';
import { CloseIcon } from 'assets';

interface IconTitleProps {
  icon: React.ReactElement;
  title: string;
  onClose?: () => void;
}

const IconTitle = ({ icon, title, onClose }: IconTitleProps): React.ReactElement => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        {icon}
        <Typography variant={'subtitle2'} fontWeight={'bold'}>
          {title}
        </Typography>
      </Stack>
      {onClose && (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default IconTitle;
