import { Stack, useTheme } from '@mui/material';
import { TagDTO } from '@piefi-platform/types-lib';
import { Users01 } from 'assets/icons/users';
import React from 'react';
import TagChip from '../../v2/base/tag-chip/TagChip';

interface Props {
  tagLimit?: number;
  tags?: TagDTO[];
}

const TagList = ({ tags, tagLimit = 5 }: Props): React.ReactElement => {
  const theme = useTheme();
  return tags && tags.length > 0 ? (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Users01 />
      {tags?.slice(0, tagLimit).map((tag) => (
        <TagChip key={tag.id} id={tag.id} label={tag.name} backgroundColor={tag.color} />
      ))}
      {tags && tags?.length > tagLimit && (
        <TagChip
          key="overflow-tag"
          label={`+${(tags?.length || 0) - tagLimit}`}
          backgroundColor={theme.palette.brand.grey[500]}
          title={tags
            ?.slice(tagLimit, tags.length)
            .map((tag) => tag.name)
            .join(', ')}
        />
      )}
    </Stack>
  ) : (
    <></>
  );
};

export default TagList;
