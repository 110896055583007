import { Box, styled } from '@mui/material';

export const BoxStyled = styled(Box)<{}>(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  borderRadius: '0.25rem',
  padding: '1rem',
  marginTop: '3rem'
}));

export const Container = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '.125rem',
    opacity: 0.4
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey['500']
  }
}));
