import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme): object {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '0',
          height: '2rem',
          width: '2rem',
          padding: '0',
          '&:hover': {
            backgroundColor: theme.palette.grey[300]
          },
          '&:focus-visible': {
            border: `0.0625rem solid ${theme.palette.grey[300]}`
          },
          '& svg': {
            height: '1rem',
            width: '1rem'
          }
        }
      }
    }
  };
}
