import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import ProposalOptions from '../proposal-options';
import ProposalResults from '../proposal-results';
import IFeedProposalProps from './Proposal.props';
import { useFeed } from 'hooks';
import { abbreviateNumber } from 'utils';
import { UI_LABELS } from 'constants/ui-labels';
import { formatDistanceToNowStrict, isAfter } from 'date-fns';
import { PROPOSAL } from 'constants/feed.labels';
import { CaptionText } from './Proposal.styles';

const Proposal = ({
  feedItemId,
  room,
  proposal,
  optionSelectedLabel = UI_LABELS.YOU_VOTED
}: IFeedProposalProps): React.ReactElement => {
  const {
    id: roomId,
    dao: { id: daoId }
  } = room;
  const { VOTES, LEFT, CLOSED } = PROPOSAL;
  const { id: proposalId, completionTimestamp, proposalOptions, totalVotes } = proposal;
  const { votes } = useFeed();
  const [userHasVoted, setUserHasVoted] = useState(false);
  const [proposalOpen, setProposalOpen] = useState(true);

  useEffect(() => {
    setProposalOpen(isAfter(new Date(completionTimestamp), new Date()));
  }, [completionTimestamp]);

  useEffect(() => {
    setUserHasVoted(!!proposalOptions.find((option) => option.votes?.length >= 1));
  }, [proposalOptions]);

  useEffect(() => {
    votes > totalVotes && setUserHasVoted(true);
  }, [votes, totalVotes]);

  return (
    <Stack direction="column" alignItems="left" spacing={2}>
      {!userHasVoted && proposalOpen && (
        <ProposalOptions
          daoId={daoId}
          roomId={roomId}
          feedItemId={feedItemId}
          proposalId={proposalId}
          options={proposalOptions}
        />
      )}
      {(userHasVoted || !proposalOpen) && (
        <ProposalResults
          daoId={daoId}
          roomId={roomId}
          feedItemId={feedItemId}
          proposalId={proposalId}
          proposalOptions={proposalOptions}
          selectedOptionLabel={optionSelectedLabel}
        />
      )}
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography
          id="total-votes"
          data-testid="total-votes"
          color="brand.red.primary"
          variant="caption"
        >
          {`${abbreviateNumber(totalVotes)} ${VOTES}`}
        </Typography>
        <Typography variant="caption" color="grey.500">
          /
        </Typography>
        <CaptionText id="days-left" data-testid="days-left" color="grey.500" variant="caption">
          {`${
            isAfter(new Date(completionTimestamp), new Date())
              ? `${formatDistanceToNowStrict(new Date(completionTimestamp))} ${LEFT}`
              : CLOSED
          } `}
        </CaptionText>
      </Stack>
    </Stack>
  );
};

export default Proposal;
