import { Chip, Divider, Stack, Typography } from '@mui/material';
import { DaoPublicDTO, ValuationEventDTO } from '@piefi-platform/types-lib';
import { AsyncButton, AvatarBuilder, UnitAmountIconDisplay } from 'components';
import IconValueCard from 'components/cards/icon-value-card/IconValueCard';
import { DIRECT_ALLOCATION, DIRECT_ALLOCATION_DISCLAIMER } from 'constants/dao-admin-nav.labels';
import { useDaoService, usePatronageActivityService, useValuationService } from 'hooks/services';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AvatarSize } from 'types/enum';
import { AllocationPayload } from './direct-allocation-wrapper/DirectAllocationWrapper';

interface DirectAllocationFormProps {
  allocationPayload: AllocationPayload;
  currentDao: DaoPublicDTO;
  onDistributionComplete: () => void;
}

const DirectAllocationConfirmation = ({
  allocationPayload,
  currentDao,
  onDistributionComplete
}: DirectAllocationFormProps): React.ReactElement => {
  const { getDaoUnallocatedPoints, getDaoInfo } = useDaoService();
  const { getMostRecentValuation } = useValuationService();
  const [currentAllocationPayload, setCurrentAllocationPayload] = useState(allocationPayload);

  const [unitsRemaining, setUnitsRemaining] = useState<{
    unallocatedDollars: number;
    unallocatedPoints: number;
  }>({ unallocatedDollars: 0, unallocatedPoints: 0 });
  const [currentValuation, setCurrentValuation] = useState<ValuationEventDTO | undefined>();
  const { allocateAwardToMembersByActivity } = usePatronageActivityService();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const isOverSpending = useRef<boolean>(
    allocationPayload.amount * allocationPayload.members.length < unitsRemaining.unallocatedDollars
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { CONFIRM } = DIRECT_ALLOCATION;

  const configureMetrics = useCallback(async () => {
    if (!currentDao.id) return;
    try {
      setLoading(true);
      const { data: daoInfo } = await getDaoInfo(currentDao.id);
      const [unallocatedPoints, valuations] = await Promise.all([
        getDaoUnallocatedPoints(currentDao.id),
        getMostRecentValuation(daoInfo.companyId, true)
      ]);
      setUnitsRemaining({ ...unallocatedPoints.data });
      isOverSpending.current =
        allocationPayload.amount * allocationPayload.members.length >
        unallocatedPoints.data.unallocatedDollars;

      if (valuations.data.length) {
        setCurrentValuation({ ...valuations.data[0] });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [currentDao, getDaoUnallocatedPoints]);

  const handleDistribution = useCallback(async () => {
    try {
      await allocateAwardToMembersByActivity(currentDao.id, '-', {
        memberIds: allocationPayload.members.map((i) => i.id),
        amount: allocationPayload.amount,
        memo: allocationPayload.memo || undefined
      });
      setIsSuccessful(true);
    } catch (error) {}
  }, [allocationPayload]);

  const formatTotalToDisplay = () => {
    const amount = currentAllocationPayload.amount;
    const membersCount = currentAllocationPayload.members.length;
    const total = amount * membersCount;
    const isInteger = Number.isInteger(total);

    return isInteger ? total.toString() : total.toFixed(2);
  };

  useEffect(() => {
    if (!currentDao) return;
    configureMetrics();
  }, [currentDao]);

  useEffect(() => {
    setCurrentAllocationPayload(allocationPayload);
  }, [allocationPayload]);

  return (
    <Stack direction={'column'}>
      <Stack justifyContent={'center'} alignItems={'center'} padding={'1.5rem 0'}>
        <Typography variant={'h3'}>${formatTotalToDisplay()}</Typography>
        <UnitAmountIconDisplay
          amount={
            currentValuation
              ? (currentAllocationPayload.amount * currentAllocationPayload.members.length) /
                currentValuation?.sharePrice
              : 0
          }
          typographyProps={{ fontWeight: 'bold' }}
          iconProps={{ width: 12, height: 12 }}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent={'center'}
        spacing={'0.375rem'}
        flexWrap={'wrap'}
        rowGap={'0.5rem'}
      >
        {currentAllocationPayload.members.map((i) => (
          <Chip
            sx={{
              borderRadius: '0.5rem',
              padding: '0.375rem',
              border: '1px solid #D6D6D6',
              '& .MuiChip-label': {
                paddingLeft: '0.375rem'
              }
            }}
            variant="outlined"
            label={`${i.user.firstName}`}
            key={i.id}
            icon={
              <AvatarBuilder
                hideAnimation={true}
                id={i.id}
                src={i.user.profileThumbnail}
                size={AvatarSize.X_SMALL}
              />
            }
          />
        ))}
      </Stack>
      <Typography variant="body1" textAlign={'center'} padding={'1rem 0'}>
        {currentAllocationPayload.memo}
      </Typography>
      <Divider />
      <Stack direction={'column'} padding={'1rem 0'} spacing={0.5}>
        <Typography variant="body2" fontWeight={'medium'}>
          {CONFIRM.BALANCE}
        </Typography>
        <IconValueCard
          imageDescriptor={currentDao.name}
          leadingImageUrl={currentDao.logoThumbnail}
          metricConfiguration={{
            bitAmount: unitsRemaining.unallocatedPoints,
            dollarAmount: unitsRemaining.unallocatedDollars
          }}
        />
      </Stack>
      <Typography variant="caption" paddingBottom={'1rem'}>
        {DIRECT_ALLOCATION_DISCLAIMER}
      </Typography>
      <AsyncButton
        onClick={handleDistribution}
        isSuccessful={isSuccessful}
        disabled={loading || isSuccessful || isOverSpending.current}
        onCheckMarkAnimationComplete={onDistributionComplete}
      >
        {isOverSpending.current ? `${CONFIRM.OVERSPEND}` : `${CONFIRM.CONFIRMATION_ACTION}`}
      </AsyncButton>
    </Stack>
  );
};

export default DirectAllocationConfirmation;
