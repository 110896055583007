const daoInvitePath = (daoId: string, manageableEntityId: string, daoInviteId: string): string =>
  `coops/${daoId}/home/manageable-entities/${manageableEntityId}/invites/${daoInviteId}`;

const daoManageableEntityURL = (daoId: string): string =>
  `${process.env.REACT_APP_BASE_URL}/daos/${daoId}/manageable-entities`;

const daoInviteURL = (daoId: string, manageableEntityId = '-'): string =>
  `${daoManageableEntityURL(daoId)}/${manageableEntityId}/dao-invites`;

const daoInviteByIdURL = (daoId: string, daoInviteId: string, manageableEntityId: string): string =>
  `${daoInviteURL(daoId, manageableEntityId)}/${daoInviteId}`;

export { daoInvitePath, daoManageableEntityURL, daoInviteURL, daoInviteByIdURL };
