/**
 * Strip HTML tags from a string
 *
 * @param {string} htmlString
 * @returns {string}
 */
const stripHtml = (htmlString: string): string => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
};

export default stripHtml;
