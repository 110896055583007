export const CONFIRMATION_LABELS = {
  ACTION_CANNOT_BE_UNDONE:
    'This action cannot be undone, but the Blockchain will always remember...',
  ARE_YOU_SURE: 'Are you sure?',
  ARE_YOU_SURE_DELETE_POST: 'Are you sure you want to delete this post?',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  DAO_JOIN_SUCCESSFUL: 'Join collective request successfully',
  SAVE: 'Save!',
  SELECT: 'Select',
  COMMENT_SUCCESSFUL: 'Comment successfully',
  REPORT_SUCCESSFUL: 'Report successfully',
  UPDATE_SUCCESSFUL: 'Update successfully',
  YES: 'Yes'
};

export const CLIENT_ENTITY_DELETED = 'This item was deleted.';

export const MAINTENANCE = {
  PATIENCE: `Thanks for your patience. We'll be back shortly!`,
  IN_PROGRESS: `Maintenance In Progress`
};

export const LANGUAGE = {
  US: 'en-US'
};

export const PAGE_TITLES = {
  FEED_PAGE: 'Feed',
  DIRECTORY: 'Directory'
};

export const FILTER_TABS = {
  BOUNTIES: 'Bounties',
  CHAT: 'Chat',
  INFO: 'Information',
  POSTS: 'Posts',
  PROPOSALS: 'Polls',
  RULE: 'Rules',
  SAVED: 'Saved',
  SUMMARY: 'Summary',
  UPDATES: 'Updates',
  FUNDING_CAMPAIGN: 'Fundraising'
};

export const POST_FORM_LABELS = {
  POST_LABEL: 'Write a new post. What’s up?',
  UPDATES_LABEL: 'Write a new update.',
  BOUNTY_LABEL: 'Create a new bounty. Let the games begin!',
  PROPOSAL_LABEL: 'Start a new poll. Time to vote.',
  NEW_POST: 'New Post',
  POST_TITLE_PLACEHOLDER: 'Post Title',
  POST_CONTENT_PLACEHOLDER: '‘Sup?',
  POST: 'Post',
  ADDITIONAL_INFORMATION: 'ADDITIONAL INFORMATION',
  ADD_ANOTHER_OPTION: 'Add Another Option',
  ADD_ANOTHER_REWARD: 'Add Another Reward',
  ADD_ANOTHER: 'Add Another',
  ADD_BOUNTY: 'Add Bounty',
  ADD_PROPOSAL: 'Add Poll',
  AND: 'AND',
  BOUNTY: 'Bounty',
  FORM_CONTENT_PLACEHOLDER: 'What do you think about this?',
  FORM_TITLE_COLLAPSED_PLACEHOLDER: 'Create a post...',
  FORM_TITLE_PLACEHOLDER: 'Post Title',
  FORM_TITLE: 'Create a post',
  MAX_RECIPIENTS_LABEL: 'How many people should receive this bounty?',
  MAX_RECIPIENTS_PLACEHOLDER: 'Max. Recipients',
  NEW_PROPOSAL: 'New Poll',
  OPTION: 'Option',
  PROPOSAL_OPTIONS: 'POLL OPTIONS',
  PROPOSAL_TITLE: 'Poll Title',
  PROPOSAL_TEXTAREA_PLACEHOLDER: 'What else should members know about this?',
  REMOVE_OPTION: 'remove an option',
  REMOVE_REWARD: 'remove a reward',
  REMOVE: 'remove',
  REWARD_AMOUNT: 'Reward Amount',
  REWARD_LABEL: 'What do they get for completing this bounty?',
  SELECT_CURRENCY: 'Select currency...',
  WRONG_FORMAT: 'The value format is wrong',
  DISPLAY_POST_AS_ANNOUNCEMENT: 'Also display this post as an Update in the collective Commons',
  EDIT_POST: 'Edit Post'
};

export const UI_LABELS = {
  ADD_USER: 'Add user',
  AGO: 'ago',
  BTN_SETTING_PROFILE: 'Button setting profile',
  CANCEL: 'Cancel',
  COMMENTS: 'comments',
  COMMENT_REPORTED: 'Comment Reported',
  CONTENT_DIALOG_REPORT:
    'Thank you for taking the time to submit a report. Let us know what happens by selecting one of the categories below and we will look into it.',
  COPIED: 'Copied!',
  DAO_COMMON: 'Collective COMMONS',
  DAO_PROFILE: 'Collective Profile',
  DAO_ROLE: 'Collective Role',
  DAYS_AGO: 'days ago',
  DAYS_LEFT: 'days left',
  DOWN_VOTE: 'Down vote',
  EDITED: 'edited',
  ERROR: 'Error',
  ETH: 'ETH',
  HBAR: 'HBAR',
  HOURS_AGO: 'hours ago',
  INTERACTIONS: 'interactions',
  MAX_OPTION_LENGTH: 'Max text length has been reached',
  MAIN_ROOM_ROLE: 'Main Room Role',
  MEMBER_REQUEST: 'Member Request',
  MEMBERSHIP_REQUEST: 'Membership Request',
  MINUTES_AGO: ' minutes ago',
  MONTHS_AGO: 'months ago',
  NOTIFICATION: 'Notifications',
  NO_COMMENTS: "There's no comment yet",
  NO_DATA_MESSAGE: "There's nothing to see here.",
  PRIVATE_DAO: 'private Collective',
  POST_REPORTED: 'Post Reported',
  PROFILE_SETTINGS: 'Profile Settings',
  REPORT: 'Report',
  ROOM_LABEL: 'Who should be here, but isn’t?',
  SAVE_CHANGES: 'Save Changes?',
  SAVE: 'Save',
  SAVED: 'Saved',
  SEARCH: 'Search',
  ADDRESS: 'Address',
  SECONDS_AGO: 'seconds ago',
  SELECT_CATEGORY: 'Select a category',
  SELECT_MEMBER: 'Select a member',
  SELECT_ROLE: 'Select a role',
  SHOW_FILTER: 'Show filter',
  SUMMARY: 'Summary',
  THANKS_FOR_REPORTING: 'Thank you for reporting',
  TOTAL_CRYPTO: 'Total Crypto Value',
  UPSIDEDAO_ICON: 'Awsm icon',
  UP_TO_DATE: 'up to date',
  UP_VOTE: 'Up vote',
  UPLOAD_FILE: 'Upload file',
  USD: 'USD',
  USER_REPORTED: 'User Reported',
  VOTES: 'votes',
  WEEKS_AGO: 'weeks ago',
  YEARS_AGO: 'years ago',
  YOU_VOTED: '(You voted)'
};

export const SETUP_TABS = {
  ACCOUNT: 'Account Info',
  NOTIFICATION: 'Notifications',
  OTHER: 'Other',
  PROFILE: 'Profile Info'
};

export const SINGLE_TABS = {
  COMMENT: 'Comments/Submission'
};

export const SEARCH_LABELS = {
  DAO_MEMBERS: 'Search for Members',
  SEARCH: 'Search EVERYTHING'
};

export const SORTING_LABELS = {
  SORT_BY: 'Sort By :'
};
