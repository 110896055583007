import { Button, Chip, IconButton, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon, UpsideDaoIcon } from 'assets';
import { PatronageActivityForm, RedirectActivityForm } from 'components';
import { PATRONAGE_ACTIVITY_FORM } from 'constants/dao-admin-nav.labels';
import { useDao, usePatronageActivityStepper } from 'hooks';
import { PatronageActivityCreatePayload } from 'model';
import { ActivityFormStep } from 'model/contexts/patronage-activity-context-type.model';
import { useCallback, useRef } from 'react';
import { ActivityFormTypeValue } from 'types/enum';

const PatronageActivityStepperFormWrapper = (): React.ReactElement => {
  const { currentDao } = useDao();
  const daoId = useRef(currentDao.id);

  const {
    ADD_PATRONAGE_ACTIVITY,
    EDIT_ACTIVITY,
    CHOOSE_ACTIVITY,
    CHOOSE_PLATFORM,
    CUSTOM,
    REDIRECT
  } = PATRONAGE_ACTIVITY_FORM;

  const { CUSTOM_FORM, REDIRECT_FORM } = ActivityFormTypeValue;

  const defaultValues: PatronageActivityCreatePayload = {
    name: '',
    category: 'OTHER',
    color: '',
    description: '',
    // we do this to have an empty value display in the text box
    points: '' as unknown as number,
    tags: [],
    variablePoint: undefined,
    allocationAgreements: []
  };

  const {
    activityStepperActive,
    activeFormStep,
    activityFormType,
    setActivityStepperActive,
    setActivityFormType,
    setActiveFormStep,
    activityToEdit,
    setActivityToEdit
  } = usePatronageActivityStepper();

  const hideForm = useCallback(() => {
    setActivityToEdit(undefined);
    setActivityStepperActive(!activityStepperActive);
  }, [activityStepperActive, setActivityStepperActive, setActivityToEdit]);

  const activateActivityStep = useCallback(
    (type: ActivityFormTypeValue) => {
      setActiveFormStep(ActivityFormStep.ACTIVITY);
      setActivityFormType(type);
    },
    [setActiveFormStep]
  );

  const activatePlatformStep = useCallback(() => {
    setActiveFormStep(ActivityFormStep.PLATFORM);
    setActivityFormType(ActivityFormTypeValue.BLANK);
  }, [setActiveFormStep]);

  if (!daoId.current) return <></>;

  return (
    <Stack direction="column">
      <Stack
        alignItems="center"
        direction="row"
        gap=".5rem"
        marginBottom="4rem"
        onClick={hideForm}
        style={{ cursor: 'pointer' }}
      >
        <IconButton style={{ marginLeft: '-.5rem' }} data-testid="back-btn">
          <ArrowLeftIcon />
        </IconButton>
        <Typography variant="h6">
          {activityToEdit ? EDIT_ACTIVITY : ADD_PATRONAGE_ACTIVITY}
        </Typography>
      </Stack>

      <Stack direction="row" gap="1.5rem" marginBottom="4rem">
        <Chip
          color={activeFormStep === 'platform' ? 'primary' : 'secondary'}
          label={CHOOSE_PLATFORM}
          size="small"
          variant="filled"
          data-testid="platform-step-chip"
          clickable
          onClick={activatePlatformStep}
          disabled={!!activityToEdit}
        />
        <Chip
          color={activeFormStep === 'activity' ? 'primary' : 'secondary'}
          disabled={activeFormStep === 'platform'}
          label={CHOOSE_ACTIVITY}
          size="small"
          variant="filled"
          data-testid="activity-step-chip"
        />
      </Stack>

      {/* STEP 1 */}
      {activeFormStep === 'platform' && (
        <Stack data-testid="platform-step">
          <Stack direction={'row'} gap={2}>
            <Button
              color="inherit"
              data-testid="build-activity-btn"
              fullWidth={false}
              size="xxLarge"
              style={{ maxWidth: '7.875rem', gap: '.5rem', padding: '0 1rem' }}
              variant="outlined"
              onClick={() => activateActivityStep(CUSTOM_FORM)}
            >
              <UpsideDaoIcon />
              <Typography variant="body1">{CUSTOM}</Typography>
            </Button>
            <Button
              color="inherit"
              data-testid="build-redirect-btn"
              fullWidth={false}
              size="xxLarge"
              style={{ maxWidth: '7.875rem', gap: '.5rem', padding: '0 1rem' }}
              variant="outlined"
              onClick={() => activateActivityStep(REDIRECT_FORM)}
            >
              <UpsideDaoIcon />
              <Typography variant="body1">{REDIRECT}</Typography>
            </Button>
          </Stack>
        </Stack>
      )}

      {/* STEP 2 */}
      {activeFormStep === ActivityFormStep.ACTIVITY && (
        <Stack data-testid="activity-step">
          {activityFormType === ActivityFormTypeValue.CUSTOM_FORM && (
            <PatronageActivityForm
              daoId={daoId.current}
              header={
                <Stack direction="row" gap={1} alignItems={'center'}>
                  <UpsideDaoIcon />
                  <Typography variant="body1">{CUSTOM}</Typography>
                </Stack>
              }
              onSubmit={() => {}}
              cancelForm={hideForm}
              patronageActivity={
                activityToEdit
                  ? { ...activityToEdit, description: activityToEdit.description || '' }
                  : defaultValues
              }
            />
          )}
          {activityFormType === ActivityFormTypeValue.REDIRECT_FORM && (
            <RedirectActivityForm
              daoId={daoId.current}
              header={
                <Stack direction="row" gap={1} alignItems={'center'}>
                  <UpsideDaoIcon />
                  <Typography variant="body1">{REDIRECT}</Typography>
                </Stack>
              }
              onSubmit={() => {}}
              cancelForm={hideForm}
              patronageActivity={
                activityToEdit
                  ? { ...activityToEdit, description: activityToEdit.description || '' }
                  : { ...defaultValues, redirectActivity: { redirectURL: '' } }
              }
            />
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default PatronageActivityStepperFormWrapper;
