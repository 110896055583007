export const ROOT_ROUTE = {
  USER: (userId: string | undefined = ':userId'): string => `/u/${userId}`,
  DAO: (daoId: string | undefined = ':daoId'): string => `/c/${daoId}`,
  AUTH: '/auth'
};
export const ROUTES = {
  ANALYTICS: '/analytics',
  AUTH: '/auth',
  CONNECT: (userId: string | undefined = ':userId'): string => `connect`,
  DAO_PROFILE: (daoId: string | undefined = ':daoId'): string => `${ROOT_ROUTE.DAO}/${daoId}`,
  DAO_HOME: (daoId: string | undefined = ':daoId'): string => {
    return ``;
  },
  DAO_UPDATES: (daoId: string | undefined = ':daoId'): string => `updates`,
  DAO_ACTIVITIES: (daoId: string | undefined = ':daoId'): string => `admin/activities`,
  DAO_ADMIN_ACTIVITY_FORM: (
    daoId: string | undefined = ':daoId',
    activityId: string | undefined = ':activityId'
  ): string => `admin/activities/${activityId}`,
  DAO_ADMIN_ACTIVITY_MANUAL_AWARD_PAGE: (activityId: string | undefined = ':activityId'): string =>
    `admin/activities/${activityId}/manual-allocation`,
  DAO_DEVELOPER_DASHBOARD: (daoId: string | undefined = ':daoId'): string => `developer-dashboard`,
  DAO_INSIGHTS_DASHBOARD: (daoId: string | undefined = ':daoId'): string => `insights-dashboard`,
  DAO_TRANSACTION_LIST: (daoId: string | undefined = ':daoId'): string => 'transactions',
  DAO_DOCUMENTS: (daoId: string | undefined = ':daoId'): string => `documents`,
  DAO_COMMONS_ACTIVITIES: (daoId: string | undefined = ':daoId'): string => `activities`,
  DAO_ACTIVITY_DETAILS_PAGE: (activityId: string | undefined = ':activityId'): string =>
    `${activityId}`,

  DAO_MEMBERS: (daoId: string | undefined = ':daoId'): string => `members`,
  DAO_TOP_MEMBERS_LIST: (daoId: string | undefined = ':daoId'): string => `member-leaderboard`,
  DAO_TOP_ACTIVITIES_LIST: (daoId: string | undefined = ':daoId'): string => `activity-leaderboard`,
  DAO_ADMIN_MEMBERS: (daoId: string | undefined = ':daoId'): string => `admin/members`,
  DAO_ADMIN_MEMBERS_PROFILE: (
    daoId: string | undefined = ':daoId',
    memberId: string | undefined = ':memberId'
  ): string => `memberships/${memberId}/profile`,
  DAO_MANAGE: (daoId: string | undefined = ':daoId'): string => `manage`,
  DAO_PROFILE_SETTINGS: (daoId: string | undefined = ':daoId'): string => `settings`,
  DAO_INVITE: (
    daoId: string | undefined = ':daoId',
    manageableEntityId: string | undefined = ':manageableEntityId',
    daoInviteId: string | undefined = ':daoInviteId',
    temporaryInvitationId: string | undefined = ':temporaryInvitationId'
  ): string =>
    `manageable-entities/${manageableEntityId}/invites/${daoInviteId}/temp-invitation/${temporaryInvitationId}`,
  DAO_ROOM_FEED: (
    daoId: string | undefined = ':daoId',
    roomId: string | undefined = ':roomId'
  ): string => `rooms/${roomId}`,
  HELP: '/help',
  POINTS: (userId: string | undefined = ':userId'): string => `points`,
  SINGLE_POST: (
    daoId: string | undefined = ':daoId',
    roomId: string | undefined = ':roomId',
    postId: string | undefined = ':postId'
  ): string => `posts/${postId}`,
  FEED: '/',
  NOT_FOUND: '404',
  OWNERSHIP: (userId: string | undefined = ':userId'): string => `ownership`,
  REGISTER: `${ROOT_ROUTE.AUTH}/register`,
  FORGOT_PASSWORD: `${ROOT_ROUTE.AUTH}/forgot-password`,
  VERIFICATION: `${ROOT_ROUTE.AUTH}/verification`,
  USER_ACCOUNT_MANAGEMENT: `${ROOT_ROUTE.AUTH}/account`,
  USER_DAO_NOTIFICATION_SETTINGS: (userId: string | undefined = ':userId'): string =>
    `${ROOT_ROUTE.USER}/${userId}/coop-notification-settings`,
  USER_PROFILE: (userId: string | undefined = ':userId'): string => {
    return `${ROOT_ROUTE.USER(userId)}/`;
  },
  USER_PROFILE_SETTINGS: (userId: string | undefined = ':userId'): string => `settings`,
  USER_TRANSACTION_LIST: (userId: string | undefined = ':userId'): string => 'transactions',
  WALLET: '/wallet',
  REFERRAL: '/referral'
};
