import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function BitIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '20px';
  const height = props.height ? `${props.height}px` : '20px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 20 20" {...props}>
      <path
        d="M9.99496 20C9.25945 20 8.56423 19.7077 8.0403 19.1835L0.816121 11.9557C0.292191 11.4315 0 10.7359 0 10C0 9.26412 0.292191 8.56856 0.816121 8.04436L8.0403 0.806469C9.11839 -0.272159 10.8816 -0.272159 11.9597 0.806469L19.1839 8.03428C19.7078 8.55848 20 9.25404 20 9.98993C20 10.7258 19.7078 11.4315 19.1839 11.9456L11.9597 19.1734C11.4358 19.6976 10.7406 19.9899 10.005 19.9899L9.99496 20ZM9.10831 1.87502L1.88413 9.11291C1.64232 9.35485 1.51134 9.66735 1.51134 10C1.51134 10.3327 1.64232 10.6552 1.88413 10.8871L9.10831 18.1149C9.60202 18.6089 10.398 18.6089 10.8917 18.1149L18.1159 10.8871C18.3577 10.6452 18.4887 10.3327 18.4887 10C18.4887 9.66735 18.3577 9.34477 18.1159 9.11291L10.8917 1.87502C10.398 1.38106 9.60202 1.38106 9.10831 1.87502Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M9.99482 12.7722C9.58151 12.7722 9.23877 12.4294 9.23877 12.0161V7.98386C9.23877 7.57056 9.58151 7.22781 9.99482 7.22781C10.4081 7.22781 10.7509 7.57056 10.7509 7.98386V12.0161C10.7509 12.4294 10.4081 12.7722 9.99482 12.7722Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
