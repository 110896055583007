import { TimeSeriesValue } from 'constants/time-series-dropdown-options';
import { subDays, subMonths, subWeeks } from 'date-fns';

/**
 * @description Gets query start date based on TimeSeriesValue
 * @param value {TimeSeriesValue}
 * @returns
 */
export const getQueryStartDate = (value: TimeSeriesValue): Date => {
  let startDate = new Date();
  switch (value) {
    case TimeSeriesValue.ONE_DAY:
      startDate = subDays(startDate, 1);
      break;
    case TimeSeriesValue.SEVEN_DAYS:
      startDate = subWeeks(startDate, 1);
      break;
    case TimeSeriesValue.ONE_MONTH:
      startDate = subMonths(startDate, 1);
      break;
    case TimeSeriesValue.THREE_MONTHS:
      startDate = subMonths(startDate, 3);
      break;
    default:
      break;
  }

  return startDate;
};
