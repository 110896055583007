import { Tooltip } from '@mui/material';
import PatronageActivityStatusProps from './PatronageActivityStatus.props';
import StatusIndicatorStyled from './PatronageActivityStatus.style';
import { DAO_ADMIN_LABELS } from 'constants/dao-admin-nav.labels';

const PatronageActivityStatus = ({
  connected
}: PatronageActivityStatusProps): React.ReactElement => {
  const { CONNECT_STATUS } = DAO_ADMIN_LABELS;
  return (
    <Tooltip title={CONNECT_STATUS(connected)}>
      <StatusIndicatorStyled connected={connected!!} />
    </Tooltip>
  );
};

export default PatronageActivityStatus;
