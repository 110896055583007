import {
  PatronageActivityAggregateResultsDTO,
  PatronageActivityDTO
} from '@piefi-platform/types-lib';
import { SingleLineProps } from 'components';
import { INTERVAL_METRIC } from 'constants/chart.labels';
import { SeasonsDropdownOptions } from 'constants/seasons-dropdown-options';
import { useDao } from 'hooks';
import { useUserService } from 'hooks/services';
import { TimeSeriesDropdownOption } from 'model';
import {
  PatronageActivityStatistic,
  SeasonsPatronageStatisticsContextType,
  initPatronageContextStatisticsType
} from 'model/contexts/seasons-patronage-statistics-type.model';
import { ReactNode, createContext, useEffect, useState } from 'react';
import DataType from 'types/enum/chart-data-types.enum';
import { getAdjustedInterval } from 'utils';
import { TimeIntervalOption } from '../types/enum';

export interface ActivityStatisticsResponse {
  activity: PatronageActivityDTO;
  statistics: PatronageActivityAggregateResultsDTO;
}

export interface AllPatronageData {
  areaData: PatronageActivityAggregateResultsDTO;
  activityData: PatronageActivityStatistic[];
}
const initialState: SeasonsPatronageStatisticsContextType = initPatronageContextStatisticsType();

export const SeasonsPatronageStatisticsDollarsContext = createContext(initialState);

const SeasonsPatronageStatisticsDollarsContextProvider = ({
  children
}: {
  children: ReactNode;
}): React.ReactElement => {
  const { activeDaoMembership, currentActiveSeasonForDao } = useDao();
  const { currentDao } = useDao();
  const [dateWindow, setDateWindow] = useState<{ startDate: Date; endDate: Date }>({
    startDate: new Date(),
    endDate: new Date()
  });
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<TimeSeriesDropdownOption>(
    {} as TimeSeriesDropdownOption
  );
  const [allData, setAllData] = useState<AllPatronageData>({
    areaData: {
      percentChange: 0,
      timeSeriesData: [],
      totalCount: 0
    },
    activityData: []
  });
  const { getStatistics, getActivitiesWithStatistics } = useUserService();

  useEffect(() => {
    if (!selectedDropdownItem || !activeDaoMembership) return;
    setSelectedDropdownItem(SeasonsDropdownOptions(activeDaoMembership.score)[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDaoMembership]);

  // When dropdown is updated
  useEffect(() => {
    if (!selectedDropdownItem?.value || !activeDaoMembership) return;
    const selectedMemberSeason = activeDaoMembership?.score.find(
      (item) => item.id === selectedDropdownItem.value
    );
    if (!selectedMemberSeason) return;

    const selectedSeasonIsCurrentSeason =
      selectedMemberSeason.season.seasonNumber === currentActiveSeasonForDao?.seasonNumber;

    setDateWindow({
      startDate: new Date(selectedMemberSeason.season.createdAt),
      endDate: selectedSeasonIsCurrentSeason
        ? new Date()
        : new Date(selectedMemberSeason.season.endDate)
    });
  }, [activeDaoMembership, currentActiveSeasonForDao?.seasonNumber, selectedDropdownItem]);

  // When date window is updated refresh data
  useEffect(() => {
    if (!currentDao.id || !selectedDropdownItem.value) return;

    const fetchUpdatedData = async () => {
      const aggArea = await getAggregatedData(currentDao.id);
      const aggPatronage = await getPatronageActivitiesWithStats(currentDao.id);
      setAllData({
        activityData: [...aggPatronage],
        areaData: aggArea
      });
    };
    fetchUpdatedData();
    // I only want this to trigger on dateWindow update NOTHING ELSE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateWindow]);

  const fetchInterval = () => {
    const adjustedInterval = getAdjustedInterval(
      dateWindow.startDate,
      3,
      TimeIntervalOption.ONE_DAY,
      INTERVAL_METRIC.DAYS,
      selectedDropdownItem.interval
    );

    return adjustedInterval;
  };

  /**
   * @description Gets the aggregated data across the entire time interval across all activities
   * @param daoId {string}
   */
  const getAggregatedData = async (
    daoId: string
  ): Promise<PatronageActivityAggregateResultsDTO> => {
    const adjustedInterval = fetchInterval();

    const { data: result } = await getStatistics(
      daoId,
      dateWindow.startDate,
      dateWindow.endDate,
      adjustedInterval,
      selectedDropdownItem.value,
      DataType.POINT_COUNT
    );

    const queriedAreaData = result[0];

    const dollarTimeSeriesData = queriedAreaData.timeSeriesData.map((item) => ({
      ...item,
      count: item.count / 100
    }));

    return {
      ...queriedAreaData,
      timeSeriesData: dollarTimeSeriesData,
      totalCount: queriedAreaData.totalCount / 100
    };
  };

  const getPatronageActivitiesWithStats = async (daoId: string) => {
    const adjustedInterval = fetchInterval();

    const activitiesWithStatsForInterval = await getActivitiesWithStatistics(
      daoId,
      dateWindow.startDate,
      dateWindow.endDate,
      adjustedInterval,
      selectedDropdownItem.value,
      DataType.POINT_COUNT
    );

    const formattedActivities = activitiesWithStatsForInterval.map((act) => {
      const { activity, statistics } = act;

      const randomHexColor = '#000000'.replace(/0/g, () => {
        //(~~) =  double NOT bitwise operation it is faster than Math.floor()
        return (~~(Math.random() * 16)).toString(16);
      });

      const existingActivity = allData.activityData.find(
        (item) => item.activity.id === act.activity.id
      );

      // if we already have a color use it
      // if we dont already have a color but the color is white then use a random one
      const color = existingActivity?.lineProps.color
        ? existingActivity.lineProps.color
        : activity.color === '#fff' || !activity.color
        ? randomHexColor
        : activity.color;

      return {
        activity,
        totalMetric: statistics.totalCount / 100,
        lineProps: {
          color,
          data: statistics.timeSeriesData.map((data) => ({
            date: data.end,
            value: data.count / 100
          })),
          key: activity.id,
          name: activity.name
        } as SingleLineProps
      } as PatronageActivityStatistic;
    });

    return formattedActivities;
  };

  const onTimeSeriesDropdownOptionUpdated = (optionSelected: TimeSeriesDropdownOption) => {
    setSelectedDropdownItem(optionSelected);
  };

  return (
    <SeasonsPatronageStatisticsDollarsContext.Provider
      value={{
        areaDataForInterval: allData.areaData,
        onTimeSeriesDropdownOptionUpdated,
        patronageActivities: allData.activityData,
        selectedTimeSeriesDropdownOption: selectedDropdownItem
      }}
    >
      {children}
    </SeasonsPatronageStatisticsDollarsContext.Provider>
  );
};

export default SeasonsPatronageStatisticsDollarsContextProvider;
