import { useHttp } from 'hooks';
import DaoTempInviteService from './use-dao-temporary-invitation-service.model';
import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { daoTempInviteServiceURL } from './use-dao-invite-service.url';
import { UserDaoRole } from '../../../types/enum';

const useDaoTempInviteService = (): DaoTempInviteService => {
  const { post, put, _delete } = useHttp();

  const resendInvitation = useCallback(
    (daoId: string, temporaryInvitation: string): Promise<AxiosResponse<void>> => {
      return post(`${daoTempInviteServiceURL(daoId)}/${temporaryInvitation}/resend`);
    },
    [post]
  );

  const deleteInvitation = useCallback(
    (daoId: string, temporaryInvitation: string): Promise<AxiosResponse<void>> => {
      return _delete(`${daoTempInviteServiceURL(daoId)}/${temporaryInvitation}`);
    },
    [_delete]
  );

  const updateInvitation = useCallback(
    (
      daoId: string,
      temporaryInvitation: string,
      body: {
        externalIdOnCreate: string;
        roleOnCreate: UserDaoRole;
      }
    ): Promise<AxiosResponse<any>> => {
      return put(`${daoTempInviteServiceURL(daoId)}/${temporaryInvitation}`, body);
    },
    [put]
  );

  return {
    resendInvitation,
    deleteInvitation,
    updateInvitation
  };
};

export default useDaoTempInviteService;
