import { TableCell, TableRow, styled } from '@mui/material';

export const StyledDaoMemberAdminRow = styled(TableRow)(({ theme }) => ({
  height: '3rem',
  '&:hover': {
    backgroundColor: theme.palette.grey['300']
  }
}));

export const ClickableTableCell = styled(TableCell)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer'
  }
}));
