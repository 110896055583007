import { Stack, styled } from '@mui/material';

const HeaderStyled = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: '0 auto',
    width: '100%'
  }
}));

export { HeaderStyled };
