import { ProposalResultDTO, ProposalVoteDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import useHttp from 'hooks/use-http';
import IUseProposalServiceProps from './use-proposal-service.props';
import { proposalUrl } from './use-proposal-service.url';

const useProposalService = (): IUseProposalServiceProps => {
  const { get, put } = useHttp();

  const getProposalResults = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      proposalId: string
    ): Promise<AxiosResponse<ProposalResultDTO[]>> => {
      return get<Array<ProposalResultDTO>>(
        `${proposalUrl(daoId, roomId, postId, proposalId)}/results`
      );
    },
    [get]
  );

  const createProposalOptionVote = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      proposalId: string,
      proposalOptionId: string
    ): Promise<AxiosResponse<ProposalVoteDTO>> => {
      return put<ProposalVoteDTO>(
        `${proposalUrl(daoId, roomId, postId, proposalId)}/option/${proposalOptionId}`
      );
    },
    [put]
  );

  return { getProposalResults, createProposalOptionVote };
};

export default useProposalService;
