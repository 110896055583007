import { SetStateAction } from 'react';
import { CommentPostDTO, FeedPostDTO } from '@piefi-platform/types-lib';

export default interface FeedItemContextType {
  comments: CommentPostDTO[] | undefined;
  isReplyActive: boolean;
  post: FeedPostDTO | undefined;
  showActionButtons: boolean;
  showEditMode: boolean;
  votes: number;
  setIsReplyActive: (value: SetStateAction<boolean>) => void;
  setComments: (value: SetStateAction<CommentPostDTO[] | undefined>) => void;
  setPost: (callback: (value: FeedPostDTO | undefined) => FeedPostDTO) => void;
  setShowActionButtons: (value: SetStateAction<boolean>) => void;
  setShowEditForm: (value: boolean) => void;
  setVotes: (value: SetStateAction<number>) => void;
  updatePostWithComments: (value: CommentPostDTO) => void;
  setReferrer: (value?: string) => void;
  referrer?: string;
}

export const initFeedItemContextType = (): FeedItemContextType => ({
  isReplyActive: false,
  setIsReplyActive: (value: SetStateAction<boolean>): void => {},
  post: undefined,
  setPost: (value): void => {},
  comments: undefined,
  setComments: (value): void => {},
  showActionButtons: true,
  setShowActionButtons: (value: SetStateAction<boolean>): void => {},
  votes: 0,
  setVotes: (value: SetStateAction<number>): void => {},
  updatePostWithComments: (value): void => {},
  showEditMode: false,
  setShowEditForm: (value: boolean): void => {},
  setReferrer: (value?: string) => {}
});
