import { Stack, Typography } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { ArrowNarrowLeft } from 'assets/icons/arrows';
import { DIRECT_ALLOCATION } from 'constants/dao-admin-nav.labels';
import { useDao } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import DirectAllocationConfirmation from '../DirectAllocationConfirmation';
import DirectAllocationForm from '../DirectAllocationForm';
import DirectAllocationCongratulations from '../direct-allocation-congratulations/DirectAllocationCongratulations';
import { ButtonStyled } from './DirectAllocationWrapper.style';

enum DirectAllocationFormStep {
  FORM = 'FORM',
  CONFIRMATION = 'CONFIRMATION',
  CONGRATULATIONS = 'CONGRATULATIONS'
}
export interface AllocationPayload {
  amount: number;
  members: DaoMembershipDTO[];
  memo?: string;
}

interface Props {
  onCancel: () => void;
}
const DirectAllocationWrapper = ({ onCancel }: Props): React.ReactElement => {
  const { currentDao } = useDao();
  const [step, setStep] = useState<DirectAllocationFormStep>(DirectAllocationFormStep.FORM);

  const { FORM, CONFIRM } = DIRECT_ALLOCATION;

  const [allocationPayload, setAllocationPayload] = useState<AllocationPayload>(
    {} as AllocationPayload
  );

  const handleAllocationFormSubmission = useCallback((directAllocationForm: AllocationPayload) => {
    setAllocationPayload(directAllocationForm);
  }, []);

  const handleDistributionComplete = useCallback(() => {
    setStep(DirectAllocationFormStep.CONGRATULATIONS);
  }, []);

  useEffect(() => {
    if (allocationPayload.amount) {
      setStep(DirectAllocationFormStep.CONFIRMATION);
    }
  }, [allocationPayload]);

  return (
    <Stack>
      {step === DirectAllocationFormStep.FORM && (
        <Stack>
          <Typography variant="subtitle2" fontWeight={'bold'}>
            {FORM.SECTION_HEADER}
          </Typography>
          <DirectAllocationForm
            onSubmit={handleAllocationFormSubmission}
            allocationPayload={allocationPayload}
          />
        </Stack>
      )}

      {step === DirectAllocationFormStep.CONFIRMATION && (
        <Stack direction={'column'}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <ButtonStyled onClick={() => setStep(DirectAllocationFormStep.FORM)}>
              <ArrowNarrowLeft />
            </ButtonStyled>
            <Typography variant="subtitle2" fontWeight={'bold'}>
              {CONFIRM.SECTION_HEADER}
            </Typography>
          </Stack>

          <DirectAllocationConfirmation
            allocationPayload={allocationPayload}
            currentDao={currentDao}
            onDistributionComplete={handleDistributionComplete}
          />
        </Stack>
      )}

      {step === DirectAllocationFormStep.CONGRATULATIONS && (
        <DirectAllocationCongratulations
          allocationInformation={allocationPayload}
          onDoneClicked={onCancel}
        />
      )}
    </Stack>
  );
};

export default DirectAllocationWrapper;
