import {
  ProposalsIcon,
  ProposalsFilledIcon,
  BountiesIcon,
  BountiesFilledIcon,
  SummaryIcon,
  SummaryFilledIcon,
  PostsIcon,
  PostsFilledIcon,
  InfoIcon,
  InfoFilledIcon
} from 'assets';
import { DynamicNavIconProps } from './DynamicNavIcon.props';
import { ReactElement } from 'react';

export default function DynamicNavIcon({
  active,
  value,
  style
}: DynamicNavIconProps): ReactElement {
  const defaultComponents = {
    polls: ProposalsIcon,
    bounties: BountiesIcon,
    summary: SummaryIcon,
    posts: PostsIcon,
    info: InfoIcon
  };
  const filledComponents = {
    polls: ProposalsFilledIcon,
    bounties: BountiesFilledIcon,
    summary: SummaryFilledIcon,
    posts: PostsFilledIcon,
    info: InfoFilledIcon
  };
  const ComponentToRender = active
    ? filledComponents[value as keyof typeof filledComponents]
    : defaultComponents[value as keyof typeof defaultComponents];
  return <ComponentToRender style={style} />;
}
