import { IconButton, styled } from '@mui/material';

const IconButtonStyled = styled(IconButton)({
  backgroundColor: '#d1e0ff',
  borderRadius: '50%',
  height: '3.5rem',
  width: '3.5rem',
  transition: 'none',
  cursor: 'default',
  '&:hover': {
    backgroundColor: '#d1e0ff'
  }
});

export { IconButtonStyled };
