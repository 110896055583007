/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { User } from '@piefi-platform/types-lib';
import { captureException } from '@sentry/react';
import { EyeClosedIcon, EyeIcon } from 'assets';
import { InputForm, InputFormLocation } from 'components';
import { INPUT_VALIDATION } from 'constants/app-config';
import { EmailRegex, MIN_AGE_TO_REGISTER, PasswordRegex } from 'constants/auth.constants';
import { AUTH_ERROR, COMPLETE_PROFILE, CREATE_ACCOUNT } from 'constants/auth.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { HTTP_STATUS } from 'constants/http-statuses';
import { ROUTES } from 'constants/routes';
import { UI_LABELS } from 'constants/ui-labels';
import { useAuth, useGooglePlaces, useNotification } from 'hooks';
import { useAuthService } from 'hooks/services';
import UserParam from 'model/user';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Severity } from 'types/enum';
import { ErrorForm } from '../../../labels';
import { subYears } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const RegisterForm = (): React.ReactElement => {
  const minAgeRequirement = useMemo(() => subYears(new Date(), MIN_AGE_TO_REGISTER), []);
  const { notify } = useNotification();
  const navigate = useNavigate();
  const theme = useTheme();
  const { executeRegister } = useAuthService();
  const { setRegisteringUser } = useAuth();
  const [userRegisterData, setUserRegisterData] = useState<UserParam>();
  const [showPasswordText, setShowPasswordText] = useState<boolean>(false);
  const file = useRef<File>({} as File);
  const { FIRST_NAME, LAST_NAME } = COMPLETE_PROFILE;
  const { ALREADY_HAVE_AN_ACCOUNT, GET_STARTED, SIGN_UP, SIGN_IN_OVER_HERE } = CREATE_ACCOUNT;
  const { REQUIRED, MAX_LENGTH, VALID_EMAIL, VALID_PASSWORD_REQUIREMENTS, VALID_PASSWORD } =
    COMMON_VALIDATIONS;
  const { MAX_SHORT_TEXT_LENGTH } = INPUT_VALIDATION;
  const {
    clearErrors,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    getValues,
    setError
  } = useForm<UserParam>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      birthdate: minAgeRequirement,
      password: ''
    }
  });

  useEffect(() => {
    if (!userRegisterData) return;

    const registerUser = async () => {
      const { username, bio, email, password, firstName, lastName, tosAccepted, birthdate } =
        userRegisterData;

      if (!email || !tosAccepted) return;

      const userData = {
        username,
        bio,
        firstName,
        lastName,
        tosAccepted: true as true,
        birthdate
      };
      await executeRegister(userData as User, email, password, file);
    };

    registerUser();
    return () => {
      setRegisteringUser(false);
    };
  }, [userRegisterData, executeRegister, setRegisteringUser]);

  const submit: SubmitHandler<UserParam> = async ({ password, ...user }: UserParam) => {
    if (!user.email) return;

    try {
      const { email, firstName, lastName, tosAccepted } = user;

      if (!email || !tosAccepted) return;

      const userData = {
        firstName,
        lastName,
        tosAccepted: true as true,
        birthdate: user.birthdate
      };
      await executeRegister(userData as User, email, password, file);
    } catch (error: any) {
      captureException(error);
      // const axiosError = error as AxiosError;
      if (error.code === 'auth/weak-password)') {
        notify('Password must be at least 6 characters', { severity: Severity.Error });
      } else if (error.code === 'auth/wrong-password') {
        notify('Incorrect password. Please try again.', { severity: Severity.Error });
        return;
      } else if (error.code === 'auth/email-already-in-use') {
        notify('Email already in use.', { severity: Severity.Error });
        return;
      } else if (error.code === 'auth/invalid-email') {
        notify('Email Invalid', { severity: Severity.Error });
        return;
      } else if (error.code === 'auth/too-many-requests') {
        notify('Account has been locked try again later.', { severity: Severity.Error });
        return;
      }

      if (error.response?.status === HTTP_STATUS.BAD_REQUEST) {
        notify(AUTH_ERROR.REGISTRATION_ERROR(error.response.message), { severity: Severity.Info });
        return;
      } else {
        // shows generic error
        notify(`${error.response?.data.message ?? AUTH_ERROR.UNKNOWN_REGISTRATION_ERROR}`, {
          severity: Severity.Error
        });
        return;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack>
        <Typography variant={'h6'}>{SIGN_UP}</Typography>
        <Stack direction={'row'} paddingY={'1.5rem'}>
          <Typography variant="body2" paddingRight={'0.25rem'}>
            {ALREADY_HAVE_AN_ACCOUNT}
          </Typography>
          <Link
            data-testid="btn-create-account"
            underline="none"
            component="button"
            type="button"
            variant="body2"
            onClick={() => navigate(ROUTES.AUTH)}
          >
            {SIGN_IN_OVER_HERE}
          </Link>
        </Stack>
        <Stack spacing={2} paddingTop={'2.5rem'}>
          <Stack data-testid="email">
            <Controller
              control={control}
              name="email"
              rules={{
                required: REQUIRED,
                maxLength: { value: 64, message: MAX_LENGTH(64) },
                pattern: {
                  value: EmailRegex,
                  message: VALID_EMAIL
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputForm
                    id={field.name}
                    data-testid={field.name}
                    {...field}
                    autoFocus
                    fullWidth
                    hiddenLabel
                    error={!!fieldState.error}
                    placeholder="Email"
                    size="small"
                    type="text"
                    variant="outlined"
                  />
                );
              }}
            />
            <div>{errors?.email && <ErrorForm message={errors.email.message} />}</div>
          </Stack>
          <Stack data-testid="password-wrapper">
            <Controller
              control={control}
              name="password"
              rules={{
                required: REQUIRED,
                pattern: {
                  value: PasswordRegex,
                  message: VALID_PASSWORD
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputForm
                    id={field.name}
                    data-testid={field.name}
                    {...field}
                    onChange={field.onChange}
                    fullWidth
                    hiddenLabel
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPasswordText(!showPasswordText)}
                            edge="end"
                          >
                            {showPasswordText ? <EyeIcon /> : <EyeClosedIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={!!fieldState.error}
                    placeholder="Password"
                    size="small"
                    helperText={
                      <Typography variant="caption" textAlign={'center'}>
                        {VALID_PASSWORD_REQUIREMENTS}
                      </Typography>
                    }
                    type={showPasswordText ? 'text' : 'password'}
                    variant="outlined"
                  />
                );
              }}
            />
            {errors?.password && <ErrorForm message={errors.password.message} />}
          </Stack>
        </Stack>

        <Divider style={{ margin: '1.5rem 0', color: theme.palette.secondary.main }} />

        <Stack spacing={2}>
          <Stack direction={'row'} spacing={2} data-testid="names">
            <Stack>
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: REQUIRED,
                  maxLength: {
                    value: MAX_SHORT_TEXT_LENGTH,
                    message: MAX_LENGTH(MAX_SHORT_TEXT_LENGTH)
                  }
                }}
                render={({ field, fieldState }) => {
                  return (
                    <InputForm
                      id={field.name}
                      data-testid={field.name}
                      {...field}
                      fullWidth
                      onChange={field.onChange}
                      hiddenLabel
                      error={!!fieldState.error}
                      placeholder={FIRST_NAME}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  );
                }}
              />
              {errors?.firstName && <ErrorForm message={errors.firstName.message} />}
            </Stack>
            <Stack>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: REQUIRED, maxLength: { value: 64, message: MAX_LENGTH(64) } }}
                render={({ field, fieldState }) => {
                  return (
                    <InputForm
                      id={field.name}
                      data-testid={field.name}
                      {...field}
                      fullWidth
                      hiddenLabel
                      onChange={field.onChange}
                      inputProps={{ maxLength: { value: 64, message: MAX_LENGTH(64) } }}
                      error={!!fieldState.error}
                      placeholder={LAST_NAME}
                      size="small"
                      type="text"
                      variant="outlined"
                    />
                  );
                }}
              />
              {errors?.lastName && <ErrorForm message={errors.lastName.message} />}
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Stack direction={'row'} alignItems={'center'} paddingTop={'1.5rem'} data-testid="tos">
            <Controller
              name="tosAccepted"
              control={control}
              rules={{ required: REQUIRED }}
              render={({ field: { name, value, onChange, ref } }) => (
                <Checkbox
                  id={name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  role="checkbox"
                  data-testid="acknowledge-document"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      height: 20,
                      width: 20,
                      bgcolor: 'transparent',
                      path: { fill: 'transparent' },
                      rect: { stroke: theme.palette.secondary.main, strokeWidth: '1' }
                    },
                    '&:hover': { bgcolor: 'transparent' }
                  }}
                />
              )}
            />
            <Typography variant="body2">
              I agree to{' '}
              <Link
                href="https://www.awsm.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
              >
                Terms of Service
              </Link>
              ,{' '}
              <Link
                href="https://www.awsm.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
              >
                Privacy Policy
              </Link>
              , and{' '}
              <Link
                href="https://www.awsm.com/member-agreement"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
              >
                Member Agreement
              </Link>
              , and am at least {MIN_AGE_TO_REGISTER} years old.
            </Typography>
          </Stack>
          {errors?.tosAccepted && <ErrorForm message={errors.tosAccepted.message} />}
        </Stack>
        <Button
          type="submit"
          variant="contained"
          size="extraLarge"
          style={{ marginTop: '1.5rem' }}
          disabled={!isDirty || !isValid || !getValues('tosAccepted')}
        >
          {GET_STARTED}
        </Button>
      </Stack>
    </form>
  );
};

export default RegisterForm;
