import { ApiKeyCreateResponseDTO, ApiKeyDTO, BaseQueryParams } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import ApiKeyService from './use-api-key.service.model';
import { apiKeyUrl } from './use-api-key.service.url';

const useApiKeyService = (): ApiKeyService => {
  const { get, post, patch, _delete } = useHttp();

  const getApiKeysByDao = useCallback(
    (daoId: string, query: BaseQueryParams): Promise<AxiosResponse<ApiKeyDTO[]>> => {
      return get<ApiKeyDTO[]>(`${apiKeyUrl(daoId)}?page=${query.page}&size=${query.size}`);
    },
    [get]
  );

  const updateApiKey = useCallback(
    (daoId: string, apiKeyId: string, payload: ApiKeyDTO): Promise<AxiosResponse<ApiKeyDTO>> => {
      return patch<ApiKeyDTO>(`${apiKeyUrl(daoId)}/${apiKeyId}`, payload);
    },
    [patch]
  );

  const createApiKey = useCallback(
    (daoId: string, payload: any): Promise<AxiosResponse<ApiKeyCreateResponseDTO>> => {
      return post<ApiKeyCreateResponseDTO>(apiKeyUrl(daoId), payload);
    },
    [post]
  );

  const deleteKey = useCallback(
    (daoId: string, apiKeyId: string): Promise<AxiosResponse<void>> => {
      return _delete<void>(`${apiKeyUrl(daoId)}/${apiKeyId}`);
    },
    [_delete]
  );

  return {
    getApiKeysByDao,
    updateApiKey,
    createApiKey,
    deleteKey
  };
};

export default useApiKeyService;
