import Check from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import {
  AllocationAgreement,
  AllocationAgreementDTO,
  PatronageActivityUpdatePayload,
  ValuationEventDTO
} from '@piefi-platform/types-lib';
import { CloseIcon, DivisionOperatorBox, USDIcon } from 'assets';
import { ColorPicker, InputForm, SelectForm } from 'components/inputs';
import TagsManager from 'components/inputs/tags-manager/TagsManager';
import { ErrorForm } from 'components/labels';
import { MAX_FILE_SIZE_IN_MB } from 'constants/app-config';
import { DAO_ADMIN_LABELS, PATRONAGE_ACTIVITY_FORM } from 'constants/dao-admin-nav.labels';
import { useActivityTags, useDao, useNotification, usePatronageActivityStepper } from 'hooks';
import { useDaoService, usePatronageActivityService, useValuationService } from 'hooks/services';
import {
  PatronageActivityPayload as PatronageActivity,
  PatronageActivityCreatePayload
} from 'model';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FieldArrayWithId, useFieldArray, useForm } from 'react-hook-form';
import { Severity, TagVisibility } from 'types/enum';
import DistributionType, { ArithmeticOperator } from 'types/enum/points-label.enum';
import { formatDateLong, numberFormatter, toTitleCase, uploadImage } from 'utils';
import PatronageActivityFormProps from './PatronageActivityForm.props';

const PatronageActivityForm = ({
  daoId,
  header,
  patronageActivity,
  cancelForm,
  onSubmit
}: PatronageActivityFormProps): React.ReactElement => {
  const { currentDao } = useDao();
  const { getDaoInfo } = useDaoService();

  const { getMostRecentValuation } = useValuationService();
  const { activityToEdit, setActivityToEdit } = usePatronageActivityStepper();
  const { notify } = useNotification();
  const activity = useRef<PatronageActivity>(patronageActivity);
  const { handleTagDetach, handleTagSelection, tags, attachTagsToActivity } =
    useActivityTags(patronageActivity);
  const [currentValuationInBitPrice, setCurrentValuationInBitPrice] = useState<
    ValuationEventDTO | undefined
  >();
  const [color, setColor] = useState<string>(patronageActivity.color);
  const [tagsEnabled, setTagsEnabled] = useState<boolean>(
    (activity.current.tags && activity.current.tags?.length > 0) || false
  );
  const [variableEnabled, setVariableEnabled] = useState<boolean>(!!activity.current.variablePoint);
  const { create, update } = usePatronageActivityService();
  const { CANCEL, SAVE, MIN_POINT_AMOUNT, REQUIRED_FIELD, MIN_MAX_LENGTH } = DAO_ADMIN_LABELS;
  const {
    ADD_ACTIVITY,
    ACTIVITY_ADDED,
    ACTIVITY_UPDATED,
    TITLE_SECTION,
    DESCRIPTION_SECTION,
    COLOR_SECTION,
    POINT_AWARD_VALUE_SECTION,
    VISIBILITY_SECTION,
    ALLOCATION_AGREEMENTS
  } = PATRONAGE_ACTIVITY_FORM;
  const MIN_POINT_AMOUNT_VALUE = 0;
  const formId = 'patronage-activity-form';

  const ArithmeticOperatorIconMap = {
    [ArithmeticOperator.DIVIDE]: <DivisionOperatorBox fill="white" fontSize="large" />
  };

  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    getValues,
    formState: { errors, isValid, isSubmitting }
  } = useForm<PatronageActivity>({
    defaultValues: {
      ...activity.current,
      tagVisibility: activity.current.tags?.length
        ? TagVisibility.BY_MEMBER_TAG
        : TagVisibility.ALL_MEMBERS,
      pointAwardCategory: activity.current.variablePoint
        ? DistributionType.VARIABLE
        : DistributionType.FIXED
    },
    shouldFocusError: true,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { fields, append, remove } = useFieldArray<PatronageActivity>({
    control,
    name: 'allocationAgreements'
  });

  const createOrUpdate = useCallback(
    async (
      { tagVisibility, pointAwardCategory, tags, ...data }: PatronageActivityCreatePayload,
      event: any
    ) => {
      if (!daoId || event.target.id !== formId) return;
      const { description, name, points, variablePoint } = data;

      let activityId = activity.current.id;
      const isUpdating = !!activityId;

      let result;

      // Filter out new agreements which have a file attached
      const newAgreements = data?.allocationAgreements?.filter((agreement) => agreement.file) || [];

      // Upload images for new agreements and construct their details
      const uploadedAgreements = await Promise.all(
        newAgreements.map(async (agreement) => {
          const imageUrl = await uploadImage(
            `daos/${currentDao.id}/activities/${data.name}_${new Date().toISOString()}`,
            agreement.file!
          );
          return {
            fileName: agreement.fileName,
            fileUrl: imageUrl
          };
        })
      );

      // Construct the final list of agreements
      let finalList = [...uploadedAgreements];

      if (uploadedAgreements.length > 0 && patronageActivity.allocationAgreements) {
        const markedDeletedAgreements = patronageActivity.allocationAgreements.map((agreement) => ({
          fileName: agreement.fileName,
          fileUrl: agreement.fileUrl,
          id: agreement.id,
          deleted: true
        }));
        finalList = [...finalList, ...markedDeletedAgreements];
      }
      if (isUpdating) {
        let { data: response } = await update(activityId!, daoId, {
          description: description || null,
          name,
          points: variableEnabled ? undefined : points,
          color,
          variablePoint: variableEnabled ? variablePoint : undefined,
          allocationAgreements: finalList
        } as PatronageActivityUpdatePayload);

        result = { ...response };
      } else {
        const { data: newActivity } = await create(daoId, {
          ...data,
          color,
          category: 'OTHER',
          description: description || null,
          allocationAgreements: finalList
        });

        result = { ...newActivity };
        await attachTagsToActivity(result.id);
      }

      setActivityToEdit(undefined);
      notify(isUpdating ? ACTIVITY_UPDATED : ACTIVITY_ADDED, { severity: Severity.Success });
      onSubmit && onSubmit(result);

      cancelForm();
    },
    [
      daoId,
      setActivityToEdit,
      notify,
      ACTIVITY_UPDATED,
      ACTIVITY_ADDED,
      onSubmit,
      tags,
      cancelForm,
      update,
      variableEnabled,
      color,
      create
    ]
  );

  const cancel = useCallback(() => {
    if (activityToEdit) setActivityToEdit(undefined);
    cancelForm();
  }, [activityToEdit, cancelForm, setActivityToEdit]);

  // prevent form submission on Enter
  const checkFormKeyDown = useCallback((event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  /**
   * Create a new optional option text
   */
  const addNewOption = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files?.length || !e.target.files[0]) return;

    if (e.target.files[0].size > MAX_FILE_SIZE_IN_MB.ALLOCATION_AGREEMENT) {
      notify(
        `File must be less than: ${MAX_FILE_SIZE_IN_MB.ALLOCATION_AGREEMENT / 1024 / 1024}MB`,
        { severity: Severity.Error }
      );
      return;
    }

    append({
      fileName: e.target.files[0].name || 'missing name',
      fileUrl: '',
      file: e.target.files[0]
    });

    // Reset the value of the input
    e.target.value = '';
  };

  const removeOption = (item: any, index: number) => {
    remove(index);
  };

  useEffect(() => {
    // this code triggers time validations after a date is selected
    const tagVisibilitySubscription = watch(({ tagVisibility }, { name, type }) => {
      if (name === 'tagVisibility' && type === 'change') {
        setTagsEnabled(tagVisibility === TagVisibility.BY_MEMBER_TAG);
      }
    });

    // this code triggers time validations after a date is selected
    const pointAwardCategorySubscription = watch(({ pointAwardCategory }, { name, type }) => {
      if (name === 'pointAwardCategory' && type === 'change') {
        const enabled = pointAwardCategory === DistributionType.VARIABLE;
        if (enabled) {
          setValue('points', undefined as any);
          setValue('variablePoint', {
            operandValue: '' as unknown as number,
            operator: 'MULTIPLY'
          });
        } else {
          setValue('points', '' as any);
          setValue('variablePoint', undefined);
        }

        setVariableEnabled(enabled);
      }
    });

    return () => {
      tagVisibilitySubscription.unsubscribe();
      pointAwardCategorySubscription.unsubscribe();
    };
  }, [setValue, watch]);

  useEffect(() => {
    const getData = async () => {
      const { data: daoInfo } = await getDaoInfo(currentDao.id);
      const currentValuation = await getMostRecentValuation(daoInfo.companyId, true);
      setCurrentValuationInBitPrice({ ...currentValuation.data[0] });
    };
    getData();
  }, []);

  if (!daoId) return <></>;

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(createOrUpdate)}
      onKeyDownCapture={checkFormKeyDown}
      data-testid={formId}
    >
      <Card style={{ padding: 0 }}>
        {header && (
          <Box style={{ height: '4.25rem' }} data-testid="header-container">
            <Box style={{ padding: '1.5rem' }}>{header}</Box>
            <Divider />
          </Box>
        )}
        <CardContent style={{ padding: '1.5rem' }}>
          <Stack direction="column" spacing={4} display="flex" justifyContent="space-between">
            <Stack data-testid="title-section">
              <Typography variant="body1" fontWeight={800} marginBottom={'.25rem'}>
                {TITLE_SECTION.HEADER}
              </Typography>
              <Typography variant="body2" marginBottom="1rem" color="secondary">
                {TITLE_SECTION.DESCRIPTION}
              </Typography>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <InputForm
                    id={field.name}
                    {...field}
                    {...register('name', {
                      required: {
                        value: true,
                        message: REQUIRED_FIELD('name')
                      },
                      minLength: {
                        value: 4,
                        message: MIN_MAX_LENGTH('name', 'min', 4)
                      },
                      maxLength: {
                        value: 64,
                        message: MIN_MAX_LENGTH('name', 'max', 64)
                      }
                    })}
                    placeholder={TITLE_SECTION.PLACEHOLDER}
                    hiddenLabel
                    autoFocus
                    fullWidth
                    size="small"
                    type="text"
                    variant="outlined"
                    bold={true}
                    error={!!fieldState.error}
                    inputProps={{ maxLength: 128 }}
                  />
                )}
              />
              {errors?.name && <ErrorForm message={errors.name.message} />}
            </Stack>
            <Stack data-testid="description-section">
              <Typography variant="body1" fontWeight={800} marginBottom={'.25rem'}>
                {DESCRIPTION_SECTION.HEADER}
              </Typography>
              <Typography variant="body2" marginBottom="1rem" color="secondary">
                {DESCRIPTION_SECTION.DESCRIPTION}
              </Typography>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <InputForm
                    {...field}
                    {...register('description', {
                      required: {
                        value: false,
                        message: REQUIRED_FIELD('description')
                      },
                      minLength: {
                        value: 0,
                        message: MIN_MAX_LENGTH('description', 'min', 4)
                      },
                      maxLength: {
                        value: 1024,
                        message: MIN_MAX_LENGTH('description', 'max', 1024)
                      }
                    })}
                    placeholder={DESCRIPTION_SECTION.PLACEHOLDER}
                    hiddenLabel
                    fullWidth
                    size="small"
                    type="text"
                    variant="outlined"
                    bold={true}
                    error={!!fieldState.error}
                    inputProps={{ maxLength: 1024 }}
                  />
                )}
              />
              {errors?.description && <ErrorForm message={errors.description.message} />}
            </Stack>
            <Stack data-testid="color-section">
              <Typography variant="body1" fontWeight={800} marginBottom={'.25rem'}>
                {COLOR_SECTION.HEADER}
              </Typography>
              <Typography variant="body2" marginBottom="1rem" color="secondary">
                {COLOR_SECTION.DESCRIPTION}
              </Typography>
              <Stack direction={'row'}>
                <ColorPicker onColorSelected={(color) => setColor(color)} defaultColor={color} />
              </Stack>
            </Stack>
            <Stack data-testid="allocation-agreement">
              <Typography variant="body1" fontWeight={800} marginBottom={'.25rem'}>
                {ALLOCATION_AGREEMENTS.HEADER}
              </Typography>
              <Typography variant="body2" marginBottom="1rem" color="secondary">
                {ALLOCATION_AGREEMENTS.DESCRIPTION}
              </Typography>
              <Button
                style={{ minWidth: '4rem', maxWidth: '4rem' }}
                variant="outlined"
                component="label"
                disabled={getValues('allocationAgreements')?.filter((i) => !i.deleted).length === 1}
              >
                <Typography variant="body1">{PATRONAGE_ACTIVITY_FORM.ADD}</Typography>
                <input
                  hidden
                  accept="application/pdf"
                  type="file"
                  onChange={(e) => addNewOption(e)}
                />
              </Button>
              {fields.map((option: FieldArrayWithId<PatronageActivity>, index: number) => {
                const createdName = `${
                  (fields.at(index) as AllocationAgreementDTO).createdBy?.user.firstName
                } ${(fields.at(index) as AllocationAgreementDTO).createdBy?.user.lastName}`;

                return (
                  <Stack
                    key={option.id}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignContent={'center'}
                    spacing={1}
                  >
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      {(fields.at(index) as AllocationAgreementDTO).createdAt && (
                        <Check color={'success'} />
                      )}
                      {(fields.at(index) as AllocationAgreementDTO).fileUrl ? (
                        <Link
                          href={(fields.at(index) as AllocationAgreementDTO).fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                        >
                          {(fields.at(index) as AllocationAgreement).fileName}
                        </Link>
                      ) : (
                        <Typography variant="caption" color="secondary">
                          {(fields.at(index) as AllocationAgreement).fileName}
                        </Typography>
                      )}
                      {(fields.at(index) as AllocationAgreementDTO).createdBy ? (
                        <Tooltip
                          title={ALLOCATION_AGREEMENTS.TOOL_TIP(
                            createdName,
                            (fields.at(index) as AllocationAgreementDTO).createdAt as any
                          )}
                        >
                          <Typography variant="caption">
                            (Uploaded By: {createdName}
                            {' on '}
                            {formatDateLong(
                              (fields.at(index) as AllocationAgreementDTO).createdAt as any
                            )}
                            )
                          </Typography>
                        </Tooltip>
                      ) : null}
                    </Stack>
                    <IconButton onClick={() => removeOption(option, index)}>
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
            <Stack data-testid="point-section">
              <Typography variant="body1" fontWeight={800} marginBottom={'.25rem'}>
                {POINT_AWARD_VALUE_SECTION.HEADER}
              </Typography>
              <Typography variant="body2" marginBottom="1rem" color="secondary">
                {POINT_AWARD_VALUE_SECTION.DESCRIPTION}
              </Typography>
              <Controller
                name="pointAwardCategory"
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState }) => (
                  <SelectForm
                    id={field.name}
                    data-testid="tag-visibility-select"
                    {...field}
                    fullWidth
                    invalid={!!fieldState.error}
                    style={{ marginBottom: '1rem' }}
                  >
                    {Object.keys(DistributionType).map((pointAwardType: string) => {
                      return (
                        <MenuItem
                          key={pointAwardType}
                          value={pointAwardType}
                          style={{ padding: '1rem' }}
                        >
                          {toTitleCase(pointAwardType)}
                        </MenuItem>
                      );
                    })}
                  </SelectForm>
                )}
              />
              {variableEnabled ? (
                <Stack
                  direction={'row'}
                  style={{ width: '100%' }}
                  spacing={4}
                  alignItems={'center'}
                >
                  <Stack width={'45%'}>
                    <InputForm disabled={true} placeholder="Event Value" fullWidth />
                  </Stack>
                  <Stack width={'10%'} height={'100%'}>
                    <Controller
                      name="variablePoint.operator"
                      control={control}
                      defaultValue="MULTIPLY"
                      rules={{ required: false }}
                      render={({ field, fieldState }) => (
                        <SelectForm
                          id={field.name}
                          data-testid="tag-visibility-select"
                          {...field}
                          sx={{
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 0
                            },
                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                border: 0
                              }
                          }}
                          invalid={!!fieldState.error}
                          fullWidth
                          renderValue={(value) => (
                            <>
                              {ArithmeticOperatorIconMap[value as keyof typeof ArithmeticOperator]}
                            </>
                          )}
                        >
                          {Object.keys(ArithmeticOperator).map((pointAwardType: string) => {
                            return (
                              <MenuItem
                                key={pointAwardType}
                                value={pointAwardType}
                                style={{ paddingLeft: '1.5rem', paddingTop: '1rem' }}
                              >
                                <>
                                  {
                                    ArithmeticOperatorIconMap[
                                      pointAwardType as keyof typeof ArithmeticOperator
                                    ]
                                  }
                                </>
                              </MenuItem>
                            );
                          })}
                        </SelectForm>
                      )}
                    />
                  </Stack>
                  <Stack width={'45%'}>
                    <Controller
                      name="variablePoint.operandValue"
                      control={control}
                      rules={{ required: false }}
                      render={({ field, fieldState }) => (
                        <InputForm
                          id={field.name}
                          {...field}
                          {...register('variablePoint.operandValue', {
                            required: {
                              value: true,
                              message: REQUIRED_FIELD('value')
                            },
                            min: {
                              value: MIN_POINT_AMOUNT_VALUE,
                              message: MIN_POINT_AMOUNT(MIN_POINT_AMOUNT_VALUE)
                            }
                          })}
                          placeholder={POINT_AWARD_VALUE_SECTION.PLACEHOLDER}
                          hiddenLabel
                          fullWidth
                          type="number"
                          variant="outlined"
                          bold={true}
                          error={!!fieldState.error}
                        />
                      )}
                    />
                    {errors?.variablePoint?.operandValue && (
                      <ErrorForm message={errors?.variablePoint?.operandValue.message} />
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Stack data-testid="point-award-value-section">
                  <Controller
                    name="points"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field, fieldState }) => (
                      <InputForm
                        id={field.name}
                        {...field}
                        {...register('points', {
                          required: {
                            value: true,
                            message: REQUIRED_FIELD('value')
                          },
                          min: {
                            value: MIN_POINT_AMOUNT_VALUE,
                            message: MIN_POINT_AMOUNT(MIN_POINT_AMOUNT_VALUE)
                          }
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <USDIcon
                                fill="#FFFFFF"
                                iconColor="black"
                                onlyDollarSign
                                sx={{ height: '1.5rem', width: '1.5rem' }}
                              />
                            </InputAdornment>
                          )
                        }}
                        placeholder={POINT_AWARD_VALUE_SECTION.PLACEHOLDER}
                        hiddenLabel
                        fullWidth
                        style={{ maxWidth: '9.125rem' }}
                        size="small"
                        type="number"
                        variant="outlined"
                        bold={true}
                        error={!!fieldState.error}
                      />
                    )}
                  />
                  {watch('points') && currentValuationInBitPrice?.sharePrice && (
                    <Typography variant="caption">
                      {numberFormatter.formatterWithTwoDecimals(
                        watch('points')! / currentValuationInBitPrice?.sharePrice
                      )}{' '}
                      bits
                    </Typography>
                  )}
                  {errors?.points && <ErrorForm message={errors.points.message} />}
                </Stack>
              )}
            </Stack>
            <Stack data-testid="tags-selection-section">
              <Typography variant="body1" fontWeight={800} marginBottom={'.25rem'}>
                {VISIBILITY_SECTION.HEADER}
              </Typography>
              <Typography variant="body2" marginBottom="1rem" color="secondary">
                {VISIBILITY_SECTION.DESCRIPTION}
              </Typography>
              <Controller
                name="tagVisibility"
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState }) => (
                  <SelectForm
                    id={field.name}
                    data-testid="tag-visibility-select"
                    {...field}
                    fullWidth
                    invalid={!!fieldState.error}
                  >
                    {Object.keys(TagVisibility).map((visibilityType: string) => {
                      return (
                        <MenuItem
                          key={visibilityType}
                          value={visibilityType}
                          style={{ padding: '1rem' }}
                        >
                          {toTitleCase(visibilityType)}
                        </MenuItem>
                      );
                    })}
                  </SelectForm>
                )}
              />
              {tagsEnabled && (
                <TagsManager
                  tagsAssigned={tags}
                  onCreateAndAttach={handleTagSelection}
                  onDetach={handleTagDetach}
                />
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <Stack direction="row" marginTop="3rem" gap={activity.current.id ? 1 : 0}>
        {activity.current.id && (
          <Button
            color="primary"
            data-testid="cancel"
            disabled={isSubmitting}
            size="extraLarge"
            style={{ width: '50%' }}
            variant="contained"
            onClick={cancel}
          >
            {CANCEL}
          </Button>
        )}
        <Button
          color="primary"
          data-testid="submit"
          disabled={
            isSubmitting || !isValid || !color || getValues('allocationAgreements')?.length !== 1
          }
          size="extraLarge"
          style={{ width: activity.current.id ? '50%' : '100%', padding: 0 }}
          type="submit"
          variant="contained"
        >
          {isSubmitting ? (
            <Skeleton animation="pulse" variant="rectangular" height={'100%'} width={'100%'} />
          ) : (
            <Typography>{activity.current.id ? SAVE : ADD_ACTIVITY}</Typography>
          )}
        </Button>
      </Stack>
    </form>
  );
};

export default PatronageActivityForm;
