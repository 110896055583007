import { Box, Button, Checkbox, FormControlLabel, Stack, Theme, Typography } from '@mui/material';
import { PostsIcon } from 'assets';
import { InputForm, TextEditor } from 'components';
import { SizeContainer } from 'components/containers';
import { ErrorForm } from 'components/labels';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { HTTP_STATUS } from 'constants/http-statuses';
import { POST_FORM_LABELS } from 'constants/ui-labels';
import { useFeed, useFeedPage } from 'hooks';
import { usePostService } from 'hooks/services';
import { PostParam } from 'model';
import { ReactElement, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UserDaoRole } from 'types/enum';
import compareDaoRoles from 'utils/helper/compare-dao-roles';
import { CreatePostFormProps } from './CreatePostForm.props';

const CreatePostForm = ({
  editMode,
  enableUpdates,
  forceUpdates,
  daoId,
  room,
  userDaoRole
}: CreatePostFormProps): ReactElement => {
  const { posts, setPosts, setShowCreatePostForm } = useFeedPage();
  const { post: currentPost, setPost, setShowEditForm } = useFeed();
  const { createPost, updatePost } = usePostService();
  const isUserAdmin = compareDaoRoles(UserDaoRole.ADMIN, userDaoRole);
  const isDefaultRoom = room?.isDefault;
  const shouldAllowUpdates = (isUserAdmin && isDefaultRoom) || enableUpdates;
  const {
    POST_TITLE_PLACEHOLDER,
    NEW_POST,
    POST_CONTENT_PLACEHOLDER,
    POST,
    DISPLAY_POST_AS_ANNOUNCEMENT
  } = POST_FORM_LABELS;
  const { REQUIRED } = COMMON_VALIDATIONS;
  const { CANCEL, SAVE } = BUTTON_LABELS;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<PostParam>({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      title: editMode ? currentPost?.title : '',
      content: editMode ? currentPost?.content : '',
      isAnnouncement: editMode ? currentPost?.isAnnouncement : false
    }
  });

  const update = useCallback(
    async (editedPost: PostParam) => {
      try {
        if (editMode && currentPost && editedPost) {
          const { title, content, isAnnouncement } = editedPost;
          const editPostRes = await updatePost(
            daoId,
            room.id,
            currentPost.id,
            title,
            content ?? undefined,
            isAnnouncement
          );

          if (editPostRes.status === HTTP_STATUS.OK) {
            setPost(() => editPostRes.data);
            setShowEditForm(false);
          }
        }
      } catch (error) {}
    },
    [daoId, room, editMode, currentPost, setPost, setShowEditForm, updatePost]
  );

  const create = useCallback(
    async (post: PostParam) => {
      const { title, content, isAnnouncement } = post;
      const createPostRes = await createPost(
        daoId,
        room.id,
        title,
        content ?? undefined,
        forceUpdates || isAnnouncement
      );

      if (createPostRes.status === HTTP_STATUS.OK) {
        setPosts([createPostRes.data, ...posts]);
        setShowCreatePostForm(false);
      }
    },
    [createPost, daoId, forceUpdates, posts, room.id, setPosts, setShowCreatePostForm]
  );

  const submit = async (formPost: PostParam) => {
    if (editMode) {
      update(formPost);
    } else {
      create(formPost);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack padding={(theme: Theme) => ({ xs: 2, md: theme.spacing(3, 6, 6, 6) })} gap={3}>
        <Stack direction="row" gap={1.5} alignItems="center">
          <PostsIcon style={{ height: '1.125rem', width: '1.25rem' }} fill="text.primary" />
          <Typography variant="h6" color="text.primary">
            {NEW_POST}
          </Typography>
        </Stack>
        <SizeContainer width="100%">
          <Controller
            name="title"
            control={control}
            rules={{ required: REQUIRED }}
            render={({ field, fieldState }) => (
              <InputForm
                id={field.name}
                {...field}
                hiddenLabel
                fullWidth
                autoFocus
                placeholder={POST_TITLE_PLACEHOLDER}
                size="small"
                type="text"
                variant="outlined"
                bold={true}
                error={!!fieldState.error}
                inputProps={{ maxLength: 128 }}
              />
            )}
          />
          {errors?.title && <ErrorForm message={errors.title.message} />}
        </SizeContainer>
        <Controller
          name="content"
          control={control}
          rules={{ maxLength: 4096 }}
          render={({ field: { name, value, onChange, ref } }) => (
            <TextEditor
              name={name}
              value={value || ''}
              showActions={false}
              placeholder={POST_CONTENT_PLACEHOLDER}
              toolBarId="post-id"
              onChange={onChange}
              ref={ref}
            />
          )}
        />
        {shouldAllowUpdates && !editMode && (
          <Controller
            name="isAnnouncement"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    id={name}
                    name={name}
                    checked={forceUpdates || value}
                    value={value}
                    onChange={onChange}
                    ref={ref}
                  />
                }
                label={DISPLAY_POST_AS_ANNOUNCEMENT}
                style={{ marginLeft: '-.5rem' }}
              />
            )}
          />
        )}
        <Stack direction={editMode ? 'row' : 'column'} alignItems="center" gap={editMode ? 3 : 0}>
          <Box width={editMode ? '50%' : '100%'}>
            {editMode && (
              <Button
                onClick={() => setShowEditForm(false)}
                role="button"
                type="submit"
                color="primary"
                size="large"
                variant="outlined"
                fullWidth
                style={{ height: '3.25rem' }} // TODO: create new button size variant
              >
                {CANCEL}
              </Button>
            )}
          </Box>
          <Box width={editMode ? '50%' : '100%'}>
            <Button
              role="button"
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              style={{ height: '3.25rem' }} // TODO: create new button size variant
            >
              {editMode ? SAVE : POST}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
};

export default CreatePostForm;
