import { Button, buttonClasses, styled } from '@mui/material';

export const CommentCounterButtonStyled = styled(Button)(({ theme }) => ({
  [`&.${buttonClasses.text}`]: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    border: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecorationLine: 'underline'
    }
  }
}));
