import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ConsoleIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '22px';
  const height = props.height ? `${props.height}px` : '16px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 22 16" {...props}>
      <path
        d="M20.6219 0.77C20.1319 0.27 19.4919 0 18.7819 0H2.78187C2.00187 0 1.37187 0.240003 0.901869 0.710003C-0.0181307 1.64 0.0118694 3.18 0.0118694 4.41C0.0118694 4.6 0.0118694 4.79 0.0118694 4.95C-0.00813064 7.09 -0.0181306 9.61 0.111869 12.12C0.131869 12.57 0.161869 13.14 0.361869 13.69C0.811869 14.92 2.05187 15.52 3.93187 15.52H4.05187C6.23187 15.49 8.49187 15.46 10.7019 15.54C11.2019 15.56 11.7119 15.58 12.2219 15.6C13.2519 15.65 14.3019 15.69 15.3619 15.69C16.2419 15.69 17.1119 15.66 17.9919 15.57C19.6419 15.4 21.4119 15 21.5019 12.31C21.5319 11.46 21.5619 10.61 21.6019 9.76C21.6719 8.02 21.7419 6.29 21.7819 4.55C21.7919 3.96 21.7419 1.94 20.6019 0.77H20.6219ZM20.1219 9.69C20.0919 10.54 20.0519 11.4 20.0219 12.25C19.9719 13.56 19.5719 13.9 17.8619 14.07C16.0319 14.26 14.1319 14.18 12.3019 14.09C11.7919 14.07 11.2819 14.05 10.7719 14.03C9.69187 13.99 8.60187 13.98 7.51187 13.98C6.35187 13.98 5.19187 13.99 4.05187 14.01H3.93187C2.27187 13.99 1.92187 13.54 1.79187 13.17C1.67187 12.84 1.64187 12.42 1.63187 12.05C1.51187 9.58 1.51187 7.08 1.53187 4.97C1.53187 4.79 1.53187 4.59 1.53187 4.39C1.52187 3.49 1.50187 2.25 1.98187 1.77C2.04187 1.7 2.24187 1.5 2.79187 1.5H18.7919C19.1019 1.5 19.3519 1.6 19.5619 1.82C20.1019 2.38 20.3319 3.57 20.3119 4.52C20.2719 6.25 20.2119 7.97 20.1419 9.7L20.1219 9.69Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M8.42229 7.15L5.16229 4.93C4.82229 4.7 4.35229 4.78 4.12229 5.13C3.89229 5.47 3.97229 5.94 4.32229 6.17L7.58229 8.39C7.58229 8.39 7.65229 8.44 7.68229 8.46C7.65229 8.49 7.62229 8.52 7.58229 8.55C7.35229 8.75 7.11229 8.96 6.88229 9.16C6.12229 9.81 5.34229 10.49 4.65229 11.25C4.37229 11.56 4.40229 12.03 4.71229 12.31C4.85229 12.44 5.03229 12.5 5.21229 12.5C5.42229 12.5 5.62229 12.42 5.77229 12.25C6.39229 11.56 7.14229 10.92 7.86229 10.3C8.10229 10.09 8.34229 9.89 8.58229 9.68C8.80229 9.48 9.32229 9.02 9.26229 8.33C9.20229 7.68 8.66229 7.31 8.43229 7.16L8.42229 7.15Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M16.7515 10.35H11.5215C11.1115 10.35 10.7715 10.69 10.7715 11.1C10.7715 11.51 11.1115 11.85 11.5215 11.85H16.7515C17.1615 11.85 17.5015 11.51 17.5015 11.1C17.5015 10.69 17.1615 10.35 16.7515 10.35Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
