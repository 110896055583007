import { Button, Stack, Typography } from '@mui/material';
import BaseModal from 'components/modals';
import { BUTTON_LABELS } from 'constants/button.labels';
import { DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';
import { useNotification } from 'hooks';
import { useApiKeyService } from 'hooks/services';
import React, { useEffect, useState } from 'react';
import { Severity } from 'types/enum';
import DeleteApiKeyProps from './DeactivateApiKeyProps';

const DeactivateApiKeyModal = ({
  onClose,
  daoId,
  apiKeyId,
  isOpen
}: DeleteApiKeyProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);
  const { DEACTIVATE, KEEP } = BUTTON_LABELS;
  const { deleteKey } = useApiKeyService();
  const { notify } = useNotification();
  const { DEACTIVATE_WARNING, DEACTIVATE_HEADER } = DEV_DASHBOARD_LABELS;

  const handleDeleteActivity = async () => {
    try {
      await deleteKey(daoId, apiKeyId);
      onClose(true);
      notify('Success', { severity: Severity.Success });
    } catch (error) {
      console.log(error);
      notify('Failure', { severity: Severity.Error });
    } finally {
      setOpen(false);
    }
  };

  const cancel = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <BaseModal title={DEACTIVATE_HEADER} toggle={open} close={cancel} width={450}>
      <Stack direction={'column'}>
        <Typography data-testid={'modal-delete-content'} variant={'subtitle1'}>
          {DEACTIVATE_WARNING}
        </Typography>
        <Stack direction={'row'} spacing={2} marginTop={'3rem'}>
          <Button
            data-testid={'modal-cancel-button'}
            variant={'outlined'}
            color={'primary'}
            size={'large'}
            onClick={cancel}
          >
            {KEEP}
          </Button>
          <Button
            data-testid={'modal-delete-button'}
            variant={'contained'}
            color={'primary'}
            size={'large'}
            onClick={handleDeleteActivity}
          >
            {DEACTIVATE}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};
export default DeactivateApiKeyModal;
