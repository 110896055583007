import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DiscordIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M12.6275 3.30607C11.7515 2.89029 10.8267 2.59533 9.87676 2.42871C9.74677 2.66962 9.62915 2.91748 9.5244 3.17125C8.51252 3.01318 7.48351 3.01318 6.47163 3.17125C6.36682 2.9175 6.2492 2.66965 6.11927 2.42871C5.16871 2.59674 4.24331 2.8924 3.36643 3.30824C1.62559 5.97835 1.15367 8.58215 1.38963 11.149C2.40911 11.9299 3.5502 12.5237 4.7633 12.9048C5.03645 12.5239 5.27816 12.1199 5.48585 11.6969C5.09137 11.5442 4.71062 11.3557 4.34801 11.1338C4.44344 11.062 4.53678 10.9881 4.62697 10.9163C5.68206 11.4307 6.83364 11.6974 7.99959 11.6974C9.16553 11.6974 10.3171 11.4307 11.3722 10.9163C11.4634 10.9935 11.5568 11.0674 11.6512 11.1338C11.2879 11.3561 10.9064 11.5449 10.5112 11.698C10.7187 12.1208 10.9604 12.5245 11.2338 12.9048C12.4479 12.5253 13.5899 11.9317 14.6095 11.1501C14.8864 8.17337 14.1366 5.59349 12.6275 3.30607ZM5.78473 9.5704C5.1272 9.5704 4.58397 8.95179 4.58397 8.19077C4.58397 7.42974 5.10832 6.8057 5.78263 6.8057C6.45695 6.8057 6.99598 7.42974 6.98444 8.19077C6.97291 8.95179 6.45485 9.5704 5.78473 9.5704ZM10.2144 9.5704C9.55586 9.5704 9.01473 8.95179 9.01473 8.19077C9.01473 7.42974 9.53908 6.8057 10.2144 6.8057C10.8898 6.8057 11.4246 7.42974 11.4131 8.19077C11.4016 8.95179 10.8846 9.5704 10.2144 9.5704Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
