import { Stack, Typography } from '@mui/material';
import { CardSidebar, FeedCardContent, Meta, UserPublicProfile } from 'components';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useFeed } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ContentType } from 'types/enum';
import { handleKeyEnterEvent } from 'utils';
import { getContentTypeFromFeedPost } from 'utils/helper/content-type';
import { Proposal } from '../proposal';
import FeedCardProps from './FeedCard.props';
import { FeedCardStyled } from './FeedCard.style';

const FeedCard = ({
  post: currentPost,
  onClick,
  redirectLocation
}: FeedCardProps): React.ReactElement => {
  const [userProfile, setUserProfile] = useState(false);
  const { setPost, post } = useFeed();
  const contentType = getContentTypeFromFeedPost(currentPost);
  const navigate = useNavigate();

  useEffect(() => {
    setPost(() => currentPost);
  }, [currentPost, setPost]);

  const handleCardClick = useCallback(() => {
    const route = ROUTES.SINGLE_POST(
      currentPost?.room.dao.id,
      currentPost?.room.id,
      currentPost?.id
    );

    navigate(
      `${ROOT_ROUTE.DAO((currentPost?.room.dao as any).whiteLabelSubDomain)}/${ROUTES.DAO_ROOM_FEED(
        currentPost?.room.dao.id,
        currentPost?.room.id
      )}/${route}`,
      {
        state: { redirectLocation }
      }
    );
  }, [currentPost.id, currentPost.room.id, currentPost.room.dao.id, navigate]);

  const hideUserPublicProfile = useCallback(() => setUserProfile(false), []);
  const showDaoPublicProfile = useCallback(() => {
    navigate(ROUTES.DAO_PROFILE(post?.room.dao.id));
  }, [navigate, post?.room.dao.id]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      handleKeyEnterEvent({ event, callBack: handleCardClick });
    },
    [handleCardClick]
  );

  const handleClick = useCallback(() => onClick && onClick(), [onClick]);

  if (!post) {
    return <></>;
  }

  return (
    <>
      <FeedCardStyled
        contentType={contentType}
        role="article"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={handleCardClick}
      >
        <Stack spacing={{ xs: 2, md: 4 }} direction="row" justifyContent="space-between">
          <Stack direction="column" spacing={4} justifyContent="space-between" width={'100%'}>
            <Stack spacing={contentType !== ContentType.Post ? 2 : 1}>
              <Typography variant="h6" component="h2">
                {post.title}
              </Typography>
              <FeedCardContent onClick={handleClick} body={post.content} truncateBody>
                {post.proposal && (
                  <Proposal
                    feedItemId={post.id}
                    proposal={post.proposal ?? null}
                    room={post.room}
                  />
                )}
              </FeedCardContent>
            </Stack>
            <Meta
              user={post?.author?.user}
              date={post.createdAt}
              handleDao={showDaoPublicProfile}
            />
          </Stack>

          <CardSidebar comments={post._count.comments} type={contentType} />
        </Stack>
      </FeedCardStyled>

      {userProfile && (
        <UserPublicProfile userId={post?.author?.user?.id} afterClose={hideUserPublicProfile} />
      )}
    </>
  );
};

export default FeedCard;
