// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const hexToRgb = (hex: string) => {
  if (!hex) return { r: 0, g: 0, b: 0 };

  // Remove the hash character if present
  hex = hex.replace('#', '');

  // Convert the hex color code to an integer
  const hexInt = parseInt(hex, 16);

  // Extract the red, green, and blue color components
  const red = (hexInt >> 16) & 255;
  const green = (hexInt >> 8) & 255;
  const blue = hexInt & 255;

  // Return an object with the red, green, and blue color components
  return { r: red, g: green, b: blue };
};

export default hexToRgb;
