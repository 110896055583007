import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function HamburgerMenuIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M0 0H16V3.2H0V0Z" fill={props.fill || '#181818'} />
      <path d="M0 6.40039H16V9.60039H0V6.40039Z" fill={props.fill || '#181818'} />
      <path d="M0 12.7998H16V15.9998H0V12.7998Z" fill={props.fill || '#181818'} />
    </SvgIcon>
  );
}
