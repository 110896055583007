import { usdFormatter } from 'utils';

/**
 * This method checks if a token amount needs an additional format related to its decimals
 * @param amount token amount
 * @returns a final formatted token amount
 */
const formatTokenAmount = (tokenAmount: number): string => {
  let formattedAmount: string = usdFormatter.format(tokenAmount);
  formattedAmount = formattedAmount.replace('$', '').replace('.00', '');

  if (/.[1-9]0/.test(formattedAmount)) {
    formattedAmount = formattedAmount.slice(0, -1);
  }
  return formattedAmount;
};

export default formatTokenAmount;
