import { Card, CardActions, styled, svgIconClasses, Theme, typographyClasses } from '@mui/material';
import { ContentType } from 'types/enum';

const metaDefaultStyle = {
  [`& .${typographyClasses.root}.meta`]: {
    visibility: 'hidden',
    opacity: 0
  }
};
const hoverStyle = {
  [`& .MuiIconButton-root, .${svgIconClasses.root}, .${typographyClasses.root}, div
    > .${svgIconClasses.root}`]: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.35s linear'
  },
  [`& .${typographyClasses.root}.meta`]: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.35s linear'
  }
};

const getContentTypeStyles = (contentType: ContentType | undefined, theme: Theme): object => {
  switch (contentType) {
    case ContentType.Bounty:
      return {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          ...hoverStyle
        }
      };
    case ContentType.Proposal:
      return {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          ...hoverStyle
        }
      };
    case ContentType.Post:
      return {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          ...hoverStyle
        }
      };
    default:
      return {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          ...hoverStyle
        }
      };
  }
};

export const FeedCardStyled = styled(Card, {
  shouldForwardProp: (props) => props !== 'contentType'
})<{ contentType: ContentType }>(({ theme, contentType }) => ({
  ...metaDefaultStyle,
  ...getContentTypeStyles(contentType, theme),
  '&:first-of-type': {
    borderTopWidth: 0
  },
  '&:last-of-type': {
    borderBottomWidth: '.0625rem'
  },
  borderTopWidth: '.0313rem',
  borderBottomWidth: '.0313rem'
}));

export const CardActionsStyled = styled(CardActions)(({ theme }) => ({
  padding: theme.spacing(4, 0, 0, 0)
}));
