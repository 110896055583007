import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { ReactElement } from 'react';

const EditIcon = (props: SvgIconProps): ReactElement => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M2.33334 11.0001H5.16001C5.24775 11.0006 5.33472 10.9838 5.41595 10.9506C5.49717 10.9175 5.57105 10.8686 5.63334 10.8068L10.2467 6.18679L12.14 4.33346C12.2025 4.27148 12.2521 4.19775 12.2859 4.11651C12.3198 4.03527 12.3372 3.94813 12.3372 3.86012C12.3372 3.77211 12.3198 3.68498 12.2859 3.60374C12.2521 3.5225 12.2025 3.44876 12.14 3.38679L9.31334 0.526789C9.25137 0.464303 9.17763 0.414707 9.0964 0.380861C9.01516 0.347015 8.92802 0.32959 8.84001 0.32959C8.752 0.32959 8.66487 0.347015 8.58363 0.380861C8.50239 0.414707 8.42865 0.464303 8.36668 0.526789L6.48668 2.41346L1.86001 7.03346C1.79822 7.09575 1.74934 7.16963 1.71616 7.25085C1.68299 7.33208 1.66617 7.41905 1.66668 7.50679V10.3335C1.66668 10.5103 1.73691 10.6798 1.86194 10.8049C1.98696 10.9299 2.15653 11.0001 2.33334 11.0001ZM8.84001 1.94012L10.7267 3.82679L9.78001 4.77346L7.89334 2.88679L8.84001 1.94012ZM3.00001 7.78012L6.95334 3.82679L8.84001 5.71346L4.88668 9.66679H3.00001V7.78012ZM13 12.3335H1.00001C0.823199 12.3335 0.65363 12.4037 0.528606 12.5287C0.403581 12.6537 0.333344 12.8233 0.333344 13.0001C0.333344 13.1769 0.403581 13.3465 0.528606 13.4715C0.65363 13.5966 0.823199 13.6668 1.00001 13.6668H13C13.1768 13.6668 13.3464 13.5966 13.4714 13.4715C13.5964 13.3465 13.6667 13.1769 13.6667 13.0001C13.6667 12.8233 13.5964 12.6537 13.4714 12.5287C13.3464 12.4037 13.1768 12.3335 13 12.3335Z"
        fill={props.fill || '#8A8A8A'}
      />
    </SvgIcon>
  );
};

export default EditIcon;
