import { TableContainer, TableRow, styled } from '@mui/material';

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '65rem',
  overflowY: 'auto',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '.125rem',
    opacity: 0.4
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'gray'
  },
  '&:MuiTable-root': {
    minWidth: 'unset'
  }
}));

export const StyledDaoMemberRow = styled(TableRow)(({ theme }) => ({
  height: '3rem',
  '&:hover': {
    backgroundColor: theme.palette.grey['300']
  }
}));
