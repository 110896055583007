import { Button, ButtonProps, styled } from '@mui/material';

export const CreatePostButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  padding: '.5rem 1rem',
  border: `.0625rem solid ${theme.palette.grey[300]}`,
  justifyContent: 'flex-start',
  ...theme.typography.body1,
  transition: theme.transitions.create(
    ['background-color', 'border-color', 'color', 'box-shadow'],
    { duration: theme.transitions.duration.short }
  ),
  '& path': {
    transition: theme.transitions.create('fill', { duration: theme.transitions.duration.short }),
    fill: theme.palette.grey[700]
  },
  cursor: 'text',
  '&:hover': {
    borderColor: theme.palette.grey[900],
    backgroundColor: theme.palette.button.neutral.background,
    '& path': {
      fill: theme.palette.button.primary.background
    }
  }
}));
