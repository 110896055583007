import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useAuth } from '../../hooks';
import EmailVerificationStepper from './EmailVerificationStepper';
import PhoneVerificationStepper from './PhoneVerificationStepper';
import { AUTH_VERIFICATION } from '../../constants/auth.labels';
import { LinkButton } from 'components/buttons';

export enum AuthenticationStepperState {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  PHONE_VERIFICATION = 'PHONE_VERIFICATION'
}

const AuthenticationVerificationStepper = (): React.ReactElement => {
  const { logOut } = useAuth();
  const { auth, authProviderUser, isEnrolledInMfa } = useAuth();
  const [activeStep, setActiveStep] = React.useState<AuthenticationStepperState>();

  const { RETURN_TO_LOGIN } = AUTH_VERIFICATION;

  useEffect(() => {
    if (!authProviderUser) {
      return;
    }

    if (!authProviderUser?.emailVerified) {
      setActiveStep(AuthenticationStepperState.EMAIL_VERIFICATION);
    } else if (!isEnrolledInMfa) {
      setActiveStep(AuthenticationStepperState.PHONE_VERIFICATION);
    }
  }, [authProviderUser]);

  return (
    <Stack display="flex" justifyContent="space-between" spacing={3}>
      {activeStep === AuthenticationStepperState.EMAIL_VERIFICATION && (
        <EmailVerificationStepper firebaseUser={authProviderUser!} />
      )}
      {activeStep === AuthenticationStepperState.PHONE_VERIFICATION && (
        <PhoneVerificationStepper auth={auth} isEnrolling={!isEnrolledInMfa} />
      )}
      {!activeStep && <LinkButton onClick={() => logOut()}>{RETURN_TO_LOGIN}</LinkButton>}
    </Stack>
  );
};

export default AuthenticationVerificationStepper;
