import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Send05(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M7.75757 16.2429L3.51493 20.4856"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.17163 20.4854L7.75742 21.8996"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51489 14.8284L2.10068 16.2426"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4143 10.5857L11.293 12.707"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2716 3.19854L5.9213 7.31531C4.12374 7.9145 4.08956 10.4447 5.87027 11.0923L11.0311 12.9689L12.9077 18.1297C13.5553 19.9104 16.0855 19.8762 16.6847 18.0787L20.8015 5.72836C21.3226 4.16484 19.8351 2.67737 18.2716 3.19854Z"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
    </SvgIcon>
  );
}
