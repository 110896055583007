import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function KeyIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M21.0074 7.92377C19.8274 6.66377 18.4974 5.57377 17.2174 4.51377L16.8074 4.17377C16.6474 4.04377 16.4474 3.98377 16.2474 4.00377C16.0474 4.02377 15.8574 4.13377 15.7374 4.29377L10.9374 10.6438L8.09737 14.0538C7.97737 14.0138 7.84737 13.9838 7.70737 13.9638C6.29737 13.7138 4.32737 14.0138 3.41737 15.6538C2.81737 16.7238 2.86737 18.0738 3.54737 19.1038C3.97737 19.7638 4.57737 20.2338 5.27737 20.4538C5.58737 20.5538 5.91737 20.6038 6.24737 20.6038C7.00737 20.6038 7.78737 20.3438 8.45737 19.8638C9.45737 19.1438 10.0274 18.0538 10.0374 16.8938C10.0374 16.0938 9.79737 15.3838 9.36737 14.8738L12.0974 11.5938L13.6774 9.51377L16.5274 12.5538C16.6774 12.7138 16.8774 12.7938 17.0774 12.7938C17.2574 12.7938 17.4474 12.7238 17.5874 12.5938C17.8874 12.3138 17.9074 11.8338 17.6174 11.5338L14.5874 8.30377L16.4474 5.84377C17.6874 6.85377 18.8474 7.82377 19.9074 8.96377C20.1874 9.26377 20.6674 9.28377 20.9674 9.00377C21.2674 8.72377 21.2874 8.24377 21.0074 7.94377V7.92377ZM7.56737 18.6438C6.98737 19.0638 6.29737 19.2038 5.71737 19.0238C5.34737 18.9038 5.02737 18.6538 4.78737 18.2838C4.42737 17.7338 4.39737 16.9638 4.71737 16.3938C5.11737 15.6838 5.96737 15.3938 6.80737 15.3938C7.02737 15.3938 7.23737 15.4138 7.43737 15.4438C7.75737 15.5038 8.02737 15.6238 8.19737 15.7938C8.48737 16.1038 8.52737 16.6038 8.52737 16.8738C8.52737 17.5538 8.17737 18.2038 7.56737 18.6438Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
