import { MenuItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AvatarBuilder, EllipsisMenu } from 'components';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useDao } from 'hooks';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import { AvatarSize, UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import DaoCommonsHeaderProps from './DaoCommonsHeader.props';
import { BannerStack, BoxStyled, OverlappingHeader } from './DaoCommonsHeader.style';
import DAOJoinRequestButton from '../../buttons/dao-join-request-button/DaoJoinRequestButton';
import { HEADER_SECTION_TITLES } from 'constants/home-page.labels';

const DaoCommonsHeader = ({ daoProfile }: DaoCommonsHeaderProps): React.ReactElement => {
  const { activeDaoMembership } = useDao();
  const theme = useTheme();
  const isAdminUser = useRef<boolean>(
    compareDaoRoles(UserDaoRole.ADMIN, activeDaoMembership?.role as UserDaoRole)
  );
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { EDIT_COLLECTIVE, BECOME_A_MEMBER } = HEADER_SECTION_TITLES;

  return (
    <>
      <BoxStyled data-testid="dao-commons-header">
        <Stack direction="column" width={'100%'}>
          <BannerStack />
          <OverlappingHeader direction="row">
            <AvatarBuilder
              src={daoProfile.logoThumbnail}
              id={'profile-avatar'}
              alt={daoProfile.name}
              size={isMobile ? AvatarSize.X_LARGE : AvatarSize.XXX_LARGE}
              isSquare
              style={{
                border: '4px solid white',
                borderRadius: '1.5rem',
                boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
                backgroundColor: 'white'
              }}
            />
            <Stack direction={'row'} width="100%" justifyContent={'space-between'}>
              <Stack
                direction={'column'}
                paddingLeft="1.5rem"
                marginTop={{ xs: '2rem', sm: '2rem', md: '3rem' }}
              >
                <Typography variant={isMobile ? 'h6' : 'h5'} fontWeight={'bold'}>
                  {daoProfile.name}
                </Typography>
                <Typography variant={'body1'} sx={{ maxWidth: '30rem' }}>
                  {daoProfile.about}
                </Typography>
              </Stack>
              <Stack direction={'row'} marginTop={{ xs: '2rem', sm: '2rem', md: '3rem' }}>
                {!activeDaoMembership?.id ? (
                  <DAOJoinRequestButton ctaText={BECOME_A_MEMBER} />
                ) : (
                  isAdminUser.current && (
                    <EllipsisMenu
                      darker
                      style={{
                        border: `1px solid ${theme.palette.grey[700]}`,
                        borderRadius: '0.5rem'
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate(
                            `${ROOT_ROUTE.DAO(
                              (daoProfile as any).whiteLabelSubDomain
                            )}/${ROUTES.DAO_PROFILE_SETTINGS(daoProfile?.id as string)}`
                          )
                        }
                      >
                        {EDIT_COLLECTIVE}
                      </MenuItem>
                    </EllipsisMenu>
                  )
                )}
              </Stack>
            </Stack>
          </OverlappingHeader>
        </Stack>
      </BoxStyled>
    </>
  );
};

export default DaoCommonsHeader;
