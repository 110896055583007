import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material';
import nebulaNoise from '../../../assets/images/nebulaNoise.jpg';

export const BoxStyled = styled(Box)(() => ({
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
  maxWidth: '100rem'
}));

export const BannerStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '15rem',
  backgroundImage: `url(${nebulaNoise})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    height: '8rem'
  }
}));

export const OverlappingHeader = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-start',
  marginTop: '-2.5rem',
  padding: '0 1rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 4.5rem'
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '-1.25rem'
  }
}));
