import React from 'react';
import { ScrimProps } from './Scrim.props';
import { ContentContainer, ScrimContainer } from './Scrim.styles';

const Scrim = ({ cta }: ScrimProps): React.ReactElement => {
  return (
    <ScrimContainer>
      <ContentContainer>{cta}</ContentContainer>
    </ScrimContainer>
  );
};

export default Scrim;
