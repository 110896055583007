import { GlobalStyles, useTheme } from '@mui/material';
import React from 'react';
import InputForm from '../input-form/InputForm';
import InputFormProps from '../input-form/InputForm.props';
import mapPinSvg from 'assets/map-pin.svg';
import mapPinActiveSvg from 'assets/images/map-pin-active.svg';

const InputFormLocation = React.forwardRef((props: InputFormProps, ref): React.ReactElement => {
  const theme = useTheme();
  return (
    <>
      <GlobalStyles
        styles={{
          // search results container
          '.pac-container': {
            border: `1px solid ${theme.palette.common.black} !important`,
            boxShadow: 'none'
          },
          // search result item
          '.pac-item': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '1rem 0',
            height: '3.25rem',
            backgroundColor: `${theme.palette.common.white} !important`,
            '&:hover': {
              backgroundColor: `${theme.palette.common.black} !important`,
              color: `${theme.palette.common.white} !important`
            }
          },
          '.pac-item > span, .pac-item-query': {
            fontWeight: 550,
            fontSize: '.90625rem',
            lineHeight: '1.25rem'
          },
          // search result item text
          '.pac-item:hover .pac-item-query': {
            color: `${theme.palette.common.white} !important`,
            'svg, svg > path': {
              fill: `${theme.palette.common.white} !important`
            }
          },
          '.pac-item:hover .pac-icon': {
            background: `url(${mapPinActiveSvg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          },
          // default icon styles
          '.hdpi .pac-icon': {
            alignSelf: 'flex-end',
            background: `url(${mapPinSvg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            display: 'flex',
            marginLeft: '1rem',
            marginRight: '.625rem',
            minHeight: '1rem',
            minWidth: '1rem'
          },
          // removes third-party service logo
          '.pac-logo:after': {
            backgroundImage: 'none !important',
            height: '0 !important',
            padding: 'unset'
          }
        }}
      />
      <InputForm ref={ref} {...props} />
    </>
  );
});

export default InputFormLocation;
