import { Stack } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { ActivitySelectorItem, AreaChart, BaseCardWrapper, SingleLineProps } from 'components';
import { ChartHeader, ChartWrapper } from 'components/display/charts';
import { INSIGHTS_DASHBOARD } from 'constants/dao-admin-nav.labels';
import {
  DefaultTimeSeriesDropdownOption,
  TimeSeriesDropdownOptions
} from 'constants/time-series-dropdown-options';
import { usePatronageActivityStatistics } from 'hooks';
import { useEffect, useState } from 'react';
import { TimeSeriesChartPoint } from 'types';
import { MetricType } from 'types/enum';
import DataType from 'types/enum/chart-data-types.enum';

const DaoEventStatistics = (): React.ReactElement => {
  const {
    areaDataForInterval,
    patronageActivities,
    onTimeSeriesDropdownOptionUpdated,
    dataType,
    onTimeSeriesDataTypeUpdated
  } = usePatronageActivityStatistics();
  const [areaData, setAreaData] = useState<{
    totalCount: number;
    percentage: number;
    data: TimeSeriesChartPoint[];
  }>({ data: [], percentage: 0, totalCount: 0 });
  const [activitiesData, setActivitiesData] = useState<
    {
      selectData: ActivitySelectorItem;
      data: SingleLineProps;
    }[]
  >([]);

  const { BITS, EVENTS, TOTAL_DISTRIBUTED } = INSIGHTS_DASHBOARD;

  useEffect(() => {
    // format area data and set percent and totalCount
    const normalizeAreaData = () => {
      const { percentChange, totalCount, timeSeriesData } = areaDataForInterval;

      const normalizedAreaData =
        timeSeriesData?.map(
          (data) => ({ date: data.end, value: data.count } as TimeSeriesChartPoint)
        ) || [];

      setAreaData({
        data: normalizedAreaData,
        percentage: percentChange || 0,
        totalCount: totalCount || 0
      });
    };

    normalizeAreaData();
  }, [areaDataForInterval]);

  useEffect(() => {
    const results = patronageActivities.map((act) => {
      const { activity, lineProps, totalMetric } = act;
      const foundItem = activitiesData.find((d) => d.data.key === act.activity.id);
      return {
        data: lineProps,
        selectData: {
          activity: {
            ...activity,
            color: lineProps.color
          },
          isSelected: foundItem?.selectData.isSelected || false,
          metric: totalMetric
        }
      } as {
        selectData: ActivitySelectorItem;
        data: SingleLineProps;
      };
    });

    setActivitiesData([...results]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patronageActivities]);

  function EventChart() {
    if (!areaData.data.length) return <></>;
    return (
      <ParentSize>
        {({ width, height }) => {
          if (width === 0 && height === 0) return <></>;

          return (
            <AreaChart
              data={areaData.data}
              width={width}
              height={height}
              margin={{ left: 1, top: 1, bottom: 1, right: 1 }}
              metricType={DataType.POINT_COUNT === dataType ? MetricType.BITS : MetricType.NUMBER}
            />
          );
        }}
      </ParentSize>
    );
  }

  return (
    <BaseCardWrapper>
      <Stack direction={'column'} spacing={3} data-testid="wrapper">
        <ChartHeader
          title={TOTAL_DISTRIBUTED}
          metricConfig={{
            totalMetricConfig: {
              totalMetricValue:
                activitiesData.length > 0
                  ? activitiesData.map((d) => d.selectData.metric).reduce((a, b) => a + b, 0)
                  : areaData.totalCount,
              metricType: DataType.POINT_COUNT === dataType ? MetricType.BITS : MetricType.NUMBER
            }
          }}
          dropdownConfig={{
            dropDownOptions: TimeSeriesDropdownOptions,
            dropDownOptionSelected: onTimeSeriesDropdownOptionUpdated,
            defaultDropdownOption: DefaultTimeSeriesDropdownOption
          }}
          toggleDataTypeConfig={{
            default: dataType,
            onToggleSelected: onTimeSeriesDataTypeUpdated,
            toggleOptions: [
              { display: BITS, value: DataType.POINT_COUNT },
              { display: EVENTS, value: DataType.EVENT_COUNT }
            ]
          }}
        />
        <ChartWrapper height={240}>
          <EventChart />
        </ChartWrapper>
      </Stack>
    </BaseCardWrapper>
  );
};

export default DaoEventStatistics;
