import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { CollectiveInfoIcon } from 'assets';
import AvatarBuilder from 'components/avatar/AvatarBuilder';
import { NAV_LABELS } from 'constants/navigation.labels';
import { ROOT_ROUTE } from 'constants/routes';
import { useAppNavigation, useUser } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AvatarSize } from 'types/enum';
import truncateText from 'utils/helper/truncate-text';
import { StyledListItemButton, StyledListItemButtonNoCollective } from './CollectiveNavItem.style';

interface CollectiveNavItemProps {
  setOpenDrawer: (value: boolean) => void;
  activeNavItemName: string;
  setActiveNavItemName: (value: string) => void;
}

const CollectiveNavItem = ({
  setOpenDrawer,
  activeNavItemName,
  setActiveNavItemName
}: CollectiveNavItemProps): React.ReactElement => {
  const { daoMemberships } = useUser();
  const { userNavExpanded, setShowDaoNav } = useAppNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const { COLLECTIVE_EMPTY } = NAV_LABELS;

  useEffect(() => {
    const subdomain = location.pathname.split('/')[2];
    const activeCollective = daoMemberships.find(
      (membership: DaoMembershipDTO) => (membership.dao as any).whiteLabelSubDomain === subdomain
    );
    if (activeCollective) {
      setActiveNavItemName(activeCollective.id);
    }
  }, [daoMemberships]);

  const handleCollectiveNavigate = useCallback(
    (membership: DaoMembershipDTO) => {
      setShowDaoNav(true);
      if (userNavExpanded) setOpenDrawer(false);
      if (membership) {
        setActiveNavItemName(membership.id);
        navigate(ROOT_ROUTE.DAO((membership.dao as any).whiteLabelSubDomain));
      }
    },
    [userNavExpanded, navigate]
  );

  return (
    <List>
      {daoMemberships.length > 0 ? (
        daoMemberships.map((membership: DaoMembershipDTO) => (
          <ListItem
            key={membership.id}
            onClick={() => handleCollectiveNavigate(membership)}
            sx={{
              height: '3rem',
              display: 'flex',
              justifyContent: userNavExpanded ? 'unset' : 'center',
              alignItems: 'center'
            }}
          >
            <StyledListItemButton
              openDrawer={userNavExpanded}
              activeNavItemName={activeNavItemName}
              membership={membership}
            >
              <ListItemIcon
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: userNavExpanded ? '-0.25rem' : '0.5rem'
                }}
              >
                <AvatarBuilder
                  id={membership.dao.id}
                  size={AvatarSize.MEDIUM}
                  src={membership.dao.logoThumbnail}
                  isSquare={true}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: userNavExpanded ? 1 : 0, marginLeft: '-0.5rem' }}>
                <Typography variant={'body1'}>{truncateText(membership.dao.name, 17)}</Typography>
              </ListItemText>
            </StyledListItemButton>
          </ListItem>
        ))
      ) : (
        <ListItem
          sx={{
            height: '3.5rem',
            alignItems: 'baseline'
          }}
        >
          <StyledListItemButtonNoCollective>
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                '& > svg': {
                  height: '1.5rem',
                  width: '1.5rem'
                }
              }}
            >
              <CollectiveInfoIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: userNavExpanded ? 1 : 0 }}>
              <Typography variant={'body1'} color={'secondary'} whiteSpace={'normal'}>
                {COLLECTIVE_EMPTY}
              </Typography>
            </ListItemText>
          </StyledListItemButtonNoCollective>
        </ListItem>
      )}
    </List>
  );
};

export default CollectiveNavItem;
