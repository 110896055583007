import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  CardSidebar,
  CommentsList,
  FeedCardContent,
  Meta,
  SkeletonCard,
  UserPublicProfile
} from 'components';
import { PAGINATION } from 'constants/app-config';
import { ROUTES } from 'constants/routes';
import { useDao, useFeed } from 'hooks';
import { usePostService } from 'hooks/services';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ContentType } from 'types/enum';
import { getContentTypeFromFeedPost } from 'utils';
import { Proposal } from '../proposal';
import { FeedSinglePostProps } from './FeedSinglePost.props';
import {
  FeedCardInnerContainerStyled,
  RoomTitleContainerStyled,
  StackStyled
} from './FeedSinglePost.style';

const FeedSinglePost = ({ atBottom }: FeedSinglePostProps): React.ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { roomId, postId } = useParams();
  const { currentDao } = useDao();
  const { comments, setComments, post, setPost } = useFeed();
  const { getPostById } = usePostService();
  const { getRootComments } = usePostService();

  const [userProfile, setUserProfile] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<boolean>(false);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (atBottom && !lastPage) {
      setPage(page + 1);
      setShowSkeleton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPage, atBottom]);

  const getPost = useCallback(async (): Promise<void> => {
    try {
      if (!currentDao.id || !roomId || !postId) {
        return;
      }

      const postRes = await getPostById(currentDao.id, roomId, postId);

      if (postRes.data) {
        setPost(() => postRes.data);
        return;
      }
    } catch (error) {
      navigate(ROUTES.DAO_HOME(currentDao.id));
    }
  }, [currentDao.id, getPostById, navigate, postId, roomId, setComments, setPost]);

  const getComments = useCallback(async () => {
    try {
      const commentsResponse = await getRootComments(currentDao.id!!, roomId!!, postId!!, {
        page: page,
        size: PAGINATION.POST_COMMENTS
      });
      if (commentsResponse) {
        const recordsCount = commentsResponse.data?.length || 0;
        setComments((prevComments = []) => [...prevComments, ...commentsResponse.data]);

        if (recordsCount < PAGINATION.POST_COMMENTS) setLastPage(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowSkeleton(false);
    }
  }, [getRootComments, currentDao.id, roomId, postId, page, setComments]);

  useEffect(() => {
    if (postId) getPost();
  }, [postId, getPost]);

  useEffect(() => {
    getComments();
  }, [page, getComments]);

  const hideUserPublicProfile = useCallback(() => setUserProfile(false), []);

  if (!post || !currentDao.id || !roomId) {
    return <></>;
  }

  return (
    <>
      <StackStyled>
        {!isMobile && (
          <RoomTitleContainerStyled>
            <Typography variant="body1" fontWeight={800}>
              {post.room.name}
            </Typography>
          </RoomTitleContainerStyled>
        )}
        <Stack
          direction="row"
          justifyContent="flex-start"
          style={{ margin: `${isMobile ? '1.5rem' : 'initial'}` }}
        >
          <FeedCardInnerContainerStyled
            spacing={getContentTypeFromFeedPost(post) !== ContentType.Post ? 2 : 1}
          >
            <Typography variant="h6" component="h2" data-testid="post-title">
              {post.title}
            </Typography>
            <FeedCardContent body={post.content} data-testid="post-body">
              {post.proposal && (
                <Proposal feedItemId={post.id} proposal={post.proposal} room={post.room} />
              )}
            </FeedCardContent>
            <Meta
              user={post?.author?.user}
              date={post.createdAt}
              socialEntityAudits={post?.socialEntityAudits}
            />
          </FeedCardInnerContainerStyled>
          <CardSidebar
            comments={post._count.comments}
            postOptions
            showComment
            type={getContentTypeFromFeedPost(post)}
          />
        </Stack>

        {comments && (
          <CommentsList post={post} comments={comments} daoId={currentDao.id} roomId={roomId} />
        )}
        {showSkeleton && <SkeletonCard />}
      </StackStyled>

      {userProfile && (
        <UserPublicProfile
          userId={post?.author?.user?.id ?? ''}
          afterClose={hideUserPublicProfile}
        />
      )}
    </>
  );
};

export default FeedSinglePost;
