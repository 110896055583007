import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme): object {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          // borderBottom: `1px solid ${theme.palette.button.primary.linkBackground}`,
          paddingBottom: '0.563rem',
          '& .MuiTabs-indicator': {
            backgroundColor: 'inherit'
          },
          [theme.breakpoints.down('md')]: {
            borderBottom: '0'
          }
        },
        vertical: {
          borderBottom: `0`,
          width: '8.9375rem',
          '& .Mui-selected, .MuiTabs-indicator': {
            backgroundColor: `${theme.palette.grey[900]} !important`,
            color: `${theme.palette.grey[0]} !important`,
            padding: '0.25rem'
          },
          '& .MuiTab-root': {
            backgroundColor: theme.palette.grey[0],
            borderRadius: 0,
            color: theme.palette.grey[800],
            fontSize: '0.875rem',
            height: '3.25rem',
            justifyContent: 'flex-start',
            width: '100%',
            '& svg': {
              marginLeft: '0.25rem'
            }
          }
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: theme.typography.fontWeightMedium,
          maxHeight: 'initial',
          maxWidth: 'initial',
          minHeight: '1.25rem',
          minWidth: 'initial',
          marginRight: '1.75rem !important',
          padding: '0.125rem 0.25rem 0.25rem 0.25rem',
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey[900],
            color: theme.palette.grey[0]
          },
          '&:not(:last-of-type)': {
            marginRight: theme.spacing(3)
          },
          [theme.breakpoints.up('sm')]: {
            marginRight: '1rem !important'
          }
        },
        labelIcon: {
          flexDirection: 'row',
          '& > *:first-of-type': {
            marginBottom: 0,
            marginRight: theme.spacing(1)
          }
        },
        wrapper: {
          flexDirection: 'row',
          whiteSpace: 'nowrap'
        },
        textColorInherit: {
          opacity: 1,
          color: theme.palette.text.secondary
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: '50%'
        }
      }
    }
  };
}
