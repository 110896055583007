import {
  BaseQueryParams,
  DaoInviteCreateDTO,
  DaoInviteCreatePayload,
  DaoInviteDTO,
  DaoInviteLinkDTO,
  DaoInviteUpdateDTO,
  ManageableEntityDTO
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useHttp } from 'hooks';
import { useCallback } from 'react';
import { DaoInviteStatus } from 'types/enum';
import DaoInviteService from './use-dao-invite-service.model';
import {
  daoInviteByIdURL,
  daoInviteURL,
  daoManageableEntityURL
} from './use-dao-invite-service.url';

const useDaoInviteService = (): DaoInviteService => {
  const { post, get, put, patch } = useHttp();

  const createDaoInvite = useCallback(
    (
      daoId: string,
      payload: DaoInviteCreatePayload
    ): Promise<AxiosResponse<DaoInviteCreateDTO>> => {
      const url: string = daoInviteURL(daoId);
      return post(url, payload);
    },
    [post]
  );

  const updateDaoInvite = useCallback(
    (
      daoId: string,
      daoInviteId: string,
      manageableEntityId: string,
      payload: DaoInviteUpdateDTO
    ): Promise<AxiosResponse<DaoInviteDTO>> => {
      const url = `${daoInviteByIdURL(daoId, daoInviteId, manageableEntityId)}/links`;
      return patch(url, payload);
    },
    [patch]
  );

  const getDaoInviteLink = useCallback(
    (
      daoId: string,
      daoInviteId: string,
      manageableEntityId: string
    ): Promise<AxiosResponse<DaoInviteLinkDTO>> => {
      const url: string = `${daoInviteByIdURL(daoId, daoInviteId, manageableEntityId)}/links`;
      return get(url);
    },
    [get]
  );

  const changeDaoInviteStatus = useCallback(
    (
      daoId: string,
      daoInviteId: string,
      manageableEntityId: string,
      status: DaoInviteStatus
    ): Promise<AxiosResponse<any>> => {
      const url = `${daoInviteByIdURL(daoId, daoInviteId, manageableEntityId)}`;
      return put(url, { status });
    },
    [put]
  );

  const acceptDaoInvite = useCallback(
    (
      daoId: string,
      daoInviteId: string,
      manageableEntityId: string,
      temporaryInvitationId?: string
    ): Promise<AxiosResponse<void>> => {
      const url = `${daoInviteByIdURL(daoId, daoInviteId, manageableEntityId)}`;
      return patch(url, {
        status: DaoInviteStatus.Accepted.toString(),
        temporaryInvitationId: temporaryInvitationId
      });
    },
    [patch]
  );

  const getDaoInvites = useCallback(
    (
      daoId: string,
      { page, size }: BaseQueryParams
    ): Promise<AxiosResponse<ManageableEntityDTO>> => {
      const url: string = `${daoManageableEntityURL(
        daoId
      )}?type=dao-invites&page=${page}&size=${size}`;
      return get(url);
    },
    [get]
  );

  const getDaoInvite = useCallback(
    (
      daoId: string,
      daoInviteId: string,
      manageableEntityId: string
    ): Promise<AxiosResponse<ManageableEntityDTO>> => {
      const url: string = daoInviteByIdURL(daoId, daoInviteId, manageableEntityId);
      return get(url);
    },
    [get]
  );

  return {
    createDaoInvite,
    updateDaoInvite,
    getDaoInviteLink,
    changeDaoInviteStatus,
    acceptDaoInvite,
    getDaoInvite,
    getDaoInvites
  };
};

export default useDaoInviteService;
