import { Stack } from '@mui/material';
import { ProposalOptionDTO } from '@piefi-platform/types-lib';
import { StyledRedButton } from 'components/v2/base/red-button/RedButton';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useFeed } from 'hooks';
import { useProposalService } from 'hooks/services';
import React, { useCallback } from 'react';
import ProposalOptionsProps from './ProposalOptions.props';

const ProposalOptions = ({
  daoId,
  roomId,
  feedItemId,
  proposalId,
  options
}: ProposalOptionsProps): React.ReactElement => {
  const { setPost } = useFeed();
  const { createProposalOptionVote } = useProposalService();

  /**
   * This method creates a new vote for the current user
   */
  const vote = useCallback(
    async (optionId: string) => {
      try {
        const result = await createProposalOptionVote(
          daoId,
          roomId,
          feedItemId,
          proposalId,
          optionId
        );

        if (result.status === HTTP_STATUS.OK) {
          setPost((prevState) => {
            if (prevState && prevState.proposal) {
              return {
                ...prevState,
                proposal: {
                  ...prevState.proposal,
                  proposalOptions: prevState.proposal.proposalOptions.map(
                    (option: ProposalOptionDTO) => {
                      if (option.id === optionId) {
                        return {
                          ...option,
                          votes: [
                            {
                              id: '1',
                              createdAt: new Date(),
                              authorId: '1',
                              proposalOptionId: optionId
                            }
                          ]
                        };
                      }
                      return option;
                    }
                  ),
                  totalVotes: prevState.proposal.totalVotes + 1
                }
              };
            } else {
              return prevState!!;
            }
          });
        }
      } catch (error) {}
    },
    [daoId, roomId, feedItemId, proposalId, createProposalOptionVote, setPost]
  );

  const handleOptionSelection = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: ProposalOptionDTO
  ) => {
    e.stopPropagation();
    await vote(option.id);
  };

  return (
    <Stack direction="column" spacing={2}>
      {options.map((option: ProposalOptionDTO, index: number) => {
        return (
          <StyledRedButton
            key={`option-${index}`}
            onClick={(e) => handleOptionSelection(e, option)}
          >
            {option.optionText}
          </StyledRedButton>
        );
      })}
    </Stack>
  );
};

export default ProposalOptions;
