import { Box, Stack, StackProps, styled, svgIconClasses, typographyClasses } from '@mui/material';

const FeedCardInnerContainerStyled = styled(Stack)<StackProps>(({ theme }) => ({
  marginRight: '1rem',
  minWidth: '17.4375rem',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginLeft: '3rem',
    marginRight: '2rem',
    marginBottom: '3rem',
    width: '34.5rem'
  },
  [`& .${typographyClasses.root}.meta`]: {
    visibility: 'visible',
    opacity: 1
  }
}));

const RoomTitleContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  padding: '0.25rem 0.5rem',
  height: '1.75rem',
  marginBottom: '1.25rem',
  minWidth: '3.1875rem',
  width: 'fit-content'
}));

const StackStyled = styled(Stack)(({ theme }) => ({
  [`& .MuiIconButton-root, .${svgIconClasses.root}, .${typographyClasses.root}, div 
    > .${svgIconClasses.root}`]: {
    visibility: 'visible !important',
    opacity: '1 !important',
    transition: 'opacity 0.35s linear'
  }
}));

export { FeedCardInnerContainerStyled, RoomTitleContainerStyled, StackStyled };
