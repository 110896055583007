import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { ManageableEntityDTO } from '@piefi-platform/types-lib';
import { daoURL } from './use-dao-join-request-service.url';
import IUseDaoJoinRequestServiceProps from './use-dao-join-request-service.props';
import { useCallback } from 'react';
import { UserDaoRole, UserRoomRole, ReportedEntityState } from 'types/enum';

const useDaoJoinRequestService = (): IUseDaoJoinRequestServiceProps => {
  const { post, put } = useHttp();

  const createDaoRequestToJoin = useCallback(
    (daoId: string): Promise<AxiosResponse> => {
      return post(`${daoURL}/${daoId}/manageable-entities/-/join-requests`);
    },
    [post]
  );

  const updateJoinRequest = useCallback(
    (
      daoId: string,
      manageableEntityId: string,
      joinRequestId: string,
      status: ReportedEntityState.Accepted | ReportedEntityState.Declined,
      daoRole: UserDaoRole,
      mainRoomRole: UserRoomRole
    ): Promise<AxiosResponse<ManageableEntityDTO>> => {
      return put(
        `${daoURL}/${daoId}/manageable-entities/${manageableEntityId}/join-requests/${joinRequestId}`,
        { status, daoRole, mainRoomRole }
      );
    },
    [put]
  );

  return { createDaoRequestToJoin, updateJoinRequest };
};

export default useDaoJoinRequestService;
