import { DaoMembershipDTO, DaoPublicDTO, ManageableEntityDTO } from '@piefi-platform/types-lib';
import SeasonDTO from '@piefi-platform/types-lib/dtos/season.dto';
import { captureException } from '@sentry/react';
import {
  useDaoDocumentService,
  useDaoInviteService,
  useDaoService,
  useSeasonsService,
  useUserService
} from 'hooks/services';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { matchRoutes, useNavigate, useParams } from 'react-router';
import { compareDaoRoles, downloadImage } from 'utils';
import { ROUTES } from '../constants/routes';
import { useNotification, useUser } from '../hooks';
import { InviteProperties } from '../model/contexts/user-context-type.model';
import { DaoTabNotificationCategory, SeasonStatus, UserDaoRole } from 'types/enum';
import { DAO_ADMIN_PAGES } from '../constants/dao-admin-nav.labels';
import { DaoTabNotification, DaoTabNotificationConfig } from 'types';

export interface DaoContextType {
  currentDao: DaoPublicDTO & { whiteLabelSubDomain: string };
  fetchAndSetDao: () => Promise<void>;
  activeDaoMembership?: DaoMembershipDTO;
  currentActiveSeasonForDao?: SeasonDTO;
  pendingJoinRequestForDao?: Partial<ManageableEntityDTO>;
  inviteProperties?: InviteProperties;
  updatePendingJoinRequestsForDao: () => Promise<void>;
  updateDaoMembership: () => Promise<void>;
  deleteInviteProps: () => void;
  tabNotifications: DaoTabNotificationConfig;
  addNotificationToTab: (tab: string, notification: DaoTabNotification) => void;
  removeNotificationFromTab: (tab: string, key: DaoTabNotificationCategory) => void;
  sendAcceptDaoInvite: (inviteProps: InviteProperties) => void;
}

interface DaoContextProviderProps {
  children: ReactNode;
}

const initialState: DaoContextType = {
  currentDao: {} as DaoPublicDTO & { whiteLabelSubDomain: string },
  fetchAndSetDao: (): Promise<void> => Promise.resolve(),
  activeDaoMembership: {} as DaoMembershipDTO,
  currentActiveSeasonForDao: {} as SeasonDTO,
  pendingJoinRequestForDao: {} as Partial<ManageableEntityDTO>,
  inviteProperties: {} as InviteProperties,
  updatePendingJoinRequestsForDao: () => Promise.resolve(),
  updateDaoMembership: (): Promise<void> => Promise.resolve(),
  deleteInviteProps: () => {},
  tabNotifications: {},
  addNotificationToTab: () => {},
  removeNotificationFromTab: () => {},
  sendAcceptDaoInvite: () => {}
};

const DaoContext = createContext(initialState);

const DaoContextProvider = ({ children }: DaoContextProviderProps): any => {
  const [tabNotifications, setTabNotifications] = useState<DaoTabNotificationConfig>({});
  const ldClient = useLDClient();
  const { userInfo, daoMemberships } = useUser();
  const [currentDao, setCurrentDao] = useState<DaoPublicDTO & { whiteLabelSubDomain: string }>(
    {} as DaoPublicDTO & { whiteLabelSubDomain: string }
  );
  const { getPageOfDaos, getDaoInfo } = useDaoService();
  const { notify } = useNotification();
  const { daoId } = useParams();
  const { getSeasons } = useSeasonsService();
  const { getUserDaoMembershipByDaoId, getUserJoinRequestsByDao } = useUserService();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const { DOCUMENTS } = DAO_ADMIN_PAGES;
  const { acceptDaoInvite } = useDaoInviteService();

  const [currentActiveSeasonForDao, setCurrentActiveSeasonForDao] = useState<SeasonDTO>();
  const [membershipForCurrentDao, setMembershipForCurrentDao] = useState<DaoMembershipDTO>();
  const [joinRequest, setJoinRequest] = useState<Partial<ManageableEntityDTO>>();
  const [, setInviteProps] = useState<InviteProperties | undefined>();
  const [loading, setLoading] = useState(false);
  const { getDocuments } = useDaoDocumentService();
  const inviteSentRef = useRef(false);

  const sendAcceptDaoInvite = useCallback(
    async (inviteProps: InviteProperties) => {
      if (!inviteProps || inviteSentRef.current) return;
      inviteSentRef.current = true;
      await acceptDaoInvite(
        currentDao.id,
        inviteProps.inviteId,
        inviteProps.manageableEntityId,
        inviteProps.temporaryInvitationId
      );
    },
    [acceptDaoInvite, currentDao.id]
  );

  const addNotificationToTab = (tab: string, notification: DaoTabNotification) => {
    if (tabNotifications[tab]) {
      tabNotifications[tab].push(notification);
    } else {
      tabNotifications[tab] = [notification];
    }
    setTabNotifications({ ...tabNotifications });
  };

  const removeNotificationFromTab = (tab: string, key: DaoTabNotificationCategory) => {
    if (tabNotifications[tab]) {
      tabNotifications[tab] = tabNotifications[tab].filter((item) => item.notificationType !== key);
      setTabNotifications({ ...tabNotifications });
    }
  };

  const updatePendingRequests = useCallback(async () => {
    const { data: joinRequestsToDao } = await getUserJoinRequestsByDao(currentDao.id);
    if (joinRequestsToDao.length > 0) {
      setJoinRequest(joinRequestsToDao[0]);
      return;
    }
  }, [currentDao, getUserJoinRequestsByDao]);

  const getSeason = useCallback(
    async (daoMem: DaoMembershipDTO) => {
      if (!currentDao) return;

      const canGetSeasons = compareDaoRoles(UserDaoRole.MEMBER, daoMem.role as UserDaoRole);
      if (!canGetSeasons) return;

      const { data: season } = await getSeasons(daoMem.daoId, SeasonStatus.ACTIVE);
      if (season && season[0]) {
        setCurrentActiveSeasonForDao({ ...season[0] });
      }

      return season;
    },
    [currentDao.id, getSeasons, currentDao]
  );

  const checkForUnsignedDocuments = useCallback(
    async (daoId: string, role: UserDaoRole) => {
      const canGetDocuments = compareDaoRoles(UserDaoRole.ADMIN, role as UserDaoRole);
      if (!canGetDocuments) return;

      const res = await getDocuments(daoId, {
        page: 0,
        size: 1,
        unsignedDocuments: true
      } as any);

      if (res.data.length > 0) {
        addNotificationToTab(DOCUMENTS, {
          notificationType: DaoTabNotificationCategory.UNSIGNED_DOCUMENTS
        });
      } else {
        removeNotificationFromTab(DOCUMENTS, DaoTabNotificationCategory.UNSIGNED_DOCUMENTS);
      }
    },
    [currentDao]
  );

  const updateDaoMembership = useCallback(async () => {
    await getMembershipForDao(currentDao.id);
    return;
  }, [getUserDaoMembershipByDaoId, userInfo, currentDao]);

  const deleteInviteProps = () => {
    setInviteProps(undefined);
  };
  useEffect(() => {}, [currentDao, userInfo]);

  const getMembershipForDao = useCallback(
    async (daoId: string) => {
      if (!daoId || !userInfo?.id) return;
      let daoMembershipResponse: DaoMembershipDTO;

      const cachedMembership = daoMemberships.find((item) => item.daoId === daoId);
      if (cachedMembership) {
        daoMembershipResponse = { ...cachedMembership };
      } else {
        const data = await getUserDaoMembershipByDaoId(daoId);
        daoMembershipResponse = { ...data.data };
      }
      if (daoMembershipResponse?.id) {
        await getSeason(daoMembershipResponse);
        await checkForUnsignedDocuments(
          daoMembershipResponse.daoId,
          daoMembershipResponse.role as UserDaoRole
        );

        await ldClient?.waitForInitialization();
        await ldClient?.identify({
          kind: 'multi',
          user: {
            key: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            avatar: userInfo.profileThumbnail
          },
          daoMembership: {
            kind: 'dao-member',
            key: daoMembershipResponse.id,
            role: daoMembershipResponse.role,
            daoId: daoMembershipResponse.daoId
          }
        });

        setMembershipForCurrentDao({ ...daoMembershipResponse });

        return daoMembershipResponse;
      } else {
        const { data: joinRequestsToDao } = await getUserJoinRequestsByDao(daoId);
        if (joinRequestsToDao.length > 0) {
          setJoinRequest(joinRequestsToDao[0]);
          return;
        }

        const inviteRoute = matchRoutes([{ path: ROUTES.DAO_INVITE() }], window.location);

        if (inviteRoute) return;
        navigateRef.current(ROUTES.DAO_HOME(daoId));
      }
    },
    [currentDao, userInfo]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const subDomain = daoId;
        const data = await getPageOfDaos({ page: 0, size: 1, subdomain: subDomain });
        if (!data.data || data.data.length === 0) {
          setTimeout(() => {
            window.location.href = 'https://awsm.com';
          }, 1000);
          captureException(new Error(`Domain ${subDomain} not found`));
          return;
        }
        const fetchedDao = data.data[0];

        await getMembershipForDao(fetchedDao.id);

        await ldClient?.waitForInitialization();
        await ldClient?.identify({
          kind: 'dao',
          key: fetchedDao.id,
          name: fetchedDao.name,
          avatar: fetchedDao.logoThumbnail,
          memberCount: fetchedDao.memberCount
        });
        setCurrentDao({ ...fetchedDao } as DaoPublicDTO & { whiteLabelSubDomain: string });
      } catch (error) {
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 0);
      }
    };
    getData();
  }, [ldClient, getPageOfDaos, daoId]);

  const fetchAndSetDao = useCallback(async () => {
    try {
      const daoId = currentDao.id;
      const { data: daoResponse } = await getDaoInfo(daoId);

      if (daoResponse?.id) {
        let logoThumbnail: string = daoResponse?.logoThumbnail ? daoResponse.logoThumbnail : '';

        // getting user profile image from firebase
        try {
          logoThumbnail = await downloadImage(`daos/${daoId}/profile-image`);
        } catch (error) {
          captureException(error);
        }

        await ldClient?.waitForInitialization();
        await ldClient?.identify({
          kind: 'dao',
          key: daoResponse.id,
          name: daoResponse.name,
          avatar: daoResponse.logoThumbnail,
          memberCount: daoResponse.memberCount
        });

        setCurrentDao({ ...daoResponse, logoThumbnail } as DaoPublicDTO & {
          whiteLabelSubDomain: string;
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [getDaoInfo, notify, ldClient, currentDao]);

  const providerValue = useMemo(
    () => ({
      currentDao,
      fetchAndSetDao,
      currentActiveSeasonForDao,
      updatePendingJoinRequestsForDao: updatePendingRequests,
      activeDaoMembership: membershipForCurrentDao,
      pendingJoinRequestForDao: joinRequest,
      updateDaoMembership,
      deleteInviteProps,
      tabNotifications,
      addNotificationToTab,
      removeNotificationFromTab,
      sendAcceptDaoInvite
    }),
    [
      joinRequest,
      currentDao,
      fetchAndSetDao,
      currentActiveSeasonForDao,
      updatePendingRequests,
      membershipForCurrentDao,
      updateDaoMembership,
      deleteInviteProps,
      tabNotifications,
      addNotificationToTab,
      removeNotificationFromTab,
      sendAcceptDaoInvite
    ]
  );

  if (!currentDao.id) return <></>;

  if (loading) {
    return <></>;
  }

  return <DaoContext.Provider value={providerValue}>{children}</DaoContext.Provider>;
};

export default DaoContext;
export { DaoContextProvider };
