import { FailIcon, SuccessIcon } from 'assets';
import React from 'react';
import ValidationInputFormProps from './ValidationInputForm.props';
import ValidationInputFormStyled from './ValidationInputForm.style';

const ValidationInputForm = React.forwardRef(
  (props: ValidationInputFormProps, ref): React.ReactElement => {
    const { valid, error, InputProps } = props;
    const validAdornment = valid ? <SuccessIcon data-testid="success-adornment" /> : <></>;
    const endAdornment = error ? <FailIcon data-testid="fail-adornment" /> : validAdornment;

    return (
      <ValidationInputFormStyled
        {...props}
        hiddenLabel
        InputProps={{ endAdornment, ...InputProps }}
        inputRef={ref}
      />
    );
  }
);

export default ValidationInputForm;
