import { Avatar, Badge, Box, Divider, Grid, IconButton, Stack, Tab } from '@mui/material';
// Do not remove these lines, we're postponing social links implementation for now
// import InstagramIcon from 'assets/InstagramIcon';
// import MemberIcon from 'assets/MemberIcon';
import { InternetIcon, LinkedinIcon, TwitterIcon } from 'assets';
import { TAB_ARIA } from 'constants/tabs.labels';
import { Fragment, useState } from 'react';
import ProfileTemplateProps from './ProfileTemplate.props';
import {
  AvatarContainerStyled,
  ContentBoxStyled,
  ProfileContainerStyled,
  SegmentProfileInfoStyled,
  SocialMediaStackStyled,
  TabContentStyled,
  TabsStyled,
  UserAboutTextStyled,
  UserNameStyled
} from './ProfileTemplate.style';

const ProfileTemplate = ({
  daoRequestStateButton,
  tabComponents,
  genericProfile,
  isLoading
}: ProfileTemplateProps): React.ReactElement => {
  const [currentTab, setCurrentTab] = useState(0);
  const { SCROLL_ELEMENT, TAB_CONTENT, TAB_HEADER, TAB_CONTENT_HEADER } = TAB_ARIA;

  const handleTabsChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <ProfileContainerStyled data-testid="profile-template" tabIndex={0}>
        <Grid container justifyContent={'center'} style={{ marginTop: '1.5rem' }}>
          <AvatarContainerStyled item xs={12} md={2} id="profile-avatar-container">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={daoRequestStateButton}
            >
              <Avatar
                alt={genericProfile.name}
                data-testid={'avatar'}
                id="profile-avatar"
                style={{ width: '6.25rem', height: '6.25rem' }}
                src={genericProfile.profileThumbnail}
              />
            </Badge>
          </AvatarContainerStyled>

          <SegmentProfileInfoStyled item xs={12} md={10} id="profile-info-segment">
            <Stack direction="row" justifyContent="space-between">
              <div style={{ width: '100%' }}>
                <UserNameStyled data-testid="userName" variant="h5">
                  {genericProfile.name}
                </UserNameStyled>
              </div>
            </Stack>
            <UserAboutTextStyled variant="body2">{genericProfile.about}</UserAboutTextStyled>
            <SocialMediaStackStyled direction="row" spacing="1rem">
              {genericProfile.socialLinks?.twitterLink && (
                <IconButton
                  target="_blank"
                  href={genericProfile.socialLinks?.twitterLink}
                  rel="noopener noreferrer"
                  aria-label={`${genericProfile.name} Twitter`}
                >
                  <TwitterIcon />
                </IconButton>
              )}
              {genericProfile.socialLinks?.linkedInLink && (
                <IconButton
                  target="_blank"
                  href={genericProfile.socialLinks?.linkedInLink}
                  rel="noopener noreferrer"
                  aria-label={`${genericProfile.name} Linkedin`}
                >
                  <LinkedinIcon />
                </IconButton>
              )}

              {genericProfile.socialLinks?.webLink && (
                <IconButton
                  target="_blank"
                  href={genericProfile.socialLinks?.webLink}
                  rel="noopener noreferrer"
                  aria-label={`${genericProfile.name} internet`}
                >
                  <InternetIcon />
                </IconButton>
              )}
            </SocialMediaStackStyled>
          </SegmentProfileInfoStyled>

          <Grid item md={12} xs={12} alignContent="flex-start">
            <Box id="tabs-container">
              <TabsStyled
                value={currentTab}
                variant="scrollable"
                onChange={handleTabsChange}
                scrollButtons="auto"
                aria-label={SCROLL_ELEMENT}
              >
                {tabComponents &&
                  tabComponents
                    .filter(({ isEnabled }) => isEnabled)
                    .map((item, index: number) => {
                      return (
                        <Tab
                          aria-label={TAB_HEADER(item.label)}
                          id={TAB_HEADER(item.label)}
                          key={TAB_HEADER(item.label)}
                          label={item.label}
                          value={index}
                        />
                      );
                    })}
              </TabsStyled>
              {!isLoading && (
                <ContentBoxStyled>
                  {tabComponents &&
                    tabComponents
                      .filter(({ isEnabled }) => isEnabled)
                      .map((tab, index: number) => {
                        const isMatch = index === currentTab;

                        return isMatch ? (
                          <Fragment key={index}>
                            {tab.header && (
                              <>
                                <TabContentStyled
                                  aria-label={TAB_CONTENT_HEADER(tab.label)}
                                  id={TAB_CONTENT_HEADER(tab.label)}
                                  key={TAB_CONTENT_HEADER(tab.label)}
                                >
                                  {tab.header}
                                </TabContentStyled>
                                <Divider />
                              </>
                            )}
                            <TabContentStyled
                              aria-label={TAB_CONTENT(tab.label)}
                              id={TAB_CONTENT(tab.label)}
                              key={TAB_CONTENT(tab.label)}
                            >
                              {tab.component}
                            </TabContentStyled>
                          </Fragment>
                        ) : (
                          <Box
                            aria-label={TAB_CONTENT(tab.label)}
                            id={TAB_CONTENT(tab.label)}
                            key={TAB_CONTENT(tab.label)}
                          />
                        );
                      })}
                </ContentBoxStyled>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container>{isLoading && <Grid item>{/* <FeedSkeleton /> */}</Grid>}</Grid>
      </ProfileContainerStyled>
    </>
  );
};

export default ProfileTemplate;
