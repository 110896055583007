import { CONNECT_URL } from 'constants/app-config';

export enum SupportedLanguage {
  JAVASCRIPT = 'javascript',
  CURL = 'curl',
  JAVA = 'java'
}
export const GettingStartedCodeSamples: { [key: string]: string } = {
  [SupportedLanguage.JAVA]: `import java.net.http.HttpClient;
  import java.net.http.HttpHeaders;
  import java.net.http.HttpRequest;
  import java.net.http.HttpResponse;
  
  public class Main {
  
      public static void main(String[] args) throws Exception {
          HttpClient client = HttpClient.newHttpClient();
          HttpRequest request = HttpRequest.newBuilder()
                  .uri(new URI("${CONNECT_URL}"))
                  .header("x-company-id", "{{COMPANY_ID}}")
                  .header("Authorization", "{{API_KEY}}")
                  .build();
  
          HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
  
          if (response.statusCode() == 200) {
              System.out.println(response.body());
          } else {
              System.out.println("Error: " + response.statusCode());
          }
      }
  }`,
  [SupportedLanguage.JAVASCRIPT]: `fetch('${CONNECT_URL}', {
    method: 'GET', 
    headers: {
      'x-company-id': {{COMPANY_ID}},
      'Authorization': {{API_KEY}}
    }
})`,
  [SupportedLanguage.CURL]: `curl -X GET '${CONNECT_URL}' \
  -H 'x-company-id: {{COMPANY_ID}}' \
  -H 'Authorization: {{API_KEY}}'`
};
