import { CloseIcon } from 'assets';
import { BaseCardWrapperProps } from './BaseCardWrapper.props';
import { CardStyled, IconButtonStyled } from './BaseCardWrapper.style';

const BaseCardWrapper = ({
  children,
  borderWidth,
  borderColor,
  backgroundColor,
  onClick,
  onClose,
  sx,
  includeHighlight
}: BaseCardWrapperProps): React.ReactElement => {
  return (
    <CardStyled
      borderWidth={borderWidth}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      onClick={onClick}
      sx={sx}
      includeHighlight={includeHighlight}
    >
      {onClose && (
        <IconButtonStyled onClick={onClose}>
          <CloseIcon />
        </IconButtonStyled>
      )}
      {children}
    </CardStyled>
  );
};

export default BaseCardWrapper;
