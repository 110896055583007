import { Stack, Typography, styled } from '@mui/material';

const StackStyled = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  '& blockquote': {
    borderLeft: `0.25rem solid ${theme.palette.divider} !important`,
    marginBottom: '0.3125rem !important',
    marginTop: '0.3125rem !important',
    paddingLeft: '1rem !important'
  },
  '& pre': {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
    borderRadius: '0.1875rem !important',
    overflow: 'visible !important',
    padding: '0.3125rem !important',
    whiteSpace: 'pre-wrap !important'
  }
}));

export const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 10,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

export default StackStyled;
