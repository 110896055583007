export const PROFILE_LABELS = {
  ACCOUNT: 'Account',
  ALL_TIME: 'All Time',
  CONNECT: 'Connect',
  CONNECT_SUBTITLE:
    'Connecting your outside accounts allows you to earn bits for your transactions or even grant membership in certain collectives.',
  CURRENT: 'Current Season',
  CURRENT_SEASON_NUMBER: (number: number): string => `season ${number}`,
  GO_EARN_POINTS: 'No activity yet',
  EARNINGS: 'Earnings',
  EVENTS: 'Events',
  HEDERA: 'Hedera',
  HELP: 'Help',
  HIGHTEST: 'Highest Total',
  HOME: 'Home',
  LINKED_ACCOUNTS: 'Linked Accounts',
  MAIN: 'hedera mainnet',
  MOST_SHARES: 'Most Bits',
  MY_OWNERSHIP: 'My Ownership',
  MY_POINTS: 'My Points',
  OWNERSHIP: 'Ownership',
  BITS: 'Bits',
  POINTS_REMAINING: 'Dollars Remaining',
  PROFILE: 'Profile',
  SETTINGS: 'Settings',
  DAO_NOTIFICATION_SETTINGS: 'Notifications',
  SHARES: 'Collective Shares',
  TEST: 'hedera testnet',
  TOTAL: 'Total',
  VALUE: 'Value',
  VALUE_TIP: 'Value information displayed is for informational purposes only.',
  VIEW_PROFILE: 'View Profile',
  VIEW_DAO: 'View DAO'
};

export const PROFILE_HEADER_LABELS = {
  MORNING: 'Good Morning, ',
  AFTERNOON: 'Good Afternoon, ',
  EVENING: 'Good Evening, '
};

export const PROFILE_CHARTS = {
  ALL_TIME: 'All Time',
  TOTAL_AWARDED: 'Total Awarded',
  TOTAL_VALUE: 'Total Value',
  VALUE: 'Value',
  HISTORY: 'History'
};

export const PROFILE_ACTIONS = {
  GET_INVOLVED: 'Get Involved',
  AVAILABLE_ACTIVITIES: 'available activities',
  CHECK_IT_OUT: 'check it out'
};
