import { Button, Stack, Typography } from '@mui/material';
import { Room } from '@piefi-platform/types-lib';
import { InputForm, TextAreaForm } from 'components';
import { BUTTON_LABELS } from 'constants/button.labels';
import { ROOM_FORM } from 'constants/room.labels';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import RoomFormProps from './RoomForm.props';
import { useNotification } from 'hooks';
import { Severity } from 'types/enum';

const RoomForm = ({ close, submit, room }: RoomFormProps): React.ReactElement => {
  const {
    SUBMIT_BUTTON_TEXT,
    ROOM_DESCRIPTION_LABEL,
    ROOM_DESCRIPTION_ERROR,
    ROOM_DESCRIPTION_INPUT_PLACEHOLDER,
    ROOM_NAME_LABEL,
    ROOM_NAME_ERROR,
    ROOM_NAME_INPUT_PLACEHOLDER
  } = ROOM_FORM;
  const { CANCEL } = BUTTON_LABELS;

  const { notify } = useNotification();

  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset
  } = useForm<Room>({
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: room?.name ?? '',
      description: room?.description ?? ''
    }
  });

  const onCancel = () => {
    reset();
    close();
  };

  useEffect(() => {
    // cleaning fields up after submit event
    isSubmitSuccessful && reset();
  }, [isSubmitSuccessful, reset]);

  const acceptButton = () => (
    <Button
      data-testid="btn-submit"
      variant="contained"
      color="primary"
      size="large"
      type="submit"
      fullWidth={!!room}
    >
      {SUBMIT_BUTTON_TEXT(room)}
    </Button>
  );

  const handleValidationFailed = (errors: any) => {
    const keys = Object.keys(errors);
    notify(errors[keys[0]].message, { severity: Severity.Error });
  };

  const cancelButton = () => (
    <Button
      data-testid="btn-cancel"
      variant="outlined"
      color="primary"
      size="large"
      fullWidth={!!room}
      onClick={onCancel}
    >
      {CANCEL}
    </Button>
  );

  return (
    <form data-testid="room-form" onSubmit={handleSubmit(submit, handleValidationFailed)}>
      <Stack spacing={3}>
        <Stack spacing={1.5}>
          <Typography variant="body1" color="common.black">
            {ROOM_NAME_LABEL}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: ROOM_NAME_ERROR }}
            render={({ field, fieldState }) => (
              <InputForm
                id={field.name}
                data-testid={field.name}
                {...field}
                fullWidth
                placeholder={ROOM_NAME_INPUT_PLACEHOLDER}
                error={!!fieldState.error}
                helperText={fieldState.error?.message && `* ${fieldState.error?.message}`}
              />
            )}
          />
        </Stack>

        <Stack spacing={1.5}>
          <Typography variant="body1" color="common.black">
            {ROOM_DESCRIPTION_LABEL}
          </Typography>
          <Controller
            name="description"
            control={control}
            rules={{ maxLength: { value: 4096, message: ROOM_DESCRIPTION_ERROR } }}
            render={({ field }) => (
              <TextAreaForm
                id={field.name}
                data-testid={field.name}
                {...field}
                value={field.value ?? ''}
                minRows={4}
                maxRows={4}
                placeholder={ROOM_DESCRIPTION_INPUT_PLACEHOLDER}
              />
            )}
          />
        </Stack>

        {!room && (
          <Stack direction="row" marginTop="3.25rem !important" spacing={1.5}>
            {acceptButton()} {cancelButton()}
          </Stack>
        )}
        {room && (
          <Stack direction="row" display="flex" justifyContent="space-between" spacing={3}>
            {cancelButton()} {acceptButton()}
          </Stack>
        )}
      </Stack>
    </form>
  );
};

export default RoomForm;
