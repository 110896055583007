export const HELP_LABELS = {
  APP_INFO: 'App Info',
  IMPORTANT_LINKS: 'Important Links',
  BETA: 'beta',
  API: 'api',
  VERSION: (version: string): string => `v${version}`
};

export const HELP_APP_INFO_LINKS = {
  RELEASE_NOTES: 'Release Notes',
  SUBMIT_BUG: 'Submit a Bug',
  REQUEST_FEATURE: 'Request a Feature',
  CONTACT_US: 'Contact Us'
};

export const HELP_IMPORTANT_LINKS = {
  PRIVACY_POLICY: 'Privacy Policy',
  MEMBERSHIP_AGREEMENT: 'Membership Agreement',
  TERMS_OF_SERVICE: 'Terms of Service',
  FAQ: 'FAQs'
};
