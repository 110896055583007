import { Stack } from '@mui/material';
import { BaseSectionHeader } from 'components/headers';
import { TitledColumnSectionWrapperProps } from './TitledColumnSectionWrapper.props';

const TitledColumnSectionWrapper = ({
  title,
  subtitle,
  children
}: TitledColumnSectionWrapperProps): React.ReactElement => {
  return (
    <Stack direction={'column'}>
      <BaseSectionHeader title={title} subtitle={subtitle} />
      {children}
    </Stack>
  );
};

export default TitledColumnSectionWrapper;
