import {
  AllocatedUnitsDto,
  AwardedUnitsDto,
  BaseQueryParams,
  DaoDTO,
  DaoEquityDataDTO,
  DaoMembershipDTO,
  DaoPublicDTO,
  DaoQueryParams,
  DaoUpdatePayload,
  ManageableEntityDTO,
  ManageableEntityQueryParams,
  PointAwardEventWithMemberSeasonDTO,
  PointAwardEventQueryDto,
  UnallocatedPointsDto
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import { TokenType } from 'types/enum';
import generateQueryString from 'utils/helper/query-builder';
import DaoService from './use-dao-service.model';
import { daoTokenUrl, daoUrl } from './use-dao-service.url';
import { DaoMemberQueryType } from 'model';
import { DaoMemberCheckbox } from 'components/tables/dao-admin/DaoAdminMemberRow.props';

const useDaoService = (): DaoService => {
  const { get, post, put } = useHttp();

  const getDaoEquityData = useCallback(
    (daoId: string): Promise<AxiosResponse<DaoEquityDataDTO>> => {
      return post(`${daoUrl}/${daoId}/equity-data`);
    },
    [post]
  );

  const getDaoUnallocatedPoints = useCallback(
    (daoId: string): Promise<AxiosResponse<UnallocatedPointsDto>> => {
      return get<UnallocatedPointsDto>(`${daoUrl}/${daoId}/unallocated-points`);
    },
    [get]
  );

  const getDaoAllocatedUnits = useCallback(
    (daoId: string): Promise<AxiosResponse<AllocatedUnitsDto>> => {
      return get<AllocatedUnitsDto>(`${daoUrl}/${daoId}/allocated-units`);
    },
    [get]
  );

  const getDaoAwardedUnits = useCallback(
    (
      daoId: string
    ): Promise<
      AxiosResponse<AwardedUnitsDto & { awardedPoints: number; awardedDollars: number }>
    > => {
      return get<AwardedUnitsDto & { awardedPoints: number; awardedDollars: number }>(
        `${daoUrl}/${daoId}/awarded-units`
      );
    },
    [get]
  );

  const getPageOfDaos = useCallback(
    (params: Partial<DaoQueryParams>): Promise<AxiosResponse<Array<DaoPublicDTO>>> => {
      return get<DaoPublicDTO[]>(
        `${daoUrl}?page=${params.page}&size=${params.size}${
          params.subdomain ? `&subdomain=${params.subdomain}&subdomainQueryMode=equals` : ''
        }`
      );
    },
    [get]
  );

  const getDaoInfo = useCallback(
    (daoId: string): Promise<AxiosResponse<DaoDTO>> => {
      return get<DaoDTO>(`${daoUrl}/${daoId}`);
    },
    [get]
  );

  const requestToJoin = useCallback(
    (daoId: string): Promise<AxiosResponse> => {
      return post(`${daoUrl}/${daoId}/manageable-entities/-/join-requests`);
    },
    [post]
  );

  const getManageableEntitiesByDaoId = useCallback(
    (
      daoId: string,
      query: ManageableEntityQueryParams
    ): Promise<AxiosResponse<ManageableEntityDTO[]>> => {
      const queryString = generateQueryString(query);
      return get<ManageableEntityDTO[]>(`${daoUrl}/${daoId}/manageable-entities?${queryString}`);
    },
    [get]
  );

  const getDaoMemberships = useCallback(
    (
      daoId: string,
      queryParams: DaoMemberQueryType
    ): Promise<AxiosResponse<DaoMembershipDTO[]>> => {
      const queryString = generateQueryString(queryParams);
      return get<DaoMembershipDTO[]>(
        `${daoUrl}/${daoId}/dao-memberships?${queryString}
        `
      );
    },
    [get]
  );

  const getDaoMembershipsWithInvites = useCallback(
    (
      daoId: string,
      queryParams: DaoMemberQueryType
    ): Promise<AxiosResponse<DaoMemberCheckbox[]>> => {
      const queryString = generateQueryString(queryParams);
      return get<DaoMemberCheckbox[]>(
        `${daoUrl}/${daoId}/dao-memberships/dao-membership-invites?${queryString}
        `
      );
    },
    [get]
  );

  const getDaoTokens = useCallback(
    (
      daoId: string,
      { size, page }: BaseQueryParams,
      tokenType: TokenType
    ): Promise<AxiosResponse<any>> => {
      const url: string = `${daoTokenUrl(daoId)}?page=${page}&size=${size}&type=${tokenType}`;
      return get(url);
    },
    [get]
  );

  const updateDaoMemberPermissions = useCallback(
    (
      daoId: string,
      role: string,
      daoMemberId: string
    ): Promise<AxiosResponse<Array<DaoMembershipDTO>>> => {
      return put(`${daoUrl}/${daoId}/dao-memberships/${daoMemberId}`, { role });
    },
    [put]
  );

  const updateDaoById = useCallback(
    (daoId: string, data: DaoUpdatePayload): Promise<AxiosResponse<DaoDTO>> => {
      return put<DaoUpdatePayload, AxiosResponse<DaoDTO>>(`${daoUrl}/${daoId}`, data);
    },
    [put]
  );

  const getPointAwardEventsByDaoId = useCallback(
    (
      daoId: string,
      params: PointAwardEventQueryDto
    ): Promise<AxiosResponse<PointAwardEventWithMemberSeasonDTO[]>> => {
      return get<PointAwardEventWithMemberSeasonDTO[]>(
        `${daoUrl}/${daoId}/point-award-events?page=${params.page}&size=${params.size}${
          params.includeDollarValueAtTimeOfAllocation
            ? `&includeDollarValueAtTimeOfAllocation=true`
            : ''
        }${params.includeCurrentDollarValue ? `&includeCurrentDollarValue=true` : ''}`
      );
    },
    [get]
  );

  return {
    getDaoAllocatedUnits,
    getDaoAwardedUnits,
    getDaoUnallocatedPoints,
    getDaoInfo,
    getDaoMemberships,
    getDaoTokens,
    getManageableEntitiesByDaoId,
    getPageOfDaos,
    requestToJoin,
    updateDaoById,
    updateDaoMemberPermissions,
    getPointAwardEventsByDaoId,
    getDaoMembershipsWithInvites,
    getDaoEquityData
  };
};

export default useDaoService;
