import { Typography } from '@mui/material';
import React from 'react';
import IErrorFormProps from './ErrorForm.props';

const ErrorForm = ({ message }: IErrorFormProps): React.ReactElement => {
  return (
    <div data-testid="error-message">
      <Typography role="alert" variant="caption" color={'red'}>
        * {message}
      </Typography>
    </div>
  );
};

export default ErrorForm;
