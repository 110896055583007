import { Box, Stack, StackProps, styled } from '@mui/material';

export const IconBox = styled(Box)({
  width: '2rem',
  height: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const SidebarStackStyled = styled(Stack)<StackProps>(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '&:hover .comment-icon, .comments-count': {
      visibility: 'visible',
      opacity: 1
    },
    '& .comment-icon, .comments-count': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 0.3s linear'
    }
  }
}));
