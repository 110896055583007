import { Autocomplete, Chip, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EmailRegex } from 'constants/auth.constants';
import { MultiEmailInputLabel } from './multi-email-input.label';

type MultiEmailInputProps = TextFieldProps & {
  onEmailListChange: (emailList: string[]) => void;
};

const MultiEmailInput = ({
  onEmailListChange,
  ...rest
}: MultiEmailInputProps): React.ReactElement => {
  const [emailChips, setEmailChips] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    if (typeof newValue === 'string' && !emailChips.includes(newValue)) {
      setEmailChips((prev) => [...prev, newValue]);
    } else if (Array.isArray(newValue)) {
      if (!newValue.length) {
        setEmailChips((prev) => []);
        return;
      }
      const newEmail = newValue[newValue.length - 1];
      if (EmailRegex.test(newEmail)) {
        setEmailChips(newValue);
      } else {
        setError(MultiEmailInputLabel.ENTER_VALID);
      }
    }
  };

  useEffect(() => {
    onEmailListChange(emailChips);
  }, [emailChips]);

  return (
    <Autocomplete
      multiple
      disableClearable
      limitTags={2}
      freeSolo
      sx={{ width: '100%' }}
      id="email-chips-autocomplete"
      options={[]}
      open={false}
      value={emailChips}
      onChange={handleOnChange}
      renderTags={(value: any, getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} key={index} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...rest}
          {...params}
          onChange={(e) => {
            setError('');
          }}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{rest.InputProps?.endAdornment}</>
          }}
        />
      )}
    />
  );
};

export default MultiEmailInput;
