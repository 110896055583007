import {
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ProposalOptionDTO } from '@piefi-platform/types-lib';
import { ProposalsIcon } from 'assets';
import AddIcon from 'assets/AddIcon';
import RemoveIcon from 'assets/RemoveIcon';
import { InputForm, TextEditor } from 'components';
import { ErrorForm } from 'components/labels';
import { MAX_OPTION_TEXT_LENGTH, PROPOSAL_RESTRICTION } from 'constants/app-config';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { HTTP_STATUS } from 'constants/http-statuses';
import { PROPOSAL_FORM } from 'constants/post.labels';
import { POST_FORM_LABELS, UI_LABELS } from 'constants/ui-labels';
import { useFeed, useFeedPage } from 'hooks';
import { usePostService } from 'hooks/services';
import { PostParam } from 'model';
import { ProposalOptionParam } from 'model/proposal-options-param.model';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Controller,
  DefaultValues,
  FieldArrayWithId,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { joinDateAndTime, subtractDates } from 'utils';
import { ProposalFormProps } from './ProposalForm.props';
import {
  BoxMainContainerStyled,
  ListStyled,
  OptionListItemStyled,
  TypographyStyled
} from './ProposalForm.style';

const ProposalForm = ({ daoId, room, editMode }: ProposalFormProps): React.ReactElement => {
  const theme = useTheme();
  const { createProposalPost, updateProposal } = usePostService();
  const { post, setPost, setShowEditForm } = useFeed();
  const { setPosts, posts, setShowCreatePostForm } = useFeedPage();
  const [minTime, setMinTime] = useState<Date | undefined>(undefined);
  const minRequiredOptions = PROPOSAL_RESTRICTION.MIN_REQUIRED_OPTIONS;
  const { MAX_OPTION_LENGTH } = UI_LABELS;
  const {
    ADD_ANOTHER,
    ADDITIONAL_INFORMATION,
    NEW_PROPOSAL,
    OPTION,
    PROPOSAL_OPTIONS,
    PROPOSAL_TEXTAREA_PLACEHOLDER,
    PROPOSAL_TITLE
  } = POST_FORM_LABELS;
  const { SAVE, POST, CANCEL } = BUTTON_LABELS;
  const { REQUIRED } = COMMON_VALIDATIONS;

  // hook form
  const defaultValues: DefaultValues<PostParam> = {
    title: editMode ? post?.title : '',
    content: editMode ? post?.content : '',
    proposal: {
      proposalOptions: editMode
        ? post?.proposal?.proposalOptions
        : [{ optionText: '' }, { optionText: '' }],
      completionDate: editMode ? post?.proposal?.completionTimestamp : null,
      completionTime: editMode ? post?.proposal?.completionTimestamp : null,
      completionTimestamp: editMode ? post?.proposal?.completionTimestamp : undefined
    }
  };

  const hookFormProps = useForm<PostParam>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = hookFormProps;

  const { fields, append, remove } = useFieldArray<PostParam>({
    control,
    name: 'proposal.proposalOptions'
  });

  /**
   * Create a new optional option text
   */
  const addNewOption = (): void => {
    append({ optionText: '' } as ProposalOptionDTO);
  };

  /**
   * Remove an optional option text
   * @param index id to remove the option
   */
  const removeOption = (index: string): void => {
    remove(+index);
  };

  const removeButtonFill =
    fields.length > minRequiredOptions ? theme.palette.brand.red[500] : theme.palette.grey[300];

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const update = useCallback(
    async (proposalParams: PostParam) => {
      try {
        if (editMode && post && proposalParams) {
          const { title, content } = proposalParams;
          const editProposalRes = await updateProposal(
            daoId,
            room.id,
            post?.id,
            post.proposal?.id || '',
            title,
            content ? content : undefined
          );

          if (editProposalRes.status === HTTP_STATUS.OK) {
            setPost(() => editProposalRes.data);
            setShowEditForm(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [daoId, editMode, post, room.id, setPost, setShowEditForm, updateProposal]
  );

  const create = useCallback(
    async (proposalParams: PostParam) => {
      try {
        if (proposalParams) {
          const { proposal, title, content } = proposalParams;

          const { completionDate, completionTime, ...otherProposalProps } =
            proposal || ({} as ProposalOptionParam);

          otherProposalProps.completionTimestamp = joinDateAndTime(
            completionDate ?? new Date(),
            completionTime ?? new Date()
          );
          if (!otherProposalProps.content) {
            otherProposalProps.content = undefined;
          }
          const createProposalRes = await createProposalPost(
            daoId,
            room.id,
            title,
            content ? content : undefined,
            [],
            otherProposalProps
          );
          if (createProposalRes?.status === HTTP_STATUS.OK) {
            setPosts([createProposalRes.data, ...posts]);
            setShowCreatePostForm(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [createProposalPost, daoId, posts, room.id, setPosts, setShowCreatePostForm]
  );

  const submit: SubmitHandler<PostParam> = async (values) => {
    if (editMode) {
      update(values);
    } else {
      create(values);
    }
  };

  useEffect(() => {
    // this code triggers time validations after a date is selected
    const subscription = watch(({ proposal }, { name, type }) => {
      if (name === 'proposal.completionDate' && type === 'change') {
        const today = new Date();
        const selectedDate: Date = proposal?.completionDate ?? today;

        if (subtractDates(selectedDate, today) === 0) {
          setMinTime(today);
          setValue('proposal.completionTime', today);
        } else {
          setMinTime(undefined);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <FormProvider {...hookFormProps}>
      <form onSubmit={handleSubmit(submit)}>
        <BoxMainContainerStyled>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '0.857rem' }}>
            <ProposalsIcon data-icon="poll" />
            <Typography variant="h6">{NEW_PROPOSAL}</Typography>
          </Box>

          <Controller
            control={control}
            data-testid={'title'}
            name="title"
            rules={{ required: REQUIRED }}
            render={({ field, fieldState }) => {
              return (
                <InputForm
                  id={field.name}
                  {...field}
                  autoFocus
                  fullWidth
                  hiddenLabel
                  inputProps={{ maxLength: 128, 'data-testid': 'title' }}
                  error={!!fieldState.error}
                  placeholder={PROPOSAL_TITLE}
                  size="small"
                  type={'text'}
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />

          <hr style={{ width: '100%' }} />

          <Stack gap={1.5}>
            <Typography variant="body1">{PROPOSAL_FORM.PROPOSAL_SETTINGS}</Typography>
            <Stack direction="row" gap={1.5}>
              <Stack gap="0.5rem" width="100%">
                <Typography variant="caption" color="text.primary">
                  {PROPOSAL_FORM.CLOSE_DATE}
                </Typography>
                <Controller
                  name="proposal.completionDate"
                  control={control}
                  rules={{ required: REQUIRED }}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={editMode}
                        disablePast
                        value={field.value}
                        onChange={field.onChange}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            id={field.name}
                            {...field}
                            {...params}
                            onKeyDown={onKeyDown}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
              <Stack gap="0.5rem" width="100%">
                <Typography variant="caption" color="text.primary">
                  {PROPOSAL_FORM.CLOSE_TIME}
                </Typography>
                <Controller
                  name="proposal.completionTime"
                  control={control}
                  rules={{ required: REQUIRED }}
                  render={({ field, fieldState }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        disabled={editMode}
                        value={field.value}
                        onChange={(selectedDate) =>
                          selectedDate && minTime && minTime > selectedDate
                            ? field.onChange(minTime)
                            : field.onChange(selectedDate)
                        }
                        ampmInClock
                        inputFormat="hh:mm a"
                        minTime={minTime}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            fullWidth
                            id={field.name}
                            {...field}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>

          <ListStyled>
            <TypographyStyled variant="caption">{PROPOSAL_OPTIONS}</TypographyStyled>
            {fields?.map((option: FieldArrayWithId<PostParam>, index: number) => {
              return (
                <OptionListItemStyled key={option.id}>
                  <Controller
                    control={control}
                    name={`proposal.proposalOptions.${index}.optionText`}
                    rules={{
                      required: REQUIRED,
                      maxLength: {
                        value: MAX_OPTION_TEXT_LENGTH,
                        message: MAX_OPTION_LENGTH
                      }
                    }}
                    render={({ field, fieldState }) => (
                      <InputForm
                        id={field.name}
                        {...field}
                        disabled={editMode}
                        fullWidth
                        size="small"
                        placeholder={`${OPTION} ${index + 1}`}
                        error={!!fieldState.error}
                        inputProps={{ maxLength: MAX_OPTION_TEXT_LENGTH }}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <ListItemIcon>
                    <IconButton
                      onClick={() => removeOption(index + '')}
                      disabled={fields.length <= minRequiredOptions || editMode}
                    >
                      <RemoveIcon fill={removeButtonFill} />
                    </IconButton>
                  </ListItemIcon>
                </OptionListItemStyled>
              );
            })}
            <ListItem data-item="add-proposal">
              <Button
                variant="text"
                disableRipple
                onClick={addNewOption}
                disabled={fields.length >= PROPOSAL_RESTRICTION.MAX_OPTIONS || editMode}
                startIcon={<AddIcon fontSize="small" />}
                size="small"
              >
                {ADD_ANOTHER}
              </Button>
            </ListItem>
          </ListStyled>

          <hr style={{ width: '100%' }} />
          <TypographyStyled variant="caption">{ADDITIONAL_INFORMATION}</TypographyStyled>
          <Controller
            control={control}
            name="content"
            rules={{
              required: REQUIRED,
              maxLength: 4096
            }}
            render={({ field: { name, value, onChange } }) => (
              <Box>
                <TextEditor
                  name={name}
                  value={value || ''}
                  showActions={false}
                  placeholder={PROPOSAL_TEXTAREA_PLACEHOLDER}
                  toolBarId="post-proposal-toolbar"
                  onChange={onChange}
                />
                {errors?.content && <ErrorForm message={errors.content.message} />}
              </Box>
            )}
          />
          <Stack direction={editMode ? 'row' : 'column'} alignItems="center" gap={editMode ? 3 : 0}>
            <Box width={editMode ? '50%' : '100%'}>
              {editMode && (
                <Button
                  onClick={() => setShowEditForm(false)}
                  role="button"
                  type="submit"
                  color="primary"
                  size="large"
                  variant="outlined"
                  fullWidth
                  style={{ height: '3.25rem' }} // TODO: create new button size variant
                >
                  {CANCEL}
                </Button>
              )}
            </Box>
            <Box width={editMode ? '50%' : '100%'}>
              <Button
                role="button"
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                style={{ height: '3.25rem' }} // TODO: create new button size
                data-testid="submit"
              >
                {editMode ? SAVE : POST}
              </Button>
            </Box>
          </Stack>
        </BoxMainContainerStyled>
      </form>
    </FormProvider>
  );
};

export default ProposalForm;
