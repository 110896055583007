import { LANGUAGE } from 'constants/ui-labels';
import { format, formatDistanceToNow, isToday, isYesterday } from 'date-fns';

const formatDate = (unformattedDate: string | Date): string => {
  const date = new Date(unformattedDate);
  return date.toDateString();
};

const formatMonthYearDate = (date: Date): string => {
  return (
    new Date(date).toLocaleDateString(LANGUAGE.US, {
      timeZone: 'UTC',
      month: 'short',
      year: 'numeric'
    }) + ''
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const formatDateLong = (dateString: string) => {
  const newString = new Date(dateString);
  return new Intl.DateTimeFormat(LANGUAGE.US, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }).format(newString);
};

/**
 * @description accepts a date and returns a string describing how long ago it was.
 * If it is longer than yesterday it will return a formatted string
 * @param inputDate {Date}
 * @returns {string}
 */
function formatCustomDate(inputDate: Date): string {
  if (isToday(inputDate)) {
    // If the input date is today, return relative time (e.g., "5 minutes ago")
    return formatDistanceToNow(inputDate, { addSuffix: true });
  } else if (isYesterday(inputDate)) {
    // If the input date was yesterday, return "Yesterday at HH:MM AM/PM"
    return `Yesterday at ${format(inputDate, 'h:mm a')}`;
  } else {
    // Otherwise, return the date in "MM/DD/YYYY at HH:MM AM/PM" format
    return format(inputDate, "MM/dd/yyyy 'at' h:mm a");
  }
}

export { formatCustomDate, formatDate, formatDateLong, formatMonthYearDate };
