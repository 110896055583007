import { TextField, styled } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const TextFieldStyled = styled(TextField)({
  '& input': {
    padding: 0
  },
  '& .MuiButton-root': {
    padding: '1rem',
    minHeight: '3.25rem'
  }
});

const InputGroupStyled = makeStyles(() =>
  createStyles({
    endAdornment: {
      padding: 0,
      paddingLeft: '.5rem'
    }
  })
);

export { TextFieldStyled, InputGroupStyled };
