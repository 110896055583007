import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Link04(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M7.5 7H7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17H9C11.7614 17 14 14.7614 14 12M16.5 17H17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7H15C12.2386 7 10 9.23858 10 12"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
