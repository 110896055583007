import { Theme } from '@mui/material/styles';
import { FONT_PRIMARY } from '../typography';

// ----------------------------------------------------------------------

export default function Link(theme: Theme): object {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },

      styleOverrides: {
        root: {
          padding: 0,
          fontFamily: FONT_PRIMARY,
          backgroundColor: theme.palette.button.primary.linkBackground,
          color: theme.palette.text.secondary,
          '&:hover': {
            backgroundColor: theme.palette.button.primary.background,
            color: theme.palette.button.primary.color
          }
        }
      }
    }
  };
}
