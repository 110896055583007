import { Avatar, Button, Stack, Typography } from '@mui/material';
import { DaoDTO, DaoUpdatePayload } from '@piefi-platform/types-lib';
import { InputForm } from 'components/inputs';
import { ErrorForm } from 'components/labels';
import { FileButton } from 'components/profile/tab-profile/profile-edit/ProfileEdit.style';
import { COMPLETE_PROFILE } from 'constants/auth.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { DAO_EDIT } from 'constants/dao-admin-labels';
import { DAO_ADMIN_LABELS } from 'constants/dao-admin-nav.labels';
import { ROUTES } from 'constants/routes';
import {
  discordInviteLinkHelperText,
  discordInviteLinkRegex,
  discordInviteLinkRegexString,
  discordLink,
  instagramLink,
  instagramLinkHelperText,
  linkedInLink,
  linkedInLinkHelperText,
  linkedInLinkRegex,
  linkedInLinkRegexString,
  socialLinksRegex,
  socialLinksRegexString,
  twitterLink,
  twitterLinkHelperText
} from 'constants/social-links.constants';
import { useDao, useNotification } from 'hooks';
import { useDaoService } from 'hooks/services';
import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Severity, UserDaoRole } from 'types/enum';
import { compareDaoRoles, uploadImage } from 'utils';

const DaoEdit = (): ReactElement => {
  const {
    BASIC_INFO,
    CHANGE_AVATAR,
    COMMUNITY_NAME,
    BIO,
    SOCIAL_LINKS,
    PLEASE_INCLUDE_ENTIRE_LINK,
    TWITTER,
    LINKEDIN,
    WEBSITE,
    DISCORD_LINK,
    SAVE,
    CANCEL,
    ERROR_UPDATING_PROFILE,
    DAO_PROFILE_UPDATED,
    INVALID_IMAGE_ERROR,
    INSTAGRAM
  } = DAO_EDIT;
  const {
    INVALID_TWITTER_HANDLE,
    INVALID_LINKEDIN_LINK,
    INVALID_INSTAGRAM_HANDLE,
    INVALID_DISCORD_INVITE_LINK
  } = COMPLETE_PROFILE;

  const [dao, setDao] = useState<DaoDTO>();
  const [submitting, setSubmitting] = useState(false);
  const { activeDaoMembership } = useDao();
  const { getDaoInfo, updateDaoById } = useDaoService();
  const { notify } = useNotification();
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();
  const { fetchAndSetDao, currentDao } = useDao();
  const { REQUIRED, MAX_LENGTH } = COMMON_VALIDATIONS;
  const { MIN_MAX_LENGTH } = DAO_ADMIN_LABELS;
  const [isTwitterLinkValid, setIsTwitterLinkValid] = useState(true);
  const [isLinkedInLinkValid, setIsLinkedInLinkValid] = useState(true);
  const [isInstagramLinkValid, setIsInstagramLinkValid] = useState(true);
  const [isDiscordLinkValid, setIsDiscordLinkValid] = useState(true);

  const defaultValues: DefaultValues<DaoUpdatePayload> = useMemo(
    () => ({
      about: dao?.about || '',
      linkedInLink: dao?.socialLinks?.linkedInLink || '',
      name: dao?.name || '',
      twitterLink: dao?.socialLinks?.twitterLink || '',
      webLink: dao?.socialLinks?.webLink || '',
      discordLink: dao?.socialLinks?.discordLink || '',
      instagramLink: dao?.socialLinks?.instagramLink || '',
      logoThumbnail: dao?.logoThumbnail || ''
    }),
    [dao]
  );

  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors, isSubmitting, isValid }
  } = useForm<DaoUpdatePayload>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues
  });

  useEffect(() => {
    if (activeDaoMembership) {
      const isAtLeastAdmin = compareDaoRoles(
        UserDaoRole.ADMIN,
        UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole]
      );

      if (!isAtLeastAdmin) navigate(ROUTES.DAO_HOME(currentDao.id));

      const { daoId } = activeDaoMembership;
      const loadData = async () => {
        const response = await getDaoInfo(daoId);
        if (response.data) {
          setDao(response.data);
        }
      };
      loadData();
    }
  }, [activeDaoMembership, getDaoInfo, navigate]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onSubmit: SubmitHandler<DaoUpdatePayload> = (data) => {
    handleConfirmation(data);
  };

  const handleConfirmation = useCallback(
    async (updatedDao: DaoUpdatePayload) => {
      if (!activeDaoMembership) return;
      const { daoId } = activeDaoMembership;
      const updateDao = async () => {
        try {
          setSubmitting(true);

          if (updatedDao.twitterLink && !socialLinksRegex.test(updatedDao.twitterLink)) {
            throw new Error(INVALID_TWITTER_HANDLE);
          }

          if (updatedDao.linkedInLink && !linkedInLinkRegex.test(updatedDao.linkedInLink)) {
            throw new Error(INVALID_LINKEDIN_LINK);
          }

          if (updatedDao.instagramLink && !socialLinksRegex.test(updatedDao.instagramLink)) {
            throw new Error(INVALID_INSTAGRAM_HANDLE);
          }

          if (updatedDao.discordLink && !discordInviteLinkRegex.test(updatedDao.discordLink)) {
            throw new Error(INVALID_DISCORD_INVITE_LINK);
          }

          const logoThumbnail = file && (await uploadImage(`daos/${daoId}/profile-image`, file));
          const response = await updateDaoById(daoId, {
            ...updatedDao,
            linkedInLink: updatedDao?.linkedInLink || null,
            twitterLink: updatedDao?.twitterLink || null,
            webLink: updatedDao?.webLink || null,
            discordLink: updatedDao?.discordLink || null,
            instagramLink: updatedDao?.instagramLink || null,
            logoThumbnail
          });
          if (response.data) {
            setDao(response.data);
            fetchAndSetDao();
            notify(DAO_PROFILE_UPDATED, { severity: Severity.Success });
          } else {
            notify(ERROR_UPDATING_PROFILE, { severity: Severity.Error });
          }
        } catch (error: any) {
          notify(error.response?.message || error?.message);
        } finally {
          setSubmitting(false);
        }
      };
      updateDao();
    },
    [
      DAO_PROFILE_UPDATED,
      ERROR_UPDATING_PROFILE,
      activeDaoMembership,
      notify,
      updateDaoById,
      file,
      fetchAndSetDao
    ]
  );

  const handleCaptureImg = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    event.preventDefault();
    const { target } = event;
    if (target?.files?.length) {
      const file = target?.files[0];
      if (['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type)) {
        const fileReader = new FileReader();
        setFile(file);
        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
          onChange(e.target?.result as string);
        };
      } else {
        notify(INVALID_IMAGE_ERROR, { severity: Severity.Error });
      }
    }
  };

  const handleSocialChanged = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    socialChanged: string
  ) => {
    const input = event.target.value;
    switch (socialChanged) {
      case twitterLink:
        setIsTwitterLinkValid(!input || socialLinksRegex.test(input));
        break;
      case linkedInLink:
        setIsLinkedInLinkValid(!input || linkedInLinkRegex.test(input));
        break;
      case instagramLink:
        setIsInstagramLinkValid(!input || socialLinksRegex.test(input));
        break;
      case discordLink:
        setIsDiscordLinkValid(!input || discordInviteLinkRegex.test(input));
        break;
      default:
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap={3} padding={2}>
        <Typography variant="body1">{BASIC_INFO}</Typography>
        <Stack spacing={3}>
          <Controller
            name={'logoThumbnail'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Stack direction={'row'} spacing={'1.5rem'} alignItems={'center'}>
                <Avatar
                  alt={`${dao?.name} avatar`}
                  style={{ width: '6.5rem', height: '6.5rem' }}
                  src={value ?? ''}
                />

                <FileButton variant="contained" component="label">
                  {CHANGE_AVATAR}
                  <input
                    hidden
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                    multiple
                    type="file"
                    onChange={(e) => handleCaptureImg(e, onChange)}
                  />
                </FileButton>
              </Stack>
            )}
          />
          <Stack>
            <Controller
              name={'name'}
              control={control}
              rules={{
                required: REQUIRED,
                maxLength: {
                  value: 31,
                  message: MIN_MAX_LENGTH('Name', 'max', 31)
                }
              }}
              render={({ field }) => (
                <InputForm
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  hiddenLabel
                  label={COMMUNITY_NAME}
                  {...field}
                />
              )}
            />
            {errors?.name && <ErrorForm message={errors?.name?.message} />}
          </Stack>
          <Stack>
            <Controller
              name={'about'}
              control={control}
              rules={{
                required: REQUIRED,
                maxLength: { value: 256, message: MAX_LENGTH(256) }
              }}
              render={({ field, fieldState }) => (
                <InputForm
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  multiline
                  hiddenLabel
                  label={BIO}
                  minRows={4}
                  maxRows={4}
                  {...field}
                  {...register('about', {
                    maxLength: {
                      value: 256,
                      message: MIN_MAX_LENGTH('Bio', 'max', 256)
                    }
                  })}
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 257 }}
                />
              )}
            />
            {errors?.about && <ErrorForm message={errors.about.message} />}
          </Stack>
        </Stack>
        <Stack gap={3}>
          <Stack gap={0.5}>
            <Typography variant="body1">{SOCIAL_LINKS}</Typography>
            <Typography variant="body2" color="text.secondary">
              {PLEASE_INCLUDE_ENTIRE_LINK}
            </Typography>
          </Stack>
          <Stack>
            <Controller
              name={twitterLink}
              control={control}
              rules={{
                maxLength: {
                  value: 64,
                  message: MIN_MAX_LENGTH('Twitter', 'max', 64)
                }
              }}
              render={({ field }) => (
                <InputForm
                  {...field}
                  placeholder="ex: 'awsm'"
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  hiddenLabel
                  label={TWITTER}
                  error={!isTwitterLinkValid}
                  helperText={isTwitterLinkValid ? '' : twitterLinkHelperText}
                  onChange={(e) => {
                    field.onChange(e);
                    handleSocialChanged(e, field.name);
                  }}
                  inputProps={{ pattern: socialLinksRegexString }}
                />
              )}
            />
            {errors?.twitterLink && <ErrorForm message={errors?.twitterLink?.message} />}
          </Stack>
          <Stack>
            <Controller
              name={linkedInLink}
              control={control}
              rules={{
                maxLength: {
                  value: 64,
                  message: MIN_MAX_LENGTH('LinkedIn', 'max', 64)
                }
              }}
              render={({ field }) => (
                <InputForm
                  {...field}
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  placeholder="ex: 'https://www.linkedin.com/company/realawsm/mycompany/'"
                  hiddenLabel
                  label={LINKEDIN}
                  error={!isLinkedInLinkValid}
                  helperText={isLinkedInLinkValid ? '' : linkedInLinkHelperText}
                  onChange={(e) => {
                    field.onChange(e);
                    handleSocialChanged(e, field.name);
                  }}
                  inputProps={{ pattern: linkedInLinkRegexString }}
                />
              )}
            />
            {errors?.linkedInLink && <ErrorForm message={errors?.linkedInLink?.message} />}
          </Stack>
          <Stack>
            <Controller
              name={instagramLink}
              control={control}
              rules={{
                maxLength: {
                  value: 64,
                  message: MIN_MAX_LENGTH('Instagram', 'max', 64)
                }
              }}
              render={({ field }) => (
                <InputForm
                  {...field}
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  placeholder="ex: 'awsm'"
                  hiddenLabel
                  label={INSTAGRAM}
                  error={!isInstagramLinkValid}
                  helperText={isInstagramLinkValid ? '' : instagramLinkHelperText}
                  onChange={(e) => {
                    field.onChange(e);
                    handleSocialChanged(e, field.name);
                  }}
                  inputProps={{ pattern: socialLinksRegexString }}
                />
              )}
            />
            {errors?.instagramLink && <ErrorForm message={errors?.instagramLink?.message} />}
          </Stack>
          <Stack>
            <Controller
              name={discordLink}
              control={control}
              rules={{
                maxLength: {
                  value: 64,
                  message: MIN_MAX_LENGTH('Discord', 'max', 64)
                }
              }}
              render={({ field }) => (
                <InputForm
                  {...field}
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  placeholder="ex: 'https://discord.gg/asdf1234'"
                  hiddenLabel
                  label={DISCORD_LINK}
                  error={!isDiscordLinkValid}
                  helperText={isDiscordLinkValid ? '' : discordInviteLinkHelperText}
                  onChange={(e) => {
                    field.onChange(e);
                    handleSocialChanged(e, field.name);
                  }}
                  inputProps={{ pattern: discordInviteLinkRegexString }}
                />
              )}
            />
            {errors?.discordLink && <ErrorForm message={errors?.discordLink?.message} />}
          </Stack>

          <Stack>
            <Controller
              name={'webLink'}
              control={control}
              rules={{
                maxLength: {
                  value: 64,
                  message: MIN_MAX_LENGTH('Website', 'max', 64)
                }
              }}
              render={({ field, fieldState }) => (
                <InputForm
                  {...field}
                  InputLabelProps={{
                    disableAnimation: true
                  }}
                  placeholder="ex: 'https://awsm.com'"
                  hiddenLabel
                  label={WEBSITE}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {errors?.webLink && <ErrorForm message={errors?.webLink?.message} />}
          </Stack>
          <Stack paddingTop={3} gap={2}>
            <Stack direction="row" spacing={1}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting || !isValid || submitting}
              >
                {SAVE}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                type="button"
                onClick={() => navigate(ROUTES.DAO_HOME(currentDao.id))}
              >
                {CANCEL}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default DaoEdit;
