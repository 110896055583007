import { Card, CardActionArea, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import { BasicIconImageCardProps } from './BasicIconImageCard.props';
import { BoxStyled } from './BasicIconImageCard.style';

const BasicIconImageCard = ({
  icon,
  label,
  data,
  image
}: BasicIconImageCardProps): React.ReactElement => {
  return (
    <Card sx={{ flex: 1, borderRadius: '0.75rem', '@media': { padding: 0 } }}>
      <CardActionArea sx={{ height: '100%' }}>
        <CardMedia sx={{ height: '66%', padding: '1.5rem 1.5rem 8.25rem' }} image={image}>
          <BoxStyled>{icon}</BoxStyled>
        </CardMedia>
        <CardContent
          sx={{ height: '33%', padding: '1.5rem', backgroundColor: 'black', whiteSpace: 'nowrap' }}
        >
          <Stack spacing={1}>
            <Typography variant={'body2'} fontWeight={'medium'} sx={{ color: 'white' }}>
              {label}
            </Typography>
            {data}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BasicIconImageCard;
