import { Stack, Typography } from '@mui/material';
import { AvatarBuilder } from 'components';
import { UI_LABELS } from 'constants/ui-labels';
import { formatDistanceToNowStrict } from 'date-fns';
import { ReactElement } from 'react';
import { AvatarSize } from 'types/enum';
import { CommentMetaProps } from './CommentMeta.props';

const CommentMeta = ({ createdAt, edited, user, memberId }: CommentMetaProps): ReactElement => {
  return (
    <Stack data-testid="comment-meta" direction="row" spacing={1} alignItems="center">
      <Stack direction="row" spacing={0.5} alignItems="center">
        <AvatarBuilder
          src={user.profileThumbnail}
          alt={user.username}
          id={user.username}
          size={AvatarSize.SMALL}
        />
        <Typography variant="body2">{user.username}</Typography>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        {`${formatDistanceToNowStrict(new Date(new Date(createdAt)))} ${UI_LABELS.AGO}`}
      </Typography>
      {edited && (
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          {UI_LABELS.EDITED}
        </Typography>
      )}
    </Stack>
  );
};

export default CommentMeta;
