import { FeedPostDTO } from '@piefi-platform/types-lib';
import { useDao, useRoomMembership } from 'hooks';
import useLocalStorage from 'hooks/use-local-storage';
import { FeedContextType } from 'model';
import { SessionFilter } from 'model/contexts/feed-context-type.model';
import {
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Props } from 'types';
import { PostFilterType } from 'types/enum';

const initialContext: FeedContextType = {
  activeFilter: PostFilterType.Summary,
  setActiveFilter: (value: SetStateAction<PostFilterType>): void => {},
  bar: true,
  showBar: (visibility: boolean) => {},
  sessionFilters: [],
  setSessionFilters: (value: Array<SessionFilter>): void => {},
  posts: [],
  setPosts: (posts: Array<FeedPostDTO>): void => {},
  showCreatePostForm: false,
  setShowCreatePostForm: (value: boolean) => {}
};

const FeedContext = createContext(initialContext);

const FeedProvider = ({ children }: Props): ReactElement => {
  const { activeDaoMembership } = useDao();
  const { activeRoom } = useRoomMembership();
  const [activeFilter, setActiveFilter] = useState<PostFilterType>(initialContext.activeFilter);
  const [sessionFilters, setSessionFilters] = useLocalStorage<Array<SessionFilter>>(
    'sessionFilters',
    []
  );
  const [bar, setBar] = useState(initialContext.bar);
  const [posts, setPosts] = useState<Array<FeedPostDTO>>(initialContext.posts);
  const [showCreatePostForm, setShowCreatePostForm] = useState<boolean>(
    initialContext.showCreatePostForm
  );

  const showBar = useCallback((visibility: boolean) => {
    setBar(visibility);
  }, []);

  useEffect(() => {
    if (!activeDaoMembership && !activeRoom) {
      // meta feed
      setActiveFilter(PostFilterType.Summary);
    } else if (activeDaoMembership?.daoId === activeRoom?.daoId) {
      const filter: SessionFilter = sessionFilters.find(
        (sessionFilter: SessionFilter) =>
          sessionFilter.daoId === activeDaoMembership?.daoId &&
          sessionFilter.roomId === activeRoom?.roomId
      );

      if (filter) {
        setActiveFilter(filter.activeFilter); // assign session filter
      } else {
        setActiveFilter(PostFilterType.Summary); // set default filter
      }
    }
  }, [activeDaoMembership, activeRoom, sessionFilters]);

  const updatePosts = useCallback((posts: Array<FeedPostDTO>) => {
    setPosts(posts);
  }, []);

  const updateShowCreatePostForm = useCallback((value: boolean) => {
    setShowCreatePostForm(value);
  }, []);

  return (
    <FeedContext.Provider
      value={{
        activeFilter,
        bar,
        posts,
        sessionFilters,
        showCreatePostForm,
        setActiveFilter,
        setPosts: updatePosts,
        setSessionFilters,
        setShowCreatePostForm: updateShowCreatePostForm,
        showBar
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export default FeedProvider;
export { FeedContext };
