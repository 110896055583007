import { Stack, Typography, useTheme } from '@mui/material';
import { EntityVote, FeedPostDTO, VoteEntityDTO } from '@piefi-platform/types-lib';
import DownVoteIcon from 'assets/DownVoteIcon';
import UpVoteIcon from 'assets/UpVoteIcon';
import { UI_LABELS } from 'constants/ui-labels';
import { useFeed } from 'hooks';
import { usePostService } from 'hooks/services';
import React, { useCallback, useEffect } from 'react';
import { EntityVoteEnum } from 'types/enum';
import { abbreviateNumber } from 'utils';
import { SideBarIconButtonStyled } from './SideVote.styles';

const SideVote = (): React.ReactElement => {
  const theme = useTheme();
  const { post, setPost, setVotes, votes } = useFeed();
  const { createPostVote } = usePostService();
  const { DOWN_VOTE, UP_VOTE } = UI_LABELS;

  useEffect(() => {
    if (post) {
      setVotes(post.upvoteCount - post.downvoteCount);
    }
  }, [post, setVotes]);

  const castVote = useCallback(
    async (action: EntityVoteEnum) => {
      const room = post?.room;
      const dao = post?.room.dao;

      try {
        if (!post || !room || !dao) {
          return;
        }

        const { data: newCount } = await createPostVote(dao.id, room.id, post?.id, {
          action
        } as EntityVote);

        const localPost: FeedPostDTO = {
          ...post,
          upvoteCount: newCount.upvoteCount,
          downvoteCount: newCount.downvoteCount
        };

        const newVote: VoteEntityDTO = {
          id: post.id,
          action: action,
          authorId: '',
          postId: post?.id,
          commentId: ''
        };

        if (post.votes && post.votes?.length > 0) {
          if (post.votes[0].action === action) {
            newVote.action = EntityVoteEnum.None;
          }
          localPost.votes = [newVote];
        } else {
          localPost?.votes?.push(newVote);
        }

        setPost(() => localPost);
        setTimeout(() => {
          setVotes(newCount.upvoteCount - newCount.downvoteCount);
        });
      } catch (error) {
        console.error(error);
      }
    },
    [post, setVotes, createPostVote, setPost]
  );

  const fillVote = (action: EntityVoteEnum) =>
    (post?.votes?.[0]?.action as EntityVoteEnum) === action
      ? theme.palette.grey[900]
      : 'transparent';

  return (
    <Stack direction="column" spacing={0.5} alignItems="center">
      <Stack direction="column" spacing={0}>
        <SideBarIconButtonStyled
          aria-label={UP_VOTE}
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation();
            castVote(EntityVoteEnum.UpVote);
          }}
        >
          <UpVoteIcon fill={fillVote(EntityVoteEnum.UpVote)} />
        </SideBarIconButtonStyled>
        <SideBarIconButtonStyled
          aria-label={DOWN_VOTE}
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation();
            castVote(EntityVoteEnum.DownVote);
          }}
        >
          <DownVoteIcon fill={fillVote(EntityVoteEnum.DownVote)} />
        </SideBarIconButtonStyled>
      </Stack>
      <Typography variant="body1" fontWeight={theme.typography.fontWeightBold}>
        {abbreviateNumber(votes)}
      </Typography>
    </Stack>
  );
};

export default SideVote;
