import { Typography, styled } from '@mui/material';

export const CardTypographyStyled = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minHeight: '3rem'
}));
