import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function RemoveIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.666 7.33301H3.33268C3.15587 7.33301 2.9863 7.40325 2.86128 7.52827C2.73625 7.65329 2.66602 7.82286 2.66602 7.99967C2.66602 8.17649 2.73625 8.34605 2.86128 8.47108C2.9863 8.5961 3.15587 8.66634 3.33268 8.66634H12.666C12.8428 8.66634 13.0124 8.5961 13.1374 8.47108C13.2624 8.34605 13.3327 8.17649 13.3327 7.99967C13.3327 7.82286 13.2624 7.65329 13.1374 7.52827C13.0124 7.40325 12.8428 7.33301 12.666 7.33301Z"
        fill={props.fill || '#ECECEC'}
      />
    </SvgIcon>
  );
}
