import { Theme } from '@mui/material/styles';
import { FONT_PRIMARY } from '../typography';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraLarge: true;
    xxLarge: true;
  }
}

export default function Button(theme: Theme): object {
  return {
    MuiButton: {
      variants: [
        {
          props: { size: 'extraLarge' },
          style: { height: '3.25rem' }
        },
        {
          props: { size: 'xxLarge' },
          style: { height: '3.5rem' }
        }
      ],
      styleOverrides: {
        root: {
          border: `0.0625rem solid ${theme.palette.grey[0]}`,
          borderRadius: '0',
          boxShadow: 'none',
          fontFamily: FONT_PRIMARY,
          fontSize: '0.75rem',
          fontWeight: 800,
          padding: '0.3125rem 0.5rem',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            boxShadow: 'none',
            border: `0.0625rem solid ${theme.palette.grey[700]}`,
            color: theme.palette.common.white
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: `${theme.palette.common.white} !important`,
            border: `0.0625rem solid ${theme.palette.common.white}`
          }
        },
        sizeSmall: {
          padding: '.3125rem .5rem',
          fontSize: '.75rem',
          lineHeight: '1rem'
        },
        sizeMedium: {
          height: '2.25rem',
          padding: '.5rem 1rem',
          fontSize: '.75rem',
          lineHeight: '1rem'
        },
        sizeLarge: {
          padding: '.6875rem 1rem',
          fontSize: '.9063rem',
          lineHeight: '1.25rem'
        },
        sizeExtraLarge: {
          padding: '1rem',
          fontSize: '.906rem',
          lineHeight: '1.25rem'
        },
        sizexxLarge: {
          padding: '1rem',
          fontSize: '.906rem',
          lineHeight: '1.25rem'
        },
        // contained
        containedInherit: {
          backgroundColor: theme.palette.button.neutral.background,
          color: theme.palette.button.neutral.color,
          boxShadow: theme.customShadows.z8,
          '& path': {
            fill: theme.palette.button.neutral.color
          },
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          backgroundColor: theme.palette.button.primary.background,
          border: `0.063rem solid ${theme.palette.common.black}`,
          color: theme.palette.button.primary.color,
          '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`,
            color: theme.palette.common.white,
            border: `0.063rem solid ${theme.palette.button.primary.background}`
          }
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        containedInfo: {
          boxShadow: theme.customShadows.info
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning
        },
        containedError: {
          boxShadow: theme.customShadows.error
        },
        // outlined
        outlined: {
          border: `1px solid ${theme.palette.common.white}`
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            '& path': {
              fill: theme.palette.common.white
            }
          }
        },
        outlinedPrimary: {
          color: theme.palette.button.primary.background,
          border: `0.063rem solid ${theme.palette.button.primary.background}`,
          '&:hover': {
            backgroundColor: theme.palette.button.primary.background,
            color: theme.palette.button.primary.color,
            border: `0.063rem solid ${theme.palette.button.primary.background}`,
            '& path': {
              fill: theme.palette.button.primary.color
            }
          }
        },
        outlinedSecondary: {
          color: theme.palette.common.white
        },
        outlinedWarning: {
          '&:hover': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.common.white
          }
        },
        text: {
          color: theme.palette.common.black,
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
            borderColor: 'transparent',
            color: theme.palette.text.primary
          },
          '&.Mui-disabled': {
            backgroundColor: 'transparent',
            color: `${theme.palette.text.secondary} !important`,
            '& svg path': {
              fill: theme.palette.text.secondary
            }
          }
        },
        textError: {
          color: theme.palette.error.main,
          '&:hover': {
            color: theme.palette.error.main
          }
        }
      }
    }
  };
}
