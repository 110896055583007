import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
// material
import { styled, useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
//
import { LottieOptions, useLottie } from 'lottie-react';
import AnimatedLogo from 'assets/logomark_moving.json';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle(): React.ReactElement {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 2,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
            boxShadow: `0 0 2px ${theme.palette.primary.main}`
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
          }
        }
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

export default function LoadingScreen({
  width = '12.5rem',
  height = '12.5rem',
  speed,
  ...other
}: React.ComponentProps<
  any & { width?: string; height?: string; speed?: number }
>): React.ReactElement {
  const options: LottieOptions = {
    animationData: AnimatedLogo,
    loop: true,
    autoPlay: true,
    height,
    width
  };
  const { View, setSpeed } = useLottie(options, { height, width });

  useEffect(() => {
    setSpeed(speed);
  }, [setSpeed, speed]);

  return (
    <>
      <ProgressBar />
      <RootStyle {...other}>{View}</RootStyle>
    </>
  );
}
