import { Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { COLLECTIVE_LABELS } from 'constants/user-home.labels';
import { useUser } from 'hooks';
import React from 'react';
import CollectiveListItem from './collective-list-item/CollectiveListItem';
import NoCollectiveItem from './no-collective-item/NoCollectiveItem';

const UserCollectivesList = (): React.ReactElement => {
  const { daoMemberships } = useUser();
  const { MY_COLLECTIVES } = COLLECTIVE_LABELS;
  const theme = useTheme();

  return (
    <Stack direction={'column'} spacing={4}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        paddingBottom={'1.25rem'}
        borderBottom={`1px solid ${theme.palette.divider}`}
      >
        <Typography variant={'subtitle2'} fontWeight={'bold'}>
          {MY_COLLECTIVES}
        </Typography>
        {/* <ViewAllButton size={'medium'} fontStyles={{ fontWeight: 'bold' }} /> */}
      </Stack>
      <Grid container>
        {daoMemberships.length > 0 ? (
          daoMemberships.map((membership: DaoMembershipDTO, index) => (
            <Grid
              item
              md={6}
              xs={12}
              key={membership.id}
              sx={{
                ...(index % 2 === 1 ? { paddingLeft: '1.5rem !important' } : {}), // mui spacing adding padding to both columns. this adds only on odd number indexes so that space only in the middle of the two columns.
                [theme.breakpoints.down('md')]: {
                  paddingLeft: '0 !important'
                }
              }}
            >
              <CollectiveListItem membership={membership} />
              <Divider />
            </Grid>
          ))
        ) : (
          <NoCollectiveItem />
        )}
      </Grid>
    </Stack>
  );
};

export default UserCollectivesList;
