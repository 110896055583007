import { EntityVote, VoteDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import IUseCommentServiceProps from './use-comment-service.props';
import { commentURL } from './use-comment-service.url';

const useCommentService = (): IUseCommentServiceProps => {
  const { put, _delete } = useHttp();

  const deleteComment = useCallback(
    (daoId: string, roomId: string, postId: string, commentId: string): Promise<AxiosResponse> => {
      const url = `${commentURL(daoId, roomId, postId)}/${commentId}`;
      return _delete(url);
    },
    [_delete]
  );

  const createCommentVote = useCallback(
    (
      daoId: string,
      roomId: string,
      postId: string,
      commentId: string,
      entityVote: EntityVote
    ): Promise<AxiosResponse<VoteDTO>> => {
      const url = `${commentURL(daoId, roomId, postId)}/${commentId}/votes`;
      return put(url, { ...entityVote });
    },
    [put]
  );

  return { deleteComment, createCommentVote };
};

export default useCommentService;
