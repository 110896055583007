import { SelectChangeEvent, Stack } from '@mui/material';
import { CommentPostDTO } from '@piefi-platform/types-lib';
import { CTACard, CommentCard, TextEditor } from 'components';
import { MAX_LENGTH } from 'constants/app-config';
import { FEED_LABELS } from 'constants/feed.labels';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useFeed, useRoomMembership, useUser } from 'hooks';
import { usePostService } from 'hooks/services';
import { ReactNode, useState } from 'react';
import { SortOptions } from 'types/enum/sort-comments.enums';
import { htmlToMarkdown } from 'utils';
import CommentContent from '../comment-content/CommentContent';
import CommentListHeader from '../comment-list-header/CommentListHeader';
import CommentListProps from './CommentList.props';

const CommentsList = ({ comments, post, daoId, roomId }: CommentListProps): React.ReactElement => {
  const { createRootComment } = usePostService();
  const { activeRoom } = useRoomMembership();
  const { updatePostWithComments } = useFeed();
  const { userInfo } = useUser();
  const [sort, setSort] = useState<SortOptions>();
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [showEditor, setShowEditor] = useState(false);
  const { EMPTY_COMMENTS_TITLE, EMPTY_COMMENTS_DESCRIPTION } = FEED_LABELS;

  const {
    author: { id: authorId, role }
  } = post;

  const handleSortChange = (event: SelectChangeEvent<unknown>, child: ReactNode) =>
    setSort(event.target.value as SortOptions);

  const handleReply = async (content: string) => {
    try {
      if (daoId && roomId && post && userInfo) {
        const parsedContent = htmlToMarkdown(content);
        const newCommentRes = await createRootComment(daoId, roomId, post.id, parsedContent);

        if (newCommentRes.status === HTTP_STATUS.OK) {
          const commentCreated: CommentPostDTO = {
            author: {
              id: activeRoom?.id || authorId,
              role: activeRoom?.role || role,
              user: userInfo
            },
            upvoteCount: 0,
            downvoteCount: 0,
            deleted: false,
            contentUrls: [],
            id: newCommentRes.data.id,
            level: 0,
            content: newCommentRes.data.content,
            createdAt: newCommentRes.data.createdAt,
            updatedAt: '',
            parentId: newCommentRes.data.parentId,
            postId: newCommentRes.data.postId,
            _count: {
              children: 0
            },
            socialEntityAudits: []
          };
          setTextAreaValue('');
          setShowEditor(false);
          updatePostWithComments(commentCreated);
        }
      }
    } catch (error) {
      console.log(error); // TODO: Integrate logs
    }
  };

  const toggleEditor = () => setShowEditor(!showEditor);

  return (
    <Stack spacing={0} data-testid="comment-list">
      <CommentListHeader
        post={post}
        toggleEditor={toggleEditor}
        handleSortChange={handleSortChange}
        sort={sort}
      />
      {showEditor && (
        <TextEditor
          value={textAreaValue}
          onChange={(content: string) => setTextAreaValue(content)}
          handleCancelReply={() => {
            setShowEditor(false);
            setTextAreaValue('');
          }}
          name="comment-editor"
          handleReply={handleReply}
          maxLength={MAX_LENGTH.COMMENTS}
          toolBarId={`post-comment-rich-editor-toolbar-${post.id}`}
        />
      )}
      {comments?.length === 0 && (
        <CTACard
          description={EMPTY_COMMENTS_DESCRIPTION}
          title={EMPTY_COMMENTS_TITLE}
          style={{ margin: '4.5rem' }}
        />
      )}
      {comments?.map((comment, index) => (
        <CommentCard key={`comment-${comment.id}`} data-testid={`comment-${index}`}>
          <CommentContent
            id={`comment-${comment.id}`}
            level={1}
            mainPost={post}
            parentComment={comment}
            replyActionHandler={(value: any, callback: any): void => {}}
          />
        </CommentCard>
      ))}
    </Stack>
  );
};

export default CommentsList;
