import { Theme } from '@mui/material/styles';
import { SvgIcon, SvgIconProps } from '@mui/material';

// ----------------------------------------------------------------------

function Icon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <rect x="1" y="1" width="16" height="16" fill="#FDFDFD" stroke="#181818" strokeWidth="2" />
    </SvgIcon>
  );
}

function CheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <rect x="1" y="1" width="16" height="16" fill="#181818" stroke="#181818" strokeWidth="2" />
      <path
        d="M4 9.5L7 12.5L14.5 5"
        stroke="#FDFDFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

function IndeterminateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <rect x="1" y="1" width="16" height="16" fill="#181818" stroke="#181818" strokeWidth="2" />
      <path d="M4 9H14" stroke="#FDFDFD" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
}

export default function Checkbox(theme: Theme): object {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <Icon />,
        checkedIcon: <CheckedIcon />,
        indeterminateIcon: <IndeterminateIcon />
      },

      styleOverrides: {
        root: {
          padding: theme.spacing(1),
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.disabled
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: 24,
            height: 24
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 20,
            height: 20
          },
          svg: {
            color: theme.palette.common.black,
            fontSize: 24,
            '&[font-size=small]': {
              fontSize: 20
            }
          }
        }
      }
    }
  };
}
