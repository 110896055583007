import { TagDTO } from '@piefi-platform/types-lib';

type Listener<T = any> = (payload: T) => void;
type Unsubscribe = () => void;

interface MyEventPayloads {
  ON_TAG_DELETE?: { data: TagDTO };
  ON_TAG_UPDATE?: { data: TagDTO };
  BULK_TAG_ADDED?: { data: TagDTO; membersEffected: string[] };
  BULK_TAG_DETACHED?: { data: { id: string }; membersEffected: string[] };
}

type EventType = keyof MyEventPayloads;

type EmitEvent = <T extends keyof MyEventPayloads>(type: T, payload: MyEventPayloads[T]) => void;
type Subscribe = <T extends keyof MyEventPayloads>(
  type: T,
  id: number,
  callback: Listener<MyEventPayloads[T]>
) => Unsubscribe;

const listeners: Record<EventType, Record<number, Listener<any>>> = {
  ON_TAG_DELETE: {},
  ON_TAG_UPDATE: {},
  BULK_TAG_ADDED: {},
  BULK_TAG_DETACHED: {}
};

const useEventEmitter = (): { emitEvent: EmitEvent; subscribe: Subscribe } => {
  /**
   * @description Iterates over the listeners for the specific type and invokes the call back function
   * @param type {T}
   * @param payload
   */
  const emitEvent = <T extends EventType>(type: T, payload: MyEventPayloads[T]) => {
    if (listeners[type]) {
      Object.entries(listeners[type]).forEach(([_, callBack]) => callBack(payload));
    }
  };

  /**
   * @description Assigns a callback to a specific type for a specific id
   * @example
   * ```
   *  {
   *    ON_TAG_DELETE: {
   *      123: (payload) => console.log(payload),
   *      456: (payload) => // do something unique with this implementation
   *    }
   *  }
   *  ```
   * @param type
   * @param id
   * @param callback
   * @returns
   */
  const subscribe = <T extends EventType>(
    type: T,
    id: number,
    callback: Listener<MyEventPayloads[T]>
  ): Unsubscribe => {
    listeners[type][id] = callback;
    return () => {
      delete listeners[type][id];
    };
  };

  return { emitEvent, subscribe };
};

export default useEventEmitter;
