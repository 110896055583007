/**
 * @description Truncates strings by taking `truncateSize` at the front and back with the `delimiter` in the middle
 * @param text {string} text to truncate
 * @param truncateSize {number}
 * @param delimiter {string} delimiter added after text to '...'
 * @returns {string}
 */
const truncateText = (text: string, truncateSize = 20, delimiter = '...'): string => {
  if (text.length < truncateSize) return text;

  return `${text.substring(0, truncateSize)}${delimiter}`;
};

export default truncateText;
