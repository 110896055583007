import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PieFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M20.3501 7.39002C19.9701 6.70002 19.2501 5.84002 18.7501 5.34002C17.7701 4.36002 16.7901 3.84002 15.6601 3.25002L15.4901 3.16002C15.4101 3.12002 15.2301 3.02002 15.0101 3.01002C14.4801 2.97002 14.0401 3.33002 13.7601 4.07002C13.7001 4.24002 13.6301 4.40002 13.5601 4.57002C13.5001 4.56002 13.4501 4.53002 13.3901 4.52002C13.2001 4.48002 13.0001 4.44002 12.8001 4.42002C10.6201 4.12002 8.25012 4.76002 6.46012 6.12002C5.11012 7.14002 3.44013 8.82002 3.15013 11.03C2.66013 14.87 4.52013 18.6 7.68013 20.11C8.23013 20.37 8.82013 20.57 9.43013 20.68C10.0101 20.79 10.6501 20.85 11.3101 20.85C13.2801 20.85 15.4201 20.31 16.8101 18.96C18.0201 17.78 18.8901 16.34 19.3101 14.8C19.6501 13.54 19.6601 12.28 19.3701 11.04C19.9501 10.82 20.5301 10.56 20.9901 10.21C21.4201 9.88002 21.5701 9.63002 20.3401 7.40002L20.3501 7.39002ZM18.1101 9.90002L17.6601 10.06C16.6501 10.43 15.6201 10.76 14.6101 11.06C13.9401 11.26 13.2701 11.44 12.5301 11.62C12.8101 10.79 13.1201 9.89002 13.5201 8.82002C13.8001 8.07002 14.1001 7.32002 14.3901 6.57002C14.6401 5.94002 14.8901 5.31002 15.1301 4.68002C16.1101 5.20002 16.9101 5.64002 17.6801 6.41002C18.1101 6.84002 18.7301 7.59002 19.0301 8.12002C19.1001 8.25002 19.4001 8.86002 19.6101 9.30002C19.1601 9.53002 18.6101 9.72002 18.1001 9.90002H18.1101Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
