export const BOUNTY_FORM = {
  NEW_BOUNTY: 'New Bounty',
  BOUNTY_TITLE: 'Bounty Title',
  BOUNTY_DETAILS: 'BOUNTY DETAILS',
  MAXIMUM_RECIPIENTS: 'Maximum Recipients',
  BOUNTY_REWARDS: 'BOUNTY REWARDS',
  REWARD_TYPE: 'Reward Type',
  REWARD_AMOUNT: 'Reward Amount',
  CHOOSE_ONE: 'choose one',
  ADD_ANOTHER: 'Add Another',
  ADDITIONAL_INFORMATION: 'ADDITIONAL INFORMATION',
  WHAT_ELSE_SHOULD_MEMBERS_KNOW_ABOUT_THIS: 'What else should members know about this?'
};

export const REPORT_POST = {
  TITLE: 'Report',
  HEADS_UP:
    'Please respond as accurately as possible. This will help us and your Community Managers make this a safe space for everyone.',
  SELECT_REASON: 'SELECT A REASON',
  SEND_REPORT: 'Send Report',
  CHOOSE_ONE_REASON: 'Choose one reason'
};

export const PROPOSAL_FORM = {
  PROPOSAL_SETTINGS: 'POLL SETTINGS',
  CLOSE_DATE: 'Close Date',
  CLOSE_TIME: 'Close Time'
};
