import { IconButton, useTheme } from '@mui/material';
import CloseIcon from 'assets/CloseIcon';
import IconSearch from 'assets/SearchIcon';
import { UI_LABELS } from 'constants/ui-labels';
import React, { ReactElement } from 'react';
import SquareIconButton from '../../square-icon-button/SquareIconButton';
import SearchAutoComplete from '../search-auto-complete/SearchAutoComplete';
import { SearchAutoCompleteProps } from '../search-auto-complete/SearchAutoComplete.props';
import {
  AutoCompleteContainerStyled,
  PopoverStyled,
  SearchContainer
} from './MobileSearchContainer.style';

function MobileSearchContainer<T>(props: SearchAutoCompleteProps<T>): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { SEARCH } = UI_LABELS;
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? 'search-mobile-popover' : undefined;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleCloseAndClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (props.searchPhrase === '') {
      setAnchorEl(null);
    }
    props.handleInputChange && props.handleInputChange('');
    props.handleOnChange && props.handleOnChange(null);
  };

  const handleOnChange = (value: string | T | null): void => {
    props.handleOnChange && props.handleOnChange(value);
    props.handleInputChange && props.handleInputChange('');
    setAnchorEl(null);
  };

  return (
    <SearchContainer>
      <SquareIconButton aria-label={SEARCH} onClick={handleClick} aria-describedby={id}>
        <IconSearch viewBox="0 0 16 16" fontSize="small" />
      </SquareIconButton>
      <PopoverStyled
        anchorReference="anchorPosition"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{ top: 0, left: 0 }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          elevation: 0,
          style: { backgroundColor: theme.palette.background.default }
        }}
        BackdropProps={{
          invisible: true,
          style: { backgroundColor: theme.palette.background.default }
        }}
      >
        <AutoCompleteContainerStyled>
          {
            <SearchAutoComplete<T>
              {...props}
              handleOnChange={handleOnChange}
              endAdornment={
                <IconButton aria-label="close" onClick={(e) => handleCloseAndClear(e)}>
                  <CloseIcon />
                </IconButton>
              }
              disableClearable
            />
          }
        </AutoCompleteContainerStyled>
      </PopoverStyled>
    </SearchContainer>
  );
}

export default MobileSearchContainer;
