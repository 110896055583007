import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ResendIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M19 9C18.7348 9 18.4804 9.10536 18.2929 9.29289C18.1054 9.48043 18 9.73478 18 10C18.0119 11.873 17.3703 13.6916 16.1857 15.1425C15.001 16.5933 13.3475 17.5857 11.51 17.9486C9.67239 18.3115 7.76578 18.0223 6.11851 17.1308C4.47123 16.2392 3.18641 14.8012 2.48533 13.0643C1.78425 11.3273 1.71079 9.40032 2.27762 7.61508C2.84445 5.82983 4.01607 4.29813 5.5907 3.28378C7.16533 2.26942 9.0444 1.83591 10.9043 2.05791C12.7641 2.27991 14.4884 3.14352 15.78 4.5H13.38C13.1148 4.5 12.8604 4.60536 12.6729 4.79289C12.4854 4.98043 12.38 5.23478 12.38 5.5C12.38 5.76522 12.4854 6.01957 12.6729 6.20711C12.8604 6.39464 13.1148 6.5 13.38 6.5H17.91C18.1752 6.5 18.4296 6.39464 18.6171 6.20711C18.8046 6.01957 18.91 5.76522 18.91 5.5V1C18.91 0.734783 18.8046 0.480429 18.6171 0.292893C18.4296 0.105356 18.1752 -2.97674e-07 17.91 -2.97674e-07C17.6448 -2.97674e-07 17.3904 0.105356 17.2029 0.292893C17.0154 0.480429 16.91 0.734783 16.91 1V2.77C15.2447 1.17806 13.0808 0.209952 10.784 0.0293288C8.48729 -0.151295 6.19863 0.466644 4.30492 1.77869C2.41121 3.09074 1.02861 5.01641 0.390831 7.23019C-0.246949 9.44397 -0.100787 11.8101 0.804611 13.9285C1.71001 16.047 3.31911 17.7878 5.3599 18.8568C7.40069 19.9258 9.748 20.2574 12.005 19.7955C14.2621 19.3336 16.2904 18.1065 17.7471 16.3217C19.2039 14.5369 19.9997 12.3038 20 10C20 9.73478 19.8946 9.48043 19.7071 9.29289C19.5196 9.10536 19.2652 9 19 9Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
