import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import DaoExternalReferenceService from './use-dao-external-reference-service.model';
import { daoExternalReferenceUrl } from './use-dao-external-reference-service.url';
import { AxiosResponse } from 'axios';
import { ExternalReferenceDTO, ExternalReferenceUpdatePayload } from '@piefi-platform/types-lib';

const useDaoExternalReferenceService = (): DaoExternalReferenceService => {
  const { post, put } = useHttp();

  const updateExternalReference = useCallback(
    async (
      daoId: string,
      externalReferenceId: string,
      payload: ExternalReferenceUpdatePayload
    ): Promise<AxiosResponse<ExternalReferenceDTO>> => {
      return put(`${daoExternalReferenceUrl(daoId)}/${externalReferenceId}`, payload);
    },
    [put]
  );

  const createExternalReference = useCallback(
    (
      daoId: string,
      payload: ExternalReferenceUpdatePayload
    ): Promise<AxiosResponse<ExternalReferenceDTO>> => {
      return post(`${daoExternalReferenceUrl(daoId)}`, payload);
    },
    [post]
  );

  return {
    updateExternalReference,
    createExternalReference
  };
};

export default useDaoExternalReferenceService;
