import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme): object {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 900 : 700],
          borderRadius: 0,
          padding: '1rem',
          lineHeight: '1rem'
        },
        arrow: {
          color: theme.palette.grey[isLight ? 800 : 700]
        }
      }
    }
  };
}
