import { Room } from '@piefi-platform/types-lib';
import { RoomForm } from 'components';
import BaseModal from 'components/modals';
import { HTTP_STATUS } from 'constants/http-statuses';
import { ROOM_FORM } from 'constants/room.labels';
import { useDao, useNotification, useRoomMembership } from 'hooks';
import { useRoomService } from 'hooks/services';
import React from 'react';
import { Severity } from 'types/enum';
import AddRoomModalProps from './AddRoomModalProps';

const AddRoomModal = ({ open, setOpen }: AddRoomModalProps): React.ReactElement => {
  const { currentDao } = useDao();
  const closeModal = (): void => setOpen(false);
  const { createRoom } = useRoomService();
  const { notify } = useNotification();
  const { loadUserRooms } = useRoomMembership();
  const { TITLE, ROOM_CREATED_SUCCESSFULLY, ROOM_CREATED_FAILURE } = ROOM_FORM;

  const onSubmit = async (room: Room) => {
    if (!currentDao?.id) return;
    try {
      const response = await createRoom(currentDao.id, {
        ...room,
        description: room.description || null
      });

      if (response?.status === HTTP_STATUS.OK) {
        notify(ROOM_CREATED_SUCCESSFULLY);
        loadUserRooms(currentDao.id); // TODO: Fix this when rooms pagination is implemented
      } else {
        notify(ROOM_CREATED_FAILURE, { severity: Severity.Error });
      }
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BaseModal title={TITLE()} toggle={open} close={closeModal}>
      <RoomForm close={closeModal} submit={onSubmit} />
    </BaseModal>
  );
};

export default AddRoomModal;
