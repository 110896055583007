import { Stack, Typography } from '@mui/material';
import { AvatarBuilder } from 'components';
import { AvatarSize } from 'types/enum';
import { DefaultTransactionListItemProps } from './DefaultTransactionListItem.props';

const DefaultTransactionListItem = ({
  label
}: DefaultTransactionListItemProps): React.ReactElement => {
  return (
    <Stack alignItems={'center'} data-testid="empty-list">
      <Stack
        direction={'column'}
        sx={{
          maxWidth: '15rem',
          minHeight: '17rem',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <AvatarBuilder id={'test'} size={AvatarSize.LARGE} src={''} isSquare={true} />
        <Typography variant={'body2'} textAlign={'center'} pt={'1rem'}>
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default DefaultTransactionListItem;
