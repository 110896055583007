import { Theme } from '@mui/material/styles';
import { ColorSchema } from 'types/theme';

// ----------------------------------------------------------------------

declare module '@mui/material/Alert' {
  export interface AlertPropsColorOverrides {
    primary: true;
  }
}

export default function Alert(theme: Theme): object {
  const isLight = theme.palette.mode === 'light';

  const standardStyle = (color: ColorSchema) => ({
    color: theme.palette[color][isLight ? 'darker' : 'lighter'],
    backgroundColor: theme.palette[color][isLight ? 'lighter' : 'darker'],
    '& .MuiAlert-icon': {
      color: theme.palette[color][isLight ? 'main' : 'light']
    }
  });

  const filledStyle = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText
  });

  const outlinedStyle = (color: ColorSchema) => ({
    color: theme.palette[color][isLight ? 'darker' : 'lighter'],
    border: `solid 1px ${theme.palette[color][isLight ? 'light' : 'dark']}`,
    backgroundColor: theme.palette[color][isLight ? 'lighter' : 'darker'],
    '& .MuiAlert-icon': {
      color: theme.palette[color][isLight ? 'main' : 'light']
    }
  });

  return {
    MuiAlert: {
      variants: [
        {
          props: { color: 'primary' }
        }
      ],
      defaultProps: {
        iconMapping: {
          error: <></>,
          info: <></>,
          success: <></>,
          warning: <></>
        }
      },
      styleOverrides: {
        message: {
          '& .MuiAlertTitle-root': {
            marginBottom: theme.spacing(0.5)
          }
        },
        action: {
          '& button:not(:first-of-type)': {
            marginLeft: theme.spacing(1)
          }
        },
        standardInfo: standardStyle('info'),
        standardSuccess: {
          alignItems: 'center',
          backgroundColor: theme.palette.common.black,
          borderRadius: 0,
          color: theme.palette.common.white,
          height: '3.5rem',
          maxWidth: '100%',
          width: '44.625rem',
          '& .MuiAlert-action': {
            color: theme.palette.grey[500]
          },
          '& .MuiAlert-icon': {
            color: `${theme.palette.common.white} !important`
          }
        },
        standardWarning: standardStyle('warning'),
        standardError: standardStyle('error'),

        filledInfo: filledStyle('info'),
        filledSuccess: filledStyle('success'),
        filledWarning: filledStyle('warning'),
        filledError: filledStyle('error'),

        outlinedInfo: outlinedStyle('info'),
        outlinedSuccess: outlinedStyle('success'),
        outlinedWarning: outlinedStyle('warning'),
        outlinedError: outlinedStyle('error')
      }
    }
  };
}
