import { ChartWrapperProps } from './ChartWrapper.props';

const ChartWrapper = ({ children, width, height }: ChartWrapperProps): React.ReactElement => {
  return (
    <div style={{ width: width ?? '100%', height: height ?? '15rem', marginBottom: '3.5rem' }}>
      {children}
    </div>
  );
};

export default ChartWrapper;
