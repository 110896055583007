import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function Pin02(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 22 22" {...props}>
      <path
        d="M7.3767 14.6163L1.71985 20.2732M10.6944 5.64181L9.1335 7.2027C9.00618 7.33003 8.94252 7.39369 8.86999 7.44427C8.80561 7.48917 8.73616 7.52634 8.66309 7.555C8.58077 7.58729 8.49249 7.60495 8.31592 7.64026L4.65145 8.37315C3.69915 8.56361 3.223 8.65884 3.00024 8.9099C2.80617 9.12861 2.71755 9.4213 2.75771 9.71092C2.8038 10.0434 3.14715 10.3867 3.83387 11.0735L10.9196 18.1592C11.6063 18.8459 11.9497 19.1893 12.2821 19.2354C12.5718 19.2755 12.8645 19.1869 13.0832 18.9928C13.3342 18.7701 13.4294 18.2939 13.6199 17.3416L14.3528 13.6771C14.3881 13.5006 14.4058 13.4123 14.4381 13.33C14.4667 13.2569 14.5039 13.1875 14.5488 13.1231C14.5994 13.0505 14.663 12.9869 14.7904 12.8596L16.3512 11.2987C16.4326 11.2173 16.4734 11.1766 16.5181 11.141C16.5578 11.1095 16.5999 11.081 16.644 11.0558C16.6936 11.0274 16.7465 11.0048 16.8523 10.9594L19.3467 9.8904C20.0744 9.57853 20.4383 9.42259 20.6035 9.17061C20.7481 8.95025 20.7998 8.68175 20.7474 8.42348C20.6875 8.12813 20.4076 7.84822 19.8478 7.28839L14.7047 2.14526C14.1448 1.58543 13.8649 1.30552 13.5696 1.24565C13.3113 1.19329 13.0428 1.245 12.8225 1.38953C12.5705 1.55481 12.4145 1.91866 12.1027 2.64636L11.0337 5.14071C10.9883 5.24653 10.9656 5.29944 10.9373 5.34905C10.9121 5.39313 10.8836 5.43522 10.852 5.47496C10.8165 5.51971 10.7758 5.56041 10.6944 5.64181Z"
        stroke={props.stroke || '#181818'}
        strokeWidth={props.strokeWidth || '2'}
        fill={props.fill || 'none'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
