import {
  PatronageActivityAggregateResultsDTO,
  PatronageActivityDTO
} from '@piefi-platform/types-lib';
import { SingleLineProps } from 'components';
import { TimeSeriesDropdownOption } from '../drop-down-option.model';
import DataType from 'types/enum/chart-data-types.enum';

export interface PatronageActivityStatistic {
  activity: PatronageActivityDTO;
  lineProps: SingleLineProps;
  totalMetric: number;
}

export interface SeasonsPatronageStatisticsContextType {
  selectedTimeSeriesDropdownOption: TimeSeriesDropdownOption;
  onTimeSeriesDropdownOptionUpdated: (newOption: TimeSeriesDropdownOption) => void;

  patronageActivities: PatronageActivityStatistic[];
  areaDataForInterval: PatronageActivityAggregateResultsDTO;

  onTimeSeriesDataTypeUpdated?: (event: any, typeSelected: string) => void;
  dataType?: string;
}

export const initPatronageContextStatisticsType = (): SeasonsPatronageStatisticsContextType => ({
  patronageActivities: [],
  areaDataForInterval: {} as PatronageActivityAggregateResultsDTO,
  onTimeSeriesDropdownOptionUpdated: (option: TimeSeriesDropdownOption) => {},
  selectedTimeSeriesDropdownOption: {} as TimeSeriesDropdownOption,
  onTimeSeriesDataTypeUpdated: (event: any, typeSelected: string) => {},
  dataType: DataType.POINT_COUNT
});
