import { Stack, Typography } from '@mui/material';
import { Room } from '@piefi-platform/types-lib';
import { ModalLayer, RoomForm } from 'components';
import { HTTP_STATUS } from 'constants/http-statuses';
import { ROOM_FORM } from 'constants/room.labels';
import { useDao, useNotification, useRoomMembership } from 'hooks';
import { useRoomService } from 'hooks/services';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Severity } from 'types/enum';
import EditRoomModalProps from './EditRoomModal.props';

const EditRoomModal = ({ room, afterClose }: EditRoomModalProps): React.ReactElement => {
  const [open, setOpen] = useState(true);
  const { updateRoom } = useRoomService();
  const { currentDao } = useDao();
  const { roomId } = useParams();
  const { notify } = useNotification();
  const { loadUserRooms } = useRoomMembership();
  const { TITLE, ROOM_UPDATED_SUCCESSFULLY, ROOM_UPDATE_FAILURE } = ROOM_FORM;

  const closeModal = () => {
    setOpen(false);
    afterClose && afterClose();
  };

  const submit = async (room: Room) => {
    if (!currentDao || !roomId) return;
    try {
      const response = await updateRoom(currentDao.id, roomId, {
        name: room.name,
        description: room.description
      });

      if (response?.status === HTTP_STATUS.OK) {
        notify(ROOM_UPDATED_SUCCESSFULLY, { severity: Severity.Success });
        loadUserRooms(currentDao.id); // TODO: Fix this when rooms pagination is implemented
      } else {
        notify(ROOM_UPDATE_FAILURE, { severity: Severity.Error });
      }
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalLayer toggle={open} afterClose={closeModal}>
      <Stack spacing={3} marginTop="1.5rem">
        <Typography variant="h6">{TITLE(room)}</Typography>
        <RoomForm room={room} close={closeModal} submit={submit} />
      </Stack>
    </ModalLayer>
  );
};

export default EditRoomModal;
