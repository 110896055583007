import { Chip, styled } from '@mui/material';
import { getContrastColor } from 'utils';

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor'
})<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
  backgroundColor: `${backgroundColor}!important`,
  color: getContrastColor(backgroundColor),
  fontWeight: 800,
  fontSize: '.75rem',
  height: '1.5rem',
  margin: theme.spacing(0.5),
  minWidth: '3rem',
  '.MuiAutocomplete-listbox .MuiAutocomplete-option .Mui-focused': {
    backgroundColor: 'red !important'
  },
  '&:hover, .Mui-focused': {
    backgroundColor: `${backgroundColor}!important`
  },
  '& .MuiChip-icon, .MuiIconButton-root': {
    cursor: 'pointer',
    marginRight: '1.5rem',
    order: 1,
    paddingLeft: '.5rem',
    position: 'absolute',
    right: 0,
    width: '1.5rem',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  '& .MuiChip-deleteIcon': {
    marginRight: '.5rem',
    order: 2,
    paddingLeft: '.25rem',
    position: 'absolute',
    right: 0
  },
  '& svg': {
    display: 'none',
    fill: `${theme.palette.grey[0]} !important`,
    opacity: 1,
    backgroundColor: `${backgroundColor}!important`,
    '& path': {
      fill: `${theme.palette.grey[0]} !important`,
      opacity: 0.5
    }
  },
  '&:hover svg': {
    display: 'block'
  }
}));

export default StyledChip;
