import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactElement } from 'react';

function PostsFilledIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <rect x="1" y="1" width="15" height="18" fill="#3DD0FF" />
      <path
        d="M13.6028 16.0876C13.5775 16.0876 13.5521 16.086 13.5263 16.0828C12.2735 15.9216 8.71081 15.617 4.19453 15.617C3.86678 15.617 3.60156 15.3518 3.60156 15.0241C3.60156 14.6963 3.86678 14.4311 4.19453 14.4311C8.77064 14.4311 12.3985 14.7421 13.6772 14.906C14.0023 14.9475 14.2314 15.2451 14.1898 15.5696C14.1516 15.8688 13.8966 16.0876 13.6028 16.0876ZM13.6028 12.325C13.5775 12.325 13.5521 12.3234 13.5263 12.3201C12.2735 12.159 8.71081 11.8544 4.19453 11.8544C3.86678 11.8544 3.60156 11.5892 3.60156 11.2614C3.60156 10.9337 3.86678 10.6684 4.19453 10.6684C8.77064 10.6684 12.3985 10.9795 13.6772 11.1434C14.0023 11.1849 14.2314 11.4824 14.1898 11.8069C14.1516 12.1061 13.8966 12.325 13.6028 12.325ZM13.6028 9.03238C13.5678 9.03238 13.5327 9.02915 13.4972 9.02268C11.7204 8.70302 7.61543 8.19414 4.79343 8.55693C4.46838 8.59844 4.17135 8.36934 4.12984 8.04428C4.08834 7.71923 4.31744 7.4222 4.64249 7.38069C7.60788 6.99958 11.8692 7.52462 13.7079 7.85561C14.0303 7.91383 14.2443 8.22217 14.1866 8.54399C14.1349 8.83077 13.8853 9.03185 13.6039 9.03185L13.6028 9.03238Z"
        fill="#181818"
      />
      <path
        d="M13.8278 5.89004H4.14082C3.81307 5.89004 3.54785 5.62482 3.54785 5.29707C3.54785 4.96932 3.81307 4.7041 4.14082 4.7041H13.8278C14.1555 4.7041 14.4208 4.96932 14.4208 5.29707C14.4208 5.62482 14.1555 5.89004 13.8278 5.89004Z"
        fill="#181818"
      />
      <path
        d="M13.6636 20C13.0695 20 12.3725 19.9795 11.5494 19.9553C10.3273 19.9191 8.80608 19.8739 6.92906 19.8739C6.23421 19.8739 5.62399 19.8927 5.086 19.9089C3.36423 19.9617 2.32438 19.9935 1.6085 19.2906C0.67215 18.372 0.693173 16.5915 0.748158 11.8332C0.76433 10.4489 0.782119 8.8802 0.782119 6.99833C0.782119 6.17518 0.765947 5.46038 0.751392 4.82968C0.703416 2.72194 0.677002 1.56079 1.41067 0.816887C2.23921 -0.0229741 3.65641 -0.00949756 7.08 0.0217681C8.57051 0.0352447 10.4254 0.0524947 12.758 0.00343996H12.7655C14.6867 -0.0364507 15.9546 0.270276 16.6397 0.940332C17.2564 1.54354 17.257 2.26858 17.2575 2.70145C17.2575 2.74296 17.2575 2.78231 17.2581 2.81951V2.83029V14.0504C17.2581 14.5436 17.2629 15.0002 17.2672 15.4029C17.2888 17.4734 17.3006 18.6141 16.5858 19.3154C16.0258 19.8647 15.18 19.9989 13.6641 19.9989L13.6636 20ZM6.92906 18.6879C8.82333 18.6879 10.3543 18.7332 11.5844 18.7699C13.9239 18.8394 15.3363 18.8809 15.7551 18.4701C16.1093 18.1224 16.0991 17.1042 16.0813 15.4163C16.077 15.011 16.0721 14.5511 16.0721 14.0514V2.83622C16.0716 2.79417 16.0716 2.74997 16.071 2.70361C16.071 2.3047 16.0522 2.02601 15.8096 1.78882C15.5072 1.49287 14.7363 1.14841 12.7827 1.18938H12.7757C10.4276 1.23897 8.56512 1.22172 7.06814 1.20771C4.27094 1.18183 2.72975 1.16782 2.2543 1.64974C1.87372 2.03571 1.89744 3.07718 1.93625 4.80218C1.95081 5.43936 1.96698 6.1617 1.96698 6.99779C1.96698 8.88613 1.94865 10.4586 1.93302 11.8461C1.88666 15.8632 1.86348 17.8793 2.43812 18.4437C2.79336 18.792 3.69037 18.7645 5.04881 18.723C5.59542 18.7062 6.21534 18.6874 6.92852 18.6874L6.92906 18.6879Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}

export default PostsFilledIcon;
