export const DAO_ACTIONS_LABELS = {
  ACTIONS_SUBTITLE:
    'Discover new ways to get involved with the collective and earn ownership. More bits means more ownership.',
  EARN_POINTS: 'Earn Bits',
  LEADERBOARD: 'Leaderboard',
  LEARN_MORE: 'Learn More',
  GET_LINK: 'Get My Redirect Link',
  INTRO_ONE:
    "This is your Collective.  It's currently seeded with demo data to help you see the possibilities.",
  INTRO_TWO: 'Feel free to explore, look around, start creating content, etc.',
  SEASON_ZERO:
    'Well aren’t you here early! Think of season 0 like the warmup before the game. The lap before the race. The tuning before the concert. The aperitif before the meal. As a collective member, bits may be earned, but they will not translate into Ownership and everything will be reset once Season 1 officially begins. So sit tight and have fun!',
  SEASON_ZERO_MODAL_HEADER: 'Exploring your collective:',
  SEASON_ZERO_MODAL_BUTTON: 'Got it, thanks!',
  SEASON_ZERO_MODAL_TEXT1:
    'Currently this collective is in what we call a demo state.  Here you can set up, test, and generally get to know your collective before you start using real equity.  ',
  SEASON_ZERO_MODAL_TEXT2:
    'In this state you can create and test Activities, invite your admins, tune permissions, write some copy, and generally prep your Awsm collective before you open the doors to your members.',
  SEASON_ZERO_MODAL_TEXT3: "Once you're ready to officially start, reach out to Awsm: ",
  SEASON_ZERO_MODAL_SUPPORT: 'support@awsm.com',
  MODAL_LIST_TITLE: 'Things to know:',
  MODAL_LIST_ITEM1: `Equity awarded is not real`,
  MODAL_LIST_ITEM2: 'All content you create will persist, including members invited',
  MODAL_LIST_ITEM3: 'Demo data is not real and can be removed at any time at your request',
  REGULAR_SEASON_ENDS_IN(daysValue: number, hoursValue: number): string {
    return `ends in ${daysValue} days and ${hoursValue} hours`;
  },
  WELCOME: 'Welcome to Awsm!',
  ALLOCATION_AGREEMENT: 'Allocation_Agreement'
};
