export const AUTH = {
  WELCOME_TO_THE_UPSIDEDAO: 'Welcome to Awsm'
};

export const USER_MANAGEMENT = {
  MISSING_PROPERTIES: 'Missing required properties to complete the action.',
  REMOVE_SECOND_FACTOR: {
    HEADER: 'Removing Second Factor',
    SUCCESS: 'Second Factor Removed',
    SUBHEADER: `Your second factor has been removed successfully. Click button below to continue!`,
    ERROR_SUBTEXT: 'Error removing second factor',
    CONTINUE: 'Continue',
    LOGOUT: 'Return to Login',
    ERROR: {
      INVALID_ACTION_CODE: 'Your request to revoke has expired or the link has already been used',
      USER_DISABLED: 'User disabled. If this is a mistake reach out to support@awsm.com',
      USER_NOT_FOUND: 'User not found.',
      DEFAULT: 'Error verifying email'
    }
  },
  VERIFY: {
    VERIFICATION_EMAIL: 'Send Verification Email',
    EMAIL_VERIFIED: 'Email Verified!',
    EMAIL_VERIFIED_SUBTEXT:
      'Your email has been verified successfully. Click button below to continue!',
    ERROR_VERIFYING_EMAIL: 'Error verifying email',
    RESEND_VERIFICATION: 'Resend Verification',
    CONTINUE: 'Continue',
    LOGOUT: 'Return to Login',
    ERROR: {
      INVALID_ACTION_CODE:
        'Your request to verify your email has expired or the link has already been used',
      USER_DISABLED: 'User disabled. If this is a mistake reach out to support@awsm.com',
      USER_NOT_FOUND: 'User not found.',
      DEFAULT: 'Error verifying email'
    }
  },
  RESET: {
    RESET_PASSWORD: 'Reset Password',
    VERIFY_EMAIL: 'Verify Email',
    RESET_SUCCESSFUL: 'Password reset successful',
    RESET_SUCCESSFUL_SUBTEXT:
      'Your password has been reset successfully. Please login with your new password.',
    GO_TO_LOGIN: 'Go To Login',
    ERROR: {
      INVALID_ACTION_CODE: 'Invalid action code',
      USER_DISABLED: 'User disabled. If this is a mistake reach out to support@awsm.com',
      USER_NOT_FOUND: 'User not found.',
      DEFAULT: 'Error resetting password'
    }
  }
};
export const AUTH_VERIFICATION = {
  EMAIL: {
    HEADER: 'Thanks! Check Your Email',
    SUBHEADER: `Check your email for a verification link, you'll be navigated back here when finished`,
    ACTION: 'Resend email',
    SUB_ACTION: 'Logout',
    TOO_MANY_REQUESTS: 'Too many attempts, please try again in 15 minutes.'
  },
  RETURN_TO_LOGIN: 'Return to login',
  BACK: 'Back',
  PHONE: {
    HEADER: 'Enter your phone number',
    RETURN: 'Return to login',
    SUBHEADER:
      'We will use this to secure your account with a 6-digit verification code sent to your device. It expires 15 minutes after you request it.',
    INPUT_LABEL: 'Phone',
    INPUT_PLACEHOLDER: '801-555-5555',
    ACTION: 'Send Code',
    SUB_ACTION: 'Logout',
    SUBMIT: 'Submit',
    LOGIN_AGAIN: 'Please login again',
    VERIFY: {
      HEADER: 'Verify Your Account',
      SUBHEADER: `A text message has been sent to `,
      ACTION_TEXT: `Didn't receive it?`,
      ACTION: `Resend Code`,
      MESSAGE_SENT: 'Message has been resent',
      ERROR: {
        VALID_PHONE: 'Please enter a valid phone number',
        INVALID_CODE: 'The code you entered is incorrect. Please check your code and try again.',
        CODE_EXPIRED: 'Your code has expired. Please try again.',
        UNKNOWN: 'Unknown error occurred during verification. Refresh and try again.'
      }
    },
    SESSION_EXPIRED: 'Session Expired'
  }
};

export const AUTH_ERROR = {
  WRONG_PASSWORD: 'Wrong password',
  USER_NOT_FOUND: 'User not found',
  INVALID_EMAIL: 'Invalid email',
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL: 'The account already exists for that email',
  EMAIL_ALREADY_IN_USE: 'An account with this email address already exists',
  UNKNOWN_FIREBASE_ERROR: 'Unknown firebase error occurred',
  EXISTING_ACCOUNT_REGISTRATION:
    "The account you tried to register already exists, we're redirecting to home page!",
  UNKNOWN_REGISTRATION_ERROR: 'An Unknown error occurred in the registration process',
  REGISTRATION_ERROR: (message: string): string => `Error creating user: ${message}`
};

export const LOGIN = {
  CREATE_ACCOUNT: 'Create an account now',
  SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
  SIGN_IN_WITH_GITHUB: 'Sign in with Github',
  SIGN_IN: 'Sign In',
  SIGN_IN_WITH_EMAIL_TITLE: 'Sign in with email',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_MY_PASSWORD: 'forgot password',
  DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
  SIGN_UP_NOW_FOR_FREE: 'Sign up now for free.',
  LOGOUT: 'Sign out of account',
  SUBTITLE: 'Please sign in with your Awsm account information to continue',
  ERRORS: {
    NOT_FOUND: 'User not found. Please check your email address and try again.',
    INCORRECT_PASSWORD: 'Incorrect password. Please try again.',
    INVALID_EMAIL: 'Email Invalid',
    TOO_MANY_REQUESTS: 'Account has been locked try again later.',
    UNKNOWN_ERROR: 'An error occurred during login. Please try again.',
    INCORRECT_AUTH: 'Email and password do not match.'
  }
};

export const FORGOT_PASSWORD = {
  LEGEND: 'Forgot Password',
  SEND_EMAIL: 'Send Email',
  LEGEND_EMAIL_SENT: 'Email Sent',
  PLEASE_CHECK_YOUR_EMAIL:
    'Please check your email for instructions on how to reset your password. This could take up to a few minutes. Be patient.',
  BACK_TO_SIGN_IN: 'Back to Sign In'
};

export const CREATE_ACCOUNT = {
  LEGEND: 'Create Account',
  EMAIL_PLACEHOLDER: 'ex. johnny_pieface@gmail.com',
  MAKE_IT_GOOD: 'make it good',
  SIGN_UP: 'Sign Up',
  SIGN_UP_WITH_EMAIL: 'Sign up with email',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  SIGN_IN_OVER_HERE: 'Sign in over here.',
  OR: 'or',
  USER_CREATED_SUCCESSFULLY: 'User created successfully!',
  GET_STARTED: 'Get Started'
};

export const COMPLETE_PROFILE = {
  ACCOUNT: 'Account',
  LEGEND: 'Complete Profile',
  SELECT_PROFILE_PICTURE: 'Select profile picture',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  PROFILE_BIO: 'Profile Bio',
  WHO_ART_THOU: 'Who art thou?',
  ENTER_THE_UPSIDEDAO: 'Get Started',
  CHANGE_AVATAR: 'Change avatar',
  ERROR_USER_CREATION: 'Error creating user',
  BIO_IS_TOO_LARGE: 'The bio is too large',
  FILL_OUT_MISSING_INFO: 'Please fill out this missing information',
  COMPLETE_REGISTRATION_PROCESS: 'Please complete the registration process',
  SOCIAL_LINKS: 'Social Info',
  PLEASE_INCLUDE_ENTIRE_LINK: 'Lets get connected!',
  TWITTER: 'Twitter Handle',
  LINKEDIN: 'LinkedIn Link',
  WEBSITE: 'Website URL',
  DISCORD: 'Discord Username',
  INSTAGRAM: 'Instagram Handle',
  INVALID_IMAGE_ERROR: 'Please upload a valid image file (png, jpg, jpeg, or gif)',
  ADDRESS: 'Home Address',
  INVALID_ADDRESS: 'Address must have a Street, City, Country and Zip Code.',
  USERNAME: 'Username',
  BIRTHDATE: 'Birthday',
  USERNAME_UNAVAILABLE: 'username unavailable. try another.',
  USERNAME_AVAILABLE: 'username is available. cool.',
  CANCEL: 'Back',
  INVALID_URL: 'Please enter a valid URL',
  EMAIL: 'Email',
  NOTIFICATIONS: 'Notifications',
  PASSWORD: 'Password',
  INVALID_TWITTER_HANDLE: 'Please provide a valid twitter handle.',
  INVALID_LINKEDIN_LINK: 'Please provide a valid LinkedIn link.',
  INVALID_INSTAGRAM_HANDLE: 'Please provide a valid instagram handle.',
  INVALID_DISCORD_USERNAME: 'Please provide a valid discord username.',
  INVALID_DISCORD_INVITE_LINK: 'Please provide a valid discord server link.'
};

export const SECURITY_CHECK = {
  SECURITY_PROMPT: `Your security is super important to us. In order to continue
  we require you to confirm your email address by entering
  the authentication code we sent.`,
  SECURITY_HEADER: `Additional Verification Required`,
  SUBMIT: `Submit`,
  EMAIL_CONFIRMATION: (email: string): string => `Verification sent to ${email}`,
  DID_NOT_GET_IT: `Try refreshing this page to resend the code.`,
  RESEND: 'Resend Code'
};
