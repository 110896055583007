import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Progress(theme: Theme): object {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          height: 12,
          overflow: 'hidden'
        },
        bar: {
          backgroundColor: '#155EEF',
          borderRadius: 12
        },
        colorPrimary: {
          backgroundColor: isLight ? '#EAECF0' : '#ECECED'
        },
        buffer: {
          backgroundColor: 'transparent'
        }
      }
    }
  };
}
