import { Box, styled } from '@mui/material';

const DaoCardContainerStyle = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '0',
  gridTemplateColumns: 'repeat(2,1fr)',
  margin: '0 auto',
  width: 'fit-content',
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateColumns: 'repeat(3,1fr)'
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4,1fr)',
    maxWidth: '35.5rem'
  }
}));

export { DaoCardContainerStyle };
