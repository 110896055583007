import { Link, styled } from '@mui/material';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: 'transparent',
  padding: '0.5rem 0 1.5rem',
  ':hover': {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    cursor: 'pointer'
  }
}));

export default StyledLink;
