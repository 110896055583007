import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function KeyFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M21.3079 7.16113C20.0979 5.86113 18.7479 4.75112 17.4379 3.68112L17.0279 3.34113C16.7179 3.08113 16.3079 2.96113 15.8979 3.01113C15.4879 3.06113 15.1279 3.27113 14.8779 3.59113L10.0979 9.91113L7.55791 12.9611C5.38791 12.5911 3.40791 13.4011 2.50791 15.0311C1.77791 16.3411 1.83791 18.0011 2.66791 19.2511C3.19791 20.0611 3.93791 20.6311 4.79791 20.9011C5.18791 21.0211 5.58791 21.0811 5.99791 21.0811C6.91791 21.0811 7.84791 20.7811 8.65791 20.1911C9.84791 19.3211 10.5379 18.0211 10.5479 16.6211C10.5479 15.8811 10.3779 15.2111 10.0679 14.6511L12.4679 11.7711L13.5079 10.4011L15.7479 12.7911C16.0379 13.1111 16.4379 13.2611 16.8379 13.2611C17.2079 13.2611 17.5779 13.1311 17.8679 12.8511C18.4679 12.2811 18.5079 11.3311 17.9379 10.7311L15.3379 7.96112L16.3479 6.63113C17.3379 7.45113 18.2779 8.27112 19.1279 9.18112C19.6879 9.79112 20.6379 9.82113 21.2479 9.26113C21.8579 8.70113 21.8879 7.75112 21.3279 7.14112L21.3079 7.16113ZM6.87791 17.7811C6.49791 18.0611 6.03791 18.1611 5.69791 18.0511C5.48791 17.9811 5.31791 17.8411 5.16791 17.6111C4.95791 17.2911 4.93791 16.8211 5.12791 16.4911C5.36791 16.0511 5.96791 15.8811 6.54791 15.8811C6.71791 15.8811 6.89791 15.9011 7.05791 15.9211C7.26791 15.9611 7.37791 16.0211 7.40791 16.0511C7.45791 16.1011 7.53791 16.2911 7.52791 16.6011C7.52791 17.2311 7.06791 17.6211 6.87791 17.7711V17.7811Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
