const googlePlacesConfig = {
  API_URL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  API_SCRIPT_ID: 'google-maps-places-script',
  PLACE_COMPONENTS: {
    STREET_NUMBER: 'street_number',
    ROUTE: 'route',
    POSTAL_CODE: 'postal_code',
    LOCALITY: 'locality',
    ADMINISTRATIVE_AREA_L1: 'administrative_area_level_1',
    COUNTRY: 'country'
  }
};

export default googlePlacesConfig;
