import { TagDTO, TagUpdatePayload, TagsQueryParams } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import TagService from './use-tag-service.model';
import { tagUrl } from './use-tag-service.url';
import generateQueryString from '../../../utils/helper/query-builder';

const useTagService = (): TagService => {
  const { get, patch, _delete } = useHttp();
  const getTags = useCallback(
    (daoId: string, params: TagsQueryParams): Promise<AxiosResponse<TagDTO[]>> => {
      const queryString = generateQueryString(params);
      const url: string = `${tagUrl(daoId)}?${queryString}`;
      return get(url);
    },
    [get]
  );

  const updateTag = useCallback(
    (tagId: string, daoId: string, payload: TagUpdatePayload) => {
      return patch(`${tagUrl(daoId)}/${tagId}`, payload) as Promise<AxiosResponse<TagDTO>>;
    },
    [patch]
  );

  const deleteTag = useCallback(
    (tagId: string, daoId: string) => {
      return _delete(`${tagUrl(daoId)}/${tagId}`) as Promise<AxiosResponse<void>>;
    },
    [patch]
  );

  return {
    deleteTag,
    getTags,
    updateTag
  };
};

export default useTagService;
