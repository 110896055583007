import { ListItemText, styled } from '@mui/material';

export const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  cursor: 'pointer',
  flex: 'initial',
  minWidth: '67%',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey[300]
  },
  '& span': {
    alignItems: 'center',
    backgroundColor: isActive ? theme.palette.grey[300] : 'inherit',
    color: `${theme.palette.common.black} !important`,
    display: 'flex',
    flexDirection: 'row-reverse',
    fontWeight: '550 !important',
    justifyContent: 'left',
    gap: '0.5rem',
    height: '1.75rem',
    marginRight: '-.5rem',
    maxWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '0.5rem',
    minWidth: 'auto',
    width: 'auto',
    '& span': {
      flexDirection: 'row',
      justifyContent: 'center'
    }
  }
}));
