import { Box, Stack, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import { CreateNewPostButtonProps } from './CreateNewPostButton.props';
import { CreatePostButtonStyled } from './CreateNewPostButton.style';

const CreateNewPostButton = ({ label, onClick }: CreateNewPostButtonProps): ReactElement => {
  const theme = useTheme();

  const handleClick = () => onClick();

  return (
    <Stack direction="column" justifyContent="space-between" alignItems="normal">
      <Box padding={2} border={`1px solid ${theme.palette.grey[300]}`} borderRadius={'0.1875rem 0'}>
        <CreatePostButtonStyled variant="outlined" fullWidth onClick={handleClick}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1.5}>
            {label}
          </Stack>
        </CreatePostButtonStyled>
      </Box>
    </Stack>
  );
};

export default CreateNewPostButton;
