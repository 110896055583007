import {
  PatronageActivityAggregateResultsDTO,
  PatronageActivityDTO
} from '@piefi-platform/types-lib';
import { SingleLineProps } from 'components';
import { DefaultTimeSeriesDropdownOption } from 'constants/time-series-dropdown-options';
import { differenceInSeconds, isBefore } from 'date-fns';
import { usePatronageActivityService } from 'hooks/services';
import { TimeSeriesDropdownOption } from 'model';
import {
  PatronageActivityStatistic,
  TimeSeriesPatronageStatisticsContextType,
  initPatronageContextStatisticsType
} from 'model/contexts/time-series-patronage-statistics-type.model';
import { ReactNode, createContext, useEffect, useState } from 'react';
import DataType from 'types/enum/chart-data-types.enum';
import { getQueryStartDate } from 'utils';
import { useDao } from '../hooks';

export interface ActivityStatisticsResponse {
  activity: PatronageActivityDTO;
  statistics: PatronageActivityAggregateResultsDTO;
}

export interface AllPatronageData {
  areaData: PatronageActivityAggregateResultsDTO;
  activityData: PatronageActivityStatistic[];
}
const initialState: TimeSeriesPatronageStatisticsContextType = initPatronageContextStatisticsType();

export const TimeSeriesPatronageStatisticsContext = createContext(initialState);

const TimeSeriesPatronageStatisticsContextProvider = ({
  children
}: {
  children: ReactNode;
}): React.ReactElement => {
  const MIN_DELTA_TIME_THRESHOLD = 1;
  const { currentDao } = useDao();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateWindow, setDateWindow] = useState<{ startDate: Date; endDate: Date }>({
    startDate: new Date(),
    endDate: new Date()
  });
  const [selectedDropdownItem, setSelectedDropdownItem] = useState(DefaultTimeSeriesDropdownOption);
  const [allData, setAllData] = useState<AllPatronageData>({
    areaData: {
      percentChange: 0,
      timeSeriesData: [],
      totalCount: 0
    },
    activityData: []
  });
  const { getStatistics, getActivitiesWithStatistics } = usePatronageActivityService();
  const [dataType, setDataType] = useState<string>(DataType.POINT_COUNT);
  const { currentActiveSeasonForDao } = useDao();

  // When dropdown is updated
  useEffect(() => {
    let queryStartDate = getQueryStartDate(selectedDropdownItem.value);

    if (currentActiveSeasonForDao?.seasonNumber) {
      const queryIsBeforeSeasonStart = isBefore(
        queryStartDate,
        new Date(currentActiveSeasonForDao.startDate)
      );

      if (queryIsBeforeSeasonStart) {
        queryStartDate = new Date(currentActiveSeasonForDao.startDate);
      }
    }

    setDateWindow({ startDate: queryStartDate, endDate: new Date() });
  }, [selectedDropdownItem]);

  // When date window is updated refresh data
  useEffect(() => {
    if (!currentDao?.id) return;
    const distanceBetweenStartEndInMins = differenceInSeconds(
      dateWindow.endDate,
      dateWindow.startDate
    );
    if (distanceBetweenStartEndInMins < MIN_DELTA_TIME_THRESHOLD) return;

    const fetchUpdatedData = async () => {
      try {
        setIsLoading(true);
        const aggArea = await getAggregatedData(currentDao?.id);
        // const aggPatronage = await getPatronageActivitiesWithStats(currentDao?.id);
        setAllData({ activityData: [], areaData: aggArea });
      } catch (error) {
        console.error('Error fetching updated data', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUpdatedData();
    // I only want this to trigger on dateWindow or dataType toggle update NOTHING ELSE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateWindow, dataType]);

  /**
   * @description Gets the aggregated data across the entire time interval across all activities
   * @param daoId {string}
   */
  const getAggregatedData = async (daoId: string) => {
    const { data: queriedAreaData } = await getStatistics(
      daoId,
      dateWindow.startDate,
      dateWindow.endDate,
      selectedDropdownItem.interval,
      dataType as DataType
    );

    return queriedAreaData[0];
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getPatronageActivitiesWithStats = async (daoId: string) => {
    const activitiesWithStatsForInterval = await getActivitiesWithStatistics(
      daoId,
      dateWindow.startDate,
      dateWindow.endDate,
      selectedDropdownItem.interval,
      dataType as DataType,
      true
    );

    const formattedActivities = activitiesWithStatsForInterval.map((act) => {
      const { activity, statistics } = act;

      const randomHexColor = '#000000'.replace(/0/g, () => {
        //(~~) =  double NOT bitwise operation it is faster than Math.floor()
        return (~~(Math.random() * 16)).toString(16);
      });

      const existingActivity = allData.activityData.find(
        (item) => item.activity.id === act.activity.id
      );

      // if we already have a color use it
      // if we dont already have a color but the color is white then use a random one
      const color = existingActivity?.lineProps.color
        ? existingActivity.lineProps.color
        : activity.color === '#fff' || !activity.color
        ? randomHexColor
        : activity.color;

      return {
        activity,
        totalMetric: statistics.totalCount,
        lineProps: {
          color,
          data: statistics.timeSeriesData.map((data) => ({ date: data.end, value: data.count })),
          key: activity.id,
          name: activity.name
        } as SingleLineProps
      } as PatronageActivityStatistic;
    });

    return formattedActivities;
  };

  const onTimeSeriesDropdownOptionUpdated = (optionSelected: TimeSeriesDropdownOption) => {
    setSelectedDropdownItem(optionSelected);
  };

  const onTimeSeriesDataTypeUpdated = (event: any, typeSelected: string) => {
    typeSelected && setDataType(typeSelected);
  };

  return (
    <TimeSeriesPatronageStatisticsContext.Provider
      value={{
        areaDataForInterval: allData.areaData,
        onTimeSeriesDropdownOptionUpdated,
        patronageActivities: allData.activityData,
        selectedTimeSeriesDropdownOption: selectedDropdownItem,
        onTimeSeriesDataTypeUpdated,
        dataType,
        timeSeriesDataLoading: isLoading
      }}
    >
      {children}
    </TimeSeriesPatronageStatisticsContext.Provider>
  );
};

export default TimeSeriesPatronageStatisticsContextProvider;
