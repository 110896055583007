import { Stack, Typography } from '@mui/material';
import { TitleValueCardProps } from './TitleValueCard.props';

const TitleValueCard = ({ title, spacing, children }: TitleValueCardProps): React.ReactElement => {
  return (
    <Stack direction={'column'} spacing={spacing || 2}>
      <Typography variant={'body2'} fontWeight={'medium'}>
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

export default TitleValueCard;
