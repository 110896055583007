import { Stack, StackProps, styled } from '@mui/material';

const ColorSquare = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(({ theme, color }) => ({
  height: '0.5rem',
  width: '0.5rem',
  backgroundColor: color,
  margin: '0 1rem'
}));

const LineItemContainer = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  height: '3rem'
}));

interface ExtendedStackProps extends StackProps {
  colorobj: { r: number; g: number; b: number };
  percentage: number;
}

const StyledStack = styled(Stack)<ExtendedStackProps>(({ theme, colorobj, percentage }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '2rem',
  width: '100%',
  backgroundImage: `
      linear-gradient(
        to right,
        rgba(${colorobj.r}, ${colorobj.g}, ${colorobj.b}, 0.2) 0%,
        rgba(${colorobj.r}, ${colorobj.g}, ${colorobj.b}, 0.2) ${percentage}%,
        transparent ${percentage}%,
        transparent 100%
      )`
}));

export { ColorSquare, LineItemContainer, StyledStack };
