import { Room } from '@piefi-platform/types-lib';
import { BUTTON_LABELS } from 'constants/button.labels';

const { SAVE } = BUTTON_LABELS;

export const ROOM_NAVIGATION_OPTIONS = {
  ABOUT: 'About'
};

export const ROOM_ACTIONS = {
  ADD_ROOM_ACTION: '+ add room',
  ROOM_ACTION: 'Rooms',
  ADD_TO_ROOM: 'Add Member to Room',
  ROOM_MEMBER_DIALOG: 'Find members to add to the room.'
};

export const ROOM_FORM = {
  ROOM_CREATED_FAILURE: 'Room creation failed',
  ROOM_CREATED_SUCCESSFULLY: 'Room created successfully',
  ROOM_UPDATED_SUCCESSFULLY: 'Room updated successfully',
  ROOM_UPDATE_FAILURE: 'Room update failed',
  ROOM_NAME_LABEL: "What's the name of the room",
  ROOM_NAME_ERROR: 'Please enter the name of the room',
  ROOM_NAME_INPUT_PLACEHOLDER: 'e.g. room_of_requirement',
  ROOM_DESCRIPTION_LABEL: "What's the purpose",
  ROOM_DESCRIPTION_ERROR: 'Room description is too long, please shorten it to create the room',
  ROOM_DESCRIPTION_INPUT_PLACEHOLDER:
    'e.g. a short description about why users would want to come here and its use.',
  SUBMIT_BUTTON_TEXT: (room?: Room): string => (!room ? 'Add' : SAVE),
  TITLE: (room?: Room): string => (!room ? 'Create new room' : 'Edit Room'),
  STATUS: 'Change campaign status',
  SET_ROLE: 'Set Role'
};

export const ROOM_INFO_TITLES = {
  ROOM_NAME: 'ROOM NAME',
  ROOM_DESC: 'ROOM DESCRIPTION',
  ROOM_MEMBERS: 'ROOM MEMBERS'
};

export const ROOM_UPDATE_PERMISSIONS = {
  PERMISSIONS_MODAL_TITLE: 'Change Member Role',
  PERMISSIONS_MODAL_DESC: (roomName: string): string =>
    `This will change this user's role in the ${roomName} room.`
};
