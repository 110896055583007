import { Box, styled } from '@mui/material';

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: '0.75rem',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: 'transparent',
  borderRadius: '0.625rem',
  minWidth: 'fit-content',
  width: 'fit-content',
  height: 'fit-content',
  cursor: 'default',
  transition: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.divider}`
  }
}));

export { BoxStyled };
