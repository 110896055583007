import { useCallback, useEffect, useRef, useState } from 'react';
import { useThrottle } from 'hooks';
import { AsyncResult } from 'model';
import { ExecutionType } from 'types/enum';

const useAsync = <T>(
  method: () => Promise<T>,
  executionType = ExecutionType.Automatic
): AsyncResult<T> => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<T>();
  const throttledValue = useThrottle(executionType);
  const mounted = useRef(false);

  const execute = useCallback(() => {
    setLoading(true);

    const invoke = async () => {
      const result: T = await method();

      if (mounted.current) {
        setValue(result);
        setLoading(false);
      }
    };
    invoke();
  }, [method]);

  useEffect(() => {
    mounted.current = true;
    throttledValue === ExecutionType.Automatic && execute();

    return () => {
      mounted.current = false;
    };
  }, [throttledValue, execute]);

  return { loading, value, execute };
};

export default useAsync;
