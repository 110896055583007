import { Select, styled } from '@mui/material';

const SelectFormStyled = styled(Select, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'invalid'
})<{ invalid: boolean }>(({ theme, invalid }) => ({
  '&': {
    border: invalid && `solid ${theme.palette.error.main} 0.063rem`,
    borderRadius: invalid && '0.438rem'
  },
  '& .MuiTypography-body2': {
    lineHeight: '1.5rem'
  }
}));

export default SelectFormStyled;
