import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { CommentIcon, DownVoteIcon, TrophyPlusIcon, UpVoteIcon } from 'assets';
import { CommentMenu } from 'components';
import { COMMENTS_ACTIONS, POST_ACTIONS } from 'constants/menu-actions.labels';
import { useUser } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { EntityVoteEnum } from 'types/enum';
import { CommentFooterProps } from './CommentFooter.props';
import { CommentCounterButtonStyled } from './CommentFooter.style';

const CommentFooter = ({
  comment,
  getInteractions,
  hasDeletePermissions,
  isDeleteDialogOpen,
  setShowReply,
  showInteractions,
  toggleAwardBounty,
  toggleDeleteDialog,
  toggleEdit,
  toggleReportDialog,
  voteOnCommentReply,
  votes
}: CommentFooterProps): React.ReactElement => {
  const theme = useTheme();
  const { userInfo } = useUser();

  const defaultUpVoteIcon = useMemo(
    () => (
      <UpVoteIcon
        fontSize="inherit"
        stroke={comment.deleted ? theme.palette.grey[700] : theme.palette.text.primary}
      />
    ),
    [theme.palette.grey, comment.deleted]
  );
  const defaultDownVoteIcon = useMemo(
    () => (
      <DownVoteIcon
        fontSize="inherit"
        stroke={comment.deleted ? theme.palette.grey[700] : theme.palette.text.primary}
      />
    ),
    [theme.palette.grey, comment.deleted]
  );
  const [upVoteIcon, setUpVoteIcon] = useState(defaultUpVoteIcon);
  const [downVoteIcon, setDownVoteIcon] = useState(defaultDownVoteIcon);

  useEffect(() => {
    const configureVoteIcon = (action: EntityVoteEnum) => {
      switch (action) {
        case EntityVoteEnum.DownVote:
          setDownVoteIcon(<DownVoteIcon fontSize="inherit" fill={theme.palette.common.black} />);
          setUpVoteIcon(defaultUpVoteIcon);
          break;
        case EntityVoteEnum.UpVote:
          setUpVoteIcon(<UpVoteIcon fontSize="inherit" fill={theme.palette.common.black} />);
          setDownVoteIcon(defaultDownVoteIcon);
          break;
        default:
          setUpVoteIcon(defaultUpVoteIcon);
          setDownVoteIcon(defaultDownVoteIcon);
          break;
      }
    };

    if (comment.deleted) {
      setUpVoteIcon(defaultUpVoteIcon);
      setDownVoteIcon(defaultDownVoteIcon);
      return;
    }

    const vote = comment.votes && comment?.votes?.length > 0 && comment.votes[0];
    if (vote) configureVoteIcon(vote.action as EntityVoteEnum);
  }, [comment, defaultUpVoteIcon, defaultDownVoteIcon, theme.palette.common.black]);

  return (
    <Stack data-testid="comment-footer" direction="row" justifyContent="space-between">
      <CommentCounterButtonStyled variant="text" onClick={() => getInteractions(comment)}>
        {showInteractions
          ? POST_ACTIONS.COLLAPSE
          : `${comment._count.children} ${COMMENTS_ACTIONS.COMMENTS}`}
      </CommentCounterButtonStyled>
      <Stack direction="row" gap={1} justifyContent="flex-end" alignItems="center">
        <Stack direction="row" gap={0.25} alignItems="center">
          <IconButton
            data-testid="upvote-comment"
            size="small"
            onClick={() => voteOnCommentReply(EntityVoteEnum.UpVote)}
          >
            {upVoteIcon}
          </IconButton>
          <Typography
            data-testid="votes-count"
            variant="caption"
            color={comment.deleted ? 'text.secondary' : 'text.primary'}
          >
            {votes}
          </Typography>
          <IconButton
            data-testid="downvote-comment"
            size="small"
            onClick={() => voteOnCommentReply(EntityVoteEnum.DownVote)}
          >
            {downVoteIcon}
          </IconButton>
        </Stack>
        <IconButton size="small" onClick={() => setShowReply(true)}>
          <CommentIcon
            fontSize="inherit"
            fill={comment.deleted ? theme.palette.text.secondary : theme.palette.text.primary}
          />
        </IconButton>
        {toggleAwardBounty && (
          <IconButton data-testid="award-bounty" size="small" onClick={toggleAwardBounty}>
            <TrophyPlusIcon />
          </IconButton>
        )}
        <CommentMenu
          comment={comment}
          authorId={userInfo?.id}
          hasDeletePermissions={hasDeletePermissions}
          isDeleteDialogOpen={isDeleteDialogOpen}
          toggleReportModal={toggleReportDialog}
          toggleDeleteModal={toggleDeleteDialog}
          toggleEdit={toggleEdit}
        />
      </Stack>
    </Stack>
  );
};

export default CommentFooter;
