import { Button, Stack, Typography } from '@mui/material';
import { Check } from 'assets/icons/general';
import { DIRECT_ALLOCATION } from 'constants/dao-admin-nav.labels';
import { useMemo } from 'react';
import { AllocationPayload } from '../direct-allocation-wrapper/DirectAllocationWrapper';
import { IconButtonStyled } from './DirectAllocationCongratulations.style';

interface Props {
  allocationInformation: AllocationPayload;
  onDoneClicked: () => void;
}

const DirectAllocationCongratulations = ({
  allocationInformation,
  onDoneClicked
}: Props): React.ReactElement => {
  const { CONGRATULATIONS } = DIRECT_ALLOCATION;
  const memberNames = useMemo(
    () => allocationInformation.members.map((i) => i.user.firstName),
    [allocationInformation.members]
  );

  return (
    <Stack direction={'column'} alignItems={'center'} spacing={3}>
      <IconButtonStyled
        disableRipple
        size="large"
        onClick={() => window.open('https://rocks.awsm.com', '_blank')}
      >
        <Check stroke="#155eef" />
      </IconButtonStyled>
      <Typography variant={'h3'} textAlign={'center'}>
        {CONGRATULATIONS.SECTION_HEADER}
      </Typography>
      <Typography variant={'body1'} textAlign={'center'}>
        {CONGRATULATIONS.SUMMARY(memberNames)}
      </Typography>
      <Button variant="outlined" size={'large'} fullWidth onClick={onDoneClicked}>
        <Typography variant={'subtitle2'} fontWeight={'bold'}>
          {CONGRATULATIONS.FINALIZE_ACTION}
        </Typography>
      </Button>
    </Stack>
  );
};

export default DirectAllocationCongratulations;
