import { DaoMembershipDTO } from '@piefi-platform/types-lib';

export const HEADER_SECTION_TITLES = {
  EDIT_COLLECTIVE: 'Edit Collective',
  BECOME_A_MEMBER: 'Become a Member'
};

export const HOME_PAGE_TITLES = {
  ABOUT: 'About',
  ACTIVITIES: (
    value: number | undefined,
    activeDaoMembership: DaoMembershipDTO | undefined
  ): string => `Activities ${activeDaoMembership?.id ? `(${value ? value : 0})` : ''}`,
  MEMBERS: (value: number | undefined, activeDaoMembership: DaoMembershipDTO | undefined): string =>
    `Members ${activeDaoMembership?.id ? `(${value ? value : 0})` : ''}`
};

export const ABOUT_SECTION_LABELS = {
  FOUNDED: 'Founded',
  WEBSITE: 'Website',
  READ_MORE: 'Read more'
};

export const ACTIVITIES_SECTION_LABELS = {
  NO_ACTIVITIES: 'No activities available',
  BACK: 'back',
  AVAILABLE: 'available'
};

export const HOME_PAGE_BUTTON_LABELS = {
  JOIN_TO_SEE_ACTIVITIES: 'Join to see Activities',
  JOIN_TO_SEE_MEMBERS: 'Join to see Members'
};
