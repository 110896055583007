import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AxisBottom } from '@visx/axis';
import { curveBasis } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AnimatedAreaSeries, Tooltip, XYChart } from '@visx/xychart';
import { UnitAmountIconDisplay } from 'components/texts';
import { extent, max } from 'd3-array';
import { format } from 'date-fns';
import { usePatronageActivityStatistics } from 'hooks';
import React, { useCallback, useMemo } from 'react';
import { TimeSeriesChartPoint } from 'types';
import { MetricType } from 'types/enum';
import { formatDate, numberFormatter, usdFormatter } from 'utils';
import AreaChartProps from './AreaChart.props';

const AreaChart = ({
  data,
  height,
  width,
  margin,
  metricType
}: AreaChartProps): React.ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { selectedTimeSeriesDropdownOption, timeSeriesDataLoading } =
    usePatronageActivityStatistics();

  const accessors = {
    xAccessor: (d: TimeSeriesChartPoint) => new Date(d?.date ?? 0),
    yAccessor: (d: TimeSeriesChartPoint) => d.value
  };

  const tickValues = useMemo(() => {
    const ticks = data.map((d) => new Date(accessors.xAccessor(d)));
    const maxTicks = isMobile ? 2 : 12;
    if (ticks.length <= maxTicks) {
      return ticks;
    }
    const interval = Math.ceil(ticks.length / maxTicks);
    let filteredTicks = ticks.filter((_, i) => i % interval === 0);

    // Ensure the first and last ticks are included
    if (!filteredTicks.includes(ticks[0])) {
      filteredTicks.unshift(ticks[0]);
    }
    if (!filteredTicks.includes(ticks[ticks.length - 1])) {
      filteredTicks.push(ticks[ticks.length - 1]);
    }

    return filteredTicks;
  }, [data, accessors]);

  // bounds
  const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
  const { top, right, bottom, left } = margin || defaultMargin;

  const innerWidth = (width || 0) - left - right;
  const innerHeight = (height || 0) - top - bottom;

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [left, innerWidth + left] as [number, number],
        domain: extent(data, accessors.xAccessor) as [Date, Date]
      }),
    [innerWidth, left, data]
  );

  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [innerHeight + top, top] as [number, number],
        domain: [0, (max(data, accessors.yAccessor) || 0) + innerHeight / 3],
        nice: true
      }),
    [top, innerHeight, data]
  );

  const getTickFormat = useCallback(
    (d: Date) => {
      if (selectedTimeSeriesDropdownOption.value === '1d') {
        const localDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000);
        return format(localDate, 'hh a');
      }
      return format(d, 'MMM d');
    },
    [selectedTimeSeriesDropdownOption]
  );

  const xToIndexMap = useMemo(() => {
    const scale = dateScale.copy();
    return tickValues.map((d, i) => ({ x: scale(d), index: i }));
  }, [dateScale, tickValues]);

  return (
    <>
      <XYChart
        height={height}
        width={width}
        margin={margin}
        xScale={{ type: 'time' }}
        yScale={{ type: 'linear' }}
      >
        <GridRows
          left={left}
          scale={valueScale}
          width={innerWidth}
          strokeDasharray="1,0"
          stroke={theme.palette.grey[400]}
          strokeOpacity={0.2}
          pointerEvents="none"
          tickValues={valueScale.ticks(5)}
        />
        {!timeSeriesDataLoading && (
          <AnimatedAreaSeries
            dataKey="area"
            data={data}
            xAccessor={accessors.xAccessor}
            yAccessor={accessors.yAccessor}
            curve={curveBasis}
            fill={'url(#area-gradient)'}
            lineProps={{ stroke: '#155EEF', strokeWidth: 2 }}
          />
        )}
        <LinearGradient id="area-gradient" from={'#D1E0FF'} to={'#D1E0FF'} toOpacity={0} />
        <Tooltip<TimeSeriesChartPoint>
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          renderTooltip={({ tooltipData }) => {
            const tooltipDate =
              tooltipData?.nearestDatum?.datum &&
              formatDate(new Date(tooltipData?.nearestDatum?.datum.date));

            const tooltipTime =
              tooltipData?.nearestDatum?.datum &&
              format(new Date(tooltipData?.nearestDatum?.datum.date), 'hh:mm a');

            const tooltipValue = Object.entries(tooltipData?.datumByKey || {}).map(
              ([key, value]) => {
                let keyValue = 'Total';
                if (key !== 'area') {
                  keyValue = key;
                }

                let datumValue;
                if (metricType === MetricType.BITS) {
                  datumValue = <UnitAmountIconDisplay amount={value.datum.value} showAsPrimary />;
                } else if (metricType === MetricType.CURRENCY) {
                  datumValue = usdFormatter.format(value.datum.value);
                } else if (metricType === MetricType.NUMBER) {
                  datumValue = numberFormatter.formatterWithZeroDecimals(value.datum.value);
                }

                return (
                  <Stack direction={'row'} key={key} alignItems={'center'}>
                    <Typography
                      variant={'body2'}
                      paddingRight={'0.25rem'}
                    >{`${keyValue}: `}</Typography>
                    {datumValue}
                  </Stack>
                );
              }
            );

            return (
              <Stack direction={'column'} spacing={0.5}>
                <Typography variant={'body2'} color={theme.palette.secondary.main}>
                  {tooltipDate}
                </Typography>
                {selectedTimeSeriesDropdownOption.value === '1d' && (
                  <Typography variant={'body2'} color={theme.palette.secondary.main}>
                    {tooltipTime}
                  </Typography>
                )}
                {tooltipValue}
              </Stack>
            );
          }}
        />
      </XYChart>
      {!timeSeriesDataLoading && (
        <svg width={width}>
          <AxisBottom
            top={2}
            left={left}
            scale={dateScale}
            stroke={`${theme.palette.grey[600]}`}
            tickStroke={`${theme.palette.grey[600]}`}
            tickValues={tickValues}
            tickFormat={(d) => getTickFormat(d as Date)}
            tickComponent={(tickRendererProps) => {
              const { x, y, formattedValue } = tickRendererProps;

              const mapping = xToIndexMap.find((m) => m.x === x);
              const index = mapping ? mapping.index : -1;

              let textAnchor = 'middle';
              if (index === 0) {
                textAnchor = 'start';
              } else if (index === tickValues.length - 1) {
                textAnchor = 'end';
              }

              return (
                <text
                  x={x}
                  y={y + 8}
                  fill={`${theme.palette.grey[600]}`}
                  fontSize="12px"
                  fontFamily="sans-serif"
                  textAnchor={textAnchor}
                >
                  {formattedValue}
                </text>
              );
            }}
          />
        </svg>
      )}
    </>
  );
};

export default AreaChart;
