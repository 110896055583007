import {
  PatronageActivityAggregateResultsDTO,
  PatronageActivityDTO
} from '@piefi-platform/types-lib';
import { SingleLineProps } from 'components';
import { DefaultTimeSeriesDropdownOption } from 'constants/time-series-dropdown-options';
import { TimeSeriesDropdownOption } from '../drop-down-option.model';
import DataType from 'types/enum/chart-data-types.enum';

export interface PatronageActivityStatistic {
  activity: PatronageActivityDTO;
  lineProps: SingleLineProps;
  totalMetric: number;
}

export interface TimeSeriesPatronageStatisticsContextType {
  selectedTimeSeriesDropdownOption: TimeSeriesDropdownOption;
  onTimeSeriesDropdownOptionUpdated: (newOption: TimeSeriesDropdownOption) => void;

  patronageActivities: PatronageActivityStatistic[];
  areaDataForInterval: PatronageActivityAggregateResultsDTO;

  onTimeSeriesDataTypeUpdated: (event: any, typeSelected: string) => void;
  dataType: string;
  timeSeriesDataLoading: boolean;
}

export const initPatronageContextStatisticsType = (): TimeSeriesPatronageStatisticsContextType => ({
  patronageActivities: [],
  areaDataForInterval: {} as PatronageActivityAggregateResultsDTO,
  onTimeSeriesDropdownOptionUpdated: (option: TimeSeriesDropdownOption) => {},
  selectedTimeSeriesDropdownOption: DefaultTimeSeriesDropdownOption,
  onTimeSeriesDataTypeUpdated: (event: any, typeSelected: string) => {},
  dataType: DataType.POINT_COUNT,
  timeSeriesDataLoading: false
});
