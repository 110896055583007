import { Button, Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { InputForm } from 'components/inputs';
import BaseModal from 'components/modals';
import { INPUT_VALIDATION } from 'constants/app-config';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { DAO_UPDATE_PERMISSIONS } from 'constants/dao-admin-labels';
import { useDao, useNotification } from 'hooks';
import { useDaoExternalReferenceService } from 'hooks/services';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Severity } from 'types/enum';
import { HTTP_STATUS } from '../../../../constants/http-statuses';
import { UpdateExternalReferenceModalProps } from './UpdateExternalReferenceModal.props';

const UpdateExternalReferenceModal = ({
  externalReference,
  userId,
  onSaveSuccessful,
  open,
  setModalState
}: UpdateExternalReferenceModalProps): React.ReactElement => {
  const { currentDao } = useDao();
  const { notify, resetNotifier } = useNotification();
  const closeModal = (): void => {
    setModalState(false);
  };
  const { createExternalReference, updateExternalReference } = useDaoExternalReferenceService();
  const {
    CHANGE_EXTERNAL_ID,
    CHANGE_EXTERNAL_ID_DESC,
    SUCCESS,
    ERROR,
    DUPLICATE_ERROR,
    FORM_VALIDATION
  } = DAO_UPDATE_PERMISSIONS.EXTERNAL_REFERENCE;
  const { MAX_LENGTH, MIN_LENGTH } = COMMON_VALIDATIONS;
  const { MAX_MEDIUM_TEXT_LENGTH, MIN_NAME_LENGTH } = INPUT_VALIDATION;
  const { REQUIRED } = FORM_VALIDATION;
  const { CANCEL, SAVE } = BUTTON_LABELS;
  const [loading, setLoading] = React.useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<{ externalId: string }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      externalId: ''
    }
  });

  const onSubmit = async ({ externalId }: { externalId: string }) => {
    try {
      setLoading(true);
      const payload = {
        externalId,
        userId
      };
      // convert this into ternary
      const response = externalReference
        ? await updateExternalReference(currentDao?.id as string, externalReference.id, payload)
        : await createExternalReference(currentDao?.id as string, { externalId, userId });

      notify(`${SUCCESS}`, {
        severity: Severity.Success
      });
      onSaveSuccessful(response.data);
      closeModal();
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === HTTP_STATUS.CONFLICT) {
        resetNotifier();
        setError('externalId', { message: DUPLICATE_ERROR });
      } else {
        notify(`${ERROR}`, {
          severity: Severity.Error
        });
      }
      console.error(error);
      captureException(error);
    }
  };

  return (
    <BaseModal title={CHANGE_EXTERNAL_ID} toggle={open} close={closeModal}>
      <Typography variant="body1" marginBottom="1rem" marginTop="1rem">
        {CHANGE_EXTERNAL_ID_DESC}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={8}>
          <Controller
            name="externalId"
            control={control}
            rules={{
              required: REQUIRED,
              minLength: {
                value: MIN_NAME_LENGTH,
                message: MIN_LENGTH(MIN_NAME_LENGTH)
              },
              maxLength: {
                value: MAX_MEDIUM_TEXT_LENGTH,
                message: MAX_LENGTH(MAX_MEDIUM_TEXT_LENGTH)
              }
            }}
            render={({ field, fieldState }) => (
              <InputForm
                placeholder={externalReference?.externalId || ''}
                size="small"
                error={!!errors.externalId}
                helperText={errors.externalId?.message}
                {...field}
              />
            )}
          />
          <Stack direction="row" spacing={2}>
            <Button
              onClick={closeModal}
              role="button"
              type="button"
              color="primary"
              size="large"
              variant="outlined"
            >
              {CANCEL}
            </Button>
            <Button
              role="button"
              type="submit"
              color="primary"
              size="large"
              variant="contained"
              disabled={loading}
            >
              {SAVE}
            </Button>
          </Stack>
        </Stack>
      </form>
    </BaseModal>
  );
};

export default UpdateExternalReferenceModal;
