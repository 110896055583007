import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  TableCell,
  Tooltip,
  Typography
} from '@mui/material';
import { DaoMembershipDTO, ExternalReferenceDTO, TagDTO } from '@piefi-platform/types-lib';
import { TagIcon, UserRoleIcon } from 'assets';
import { NoteReceipt } from 'assets/icons/hero-ui';
import {
  AvatarBuilder,
  EllipsisMenu,
  TagChip,
  TagsManager,
  UpdateDaoPermissionsModal,
  UpdateExternalReferenceModal
} from 'components';
import { USER_ACTIONS } from 'constants/menu-actions.labels';
import { useAdminMembership, useDao, useEventEmitter } from 'hooks';
import { useDaoMembershipService } from 'hooks/services';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BRAND } from 'theme/palette';
import { AvatarSize, UserDaoRole } from 'types/enum';
import { compareDaoRoles, getCurrentDaoRoleTier, truncateId } from 'utils';
import { DaoAdminMemberRowProps } from './DaoAdminMemberRow.props';
import { ClickableTableCell, StyledDaoMemberAdminRow } from './DaoAdminMemberRow.style';

const DaoAdminMemberRow = ({ daoMembership }: DaoAdminMemberRowProps): React.ReactElement => {
  const [tags, setTags] = useState<TagDTO[]>(daoMembership.tags || []);
  const [membership, setMembership] = useState<DaoMembershipDTO & { isSelected: boolean }>(
    daoMembership
  );
  const [isClickedRow, setIsClickedRow] = useState<boolean>(false);
  const [isInlineChip, setIsInlineChip] = useState<boolean>(true);
  const [openPermissionsModal, setOpenPermissionsModal] = useState<boolean>(false);
  const [openExternalReferenceModal, setOpenExternalReferenceModal] = useState<boolean>(false);

  const { subscribe } = useEventEmitter();
  const { createAndAttachTag, detachTag } = useDaoMembershipService();
  const { anchorEl, tagManagerEnabled, deselectAll, toggleMember, toggleTagManager, setAnchorEl } =
    useAdminMembership();
  const { currentDao, activeDaoMembership } = useDao();
  const ellipsisButtonRef = useRef<HTMLButtonElement | null>(null);
  const { CHANGE_ROLE, EDIT_TAGS, EDIT_EXTERNAL_REFERENCE } = USER_ACTIONS;
  const TAG_LIMIT = 4;

  useEffect(() => {
    const userExtIds = (membership.user as any).externalIds || [];
    setMembership({
      ...daoMembership,
      user: { ...daoMembership.user, externalIds: [...userExtIds] }
    });
  }, [daoMembership]);

  const checkIfTagExistsInList = useCallback(
    (desiredTagId: string): boolean => {
      const tagFound = tags.find((i) => i.id === desiredTagId);
      return !!tagFound;
    },
    [tags]
  );

  useEffect(() => {
    const unsubscribe = subscribe('ON_TAG_DELETE', Math.random(), (payload) => {
      const updatedMemberTags = tags.filter((i) => i.id !== payload?.data.id);
      setTags([...updatedMemberTags]);
    });

    return () => unsubscribe();
  }, [subscribe]);

  useEffect(() => {
    const unsubscribe = subscribe('ON_TAG_UPDATE', Math.random(), (payload) => {
      const updatedTags = tags.map((i) => {
        if (i.id === payload?.data.id) i = { ...payload.data };
        return i;
      });
      setTags([...updatedTags]);
    });

    return () => unsubscribe();
  }, [subscribe]);

  useEffect(() => {
    const unsubscribe = subscribe('BULK_TAG_ADDED', Math.random(), (payload) => {
      if (!payload) return;
      const memberEffectedByTagAddition = payload.membersEffected.includes(daoMembership.id);
      const tagAlreadyExists = checkIfTagExistsInList(payload?.data.id || '');
      if (payload && memberEffectedByTagAddition && !tagAlreadyExists)
        setTags((prevTags) => [...prevTags, payload.data]);
    });

    return () => unsubscribe();
  }, [subscribe]);

  useEffect(() => {
    const unsubscribe = subscribe('BULK_TAG_DETACHED', Math.random(), (payload) => {
      if (!payload) return;
      const memberEffectedByTagDetached = payload.membersEffected.includes(daoMembership.id);
      if (payload && memberEffectedByTagDetached)
        setTags((prevTags) => [...prevTags.filter((i) => i.id !== payload.data.id)]);
    });

    return () => unsubscribe();
  }, [subscribe]);

  const handleMoreTagsClick = useCallback(
    (event: any) => {
      event.preventDefault();
      deselectAll();
      const element = document.getElementById(`tag-cell-${daoMembership.id}`);
      if (!element) return;

      setIsClickedRow(true);
      toggleTagManager(event, element);
    },
    [deselectAll, toggleTagManager, setAnchorEl]
  );

  const handleEditTags = useCallback(
    (e: any) => {
      e.preventDefault();
      deselectAll();
      setIsInlineChip(false);
      setIsClickedRow(true);
      toggleTagManager(e, ellipsisButtonRef.current as HTMLElement | undefined);
    },
    [deselectAll, toggleTagManager]
  );

  const handleCloseTagManager = useCallback(() => {
    setIsClickedRow(false);
    setIsInlineChip(true);
    toggleTagManager(undefined, undefined);
  }, [toggleTagManager]);

  const onCreateAndAttach = useCallback(
    async (tag: Partial<TagDTO>) => {
      if (!membership.id || !currentDao) return;
      const response = await createAndAttachTag([membership.id], currentDao.id, tag);
      const newTag = response.data as TagDTO;
      setTags((prevTags) => [...prevTags, newTag]);
    },
    [tags, currentDao]
  );

  const onDetach = useCallback(
    async (tag: TagDTO) => {
      if (!membership.id || !currentDao) return;
      await detachTag([membership.id], currentDao.id, tag.id);
      setTags((prevTags) => [...prevTags.filter((t) => t.id !== tag.id)]);
    },
    [tags, currentDao]
  );

  const isAdmin = useCallback((): boolean => {
    if (!activeDaoMembership) return false;
    return compareDaoRoles(
      UserDaoRole.ADMIN,
      UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole]
    );
  }, [activeDaoMembership]);

  const isLowerOrSameLevelPermission = useCallback(() => {
    return Boolean(
      getCurrentDaoRoleTier[activeDaoMembership?.role as keyof typeof UserDaoRole].includes(
        membership?.role
      )
    );
  }, [activeDaoMembership?.role, membership?.role]);

  const updateMembership = useCallback(
    (updatedMember: DaoMembershipDTO) => {
      setMembership({ ...updatedMember, isSelected: membership.isSelected });
    },
    [membership.isSelected]
  );

  if (!activeDaoMembership) return <></>;

  return (
    <>
      {openPermissionsModal && (
        <UpdateDaoPermissionsModal
          open={openPermissionsModal}
          setModalState={(isOpen) => setOpenPermissionsModal(isOpen)}
          onRoleUpdated={updateMembership}
          daoMembership={membership}
        />
      )}
      {openExternalReferenceModal && (
        <UpdateExternalReferenceModal
          open={openExternalReferenceModal}
          setModalState={(isOpen) => setOpenExternalReferenceModal(isOpen)}
          onSaveSuccessful={(extRef: ExternalReferenceDTO) => {
            updateMembership({
              ...membership,
              user: {
                ...membership.user,
                externalIds: [extRef]
              }
            });
          }}
          userId={membership.user.id}
          externalReference={membership.user.externalIds?.[0]}
        />
      )}
      <StyledDaoMemberAdminRow key={membership.id} data-testid="member-row">
        {/* Member Avatar and Name */}
        <ClickableTableCell
          align="left"
          width={'30%'}
          onClick={() => toggleMember(!membership.isSelected, membership)}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <Checkbox
              checked={membership.isSelected}
              sx={{
                '& .MuiSvgIcon-root': {
                  height: 16,
                  width: 16,
                  rect: { stroke: 'transparent' }
                },
                '& .Mui-checked': { rect: { stroke: 'primary.main' } },
                '&:hover': { bgcolor: 'transparent' }
              }}
            />
            <AvatarBuilder
              src={membership.user?.profileThumbnail}
              id={'profile-avatar'}
              alt={membership.user?.username}
              size={AvatarSize.MEDIUM}
            />
            <Typography variant="body2" marginLeft={'.5rem'}>
              {membership.user.firstName} {membership.user.lastName}
            </Typography>
          </Stack>
        </ClickableTableCell>

        {/* Tags */}
        <TableCell id={`tag-cell-${daoMembership.id}`} align="left" width={'38%'}>
          {tags?.slice(0, TAG_LIMIT).map((tag) => (
            <TagChip key={tag.id} id={tag.id} label={tag.name} backgroundColor={tag.color} />
          ))}
          {tags && tags?.length > TAG_LIMIT && (
            <TagChip
              key="overflow-tag"
              label={`+${(tags?.length || 0) - TAG_LIMIT}`}
              title={tags
                ?.slice(TAG_LIMIT, tags.length)
                .map((tag) => tag.name)
                .join(', ')}
              backgroundColor={BRAND.grey[500]}
              onClick={(event: any) => handleMoreTagsClick(event)}
            />
          )}
        </TableCell>

        {/* ExternalId */}
        <TableCell align="left" width={'20%'}>
          <Tooltip title={membership.user?.externalIds?.[0]?.externalId ?? ''}>
            <Typography variant="body2">
              {truncateId(membership.user?.externalIds?.[0]?.externalId ?? '')}
            </Typography>
          </Tooltip>
        </TableCell>

        {/* Role */}
        <TableCell
          width={'10%'}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <Typography variant="body2" color="text.secondary" paddingRight={'1rem'}>
            {membership.role.toLowerCase()}
          </Typography>
          {isAdmin() ? (
            <EllipsisMenu center={false} ref={ellipsisButtonRef}>
              <MenuItem data-testid="edit-dao-member-tags" onClick={(e: any) => handleEditTags(e)}>
                <TagIcon style={{ marginRight: '.5rem' }} /> {EDIT_TAGS}
              </MenuItem>
              <MenuItem
                data-testid="edit-dao-member-external-reference"
                onClick={(e: any) => setOpenExternalReferenceModal(true)}
              >
                <NoteReceipt style={{ marginRight: '.5rem' }} /> {EDIT_EXTERNAL_REFERENCE}
              </MenuItem>
              {membership?.userId !== activeDaoMembership?.userId &&
                isLowerOrSameLevelPermission() && (
                  <MenuItem
                    data-testid="edit-dao-member-permissions"
                    onClick={() => setOpenPermissionsModal(true)}
                  >
                    <UserRoleIcon style={{ marginRight: '.5rem' }} />
                    {CHANGE_ROLE}
                  </MenuItem>
                )}
            </EllipsisMenu>
          ) : (
            <IconButton disabled={true} />
          )}
        </TableCell>
      </StyledDaoMemberAdminRow>
      {anchorEl && tagManagerEnabled && isClickedRow && (
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: isInlineChip ? 'left' : 'right'
          }}
          open={tagManagerEnabled}
          anchorEl={anchorEl}
          onClose={handleCloseTagManager}
          transformOrigin={{
            vertical: 'top',
            horizontal: isInlineChip ? 'left' : 'right'
          }}
        >
          <Box width="100%" maxWidth="20rem">
            <TagsManager
              onCreateAndAttach={onCreateAndAttach}
              onDetach={onDetach}
              tagsAssigned={tags}
            />
          </Box>
        </Popover>
      )}
    </>
  );
};

export default DaoAdminMemberRow;
