import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function NoteFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '16px';
  const height = props.height ? `${props.height}px` : '21px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 21" {...props}>
      <path
        d="M15.6966 9.01879C15.6566 8.16879 15.6666 7.32879 15.6866 6.52879C15.7066 5.56879 15.7266 4.57879 15.6466 3.55879C15.5066 1.73879 14.5966 0.588788 12.9366 0.128788C12.2466 -0.0612124 11.5266 -0.0012121 10.8866 0.0587879C10.6666 0.0787879 10.4466 0.098789 10.2366 0.108789C9.26662 0.148789 8.29662 0.148788 7.34662 0.128788H6.69662C5.73662 0.0887876 4.74662 0.0687883 3.75662 0.158788C3.61662 0.168788 3.47662 0.188788 3.33662 0.208788C0.95662 0.508788 0.296618 1.73879 0.166618 2.71879L0.0866186 3.31879C0.0666186 3.46879 0.0566198 3.62879 0.0566198 3.77879L0.13662 6.07879C0.18662 7.55879 0.136618 9.07879 0.0766184 10.5488C0.00661835 12.3888 -0.0533816 14.2888 0.0766184 16.1888C0.136618 17.0088 0.236619 17.9588 0.696619 18.7988C1.47662 20.1888 3.00662 20.4988 4.52662 20.4988C5.17662 20.4988 5.82662 20.4388 6.41662 20.3888C6.72662 20.3588 7.02662 20.3388 7.28662 20.3188L9.21662 20.2088C9.39662 20.1988 9.59662 20.2088 9.78662 20.2088C10.1966 20.2088 10.6266 20.2288 11.0366 20.1388C11.6466 20.0088 11.8866 19.6288 12.0566 19.3588L14.4166 15.8388C15.0466 14.8988 15.5666 13.7588 15.5966 13.7088C15.5966 13.7088 15.5966 13.7088 15.5966 13.6988C15.6166 13.6588 15.6166 13.6088 15.6266 13.5688C15.6366 13.5188 15.6566 13.4788 15.6566 13.4288C15.6566 13.2988 15.7666 10.1888 15.7066 9.01879H15.6966ZM8.77662 10.1688L4.11662 10.2288C3.70662 10.2288 3.37662 9.89879 3.36662 9.48879C3.36662 9.07879 3.69662 8.73879 4.10662 8.72879L8.76662 8.66879C9.17662 8.66879 9.50662 8.99879 9.51662 9.40879C9.51662 9.81879 9.18662 10.1588 8.77662 10.1688ZM4.10662 6.22879C3.69662 6.22879 3.35662 5.89879 3.35662 5.48879C3.35662 5.07879 3.68662 4.73879 4.09662 4.72879L11.8866 4.66879C12.2966 4.66879 12.6366 4.99879 12.6366 5.40879C12.6366 5.81879 12.3066 6.15879 11.8966 6.16879L4.10662 6.22879ZM13.1566 15.0088L10.8866 18.3988L10.7866 18.5588C10.7866 18.5588 10.7866 18.5588 10.7866 18.5688L10.3166 14.1588H13.6766C13.5266 14.4388 13.3466 14.7388 13.1666 15.0088H13.1566Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
