import { PatronageActivity, PatronageActivityDTO } from '@piefi-platform/types-lib';
import { ActivityFormTypeValue } from 'types/enum';

export enum ActivityFormStep {
  PLATFORM = 'platform',
  ACTIVITY = 'activity',
  MANUAL_ALLOCATION = 'manual_allocation',
  BLANK = ''
}

export default interface PatronageActivityContextType {
  activityStepperActive: boolean;
  setActivityStepperActive: (value: boolean) => void;

  activityFormType: ActivityFormTypeValue;
  setActivityFormType: (type: ActivityFormTypeValue) => void;

  activeFormStep: ActivityFormStep;
  setActiveFormStep: (value: ActivityFormStep) => void;

  activityToEdit?: PatronageActivity;
  setActivityToEdit: (activity: PatronageActivity | undefined) => void;

  activityForManualDistribution?: PatronageActivityDTO;
  setActivityForManualDistribution: (activity: PatronageActivityDTO | undefined) => void;
}
