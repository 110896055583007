declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    fontWeightExtraBold?: number;
  }
}

export const FONT_PRIMARY = 'Manrope, sans-serif';

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

const typography = {
  fontFamily: FONT_PRIMARY,
  htmlFontSize: 16,
  fontSize: 14,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  h1: {
    fontWeight: 700,
    lineHeight: pxToRem(90),
    fontSize: pxToRem(72)
  },
  h2: {
    fontWeight: 700,
    lineHeight: pxToRem(72),
    fontSize: pxToRem(60)
  },
  h3: {
    fontWeight: 700,
    lineHeight: pxToRem(60),
    fontSize: pxToRem(48)
  },
  h4: {
    fontWeight: 700,
    lineHeight: pxToRem(44),
    fontSize: pxToRem(36)
  },
  h5: {
    fontWeight: 700,
    lineHeight: pxToRem(38),
    fontSize: pxToRem(30)
  },
  h6: {
    fontWeight: 700,
    lineHeight: pxToRem(32),
    fontSize: pxToRem(24)
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: pxToRem(30),
    fontSize: pxToRem(20)
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: pxToRem(28),
    fontSize: pxToRem(18)
  },
  body1: {
    fontWeight: 400,
    lineHeight: pxToRem(24),
    fontSize: pxToRem(16)
  },
  body2: {
    fontWeight: 400,
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14)
  },
  caption: {
    fontWeight: 400,
    lineHeight: pxToRem(18),
    fontSize: pxToRem(12)
  },
  button: {
    fontWeight: 550,
    lineHeight: pxToRem(20),
    fontSize: pxToRem(14.5),
    textTransform: 'none',
    fontFamily: FONT_PRIMARY
  }
} as const;

export default typography;
