import { remark } from 'remark';
import remarkHtml from 'remark-html';

import { toHtml } from 'hast-util-to-html';
import rehypeParse from 'rehype-parse';
import rehypeRemark, { all } from 'rehype-remark';
import remarkStringify from 'remark-stringify';

export const markdownToHtml = (markDowText: string): string => {
  const file = remark().use(remarkHtml).processSync(markDowText);
  return String(file);
};

export const htmlToMarkdown = (htmlText: string): string => {
  const file = remark()
    .use(rehypeParse, { fragment: true })
    .use(rehypeRemark, {
      handlers: {
        u: (h, node) => {
          return h(node, 'html', toHtml(node));
        },
        p: (h, node) => {
          const nodes = all(h, node);
          if (nodes.length > 0) {
            return h(node, 'paragraph', nodes);
          } else {
            return h(node, 'html', toHtml(node.children[0]));
          }
        }
      }
    })
    .use(remarkStringify)
    .processSync(htmlText);

  return String(file);
};
