import { Button, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { EyeClosedIcon, EyeIcon } from 'assets';
import { InputForm } from 'components';
import { LinkButton } from 'components/buttons';
import { LOGIN } from 'constants/auth.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { ROUTES } from 'constants/routes';
import { useAuthService } from 'hooks/services';
import { LoginResult } from 'hooks/services/auth-service/use-auth-service';
import { LoginParam } from 'model';
import PhoneVerificationStepper from 'pages/auth/PhoneVerificationStepper';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ErrorForm } from '../../../labels';

const LoginForm = (): React.ReactElement => {
  const { executeLogin } = useAuthService();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [showPasswordText, setShowPasswordText] = useState<boolean>(false);
  const [mfaConfig, setMfaConfig] = useState<LoginResult>();

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { isDirty, isValid }
  } = useForm<LoginParam>({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { EMAIL, CREATE_ACCOUNT, DONT_HAVE_AN_ACCOUNT, ERRORS, FORGOT_MY_PASSWORD, SIGN_IN } =
    LOGIN;
  const { REQUIRED } = COMMON_VALIDATIONS;

  useEffect(() => {
    // triggering validations
    const subscription = watch((_, { type }) => type === 'change' && trigger(['email']));
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const submit: SubmitHandler<LoginParam> = useCallback(
    async (login: LoginParam) => {
      try {
        const res = await executeLogin(login.email, login.password);
        if (res.mfaNeeded) {
          setMfaConfig(res);
          return;
        }
      } catch (error: any) {
        captureException(error);
        if (error.code === 'auth/user-not-found') {
          setError(ERRORS.INCORRECT_AUTH);
        } else if (error.code === 'auth/wrong-password') {
          setError(ERRORS.INCORRECT_AUTH);
        } else if (error.code === 'auth/invalid-email') {
          setError(ERRORS.INCORRECT_AUTH);
        } else if (error.code === 'auth/too-many-requests') {
          setError(ERRORS.TOO_MANY_REQUESTS);
        } else {
          setError(ERRORS.UNKNOWN_ERROR);
        }
      }
    },
    [executeLogin, setError]
  );

  if (mfaConfig?.mfaNeeded) {
    return (
      <Stack>
        <PhoneVerificationStepper
          auth={mfaConfig.auth}
          error={mfaConfig.error}
          onCancel={() => setMfaConfig(undefined)}
        />
      </Stack>
    );
  }

  return (
    <form data-testid="email-provider" onSubmit={handleSubmit(submit)}>
      <Stack>
        <Typography variant={'h6'}>{SIGN_IN}</Typography>
        <Stack direction={'row'} paddingY={'1.5rem'}>
          <Typography variant="body2" paddingRight={'0.25rem'}>
            {DONT_HAVE_AN_ACCOUNT}
          </Typography>
          <Link
            data-testid="btn-create-account"
            type="button"
            underline="none"
            component="button"
            variant="body2"
            onClick={() => navigate(ROUTES.REGISTER)}
          >
            {CREATE_ACCOUNT}
          </Link>
        </Stack>
        <Stack spacing={2} paddingTop={'2.5rem'}>
          <Controller
            control={control}
            name="email"
            rules={{ required: REQUIRED }}
            render={({ field, fieldState }) => {
              return (
                <InputForm
                  id={field.name}
                  data-testid={field.name}
                  {...field}
                  autoFocus
                  fullWidth
                  hiddenLabel
                  inputProps={{ maxLength: 128 }}
                  error={!!fieldState.error}
                  placeholder={EMAIL}
                  size="small"
                  type="text"
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: REQUIRED }}
            render={({ field, fieldState }) => {
              return (
                <InputForm
                  id={field.name}
                  data-testid={field.name}
                  {...field}
                  fullWidth
                  hiddenLabel
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPasswordText(!showPasswordText)}
                          edge="end"
                        >
                          {showPasswordText ? <EyeIcon /> : <EyeClosedIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={!!fieldState.error}
                  placeholder="Password"
                  size="small"
                  type={showPasswordText ? 'text' : 'password'}
                  variant="outlined"
                  helperText={fieldState.error?.message}
                />
              );
            }}
          />
        </Stack>
        {error && <ErrorForm message={error} />}
        <LinkButton onClick={() => navigate(ROUTES.FORGOT_PASSWORD)} sx={{ alignSelf: 'flex-end' }}>
          {FORGOT_MY_PASSWORD}
        </LinkButton>
        <Button
          type="submit"
          variant="contained"
          size="extraLarge"
          fullWidth
          disabled={!isDirty || !isValid}
        >
          {SIGN_IN}
        </Button>
      </Stack>
    </form>
  );
};

export default LoginForm;
