import { Button, Divider, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { CopyIcon } from 'assets';
import BaseModal from 'components/modals';
import { DAO_ADMIN_LABELS, DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';
import { useNotification } from 'hooks';
import { useEffect, useState } from 'react';
import { CopyBlock, atomOneDark } from 'react-code-blocks';
import { copyToClipboard, toTitleCase } from 'utils';
import ApiKeyGettingStartedModalProps from './ApiKeyGettingStartedModal.props';
import { GettingStartedWrapper } from './ApiKeyGettingStartedModal.style';
import { GettingStartedCodeSamples, SupportedLanguage } from './GettingStartedCodeSamples';

const ApiKeyGettingStartedModal = ({
  onClose,
  isOpen,
  companyId
}: ApiKeyGettingStartedModalProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);
  const { GETTING_STARTED, DONE, COMPANY_ID, CODE_SAMPLE } = DEV_DASHBOARD_LABELS;
  const { COPIED } = DAO_ADMIN_LABELS;
  const { notify } = useNotification();
  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguage>(
    SupportedLanguage.JAVASCRIPT
  );
  const [codeSampleText, setCodeSampleText] = useState<string>(
    GettingStartedCodeSamples[selectedLanguage]
  );

  const cancel = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <BaseModal title={GETTING_STARTED.HEADER} toggle={open} close={cancel} width={450}>
      <GettingStartedWrapper direction={'column'} spacing={2}>
        <Typography variant={'subtitle1'}>{GETTING_STARTED.SUBTITLE}</Typography>
        <Typography variant={'body1'}>{GETTING_STARTED.DESCRIPTION}</Typography>
        <Divider />
        <Stack direction={'column'} spacing={1}>
          <Typography variant={'subtitle1'}>{COMPANY_ID}</Typography>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>{companyId}</Typography>
            <IconButton
              onClick={() => {
                copyToClipboard(companyId);
                notify(COPIED);
              }}
            >
              <CopyIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction={'column'} spacing={1}>
          <Typography variant={'subtitle1'}>{CODE_SAMPLE}</Typography>
          <Typography>{GETTING_STARTED.CODE_SAMPLE_HEADER}</Typography>
          <Select
            labelId={'dao-role-select-label'}
            id={'dao-role-select'}
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value as SupportedLanguage);
              setCodeSampleText(GettingStartedCodeSamples[e.target.value]);
            }}
          >
            {Object.keys(GettingStartedCodeSamples).map((role: string) => {
              return (
                <MenuItem key={role} value={role}>
                  {toTitleCase(role)}
                </MenuItem>
              );
            })}
          </Select>
          <CopyBlock
            copied
            theme={atomOneDark as any}
            text={codeSampleText}
            wrapLongLines={false}
            onCopy={(e) => notify(COPIED)}
            language={selectedLanguage}
            codeBlock={true}
          />
        </Stack>
      </GettingStartedWrapper>
      <Button variant={'contained'} size={'large'} style={{ marginTop: '2rem' }} onClick={cancel}>
        {DONE}
      </Button>
    </BaseModal>
  );
};

export default ApiKeyGettingStartedModal;
