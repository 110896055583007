import { Card, Skeleton, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

const SkeletonCard = (): ReactElement => {
  return (
    <Card>
      <Stack spacing={{ xs: 2, md: 4 }} direction="row" justifyContent="space-between">
        <Stack direction="column" spacing={4} justifyContent="space-between" width={'100%'}>
          <Stack spacing={1}>
            <Typography variant="h6" component="h2">
              <Skeleton variant="rectangular" />
            </Typography>
            <Skeleton animation="wave" height={80} />
          </Stack>
          <Skeleton animation="wave" variant="circular" height={24} width={24} />
        </Stack>
        <Stack direction="column" alignItems="flex-end" justifyContent="space-between">
          <Skeleton animation="wave" variant="circular" height={32} width={32} />
        </Stack>
      </Stack>
    </Card>
  );
};

export default SkeletonCard;
