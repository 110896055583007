import { Box, styled, TextField, TextFieldProps } from '@mui/material';

export const MemberOptionContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.25rem',
  marginLeft: '1rem',
  '& .MuiAvatar-root': {
    height: '1rem',
    width: '1rem'
  },
  span: {
    width: 'auto'
  },
  [theme.breakpoints.up('sm')]: {
    '&  span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '11.875rem',
      whiteSpace: 'nowrap'
    }
  }
}));

export const TextFieldStyled = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    minHeight: '1.8rem',
    '& input': {
      marginRight: '0.5rem',
      padding: '0'
    }
  }
}));
