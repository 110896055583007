import { Avatar, AvatarProps, IconButton, IconButtonProps, styled } from '@mui/material';
import { ElementType } from 'react';

const AvatarStyled = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height'
})<AvatarProps & { width: string; height: string }>(({ theme, width, height }) => ({
  width: `${width}rem`,
  height: `${height}rem`
}));

const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height'
})<IconButtonProps & { component: ElementType; width: string; height: string }>(
  ({ theme, width, height }) => ({
    width: `${width}rem`,
    height: `${height}rem`,
    backgroundColor: theme.palette.button.primary.linkBackground,
    boxSizing: 'border-box',
    border: `.063rem dashed ${theme.palette.background.neutral}`,
    cursor: 'pointer',
    borderRadius: '3.125rem'
  })
);

export { AvatarStyled, IconButtonStyled };
