import { ListItemText, styled } from '@mui/material';

export const StatusIndicatorStyled = styled('div')(({ theme }) => ({
  width: '.4rem',
  height: '.4rem',
  borderRadius: 100,
  backgroundColor: theme.palette.error.main,
  marginLeft: '.5rem'
}));

export const ListItemTextStyled = styled(ListItemText, {
  shouldForwardProp: (prop) => {
    return prop !== 'activePageName' && prop !== 'navId';
  }
})<{ activePageName: string | undefined; navId: string }>(({ theme, activePageName, navId }) => ({
  cursor: 'pointer',
  flex: 'initial',
  minWidth: '67%',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.grey[300]
  },
  '& span': {
    alignItems: 'center',
    color: `${theme.palette.common.black} !important`,
    display: 'flex',
    fontWeight: activePageName === navId ? '750 !important' : '550 !important',
    justifyContent: 'left',
    gap: '0.5rem',
    height: '1.75rem',
    marginRight: '-0.5rem',
    maxWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: '0.5rem',
    '& span': {
      flexDirection: 'row',
      justifyContent: 'center'
    }
  }
}));
