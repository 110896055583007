import { ManageableEntityDTO } from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { ReportedEntityState } from 'types/enum';
import useHttp from 'hooks/use-http';
import IUseDaoReportEntityServiceProps from './use-dao-report-entity-service.props';
import { daoURL } from './use-dao-report-entity-service.url';

const useDaoReportEntityService = (): IUseDaoReportEntityServiceProps => {
  const { put } = useHttp();

  const updateReportEntity = useCallback(
    (
      daoId: string,
      manageableEntityId: string,
      reportEntityId: string,
      status: ReportedEntityState.Accepted | ReportedEntityState.Declined
    ): Promise<AxiosResponse<ManageableEntityDTO>> => {
      return put(
        `${daoURL}/${daoId}/manageable-entities/${manageableEntityId}/reports/${reportEntityId}`,
        { status }
      );
    },
    [put]
  );

  return { updateReportEntity };
};

export default useDaoReportEntityService;
