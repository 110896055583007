import { PaperPinnedIcon, PostsIcon, ProposalsIcon } from 'assets';
import { CreateNewPostButton, ModalLayer } from 'components';
import { POST_FORM_LABELS } from 'constants/ui-labels';
import { useDao, useFeedPage, useRoomMembership } from 'hooks';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { hashTable } from 'types';
import { PostFilterType, UserDaoRole, UserRoomRole } from 'types/enum';
import { compareDaoRoles, compareRoomRoles } from 'utils';
import IconLabel from '../../../../labels/icon-label/IconLabel';
import CreatePostForm from '../post/create-post-form/CreatePostForm';
import ProposalForm from '../proposal/proposal-form/ProposalForm';
import { CreateNewPostProps } from './CreateNewPost.props';

const CreateNewPost = ({ forceUpdates, dao, room }: CreateNewPostProps): ReactElement => {
  const { activeRoom, defaultRoom } = useRoomMembership();
  const { activeFilter, setShowCreatePostForm, showCreatePostForm } = useFeedPage();
  const [hasPostPermissions, setHasPostPermissions] = useState(false);
  const { activeDaoMembership } = useDao();

  useEffect(() => {
    if (!defaultRoom?.active && !activeRoom) return;

    const role =
      UserRoomRole[
        defaultRoom?.id === room.id
          ? (defaultRoom?.role as keyof typeof UserRoomRole)
          : (activeRoom?.role as keyof typeof UserRoomRole)
      ];
    const isRoomMember = compareRoomRoles(UserRoomRole.MEMBER, role);

    const daoRole = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
    const hasAdminAuthority = compareDaoRoles(UserDaoRole.ADMIN, daoRole);
    setHasPostPermissions(isRoomMember && hasAdminAuthority);
  }, [activeRoom, defaultRoom, room.id]);

  const filterTypeForms = hashTable<ReactElement>()
    .add(
      PostFilterType.Updates,
      <CreatePostForm
        daoId={dao.dao.id}
        room={{ ...room.room }}
        userDaoRole={(dao?.role as UserDaoRole) || ''}
        forceUpdates={forceUpdates}
      />
    )
    .add(
      PostFilterType.Posts,
      <CreatePostForm
        daoId={dao.dao.id}
        room={{ ...room.room }}
        userDaoRole={(dao?.role as UserDaoRole) || ''}
        forceUpdates={forceUpdates}
      />
    )
    .add(PostFilterType.Proposals, <ProposalForm daoId={dao.dao.id} room={{ ...room.room }} />);
  const filterTypeLabels = useRef(
    hashTable<ReactNode>()
      .add(
        PostFilterType.Updates,
        <IconLabel
          icon={
            <PaperPinnedIcon
              style={{ height: '1.125rem', width: '1.25rem' }}
              fill="text.secondary"
            />
          }
          label={POST_FORM_LABELS.UPDATES_LABEL}
        />
      )
      .add(
        PostFilterType.Posts,
        <IconLabel
          icon={<PostsIcon style={{ height: '1.125rem', width: '1.25rem' }} />}
          label={POST_FORM_LABELS.POST_LABEL}
        />
      )
      .add(
        PostFilterType.Proposals,
        <IconLabel
          icon={
            <ProposalsIcon style={{ height: '1.125rem', width: '1.25rem' }} fill="text.secondary" />
          }
          label={POST_FORM_LABELS.PROPOSAL_LABEL}
        />
      )
  );

  const toggleForm = () => setShowCreatePostForm(!showCreatePostForm);

  return (
    <>
      {[PostFilterType.Updates, PostFilterType.Posts, PostFilterType.Proposals].includes(
        activeFilter
      ) &&
        hasPostPermissions && (
          <CreateNewPostButton
            label={filterTypeLabels.current.find(activeFilter)}
            onClick={toggleForm}
          />
        )}
      {showCreatePostForm && (
        <ModalLayer toggle={showCreatePostForm} removePadding={true} afterClose={toggleForm}>
          {filterTypeForms.find(activeFilter)}
        </ModalLayer>
      )}
    </>
  );
};

export default CreateNewPost;
