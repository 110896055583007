import { APP_VERSION } from 'constants/app-config';
import { ReactElement, useEffect } from 'react';
import { Outlet } from 'react-router';

const UserLayout = (): ReactElement => {
  useEffect(() => {
    console.log(`App Version: ${APP_VERSION}`);
  }, []);

  return (
    <span style={{ width: '100%', maxWidth: '100rem' }}>
      <Outlet />
    </span>
  );
};

export default UserLayout;
