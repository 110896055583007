import { ReactNode, createContext, useRef } from 'react';
import ReferralPage from '../pages/referral/ReferralPage';

export interface ReferralContextType {}

interface DaoContextProviderProps {
  children: ReactNode;
}

const initialState: ReferralContextType = {};

const ReferralContext = createContext(initialState);

const ReferralContextProvider = ({ children }: DaoContextProviderProps): any => {
  const isReferral = useRef(window.location.href.includes('referral'));

  if (isReferral.current) {
    return <ReferralPage />;
  }

  return <ReferralContext.Provider value={{}}>{children}</ReferralContext.Provider>;
};

export default ReferralContext;
export { ReferralContextProvider };
