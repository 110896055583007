/**
 * This function takes a list of names and joins them into a single string.
 * If the list contains one name, it returns the name.
 * If the list contains two names, it joins them with 'and'.
 * If the list contains more than two names, it joins all names with commas, except for the last name, which is joined with 'and'.
 *
 * @param nameList - An array of strings where each string is a name.
 * @returns A string of names joined by commas and 'and'.
 */
const generateNameListString = (nameList: string[]): string => {
  if (nameList.length <= 1) return nameList.join('');
  if (nameList.length === 2) return nameList.join(' and ');

  const nameListExceptFinal = nameList.slice(0, nameList.length - 1);
  const finalName = nameList.slice(-1);

  return `${nameListExceptFinal.join(', ')} and ${finalName}`;
};

export default generateNameListString;
