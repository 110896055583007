import { Stack, useMediaQuery, useTheme } from '@mui/material';
import * as Sentry from '@sentry/react';
import { MobileHeader } from 'components';
import DrawerNavigation from 'components/drawer-navigation/DrawerNavigation';
import { flaggedFeatures } from 'constants/app-config';
import { FlagsProvider } from 'flagged';
import { useUser } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MobileDrawerNavigation from './components/drawer-navigation/MobileDrawerNavigation';
import { ProgressBarStyle } from './components/feedback/LoadingScreen';
import Toast from './components/widget/toast/Toast';
import MaintenancePage from './pages/maintenance/Maintenance';
import Router, { AuthenticationRouter } from './routes';
import GlobalStyles from './theme/GlobalStyles';

function App(): React.ReactElement {
  const { maintenanceModeText } = useFlags();
  const { userInfo } = useUser();
  const theme = useTheme();
  const location = useLocation();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [isInDao, setIsInDao] = useState<boolean>(false);
  const [activeNavItemName, setActiveNavItemName] = useState('');

  useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery]);

  useEffect(() => {
    setIsInDao(location.pathname.includes('/c/'));
  }, [location]);

  return (
    <>
      {maintenanceModeText ? (
        <MaintenancePage />
      ) : (
        <>
          <GlobalStyles />
          <ProgressBarStyle />
          <Toast />
          <FlagsProvider features={flaggedFeatures}>
            {userInfo ? (
              <Stack
                direction={isMobile && !location.pathname.includes('/c/') ? 'column' : 'row'}
                height={'100%'}
                width={'100%'}
              >
                {!isMobile && isMobile !== undefined && (
                  <DrawerNavigation
                    activeNavItemName={activeNavItemName}
                    setActiveNavItemName={setActiveNavItemName}
                    isInDao={isInDao}
                  />
                )}
                {isMobile && !location.pathname.includes('/c/') && (
                  <>
                    <MobileHeader isInDao={false} />
                    {isMobile && isMobile !== undefined && (
                      <MobileDrawerNavigation
                        activeNavItemName={activeNavItemName}
                        setActiveNavItemName={setActiveNavItemName}
                        isInDao={isInDao}
                      />
                    )}
                  </>
                )}
                <Router />
              </Stack>
            ) : (
              <AuthenticationRouter />
            )}
          </FlagsProvider>
        </>
      )}
    </>
  );
}

export default Sentry.withProfiler(App);
