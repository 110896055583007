import React from 'react';
import TextAreaFormProps from './TextAreaForm.props';
import TextAreaStyled from './TextAreaForm.style';

const TextAreaForm = React.forwardRef<HTMLTextAreaElement, TextAreaFormProps>(
  (props: TextAreaFormProps, ref): React.ReactElement => {
    return <TextAreaStyled data-testid="text-area" {...props} ref={ref} />;
  }
);

export default TextAreaForm;
