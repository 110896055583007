/**
 * @description Truncates strings by taking `truncateSize` at the front and back with the `delimiter` in the middle
 * @param id {string} id to truncate
 * @param truncateSize {number}
 * @param delimiter {string} defaults to '...'
 * @returns {string}
 */
const truncateId = (id: string, truncateSize = 4, delimiter = '...'): string => {
  if (id.length < 10) return id;

  return `${id.slice(0, truncateSize)}${delimiter}${id.slice(-truncateSize)}`;
};

export default truncateId;
