import { Stack, Typography } from '@mui/material';
import { Settings02 } from 'assets/icons/general';
import { AvatarBuilder } from 'components';
import { PROFILE_HEADER_LABELS } from 'constants/profile.labels';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { AvatarSize } from 'types/enum';
import { UserProfileHeaderProps } from '../user-profile-header/UserProfileHeader.props';
import { StyledIconButton } from './OwnershipHeader.style';

const OwnershipHeader = ({
  showAvatar = false,
  userInfo
}: UserProfileHeaderProps): React.ReactElement => {
  const { MORNING, AFTERNOON, EVENING } = PROFILE_HEADER_LABELS;
  const navigate = useNavigate();

  const generateGreeting = useCallback((): string => {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 4 && hour < 11) {
      return MORNING;
    } else if (hour >= 11 && hour < 16) {
      return AFTERNOON;
    } else {
      return EVENING;
    }
  }, []);

  return (
    <Stack direction={'row'} justifyContent={'space-between'} margin={'2rem 0 4rem'}>
      <Stack direction={'row'} alignItems={'center'}>
        {showAvatar && (
          <AvatarBuilder
            src={userInfo?.profileThumbnail}
            id={'profile-avatar'}
            alt={userInfo?.username}
            size={AvatarSize.LARGE}
            style={{ marginRight: '0.75rem' }}
          />
        )}
        <Stack direction={'column'}>
          <Stack direction={'row'}>
            <Typography variant={'h6'}>
              {generateGreeting()} {userInfo?.firstName} {userInfo?.lastName}
            </Typography>
          </Stack>
          <Typography variant={'body2'} sx={{ marginTop: '0.5rem', color: 'secondary.main' }}>
            {userInfo?.profile.bio}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'}>
        <StyledIconButton
          variant={'outlined'}
          onClick={() => {
            userInfo &&
              navigate(`${ROOT_ROUTE.USER(userInfo.id)}/${ROUTES.USER_PROFILE_SETTINGS()}`);
          }}
        >
          <Settings02 height={20} width={20} />
        </StyledIconButton>
      </Stack>
    </Stack>
  );
};

export default OwnershipHeader;
