import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DivisionOperatorBox(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon width="36" height="36" viewBox="0 0 36 36" {...props}>
      <rect
        x="1"
        y="1"
        width="34"
        height="34"
        rx="5"
        fill="#181818"
        stroke="#181818"
        strokeWidth="2"
      />
      <path d="M26 18H10" stroke="#FDFDFD" strokeWidth="2" strokeLinecap="round" />
      <circle cx="18" cy="12" r="1.5" fill="#FDFDFD" stroke="#FDFDFD" />
      <circle cx="18" cy="24" r="1.5" fill="#FDFDFD" stroke="#FDFDFD" />
    </SvgIcon>
  );
}
