import Check from '@mui/icons-material/Check';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface AsyncButtonProps extends ButtonProps {
  onClick: () => Promise<void>;
  isSuccessful: boolean;
  duration?: number;
  children: React.ReactNode;
  disabled: boolean;
  onCheckMarkAnimationComplete?: () => void;
}

const AsyncButton = ({
  onClick,
  isSuccessful,
  duration = 1000,
  children,
  disabled,
  onCheckMarkAnimationComplete,
  ...buttonProps
}: AsyncButtonProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);

  useEffect(() => {
    if (isSuccessful) {
      setShowCheckmark(true);
      setTimeout(() => {
        setShowCheckmark(false);
        onCheckMarkAnimationComplete && onCheckMarkAnimationComplete();
      }, duration);
    }
  }, [isSuccessful, duration]);

  const handleClick = async () => {
    setLoading(true);
    try {
      await onClick();
    } catch (error) {
      console.error('Operation failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      disabled={disabled || loading}
      size={'large'}
      onClick={handleClick}
      variant="contained"
      fullWidth
      {...buttonProps}
    >
      {loading ? <CircularProgress size={24} /> : showCheckmark ? <Check /> : children}
    </Button>
  );
};

export default AsyncButton;
