import { Divider, Stack, Typography } from '@mui/material';
import { BaseSectionHeaderProps } from './BaseSectionHeader.props';

const BaseSectionHeader = ({ title, subtitle }: BaseSectionHeaderProps): React.ReactElement => {
  return (
    <Stack direction={'column'} spacing={2.5}>
      <Stack direction={'column'} spacing={0.5}>
        <Typography variant={'subtitle2'} fontWeight={'bold'}>
          {title}
        </Typography>
        <Typography variant={'body2'} color={'secondary.main'}>
          {subtitle}
        </Typography>
      </Stack>
      <Divider />
    </Stack>
  );
};

export default BaseSectionHeader;
