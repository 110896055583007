import { MenuItem, Stack, TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import {
  DaoDTO,
  PatronageActivityDTO,
  PatronageActivityUpdatePayload
} from '@piefi-platform/types-lib';
import { TagChip } from 'components';
import { ActivityAwardLabel } from 'components/labels';
import { EllipsisMenu } from 'components/menus';
import { DeletePatronageActivityModal } from 'components/modals';
import { DAO_ADMIN_LABELS } from 'constants/dao-admin-nav.labels';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useDao, useNotification } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BRAND } from 'theme/palette';
import { copyToClipboard, truncateId } from 'utils';
import PatronageActivityStatus from '../patronage-activity-item/patronage-activity-status/PatronageActivityStatus';
import PatronageActivityTableRowProps from './PatronageActivityTableRow.props';
import { usePatronageActivityService } from 'hooks/services';
import { Pin02 } from 'assets/icons/general';

const PatronageActivityTableRow = ({
  activity,
  onActivityDeleted,
  highlightingEnabled
}: PatronageActivityTableRowProps): React.ReactElement => {
  const { currentDao } = useDao();
  const { update } = usePatronageActivityService();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { notify } = useNotification();
  const theme = useTheme();
  const TAG_LIMIT = 2;
  const CONTROL_PANEL_NAME_LENGTH = 24;
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { COPY_ID, EDIT, COPIED, DELETE, MANUAL_AWARD, HIGHLIGHT, UN_HIGHLIGHT } = DAO_ADMIN_LABELS;
  const [stateActivity, setStateActivity] = useState(activity);

  useEffect(() => {
    setStateActivity(activity);
  }, [activity]);

  const copyId = useCallback(
    (activity: PatronageActivityDTO) => {
      if (!activity.id) return;
      copyToClipboard(stateActivity.id);
      notify(COPIED);
    },
    [COPIED, notify]
  );

  const toggleEdit = useCallback((activity: PatronageActivityDTO) => {
    const subDomain = (currentDao as DaoDTO).whiteLabelSubDomain;
    navigate(
      `${ROOT_ROUTE.DAO(subDomain)}/${ROUTES.DAO_ADMIN_ACTIVITY_FORM(subDomain, stateActivity.id)}`,
      {
        state: { redirectLocation: currentUrl }
      }
    );
  }, []);

  const handleDeleted = (itemDeleted?: boolean) => {
    setShowDeleteModal(false);
    if (itemDeleted) {
      onActivityDeleted();
    }
  };

  const handleManualAward = useCallback(() => {
    navigate(
      `${ROOT_ROUTE.DAO(
        currentDao.whiteLabelSubDomain
      )}/${ROUTES.DAO_ADMIN_ACTIVITY_MANUAL_AWARD_PAGE(stateActivity.id)}`
    );
  }, [activity]);

  const handleActivityPin = useCallback(async () => {
    try {
      const res = await update(stateActivity.id, currentDao.id, {
        isHighlighted: !stateActivity.isHighlighted
      } as PatronageActivityUpdatePayload);

      setStateActivity(res.data);
    } catch (error) {}
  }, [stateActivity]);

  return (
    <>
      <DeletePatronageActivityModal
        onClose={(itemDeleted?: boolean) => handleDeleted(itemDeleted)}
        daoId={currentDao.id}
        activityId={stateActivity.id}
        isOpen={showDeleteModal}
      />
      <TableRow key={`${stateActivity.id}`}>
        <TableCell align="left" width={'30%'}>
          <Stack direction="row" alignItems="center" spacing={1}>
            {stateActivity.isHighlighted && <Pin02 height={14} width={14} />}
            <Tooltip title={stateActivity.name}>
              <Typography
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '75%'
                }}
              >
                {stateActivity.name.slice(0, CONTROL_PANEL_NAME_LENGTH)}
              </Typography>
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell align="left" width={'10%'}>
          <Stack direction="row" alignItems="center">
            {truncateId(stateActivity.id)}
            <PatronageActivityStatus connected={stateActivity.connected} />
          </Stack>
        </TableCell>
        <TableCell align="left" width={'20%'}>
          {stateActivity.tags?.slice(0, TAG_LIMIT).map((tag) => (
            <TagChip key={tag.id} id={tag.id} label={tag.name} backgroundColor={tag.color} />
          ))}
          {stateActivity.tags && stateActivity.tags?.length > TAG_LIMIT && (
            <TagChip
              key="overflow-tag"
              label={`+${(stateActivity.tags?.length || 0) - TAG_LIMIT}`}
              backgroundColor={BRAND.grey[500]}
              title={stateActivity.tags
                ?.slice(TAG_LIMIT, stateActivity.tags.length)
                .map((tag) => tag.name)
                .join(', ')}
            />
          )}
        </TableCell>
        <TableCell align="left" width={'20%'}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction={'row'} alignItems={'center'}>
              <ActivityAwardLabel
                activity={activity}
                typographyProps={{
                  variant: 'body2',
                  marginLeft: '0.25rem',
                  overflow: 'hidden'
                }}
              />
            </Stack>
            <EllipsisMenu style={{ marginLeft: '1rem' }}>
              <MenuItem onClick={() => toggleEdit(activity)}>{EDIT}</MenuItem>
              <MenuItem onClick={() => copyId(activity)}>{COPY_ID}</MenuItem>
              <MenuItem onClick={() => handleManualAward()}>{MANUAL_AWARD}</MenuItem>
              {highlightingEnabled && (
                <MenuItem onClick={() => handleActivityPin()}>
                  {stateActivity.isHighlighted ? UN_HIGHLIGHT : HIGHLIGHT}
                </MenuItem>
              )}
              <MenuItem
                onClick={() => setShowDeleteModal(true)}
                style={{ color: theme.palette.brand.red[500] }}
              >
                {DELETE}
              </MenuItem>
            </EllipsisMenu>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
export default PatronageActivityTableRow;
