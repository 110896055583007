import { Button, Link, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import BaseModal from 'components/modals';
import { DAO_ACTIONS_LABELS } from 'constants/dao-actions.labels';
import SeasonZeroModalProps from './SeasonZeroModal.props';

const SeasonZeroModal = ({ open, onClose }: SeasonZeroModalProps): React.ReactElement => {
  const {
    SEASON_ZERO_MODAL_HEADER,
    SEASON_ZERO_MODAL_BUTTON,
    SEASON_ZERO_MODAL_TEXT1,
    SEASON_ZERO_MODAL_TEXT2,
    SEASON_ZERO_MODAL_TEXT3,
    SEASON_ZERO_MODAL_SUPPORT,
    MODAL_LIST_TITLE,
    MODAL_LIST_ITEM1,
    MODAL_LIST_ITEM2,
    MODAL_LIST_ITEM3
  } = DAO_ACTIONS_LABELS;

  return (
    <BaseModal toggle={open} close={onClose} width={475}>
      <Stack direction={'column'} gap={2}>
        <Typography variant="h6" fontWeight={700}>
          {SEASON_ZERO_MODAL_HEADER}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {SEASON_ZERO_MODAL_TEXT1}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {SEASON_ZERO_MODAL_TEXT2}
        </Typography>
        <Stack direction={'column'}>
          <Typography variant="body2" color="text.secondary">
            {MODAL_LIST_TITLE}
          </Typography>
          <List sx={{ bgcolor: 'background.paper', listStyleType: 'disc', marginLeft: '2rem' }}>
            <ListItem sx={{ display: 'list-item', padding: '0' }}>
              <ListItemText
                primary={
                  <Typography variant="body2" color="text.secondary">
                    {MODAL_LIST_ITEM1}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: '0' }}>
              <ListItemText
                primary={
                  <Typography variant="body2" color="text.secondary">
                    {MODAL_LIST_ITEM2}
                  </Typography>
                }
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: '0' }}>
              <ListItemText
                primary={
                  <Typography variant="body2" color="text.secondary">
                    {MODAL_LIST_ITEM3}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant="body2" color="text.secondary">
            {SEASON_ZERO_MODAL_TEXT3}
            <Link
              href="mailto:support@awsm.com"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {SEASON_ZERO_MODAL_SUPPORT}
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Button
        fullWidth={false}
        color="primary"
        onClick={onClose}
        size="large"
        variant="contained"
        style={{ marginTop: '3rem' }}
      >
        {SEASON_ZERO_MODAL_BUTTON}
      </Button>
    </BaseModal>
  );
};

export default SeasonZeroModal;
