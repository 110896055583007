enum Currency {
  USD = 'USD'
}

enum CryptoCurrency {
  DAO_POINTS = 'DAO_POINTS'
  // TODO: Wait for BE to implement more crypto currencies
  // HBAR = 'HBAR'
  // BTC_TEST = 'BTC_TEST',
  // ETH_TEST = 'ETH_TEST',
}

const formatCurrency = (currency: CryptoCurrency | Currency): string => {
  switch (currency) {
    case CryptoCurrency.DAO_POINTS:
      return 'Points';
    // TODO: Implement more crypto currencies
    // case CryptoCurrency.BTC_TEST:
    //   return 'BTC (Test)';
    // case CryptoCurrency.ETH_TEST:
    // return 'ETH (Test)';
    default:
      return currency;
  }
};

export default Currency;
export { formatCurrency, CryptoCurrency };
