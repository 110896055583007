import { styled } from '@mui/system';
import { Select } from '@mui/material';

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'removeBorder'
})<{ removeBorder: boolean }>(({ theme, removeBorder }) => ({
  height: '3rem',
  ...(removeBorder && {
    '.MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none' // Targets the border of the OutlinedInput component
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none' // Removes border on hover
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none' // Removes border when the Select is focused
    }
  })
}));

export { StyledSelect };
