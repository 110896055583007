import { Button, Link, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { TOS_LABELS } from 'constants/tos-modal.labels';
import BaseModal from '../base-modal/BaseModal';
import { useUserService } from 'hooks/services';
import { useNotification } from 'hooks';

interface BaseModalProps {
  children?: React.ReactNode;
  open: boolean;
  onConfirm: () => void;
}

const TosModal = ({ onConfirm, open }: BaseModalProps): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { agreeToTos } = useUserService();
  const { notify } = useNotification();

  const handleKeyPress = (key: string): void => {
    // https://www.w3.org/TR/uievents-key/#keys-modifier
    if (key === 'Enter') onConfirm();
  };

  const handleTos = useCallback(async () => {
    try {
      setLoading(true);
      await agreeToTos();
      onConfirm();
    } catch (error) {
      notify(TOS_LABELS.ERROR_ACCEPTING_TOS);
    }
  }, [agreeToTos, notify, onConfirm]);

  return (
    <BaseModal toggle={open} width={450} hideCloseButton={true}>
      <Stack onKeyDown={({ key }) => handleKeyPress(key)}>
        <Stack spacing={2}>
          <Typography variant="h6" fontSize={'1.5rem'} fontWeight={700}>
            {TOS_LABELS.TITLE}
          </Typography>
          <Typography variant="body1">{TOS_LABELS.DESCRIPTION}</Typography>
          <Link
            data-testid="btn-create-account"
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            href="https://upside.coop/terms-of-service"
            component="a"
            variant="body2"
          >
            {TOS_LABELS.LINK}
          </Link>
          <Typography variant="body1">{TOS_LABELS.QUESTIONS}</Typography>
          <Typography variant="body1">{TOS_LABELS.APPRECIATION}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} marginTop="4rem">
          <Button
            style={{ backgroundColor: 'black', textTransform: 'none', color: 'white' }}
            data-testid="modal-confirm-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleTos}
            fullWidth={false}
            disabled={loading}
          >
            {TOS_LABELS.BUTTON_TEXT}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default TosModal;
