export const NAV_LABELS = {
  COLLECTIVE_EMPTY: 'Collectives you join are displayed here. Start earning ownership!',
  HELP: 'Help',
  SETTINGS: 'Settings',
  MORE: 'More',
  LOGOUT: 'Logout'
};

export const DIRECT_ALLOCATION_LABELS = {
  SEND: 'Send Equity'
};
