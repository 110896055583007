import { PatronageActivity, PatronageActivityDTO } from '@piefi-platform/types-lib';
import { PatronageActivityContextType } from 'model';
import { ActivityFormStep } from 'model/contexts/patronage-activity-context-type.model';
import { createContext, useCallback, useMemo, useState } from 'react';
import { Props } from 'types';
import { ActivityFormTypeValue } from '../types/enum';

const initialState: PatronageActivityContextType = {
  activityStepperActive: false,
  activeFormStep: ActivityFormStep.BLANK,
  activityFormType: '' as any,

  setActivityFormType: (_: ActivityFormTypeValue): void => {},
  setActivityStepperActive: (_: boolean): void => {},

  setActiveFormStep: (_: ActivityFormStep): void => {},
  setActivityToEdit: (act: PatronageActivity | undefined): void => {},

  setActivityForManualDistribution: (activity: PatronageActivityDTO | undefined) => {}
};

const PatronageActivityContext = createContext(initialState);

const PatronageActivityProvider = ({ children }: Props): React.ReactElement => {
  const [activityStepperActive, setActivityStepperActive] = useState<boolean>(
    initialState.activityStepperActive
  );
  const [activityFormType, setActivityFormType] = useState<ActivityFormTypeValue>(
    initialState.activityFormType
  );
  const [activeFormStep, setActiveFormStep] = useState<ActivityFormStep>(
    initialState.activeFormStep
  );
  const [activityToEdit, setActivityToEdit] = useState<PatronageActivity>();
  const [activityForManualDistribution, setActivityForManualDistribution] =
    useState<PatronageActivityDTO>();

  const updateActivityStepperActive = useCallback((isFormActive: boolean) => {
    setActivityStepperActive(isFormActive);
  }, []);

  const updateActiveFormStep = useCallback((activeStep: ActivityFormStep) => {
    setActiveFormStep(activeStep);
  }, []);

  const providerValue = useMemo(
    () => ({
      activityStepperActive,
      activeFormStep,
      activityFormType,
      activityForManualDistribution,
      setActivityForManualDistribution,
      setActivityFormType,
      setActivityStepperActive: updateActivityStepperActive,
      setActiveFormStep: updateActiveFormStep,
      activityToEdit,
      setActivityToEdit
    }),
    [
      activityStepperActive,
      activeFormStep,
      updateActivityStepperActive,
      updateActiveFormStep,
      activityToEdit,
      activityForManualDistribution,
      setActivityForManualDistribution,
      activityFormType
    ]
  );

  return (
    <PatronageActivityContext.Provider value={providerValue}>
      {children}
    </PatronageActivityContext.Provider>
  );
};

export default PatronageActivityProvider;
export { PatronageActivityContext };
