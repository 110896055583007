import { DaoRequestStatus } from 'types/enum';
import { DaoRequestState } from 'types/enum/dao-request-state';

export const DAO_ADMIN = {
  ITEMS_TO_REVIEW: 'Items To Review',
  FILTER: 'Filter',
  MARKETPLACE: 'Marketplace'
};

export const DAO_UPDATE_PERMISSIONS = {
  PERMISSIONS_MODAL_TITLE: 'Change Member Role',
  PERMISSIONS_MODAL_DESC: "This will change the user's role in the collective",
  EXTERNAL_REFERENCE: {
    CHANGE_EXTERNAL_ID: 'Change Shopify Code',
    CHANGE_EXTERNAL_ID_DESC: 'This will change the shopify code in the collective',
    SUCCESS: 'Shopify code updated',
    ERROR: 'Error updating shopify code',
    DUPLICATE_ERROR: 'Shopify code already in use',
    FORM_VALIDATION: {
      REQUIRED: 'Shopify code is required'
    }
  },
  TEMPORARY_INVITATION: {
    UPDATE_INVITATION: 'Update Invitation',
    SUCCESS: 'Invitation updated',
    ERROR: 'Error updating invitation',
    UPDATE_INVITATION_DESC:
      'Update the Shopify Code or the role that gets assigned when the user joins the collective',
    CHANGE_SHOPIFY_CODE: 'Change Shopify Code',
    CHANGE_ROLE_ON_ACCEPT: 'Change Role on Accept',
    DELETED_SUCCESSFULLY: 'Invitation deleted successfully',
    ARE_YOU_SURE_DELETE: 'Are you sure you want to delete this invitation?',
    INVITATIONS_SENT: 'Invitations Sent',
    ERROR_SENDING: 'Error sending invitations'
  }
};

export const DAO_SETUP = {
  PROFILE_INFO: 'Profile Info'
};

export const DAO_JOIN = {
  JOIN: 'Join',
  ALREADY_A_MEMBER: 'You are already a member'
};

export const REQUEST_STATUS_TO_LABEL = {
  [DaoRequestStatus.Accepted.toString()]: DaoRequestState.Approved,
  [DaoRequestStatus.Pending.toString()]: DaoRequestState.Pending,
  [DaoRequestStatus.Declined.toString()]: DaoRequestState.Private
};

export const DAO_EDIT = {
  ACCOUNT: 'Account',
  BASIC_INFO: 'Basic Info',
  CHANGE_AVATAR: 'Change Avatar',
  COMMUNITY_NAME: 'Collective Name',
  WIKI: 'Wiki',
  BIO: 'Bio',
  SOCIAL_LINKS: 'Social Info',
  PLEASE_INCLUDE_ENTIRE_LINK: 'Lets get connected!',
  TWITTER: 'Twitter Handle',
  LINKEDIN: 'LinkedIn Handle',
  WEBSITE: 'Website URL',
  DISCORD: 'Discord Username',
  DISCORD_LINK: 'Discord Invite Link',
  INSTAGRAM: 'Instagram Handle',
  SAVE: 'Save',
  LOGOUT: 'Sign out of account',
  CANCEL: 'Cancel',
  ERROR_UPDATING_PROFILE: 'Error updating profile',
  DAO_PROFILE_UPDATED: 'Collective profile updated',
  INVALID_URL: 'Please enter a valid URL',
  INVALID_IMAGE_ERROR: 'Please upload a valid image file (png, jpg, jpeg, or gif)'
};

export const EDIT_DAO_ABOUT = {
  EDIT_DAO_ABOUT_TITLE: 'Edit About',
  DAO_ABOUT_UPDATED: 'About updated',
  ERROR_UPDATING_DAO_ABOUT: 'Error updating About'
};
