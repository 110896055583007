import { numberFormatter, usdFormatter } from '../utils';
import generateNameListString from '../utils/helper/generate-name-list-string/generate-name-list-string';

export const DAO_ADMIN_LABELS = {
  ADMIN: 'Admin',
  ACTIVE_PATRONAGE_ACTIVITIES: 'Active Activities',
  CONTROL_PANEL_TEXT:
    'Add, remove, and manage the various activities across the collective. They can be customized to your needs through Connect. Please see our documentation or contact support if you need assistance.',
  SAVE: 'Save',
  EDIT: 'Edit',
  CANCEL: 'Cancel',
  COPY_ID: 'Copy ID',
  COPY_LINK: 'Copy Link',
  DELETE: 'Delete',
  MANUAL_AWARD: 'Manual Award',
  HIGHLIGHT: 'Highlight',
  UN_HIGHLIGHT: 'Un-Highlight',
  COPIED: 'Copied',
  REQUIRED_FIELD: (fieldName: string): string => `${fieldName} is required`,
  ONLY_INTEGERS: 'only positive integers allowed',
  MIN_MAX_LENGTH: (fieldName: string, size: 'min' | 'max', threshold: number): string =>
    `${fieldName} has a ${size} length of ${threshold}`,
  MIN_POINT_AMOUNT: (minAmount: number): string => `amount must be at least ${minAmount}`,
  MAX_AMOUNT: (maxAmount: number): string =>
    `amount must be less than ${numberFormatter.baseFormatter(maxAmount)}`,
  MAX_BUDGET: (maxAmount: number): string =>
    `You only have ${usdFormatter.format(maxAmount)} remaining in your collective pool.`,
  CONNECT_STATUS: (connected?: boolean): string =>
    `This Activity has ${connected ? '' : 'not'} produced events`
};

export const INSIGHTS_DASHBOARD = {
  MEMBERSHIP: 'Membership',
  TOTAL_AWARDED: 'Total Awarded',
  TOTAL_DISTRIBUTED: 'Total Distributed',
  TOP_MEMBERS: 'Top Members',
  EVENTS: 'Events',
  BITS: 'Bits'
};

export const DEV_DASHBOARD_LABELS = {
  COPY_CREATED:
    'Your key has been generated! Now, copy your API key to your application. Remember, this is the only time that you will be able to see this key. After you close this screen, you will not be able to view it in plain text.',
  DONE: 'Done',
  CREATE: 'Generate Key',
  CANCEL: 'Cancel',
  COPIED: 'Copied',
  CODE_SAMPLE: 'Code Sample',
  COMPANY_ID: 'Company ID',
  GET_STARTED: 'Get Started',
  MORE_INFO: 'More Information',
  DIVE_DEEP: 'Dive Deep',
  SUBMIT_EDIT: 'Edit',
  EDIT_DESC: 'Edit Description',
  DEACTIVATE: 'Deactivate',
  DEACTIVATE_HEADER: 'Are you sure?',
  DEACTIVATE_WARNING:
    'Deactivating an active API key may disrupt existing integrations and services relying on it. Please ensure you fully understand the consequences before proceeding with deactivation.',
  THEAD_CREATOR: 'Created By',
  THEAD_DESC: 'Description',
  THEAD_DATE: 'Created On',
  THEAD_STATUS: 'Status',
  CREATE_KEY: 'Generate New',
  TAB_TITLE: 'All API Keys',
  NEW_API_KEY: 'New API Key',
  DEV_DASHBOARD_HEADER:
    'Add, remove, and manage the various activities across the collective. They can be customized to your needs through Connect. Please see our documentation or contact support if you need assistance.',
  DEV_DASHBOARD_TITLE: 'Dev Tools',
  DESCRIPTION_NAME: `Please enter a descriptive name for your API key. Make sure it is identifiable in a way
  that you remember what it is for. On the next step your key will be generated and you
  will be able to copy it for your use.`,
  STEP_STATE: (currentState: number, totalStates: number): string =>
    `Step ${currentState} of ${totalStates}`,
  STARTED_DESC: `Ready to jump in? We’ve got you covered with the info and code you need to get
  started using our system.`,
  READ_DOCS: 'Read full documentation',
  LOOKING_FOR_MORE: `
  Need more? Explore our documentation. For suggestions or other needs, feel free to contact us.`,
  FORMS: {
    DESCRIPTION_PLACEHOLDER: `e.g., 'Website Analytics', 'Mobile App Integration', etc.`
  },
  GETTING_STARTED: {
    HEADER: 'Getting Started',
    SUBTITLE:
      'Connect your system to ours. Set up a custom integration. Whatever you want to connect, you can do it.',
    DESCRIPTION: `To connect and utilize your API keys, you will need to integrate them into your applications, services, or platforms. These keys serve as secure credentials to access and interact with our APIs, enabling seamless data exchange and functionality.`,
    CODE_SAMPLE_HEADER:
      'For example purposes only. Replace values with your own Company ID and API Key data.'
  }
};

export const DOCUMENT_PAGE = {
  HEADER: 'Documents for your collective'
};

export const DAO_ADMIN_PAGES = {
  ACTIVITIES: 'Activities',
  INSIGHTS_DASHBOARD: 'Insights',
  DOCUMENTS: 'Documents',
  MANAGE: 'Manage',
  MEMBERS: 'Members',
  DEVELOPERS: 'Developers',
  SETTINGS: 'Settings'
};

export const MANUAL_DISTRIBUTION = {
  MANUAL_ACTIVITY_AWARD: 'Manual Activity Award',
  FOR_SECTION: {
    HEADER: 'For',
    DESCRIPTION: 'This is the Activity that the Event will be associated with.'
  },
  NOTE_SECTION: {
    HEADER: 'For',
    DESCRIPTION:
      'Leave a Note on this Transaction so you and the Member will know what the Bits are for. (Optional)',
    PLACEHOLDER: 'This is for...'
  },
  TO_SECTION: {
    HEADER: 'To',
    DESCRIPTION: 'Who would you like to send Bits to?'
  },
  AMOUNT_SECTION: {
    HEADER: 'How Much',
    DESCRIPTION:
      'How many total dollars is the award worth? The percentage will be take from the amount you provide.'
  },
  SEND: 'Send',
  CANCEL: 'Cancel'
};

export const DIRECT_ALLOCATION = {
  FORM: {
    SECTION_HEADER: 'Send Equity'
  },
  CONFIRM: {
    SECTION_HEADER: 'Confirm Details',
    CONFIRMATION_ACTION: 'Confirm Send',
    OVERSPEND: 'Desired amount would result in overspend',
    BALANCE: 'Available Balance'
  },
  CONGRATULATIONS: {
    SECTION_HEADER: 'Success!',
    FINALIZE_ACTION: 'Done',
    SUMMARY: (memberNames: string[]): string =>
      `You sent equity to ${generateNameListString(memberNames)}`
  }
};
export const DIRECT_ALLOCATION_DISCLAIMER = `By clicking ${DIRECT_ALLOCATION.CONFIRM.CONFIRMATION_ACTION} you acknowledge that the recipients have fulfilled requirement
set by the cooperative and that issued bits are non-refundable.`;

export enum AWARD_TYPE {
  FLAT = 'Flat',
  PERCENTAGE = 'Percentage',
  CURVED = 'Curved'
}

export enum ACTIVITY_SELECTOR_TYPE {
  ASSIGN = 'Assign',
  AGREEMENT = 'Agreement',
  AFFILIATE_LINK = 'Affiliate Link',
  NONE_AVAILABLE = 'No more available'
}

export const PATRONAGE_ACTIVITY_FORM = {
  DESCRIPTION: 'Make your equity powerful with dynamic, automated distribution options',
  AFFILIATE_RESTRICTION: 'Requires flat award',
  AFFILIATE_RESTRICTION_EDIT: 'Can only be added when creating a new activity',
  ACTIVITY_SELECTORS: {
    HEADER: 'Add to Activity:',
    ASSIGN: 'Assign',
    AGREEMENT: 'Agreement',
    AFFILIATE_LINK: 'Affiliate Link'
  },
  DETAILS: {
    NAME_PLACEHOLDER: 'Activity Name',
    DESCRIPTION_PLACEHOLDER: 'Add details about the activity.'
  },
  AWARD: {
    HEADER: 'Award',
    DETAILS: 'Award Details',
    DISTRIBUTION_TYPE: 'Distribution Type',
    FLAT: {
      HEADER: AWARD_TYPE.FLAT,
      DESCRIPTION: 'Awards are the same value each time',
      DESCRIPTOR: 'Award Amount',
      DESCRIPTOR_DESCRIPTION:
        'The actual value of equity that you want to award for completing this Activity',
      INPUT_LABEL: 'Dollar Value',
      INPUT_PLACEHOLDER: '0'
    },
    PERCENTAGE: {
      HEADER: AWARD_TYPE.PERCENTAGE,
      DESCRIPTION: 'Awards vary based on provided value',
      DESCRIPTOR: 'Award Amount',
      DESCRIPTOR_DESCRIPTION:
        'The actual value of equity that you want to award for completing this Activity',
      INPUT_LABEL: 'Percent',
      INPUT_PLACEHOLDER: '0'
    },
    CURVED: {
      HEADER: AWARD_TYPE.CURVED,
      DESCRIPTION: 'Awards follow a structured curve of dynamic values'
    },
    BUDGET: {
      DESCRIPTOR: 'Award Distribution Limits',
      DESCRIPTOR_DESCRIPTION:
        'Set limits for when you want this Activity to automatically stop distributing awards.',
      DESCRIPTOR_DESCRIPTION_CURVED:
        'Set the baseline metrics that will determine how the curve will be generated and the relative values of the associated awards. *Pro tip: a high dollar value and a low award value will create a steep distribution curve. A high dollar value and high award value will be much less steep. Find the best ratio for your needs.',
      DOLLAR_INPUT_LABEL: 'Total dollars to be distributed',
      EVENT_THRESHOLD_LABEL: 'Total number of awards to be distributed',
      PLACEHOLDER: '0'
    }
  },
  ACTIVITY_ADDED: '1 new activity added!',
  ACTIVITY_UPDATED: 'Activity updated!',
  ADD_ACTIVITY: 'Add Activity',
  EDIT_ACTIVITY: 'Edit Activity',
  ADD_NEW: 'Add New',
  ADD: 'Add',
  ADD_PATRONAGE_ACTIVITY: 'Add New Activity',
  BUILD_CUSTOM_ACTIVITY: 'Build Custom Activity',
  BUILD_CUSTOM_ACTIVITY_DESCRIPTION:
    'Using the Connect service and our SDK, set up any custom interaction that you want. Need help? Read our documentation here.',
  CHOOSE_PLATFORM: '1. choose template',
  CHOOSE_ACTIVITY: '2. activity details',
  CUSTOM: 'Custom',
  REDIRECT: 'Redirect Link',
  HOW_MANY_POINTS: ' How many bits do you want to offer for this activity?',
  GIVE_ACTIVITY_NAME: ' Give your activity a name',
  TITLE: 'Select the activities you want to give ownership for',
  TITLE_SECTION: {
    HEADER: 'Title',
    DESCRIPTION:
      'This is the name of the activity that will be displayed to members of the collective.',
    PLACEHOLDER: 'e.g. Social Media Engagement'
  },
  DESCRIPTION_SECTION: {
    HEADER: 'Description',
    DESCRIPTION:
      'Something short and sweet in case your activity needs just a little bit more information. This will also be displayed to members. (Optional).',
    PLACEHOLDER: 'e.g. For every like you’ll get bits!'
  },
  COLOR_SECTION: {
    HEADER: 'Color',
    DESCRIPTION: 'Helps identify specific activities throughout the platform.'
  },
  POINT_AWARD_VALUE_SECTION: {
    HEADER: 'Dollar Value',
    DESCRIPTION: 'The amount in dollars you want to send',
    PLACEHOLDER: 'e.g. 1, 2, 10, 150...'
  },
  ALLOCATION_AGREEMENTS: {
    HEADER: 'Agreement',
    DESCRIPTION:
      'Add agreements or other important documents necessary for the activity to be completed.',
    TOOL_TIP: (creatorName: string, dateString: string): string =>
      `Uploaded by ${creatorName} on ${new Date(dateString).toLocaleString('en-us', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      })}`,
    ON: ' on ',
    UPLOADED: 'Uploaded By: '
  },
  VISIBILITY_SECTION: {
    HEADER: 'Assign',
    DESCRIPTION: 'Who should see and have access to this activity? Select that here.'
  },
  REDIRECT_SECTION: {
    HEADER: 'Affiliate Link',
    REDIRECT_URL: 'Redirect URL',
    DESCRIPTION: 'Where would you like users directed to when following a tracked-link?',
    PLACEHOLDER: 'https://www.your-redirect-link.com'
  },
  DELETE_MODAL: {
    DELETE_HEADER: 'Delete Activity',
    ARE_YOU_SURE: 'Are you sure you want to delete this activity?',
    SUCCESS: 'Activity successfully deleted.',
    FAILURE: 'Failed to delete activity.'
  }
};
