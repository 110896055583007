import { Box, Button, styled } from '@mui/material';

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[0]
}));

const ContainerProfile = styled(Box)(({ theme }) => ({
  '& form': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.125rem'
    }
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(6),
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      width: '100%'
    },
    '& .MuiFormControl-root': {
      '& .MuiTypography-root': {
        marginBottom: '0.5rem'
      }
    }
  }
}));

const FileButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[0],
  border: `1px solid ${theme.palette.grey[800]}`,
  color: theme.palette.grey[800],
  height: '2rem',
  '&:hover': {
    backgroundColor: theme.palette.grey[400]
  }
})) as typeof Button;

export { CancelButton, ContainerProfile, FileButton };
