import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme): object {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.spacing(0),
          padding: theme.spacing(0),
          zIndex: 1,
          '& .MuiOutlinedInput-root': {
            padding: theme.spacing(1, 1),
            borderRadius: theme.spacing(0),
            borderColor: theme.palette.grey[300],
            '&:hover,&:focus,&:focus-within': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey[300]
              }
            },
            '& .MuiAutocomplete-input': {
              padding: theme.spacing(0.5)
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-root': {
              borderColor: theme.palette.grey[300],
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey[300]
              }
            }
          }
        },
        paper: {
          [theme.breakpoints.up('md')]: {
            borderStyle: 'solid',
            borderWidth: theme.spacing(0.125),
            borderRadius: theme.spacing(0),
            borderColor: theme.palette.grey[300]
          }
        }
      },
      defaultProps: {
        paper: { elevation: 10 }
      }
    }
  };
}
