import { Stack, styled } from '@mui/material';

export const PatronageActivitySelectorItem = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0.3125rem',
  paddingRight: '1.5rem',
  backgroundColor: isSelected ? theme.palette.grey[300] : 'white',
  '&:hover': {
    cursor: 'pointer',
    borderColor: theme.palette.grey[900],
    backgroundColor: theme.palette.button.neutral.background,
    '& path': {
      fill: theme.palette.button.primary.background
    },
    '& .MuiSvgIcon-root': { rect: { stroke: theme.palette.grey['500'] } },
    '& .Mui-checked': { svg: { rect: { stroke: 'black' } } }
  }
}));

export const ColorSquare = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color'
})<{ color: string }>(({ color }) => ({
  height: '0.5rem',
  width: '0.5rem',
  backgroundColor: color,
  marginRight: '0.5rem'
}));

export const ListContainer = styled('div')(({ theme }) => ({
  maxHeight: '12.5rem',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '.125rem',
    opacity: 0.4
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey['500']
  }
}));

export const ListHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'end',
  paddingLeft: '2rem',
  height: '2.1875rem',
  paddingBottom: '0.5rem'
}));
