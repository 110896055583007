import { Divider, Slide, useMediaQuery, useTheme } from '@mui/material';
import { DAO_COMMON_PAGES } from 'constants/dao-common.labels';
import { useAppNavigation } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import DaoNavigationHeader from '../../../labels/dao-navigation-header/DaoNavigationHeader';
import { DAORoomNavigation } from '../../dao-room';
import AdminNavigation from '../../dao-room/dao-room-navigation/admin-navigation/AdminNavigation';
import CommonsNavigation from '../../dao-room/dao-room-navigation/commons-navigation/CommonsNavigation';
import { StyledDesktopStack, StyledMobileStack } from './NavigationItems.style';

const NavigationItems = (): React.ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showDaoNav } = useAppNavigation();
  const { HOME } = DAO_COMMON_PAGES;
  const [activePageName, setActivePageName] = useState<string | undefined>(`commons-${HOME}`);
  const location = useLocation();

  useEffect(() => {
    const navigationId = (location?.state as any)?.navigationId;
    if (!navigationId) {
      setActivePageName(`commons-${HOME}`);
    } else {
      setActivePageName(navigationId);
    }
  }, [location]);

  return (
    <>
      {isMobile && (
        <Slide direction="right" in={showDaoNav} mountOnEnter>
          <StyledMobileStack showDaoNav={showDaoNav}>
            <DaoNavigationHeader />
            <Divider />
            <CommonsNavigation activePageName={activePageName} />
            <Divider />
            <AdminNavigation activePageName={activePageName} />
            <Divider />
            <DAORoomNavigation />
          </StyledMobileStack>
        </Slide>
      )}
      {!isMobile && (
        <StyledDesktopStack>
          <DaoNavigationHeader />
          <CommonsNavigation activePageName={activePageName} />
          <AdminNavigation activePageName={activePageName} />
          <DAORoomNavigation setActivePageName={setActivePageName} />
        </StyledDesktopStack>
      )}
    </>
  );
};

export default NavigationItems;
