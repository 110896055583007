import { Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { LinkButton } from 'components/buttons';
import { ErrorForm } from 'components/labels';
import { AUTH_VERIFICATION } from 'constants/auth.labels';
import {
  Auth,
  MultiFactorError,
  MultiFactorInfo,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  multiFactor
} from 'firebase/auth';
import { useAuth, useNotification } from 'hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import OTPInput from 'react-otp-input';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input/input';

type MultiFactorInfoWithPhoneNumber = MultiFactorInfo & { phoneNumber: string };

interface Props {
  auth: Auth;
  isEnrolling?: boolean;
  error?: MultiFactorError;
  onCancel?: () => void;
}
const PhoneVerificationStepper = ({
  auth,
  isEnrolling,
  error,
  onCancel
}: Props): React.ReactElement => {
  const theme = useTheme();
  const { logOut, authProviderUser } = useAuth();
  const [verificationId, setVerificationId] = useState<string>();
  const [loginRequired, setLoginRequired] = useState(false);
  const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver>();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const phoneNumberForDisplay = useRef<string>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { control, formState, handleSubmit, setError, reset } = useForm<{
    phoneNumber?: string;
    verificationCode?: string;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    delayError: 1000,
    defaultValues: {
      phoneNumber: '',
      verificationCode: ''
    }
  });
  const { PHONE, BACK } = AUTH_VERIFICATION;
  const { notify } = useNotification();
  const [loading, setLoading] = useState(false);

  const sendMessage = useCallback(
    async (showSuccessToast = false) => {
      const phoneProvider = new PhoneAuthProvider(auth);
      let phoneOptions: PhoneInfoOptions;

      if (isEnrolling) {
        if (!auth.currentUser) return;

        const mfSession = await multiFactor(auth.currentUser!).getSession();
        phoneOptions = {
          session: mfSession,
          phoneNumber: phoneNumberForDisplay.current
        };
      } else {
        const resolver = getMultiFactorResolver(auth, error!);
        const selectedHint = resolver.hints[0] as MultiFactorInfoWithPhoneNumber;
        phoneOptions = {
          session: resolver.session,
          multiFactorHint: selectedHint
        };

        phoneNumberForDisplay.current = selectedHint.phoneNumber;
        setMfaResolver(resolver);
      }

      try {
        const id = await phoneProvider.verifyPhoneNumber(phoneOptions, recaptchaVerifier!);
        setVerificationId(id);
        if (showSuccessToast) {
          notify(PHONE.VERIFY.MESSAGE_SENT);
        }
      } catch (error: any) {
        if (error.code === 'auth/invalid-phone-number') {
          setError('phoneNumber', {
            type: 'pattern',
            message: PHONE.VERIFY.ERROR.VALID_PHONE
          });
        } else if (error.code === 'auth/requires-recent-login') {
          setLoginRequired(true);
        }

        console.error(error);
      }
    },
    [recaptchaVerifier, isEnrolling]
  );

  const handlePhoneNumberSubmit = useCallback(
    async (data: { phoneNumber?: string }) => {
      try {
        setLoading(true);
        const isPossible = isPossiblePhoneNumber(data.phoneNumber || '', 'US');
        if (!isPossible) {
          setError('phoneNumber', {
            type: 'pattern',
            message: PHONE.VERIFY.ERROR.VALID_PHONE
          });
          return;
        }

        const formattedNumber = `${data.phoneNumber}`;
        phoneNumberForDisplay.current = formattedNumber;

        await sendMessage();
      } catch (error: any) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [recaptchaVerifier, auth]
  );

  const handleVerificationCodeSubmit = useCallback(
    async (data: { verificationCode?: string }) => {
      try {
        if (!verificationId) {
          console.error('error missing verification id');
        }

        const credential = PhoneAuthProvider.credential(verificationId!, data.verificationCode!);
        const assertion = PhoneMultiFactorGenerator.assertion(credential);

        if (isEnrolling) {
          await multiFactor(authProviderUser!).enroll(assertion, 'User phone number');
        } else if (mfaResolver) {
          await mfaResolver.resolveSignIn(assertion);
        }
      } catch (error: any) {
        if (error.code === 'auth/invalid-verification-code') {
          setError('verificationCode', {
            type: 'value',
            message: PHONE.VERIFY.ERROR.INVALID_CODE
          });
        } else if (error.code === 'auth/code-expired') {
          setError('verificationCode', {
            type: 'value',
            message: PHONE.VERIFY.ERROR.INVALID_CODE
          });
        } else {
          setError('verificationCode', {
            type: 'value',
            message: PHONE.VERIFY.ERROR.UNKNOWN
          });
        }
        console.log(error);
      }
    },
    [authProviderUser, isEnrolling, mfaResolver, verificationId]
  );

  useEffect(() => {
    const sendMessageOnInit = async () => {
      if (isEnrolling || !recaptchaVerifier) return;

      try {
        await sendMessage();
      } catch (error) {
        console.error(error);
      } finally {
      }
    };
    sendMessageOnInit();
  }, [recaptchaVerifier]);

  useEffect(() => {
    const configureCaptcha = async () => {
      if (recaptchaVerifier) return;

      const res = new RecaptchaVerifier(auth, 'sign-in-button', { size: 'invisible' });
      await res.render();
      setRecaptchaVerifier(res);
    };
    configureCaptcha();
  }, [auth]);

  if (loginRequired) {
    return (
      <Stack spacing={'3rem'}>
        <Stack spacing={1}>
          <Typography variant="h5">{PHONE.SESSION_EXPIRED}</Typography>
          <Typography variant="body2">{PHONE.LOGIN_AGAIN}</Typography>
        </Stack>
        <LinkButton onClick={() => logOut(true)} sx={{ alignSelf: 'center' }}>
          {PHONE.RETURN}
        </LinkButton>
      </Stack>
    );
  }

  return (
    <Stack>
      {/* this is used for invisible captcha validation */}
      <div id="sign-in-button"></div>

      {verificationId || !isEnrolling ? (
        <Stack spacing={'3rem'}>
          <Stack spacing={1}>
            <Typography variant="h5">{PHONE.VERIFY.HEADER}</Typography>
            <Stack direction={'row'} spacing={0.5}>
              <Typography variant="body2">
                {PHONE.VERIFY.SUBHEADER}{' '}
                <span style={{ fontWeight: 'bold' }}>{phoneNumberForDisplay.current}</span>
              </Typography>
            </Stack>
          </Stack>
          <form onSubmit={handleSubmit(handleVerificationCodeSubmit)}>
            <Stack spacing={'3rem'}>
              <Stack spacing={0.5}>
                <Controller
                  name="verificationCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Verification code required'
                    },
                    minLength: {
                      value: 6,
                      message: 'Min length is 6'
                    }
                  }}
                  render={({ field }) => (
                    <OTPInput
                      value={field.value}
                      onChange={(otp) => field.onChange(otp)}
                      numInputs={6}
                      shouldAutoFocus
                      inputType="number"
                      containerStyle={{
                        width: '100%',
                        justifyContent: 'space-between',
                        paddingLeft: 0
                      }}
                      renderInput={(props) => (
                        <input
                          {...props}
                          style={{
                            width: isMobile ? '2.5rem' : '3rem',
                            height: isMobile ? '2.5rem' : '3rem',
                            fontSize: isMobile ? '1rem' : '2rem',
                            borderRadius: isMobile ? '0.25rem' : '0.5rem',
                            textAlign: 'center',
                            border: `2px solid ${theme.palette.grey[300]}`
                          }}
                        />
                      )}
                    />
                  )}
                />
                {formState.errors.verificationCode && (
                  <ErrorForm message={formState.errors.verificationCode?.message} />
                )}
                <Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    sx={{ width: '100%' }}
                    spacing={1}
                  >
                    <Typography variant="body2" color="secondary">
                      {AUTH_VERIFICATION.PHONE.VERIFY.ACTION_TEXT}
                    </Typography>
                    <Link
                      variant="body2"
                      onClick={() => sendMessage(true)}
                      sx={{
                        backgroundColor: 'transparent',
                        textDecoration: 'underline',
                        ':hover': {
                          cursor: 'pointer',
                          color: 'inherit',
                          backgroundColor: 'transparent'
                        }
                      }}
                    >
                      {PHONE.VERIFY.ACTION}
                    </Link>
                  </Stack>
                </Stack>
              </Stack>

              <Button
                id="otp-button"
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                disabled={loading}
              >
                {PHONE.SUBMIT}
              </Button>
            </Stack>
          </form>
        </Stack>
      ) : (
        <Stack spacing={'3rem'}>
          <Stack spacing={1}>
            <Typography variant="h5">{PHONE.HEADER}</Typography>
            <Typography variant="body2">{PHONE.SUBHEADER}</Typography>
          </Stack>
          <form onSubmit={handleSubmit(handlePhoneNumberSubmit)}>
            <Stack spacing={'3rem'}>
              <Stack spacing={0.25}>
                <Typography variant="body2" fontWeight="medium">
                  {PHONE.INPUT_LABEL}
                </Typography>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Phone number is required'
                    },
                    pattern: {
                      value: /\+1\d{10}/,
                      message: 'Enter a valid number'
                    }
                  }}
                  render={({ field: { onChange, ...rest } }) => (
                    <PhoneInput
                      value={rest.value || ''}
                      country="US"
                      placeholder={PHONE.INPUT_PLACEHOLDER}
                      style={{
                        width: '100%',
                        height: '56px',
                        padding: '16px',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        fontSize: '1rem',
                        lineHeight: '1.1876em',
                        transition: 'border-color 0.3s',
                        ':focus': {
                          borderColor: 'rgba(0, 0, 0, 0.87)',
                          boxShadow: '0px 0px 0px 2px rgba(0, 0, 0, 0.1)'
                        }
                      }}
                      onChange={onChange}
                    />
                  )}
                />
                {formState.errors.phoneNumber && (
                  <ErrorForm message={formState.errors.phoneNumber?.message} />
                )}
              </Stack>
              <Button type="submit" variant="contained" fullWidth size="large">
                {PHONE.ACTION}
              </Button>
            </Stack>
          </form>
        </Stack>
      )}
      <Stack spacing={'2rem'}>
        {verificationId && isEnrolling && (
          <Button
            sx={{ marginTop: '.5rem' }}
            variant="outlined"
            onClick={() => {
              reset();
              phoneNumberForDisplay.current = '';
              setVerificationId('');
            }}
          >
            {BACK}
          </Button>
        )}
        <LinkButton
          sx={{ alignSelf: 'center' }}
          onClick={() => {
            logOut(true);
            onCancel && onCancel();
          }}
        >
          {PHONE.RETURN}
        </LinkButton>
      </Stack>
    </Stack>
  );
};

export default PhoneVerificationStepper;
