import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

export default function Drawer(theme: Theme): object {
  return {
    MuiDrawer: {
      defaultProps: {
        elevation: 0
      }
    }
  };
}
