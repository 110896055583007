import { Button, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { ApiKey, ApiKeyCreateResponseDTO } from '@piefi-platform/types-lib';
import { InputClipboard, InputForm } from 'components';
import { ErrorForm } from 'components/labels';
import BaseModal from 'components/modals';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { DAO_ADMIN_LABELS, DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';
import { useDao, useNotification } from 'hooks';
import { useApiKeyService } from 'hooks/services';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

interface ApiKeyCreateFormProps {
  onClose: () => void;
  isOpen: boolean;
}

export interface CreateApiKeyPayload extends ApiKey {
  description: string;
}

const ApiKeyCreateForm = ({ isOpen, onClose }: ApiKeyCreateFormProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);
  const { createApiKey } = useApiKeyService();
  const { currentDao } = useDao();
  const [keyCreated, setKeyCreated] = useState<ApiKeyCreateResponseDTO>();
  const { REQUIRED, MAX_LENGTH } = COMMON_VALIDATIONS;
  const { MIN_MAX_LENGTH } = DAO_ADMIN_LABELS;
  const {
    COPY_CREATED,
    DONE: COPY_ACKNOWLEDGE,
    CANCEL,
    CREATE,
    DESCRIPTION_NAME,
    NEW_API_KEY,
    STEP_STATE,
    FORMS: CREATE_FORM
  } = DEV_DASHBOARD_LABELS;
  const [loading, setLoading] = useState(false);
  const { notify } = useNotification();

  const {
    control,
    register,
    formState: { isDirty, isValid, errors },
    handleSubmit
  } = useForm<CreateApiKeyPayload>({
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: {
      description: ''
    }
  });

  const cancel = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const submit: SubmitHandler<CreateApiKeyPayload> = async (payload) => {
    try {
      setLoading(true);
      const { data: keyResponse } = await createApiKey(currentDao.id, payload);
      setKeyCreated(keyResponse);
    } catch (error: any) {
      notify('Error creating API key, try again later...');
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal title={NEW_API_KEY} toggle={open} close={cancel} width={450}>
      {keyCreated ? (
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'subtitle1'}>{STEP_STATE(2, 2)}</Typography>
          <Typography>{COPY_CREATED}</Typography>
          <Divider />
          <InputClipboard value={keyCreated.key} backgroundColor={'white'} />
          <Button
            type={'button'}
            variant={'contained'}
            size={'large'}
            onClick={onClose}
            style={{ marginTop: '2rem', width: 'fit-content' }}
          >
            {COPY_ACKNOWLEDGE}
          </Button>
        </Stack>
      ) : (
        <Stack direction={'column'}>
          <Typography variant={'subtitle1'}>{STEP_STATE(1, 2)}</Typography>
          <Typography variant={'body1'} paddingBottom={'1rem'}>
            {DESCRIPTION_NAME}
          </Typography>
          <Divider />
          <form
            data-testid={'api-key-create-form'}
            style={{ paddingTop: '1rem' }}
            onSubmit={handleSubmit(submit)}
          >
            <Controller
              control={control}
              name={'description'}
              rules={{
                required: REQUIRED,
                maxLength: { value: 64, message: MAX_LENGTH(64) }
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputForm
                    multiline
                    minRows={1}
                    maxRows={3}
                    id={field.name}
                    data-testid={field.name}
                    {...field}
                    {...register('description', {
                      maxLength: {
                        value: 64,
                        message: MIN_MAX_LENGTH('description', 'max', 64)
                      }
                    })}
                    autoFocus
                    fullWidth
                    hiddenLabel
                    error={!!fieldState.error}
                    inputProps={{ maxLength: 65 }}
                    placeholder={CREATE_FORM.DESCRIPTION_PLACEHOLDER}
                    size={'small'}
                    type={'text'}
                    variant={'outlined'}
                  />
                );
              }}
            />
            {errors?.description && <ErrorForm message={errors.description.message} />}
            <Stack spacing={2} direction={'row'} style={{ marginTop: '2rem' }}>
              {loading ? (
                <>
                  <Button fullWidth disabled={true} style={{ padding: 0 }}>
                    <Skeleton
                      animation={'pulse'}
                      variant={'rectangular'}
                      height={'100%'}
                      width={'100%'}
                    />
                  </Button>
                  <Button fullWidth disabled={true} style={{ padding: 0 }}>
                    <Skeleton
                      animation={'pulse'}
                      variant={'rectangular'}
                      height={'100%'}
                      width={'100%'}
                    />
                  </Button>
                </>
              ) : (
                <>
                  <Button type={'button'} variant={'outlined'} onClick={onClose}>
                    {CANCEL}
                  </Button>
                  <Button type={'submit'} variant={'contained'} disabled={!isDirty || !isValid}>
                    {CREATE}
                  </Button>
                </>
              )}
            </Stack>
          </form>
        </Stack>
      )}
    </BaseModal>
  );
};

export default ApiKeyCreateForm;
