export const BUTTON_LABELS = {
  ADD_MEMBERS: '+ Add Members',
  ADD: 'Add',
  BACK: 'Back',
  BUY_NOW: 'Buy now',
  CANCEL: 'Cancel',
  COPY: 'Copy',
  DELETE: 'Delete',
  DONE: 'Done',
  GO_BACK: 'Go Back',
  KEEP: 'Keep',
  NEXT: 'Next',
  OK: 'Ok',
  POST: 'Post',
  REPORT: 'Report',
  SAVE: 'Save',
  SUBMIT: 'Submit',
  DEACTIVATE: 'Deactivate',
  SEND_INVITE: 'Send Invite',
  VIEW_ALL: 'View All'
} as const;
