import { Position, Severity } from '../types/enum';

interface Notification {
  message: string;
  position?: Position;
  duration?: number;
  severity?: Severity;
}

interface NotificationOptions extends Omit<Notification, 'message'> {}

const initNotificationOptions = (): NotificationOptions => ({
  position: Position.BottomCenter,
  duration: 4000,
  severity: Severity.Info
});

export default Notification;
export type { NotificationOptions };
export { initNotificationOptions };
