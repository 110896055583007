import {
  PatronageActivitiesQueryDTO,
  PatronageActivityAggregateResultsDTO,
  PatronageActivityByIdQueryDTO,
  PatronageActivityDTO,
  PatronageActivityUpdatePayload,
  TotalActivitiesDTO
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { ActivityStatisticsResponse } from 'contexts/TimeSeriesPatronageStatisticsContext';
import useHttp from 'hooks/use-http';
import { PatronageActivityCreatePayload } from 'model';
import { useCallback } from 'react';
import { TimeIntervalOption } from 'types/enum';
import DataType from 'types/enum/chart-data-types.enum';
import PatronageActivityService from './use-patronage-activity-service.model';
import { patronageActivityUrl } from './use-patronage-activity-service.url';
import generateQueryString from 'utils/helper/query-builder';

const usePatronageActivityService = (): PatronageActivityService => {
  const { get, patch, post, _delete } = useHttp();

  const getPatronageActivities = useCallback(
    (
      daoId: string,
      query: PatronageActivitiesQueryDTO
    ): Promise<AxiosResponse<PatronageActivityDTO[]>> => {
      const queryString = generateQueryString(query);
      const url: string = `${patronageActivityUrl(daoId)}/?${queryString}`;
      return get(url);
    },
    [get]
  );

  const getPatronageActivity = useCallback(
    (
      daoId: string,
      patronageActivityId: string,
      queryPayload: PatronageActivityByIdQueryDTO
    ): Promise<AxiosResponse<PatronageActivityDTO>> => {
      const queryString = generateQueryString(queryPayload);
      const url: string = `${patronageActivityUrl(daoId)}/${patronageActivityId}?${
        queryString ? `${queryString}` : ''
      }`;
      return get(url);
    },
    [get]
  );

  const allocateAwardToMembersByActivity = useCallback(
    (
      daoId: string,
      activityId: string,
      payload: { memberIds: string[]; amount?: number; memo?: string }
    ): Promise<AxiosResponse<{ failuresDueToTagMismatch: number; successCount: number }>> => {
      return post(`${patronageActivityUrl(daoId)}/${activityId}/point-award-events`, payload);
    },
    [post]
  );

  const create = useCallback(
    (daoId: string, payload: PatronageActivityCreatePayload) => {
      return post(patronageActivityUrl(daoId), payload);
    },
    [post]
  );

  const update = useCallback(
    (activityId: string, daoId: string, payload: PatronageActivityUpdatePayload) => {
      return patch(`${patronageActivityUrl(daoId)}/${activityId}`, payload);
    },
    [patch]
  );

  const getStatistics = useCallback(
    (
      daoId: string,
      startDate: Date,
      endDate: Date,
      interval: TimeIntervalOption,
      dataType: DataType,
      activityIds?: string[]
    ): Promise<AxiosResponse<PatronageActivityAggregateResultsDTO[]>> => {
      return post(
        `${patronageActivityUrl(
          daoId
        )}/statistics?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&interval=${interval}&dataType=${dataType}`,
        {
          activityIds
        }
      );
    },
    [post]
  );

  const getActivitiesWithStatistics = useCallback(
    async (
      daoId: string,
      startDate: Date,
      endDate: Date,
      interval: TimeIntervalOption,
      dataType: DataType,
      isAdmin = false
    ): Promise<ActivityStatisticsResponse[]> => {
      const { data: activities } = await getPatronageActivities(daoId, {
        useAdminView: isAdmin,
        page: 0,
        size: 128
      });

      const activityIds = activities.map((activity) => {
        return activity.id;
      });
      const { data: activityStatistics } = await getStatistics(
        daoId,
        startDate,
        endDate,
        interval,
        dataType,
        activityIds
      );

      const statsData = activities.map((activity) => {
        const statistics = activityStatistics.find((stat) => {
          return stat.patronageActivityId === activity.id;
        });
        return {
          activity,
          statistics
        } as ActivityStatisticsResponse;
      });

      return statsData;
    },
    [getPatronageActivities, getStatistics]
  );

  const deleteActivity = useCallback(
    async (daoId: string, activityId: string) => {
      return _delete(`${patronageActivityUrl(daoId)}/${activityId}`);
    },
    [_delete]
  );

  const getTotalActivities = useCallback(
    (daoId: string): Promise<AxiosResponse<TotalActivitiesDTO>> => {
      const url: string = `${patronageActivityUrl(daoId)}/count`;
      return get(url);
    },
    [get]
  );

  return {
    allocateAwardToMembersByActivity,
    create,
    deleteActivity,
    getActivitiesWithStatistics,
    getPatronageActivities,
    getStatistics,
    update,
    getTotalActivities,
    getPatronageActivity
  };
};

export default usePatronageActivityService;
