import { Stack, SvgIconProps, Typography, TypographyProps } from '@mui/material';
import { BitIcon } from 'assets/icons/hero-ui';
import { useTheme } from '@mui/material';
import { numberFormatter } from 'utils';

interface Props {
  amount: number;
  showAsPrimary?: boolean;
  iconProps?: SvgIconProps;
  typographyProps?: TypographyProps;
  parentheses?: boolean;
}
const UnitAmountIconDisplay = ({
  amount,
  showAsPrimary,
  iconProps,
  typographyProps,
  parentheses = false
}: Props): React.ReactElement => {
  const theme = useTheme();

  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Typography
        variant={typographyProps?.variant || 'body2'}
        color={typographyProps?.color || (showAsPrimary ? '' : 'secondary')}
        paddingRight={'0.25rem'}
        fontWeight={typographyProps?.fontWeight || 'regular'}
      >
        {`${parentheses ? '(' : ''}${numberFormatter.formatterWithThreeDecimals(amount)}`}
      </Typography>
      <BitIcon
        height={iconProps?.height || '10'}
        width={iconProps?.width || '10'}
        fill={iconProps?.fill || theme.palette.secondary.main}
      />
      {parentheses && (
        <Typography
          variant={typographyProps?.variant || 'body2'}
          color={typographyProps?.color || (showAsPrimary ? '' : 'secondary')}
          paddingRight={'0.25rem'}
          fontWeight={typographyProps?.fontWeight || 'regular'}
        >
          {')'}
        </Typography>
      )}
    </Stack>
  );
};

export default UnitAmountIconDisplay;
