import { getWholeChar } from 'utils';
import { AvatarBuilderProps } from './AvatarBuilder.props';
import { AnimatedAvatar } from './AvatarBuilder.style';
import { AvatarIconStyles } from './AvatarIconStyles';

const AvatarBuilder = ({
  src,
  alt,
  id,
  size,
  onClick,
  style,
  hideAnimation = false,
  isSquare = false
}: AvatarBuilderProps): React.ReactElement => {
  // Value passed as alt tag will be the initial default using passed child default in Mui avatar, secondary default is genereic personIcon provided by Mui

  const iconSize = AvatarIconStyles({ size, onClick });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <AnimatedAvatar
      src={src}
      id={id}
      data-testid={id}
      alt={alt}
      onClick={handleClick}
      style={{ ...iconSize, ...style }}
      hideAnimation={hideAnimation}
      isSquare={isSquare}
    >
      {alt && getWholeChar(alt, 0).toUpperCase()}
    </AnimatedAvatar>
  );
};

export default AvatarBuilder;
