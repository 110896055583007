/**
 * This method create a date adding a number a days
 * @param days numbers of days to add on current date
 * @returns a future date
 */
const addDate = (days: number): string => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toDateString();
};

/**
 * This method subtracts two dates
 * @param greaterDate first operand
 * @param lesserDate second operand
 * @returns number the days between both dates
 */
const subtractDates = (greaterDate: Date, lesserDate: Date): number => {
  const diff = Math.floor(greaterDate.getTime() - lesserDate.getTime());
  const days = 1000 * 60 * 60 * 24;
  return Math.floor(diff / days) + 1;
};

/**
 * This method returns a date based on the date from the parameter and time from the second parameter
 * @param date  date to be used
 * @param date  time to be used
 * @returns date
 */
const joinDateAndTime = (date: Date, time: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(time.getHours());
  newDate.setMinutes(time.getMinutes());
  newDate.setSeconds(time.getSeconds());

  return newDate;
};

const createDaysToZeroString = (daysLeft: number): string => {
  let daysToZeroString = '';

  if (daysLeft <= 0) {
    daysToZeroString = '0 Days';
  } else if (daysLeft === 1) {
    daysToZeroString = '1 Day';
  } else if (daysLeft < 365) {
    daysToZeroString = `${daysLeft.toFixed(0)} Days`;
  } else if (Number(daysLeft / 365) > 100) {
    daysToZeroString = 'Many Years';
  } else {
    daysToZeroString = `${Number(daysLeft / 365).toFixed(1)} Years`;
  }

  return daysToZeroString;
};

export { addDate, subtractDates, joinDateAndTime, createDaysToZeroString };
