import React from 'react';
import InputFormStyled from './InputForm.style';
import InputFormProps from './InputForm.props';
import { Stack, Typography } from '@mui/material';

const InputForm = React.forwardRef((props: InputFormProps, ref): React.ReactElement => {
  const { label, ...rest } = props;
  return (
    <Stack gap={1}>
      {label && (
        <Typography variant="caption" color="text.secondary">
          {label}
        </Typography>
      )}
      <InputFormStyled {...rest} hiddenLabel inputRef={ref} />
    </Stack>
  );
});

export default InputForm;
