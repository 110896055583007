import { Stack, Typography, useTheme } from '@mui/material';
import { AvatarBuilder, UnitAmountIconDisplay } from 'components';
import { formatDistanceToNowStrict } from 'date-fns';
import { UI_LABELS } from 'constants/ui-labels';
import { usdFormatter } from 'utils';
import { PointAwardEventListItemProps } from './PointAwardEventListItem.props';

const PointAwardEventListItem = ({
  pointAwardEventData,
  sourceData
}: PointAwardEventListItemProps): React.ReactElement => {
  const theme = useTheme();

  const { AGO } = UI_LABELS;
  const { avatarObj, sourceName } = sourceData;
  const { eventAction, pointAwardEvent } = pointAwardEventData;

  return (
    <Stack direction={'row'} marginTop={'1.5rem'}>
      <AvatarBuilder
        id={avatarObj.id}
        src={avatarObj.src}
        alt={avatarObj.id}
        size={avatarObj.size}
      />
      <Stack direction={'column'} paddingLeft={'0.75rem'}>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'body2'} fontWeight={'medium'}>
            {sourceName}
          </Typography>
          <Typography variant={'caption'} color={'secondary.main'}>{`${formatDistanceToNowStrict(
            new Date(pointAwardEvent?.createdAt)
          )} ${AGO}`}</Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Typography variant={'body2'} color={theme.palette.grey[700]}>
            {eventAction}
          </Typography>{' '}
          <Typography variant={'body2'} fontWeight={'medium'} color={'secondary.dark'}>
            {pointAwardEvent?.patronageActivity?.name}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'body2'}>
            {usdFormatter.format(pointAwardEvent?.dollarValueAtTimeOfAllocation || 0)}
          </Typography>
          <UnitAmountIconDisplay amount={pointAwardEvent.bits} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PointAwardEventListItem;
