import { SimpleDropdownOption } from 'model';

enum ManageableEntityType {
  All = 'All',
  JoinRequests = 'JoinRequests',
  Reported = 'Reported',
  DaoInvites = 'DaoInvites'
}

const filterOptions: SimpleDropdownOption[] = [
  { text: 'All', value: ManageableEntityType.All },
  { text: 'Join Requests', value: ManageableEntityType.JoinRequests },
  { text: 'Collective Invites', value: ManageableEntityType.DaoInvites },
  { text: 'Reported', value: ManageableEntityType.Reported }
];

const formatManageableEntityType = (entity: ManageableEntityType): string => {
  switch (entity) {
    case ManageableEntityType.JoinRequests:
      return 'Join Requests';
    case ManageableEntityType.DaoInvites:
      return 'Collective Invites';
    default:
      return entity;
  }
};

export default ManageableEntityType;
export { filterOptions, formatManageableEntityType };
