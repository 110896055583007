import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { TableContainerWrapper } from 'components';
import PatronageActivityTableProps from './PatronageActivityTable.props';
import PatronageActivityTableRow from './PatronageActivityTableRow';

const PatronageActivityTable = ({
  activities,
  onScroll,
  style,
  onActivityDeleted,
  highlightingEnabled
}: PatronageActivityTableProps): React.ReactElement => {
  return (
    <TableContainerWrapper style={style} onScroll={onScroll}>
      <Table aria-label="activity table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">Tags</TableCell>
            <TableCell align="left">Award Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity) => (
            <PatronageActivityTableRow
              activity={activity}
              key={activity.id}
              onActivityDeleted={() => onActivityDeleted(activity)}
              highlightingEnabled={highlightingEnabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainerWrapper>
  );
};

export default PatronageActivityTable;
