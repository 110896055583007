enum DistributionType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
  CURVED = 'CURVED'
}

export const ArithmeticOperator = {
  ADD: 'ADD',
  SUBTRACT: 'SUBTRACT',
  MULTIPLY: 'MULTIPLY',
  DIVIDE: 'DIVIDE'
};

export default DistributionType;
