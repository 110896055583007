import { Stack, Typography, useTheme } from '@mui/material';
import CommentIcon from 'assets/CommentIcon';
import { PostMenu } from 'components';
import React from 'react';
import { ContentType } from 'types/enum';
import { abbreviateNumber } from 'utils';
import CardSidebarProps from './CardSidebar.props';
import { SidebarStackStyled } from './CardSidebar.style';
import SideVote from './side-vote/SideVote';

const CardSidebar = ({
  showComment = true,
  postOptions = false,
  comments,
  type
}: CardSidebarProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <SidebarStackStyled direction="column" tabIndex={0} spacing={1}>
      {type === ContentType.Post && <SideVote />}
      {showComment && (
        <Stack direction="column" spacing={1} alignItems="center">
          <CommentIcon className="comment-icon" style={{ height: '1.25rem', width: '1.25rem' }} />
          <Typography
            className="comments-count"
            variant="body1"
            fontWeight={theme.typography.fontWeightBold}
          >
            {abbreviateNumber(comments)}
          </Typography>
        </Stack>
      )}
      {postOptions && <PostMenu />}
    </SidebarStackStyled>
  );
};

export default CardSidebar;
