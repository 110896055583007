export const MENU_ACTIONS = {
  REPORT: 'Report',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DISMISS: 'Dismiss',
  CANCEL: 'Cancel'
};

export const COMMENTS_ACTIONS = {
  ...MENU_ACTIONS,
  COMMENTS: 'comments',
  LEAVE_A_COMMENT: 'Leave a comment',
  SORT_BY: 'Sort by',
  COLLAPSE: 'Collapse',
  WITHOUT_LABEL: 'Without label'
};

export const POST_ACTIONS = {
  SHARE: 'Share',
  ...MENU_ACTIONS,
  COMMENTS: 'leave a comments',
  MORE_INTERACTIONS: 'more interactions',
  REPLY: 'Reply',
  SUBMIT: 'Submit',
  COLLAPSE: 'Collapse'
};

export const USER_ACTIONS = {
  ...MENU_ACTIONS,
  CHANGE_ROLE: 'Change Role',
  EDIT_TAGS: 'Edit Tags',
  EDIT_EXTERNAL_REFERENCE: 'Edit Shopify Code',
  RESEND_INVITATION: 'Resend',
  DELETE_INVITATION: 'Remove',
  UPDATE_INVITATION: 'Edit',
  RESEND_SUCCESSFUL: (email: string): string => `Invite resent to: ${email}`
};
