import { Box, Button, Divider, MenuItem, styled } from '@mui/material';

const DropdownContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'withBorder'
})<{ withBorder: boolean }>(({ theme, withBorder }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: 'fit-content'
}));

const SortButtonStyled = styled(Button)(({ theme }) => ({
  border: `0.0625rem solid ${theme.palette.grey[300]}`,
  justifyContent: 'flex-end',
  padding: '0.5rem',
  '& path': {
    fill: theme.palette.common.black
  }
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex !important',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: '0.5rem',
  textAlign: 'left',
  '&:hover': {
    color: theme.palette.common.white
  },
  '& .material-icons': {
    fontSize: '1.5rem',
    height: '1.5rem',
    marginRight: '0.75rem',
    width: '1.5rem'
  }
}));

const DividerStyled = styled(Divider)({
  margin: '0 0.5rem'
});

export { DividerStyled, MenuItemStyled, SortButtonStyled, DropdownContainer };
