import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ArrowLeftIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} width="8" height="12" viewBox="0 0 8 12">
      <path
        d="M3.2908 5.9997L6.8308 2.4597C7.01705 2.27234 7.12159 2.01889 7.12159 1.7547C7.12159 1.49052 7.01705 1.23707 6.8308 1.0497C6.73783 0.955976 6.62723 0.881582 6.50537 0.830813C6.38352 0.780044 6.25281 0.753906 6.1208 0.753906C5.98879 0.753906 5.85808 0.780044 5.73622 0.830813C5.61436 0.881582 5.50376 0.955976 5.4108 1.0497L1.1708 5.2897C1.07707 5.38267 1.00268 5.49327 0.951907 5.61513C0.901138 5.73699 0.875 5.86769 0.875 5.9997C0.875 6.13172 0.901138 6.26242 0.951907 6.38428C1.00268 6.50614 1.07707 6.61674 1.1708 6.7097L5.4108 10.9997C5.50424 11.0924 5.61505 11.1657 5.73689 11.2155C5.85873 11.2652 5.98919 11.2905 6.1208 11.2897C6.2524 11.2905 6.38287 11.2652 6.5047 11.2155C6.62654 11.1657 6.73736 11.0924 6.8308 10.9997C7.01705 10.8123 7.12159 10.5589 7.12159 10.2947C7.12159 10.0305 7.01705 9.77707 6.8308 9.5897L3.2908 5.9997Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
