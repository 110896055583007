import { Box, Modal, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CloseIcon } from 'assets';
import React, { useEffect, useState } from 'react';
import IBaseModalProps from './BaseModal.props';
import modalStyled, { IconButtonStyled } from './BaseModal.style';

const BaseModal = ({
  children,
  title,
  toggle,
  width = 400,
  close,
  hideCloseButton,
  executeCloseOnBackdropClick = true,
  backDropCallback
}: IBaseModalProps): React.ReactElement => {
  const [open, setOpen] = useState(toggle);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (_: any, reason: string) => {
    reason !== 'backdropClick' && setOpen(false);
    backDropCallback && backDropCallback();
    executeCloseOnBackdropClick && close && close();
  };

  const closeModal = () => {
    setOpen(false);
    close && close();
  };

  useEffect(() => {
    setOpen(toggle);
  }, [toggle]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyled(!isMobile ? width : -1) }}>
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          {!hideCloseButton && (
            <IconButtonStyled onClick={closeModal}>
              <CloseIcon />
            </IconButtonStyled>
          )}
        </Stack>
        <Stack direction={'column'}>{children}</Stack>
      </Box>
    </Modal>
  );
};

export default BaseModal;
