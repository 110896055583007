export const twitterLink = 'twitterLink';
export const linkedInLink = 'linkedInLink';
export const instagramLink = 'instagramLink';
export const discordLink = 'discordLink';

export const fullLinkRegexString = '^https?:[a-zA-Z0-9_.\\-\\/]+';
export const fullLinkRegex = /^https?:[a-zA-Z0-9_.\-\\/]+$/;
export const socialLinksRegexString = '[a-zA-Z0-9_.-]+';
export const socialLinksRegex = /^[a-zA-Z0-9_.-]+$/;
export const linkedInLinkRegexString = fullLinkRegexString;
export const linkedInLinkRegex = fullLinkRegex;
export const discordLinkRegexString = '([a-zA-Z0-9_.]+(#\\d{4})?)';
export const discordLinkRegex = /^([a-z0-9_.]+(#\d{4})?)$/i;
export const discordInviteLinkRegexString = fullLinkRegexString;
export const discordInviteLinkRegex = fullLinkRegex;

export const twitterLinkHelperText = 'Please include only a-z, A-Z, 0-9, _ or .';
export const linkedInLinkHelperText = 'Please include a valid linkedIn link';
export const instagramLinkHelperText = 'Please include only a-z, A-Z, 0-9, _ or .';
export const discordHelperText =
  'Please include only a-z, A-Z, 0-9, _ or . (optionally include #1234)';
export const discordInviteLinkHelperText = 'Please include valid discord server link';
