import { Box, Button, Typography } from '@mui/material';
import { AvatarBuilder } from 'components';
import BaseModal from 'components/modals';
import { BUTTON_LABELS } from 'constants/button.labels';
import { DAO_JOIN_LABELS } from 'constants/dao-common.labels';
import { AvatarSize } from 'types/enum';
import DaoJoinRequestModalProps from './DaoJoinRequestModal.props';

const DaoJoinRequestModal = ({
  daoInfo,
  open,
  onClose
}: DaoJoinRequestModalProps): React.ReactElement => {
  const { logoThumbnail } = daoInfo;
  const { THANKS, REQUEST_BEING_REVIEWED } = DAO_JOIN_LABELS;
  const { OK } = BUTTON_LABELS;

  return (
    <BaseModal toggle={open} close={onClose} width={680}>
      <Box padding="2.5rem">
        <Box marginBottom="1.5rem">
          <AvatarBuilder
            src={logoThumbnail}
            alt={daoInfo.id}
            id={'dao-join-request-avatar'}
            size={AvatarSize.X_LARGE}
          />
        </Box>
        <Typography variant="h6">{THANKS}</Typography>
        <Typography color="text.secondary" marginTop=".5rem" marginBottom="4rem">
          {REQUEST_BEING_REVIEWED}
        </Typography>
        <Button
          fullWidth={false}
          color="primary"
          onClick={onClose}
          size="large"
          variant="contained"
        >
          {OK}
        </Button>
      </Box>
    </BaseModal>
  );
};

export default DaoJoinRequestModal;
