import { Checkbox, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import ColorPickerProps, { ColorPickerItem, COLOR_PICKER_COLORS } from './ColorPicker.props';

const ColorPicker = ({ defaultColor, onColorSelected }: ColorPickerProps): React.ReactElement => {
  const [colorPickerOptions, setColorPickerOptions] = useState<ColorPickerItem[]>([]);

  useEffect(() => {
    const options = COLOR_PICKER_COLORS();

    const configuredOptions = options.map((color) => {
      const isSelected = color === defaultColor;

      return {
        isSelected,
        color
      };
    });

    setColorPickerOptions([...configuredOptions]);
  }, [defaultColor]);

  const handleClick = (item: ColorPickerItem) => {
    const newList = colorPickerOptions.map((option) => ({
      isSelected: option.color === item.color,
      color: option.color
    }));

    setColorPickerOptions([...newList]);
    onColorSelected(item.color);
  };

  return (
    <Stack direction={'row'} data-testid="wrapper" display={'flex'} flexWrap={'wrap'}>
      {colorPickerOptions.map((option) => {
        return (
          <Checkbox
            data-testid={`check-${option.color}`}
            checked={option.isSelected}
            sx={{
              '& .MuiSvgIcon-root': {
                height: 20,
                width: 20,
                bgcolor: 'transparent',
                rect: {
                  fill: option.color,
                  stroke: option.isSelected ? option.color : 'transparent'
                },
                path: { fill: 'transparent' }
              },
              '&:hover': { bgcolor: 'transparent', rect: { stroke: option.color } }
            }}
            key={option.color}
            onClick={() => handleClick(option)}
          />
        );
      })}
    </Stack>
  );
};

export default ColorPicker;
