import { Grid, Stack, Typography } from '@mui/material';
import { DAO_ACTIONS_LABELS } from 'constants/dao-actions.labels';
import ActionActivityList from './action-activity-list/ActionActivityList';
import ActionsLeaderboard from './actions-leaderboard/ActionsLeaderboard';

const DaoActions = (): React.ReactElement => {
  const { ACTIONS_SUBTITLE } = DAO_ACTIONS_LABELS;

  return (
    <Stack direction={'column'}>
      <Typography variant={'body2'}>{ACTIONS_SUBTITLE}</Typography>
      <Grid container direction={'row'} marginTop={'2rem'} columnSpacing={3} rowSpacing={3}>
        <Grid item md={12} sm={12} xs={12} lg={8}>
          <ActionActivityList />
        </Grid>
        <Grid item md={12} sm={12} xs={12} lg={4}>
          <ActionsLeaderboard />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DaoActions;
