import { Stack, Typography } from '@mui/material';
import BaseCardWrapper from '../base-card-wrapper/BaseCardWrapper';
import { BasicTitleCardProps } from './BasicIconCard.props';
import { ButtonStyled } from './BasicIconCard.style';

const BasicIconCard = ({
  icon,
  title,
  children,
  height,
  ...baseCardProps
}: BasicTitleCardProps): React.ReactElement => {
  return (
    <BaseCardWrapper
      {...baseCardProps}
      sx={{ cursor: baseCardProps.onClick ? 'pointer' : 'default', height: height || 'auto' }}
    >
      <Stack direction={'column'} gap={1.5}>
        <Stack direction={'row'} alignItems={'center'} spacing={0.75}>
          <ButtonStyled
            disableRipple
            sx={{ cursor: baseCardProps.onClick ? 'pointer' : 'default' }}
          >
            {icon}
          </ButtonStyled>
          <Typography variant={'subtitle2'} fontWeight={'bold'}>
            {title}
          </Typography>
        </Stack>
        {children}
      </Stack>
    </BaseCardWrapper>
  );
};

export default BasicIconCard;
