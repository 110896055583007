import { useCallback, useEffect, useRef, useState } from 'react';
import useEventEmitter from './use-event-emitter';
import { PatronageActivityPayload as PatronageActivity } from 'model';
import { TagDTO } from '@piefi-platform/types-lib';
import { usePatronageActivityTagService } from './services';
import useDao from './use-dao';
import { AxiosResponse } from 'axios';

export interface ActivityTag {
  tags: TagDTO[];
  handleTagDetach: (tag: TagDTO) => Promise<void>;
  handleTagSelection: (tag: Partial<TagDTO>) => Promise<void>;
  attachTagsToActivity: (
    activityId: string
  ) => Promise<PromiseSettledResult<AxiosResponse<any, any>>[]>;
}

/**
 * @description Manages the state for tags regarding activities.
 *  If creating an activity all tag actions happen in state (locally)
 *  If updating a pre existing activity then actions happen in both the API and state
 *
 *  We cannot create/attach a tag to an activity that does not exist in the database yet
 *
 * @param act {@link PatronageActivity}
 */
const useActivityTags = (act: PatronageActivity): ActivityTag => {
  const activity = useRef(act);
  const dateNow = useRef(new Date());
  const isUpdating = useRef<boolean>(!!activity.current.id);

  const [tags, setTags] = useState<TagDTO[]>(act.tags || []);

  const { createAndAttachTag, detachTag } = usePatronageActivityTagService();
  const { subscribe } = useEventEmitter();
  const { currentDao } = useDao();

  /**
   *
   * @param tag {@link Partial<TagDTO>}
   * @returns string : with tag.color_tag.name
   */
  const generateTagKey = (tag: Partial<TagDTO>) => `${tag.color}_${tag.name}`;

  useEffect(() => {
    const unsubscribe = subscribe('ON_TAG_DELETE', dateNow.current.getTime(), (payload) => {
      const tagExistsInDB = payload?.data.id;
      if (tagExistsInDB) {
        // filter tag by id since it has one from db
        setTags((prevTags) => prevTags.filter((i) => i.id !== payload?.data.id));
      } else {
        // at this point the tag does not have an id because it was created locally
        setTags((prevTags) =>
          prevTags.filter(
            (i) => `${generateTagKey(i)}` !== `${generateTagKey(payload?.data || {})}`
          )
        );
      }
    });

    return () => unsubscribe();
  }, [subscribe]);

  useEffect(() => {
    const updateTag = (existingTag: TagDTO, updateData: TagDTO) => {
      const { id } = updateData;

      const tagExistsInDB = id && existingTag.id === id;
      const tagExistsLocally = generateTagKey(existingTag) === generateTagKey(updateData);

      if (tagExistsInDB || tagExistsLocally) {
        return { ...updateData };
      }

      return existingTag;
    };

    const unsubscribe = subscribe('ON_TAG_UPDATE', dateNow.current.getTime(), (payload) => {
      if (!payload) return;

      const { data } = payload;

      setTags((prevTags) => prevTags.map((tag) => updateTag(tag, data)));
    });

    return () => unsubscribe();
  }, [subscribe]);

  /**
   *
   * @description Creates or attaches the tag to the activityId and updates state with tag created/attached
   *
   * @param tag {@link TagDTO}
   */
  const handleTagSelectionOnActUpdate = useCallback(
    async (tag: Partial<TagDTO>) => {
      if (!activity.current.id) return;

      const { data: res } = await createAndAttachTag([activity.current.id], currentDao.id, tag);
      setTags((prevTags) => [...prevTags, res]);
    },
    [tags]
  );

  /**
   *
   * @description When an activity is being created we do not go to the BE to attach the tag since the activity
   *  does not exist yet. We push the tag into local state then once the activity is created the tags can be
   *  attached {@link attachTagsToActivity}
   *
   * @param tag {@link TagDTO}
   */
  const handleTagSelectionOnActCreate = useCallback(
    (tag: TagDTO) => {
      setTags((prevTags) => [...prevTags, tag]);
    },
    [tags]
  );

  /**
   *
   * @description Goes to the api and detaches the tag from the activity then removes the tag from state
   *
   * @param tag {@link Partial<TagDTO>}
   */
  const handleTagDetachOnActUpdate = useCallback(
    async (tag: Partial<TagDTO>) => {
      if (!activity.current.id || !tag.id) return;

      await detachTag([activity.current.id], currentDao.id, tag.id);

      setTags((prevTags) => prevTags.filter((i) => i.id !== tag.id));
    },
    [tags]
  );

  /**
   *
   * @description Detaches tag from local state since the activity has not been created yet,
   *  we perform the following check: Does the tag have an id?
   *  1. Yes: remove tag from state using tag.id
   *  2. No: remove tag from state by concatenating color_name and removing based on that
   *
   * @param tag {@link Partial<TagDTO>}
   */
  const handleTagDetachOnActCreate = useCallback(
    (tag: Partial<TagDTO>) => {
      if (tag.id) {
        setTags((prevTags) => prevTags.filter((i) => i.id !== tag.id));
      } else {
        setTags((prevTags) =>
          prevTags.filter((i) => `${generateTagKey(i)}` !== `${generateTagKey(tag)}`)
        );
      }
    },
    [tags]
  );

  /**
   *
   * @description Removes tag from an activity. If the activity is already created we go to API and remove
   *  otherwise we only remove from local state
   *
   * @param tag {@link TagDTO}
   */
  const handleTagDetach = useCallback(
    async (tag: TagDTO) => {
      if (isUpdating.current) {
        return handleTagDetachOnActUpdate(tag);
      } else {
        return handleTagDetachOnActCreate(tag);
      }
    },
    [tags]
  );

  /**
   *
   * @description Adds tag to an activity. If the activity is already created we go to API and add
   *  otherwise we only add to local state
   *
   * @param tag {@link TagDTO}
   */
  const handleTagSelection = useCallback(
    async (tag: Partial<TagDTO>) => {
      if (isUpdating.current) {
        return handleTagSelectionOnActUpdate(tag);
      } else {
        return handleTagSelectionOnActCreate(tag as TagDTO);
      }
    },
    [tags]
  );

  /**
   *
   * @description Attaches/Creates & Attaches all tags in state to the activity id provided
   *
   * @param activityId {@link string}
   */
  const attachTagsToActivity = useCallback(
    async (activityId: string) => {
      const proms = tags.map((tag) => createAndAttachTag([activityId], currentDao.id, tag));
      const results = await Promise.allSettled(proms);
      return results;
    },
    [tags]
  );

  return {
    tags,
    handleTagDetach,
    handleTagSelection,
    attachTagsToActivity
  };
};

export default useActivityTags;
