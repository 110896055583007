import { Button, Stack, Typography } from '@mui/material';
import { ApiKeyDTO } from '@piefi-platform/types-lib';
import { captureException } from '@sentry/react';
import { InputForm } from 'components';
import BaseModal from 'components/modals';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { DAO_ADMIN_LABELS, DEV_DASHBOARD_LABELS } from 'constants/dao-admin-nav.labels';
import { useNotification } from 'hooks';
import { useApiKeyService } from 'hooks/services';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Severity } from 'types/enum';
import EditApiKeyProps from './EditApiKey.props';

import { ErrorForm } from 'components/labels';
import { CreateApiKeyPayload } from '../api-key-create-form/ApiKeyCreateForm';

const EditApiKeyModal = ({
  onClose,
  daoId,
  isOpen,
  apiKey
}: EditApiKeyProps): React.ReactElement => {
  const [open, setOpen] = useState(isOpen);
  const { updateApiKey } = useApiKeyService();
  const { notify } = useNotification();
  const { EDIT_DESC, CANCEL, FORMS: CREATE_FORM, THEAD_DESC } = DEV_DASHBOARD_LABELS;
  const { SAVE } = DAO_ADMIN_LABELS;
  const { REQUIRED, MAX_LENGTH } = COMMON_VALIDATIONS;
  const { MIN_MAX_LENGTH } = DAO_ADMIN_LABELS;

  const submit = async (updatedApiKey: CreateApiKeyPayload) => {
    try {
      const updatedKey = await updateApiKey(daoId, apiKey.id, updatedApiKey as ApiKeyDTO);
      onClose(updatedKey.data);
      notify('Success', { severity: Severity.Success });
    } catch (error: any) {
      captureException(error);
      notify(`Error Updating Key: ${error.message}`, { severity: Severity.Error });
    } finally {
      setOpen(false);
    }
  };

  const {
    control,
    register,
    formState: { isDirty, isValid, errors },
    handleSubmit
  } = useForm<CreateApiKeyPayload>({
    mode: 'onChange',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: {
      description: apiKey.description
    }
  });

  const cancel = () => {
    onClose();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <BaseModal title={EDIT_DESC} toggle={open} close={cancel} width={450}>
      <Stack direction={'column'}>
        <Typography variant={'caption'} padding={'0.5rem 0 0.25rem'}>
          {THEAD_DESC}
        </Typography>
        <form data-testid={'dev-api-key-edit-form'} onSubmit={handleSubmit(submit)}>
          <Controller
            control={control}
            name={'description'}
            rules={{
              required: REQUIRED,
              maxLength: { value: 64, message: MAX_LENGTH(64) }
            }}
            render={({ field, fieldState }) => {
              return (
                <InputForm
                  multiline
                  minRows={1}
                  maxRows={3}
                  id={field.name}
                  data-testid={field.name}
                  {...field}
                  {...register('description', {
                    maxLength: {
                      value: 64,
                      message: MIN_MAX_LENGTH('description', 'max', 64)
                    }
                  })}
                  autoFocus
                  fullWidth
                  hiddenLabel
                  error={!!fieldState.error}
                  inputProps={{ maxLength: 65 }}
                  placeholder={CREATE_FORM.DESCRIPTION_PLACEHOLDER}
                  size={'small'}
                  type={'text'}
                  variant={'outlined'}
                />
              );
            }}
          />
          {errors?.description && <ErrorForm message={errors.description.message} />}
          <Stack direction={'row'} spacing={2} style={{ marginTop: '2rem' }}>
            <Button variant={'outlined'} size={'large'} onClick={cancel}>
              {CANCEL}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              size={'large'}
              disabled={!isDirty || !isValid}
            >
              {SAVE}
            </Button>
          </Stack>
        </form>
      </Stack>
    </BaseModal>
  );
};
export default EditApiKeyModal;
