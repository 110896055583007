import { Button, Stack, Typography } from '@mui/material';
import BaseModal from 'components/modals';
import { MENU_ACTIONS } from 'constants/menu-actions.labels';
import { DELETE_OPTION } from 'constants/post-options-labels';
import { useCommentService } from 'hooks/services';
import useNotification from 'hooks/use-notification';
import { CommentDeleteModalProps } from './CommentDeleteModal.props';
import { HTTP_STATUS } from 'constants/http-statuses';
import { Severity } from 'types/enum';
import { useCallback } from 'react';

const CommentDeleteModal = ({
  entity,
  open,
  onClose,
  onCommentDeleted
}: CommentDeleteModalProps): React.ReactElement => {
  const { DELETE, CANCEL } = MENU_ACTIONS;
  const { DELETE_SUBTITLE, DELETE_SUCCESS } = DELETE_OPTION;

  const { deleteComment } = useCommentService();
  const { notify } = useNotification();

  const handleDelete = useCallback(async () => {
    const { daoId, roomId, postId, commentId } = entity;
    try {
      const deleteCommentRes = await deleteComment(daoId, roomId, postId, commentId);
      if (deleteCommentRes.status === HTTP_STATUS.NO_CONTENT) {
        onCommentDeleted();
        notify(DELETE_SUCCESS, {
          severity: Severity.Success
        });
      }
    } catch (error) {
      console.log(error);
    }
    onClose();
  }, [DELETE_SUCCESS, entity, deleteComment, notify, onClose, onCommentDeleted]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <BaseModal
      title="Delete Comment"
      data-testid="comment-delete-modal"
      toggle={open}
      close={onClose}
      width={450}
    >
      <Stack direction="column" spacing={4}>
        <Typography variant="body1">{DELETE_SUBTITLE}</Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" color="primary" size="large" onClick={handleCancel}>
            {CANCEL}
          </Button>
          <Button
            data-testid="comment-delete-button"
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleDelete}
          >
            {DELETE}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default CommentDeleteModal;
