import { Button, FormControl, Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { InputForm } from 'components';
import { ErrorForm, LabelForm } from 'components/labels';
import BaseModal from 'components/modals';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { DAO_INVITE_SETTINGS } from 'constants/dao-invite-labels';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useNotification } from 'hooks';
import { useDaoInviteService } from 'hooks/services';
import { DaoInviteCreateParam } from 'model';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Severity } from 'types/enum';
import { addDate, subtractDates } from 'utils';
import IDaoInviteFormProps from './DaoInviteForm.props';

const DaoInviteForm = ({
  daoId,
  currentInvite,
  updateInviteLink,
  open,
  setOpen
}: IDaoInviteFormProps): React.ReactElement => {
  const { updateDaoInvite } = useDaoInviteService();
  const { notify } = useNotification();
  const {
    PRIMARY_TITLE,
    SAVE_AND_UPDATE_LINK,
    INVITE_UPDATE_SUCCESSFUL,
    EXPIRES_AFTER,
    MAX_USES,
    NO_LIMIT,
    ERROR_UPDATING_INVITE
  } = DAO_INVITE_SETTINGS;
  const { BACK } = BUTTON_LABELS;
  const { REQUIRED } = COMMON_VALIDATIONS;

  // hook form
  const defaultValues: DaoInviteCreateParam = {
    endDate: currentInvite?.daoInviteEntity?.endDate
      ? currentInvite.daoInviteEntity.endDate.toString()
      : addDate(7),
    maxInvites: currentInvite?.daoInviteEntity?.maxInvites
      ? currentInvite.daoInviteEntity.maxInvites.toString()
      : ''
  };
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    reset
  } = useForm<DaoInviteCreateParam>({
    defaultValues,
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const closeModal = () => setOpen(false);

  const onSubmit: SubmitHandler<DaoInviteCreateParam> = async (payload: DaoInviteCreateParam) => {
    const maxInvites = payload.maxInvites ? +payload.maxInvites : 0;
    const endDate = payload.endDate ? new Date(payload.endDate) : undefined;
    const updatedInvite = await updateDaoInvite(
      daoId,
      currentInvite?.daoInviteEntity?.id ?? '',
      currentInvite?.id ?? '',
      { maxInvites, endDate }
    );

    if (updatedInvite.status !== HTTP_STATUS.OK) {
      notify(ERROR_UPDATING_INVITE, { severity: Severity.Error });
    } else {
      const currentDate = new Date();
      updateInviteLink(subtractDates(endDate as Date, currentDate), maxInvites);
      reset({
        endDate: endDate ? endDate.toDateString() : defaultValues.endDate,
        maxInvites: maxInvites ? maxInvites.toString() : defaultValues.maxInvites
      });

      notify(INVITE_UPDATE_SUCCESSFUL, { severity: Severity.Success });
    }
    closeModal();
  };

  const onCancel = () => {
    reset();
    closeModal();
  };

  useEffect(() => {
    // cleaning fields up after submit event
    isSubmitSuccessful && reset();
  }, [isSubmitSuccessful, reset]);

  const allowOnlyDigit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e?.key !== 'Backspace' && !/^\d$/g.test(e?.key)) e.preventDefault();
  };

  return (
    <>
      <BaseModal title={PRIMARY_TITLE} toggle={open} close={closeModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={3}>
            <FormControl fullWidth size="small">
              <LabelForm>{EXPIRES_AFTER}</LabelForm>
              <Controller
                name="endDate"
                control={control}
                rules={{ required: REQUIRED }}
                render={({ field, fieldState }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disablePast
                      value={field.value}
                      onChange={field.onChange}
                      renderInput={(params) => (
                        <TextField
                          id={field.name}
                          {...field}
                          {...params}
                          error={!!fieldState.error}
                          onKeyDown={(e) => e.preventDefault()}
                          sx={{
                            '.MuiInputBase-input': { padding: '0.531rem 0.875rem' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors?.endDate && <ErrorForm message={errors.endDate.message} />}
            </FormControl>

            <FormControl fullWidth size="small">
              <LabelForm>{MAX_USES}</LabelForm>
              <Controller
                name="maxInvites"
                control={control}
                render={({ field }) => (
                  <InputForm
                    id={field.name}
                    {...field}
                    fullWidth
                    hiddenLabel
                    onKeyDown={allowOnlyDigit}
                    placeholder={NO_LIMIT}
                    size="small"
                    type="text"
                    variant="outlined"
                    error={false}
                  />
                )}
              />
            </FormControl>

            <Stack direction="row" spacing={2} sx={{ marginTop: '3.125rem !important' }}>
              <Button type="submit" variant="contained" color="primary">
                {SAVE_AND_UPDATE_LINK}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: '4.563rem' }}
                onClick={onCancel}
              >
                {BACK}
              </Button>
            </Stack>
          </Stack>
        </form>
      </BaseModal>
    </>
  );
};

export default DaoInviteForm;
