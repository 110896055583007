import { List, ListItem, ListItemIcon, useMediaQuery, useTheme } from '@mui/material';
import { AwsmTextIcon, UpsideDaoIcon } from 'assets';
import { ROOT_ROUTE } from 'constants/routes';
import { useAppNavigation, useUser } from 'hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { StyledListItemButton } from './DashboardNavItem.style';

interface DashboardNavItemProps {
  setActiveNavItemName: (value: string) => void;
}

const DashboardNavItem = ({ setActiveNavItemName }: DashboardNavItemProps): React.ReactElement => {
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const { userNavExpanded, setNavigationOpen } = useAppNavigation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDashboardNavigate = useCallback(() => {
    // setNavigationOpen(navigationOpen, ?userNavExpanded, ?showDaoNav)
    setNavigationOpen(!isMobile, !isMobile);
    if (userInfo) {
      setActiveNavItemName('');
      navigate(`${ROOT_ROUTE.USER(userInfo.id)}`);
    }
  }, [isMobile, userNavExpanded, navigate]);

  return (
    <List>
      <ListItem
        sx={{
          height: '3.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={() => handleDashboardNavigate()}
      >
        <StyledListItemButton openDrawer={userNavExpanded}>
          <ListItemIcon
            sx={{
              '& > svg': {
                height: '1.5rem',
                width: '1.5rem',
                marginLeft: userNavExpanded ? 0 : '4.75rem'
              }
            }}
          >
            <UpsideDaoIcon />
          </ListItemIcon>
          <ListItemIcon
            sx={{
              '& > svg': {
                height: '3.5rem',
                width: '3.5rem',
                paddingTop: '0.33rem',
                marginLeft: '-0.75rem',
                opacity: userNavExpanded ? '1rem' : 0
              }
            }}
          >
            <AwsmTextIcon />
          </ListItemIcon>
        </StyledListItemButton>
      </ListItem>
    </List>
  );
};

export default DashboardNavItem;
