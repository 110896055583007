import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ConsoleFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '22px';
  const height = props.height ? `${props.height}px` : '16px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 22 16" {...props}>
      <path
        d="M20.6119 0.77C20.1219 0.27 19.4819 0 18.7719 0H2.77187C1.99187 0 1.36187 0.240003 0.891868 0.710003C-0.0281316 1.64 -0.00813073 3.18 0.0118693 4.41C0.0118693 4.6 0.0118693 4.79 0.0118693 4.95C-0.00813073 7.09 -0.0181303 9.61 0.11187 12.12C0.13187 12.57 0.16187 13.14 0.36187 13.69C0.81187 14.92 2.01187 15.52 3.93187 15.52H4.05187C6.23187 15.49 8.49187 15.46 10.7019 15.54C11.2019 15.56 11.7119 15.58 12.2219 15.6C13.2519 15.65 14.3019 15.69 15.3619 15.69C16.2419 15.69 17.1119 15.66 17.9919 15.57C19.6419 15.4 21.4119 15 21.5019 12.31C21.5319 11.46 21.5619 10.61 21.6019 9.76C21.6719 8.02 21.7419 6.29 21.7819 4.55C21.7919 3.96 21.7419 1.94 20.6019 0.77H20.6119ZM8.56187 9.68C8.32187 9.89 8.08187 10.1 7.84187 10.3C7.12187 10.92 6.37187 11.56 5.75187 12.25C5.60187 12.42 5.40187 12.5 5.19187 12.5C5.01187 12.5 4.83187 12.44 4.69187 12.31C4.38187 12.03 4.36187 11.56 4.63187 11.25C5.32187 10.49 6.10187 9.81 6.86187 9.16C7.10187 8.96 7.33187 8.76 7.56187 8.55C7.60187 8.51 7.64187 8.48 7.66187 8.46C7.63187 8.44 7.60187 8.42 7.56187 8.39L4.30187 6.17C3.96187 5.94 3.87187 5.47 4.10187 5.13C4.33187 4.79 4.80187 4.7 5.14187 4.93L8.40187 7.15C8.63187 7.31 9.17187 7.68 9.23187 8.32C9.29187 9.01 8.77187 9.48 8.55187 9.67L8.56187 9.68ZM16.7419 11.86H11.5119C11.1019 11.86 10.7619 11.52 10.7619 11.11C10.7619 10.7 11.1019 10.36 11.5119 10.36H16.7419C17.1519 10.36 17.4919 10.7 17.4919 11.11C17.4919 11.52 17.1519 11.86 16.7419 11.86Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
