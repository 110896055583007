import { Icon } from '@iconify/react';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Chip(theme: Theme): object {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <Icon icon={closeCircleFill} />
      },

      styleOverrides: {
        sizeSmall: {
          fontSize: '.75rem',
          fontWeight: 800,
          padding: '.25rem .5rem',
          '& > span': {
            padding: 0
          }
        },
        colorDefault: {
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary
          }
        },
        colorPrimary: {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.common.black,
          '&:hover': {
            backgroundColor: theme.palette.common.black
          }
        },
        colorSecondary: {
          color: theme.palette.common.black,
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.grey[300]
          }
        },
        outlined: {
          borderColor: theme.palette.grey[500_32],
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.main
          },
          '&.MuiChip-colorSecondary': {
            borderColor: theme.palette.secondary.main
          }
        },
        avatarColorInfo: {
          color: theme.palette.info.contrastText,
          backgroundColor: theme.palette.info.dark
        },
        avatarColorSuccess: {
          color: theme.palette.success.contrastText,
          backgroundColor: theme.palette.success.dark
        },
        avatarColorWarning: {
          color: theme.palette.warning.contrastText,
          backgroundColor: theme.palette.warning.dark
        },
        avatarColorError: {
          color: theme.palette.error.contrastText,
          backgroundColor: theme.palette.error.dark
        }
      }
    }
  };
}
