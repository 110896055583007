import { FeedPostDTO } from '@piefi-platform/types-lib';
import { ContentType } from 'types/enum/content-type';

export const getContentTypeFromFeedPost = (feedItem: FeedPostDTO): ContentType => {
  if (feedItem.proposal) {
    return ContentType.Proposal;
  } else {
    return ContentType.Post;
  }
};
