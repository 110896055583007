import { CreateNewPost, FeedList } from 'components';
import { PAGINATION } from 'constants/app-config';
import { useDao, useRoomMembership } from 'hooks';
import { useRoomService } from 'hooks/services';
import useFeedPage from 'hooks/use-feed-page';
import { useEffect, useState } from 'react';
import { PostFilterType, UserDaoRole, UserRoomRole } from 'types/enum';
import { compareDaoRoles, compareRoomRoles } from 'utils';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';

const DaoUpdates = (): React.ReactElement => {
  const { getFeedByRoom } = useRoomService();
  const { setActiveFilter, activeFilter } = useFeedPage();
  const { activeDaoMembership } = useDao();
  const { defaultRoom } = useRoomMembership();
  const [hasUpdateAuthority, setHasUpdateAuthority] = useState<boolean>();

  useEffect(() => {
    setActiveFilter(PostFilterType.Updates);
  }, [setActiveFilter, hasUpdateAuthority]);

  useEffect(() => {
    if (
      activeDaoMembership &&
      activeDaoMembership?.role &&
      defaultRoom &&
      defaultRoom.daoId === activeDaoMembership.daoId
    ) {
      const daoRole = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
      const hasAdminAuthority = compareDaoRoles(UserDaoRole.ADMIN, daoRole);

      const roomRole = UserRoomRole[defaultRoom?.role as keyof typeof UserRoomRole];
      const isRoomMember = compareRoomRoles(UserRoomRole.MEMBER, roomRole);
      setHasUpdateAuthority(hasAdminAuthority && isRoomMember);
    }
  }, [activeDaoMembership, defaultRoom]);

  const handleGetData = async (
    daoId: string,
    roomId: string,
    page: number,
    postType?: PostFilterType
  ) =>
    activeFilter &&
    (await getFeedByRoom(
      daoId,
      roomId,
      {
        page,
        size: PAGINATION.PAGE_SIZE
      },
      postType,
      true // announcementsOnly
    ));

  if (!activeDaoMembership || !defaultRoom || activeDaoMembership?.daoId !== defaultRoom?.daoId) {
    return <></>;
  }

  return (
    <>
      {hasUpdateAuthority && (
        <CreateNewPost dao={activeDaoMembership} room={defaultRoom} forceUpdates />
      )}
      <FeedList
        redirectLocationOnSinglePostClose={`${ROOT_ROUTE.DAO(
          (activeDaoMembership.dao as any).whiteLabelSubDomain
        )}/${ROUTES.DAO_UPDATES()}`}
        handleGetPosts={handleGetData}
        daoId={activeDaoMembership.daoId}
        roomId={defaultRoom.roomId}
      />
    </>
  );
};

export default DaoUpdates;
