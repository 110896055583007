import { useDao } from 'hooks';
import { ReactElement } from 'react';
import BaseContentNavigation from '../base-content-navigation/BaseContentNavigation';
import { BaseContentNavigationProps } from '../base-content-navigation/BaseContentNavigation.props';
import { MobileContentNavigationContainerStyled } from './MobileContentNavigation.style';

const MobileContentNavigation = (props: BaseContentNavigationProps): ReactElement => {
  const { activeDaoMembership } = useDao();

  if (!activeDaoMembership) return <></>;

  return (
    <MobileContentNavigationContainerStyled>
      <BaseContentNavigation {...props} />
    </MobileContentNavigationContainerStyled>
  );
};

export default MobileContentNavigation;
