import { Button, FormControl, Stack, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { InputForm } from 'components';
import { EmailRegex } from 'constants/auth.constants';
import { COMPLETE_PROFILE, FORGOT_PASSWORD } from 'constants/auth.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { ROUTES } from 'constants/routes';
import { useAuth, useNotification } from 'hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Severity } from 'types/enum';
import { ErrorForm } from '../../../labels';

const ForgotPassword = (): React.ReactElement => {
  const [loading, setLoading] = useState(false);
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();
  const { notify } = useNotification();

  const { CANCEL } = COMPLETE_PROFILE;
  const { LEGEND } = FORGOT_PASSWORD;
  const { REQUIRED, MAX_LENGTH, VALID_EMAIL } = COMMON_VALIDATIONS;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid }
  } = useForm<{ email: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: ''
    }
  });

  const sendReq = async ({ email }: { email: string }) => {
    try {
      setLoading(true);
      await forgotPassword(email);
      notify('Email has been sent! ✅', { severity: Severity.Success });
    } catch (error: any) {
      switch (error.code) {
        case 'auth/invalid-email':
          notify('Invalid email address', { severity: Severity.Error });
          break;
        case 'auth/user-not-found':
          notify('Email not found', { severity: Severity.Error });
          break;
        default:
          // Handle other errors
          captureException(error);
          notify('Unknown Error occurred', { severity: Severity.Error });
          break;
      }
      captureException(error);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(sendReq)}>
      <Stack spacing={6}>
        <Typography variant={'h6'}>{LEGEND}</Typography>
        <Stack spacing={1} data-testid="email">
          <FormControl fullWidth>
            <Controller
              control={control}
              name="email"
              rules={{
                required: REQUIRED,
                maxLength: { value: 64, message: MAX_LENGTH(64) },
                pattern: {
                  value: EmailRegex,
                  message: VALID_EMAIL
                }
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputForm
                    id={field.name}
                    data-testid={field.name}
                    {...field}
                    autoFocus
                    fullWidth
                    hiddenLabel
                    error={!!fieldState.error}
                    placeholder="Email"
                    size="small"
                    type="text"
                    variant="outlined"
                  />
                );
              }}
            />
            {errors?.email && <ErrorForm message={errors.email.message} />}
          </FormControl>
        </Stack>
        <Stack spacing={1}>
          <Button
            type="submit"
            variant="contained"
            size="extraLarge"
            fullWidth
            disabled={!isDirty || !isValid || loading}
          >
            Submit
          </Button>
          <Button
            type="submit"
            variant="text"
            size="extraLarge"
            fullWidth
            onClick={() => navigate(ROUTES.AUTH)}
          >
            {CANCEL}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ForgotPassword;
