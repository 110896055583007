import { Box, BoxProps, styled } from '@mui/material';

export const MobileContentNavigationContainerStyled = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));
