import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { PieFilledIcon, PieIcon } from 'assets/icons/hero-ui';
import { ROOT_ROUTE } from 'constants/routes';
import { useAppNavigation, useUser } from 'hooks';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { StyledListItemButton } from './PortfolioNavItem.style';

export interface PortfolioNavItemProps {
  setActiveNavItemName: (value: string) => void;
}

const PortfolioNavItem = ({ setActiveNavItemName }: PortfolioNavItemProps): React.ReactElement => {
  const navigate = useNavigate();
  const { userInfo } = useUser();
  const { userNavExpanded, setNavigationOpen } = useAppNavigation();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const sections = [
    {
      name: 'My Ownership',
      activeIcon: <PieFilledIcon />,
      inActiveIcon: <PieIcon />,
      url: () => `${ROOT_ROUTE.USER(userInfo?.id)}`
    }
  ];

  const handleDashboardNavigate = useCallback(
    (url: any) => {
      // setNavigationOpen(navigationOpen, ?userNavExpanded, ?showDaoNav)
      setNavigationOpen(!isMobile, !isMobile);
      if (userInfo) {
        setActiveNavItemName('');
        navigate(url());
      }
    },
    [isMobile, userNavExpanded, navigate]
  );

  return (
    <List>
      {sections.map((item) => (
        <ListItem
          key={item.name}
          onClick={() => handleDashboardNavigate(item.url)}
          sx={{
            height: '3.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <StyledListItemButton openDrawer={userNavExpanded}>
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                '& > svg': {
                  height: '1.5rem',
                  width: '1.5rem',
                  marginLeft: userNavExpanded ? 0 : '0.75rem'
                }
              }}
            >
              {location.pathname.includes('/u/') ? item.activeIcon : item.inActiveIcon}
            </ListItemIcon>
            <ListItemText sx={{ opacity: userNavExpanded ? 1 : 0, marginLeft: '-0.25rem' }}>
              <Typography
                variant="body1"
                fontWeight={location.pathname.includes('/u/') ? '750' : '550'}
              >
                {item.name}
              </Typography>
            </ListItemText>
          </StyledListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default PortfolioNavItem;
