import { Transaction } from '@hashgraph/sdk';
/**
 * @description Converts Hedera transaction to base64 (necessary for transferring through request)
 * @param transaction {Transaction}
 */
export const transactionToBase64 = (transaction: Transaction): string => {
  return Buffer.from(transaction.toBytes()).toString('base64');
};

/**
 * @description Converts transaction in base64 to Hedera Transaction (necessary for signing and executing transaction)
 * @param base64 {string}
 */
export const base64ToTransaction = (base64: string): Transaction => {
  return Transaction.fromBytes(Buffer.from(base64, 'base64'));
};
