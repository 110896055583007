import { Notification, NotificationOptions, initNotificationOptions } from 'model';

export default interface NotificationContextType {
  notifying: boolean;
  notification: Notification;
  notify: (message: string, options?: NotificationOptions) => void;
  resetNotifier: () => void;
}

export const initNotificationContextType = (): NotificationContextType => ({
  notifying: false,
  notification: {
    message: '',
    ...initNotificationOptions()
  },
  notify: (message: string, options?: NotificationOptions): void => {},
  resetNotifier: (): void => {}
});
