export const PROPOSAL = {
  CLOSED: 'Closed',
  LEFT: 'left',
  VOTES: 'Votes'
};

export const FEED_LABELS = {
  EMPTY_FEED_TITLE: 'Looks like this feed is a little empty at the moment.',
  EMPTY_FEED_DESCRIPTION:
    "Don't worry, we're sure it will fill up with all sorts of awesome content soon.",
  EMPTY_COMMENTS_TITLE: 'Hi! Hello. There aren’t any comments yet.',
  EMPTY_COMMENTS_DESCRIPTION:
    "Don't be shy. Share your thoughts. Every voice matters here, and we’d love to hear yours!"
};
