interface HashValue<T> {
  [key: string]: T;
}

class HashTable<T> {
  private readonly hashTable: HashValue<T>;

  constructor() {
    this.hashTable = {};
  }

  add = (key: string, value: T): HashTable<T> => {
    this.hashTable[key] = value;
    return this;
  };

  find = (key: string): T => {
    return this.hashTable[key];
  };
}

const hashTable = <T>(): HashTable<T> => new HashTable<T>();

export default hashTable;
