import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { EllipsisIcon } from 'assets';
import { NAV_LABELS } from 'constants/navigation.labels';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useAppNavigation, useAuth, useUser } from 'hooks';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

const MenuNavItem = (): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logOut } = useAuth();
  const { userInfo } = useUser();
  const { userNavExpanded, setNavigationOpen } = useAppNavigation();
  const { HELP, MORE, SETTINGS, LOGOUT } = NAV_LABELS;

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    if (isMobile) {
      setNavigationOpen(false);
    }
  }, [anchorEl]);

  return (
    <Stack>
      <Divider />
      <List>
        <ListItem
          sx={{
            height: '3.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={handleMenuClick}
        >
          <ListItemButton
            sx={{
              width: userNavExpanded ? 'auto' : '3rem',
              height: '3rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: userNavExpanded ? 'initial' : 'center',
              transition: 'all 0.3s ease',
              '&:hover': {
                borderRadius: '0.5rem',
                backgroundColor: theme.palette.grey[200],
                maxWidth: userNavExpanded ? 'none' : '3rem'
              }
            }}
          >
            <ListItemIcon
              sx={{
                '& > svg': {
                  height: '1.5rem',
                  width: '1.5rem',
                  marginLeft: userNavExpanded ? 0 : '1rem'
                }
              }}
            >
              <EllipsisIcon fill="black" />
            </ListItemIcon>
            <ListItemText sx={{ opacity: userNavExpanded ? 1 : 0 }}>
              <Typography variant="body1" fontWeight={'bold'}>
                {MORE}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PopoverClasses={{ paper: 'actions-menu' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            userInfo &&
              navigate(`${ROOT_ROUTE.USER(userInfo.id)}/${ROUTES.USER_PROFILE_SETTINGS()}`);
          }}
        >
          {SETTINGS}
        </MenuItem>
        <MenuItem onClick={() => navigate(`${ROUTES.HELP}`)}>{HELP}</MenuItem>
        <MenuItem onClick={() => logOut()}>{LOGOUT}</MenuItem>
      </Menu>
    </Stack>
  );
};

export default MenuNavItem;
