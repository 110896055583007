import { Button, Typography } from '@mui/material';
import { DaoDTO, PatronageActivityDTO } from '@piefi-platform/types-lib';
import { PatronageActivityTable } from 'components';
import { ENTITY_BLANK_ID, PAGINATION } from 'constants/app-config';
import { PATRONAGE_ACTIVITY_FORM } from 'constants/dao-admin-nav.labels';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useDao, usePatronageActivityStepper, useUser } from 'hooks';
import { usePatronageActivityService } from 'hooks/services';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import { TabContainerTemplate } from '../../../containers';
import { SecondaryAddIcon } from './DaoControlPanel.style';

const DaoControlPanel = (): React.ReactElement => {
  const [activities, setActivities] = useState<any[]>([]);
  const { addNewPatronageActivity } = useFlags();
  const { activeDaoMembership, currentDao } = useDao();
  const { activityStepperActive, setActivityStepperActive, setActiveFormStep } =
    usePatronageActivityStepper();
  const navigate = useNavigate();
  const { userInfo } = useUser();

  const [page, setPage] = useState<number>(0);
  const [onLastPage, setOnLastPage] = useState(false);

  const { ADD_NEW } = PATRONAGE_ACTIVITY_FORM;
  const { getPatronageActivities } = usePatronageActivityService();
  const isAdminUser = useRef<boolean>(
    compareDaoRoles(UserDaoRole.ADMIN, activeDaoMembership?.role as UserDaoRole)
  );

  const handleScroll = (e: any) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const atBottom = scrollHeight < clientHeight + scrollTop + 1;
    if (atBottom && !onLastPage) setPage(page + 1);
  };

  const toggleCreating = useCallback(() => {
    const subDomain = (currentDao as DaoDTO).whiteLabelSubDomain;
    navigate(
      `${ROOT_ROUTE.DAO(subDomain)}/${ROUTES.DAO_ADMIN_ACTIVITY_FORM(subDomain, ENTITY_BLANK_ID)}`
    );
  }, [activityStepperActive, setActivityStepperActive, setActiveFormStep]);

  const getActivities = useCallback(async () => {
    if (!activeDaoMembership) return;

    // TODO: move to a higher level
    const isAdmin = compareDaoRoles(UserDaoRole.ADMIN, activeDaoMembership.role as UserDaoRole);
    if (!isAdmin) navigate(ROUTES.DAO_HOME(currentDao.id));

    try {
      const { data: activities } = await getPatronageActivities(activeDaoMembership.daoId, {
        page: page,
        size: PAGINATION.PATRONAGE_ACTIVITIES,
        useAdminView: isAdmin
      });

      if (activities.length < PAGINATION.PATRONAGE_ACTIVITIES) setOnLastPage(true);
      setActivities((prevActivities) => [...prevActivities, ...activities]);
    } catch (error) {
      console.error(error);
    }
  }, [activeDaoMembership, getPatronageActivities, navigate, page]);

  const handleDeletedActivity = (activity: PatronageActivityDTO) => {
    const actsCopy = [...activities];
    const itemIndex = activities.findIndex((i) => i.id === activity.id);
    actsCopy.splice(itemIndex, 1);

    setActivities([...actsCopy]);
  };

  useEffect(() => {
    getActivities();
  }, [getActivities, page]);

  if (!activeDaoMembership) return <></>;

  return (
    <TabContainerTemplate
      title={' '}
      darkTitle
      rightAction={
        <>
          {isAdminUser.current && addNewPatronageActivity && (
            <Button
              id={'add-new-activity'}
              onClick={toggleCreating}
              disabled={activityStepperActive}
              data-testid="control-panel-add-button"
              size="medium"
              variant="contained"
              color="primary"
            >
              <Typography variant="body1" fontWeight="bolder">
                {ADD_NEW}
              </Typography>
              <SecondaryAddIcon style={{ marginLeft: '.5rem' }} />
            </Button>
          )}
        </>
      }
    >
      <PatronageActivityTable
        activities={[...activities]}
        onScroll={handleScroll}
        style={{ marginTop: '3rem' }}
        onActivityDeleted={handleDeletedActivity}
        highlightingEnabled={userInfo?.isPlatformAdmin}
      />
    </TabContainerTemplate>
  );
};

export default DaoControlPanel;
