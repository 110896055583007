import { styled, Link, LinkProps } from '@mui/material';
import { ElementType } from 'react';
import { FONT_PRIMARY } from 'theme/typography';

const StyledLink = styled(Link)<LinkProps & { component: ElementType }>(({ theme }) => ({
  fontFamily: FONT_PRIMARY,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '0.75rem',
  lineHeight: '1rem',
  color: theme.palette.button.primary.background,
  backgroundColor: theme.palette.button.primary.linkBackground
}));

export { StyledLink };
