import { styled } from '@mui/material';

export const ItemContainer = styled('div')(({ theme }) => ({
  maxHeight: '15rem',
  height: 'auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  paddingBottom: '0.5rem',
  '&::-webkit-scrollbar': {
    width: '.125rem',
    opacity: 0.4
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey['500']
  }
}));
