import TimelineItem from '@mui/lab/TimelineItem';
import { styled } from '@mui/material';

export const TimelineItemStyled = styled(TimelineItem)(({ theme }) => ({
  '&.MuiTimelineItem-root': {
    '& > .MuiTimelineSeparator-root': {
      '& > span.MuiTimelineConnector-root': {
        transition: 'backgroundColor 0.3s ease-in-out'
      }
    },
    '&:hover': {
      '& > .MuiTimelineSeparator-root': {
        '& > span.MuiTimelineConnector-root': {
          backgroundColor: theme.palette.grey[500]
        }
      }
    }
  }
}));
