import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function WordMarkLogo(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 113 32" fill="none">
      <path
        d="M30.2384 10.8058C29.5394 10.1639 28.5315 10.0049 27.669 10.4023L20.9341 13.5013L15.5492 4.17298C15.1322 3.44999 14.3523 3 13.5168 3C12.6813 3 11.9013 3.44999 11.4843 4.17298L1.31602 21.7856C0.803033 22.6751 0.923031 23.7821 1.61452 24.5411C2.07051 25.042 2.70199 25.309 3.34698 25.309C3.67847 25.309 4.01447 25.2385 4.33096 25.093L17.8262 18.8847L16.0232 15.7617L6.37992 20.1986L13.5183 7.8329L17.0462 13.9423L20.2216 19.4427L22.8841 24.0551C23.3386 24.8425 24.2175 25.2985 25.125 25.219C26.031 25.138 26.8155 24.5336 27.1245 23.6786L30.8594 13.3318C31.1819 12.4393 30.9389 11.4478 30.2399 10.8058H30.2384ZM24.654 19.9437L22.7371 16.6227L26.4735 14.9038L24.654 19.9437Z"
        fill="#141414"
      />
      <path
        d="M53.3159 21.1045H47.72L46.5462 24.7015H42L47.6949 8.08984H53.2153L59.1098 24.7015H54.5134L53.3143 21.1045H53.3159ZM52.5412 18.1565L51.2181 14.1352C50.9179 13.1357 50.734 12.0954 50.668 11.0127H50.2438C50.2108 12.1457 50.0442 13.186 49.744 14.1352L48.5199 18.1565H52.5412Z"
        fill="#141414"
      />
      <path
        d="M78.0944 12.2119L74.4471 24.7017H69.8507L68.5511 19.0571C68.4175 18.4584 68.3264 17.8455 68.2761 17.2217C68.2258 16.5978 68.1849 15.7696 68.1519 14.7356H67.7276C67.6946 15.7524 67.6522 16.5632 67.6035 17.1714C67.5532 17.7795 67.4542 18.4081 67.3034 19.0571L65.9802 24.7017H61.3586L57.7113 12.2119H62.1569L63.2318 17.2829C63.381 18.0498 63.4565 18.7821 63.4565 19.4814C63.4565 20.1807 63.3905 20.9475 63.2569 21.7301L63.7063 21.8307C64.1228 19.9654 64.4889 18.4332 64.8048 17.2342L65.9786 12.2135H69.8507L71.0245 17.2594C71.4079 18.9754 71.7317 20.4981 71.9988 21.8307L72.4482 21.7301C72.365 21.0638 72.3241 20.4227 72.3241 19.8067C72.3241 19.2567 72.3901 18.5825 72.5237 17.7827L73.6975 12.2119H78.0944Z"
        fill="#141414"
      />
      <path
        d="M83.4151 21.8652C83.7813 22.1559 84.248 22.3021 84.8137 22.3021C85.2789 22.3021 85.667 22.2062 85.975 22.0145C86.283 21.8228 86.437 21.5446 86.437 21.1785C86.437 20.8453 86.2956 20.5829 86.0127 20.3912C85.7299 20.1995 85.3213 20.0376 84.7886 19.9041L82.4157 19.3053C80.0177 18.672 78.8187 17.4573 78.8187 15.658C78.8187 14.8927 79.0717 14.2265 79.5808 13.6592C80.0884 13.0934 80.7798 12.6597 81.6536 12.3596C82.5273 12.0594 83.5063 11.9102 84.589 11.9102C86.2375 11.9102 87.6109 12.2842 88.7109 13.0337C89.8093 13.7833 90.4505 14.8315 90.6343 16.1813H86.4622C86.3962 15.6486 86.1793 15.2322 85.8132 14.932C85.447 14.6319 85.0227 14.4826 84.5387 14.4826C84.0547 14.4826 83.6776 14.5785 83.4026 14.7702C83.1276 14.9619 82.9909 15.2243 82.9909 15.5575C82.9909 15.8403 83.115 16.0745 83.3649 16.2568C83.6147 16.4406 84.0139 16.5978 84.5639 16.7313L86.9619 17.3065C88.1436 17.6066 89.0645 18.0435 89.7213 18.6186C90.3798 19.1938 90.7082 19.9716 90.7082 20.9538C90.7082 22.2031 90.1755 23.1899 89.11 23.9144C88.0446 24.6388 86.6115 25.0002 84.8137 25.0002C83.016 25.0002 81.6331 24.6341 80.4671 23.9018C79.3011 23.1695 78.6018 22.0695 78.3693 20.6049H82.741C82.8243 21.1549 83.049 21.5745 83.4151 21.8668V21.8652Z"
        fill="#141414"
      />
      <path
        d="M111.579 13.0119C112.336 13.7442 112.715 14.8191 112.715 16.2333V24.7018H108.393V17.0332C108.393 16.4172 108.231 15.9552 107.906 15.6472C107.581 15.3392 107.185 15.1852 106.72 15.1852H106.619C106.102 15.1852 105.678 15.3565 105.345 15.6975C105.011 16.0385 104.845 16.5335 104.845 17.1841V24.7034H100.523V17.0851C100.523 16.4361 100.362 15.9568 100.036 15.6488C99.711 15.3408 99.2993 15.1868 98.7996 15.1868H98.7242C98.2245 15.1868 97.808 15.3581 97.4749 15.6991C97.1417 16.0401 96.9752 16.5524 96.9752 17.2359V24.705H92.6537V12.2152H96.675V12.2906C96.675 12.6568 96.6169 13.0449 96.5006 13.4519C96.3843 13.8605 96.2099 14.3555 95.9757 14.9385C95.9254 15.0878 95.8673 15.2543 95.8013 15.4382C95.7353 15.6221 95.6677 15.8216 95.6017 16.0385L96.0763 16.1878L96.2507 15.5875C96.7002 14.2062 97.2753 13.2476 97.9746 12.7149C98.6739 12.1822 99.4989 11.915 100.448 11.915C101.397 11.915 102.147 12.1366 102.745 12.5766C103.344 13.0182 103.644 13.6468 103.644 14.4623C103.644 14.9118 103.536 15.4775 103.319 16.1611L103.768 16.2616C104.534 13.3639 106.182 11.915 108.714 11.915C109.863 11.915 110.816 12.2812 111.574 13.0135L111.579 13.0119Z"
        fill="#141414"
      />
    </SvgIcon>
  );
}
