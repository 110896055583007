import { Button, styled } from '@mui/material';

export const StyledIconButton = styled(Button)(({ theme }) => ({
  borderRadius: '0.5rem',
  borderColor: theme.palette.divider,
  minWidth: '2.5rem',
  width: '2.5rem',
  height: '2.5rem',
  padding: '0.625rem',
  '&:hover': {
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.grey[100]
  },
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
