import { styled, TextField, TextFieldProps } from '@mui/material';

export const TextInputStyled = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: theme.spacing(2.5, 1),
    [theme.breakpoints.up('md')]: {
      ...theme.typography.overline,
      padding: theme.spacing(0.5, 1)
    }
  }
}));
