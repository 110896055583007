import { Avatar, styled } from '@mui/material';

export const SearchResultStyled = styled('li')(({ theme }) => ({
  '&.MuiAutocomplete-option': {
    display: 'flex',
    maxHeight: '1.875rem',
    padding: theme.spacing(1, 2),
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: `${theme.palette.grey[300]} !important`
    }
  }
}));

export const SearchResultIconStyled = styled(Avatar)(({ theme }) => ({
  height: '1rem',
  marginRight: '.5rem',
  width: '1rem'
}));
