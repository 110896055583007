import { Box, Button, Stack, Typography } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { ErrorForm } from 'components/labels';
import { BUTTON_LABELS } from 'constants/button.labels';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { UI_LABELS } from 'constants/ui-labels';
import { useRoomService } from 'hooks/services';
import useNotification from 'hooks/use-notification';
import { AddMemberParams } from 'model';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Position, Severity, UserRoomRole } from 'types/enum';
import DaoMembershipSelector from '../dao-members-selector/DaoMemberSelector';
import AddMemberProps from './AddMember.props';
import { useDao } from 'hooks';

const AddMember = ({ handlerClose }: AddMemberProps): React.ReactElement => {
  const [daoMemberships, setDaoMemberships] = useState<DaoMembershipDTO[]>([]);
  const { ADD, CANCEL } = BUTTON_LABELS;
  const { roomId } = useParams();
  const { currentDao } = useDao();
  const { createRoomMembership } = useRoomService();
  const { notify } = useNotification();
  const { REQUIRED } = COMMON_VALIDATIONS;
  const { ROOM_LABEL } = UI_LABELS;
  const hookFormProps = useForm<AddMemberParams>({
    defaultValues: {
      roomRole: UserRoomRole.MEMBER
    },
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = hookFormProps;

  const handleSubmission = async () => {
    if (!currentDao.id || !roomId) return;

    daoMemberships.forEach(async (membership) => {
      try {
        await createRoomMembership(currentDao.id, roomId, {
          userId: membership.userId,
          role: UserRoomRole.MEMBER
        });

        notify(`${membership.user.username} Added`, { position: Position.BottomCenter });
      } catch (error: any) {
        const errorMessage = error?.response?.data?.message;
        notify(`Error adding ${membership.user.username}: ${errorMessage}`, {
          position: Position.BottomCenter,
          severity: Severity.Error
        });
      }
    });
  };

  return (
    <FormProvider {...hookFormProps}>
      <form
        data-testid="add-member-form"
        style={{ paddingTop: '0.375rem' }}
        onSubmit={handleSubmit(handleSubmission)}
      >
        <Stack direction="column" gap="4rem">
          <Stack direction="column" gap="1rem">
            <Typography>{ROOM_LABEL}</Typography>
            <>
              <Controller
                control={control}
                name="roomMembers"
                rules={{ required: REQUIRED }}
                render={({ field }) => (
                  <DaoMembershipSelector
                    daoMemberSelected={(members) => {
                      field.onChange(members);
                      setDaoMemberships([...members]);
                    }}
                  />
                )}
              />
              {errors?.roomMembers && <ErrorForm message={REQUIRED} />}
            </>
          </Stack>

          <Box style={{ display: 'flex', gap: '1rem' }}>
            <Button type="submit" data-testid="submit" variant="contained" size="large">
              {ADD}
            </Button>
            <Button variant="outlined" onClick={handlerClose} size="large">
              {CANCEL}
            </Button>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AddMember;
