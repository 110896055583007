import { Auth, User } from 'firebase/auth';

export interface AuthContextType {
  auth: Auth;
  registeringUser: boolean;
  token: string;
  logOut: (redirect?: boolean) => void;
  setRegisteringUser: (value: boolean) => void;
  forgotPassword: (email: string) => Promise<void>;
  authProviderUser?: User;
  isEnrolledInMfa?: boolean;
}

export const initAuthContextType = (auth = {} as Auth): AuthContextType => ({
  auth,
  registeringUser: false,
  isEnrolledInMfa: false,
  token: '',
  logOut: () => {},
  setRegisteringUser: () => {},
  forgotPassword: (email: string) => new Promise((res, rej) => res())
});
