/**
 * convert number into a reader friendly format.
 *
 * @example 1000 =  1K
 * */
export const abbreviateNumber = (value: number): string => {
  return value >= 1000 ? `${value / 1000}K` : `${value}`;
  // TODO: Check this function in the future to improve it

  // let newValue = '' + value;
  // if (value >= 1000) {
  //   const suffixes = ['', 'k', 'm', 'b', 't'];
  //   const suffixNum = Math.floor(('' + value).length / 3);
  //   let shortValue = 0;
  //   for (var precision = 2; precision >= 1; precision--) {
  //     shortValue = parseFloat(
  //       (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
  //     );
  //     var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
  //     if (dotLessShortValue.length <= 2) {
  //       break;
  //     }
  //   }

  //   newValue = (shortValue % 1 !== 0 ? shortValue.toFixed(1) : shortValue) + suffixes[suffixNum];
  // }

  // return newValue;
};
