import { List, ListItemButton, ListItemButtonProps, Radio, styled } from '@mui/material';

const ListStyled = styled(List)(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  maxHeight: '11.5rem',
  padding: '0 !important'
}));

const ListItemButtonStyled = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.secondary
  },
  padding: 0,
  '& .MuiListItemIcon-root': {
    marginRight: '.5rem'
  },
  marginBottom: '1rem'
}));

const RadioStyled = styled(Radio)({
  padding: 0
});

export { RadioStyled, ListStyled, ListItemButtonStyled };
