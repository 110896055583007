import {
  Avatar,
  Checkbox,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SearchIcon from 'assets/SearchIcon';
import { SEARCH_LABELS } from 'constants/ui-labels';
import { useDaoService } from 'hooks/services';
import DaoMemberSelectorProps from './DaoMemberSelector.props';
import { MemberOptionContainerStyled, TextFieldStyled } from './DaoMemberSelector.style';
import { useDao } from 'hooks';
import { PAGINATION } from 'constants/app-config';
import { UserDaoRole } from 'types/enum';

const DaoMembershipSelector = ({
  daoMemberSelected
}: DaoMemberSelectorProps): React.ReactElement => {
  const { roomId } = useParams();
  const { currentDao } = useDao();
  const { getDaoMemberships } = useDaoService();
  const [daoMemberships, setDaoMemberships] = useState<DaoMembershipDTO[]>([]);
  const [daoMembershipsCopy, setDaoMembershipsCopy] = useState<DaoMembershipDTO[]>([]);
  const [selectedMemberships, setSelectedMemberships] = useState<DaoMembershipDTO[]>(
    [] as DaoMembershipDTO[]
  );

  useEffect(() => {
    if (!currentDao.id || !roomId) return;

    const init = async () => {
      const { data: memberships } = await getDaoMemberships(currentDao.id, {
        excludedRoomIds: [roomId],
        page: 0,
        size: PAGINATION.LARGE_PAGE_SIZE,
        roles: [UserDaoRole.ADMIN, UserDaoRole.OWNER, UserDaoRole.MEMBER]
      });
      setDaoMembershipsCopy([...memberships]);
      setDaoMemberships([...memberships]);
    };

    init();
  }, [currentDao, getDaoMemberships, roomId]);

  const handleMemberSelected = (member: DaoMembershipDTO) => {
    const index = selectedMemberships.indexOf(member);
    if (index === -1) {
      selectedMemberships.push(member);
    } else {
      selectedMemberships.splice(index, 1);
    }
    daoMemberSelected(selectedMemberships);
    setSelectedMemberships(selectedMemberships);
  };

  const handleSearchInputChange = (wordToSearch: string): void => {
    if (wordToSearch.length === 0) {
      setDaoMemberships([...daoMembershipsCopy]);
    } else {
      const filteredMembers = daoMembershipsCopy.filter((data) =>
        data.user.username.toLocaleLowerCase().includes(wordToSearch)
      );
      setDaoMemberships([...filteredMembers]);
    }
  };

  return (
    <>
      <Select
        id="dao-membership-select-form"
        name="dao-membership-select"
        inputProps={{
          'data-testid': 'dao-membership-select'
        }}
        fullWidth
        multiple
        displayEmpty
        value={selectedMemberships}
        renderValue={(selected) => {
          if (selected.length === 0) return SEARCH_LABELS.DAO_MEMBERS;
          return selected.map((mem: DaoMembershipDTO) => mem.user.username).join(', ');
        }}
        disabled={!daoMemberships.length}
      >
        <ListSubheader>
          <TextFieldStyled
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon width="1rem" height="1rem" style={{ fontSize: '1rem' }} />
                </InputAdornment>
              )
            }}
            style={{ width: '100%', minHeight: '1.8rem' }}
            onChange={(e) => handleSearchInputChange(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
            type="search"
          />
        </ListSubheader>
        {daoMemberships.map((membership: DaoMembershipDTO, index: number) => {
          return (
            <MenuItem
              key={membership.id}
              value={membership.id}
              onClick={() => handleMemberSelected(membership)}
              style={{ marginLeft: '0.5rem' }}
            >
              <Stack flexDirection="row" alignItems="center" width="100%">
                <Checkbox checked={selectedMemberships.indexOf(membership) > -1} />
                <MemberOptionContainerStyled>
                  <Avatar src={membership.user.profileThumbnail} />
                  <Typography variant="caption">{membership.user.username}</Typography>
                </MemberOptionContainerStyled>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default DaoMembershipSelector;
