import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderOptionState,
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { TagDTO } from '@piefi-platform/types-lib';
import { CancelIcon } from 'assets';
import { EllipsisMenu, InputForm, TagChip } from 'components';
import BaseModal from 'components/modals';
import { COMMON_VALIDATIONS } from 'constants/common-validations.labels';
import { TAG_MANAGER_LABELS } from 'constants/tags.label';
import { useDao, useEventEmitter } from 'hooks';
import { useTagService } from 'hooks/services';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BRAND, COLOR_PICKER_COLORS } from 'theme/palette';
import ColorPicker from '../color-picker/ColorPicker';
import TagsManagerProps from './TagsManager.props';

const TagsManager = ({
  onCreateAndAttach,
  onDetach,
  tagsAssigned
}: TagsManagerProps): React.ReactElement => {
  const { emitEvent } = useEventEmitter();
  const { currentDao } = useDao();
  const { getTags, updateTag, deleteTag } = useTagService();
  const [selectedTags, setSelectedTags] = useState<TagDTO[]>(tagsAssigned || []);
  const [tagOptions, setTagOptions] = useState<TagDTO[]>([]);

  const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false);
  const [manageTagFormOpen, setManageTagFormOpen] = useState<boolean>(false);

  const [tagToEdit, setTagToEdit] = useState<TagDTO>();
  const [tagFilterText, setTagFilterText] = useState<string>();

  const { BACK, TAG_COLOR, EDIT_TAGS, SELECT_OR_CREATE_NEW, SAVE, DELETE, TAG_NAME } =
    TAG_MANAGER_LABELS;

  useEffect(() => {
    setSelectedTags([...tagsAssigned]);
  }, [tagsAssigned]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, isValid }
  } = useForm<TagDTO>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: '',
      name: tagToEdit?.name || '',
      color: tagToEdit?.color
    }
  });

  const isTagUnique = useCallback(
    (name: string, id?: string): boolean => {
      if (id) {
        return tagOptions.find((tag) => tag.id !== id && tag.name === name) === undefined;
      }

      return tagOptions.find((tag) => tag.name === name) === undefined;
    },
    [tagOptions]
  );

  const toggleAutoCompleteOpen = useCallback(() => {
    setAutoCompleteOpen(!autoCompleteOpen);
  }, [setAutoCompleteOpen, autoCompleteOpen]);

  const handleTagInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTagFilterText(event.target.value.toLowerCase());
    },
    [setTagFilterText]
  );

  const handleTagInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      // https://www.w3.org/TR/uievents-key/#keys-modifier
      event.stopPropagation();

      if (event.key === 'Enter') {
        if (!tagFilterText || !isTagUnique(tagFilterText.toLocaleLowerCase())) {
          return;
        }

        const index = Math.floor(Math.random() * COLOR_PICKER_COLORS.length);
        const randomColor = COLOR_PICKER_COLORS[index];

        const newTag: Partial<TagDTO> = {
          name: tagFilterText.toLocaleLowerCase(),
          color: randomColor
        };

        onCreateAndAttach && onCreateAndAttach(newTag);
      }
    },
    [isTagUnique, tagFilterText, tagOptions.length]
  );

  const toggleManageTagForm = useCallback(() => {
    setManageTagFormOpen(!manageTagFormOpen);
  }, [setManageTagFormOpen, manageTagFormOpen]);

  const handleCloseTagManager = useCallback(() => {
    setManageTagFormOpen(false);
    setTagToEdit(undefined);
    setAutoCompleteOpen(false);
  }, [setManageTagFormOpen, selectedTags]);

  const handleEditTagClick = useCallback(
    (tag: TagDTO) => {
      setTagToEdit(tag);
      reset(tag);
      setManageTagFormOpen(true);
    },
    [setTagToEdit, reset]
  );

  const handleTagColorChange = useCallback(
    (color: string) => {
      if (!color) return;
      setValue('color', color);
    },
    [setValue]
  );

  const handleSubmitTagForm = useCallback(
    async (tag: TagDTO) => {
      const updatedTag = await updateTag(tag.id, currentDao.id, {
        color: tag.color,
        name: tag.name
      });
      emitEvent('ON_TAG_UPDATE', { data: updatedTag.data });
      setManageTagFormOpen(false);
    },
    [isTagUnique, setManageTagFormOpen]
  );

  const handleTagDelete = useCallback(
    async (tag: TagDTO) => {
      await deleteTag(tag.id, currentDao.id);
      emitEvent('ON_TAG_DELETE', { data: tag });
      setManageTagFormOpen(false);
    },
    [setManageTagFormOpen]
  );

  const renderOption = useCallback(
    (props: any, option: TagDTO, state: AutocompleteRenderOptionState) => {
      if (state.selected) return;
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginLeft="1rem"
          marginRight="1rem"
          key={option.name}
        >
          <TagChip
            {...props}
            label={option.name}
            backgroundColor={option.color}
            onClick={() => onCreateAndAttach({ ...option })}
          />
          <EllipsisMenu>
            <MenuItem onClick={() => handleEditTagClick(option)}>{EDIT_TAGS}</MenuItem>
          </EllipsisMenu>
        </Stack>
      );
    },
    [EDIT_TAGS, handleEditTagClick]
  );

  const renderTags = useCallback(
    (value: TagDTO[], getTagProps: AutocompleteGetTagProps) =>
      value.map((option, index) => (
        <TagChip
          label={option.name}
          backgroundColor={option.color}
          {...getTagProps({ index })}
          icon={
            <EllipsisMenu>
              <MenuItem onClick={() => handleEditTagClick(option)}>{EDIT_TAGS}</MenuItem>
            </EllipsisMenu>
          }
          deleteIcon={<CancelIcon />}
          onDelete={() => onDetach(option)}
          key={option.name}
        />
      )),
    [EDIT_TAGS, handleEditTagClick]
  );

  useEffect(() => {
    const retrieveTags = async () => {
      if (!currentDao.id) return;

      const { data: tags } = await getTags(currentDao.id, { page: 0, size: 100 }); // TODO: paginate tags
      setTagOptions(tags);
    };
    retrieveTags();
  }, [currentDao.id, getTags]);

  return (
    <>
      <Box minWidth={'15rem'}>
        {!manageTagFormOpen && (
          <Autocomplete
            disableClearable
            disableCloseOnSelect
            open={autoCompleteOpen}
            onOpen={toggleAutoCompleteOpen}
            onClose={toggleAutoCompleteOpen}
            multiple
            options={tagOptions}
            getOptionLabel={(option) => option.name}
            value={selectedTags}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                id="tag-input"
                variant="outlined"
                placeholder={SELECT_OR_CREATE_NEW}
                value={tagFilterText}
                onKeyDown={handleTagInputKeyDown}
                onChange={handleTagInputChange}
                inputProps={{
                  ...params.inputProps,
                  maxLength: 64
                }}
              />
            )}
            renderOption={renderOption}
            renderTags={renderTags}
          />
        )}
        {/* Form below is single tag editor / color picker */}
        {manageTagFormOpen && (
          <BaseModal toggle={manageTagFormOpen} close={toggleManageTagForm}>
            <form onSubmit={handleSubmit(handleSubmitTagForm)}>
              <Stack direction="column" padding=".75rem .5rem">
                <Typography marginBottom=".5rem">{TAG_NAME}</Typography>
                <Controller
                  name={'name'}
                  control={control}
                  rules={{
                    required: COMMON_VALIDATIONS.REQUIRED,
                    maxLength: 64
                  }}
                  render={({ field, fieldState }) => (
                    <InputForm
                      {...field}
                      hiddenLabel
                      {...register('name', { required: true })}
                      error={!!fieldState.error}
                      inputProps={{
                        required: true,
                        maxLength: 64
                      }}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Typography marginBottom=".5rem" marginTop="1.5rem">
                  {TAG_COLOR}
                </Typography>
                <ColorPicker
                  defaultColor={tagToEdit?.color || BRAND.red.secondary}
                  onColorSelected={handleTagColorChange}
                />
                <Stack gap={0.5} direction="row">
                  <Button
                    variant="outlined"
                    size="extraLarge"
                    onClick={handleCloseTagManager}
                    fullWidth
                  >
                    {BACK}
                  </Button>
                  <Button
                    disabled={isSubmitting || !isValid || !tagToEdit?.color || !tagToEdit.name}
                    type="button"
                    onClick={() => handleTagDelete(tagToEdit!)}
                    variant="contained"
                    size="extraLarge"
                    fullWidth
                  >
                    {DELETE}
                  </Button>
                  <Button
                    disabled={isSubmitting || !isValid || !tagToEdit?.color || !tagToEdit.name}
                    type="submit"
                    variant="contained"
                    size="extraLarge"
                    fullWidth
                  >
                    {SAVE}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </BaseModal>
        )}
      </Box>
    </>
  );
};

export default TagsManager;
