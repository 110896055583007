import { Box, Grid, Skeleton } from '@mui/material';

const ItemRequestSkeleton = (): React.ReactElement => {
  return (
    <Box style={{ width: '50%', marginInlineStart: '0.85rem' }}>
      <Grid container>
        <Grid item md={8}>
          <Skeleton variant="rectangular" height={'0.8rem'} style={{ marginBlockEnd: '0.2rem' }} />
          <Skeleton variant="rectangular" height={'0.8rem'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemRequestSkeleton;
