export const EmailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

/**
 * @description
 * for local its any string
 *
 * for anything else it is:
 *  1. (?=.*[a-z]) - small chars
 *  2. (?=.*[A-Z]) - large chars
 *  3. (?=.*\d) - number
 *  4. '[@$!%*?&#^()\-_=+{}[\]:;,.<>\/\\]' - '@$!%*?&#' supported chars
 *  5. {8,} - min 8 characters long
 */
export const PasswordRegex =
  process.env.REACT_APP_ENVIRONMENT === 'local'
    ? /.*/
    : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#^()\-_=+{}[\]:;,.<>/\\]{8,}$/;

export const MIN_AGE_TO_REGISTER = 16;
