import { Stack, styled } from '@mui/material';

const StackStyled = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  cursor: 'pointer',
  flex: 'initial',
  width: 'inherit',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  paddingLeft: '0.5rem',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
    borderRadius: '0.5rem'
  },
  '& p': {
    color: `${theme.palette.common.black} !important`,
    display: 'initial',
    fontWeight: isActive ? '750 !important' : '550 !important',
    maxWidth: '8rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

export { StackStyled };
