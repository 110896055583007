import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function TwitterIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} viewBox="0 0 14 12">
      <path
        d="M13.6663 1.86668C13.1652 2.08408 12.6353 2.22779 12.093 2.29335C12.6651 1.95156 13.0939 1.41387 13.2997 0.780018C12.762 1.10006 12.1735 1.32555 11.5597 1.44668C11.1494 1.00173 10.603 0.705549 10.0062 0.604585C9.40944 0.503622 8.79604 0.60359 8.2622 0.888811C7.72837 1.17403 7.3043 1.62837 7.0565 2.18058C6.80871 2.73278 6.7512 3.35161 6.89301 3.94002C5.80596 3.88504 4.74264 3.60199 3.77211 3.10926C2.80159 2.61653 1.94557 1.92515 1.25967 1.08002C1.0191 1.50013 0.892687 1.9759 0.893008 2.46002C0.892155 2.90961 1.00249 3.35243 1.21418 3.74906C1.42587 4.14569 1.73235 4.48382 2.10634 4.73335C1.67166 4.72152 1.24626 4.60488 0.866341 4.39335V4.42668C0.869599 5.05661 1.09034 5.66608 1.49122 6.15199C1.8921 6.63791 2.44851 6.97045 3.06634 7.09335C2.82851 7.16573 2.58159 7.20389 2.33301 7.20668C2.16093 7.20467 1.98929 7.18907 1.81967 7.16002C1.99561 7.70189 2.33609 8.17545 2.79372 8.51479C3.25136 8.85413 3.80339 9.04239 4.37301 9.05335C3.41114 9.8102 2.2236 10.2233 0.999674 10.2267C0.776831 10.2274 0.554165 10.2141 0.333008 10.1867C1.58263 10.9935 3.03888 11.4218 4.52634 11.42C5.5528 11.4307 6.5711 11.2367 7.52175 10.8494C8.4724 10.4621 9.33636 9.88927 10.0631 9.16435C10.7899 8.43942 11.365 7.57694 11.7547 6.62729C12.1444 5.67763 12.341 4.65984 12.333 3.63335C12.333 3.52002 12.333 3.40002 12.333 3.28002C12.8561 2.88989 13.3073 2.41163 13.6663 1.86668V1.86668Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
