import { IconButton, Menu, MenuItemProps, useTheme } from '@mui/material';
import { EllipsisIcon } from 'assets';
import React, { Children, cloneElement, forwardRef, useState } from 'react';
import EllipsisMenuProps from './EllipsisMenu.props';

const EllipsisMenu = forwardRef<HTMLButtonElement, EllipsisMenuProps>(
  (
    {
      children,
      bigIcon = false,
      center = false,
      darker = false,
      disabled = false,
      style,
      onClick
    }: EllipsisMenuProps,
    forwardedRef: React.Ref<HTMLButtonElement>
  ): React.ReactElement => {
    const menuItems = Children.toArray(children) as React.ReactElement<MenuItemProps>[];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const selectOption = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      onClick && onClick(event);
      event.stopPropagation();
    };
    const closeMenu = () => setAnchorEl(null);
    const bigIconStyles = bigIcon ? { height: '1.25rem', width: '1.25rem' } : {};
    const iconColor = darker ? theme.palette.common.black : theme.palette.grey[700];

    return (
      <>
        <IconButton
          ref={forwardedRef}
          data-testid="ellipsis-button"
          size="small"
          onClick={selectOption}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={disabled}
          style={style}
        >
          <EllipsisIcon fontSize="inherit" style={bigIconStyles} fill={iconColor} />
        </IconButton>
        <Menu
          data-testid="ellipsis-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          PopoverClasses={{ paper: 'actions-menu' }}
        >
          {Children.map(menuItems, (child: React.ReactElement<MenuItemProps>) =>
            cloneElement(child, {
              onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                child.props.onClick?.call(undefined, event);
                closeMenu();
              }
            })
          )}
        </Menu>
      </>
    );
  }
);

export default EllipsisMenu;
