import { Grid, Stack } from '@mui/material';
import { PatronageActivitiesQueryDTO, PatronageActivityDTO } from '@piefi-platform/types-lib';
import ActivityCard from 'components/cards/activity-card/ActivityCard';
import { PAGINATION } from 'constants/app-config';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useDao } from 'hooks';
import { usePatronageActivityService } from 'hooks/services';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import { Container } from '../DaoActions.style';

const ActionActivityList = (): React.ReactElement => {
  const { currentDao, activeDaoMembership } = useDao();
  const { getPatronageActivities } = usePatronageActivityService();
  const [activities, setActivities] = useState<PatronageActivityDTO[]>([]);
  const [page, setPage] = useState<number>(0);
  const [lastList, setLastList] = useState<boolean>(false);
  const listInnerRef = useRef<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const isAdminUser = useRef<boolean>(
    compareDaoRoles(UserDaoRole.ADMIN, activeDaoMembership?.role as UserDaoRole)
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setPage(page + 1);
      }
    }
  };

  const getActivities = useCallback(async () => {
    try {
      const { data: activities } = await getPatronageActivities(currentDao.id, {
        page: page,
        size: PAGINATION.PATRONAGE_ACTIVITIES,
        convertToBits: true,
        useAdminView: isAdminUser.current,
        includeBudget: true
      } as PatronageActivitiesQueryDTO);

      if (activities.length < PAGINATION.PATRONAGE_ACTIVITIES) setLastList(true);

      setActivities((prevActivities) => [...prevActivities, ...activities]);
    } catch (error) {
      console.error(error);
    }
  }, [getPatronageActivities, currentDao.id, page]);

  useEffect(() => {
    if (currentDao.id && !lastList) getActivities();
  }, [currentDao.id, lastList, page, getActivities]);

  const handleClick = useCallback(
    (activity: PatronageActivityDTO) => {
      navigate(
        `${ROOT_ROUTE.DAO(
          (currentDao as any).whiteLabelSubDomain
        )}/${ROUTES.DAO_COMMONS_ACTIVITIES()}/${ROUTES.DAO_ACTIVITY_DETAILS_PAGE(
          activity.id as string
        )}`,
        {
          state: { redirectLocation: currentUrl }
        }
      );
    },
    [currentDao.id, currentUrl, navigate]
  );

  const renderedActivities = useMemo(
    () =>
      activities.map((activity: PatronageActivityDTO) => (
        <Stack key={activity.id} paddingBottom={'1rem'}>
          <ActivityCard activity={activity} onClick={() => handleClick(activity)} />
        </Stack>
      )),
    [activities]
  );

  return (
    <Grid item xs={12} height={'100%'}>
      <Container onScroll={onScroll} ref={listInnerRef}>
        {renderedActivities}
      </Container>
    </Grid>
  );
};

export default ActionActivityList;
