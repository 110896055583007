import { ListItemButton, styled } from '@mui/material';

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'openDrawer'
})<{ openDrawer: boolean }>(({ theme, openDrawer }) => ({
  width: openDrawer ? 'auto' : '3rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: openDrawer ? 'initial' : 'center',
  transition: 'all 0.3s ease',
  '&:hover': {
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.grey[300],
    maxWidth: openDrawer ? 'none' : '3rem'
  }
}));
