import { firebaseApp } from 'config';
import { STREAM_CONFIG } from 'constants/app-config';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import {
  Auth,
  User,
  getAuth,
  multiFactor,
  onIdTokenChanged,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';
import { useLocalStorage } from 'hooks';
import { AuthContextType, initAuthContextType } from 'model';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { StreamChat } from 'stream-chat';

const initialState: AuthContextType = initAuthContextType(getAuth(firebaseApp));

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [auth] = useState<Auth>(initialState.auth);
  const [token, setToken] = useLocalStorage('token', '');
  const [registeringUser, setRegisteringUser] = useState<boolean>(initialState.registeringUser);
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const [authUser, setAuthUser] = useState<User>();
  const [isEnrolledInMfa, setIsInEnrolledInfMfa] = useState(false);

  const updateRegisteringUser = useCallback((registering: boolean) => {
    setRegisteringUser(registering);
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = onIdTokenChanged(auth, async (user) => {
      try {
        if (user) {
          const isRemovingMFA = window.location.href.includes('mode=revertSecondFactorAddition');
          if (isRemovingMFA) logOut();

          const token = (await auth.currentUser?.getIdToken(false)) ?? '';
          setToken(token);
          setAuthUser(user);

          const data = multiFactor(user);
          const isEnrolled = data.enrolledFactors.length > 0;
          setIsInEnrolledInfMfa(isEnrolled);
          const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';
          if (!isLocal && (!user.emailVerified || !isEnrolled)) {
            const isVerifyingEmail = window.location.pathname.includes(
              ROUTES.USER_ACCOUNT_MANAGEMENT
            );
            if (!isVerifyingEmail) navigate(ROUTES.VERIFICATION);
            return;
          }
        }
      } catch {}
    });

    return () => unregisterAuthObserver();
  }, [auth]);

  const logOut = useCallback(
    async (redirect: boolean = true): Promise<void> => {
      try {
        if (token) {
          const client = StreamChat.getInstance(STREAM_CONFIG.API_KEY);
          setAuthUser(undefined);
          await client.disconnectUser();
          localStorage.clear();
          await signOut(auth);
          setToken('');
        }
        redirect && navigateRef.current(ROOT_ROUTE.AUTH, { replace: true });
      } catch (error) {
        throw error;
      }
    },
    [auth, token, setToken]
  );

  const forgotPassword = async (email: string) => {
    const auth = getAuth();

    const req = await sendPasswordResetEmail(auth, email);
    return req;
  };

  const providerValue = useMemo<AuthContextType>(
    () => ({
      auth,
      registeringUser,
      token,
      logOut,
      setRegisteringUser: updateRegisteringUser,
      forgotPassword,
      isEnrolledInMfa,
      authProviderUser: authUser
    }),
    [auth, token, logOut, registeringUser, updateRegisteringUser, authUser, isEnrolledInMfa]
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
export { AuthContext };
