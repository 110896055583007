import { styled } from '@mui/material';
import AddIcon from 'assets/AddIcon';

const SecondaryAddIcon = styled(AddIcon)(({ theme }) => ({
  height: '0.6668rem!important',
  width: '0.6668rem!important',
  '& path': {
    fill: theme.palette.common.white
  }
}));

export { SecondaryAddIcon };
