import { Card, IconButton, styled } from '@mui/material';

const CardStyled = styled(Card, {
  shouldForwardProp: (prop) =>
    prop !== 'borderWidth' &&
    prop !== 'borderColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'includeHighlight'
})<{
  borderWidth?: string;
  borderColor?: string;
  backgroundColor?: string;
  includeHighlight?: boolean;
}>(({ theme, borderWidth, borderColor, backgroundColor, includeHighlight }) => ({
  '&.MuiCard-root': {
    padding: '1.5rem',
    borderRadius: '0.75rem',
    border: `${borderWidth || '1px'} solid ${borderColor || theme.palette.divider}`,
    backgroundColor: `${backgroundColor || '#fff'}`,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      borderLeft: includeHighlight ? `30px solid ${theme.palette.common.black}` : '0',
      borderBottom: includeHighlight ? '30px solid transparent' : '0'
    }
  }
}));

const IconButtonStyled = styled(IconButton)({
  position: 'absolute',
  right: '0.75rem',
  top: '0.75rem'
});

export { CardStyled, IconButtonStyled };
