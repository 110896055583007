import { Button, Typography } from '@mui/material';
import { ArrowNarrowUpRight } from 'assets/icons/arrows';
import { BUTTON_LABELS } from 'constants/button.labels';
import { FontStyles, ViewAllButtonProps } from './ViewAllButton.props';

const ViewAllButton = ({
  onClick,
  backgroundColor,
  size = 'medium',
  fontStyles = {},
  sx = {}
}: ViewAllButtonProps): React.ReactElement => {
  const { VIEW_ALL } = BUTTON_LABELS;

  const mergeFontStyles = (defaultStyles: FontStyles, overrideStyles: FontStyles) => {
    return {
      variant: overrideStyles.variant || defaultStyles.variant,
      fontWeight: overrideStyles.fontWeight || defaultStyles.fontWeight,
      color: overrideStyles.color || defaultStyles.color
    };
  };

  const defaultFontStyles: FontStyles = {
    variant: 'body2',
    fontWeight: '',
    color: ''
  };

  const mergedFontStyles = mergeFontStyles(defaultFontStyles, fontStyles);

  return (
    <Button
      variant={'text'}
      size={size}
      endIcon={
        <ArrowNarrowUpRight
          height={18}
          width={18}
          stroke={mergedFontStyles.color?.toString() || ''}
        />
      }
      onClick={onClick}
      sx={{
        width: 'fit-content',
        border: 'none',
        '&:hover': {
          border: 'none',
          backgroundColor: `${backgroundColor ? backgroundColor : 'none'}`
        },
        ...sx
      }}
    >
      <Typography
        variant={mergedFontStyles.variant}
        fontWeight={mergedFontStyles.fontWeight}
        color={mergedFontStyles.color}
      >
        {VIEW_ALL}
      </Typography>
    </Button>
  );
};

export default ViewAllButton;
