import { Avatar, Box, Grid, Stack, styled } from '@mui/material';
import { HorizontalCardVariant } from 'types/enum';

const ActionContainerStyled = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.grey[500],
  display: 'flex',
  justifyContent: 'center',
  opacity: '0'
}));

const HorizontalCardContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'cardType'
})<{
  cardType: HorizontalCardVariant;
}>(({ theme, cardType }) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  padding: '1rem',
  '&:hover, :focus-within': {
    backgroundColor: theme.palette.grey[300],
    '& [data-action="actions"]': {
      opacity: '1'
    }
  },
  [theme.breakpoints.up('md')]: {
    height: cardType === HorizontalCardVariant.User ? '3.125rem' : '5.5rem'
  }
}));

const TitleContainerStyled = styled(Stack)(({ theme }) => ({
  '& .MuiTypography-root': {
    color: theme.palette.text.secondary
  }
}));

const TitleAvatarStyled = styled(Avatar)(({ theme }) => ({
  height: '1rem',
  width: '1rem'
}));

export {
  ActionContainerStyled,
  HorizontalCardContainerStyled,
  TitleAvatarStyled,
  TitleContainerStyled
};
