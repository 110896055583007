import { DaoMembershipDTO, UserEntityDTO } from '@piefi-platform/types-lib';

export interface InviteProperties {
  manageableEntityId: string;
  inviteId: string;
  temporaryInvitationId?: string;
}

export interface CryptoAssetVerificationConfiguration {
  shouldCreateCryptoAsset?: boolean;
  shouldAssociateToken?: boolean;
}

export default interface UserContextType {
  userInfo?: UserEntityDTO;
  inviteProperties?: InviteProperties;
  fetchAndSetUser: (
    showLoadingState?: boolean,
    initiateCascadeLoading?: boolean
  ) => Promise<UserEntityDTO | undefined>;
  daoMemberships: DaoMembershipDTO[];
  deleteInviteProps: () => void;
  fetchUserDaoMemberships: () => Promise<void>;
}

export const initUserContextType = (): UserContextType => ({
  userInfo: undefined,
  daoMemberships: [],
  inviteProperties: {} as InviteProperties,
  fetchAndSetUser: (): Promise<UserEntityDTO | undefined> => Promise.resolve({} as UserEntityDTO),
  deleteInviteProps: () => {},
  fetchUserDaoMemberships: () => Promise.resolve()
});
