import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LightbulbWithCircle(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <circle cx="24" cy="24" r="24" fill="#181818" />
      <path
        d="M29.0899 14.8198C28.1702 14.0634 27.0933 13.5219 25.9376 13.2347C24.7819 12.9475 23.5767 12.9219 22.4099 13.1598C20.8563 13.4736 19.4309 14.2419 18.3146 15.3671C17.1983 16.4923 16.4414 17.9238 16.1399 19.4798C15.9198 20.6465 15.9598 21.8475 16.2571 22.997C16.5544 24.1464 17.1017 25.2162 17.8599 26.1298C18.5636 26.9239 18.967 27.9393 18.9999 28.9998V31.9998C18.9999 32.7955 19.316 33.5585 19.8786 34.1211C20.4412 34.6838 21.2043 34.9998 21.9999 34.9998H25.9999C26.7956 34.9998 27.5587 34.6838 28.1213 34.1211C28.6839 33.5585 28.9999 32.7955 28.9999 31.9998V29.1898C29.0335 28.019 29.4637 26.8943 30.2199 25.9998C31.5451 24.3606 32.1697 22.2646 31.9581 20.1675C31.7465 18.0703 30.7158 16.1413 29.0899 14.7998V14.8198ZM26.9999 31.9998C26.9999 32.265 26.8946 32.5194 26.707 32.7069C26.5195 32.8945 26.2652 32.9998 25.9999 32.9998H21.9999C21.7347 32.9998 21.4804 32.8945 21.2928 32.7069C21.1053 32.5194 20.9999 32.265 20.9999 31.9998V30.9998H26.9999V31.9998ZM28.6699 24.7598C27.6644 25.9524 27.0778 27.4419 26.9999 28.9998H24.9999V25.9998C24.9999 25.7346 24.8946 25.4803 24.707 25.2927C24.5195 25.1052 24.2652 24.9998 23.9999 24.9998C23.7347 24.9998 23.4804 25.1052 23.2928 25.2927C23.1053 25.4803 22.9999 25.7346 22.9999 25.9998V28.9998H20.9999C20.9736 27.4679 20.4069 25.9946 19.3999 24.8398C18.7356 24.0438 18.2888 23.0892 18.1033 22.0692C17.9177 21.0491 17.9996 19.9983 18.341 19.0194C18.6825 18.0404 19.2718 17.1666 20.0515 16.4832C20.8312 15.7997 21.7747 15.33 22.7899 15.1198C23.6625 14.9402 24.5641 14.9571 25.4293 15.1693C26.2945 15.3815 27.1016 15.7837 27.792 16.3468C28.4824 16.9098 29.0387 17.6195 29.4206 18.4243C29.8025 19.2292 30.0004 20.109 29.9999 20.9998C30.0073 22.3697 29.537 23.6993 28.6699 24.7598Z"
        fill="#FDFDFD"
      />
    </SvgIcon>
  );
}
