import { Button, Stack, Typography } from '@mui/material';
import { FeedPostDTO, ManageableEntityDTO } from '@piefi-platform/types-lib';
import {
  CommentCard,
  CommentContent,
  CommentDeleteModal,
  FeedCardContent,
  Meta,
  Proposal
} from 'components';
import { HTTP_STATUS } from 'constants/http-statuses';
import { MENU_ACTIONS } from 'constants/menu-actions.labels';
import { DELETE_OPTION } from 'constants/post-options-labels';
import { CONFIRMATION_LABELS } from 'constants/ui-labels';
import { useNotification } from 'hooks';
import { useDaoReportEntityService, usePostService } from 'hooks/services';
import { useCallback, useEffect, useState } from 'react';
import { ContentType, ReportedEntityState, Severity } from 'types/enum';
import { getContentTypeFromFeedPost } from 'utils';
import { FeedCardStyled } from '../card/FeedCard.style';
import { IFeedReportedProps } from './FeedReported.props';

const FeedReported = ({
  entity,
  handleEntity,
  handlePreviewReset
}: IFeedReportedProps): React.ReactElement => {
  const { DELETE, DISMISS } = MENU_ACTIONS;
  const { CONTENT_DELETED } = DELETE_OPTION;
  const { COMMENT_SUCCESSFUL } = CONFIRMATION_LABELS;
  const { post, comment } = entity.reportEntity ?? {};
  const { daoId, roomId } = entity;
  const { getPostById } = usePostService();
  const { updateReportEntity } = useDaoReportEntityService();
  const { notify } = useNotification();
  const [feedPost, setFeedPost] = useState<FeedPostDTO>();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const loadPost = async (postId: string) => {
      try {
        const postResponse = await getPostById(daoId!!, roomId!!, postId!!);
        if (postResponse.status === HTTP_STATUS.OK) {
          setFeedPost(postResponse.data);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (comment) {
      loadPost(comment.postId);
    } else {
      setFeedPost(post);
      setIsLoading(false);
    }
  }, [comment, daoId, roomId, post, getPostById]);

  const toggleDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen((prevState) => !prevState);
  }, []);

  const onCommentDeleted = useCallback(async () => {
    if (!entity?.reportEntity?.comment) return;
    const status = ReportedEntityState.Declined;

    const newEntity: ManageableEntityDTO = {
      ...entity,
      reportEntity: {
        ...entity.reportEntity,
        comment: {
          ...entity.reportEntity.comment,
          content: CONTENT_DELETED
        }
      }
    };
    handleEntity(newEntity);

    try {
      const { data } = await updateReportEntity(
        daoId,
        entity.id,
        entity.reportEntity.id ?? '',
        status
      );

      if (data?.reportEntity) {
        notify(`${COMMENT_SUCCESSFUL} ${status}`, {
          severity: Severity.Success
        });
      }
    } catch (error) {
      console.error(error);
    }

    handlePreviewReset(newEntity);
  }, [
    CONTENT_DELETED,
    COMMENT_SUCCESSFUL,
    daoId,
    entity,
    handleEntity,
    notify,
    updateReportEntity,
    handlePreviewReset
  ]);

  const handleOnDismiss = useCallback(async () => {
    if (!entity?.reportEntity) return;
    const status = ReportedEntityState.Declined;
    try {
      const { data } = await updateReportEntity(
        daoId,
        entity.id,
        entity.reportEntity.id ?? '',
        status
      );

      if (data?.reportEntity) {
        notify(`${COMMENT_SUCCESSFUL} ${status}`, {
          severity: Severity.Success
        });
      }
    } catch (error) {
      console.error(error);
    }
    handlePreviewReset(entity);
  }, [COMMENT_SUCCESSFUL, daoId, entity, updateReportEntity, notify, handlePreviewReset]);

  return (
    <>
      {!isLoading && (
        <>
          {feedPost && (
            <FeedCardStyled
              contentType={getContentTypeFromFeedPost(feedPost)}
              role="article"
              tabIndex={0}
            >
              <Stack direction="column" spacing={4} justifyContent="space-between" width={'100%'}>
                <Stack spacing={getContentTypeFromFeedPost(feedPost) !== ContentType.Post ? 2 : 1}>
                  <Typography variant="h6" component="h2">
                    {feedPost.title}
                  </Typography>
                  <FeedCardContent body={feedPost.content}>
                    {feedPost.proposal && (
                      <Proposal
                        feedItemId={feedPost.id}
                        proposal={feedPost.proposal ?? null}
                        room={feedPost.room}
                      />
                    )}
                  </FeedCardContent>
                </Stack>
                <Meta user={feedPost?.author?.user} date={feedPost.createdAt} />
              </Stack>
            </FeedCardStyled>
          )}

          {feedPost && comment && (
            <CommentCard>
              <CommentContent
                id={`comment-${comment.id}`}
                level={1}
                mainPost={feedPost}
                parentComment={comment}
                replyActionHandler={(value: any, callback: any): void => {}}
                showFooter={false}
              />
            </CommentCard>
          )}

          {comment && (
            <Stack direction="row" marginTop="1rem" spacing={2} justifyContent="flex-end">
              <Button
                data-testid="comment-delete-button"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleOnDismiss}
              >
                {DISMISS}
              </Button>
              <Button
                data-testid="comment-delete-button"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={toggleDeleteDialog}
              >
                {DELETE}
              </Button>
            </Stack>
          )}

          {isDeleteDialogOpen && roomId && comment && (
            <CommentDeleteModal
              entity={{ daoId, roomId, postId: comment.postId, commentId: comment.id }}
              open={isDeleteDialogOpen}
              onClose={toggleDeleteDialog}
              onCommentDeleted={onCommentDeleted}
            />
          )}
        </>
      )}
    </>
  );
};

export default FeedReported;
