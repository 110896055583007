import {
  BaseQueryParams,
  FeedMembershipDTO,
  FeedPostDTO,
  Room,
  RoomMember,
  RoomMembershipDTO,
  RoomUpdatePayload
} from '@piefi-platform/types-lib';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import generateQueryString from 'utils/helper/query-builder';
import useHttp from 'hooks/use-http';
import UseRoomService from './use-room-service.model';
import { roomUrl } from './use-room-service.url';

const useRoomService = (): UseRoomService => {
  const { get, post, put } = useHttp();

  const getRoomById = useCallback(
    (daoId: string, roomId: string): Promise<AxiosResponse<Room>> => {
      const url: string = `${roomUrl(daoId)}/${roomId}`;
      return get(url);
    },
    [get]
  );

  const getFeedByRoom = useCallback(
    (
      daoId: string,
      roomId: string,
      { page, size }: BaseQueryParams,
      type?: string,
      announcementsOnly?: boolean
    ): Promise<AxiosResponse<Array<FeedPostDTO>>> => {
      return get<Array<FeedPostDTO>>(
        `${roomUrl(daoId)}/${roomId}/feed?page=${page}&size=${size}${type ? `&type=${type}` : ''}${
          announcementsOnly ? `&announcementsOnly=${announcementsOnly}` : ''
        }`
      );
    },
    [get]
  );

  const getRoomMemberships = useCallback(
    (daoId: string, roomId: string): Promise<AxiosResponse<FeedMembershipDTO[]>> => {
      const queryString = generateQueryString({ page: 0, size: 100 });
      return get<FeedMembershipDTO[]>(
        `${roomUrl(daoId)}/${roomId}/room-memberships?${queryString}`
      );
    },
    [get]
  );

  const createRoom = useCallback(
    (daoId: string, room: Room): Promise<AxiosResponse<Room>> => {
      return post(`${roomUrl(daoId)}`, room);
    },
    [post]
  );

  const updateRoom = useCallback(
    (daoId: string, roomId: string, room: RoomUpdatePayload): Promise<AxiosResponse<Room>> => {
      const url: string = `${roomUrl(daoId)}/${roomId}`;
      return put(url, { ...room, description: !room.description ? null : room.description });
    },
    [put]
  );

  const createRoomMembership = useCallback(
    (daoId: string, roomId: string, membership: RoomMember): Promise<AxiosResponse<RoomMember>> => {
      return post(`${roomUrl(daoId)}/${roomId}/room-memberships`, membership);
    },
    [post]
  );

  const updateRoomMemberPermissions = useCallback(
    (
      daoId: string,
      roomId: string,
      role: string,
      roomMembershipId: string
    ): Promise<AxiosResponse<Array<RoomMembershipDTO>>> => {
      return put(`${roomUrl(daoId)}/${roomId}/room-memberships/${roomMembershipId}`, { role });
    },
    [put]
  );

  return {
    getRoomById,
    getFeedByRoom,
    getRoomMemberships,
    createRoom,
    updateRoom,
    createRoomMembership,
    updateRoomMemberPermissions
  };
};

export default useRoomService;
