enum RewardType {
  DAO_POINTS = 'DAO_POINTS',
  FIAT = 'FIAT'
}

const formatRewardType = (rewardType: RewardType): string => {
  switch (rewardType) {
    case RewardType.DAO_POINTS:
      return 'Points';
    default:
      return rewardType;
  }
};

export default RewardType;
export { formatRewardType };
