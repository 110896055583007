import { Stack, Typography } from '@mui/material';
import { AllocationAgreementDTO } from '@piefi-platform/types-lib';
import React, { useCallback } from 'react';
import { Passport } from '../../../assets/icons/travel';

interface Props {
  allocationAgreements: AllocationAgreementDTO[];
}

const AllocationAgreementList = ({ allocationAgreements = [] }: Props): React.ReactElement => {
  const handleAgreementClick = useCallback((agreement: AllocationAgreementDTO) => {
    const url = agreement.fileUrl;
    if (url) {
      window.open(url, '_blank');
    }
  }, []);

  return allocationAgreements.length > 0 ? (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Passport />
      {allocationAgreements.map((agreement) => (
        <Typography
          key={agreement.id}
          variant={'body2'}
          fontWeight={'bold'}
          onClick={() => handleAgreementClick(agreement)}
          style={{ cursor: 'pointer' }}
        >
          {agreement.fileName}
        </Typography>
      ))}
    </Stack>
  ) : (
    <></>
  );
};

export default AllocationAgreementList;
