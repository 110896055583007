import { Box, Grid, Link, styled } from '@mui/material';

const BoxStyled = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
  maxWidth: '100rem'
}));

const GridStyled = styled(Grid)(({ theme }) => ({
  padding: '1rem',
  [theme.breakpoints.up('md')]: {
    padding: '1.5rem 4.5rem'
  }
}));

const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '0.25rem',
  width: 'fit-content',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.secondary.main
  }
}));

export { BoxStyled, GridStyled, StyledLink };
