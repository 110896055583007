import { Typography } from '@mui/material';
import React from 'react';
import LinkButtonProps from './LinkButton.props';
import StyledLink from './LinkButton.style';

const LinkButton = (props: LinkButtonProps): React.ReactElement => {
  return (
    <StyledLink underline="none" type="button" {...props}>
      <Typography variant="body2" style={{ marginTop: 'unset' }}>
        {props.children}
      </Typography>
    </StyledLink>
  );
};

export default LinkButton;
