import { TagDTO } from '@piefi-platform/types-lib';
import useHttp from 'hooks/use-http';
import { useCallback } from 'react';
import PatronageActivityTagService from './use-patronage-activity-tag-service.model';
import { patronageActivityTagsUrl } from './use-patronage-activity-tag-service.url';

const usePatronageActivityTagService = (): PatronageActivityTagService => {
  const { post, put } = useHttp();

  const createAndAttachTag = useCallback(
    (activityIds: string[], daoId: string, tag: Partial<TagDTO>) => {
      // if the tag has an id only pass the id otherwise pass the name and color and the tag will get created
      const tagData = tag.id ? { id: tag.id } : { name: tag.name, color: tag.color };

      return post(`${patronageActivityTagsUrl(daoId)}`, {
        ids: activityIds,
        tagData
      });
    },
    [post]
  );

  const detachTag = useCallback(
    (memberIds: string[], daoId: string, tagId: string) => {
      return put(`${patronageActivityTagsUrl(daoId)}`, {
        ids: memberIds,
        tagData: {
          id: tagId
        }
      });
    },
    [put]
  );

  return {
    createAndAttachTag,
    detachTag
  };
};

export default usePatronageActivityTagService;
