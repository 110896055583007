import { Box, Grid, styled, Stack, Typography } from '@mui/material';

export const ProfileContainerStyled = styled(Box)(() => ({
  overflow: 'hidden',
  minHeight: 'unset'
}));

export const SegmentProfileInfoStyled = styled(Grid)(({ theme }) => ({
  margin: '1rem 0 0',
  [theme.breakpoints.up('md')]: {
    marginTop: '0rem'
  }
}));

export const StyledHeaderStack = styled(Stack)(({ theme }) => ({
  margin: '1.25rem 0rem 1.55rem',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const StackStyled = styled(Stack)(({ theme }) => ({
  marginTop: '1rem',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .MuiSvgIcon-root': {
    marginTop: '0.125rem'
  },
  '& .MuiPaper-root': {
    display: 'flex'
  },
  '& .MuiTypography-root': {
    color: theme.palette.grey[500],
    marginLeft: '-0.25rem'
  },
  '& .divider': {
    color: theme.palette.grey[500]
  }
}));

export const SocialMediaStackStyled = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  padding: '0.25rem 0',
  '& svg': {
    height: '1rem',
    width: '1rem'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start'
  },
  '& .MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  },
  '& path': {
    fill: theme.palette.grey[700]
  }
}));

export const UserAboutTextStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 550,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'start',
    marginRight: '4.5rem',
    marginTop: '0.5rem'
  }
}));

export const UserNameStyled = styled(Typography)(({ theme }) => ({
  marginLeft: '1.25rem',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0.25rem'
  },
  [theme.breakpoints.up('xs')]: {
    textAlign: 'left'
  }
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center'
  }
}));
