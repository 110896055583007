import { Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { AvatarBuilder } from 'components';
import { UI_LABELS } from 'constants/ui-labels';
import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { AvatarSize, SocialEntityAuditAction } from 'types/enum';
import MetaProps from './Meta.props';
import { StackStyled, TypographyMetaStyled } from './Meta.style';

const Meta = ({ user, date, socialEntityAudits }: MetaProps): React.ReactElement => {
  const { AGO, EDITED } = UI_LABELS;

  return (
    <StackStyled direction="row" alignItems="center" gap="0.25rem" data-testid="meta-element">
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <AvatarBuilder
          src={user?.profileThumbnail}
          alt={user?.username}
          id={user.id}
          size={AvatarSize.SMALL}
        />
        <TypographyMetaStyled variant="caption" color="text.secondary" className="meta">
          {`${formatDistanceToNowStrict(new Date(date))} ${AGO}`}
        </TypographyMetaStyled>
        {socialEntityAudits && socialEntityAudits?.length > 0 ? (
          socialEntityAudits[0].action === SocialEntityAuditAction.UPDATED && (
            <Tooltip
              placement="bottom"
              title={`${formatDistanceToNowStrict(
                new Date(socialEntityAudits[0].createdAt)
              )} ${AGO}`}
              TransitionComponent={Zoom}
            >
              <Typography
                color="text.secondary"
                fontWeight="800"
                variant="body2"
                data-testid="edited-label"
              >
                {EDITED}
              </Typography>
            </Tooltip>
          )
        ) : (
          <></>
        )}
      </Stack>
    </StackStyled>
  );
};

export default Meta;
