import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function LockIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M7.00011 7.55557C6.85277 7.55557 6.71146 7.6141 6.60727 7.71829C6.50308 7.82247 6.44455 7.96378 6.44455 8.11112V9.77779C6.44455 9.92513 6.50308 10.0664 6.60727 10.1706C6.71146 10.2748 6.85277 10.3333 7.00011 10.3333C7.14745 10.3333 7.28876 10.2748 7.39295 10.1706C7.49713 10.0664 7.55566 9.92513 7.55566 9.77779V8.11112C7.55566 7.96378 7.49713 7.82247 7.39295 7.71829C7.28876 7.6141 7.14745 7.55557 7.00011 7.55557ZM9.77789 5.33335V4.22224C9.77789 3.48552 9.48523 2.77898 8.96429 2.25805C8.44336 1.73712 7.73682 1.44446 7.00011 1.44446C6.2634 1.44446 5.55686 1.73712 5.03592 2.25805C4.51499 2.77898 4.22233 3.48552 4.22233 4.22224V5.33335C3.7803 5.33335 3.35638 5.50894 3.04382 5.8215C2.73126 6.13406 2.55566 6.55799 2.55566 7.00001V10.8889C2.55566 11.3309 2.73126 11.7549 3.04382 12.0674C3.35638 12.38 3.7803 12.5556 4.22233 12.5556H9.77789C10.2199 12.5556 10.6438 12.38 10.9564 12.0674C11.269 11.7549 11.4446 11.3309 11.4446 10.8889V7.00001C11.4446 6.55799 11.269 6.13406 10.9564 5.8215C10.6438 5.50894 10.2199 5.33335 9.77789 5.33335ZM5.33344 4.22224C5.33344 3.78021 5.50904 3.35629 5.8216 3.04372C6.13416 2.73116 6.55808 2.55557 7.00011 2.55557C7.44214 2.55557 7.86606 2.73116 8.17862 3.04372C8.49118 3.35629 8.66677 3.78021 8.66677 4.22224V5.33335H5.33344V4.22224ZM10.3334 10.8889C10.3334 11.0362 10.2749 11.1776 10.1707 11.2817C10.0665 11.3859 9.92523 11.4445 9.77789 11.4445H4.22233C4.07499 11.4445 3.93368 11.3859 3.82949 11.2817C3.72531 11.1776 3.66678 11.0362 3.66678 10.8889V7.00001C3.66678 6.85267 3.72531 6.71136 3.82949 6.60718C3.93368 6.50299 4.07499 6.44446 4.22233 6.44446H9.77789C9.92523 6.44446 10.0665 6.50299 10.1707 6.60718C10.2749 6.71136 10.3334 6.85267 10.3334 7.00001V10.8889Z"
        fill="#181818"
      />
    </SvgIcon>
  );
}
