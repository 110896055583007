import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { AvatarBuilder } from 'components';
import { useDao } from 'hooks';
import { useDaoService } from 'hooks/services';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { AvatarSize, UserDaoRole } from 'types/enum';
import { StyledDaoMemberRow, StyledTableContainer } from './DaoMemberList.style';
import { PAGINATION } from 'constants/app-config';

const DaoMemberList = (): ReactElement => {
  const { getDaoMemberships } = useDaoService();
  const { currentDao } = useDao();
  const [members, setMembers] = useState<DaoMembershipDTO[]>([]);

  const headerColumns = [
    { key: 'name', text: 'Name' },
    { key: 'role', text: 'Role' }
  ];

  useEffect(() => {
    const getMembers = async () => {
      try {
        if (!currentDao?.id) return;

        const daoMembershipRes = await getDaoMemberships(currentDao.id, {
          page: 0,
          size: PAGINATION.LARGE_PAGE_SIZE,
          roles: [UserDaoRole.ADMIN, UserDaoRole.OWNER, UserDaoRole.MEMBER]
        });
        if (daoMembershipRes?.data) {
          setMembers(daoMembershipRes.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getMembers();
  }, [currentDao, getDaoMemberships]);

  return (
    <StyledTableContainer>
      <Table aria-label="Member list table" stickyHeader>
        <TableHead>
          <TableRow>
            {headerColumns.map((col, index) => (
              <Fragment key={`${col.key}-${col.text}-${index}`}>
                <TableCell>
                  <Typography fontWeight="800" fontSize={'.75rem'}>
                    {col.text}
                  </Typography>
                </TableCell>
              </Fragment>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((membership: DaoMembershipDTO, index: number) => (
            <StyledDaoMemberRow key={`${index}`}>
              <TableCell align="left" style={{ width: '85%' }}>
                <Stack direction="row" alignItems="center">
                  <AvatarBuilder
                    id={`avatar-${membership.user.profileThumbnail}`}
                    src={membership.user.profileThumbnail}
                    size={AvatarSize.MEDIUM}
                  />
                  <Typography variant="body2" paddingLeft={'0.5rem'}>
                    {membership.user.firstName || 'Unknown'}
                  </Typography>
                  <Typography variant="body2" paddingLeft={'0.25rem'}>
                    {membership.user.lastName || 'Unknown'}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary" paddingRight={'1rem'}>
                  {membership.role.toLowerCase()}
                </Typography>
              </TableCell>
            </StyledDaoMemberRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default DaoMemberList;
