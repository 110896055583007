import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

interface BrandColorName {
  primary: string;
  secondary: string;
}

interface BrandColorCode {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
}

type BrandColor = BrandColorName & BrandColorCode;

interface BrandColorOptions {
  [key: string]: BrandColor;
}

// interface BrandColorOptions {
//   magenta: BrandColor;
//   yellow: BrandColor;
//   blue: BrandColor;
//   green: BrandColor;
//   red: BrandColor;
// }

interface Button {
  primary: {
    background: string;
    color: string;
    linkBackground: string;
  };
  neutral: {
    background: string;
    color: string;
    linkBackground: string;
  };
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    green: string;
  }
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    brand: BrandColorOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface CommonColors {
    black: string;
    white: string;
    red: string;
  }
  interface Palette {
    button: Button;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    850: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#aaf27f',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249'
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#8A8A8A',
  dark: '#1939B7',
  darker: '#091A7A'
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#aaf27f',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D'
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFD31A',
  dark: '#B78103',
  darker: '#7A4F01'
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E'
};

const GREY = {
  0: '#FDFDFD',
  200: '#FAFAFA',
  300: '#ECECEC',
  500: '#BABABA',
  700: '#8A8A8A',
  900: '#181818',
  500_8: alpha('#BABABA', 0.08),
  500_12: alpha('#BABABA', 0.12),
  500_16: alpha('#BABABA', 0.16),
  500_24: alpha('#BABABA', 0.24),
  500_32: alpha('#BABABA', 0.32),
  500_48: alpha('#BABABA', 0.48),
  500_56: alpha('#BABABA', 0.56),
  500_80: alpha('#BABABA', 0.8)
};

const GREEN = {
  primary: '#77E520',
  secondary: '#80CF00'
};

const MAGENTA = {
  100: '#FCCEFF',
  300: '#F65AFF',
  500: '#F222FE',
  secondary: '#D3099A',
  primary: '#FF35C6'
};

const YELLOW = {
  500: '#FFD31A',
  secondary: '#DEB91E',
  primary: '#FFED03'
};

const BLUE = {
  500: '#3DD0FF',
  primary: '#3DAEFF',
  secondary: '#009BCC'
};

const RED = {
  100: '#FFBBD3',
  200: '#FFA599',
  500: '#FF2976',
  primary: '#FF4500',
  secondary: '#DE241B'
};

const ORANGE = {
  primary: '#FF8B1F',
  secondary: '#C24E1E'
};

const PURPLE = {
  primary: '#8E1EFF',
  secondary: '#740FD6'
};

const NEUTRAL = {
  100: '#FDFDFD',
  200: '#FAFAFA',
  300: '#ECECEC',
  400: '#BABABA',
  500: '#8A8A8A',
  600: '#181818'
};

export const BRAND = {
  grey: GREY,
  red: RED,
  orange: ORANGE,
  yellow: YELLOW,
  green: GREEN,
  blue: BLUE,
  purple: PURPLE,
  magenta: MAGENTA,
  neutral: NEUTRAL
};

export const COLOR_PICKER_COLORS: string[] = [
  RED.secondary,
  RED.primary,
  ORANGE.secondary,
  ORANGE.primary,
  YELLOW.secondary,
  YELLOW.primary,
  GREEN.secondary,
  GREEN.primary,
  BLUE.secondary,
  BLUE.primary,
  PURPLE.secondary,
  PURPLE.primary,
  MAGENTA.secondary,
  MAGENTA.primary
];

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const BUTTON: Button = {
  primary: {
    background: '#181818',
    color: '#FDFDFD',
    linkBackground: '#ECECEC'
  },
  neutral: {
    background: GREY[0],
    color: GREY[900],
    linkBackground: GREY[0]
  }
};

const COMMON = {
  common: { black: '#181818', white: '#FDFDFD', red: RED[500] },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[700] },
  warning: { ...WARNING, contrastText: GREY[700] },
  error: { ...ERROR, contrastText: '#fff' },
  brand: BRAND,
  grey: GREY,
  green: GREEN,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  button: BUTTON,
  action: {
    hover: GREY[900],
    selected: GREY[500_16],
    disabled: GREY[500_8],
    disabledBackground: GREY[500_80],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
    delete: RED[500]
  }
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[900], secondary: GREY[700], disabled: GREY[500] },
    background: {
      paper: GREY[0],
      default: GREY[0],
      neutral: GREY[500],
      green: GREEN.primary
    },
    action: { active: GREY[700], ...COMMON.action }
  },
  dark: {
    ...COMMON,
    text: { primary: GREY[0], secondary: GREY[700], disabled: GREY[500] },
    background: {
      paper: GREY[900],
      default: GREY[700],
      neutral: GREY[500],
      green: GREEN.primary
    },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export default palette;
