import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { CHART_LABELS } from 'constants/chart.labels';
import { numberFormatter } from 'utils';
import {
  ActivitySelectorItem,
  PatronageActivitySelectorProps
} from './PatronageActivitySelector.props';
import {
  ColorSquare,
  ListContainer,
  ListHeader,
  PatronageActivitySelectorItem
} from './PatronageActivitySelector.style';

const PatronageActivitySelector = ({
  onActivitySelected,
  onActivityUnselected,
  activities,
  iconPrefix,
  iconSuffix
}: PatronageActivitySelectorProps): React.ReactElement => {
  const { ACTIVITY_TYPES } = CHART_LABELS;
  const NAME_SLICE_THRESHOLD = 32;

  const handleClick = (activityItem: ActivitySelectorItem, index: number) => {
    if (activityItem.isSelected) {
      activities[index].isSelected = false;
      onActivityUnselected(activityItem.activity);
    } else {
      activities[index].isSelected = true;
      onActivitySelected(activityItem.activity);
    }
  };

  return (
    <>
      {activities.length > 0 && (
        <Stack>
          <ListHeader>
            <Typography variant="body1">{ACTIVITY_TYPES}</Typography>
          </ListHeader>
          <ListContainer data-testid="list-container">
            {activities
              .sort((a, b) => b.metric - a.metric)
              .map((item, index) => (
                <PatronageActivitySelectorItem
                  isSelected={item.isSelected}
                  direction={'row'}
                  key={item.activity.id}
                  onClick={() => handleClick(item, index)}
                >
                  <Stack direction={'row'} style={{ alignItems: 'center' }}>
                    <Checkbox
                      checked={item.isSelected}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          height: 16,
                          width: 16,
                          rect: { stroke: 'transparent' }
                        },
                        '& .Mui-checked': { rect: { stroke: 'primary.main' } },
                        '&:hover': { bgcolor: 'transparent' }
                      }}
                    />
                    <ColorSquare color={item.activity.color} />
                    {item.activity.name && item.activity.name?.length > NAME_SLICE_THRESHOLD ? (
                      <Tooltip title={item.activity.name || ''}>
                        <Typography variant="body1">
                          {item.activity.name?.slice(0, NAME_SLICE_THRESHOLD)}
                          {(item.activity.name?.length || 0) > NAME_SLICE_THRESHOLD && '...'}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body1">{item.activity.name}</Typography>
                    )}
                  </Stack>
                  <Typography variant="body1" paddingLeft="0.5rem" alignItems={'center'}>
                    {iconPrefix && iconPrefix}{' '}
                    {numberFormatter.formatterWithZeroDecimals(item.metric)}
                    {iconSuffix && ' '}
                    {iconSuffix && iconSuffix}
                  </Typography>
                </PatronageActivitySelectorItem>
              ))}
          </ListContainer>
        </Stack>
      )}
    </>
  );
};

export default PatronageActivitySelector;
