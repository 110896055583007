import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ChartBoxIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '20px';
  const height = props.height ? `${props.height}px` : '20px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 20 20" {...props}>
      <path
        d="M19.6708 7.81526C19.6308 6.14526 19.5908 4.46526 19.5908 2.79526C19.5908 1.12526 18.3108 0.0252596 16.5408 0.00525961C14.6008 -0.0147404 12.6608 0.0252596 11.0208 0.0752596L9.29083 0.12526C7.24083 0.18526 5.12083 0.24526 2.95083 0.24526C2.07083 0.24526 1.23083 0.67526 0.720829 1.39526C0.110829 2.24526 0.150829 3.39526 0.190829 4.31526C0.190829 4.53526 0.210829 4.74526 0.200829 4.93526L0.160829 8.89526C0.160829 9.78526 0.120829 10.6953 0.080829 11.5953C0.010829 13.4853 -0.059171 15.4353 0.080829 17.3453C0.190829 18.7753 1.56083 19.5253 2.79083 19.6253C3.63083 19.6853 4.48083 19.6653 5.31083 19.6553C5.83083 19.6453 6.35083 19.6253 6.86083 19.6453C7.96083 19.6653 9.06083 19.7253 10.1708 19.7853C11.5208 19.8653 12.9008 19.9453 14.2808 19.9453C15.0008 19.9453 15.7208 19.9253 16.4308 19.8653C18.3608 19.7153 19.5708 18.5753 19.6608 16.7953C19.8208 13.7953 19.7408 10.7653 19.6608 7.83526L19.6708 7.81526ZM18.1608 16.6953C18.1408 17.0753 18.0808 18.2153 16.3108 18.3453C14.3308 18.4953 12.2608 18.3753 10.2508 18.2653C9.12083 18.2053 8.00083 18.1353 6.88083 18.1153C6.74083 18.1153 6.59083 18.1153 6.45083 18.1153C6.06083 18.1153 5.67083 18.1153 5.27083 18.1353C4.48083 18.1553 3.66083 18.1653 2.89083 18.1153C2.38083 18.0753 1.61083 17.8053 1.57083 17.2253C1.44083 15.4053 1.50083 13.4953 1.57083 11.6453C1.60083 10.7253 1.64083 9.81526 1.65083 8.90526L1.69083 4.94526C1.69083 4.72526 1.69083 4.49526 1.67083 4.24526C1.64083 3.51526 1.61083 2.69526 1.92083 2.25526C2.15083 1.93526 2.54083 1.73526 3.03083 1.73526C5.13083 1.73526 7.26083 1.67526 9.32083 1.61526L11.0508 1.56526C12.6708 1.52526 14.6008 1.48526 16.5108 1.49526C17.2308 1.49526 18.0708 1.84526 18.0708 2.77526C18.0708 4.46526 18.1108 6.14526 18.1508 7.83526C18.2208 10.7353 18.2908 13.7453 18.1508 16.6853L18.1608 16.6953Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M15.9006 3.74525C15.5306 3.54525 15.0806 3.68525 14.8906 4.05525L13.1906 7.24525L11.3906 10.4952L8.74058 7.70525C8.57058 7.52525 8.33058 7.44525 8.09058 7.47525C7.85058 7.50525 7.64058 7.65525 7.53058 7.87525L5.43058 11.9552L3.84058 14.8652C3.64058 15.2252 3.78058 15.6852 4.14058 15.8852C4.25058 15.9452 4.38058 15.9752 4.50058 15.9752C4.77058 15.9752 5.02058 15.8352 5.16058 15.5852L6.75058 12.6552L8.38058 9.50525L11.0106 12.2752C11.1706 12.4452 11.4106 12.5352 11.6506 12.5052C11.8906 12.4752 12.1006 12.3352 12.2106 12.1252L14.5106 7.96525L16.2206 4.76525C16.4206 4.39525 16.2806 3.94525 15.9106 3.75525L15.9006 3.74525Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
