import { CommentPostDTO, FeedPostDTO } from '@piefi-platform/types-lib';
import { FeedItemContextType, initFeedItemContextType } from 'model';
import React, {
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState
} from 'react';

const initialState: FeedItemContextType = initFeedItemContextType();

const FeedItemContext = createContext(initialState);

const FeedItemProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const [isReplyActive, setIsReplyActive] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(false);
  const [votes, setVotes] = useState(0);
  const [post, setPost] = useState<FeedPostDTO | undefined>();
  const [comments, setComments] = useState<CommentPostDTO[]>();
  const [showEditMode, setShowEditForm] = useState<boolean>(false);
  const [referrer, setReferrer] = useState<string>();

  const updatePostWithComments = useCallback((comment: CommentPostDTO) => {
    setPost((prevState) => ({
      ...prevState!!,
      comments: [comment, ...prevState!!.comments],
      _count: { comments: prevState!!._count.comments + 1 }
    }));

    setComments((comments = []) => [comment, ...comments!!]);
  }, []);

  const updatePost = useCallback((callback: (value: FeedPostDTO | undefined) => FeedPostDTO) => {
    setPost((post) => callback(post));
  }, []);

  const updateComments = useCallback((setState: SetStateAction<CommentPostDTO[] | undefined>) => {
    setComments(setState);
  }, []);

  const updateEditMode = useCallback((value: boolean) => {
    setShowEditForm(value);
  }, []);

  const updateReferrer = useCallback((value?: string) => {
    setReferrer(value);
  }, []);

  const providerValue = useMemo(
    () => ({
      comments,
      isReplyActive,
      post,
      votes,
      setComments: updateComments,
      setIsReplyActive,
      setPost: updatePost,
      setShowActionButtons,
      setShowEditForm: updateEditMode,
      setVotes,
      showActionButtons,
      showEditMode,
      updatePostWithComments,
      referrer,
      setReferrer: updateReferrer
    }),
    [
      comments,
      isReplyActive,
      post,
      votes,
      updateComments,
      setIsReplyActive,
      updatePost,
      setShowActionButtons,
      updateEditMode,
      setVotes,
      showActionButtons,
      showEditMode,
      updatePostWithComments,
      referrer,
      updateReferrer
    ]
  );

  return <FeedItemContext.Provider value={providerValue}>{children}</FeedItemContext.Provider>;
};

export default FeedItemProvider;
export { FeedItemContext };
