import { Stack, Typography } from '@mui/material';
import { User, sendEmailVerification } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import { AsyncButton } from 'components';
import { AUTH_VERIFICATION } from 'constants/auth.labels';
import { useAuth } from 'hooks';
import { ErrorForm } from 'components/labels';
import { LinkButton } from 'components/buttons';

interface Props {
  firebaseUser: User;
}

const EmailVerificationStepper = ({ firebaseUser }: Props): React.ReactElement => {
  const { logOut } = useAuth();
  const { EMAIL } = AUTH_VERIFICATION;
  const [resendSuccessful, setResendSuccessful] = useState(false);
  const [disableText, setDisabledText] = useState<string>();

  const resendEmailVerification = useCallback(async () => {
    try {
      await sendEmailVerification(firebaseUser, { url: window.location.href });
      setResendSuccessful(true);
    } catch (error: any) {
      if (error.code === 'auth/too-many-requests') {
        setDisabledText(EMAIL.TOO_MANY_REQUESTS);
      }
      console.error(error);
      setResendSuccessful(false);
    }
  }, [firebaseUser]);

  return (
    <Stack spacing={'3rem'}>
      <Stack spacing={'1rem'}>
        <Typography variant="h5">{EMAIL.HEADER}</Typography>
        <Typography variant="body2">{EMAIL.SUBHEADER}</Typography>
      </Stack>
      <Stack spacing={0.5} alignContent={'center'}>
        <Stack spacing={0.25}>
          <AsyncButton
            variant="outlined"
            onClick={resendEmailVerification}
            disabled={(disableText || '')?.length > 0}
            isSuccessful={resendSuccessful}
          >
            {EMAIL.ACTION}
          </AsyncButton>
          {disableText && <ErrorForm message={disableText} />}
        </Stack>
        <LinkButton onClick={() => logOut(true)} sx={{ alignSelf: 'center' }}>
          {EMAIL.SUB_ACTION}
        </LinkButton>
      </Stack>
    </Stack>
  );
};

export default EmailVerificationStepper;
