import { styled, Typography } from '@mui/material';
import { FONT_PRIMARY } from 'theme/typography';

const LabelStyled = styled(Typography)(({ theme }) => ({
  fontFamily: FONT_PRIMARY,
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  color: theme.palette.grey[850]
}));

export default LabelStyled;
