import { Button, Stack, Typography } from '@mui/material';
import BaseModal from 'components/modals';
import { BUTTON_LABELS } from 'constants/button.labels';
import { DELETE_OPTION, POST_OPTIONS } from 'constants/post-options-labels';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useDao, useNotification } from 'hooks';
import { usePostService } from 'hooks/services';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Severity } from 'types/enum';
import DeletePostProps from './DeletePost.props';

const DeletePost = ({ afterClose, daoId, roomId, post }: DeletePostProps): React.ReactElement => {
  const [open, setOpen] = useState(true);
  const { DELETE, DELETE_POST, DELETE_PROPOSAL } = POST_OPTIONS;
  const { DELETING_CONTENT_POST, DELETING_CONTENT_PROPOSAL, POST_DELETE_FAILURE } = DELETE_OPTION;
  const { KEEP } = BUTTON_LABELS;
  const { deletePost, deleteProposal } = usePostService();
  const navigate = useNavigate();
  const { notify } = useNotification();
  const { currentDao } = useDao();
  const { POST_DELETE_SUCCESS } = DELETE_OPTION;

  const handleDeletePost = useCallback(async () => {
    setOpen(false);

    try {
      if (post.proposal) {
        await deleteProposal(daoId, roomId, post.id, post.proposal.id);
      } else {
        await deletePost(daoId, roomId, post.id);
      }
      afterClose();
      navigate(
        `${ROOT_ROUTE.DAO((currentDao as any).whiteLabelSubDomain)}/${ROUTES.DAO_ROOM_FEED(
          daoId,
          roomId
        )}`
      );
      notify(POST_DELETE_SUCCESS, { severity: Severity.Success });
    } catch (error) {
      console.log(error);
      afterClose();
      notify(POST_DELETE_FAILURE, { severity: Severity.Error });
    }
  }, [daoId, roomId, post, currentDao, deletePost, deleteProposal, afterClose, notify, navigate]);

  const cancel = () => {
    setOpen(false);
    afterClose();
  };

  return (
    <BaseModal
      title={post.proposal ? DELETE_PROPOSAL : DELETE_POST}
      toggle={open}
      close={cancel}
      width={450}
    >
      <Stack>
        <Typography data-testid="modal-delete-content" variant="body1">
          {post.proposal ? DELETING_CONTENT_PROPOSAL : DELETING_CONTENT_POST}
        </Typography>

        <Stack direction="row" spacing={2} marginTop="4rem">
          <Button
            data-testid="modal-cancel-button"
            variant="outlined"
            color="primary"
            size="large"
            onClick={cancel}
          >
            {KEEP}
          </Button>
          <Button
            data-testid="modal-delete-button"
            variant="contained"
            color="primary"
            size="large"
            onClick={handleDeletePost}
          >
            {DELETE}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default DeletePost;
