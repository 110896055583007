import { INTERVAL_METRIC } from 'constants/chart.labels';
import { differenceInDays, differenceInHours } from 'date-fns';
import { TimeIntervalOption } from 'types/enum';

/**
 * @description Checks the difference between {date} and now is greater than threshold based on the metric provided, if so the fallback interval will be returned
 * @param date {Date}
 * @param threshold {number}
 * @param fallbackInterval {TimeIntervalOption}
 * @param metric {string}
 * @returns
 */
export const getAdjustedInterval = (
  date: Date,
  threshold: number,
  fallbackInterval: TimeIntervalOption,
  metric: INTERVAL_METRIC = INTERVAL_METRIC.HOURS,
  defaultInterval: TimeIntervalOption
): TimeIntervalOption => {
  const { DAYS, HOURS } = INTERVAL_METRIC;
  let diff = 0;
  switch (metric) {
    case DAYS:
      diff = differenceInDays(new Date(), date);
      break;
    case HOURS:
      diff = differenceInHours(new Date(), date);
      break;
    default:
      break;
  }
  if (diff > threshold) return fallbackInterval;
  return defaultInterval;
};
