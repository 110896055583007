import { formHelperTextClasses, inputBaseClasses, styled, Theme } from '@mui/material';
import InputForm from '../input-form/InputForm';

const getColor = (theme: Theme, error?: boolean, valid?: boolean): string | undefined => {
  if (error) return theme.palette.error.main;

  if (valid) return theme.palette.brand.green.secondary;
};

const ValidationInputFormStyled = styled(InputForm, {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'valid'
})<{ error?: boolean; valid?: boolean }>(({ theme, error, valid }) => ({
  [`& .${inputBaseClasses.root}`]: {
    border: `.063rem solid ${getColor(theme, error, valid)}`
  },
  [`& .${formHelperTextClasses.root}`]: {
    color: getColor(theme, error, valid)
  }
}));

export default ValidationInputFormStyled;
