import { Stack } from '@mui/material';
import { PointAwardEventWithoutMemberSeasonDTO } from '@piefi-platform/types-lib';
import DefaultTransactionListItem from 'components/containers/transaction-list-container/default-transaction-list-item/DefaultTransactionListItem';
import { PAGINATION } from 'constants/app-config';
import { EARNINGS_HISTORY_LABELS } from 'constants/user-home.labels';
import { useUserService } from 'hooks/services';
import React, { useCallback, useEffect, useState } from 'react';
import { AvatarSize, EventAction } from 'types/enum';
import { PointAwardEventListItem } from 'components/cards';
import { useNavigate } from 'react-router';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import TitleCardWrapper from 'components/cards/title-card-wrapper/TitleCardWrapper';

interface EarningsHistoryListProps {
  userId: string;
}
const EarningsHistoryList = ({ userId }: EarningsHistoryListProps): React.ReactElement => {
  const { getUserPointAwardEvents } = useUserService();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pointAwardEvents, setPointAwardEvents] = useState<PointAwardEventWithoutMemberSeasonDTO[]>(
    []
  );
  const { NO_EVENT_HISTORY_ITEMS, RECENT_EARNINGS } = EARNINGS_HISTORY_LABELS;

  const getEvents = useCallback(async () => {
    try {
      const { data: events } = await getUserPointAwardEvents({
        size: PAGINATION.SMALL_PAGE,
        page: 0,
        includeDollarValueAtTimeOfAllocation: true
      });

      setPointAwardEvents((prevEvents) => [...prevEvents, ...events]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [getUserPointAwardEvents]);

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  return (
    <TitleCardWrapper
      title={RECENT_EARNINGS}
      borderColor={'transparent'}
      onClick={() => navigate(`${ROOT_ROUTE.USER(userId)}/${ROUTES.USER_TRANSACTION_LIST()}`)}
    >
      {!isLoading &&
        (pointAwardEvents.length > 0 ? (
          <Stack direction={'column'}>
            {pointAwardEvents.map((pointAwardEvent) => {
              return (
                <React.Fragment key={pointAwardEvent.id}>
                  <PointAwardEventListItem
                    pointAwardEventData={{
                      pointAwardEvent: pointAwardEvent,
                      eventAction: EventAction.EARNED
                    }}
                    sourceData={{
                      avatarObj: {
                        src: (pointAwardEvent.patronageActivity as any).dao.logoThumbnail,
                        alt: `${(pointAwardEvent.patronageActivity as any).dao.name}-${
                          pointAwardEvent.patronageActivity.name
                        }`,
                        id: `${(pointAwardEvent.patronageActivity as any).dao.name}-${
                          pointAwardEvent.patronageActivity.id
                        }`,
                        size: AvatarSize.MEDIUM
                      },
                      sourceName: (pointAwardEvent.patronageActivity as any).dao.name
                    }}
                  />
                </React.Fragment>
              );
            })}
          </Stack>
        ) : (
          <DefaultTransactionListItem label={NO_EVENT_HISTORY_ITEMS} />
        ))}
    </TitleCardWrapper>
  );
};

export default EarningsHistoryList;
