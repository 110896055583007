import { Autocomplete, Chip, Stack, TextField, Typography, useTheme } from '@mui/material';
import { DaoMembershipDTO } from '@piefi-platform/types-lib';
import { captureException } from '@sentry/react';
import { Check } from 'assets/icons/general';
import { AvatarBuilder } from 'components';
import { PAGINATION } from 'constants/app-config';
import { DAO_MEMBER_SELECT_FORM } from 'constants/dao-member-select-form.labels';
import { useDao, useNotification } from 'hooks';
import { useDaoService } from 'hooks/services';
import { DaoMemberQueryType } from 'model';
import { useEffect, useState } from 'react';
import { AvatarSize, Severity, UserDaoRole } from 'types/enum';
import { useDebounce } from 'use-debounce';
import { DaoMemberSelectFormProps } from './DaoMemberSelectForm.props';

const DaoMemberSelectForm = ({
  onMemberListChange,
  membersSelected = []
}: DaoMemberSelectFormProps): React.ReactElement => {
  const { currentDao } = useDao();
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue] = useDebounce(inputValue, 500);
  const [options, setOptions] = useState<DaoMembershipDTO[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<DaoMembershipDTO[]>(membersSelected);
  const theme = useTheme();
  const { notify } = useNotification();
  const { ERROR_FETCHING, MEMBER_SELECT_PLACEHOLDER } = DAO_MEMBER_SELECT_FORM;

  const { getDaoMemberships } = useDaoService();

  useEffect(() => {
    const getData = async () => {
      try {
        let queryParams: DaoMemberQueryType = {
          page: 0,
          size: PAGINATION.PAGE_SIZE,
          roles: [UserDaoRole.ADMIN, UserDaoRole.OWNER, UserDaoRole.MEMBER]
        };

        if (debouncedInputValue) {
          queryParams.name = debouncedInputValue;
        }

        const res = await getDaoMemberships(currentDao.id, queryParams);
        setOptions(() => [...res.data]);
      } catch (error) {
        notify(ERROR_FETCHING, { severity: Severity.Error });
        captureException(error);
      }
    };
    getData();
  }, [debouncedInputValue]);

  return (
    <Autocomplete
      multiple
      filterOptions={(options) => options}
      options={[...options]}
      getOptionLabel={(option) => option.user.username}
      value={selectedMembers}
      onChange={(event, newValue) => {
        setSelectedMembers(newValue);
        onMemberListChange(newValue);
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{
            backgroundColor: selected ? theme.palette.grey[200] : 'white',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Stack direction="row" justifyContent={'space-between'} width={'100%'}>
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <AvatarBuilder
                hideAnimation={true}
                id={option.id}
                src={option.user.profileThumbnail}
                size={AvatarSize.X_SMALL}
              />
              <Typography variant="body1" fontWeight={'medium'}>
                {option.user.firstName} {option.user.lastName}
              </Typography>
            </Stack>
            {selected && <Check stroke={theme.palette.secondary.dark} />}
          </Stack>
        </li>
      )}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={MEMBER_SELECT_PLACEHOLDER}
          sx={{
            fieldset: {
              borderRadius: '0.5rem'
            }
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            sx={{ borderRadius: '0.5rem', padding: '0.125rem' }}
            label={`${option.user.firstName} ${option.user.lastName}`}
            {...getTagProps({ index })}
            key={option.id}
            icon={
              <AvatarBuilder
                hideAnimation={true}
                id={option.id}
                src={option.user.profileThumbnail}
                size={AvatarSize.X_SMALL}
              />
            }
          />
        ))
      }
    />
  );
};

export default DaoMemberSelectForm;
