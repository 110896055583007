import { Button, styled } from '@mui/material';

const ButtonStyled = styled(Button)(({ theme }) => ({
  padding: '0.75rem',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '0.625rem',
  minWidth: 'fit-content',
  width: 'fit-content',
  height: 'fit-content',
  cursor: 'default',
  transition: 'none'
}));

export { ButtonStyled };
