import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import useNotification from 'hooks/use-notification';
import { placeToast } from 'types/enum';

const Toast = (): React.ReactElement => {
  const { notifying, notification, resetNotifier } = useNotification();

  const closeToast = () => {
    resetNotifier();
  };

  return (
    <Snackbar
      open={notifying}
      anchorOrigin={placeToast(notification.position)}
      autoHideDuration={notification.duration}
      onClose={closeToast}
    >
      <Alert onClose={closeToast} severity={notification.severity}>
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
