import {
  Button,
  Grow,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';
import { BangTooltipIcon } from 'assets';
import { DashboardMetricDisplay } from 'components';
import { SimpleDropdownOption } from 'model';
import { useCallback, useState } from 'react';
import { ChartHeaderProps } from './ChartHeader.props';

const ChartHeader = ({
  dropdownConfig,
  title,
  metricConfig,
  tooltipValue,
  toggleDataTypeConfig
}: ChartHeaderProps): React.ReactElement => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SimpleDropdownOption>(
    dropdownConfig?.defaultDropdownOption ||
      dropdownConfig?.dropDownOptions[0] || { text: 'Default', value: '' }
  );

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const handleDropdownOption = useCallback((option: SimpleDropdownOption) => {
    setSelectedOption(option);
    dropdownConfig?.dropDownOptionSelected(option);
  }, []);

  return (
    <Stack direction={'column'} spacing={3}>
      <Stack direction={'column'} spacing={0.5}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {metricConfig && (
            <DashboardMetricDisplay
              totalMetricConfig={metricConfig.totalMetricConfig}
              percentMetric={metricConfig.percentMetric}
            />
          )}
          {toggleDataTypeConfig && (
            <ToggleButtonGroup
              value={toggleDataTypeConfig.default}
              exclusive
              onChange={toggleDataTypeConfig.onToggleSelected}
            >
              {toggleDataTypeConfig.toggleOptions.map((option) => (
                <ToggleButton key={option.value} value={option.value}>
                  {option.display}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <Typography variant={'body2'}>{title}</Typography>
          {tooltipValue && (
            <Tooltip
              id="ownership-tooltip"
              placement="top"
              title={tooltipValue}
              TransitionComponent={Grow}
              onClick={toggleTooltip}
              open={showTooltip}
              onClose={toggleTooltip}
              disableHoverListener
            >
              <IconButton
                color="inherit"
                sx={{
                  p: 0,
                  width: 12,
                  height: 12,
                  transition: (theme) => theme.transitions.create('all'),
                  '&:hover': { color: 'primary.main', bgcolor: 'transparent' }
                }}
              >
                <BangTooltipIcon style={{ height: '0.75rem', width: '0.75rem' }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={1}>
        {dropdownConfig &&
          dropdownConfig.dropDownOptions.map((option) => {
            return (
              <Button
                key={option.value}
                variant={selectedOption.value === option.value ? 'contained' : 'text'}
                onClick={() => handleDropdownOption(option)}
                size={'medium'}
                sx={{ borderRadius: '0.5rem' }}
              >
                {option.text}
              </Button>
            );
          })}
      </Stack>
    </Stack>
  );
};

export default ChartHeader;
