import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function UsersFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '24px';
  const height = props.height ? `${props.height}px` : '24px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 24 24" {...props}>
      <path
        d="M20.49 14.3628C20.34 14.2628 20.18 14.1628 20.02 14.0728C19.74 13.9128 19.48 13.7528 19.29 13.5228C19.19 13.1628 19.37 12.7428 19.59 12.2428C19.8 11.7628 20.03 11.2328 20.05 10.6228C20.08 9.4128 19.39 8.2728 18.35 7.7928C17.9 7.5828 17.38 7.4928 16.8 7.5128C16.42 7.5328 16.06 7.5928 15.74 7.7028C14.91 7.9828 14.17 8.7328 13.79 9.6528C13.44 10.5028 13.44 11.3728 13.79 12.0928C13.83 12.1828 13.87 12.2728 13.91 12.3628C14.01 12.5928 14.12 12.8128 14.24 13.0228C14.34 13.1728 14.47 13.3228 14.6 13.4728C14.68 13.5628 14.81 13.7028 14.83 13.6528C14.83 13.6728 14.77 13.7328 14.68 13.7928L14.48 13.9028C13.71 14.3028 13.22 14.5728 12.89 14.7728C12.8 14.7328 12.7 14.6828 12.61 14.6328C12.4 14.5328 12.19 14.4328 11.98 14.3228C11.41 14.0228 11.05 13.6828 10.9 13.3228C10.74 12.9361 10.7867 12.6495 11.04 12.4628C12.4 11.4428 12.8 9.2728 12.49 7.7528C12.14 6.0628 10.84 4.6328 9.26 4.1728C8.42 3.9328 7.5 3.9428 6.6 4.2028C5.99 4.3828 5.47 4.6528 5.04 5.0028C4.38 5.5528 3.89 6.3828 3.67 7.3328C3.41 8.4628 3.55 9.6828 4.07 10.7728C4.32 11.3128 4.66 11.8028 5.07 12.2228C5.23 12.3928 5.4 12.5128 5.57 12.6228C5.8 12.7828 5.82 12.8028 5.84 12.9128C5.9 13.1328 5.9 13.3328 5.84 13.4728C5.74 13.6928 5.44 13.8828 5.16 14.0328C4.87 14.1928 4.55 14.3228 4.24 14.4428C3.28 14.8328 2.2 15.2828 1.59 16.5028C1.49 16.7128 1.44 16.9128 1.39 17.0828C1.35 17.2328 1.31 17.3828 1.27 17.5428C1.16 17.9428 1.05 18.3528 1 18.8828C1 19.4228 3.43667 19.7061 8.31 19.7328H8.81C9.49 19.7328 9.98 19.7328 10.14 19.7428C11.33 19.7728 12.51 19.8028 13.7 19.8428C13.77 19.8428 13.86 19.8528 13.96 19.8628C14.15 19.8828 14.36 19.9128 14.58 19.9128C15.04 19.9128 15.51 19.8128 15.78 19.3528C15.86 19.2228 16.04 18.8928 15.85 18.1728C16.24 18.1628 16.72 18.1428 17.33 18.1128C17.66 18.0928 17.92 18.0828 18 18.0828C18.1 18.0828 18.29 18.1028 18.52 18.1128C19.22 18.1628 19.81 18.2028 20.29 18.2028C21.36 18.2028 21.92 18.0428 22.2 17.5728C22.99 16.2228 20.52 14.3528 20.49 14.3328V14.3628Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
