import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Grid(theme: Theme): object {
  return {
    MuiGrid: {
      styleOverrides: {}
    }
  };
}
