import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { DownArrowIcon } from 'assets';
import { SimpleDropdownOption } from 'model';
import { useState } from 'react';
import { CaretMenuProps } from './CaretMenu.props';

const CaretMenu = ({
  options,
  onOptionSelected,
  defaultOption
}: CaretMenuProps): React.ReactElement => {
  const [selectedOption, setSelectedOption] = useState<SimpleDropdownOption>(
    defaultOption || options[0]
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectOption = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleClick = (optionSelected: SimpleDropdownOption) => {
    setSelectedOption({ ...optionSelected });
    onOptionSelected(optionSelected);
    closeMenu();
  };

  return (
    <>
      {options.length > 0 && (
        <IconButton
          data-testid="caret-button"
          size="small"
          onClick={selectOption}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          style={{ marginLeft: '.5rem', width: 'unset', height: 'unset' }}
        >
          <Typography variant="body2" paddingRight="0.25rem">
            {selectedOption?.text}
          </Typography>
          <DownArrowIcon sx={{ height: '0.5rem !important', width: '0.5rem !important' }} />
        </IconButton>
      )}
      <Menu
        data-testid="caret-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        PopoverClasses={{ paper: 'actions-menu' }}
      >
        {options.map((option) => {
          return (
            <MenuItem onClick={() => handleClick(option)} key={option.value}>
              <Typography variant="body1">{option.text}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default CaretMenu;
