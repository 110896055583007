import { Grid, Stack, Typography } from '@mui/material';
import { AvatarBuilder } from 'components';
import { ROUTES } from 'constants/routes';
import { useDao } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AvatarSize, UserDaoRole } from 'types/enum';
import { HorizontalCardVariant } from 'types/enum/Horizontal-card-variant';
import { compareDaoRoles } from 'utils';
import { IHorizontalCardProps } from './HorizontalCard.props';
import {
  ActionContainerStyled,
  HorizontalCardContainerStyled,
  TitleAvatarStyled
} from './HorizontalCard.style';

const HorizontalCard = ({
  actions,
  body,
  img,
  variant = HorizontalCardVariant.Card,
  title,
  titleAvatar,
  name,
  memberId
}: IHorizontalCardProps): React.ReactElement => {
  const [isAtLeastAdmin, setIsAtLeastAdmin] = useState<boolean>(false);
  const cardType = variant;
  const navigate = useNavigate();
  const { currentDao } = useDao();
  const { activeDaoMembership } = useDao();

  useEffect(() => {
    if (!activeDaoMembership?.role) return;
    const isAtLeastAdmin = compareDaoRoles(
      UserDaoRole.ADMIN,
      UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole]
    );
    setIsAtLeastAdmin(isAtLeastAdmin);
  }, [activeDaoMembership?.role]);

  const handleAvatarClick = useCallback(
    (e: any) => {
      if (!memberId) return;
      e.stopPropagation();
      e.preventDefault();
      navigate(ROUTES.DAO_ADMIN_MEMBERS_PROFILE(currentDao.id, memberId));
    },
    [currentDao.id, memberId, navigate]
  );

  return (
    <HorizontalCardContainerStyled
      tabIndex={0}
      role="article"
      data-testid={'horizontal-card-container'}
      cardType={cardType}
    >
      <Grid container alignItems={'center'}>
        <Grid item>
          <AvatarBuilder
            src={img}
            alt={name}
            id={name}
            size={AvatarSize.SMALL}
            onClick={memberId && isAtLeastAdmin ? (e: any) => handleAvatarClick(e) : undefined}
          />
        </Grid>
        <Grid item flexGrow={1} paddingLeft={'1rem'} maxWidth="100%">
          <Stack direction={'column'} justifyContent={'center'} style={{ height: '100%' }}>
            <Stack direction={'row'} spacing={'0.25rem'}>
              {titleAvatar && <TitleAvatarStyled alt={title} src={titleAvatar} />}
              <Typography color="secondary" data-testid={'title'} variant={'body2'}>
                {title}
              </Typography>
            </Stack>
            {body}
          </Stack>
        </Grid>
        {actions && (
          <ActionContainerStyled tabIndex={0} data-testid={'actions'} data-action={'actions'} item>
            {actions}
          </ActionContainerStyled>
        )}
      </Grid>
    </HorizontalCardContainerStyled>
  );
};

export default HorizontalCard;
