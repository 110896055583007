import { Box, Divider, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppNavigation, useUser } from '../../hooks';
import { DrawerNavigationProps } from './DrawerNavigation.props';
import { DrawerStyled } from './DrawerNavigation.style';
import CollectiveNavItem from './collective-nav-item/CollectiveNavItem';
import DashboardNavItem from './dashboard-nav-item/DashboardNavItem';
import MenuNavItem from './menu-nav-item/MenuNavItem';
import PortfolioNavItem from './portfolio-nav-item/PortfolioNavItem';

const DrawerNavigation = ({
  activeNavItemName,
  setActiveNavItemName,
  isInDao
}: DrawerNavigationProps): React.ReactElement => {
  const theme = useTheme();
  const { daoMemberships } = useUser();
  const { userNavExpanded, setUserNavExpanded, setNavigationOpen } = useAppNavigation();
  const [transitioning, setTransitioning] = useState<boolean>(false);

  useEffect(() => {
    if (!isInDao) {
      // setNavigationOpen(navigationOpen, ?userNavExpanded, ?showDaoNav)
      setNavigationOpen(true, true);
    }

    if (isInDao && daoMemberships.length) {
      // setNavigationOpen(navigationOpen, ?userNavExpanded, ?showDaoNav)
      setNavigationOpen(true, false, true);
    }
  }, [isInDao, daoMemberships]);

  const handleDrawerOpen = () => {
    setTransitioning(true);
    setUserNavExpanded(true);
    setTimeout(() => setTransitioning(false), theme.transitions.duration.enteringScreen);
  };

  const handleDrawerClose = () => {
    setTransitioning(true);
    setUserNavExpanded(false);
    setTimeout(() => setTransitioning(false), theme.transitions.duration.leavingScreen);
  };

  return (
    <Box sx={{ maxWidth: userNavExpanded ? '18rem' : 'auto' }}>
      <DrawerStyled
        variant={'permanent'}
        open={userNavExpanded}
        onClose={handleDrawerClose}
        transitioning={transitioning}
      >
        <Stack direction={'column'} justifyContent={'space-between'} height={'inherit'}>
          <Stack direction={'column'}>
            <DashboardNavItem setActiveNavItemName={setActiveNavItemName} />
            <Divider />
            <PortfolioNavItem setActiveNavItemName={setActiveNavItemName} />
            <Divider />
            <CollectiveNavItem
              setOpenDrawer={handleDrawerOpen}
              activeNavItemName={activeNavItemName}
              setActiveNavItemName={setActiveNavItemName}
            />
          </Stack>
          <MenuNavItem />
        </Stack>
      </DrawerStyled>
    </Box>
  );
};

export default DrawerNavigation;
