import { Button, Stack, Typography } from '@mui/material';
import { Send05 } from 'assets/icons/communication';
import DirectAllocationWrapper from 'components/profile/tab-profile/dao-admin/direct-allocation/direct-allocation-wrapper/DirectAllocationWrapper';
import { DIRECT_ALLOCATION_LABELS } from 'constants/navigation.labels';
import { useDao } from 'hooks';
import { useCallback, useState } from 'react';
import { UserDaoRole } from 'types/enum';
import { compareDaoRoles } from 'utils';
import truncateText from 'utils/helper/truncate-text';
import BaseModal from '../../modals';

const DaoNavigationHeader = (): React.ReactElement => {
  const { activeDaoMembership, currentDao } = useDao();
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const { SEND } = DIRECT_ALLOCATION_LABELS;

  const handleSendModalOpenClicked = useCallback(() => {
    setSendModalOpen(!sendModalOpen);
  }, [sendModalOpen]);

  const isAdmin = compareDaoRoles(UserDaoRole.ADMIN, activeDaoMembership?.role as UserDaoRole);

  return (
    <>
      <Stack direction={'column'} paddingBottom={isAdmin ? '1rem' : 'unset'}>
        <Typography variant="subtitle1" fontWeight={'extraBold'} padding={'1.5rem'}>
          {truncateText(currentDao.name, 26)}
        </Typography>
        {isAdmin && (
          <Button
            variant="contained"
            size={'large'}
            startIcon={<Send05 stroke={'#ffffff'} width={20} height={20} />}
            onClick={handleSendModalOpenClicked}
            sx={{ margin: '0 1rem' }}
          >
            {SEND}
          </Button>
        )}
      </Stack>
      {sendModalOpen && (
        <BaseModal toggle={sendModalOpen} close={handleSendModalOpenClicked} width={600}>
          <DirectAllocationWrapper onCancel={handleSendModalOpenClicked} />
        </BaseModal>
      )}
    </>
  );
};

export default DaoNavigationHeader;
