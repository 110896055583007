import { Button, Typography } from '@mui/material';
import CTACardProps from './CTACard.props';
import CardStyled from './CTACard.style';

const CTACard = ({
  ctaAction,
  ctaText,
  description,
  title,
  ...other
}: CTACardProps): React.ReactElement => {
  return (
    <CardStyled data-testid="cta-card" {...other}>
      {title && (
        <Typography data-testid="cta-title" variant="body2" marginBottom=".5rem">
          {title}
        </Typography>
      )}
      {description && (
        <Typography data-testid="cta-description" variant="body2" marginBottom="1rem">
          {description}
        </Typography>
      )}
      {ctaAction && ctaText && (
        <Button role="button" variant="outlined" color="primary" size="medium" onClick={ctaAction}>
          {ctaText}
        </Button>
      )}
    </CardStyled>
  );
};

export default CTACard;
