import { Box, BoxProps, styled } from '@mui/material';

export const DesktopContentNavigationContainer = styled(Box)<BoxProps>(({ theme }) => ({
  visibility: 'hidden',
  width: 0,
  height: 0,
  [theme.breakpoints.up('md')]: {
    visibility: 'visible',
    width: '100%',
    height: '100%'
  }
}));
