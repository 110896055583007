import { Stack } from '@mui/material';
import { FeedCard, RoomInfo, SkeletonCard } from 'components';
import { PAGINATION } from 'constants/app-config';
import { HTTP_STATUS } from 'constants/http-statuses';
import { UI_LABELS } from 'constants/ui-labels';
import { FeedItemProvider } from 'contexts';
import { useFeedPage } from 'hooks';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { PostFilterType } from 'types/enum';
import HorizontalDivider from '../divider/HorizontalDivider';
import { FeedListProps } from './FeedList.props';

const FeedList = ({
  daoId,
  roomId,
  handleGetPosts,
  redirectLocationOnSinglePostClose
}: FeedListProps): ReactElement => {
  const { posts, setPosts } = useFeedPage();
  const { activeFilter } = useFeedPage(); // assuming activeFilter comes from useFeedPage
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpToDate, setIsUpToDate] = useState(false);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const loadPosts = async () => {
    if (isLoading || isUpToDate) return;

    setIsLoading(true);
    try {
      const postType = [PostFilterType.Posts, PostFilterType.Proposals].includes(activeFilter)
        ? activeFilter
        : undefined;
      const response = await handleGetPosts(daoId, roomId, currentPage, postType);
      if (response?.status === HTTP_STATUS.OK) {
        const newPosts = response.data || [];
        setPosts([...posts, ...newPosts]);
        setIsUpToDate(newPosts.length < PAGINATION.PAGE_SIZE);
        if (newPosts.length >= PAGINATION.PAGE_SIZE) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading || isUpToDate) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadPosts();
        }
      },
      { threshold: 1.0 }
    );

    // Check if current is a DOM element
    const current = loadMoreRef.current;
    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [currentPage, isLoading, isUpToDate, activeFilter]);

  // Reset on filter change
  useEffect(() => {
    setCurrentPage(0);
    setPosts([]);
    setIsUpToDate(false);
  }, [activeFilter, daoId, roomId]);

  if (activeFilter === PostFilterType.Info) {
    return <RoomInfo />;
  }

  return (
    <div id="feedList" role="feed" style={{ marginBottom: '2.5rem' }}>
      <Stack direction="column" justifyContent="space-between" alignItems="normal">
        {posts.map((post) => (
          <FeedItemProvider key={post.id}>
            <FeedCard post={post} redirectLocation={redirectLocationOnSinglePostClose} />
            {/* <PostCard
              post={post}
              onClick={() => {
                navigate(
                  `${ROOT_ROUTE.DAO(
                    (currentDao as any).whiteLabelSubDomain
                  )}/${ROUTES.DAO_ROOM_FEED(daoId, roomId)}/${ROUTES.POST_DETAILS_PAGE(
                    post.id as string
                  )}`
                );
              }}
            /> */}
          </FeedItemProvider>
        ))}
        {isLoading && <SkeletonCard />}
        {!isUpToDate && <div ref={loadMoreRef} />}
        {isUpToDate && <HorizontalDivider label={UI_LABELS.UP_TO_DATE} />}
      </Stack>
    </div>
  );
};

export default FeedList;
