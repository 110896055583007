import { Typography } from '@mui/material';
import { RoomMembershipDTO } from '@piefi-platform/types-lib';
import { StyledNavigationSection } from 'components/v2/base/layout/StyledNavigationSection';
import { ROOM_ACTIONS } from 'constants/room.labels';
import { ROUTES } from 'constants/routes';
import { useAppNavigation, useDao, useLocalStorage, useRoomMembership } from 'hooks';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionRoom } from 'types';
import { UserDaoRole } from 'types/enum';
import { addOrReplace, compareDaoRoles } from 'utils';
import AddRoomModal from '../add-room-modal/AddRoomModal';
import DAORoomItem from '../dao-room-item/DAORoomItem';

interface DAORoomNavigationProps {
  setActivePageName?: (value: string) => void;
}

const DAORoomNavigation = ({ setActivePageName }: DAORoomNavigationProps): React.ReactElement => {
  const navigate = useNavigate();
  const { activeDaoMembership } = useDao();
  const { activeRoom, userRoomMemberships, setActiveRoom } = useRoomMembership();
  const { setNavigationOpen } = useAppNavigation();

  const [roomCreateModalOpen, setRoomCreateModalOpen] = useState(false);
  const [sessionRooms, setSessionRooms] = useLocalStorage<SessionRoom[]>('sessionRooms', []);

  const showAddRoomModal = useCallback(() => {
    setActivePageName && setActivePageName('');
    setRoomCreateModalOpen(true);
  }, []);

  const { ROOM_ACTION, ADD_ROOM_ACTION } = ROOM_ACTIONS;
  let hasAdminAuthority = false;

  const handleClick = useCallback(
    (item: RoomMembershipDTO) => {
      const saveRoomToSession = (daoId: string, roomId: string | null) => {
        const filter: SessionRoom = { daoId: daoId, roomId: roomId };

        setSessionRooms(
          addOrReplace(sessionRooms, filter, (session: SessionRoom) => session.daoId !== daoId)
        );
      };
      saveRoomToSession(item.daoId, item.roomId);
      setActiveRoom(item);
      setActivePageName && setActivePageName('');
      setNavigationOpen(false);
      navigate(ROUTES.DAO_ROOM_FEED(item.daoId, item.roomId), { state: { navigationId: item.id } });
    },
    [navigate, setActiveRoom, sessionRooms, setSessionRooms, setNavigationOpen]
  );

  if (activeDaoMembership && activeDaoMembership?.role) {
    const daoRole = UserDaoRole[activeDaoMembership?.role as keyof typeof UserDaoRole];
    hasAdminAuthority = compareDaoRoles(UserDaoRole.ADMIN, daoRole);
  }

  if (!activeDaoMembership) {
    return <></>;
  }

  return (
    <StyledNavigationSection data-testid="rooms-navigation" spacing={0.5}>
      <Typography
        color="text.secondary"
        data-testid="rooms-navigation-title"
        fontWeight="bold"
        padding=".25rem .5rem"
        textAlign="right"
        variant="body2"
        sx={{ textAlign: { xs: 'left', md: 'left' } }}
      >
        {ROOM_ACTION}
      </Typography>
      {userRoomMemberships.map((item) => {
        const isActive = activeRoom?.roomId === item.roomId;
        return (
          <DAORoomItem
            key={`room-${item.roomId}`}
            data-testid={item.roomId}
            room={{ ...item, isActive }}
            onClick={() => handleClick(item)}
            disabled={false}
          />
        );
      })}
      {hasAdminAuthority && (
        <DAORoomItem
          key={`add-room`}
          data-testid={'add-room'}
          room={{
            roomId: 'add-room',
            isActive: false,
            room: {
              name: ADD_ROOM_ACTION,
              thumbnail: '',
              description: '',
              isDefault: false,
              id: 'add-room'
            }
          }}
          onClick={showAddRoomModal}
          disabled={false}
        />
      )}
      <AddRoomModal open={roomCreateModalOpen} setOpen={setRoomCreateModalOpen} />
    </StyledNavigationSection>
  );
};

export default DAORoomNavigation;
