import { Box, Grid, IconButton, Stack, styled, Tabs, Typography } from '@mui/material';

export const AvatarContainerStyled = styled(Grid)(({ theme }) => ({
  margin: '0.5rem 1rem 1rem 1rem',
  '& .MuiBadge-standard': {
    top: '5.125rem',
    marginRight: '2.3rem'
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    margin: 0,
    '& .MuiBadge-root': {
      paddingLeft: '1rem'
    }
  }
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  '& .MuiSvgIcon-root': {
    height: '0.8125rem',
    width: '0.8125rem'
  },
  [theme.breakpoints.down('md')]: {
    backgroundColor: `${theme.palette.common.white} !important`,
    '& path': {
      fill: theme.palette.grey[700]
    }
  }
}));

export const ProfileContainerStyled = styled(Box)(({ theme }) => ({
  border: `0.063rem solid ${theme.palette.grey[300]}`,
  overflow: 'hidden',
  minHeight: 'calc(100vh - 7.063rem)'
}));

export const SegmentProfileInfoStyled = styled(Grid)(({ theme }) => ({
  margin: 'auto 1rem',
  [theme.breakpoints.up('md')]: {
    margin: 'auto',
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  '& .MuiIconButton-root': {
    backgroundColor: theme.palette.common.black
  }
}));

export const StackStyled = styled(Stack)(({ theme }) => ({
  marginTop: '0.75rem',
  '& .MuiSvgIcon-root': {
    marginTop: '0.125rem'
  },
  '& .MuiPaper-root': {
    display: 'flex'
  },
  '& .MuiTypography-root': {
    color: theme.palette.grey[500],
    marginLeft: '-0.25rem'
  },
  '& .divider': {
    color: theme.palette.grey[500]
  }
}));

export const SocialMediaStackStyled = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  marginTop: '1.6875rem',
  '& svg': {
    height: '1rem',
    width: '1rem'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start'
  },
  '& .MuiIconButton-root': {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[300]
    }
  }
}));

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  marginBottom: '.75rem',
  marginTop: '2rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  width: '100vw',
  [theme.breakpoints.up('md')]: {
    marginTop: '2.5rem',
    padding: '0 4.5rem'
  }
}));

export const TabContentStyled = styled(Box)(({ theme }) => ({
  padding: '1rem 0',
  [theme.breakpoints.up('md')]: {
    padding: '1.5rem 4.5rem'
  }
}));

export const ContentBoxStyled = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  width: '100%'
}));

export const UserAboutTextStyled = styled(Typography)(({ theme }) => ({
  fontWeight: 550,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'start',
    marginRight: '4.5rem'
  }
}));

export const UserNameStyled = styled(Typography)(({ theme }) => ({
  fontSize: '1.375rem',
  marginLeft: '1.3rem',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    marginLeft: '0',
    textAlign: 'left'
  }
}));
