import { CircularProgress, Stack, useTheme } from '@mui/material';
import { DaoMembershipDTO, UserEntityDTO } from '@piefi-platform/types-lib';
import AnimatedLogo from 'assets/logomark_moving.json';
import { TosModal } from 'components';
import { ROOT_ROUTE, ROUTES } from 'constants/routes';
import { useAuth, useDao, useNotification } from 'hooks';
import { useUserService } from 'hooks/services';
import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LottieOptions, useLottie } from 'lottie-react';
import { UserContextType, initUserContextType } from 'model';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { WordMarkLogo } from '../assets';
import { InviteProperties } from '../model/contexts/user-context-type.model';

const initialState: UserContextType = initUserContextType();

const UserContext = createContext(initialState);

const UserProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const theme = useTheme();
  const { token, registeringUser, auth, authProviderUser, isEnrolledInMfa } = useAuth();
  const { currentDao } = useDao();
  const ldClient = useLDClient();
  const [daoMemberships, setDaoMemberships] = useState<DaoMembershipDTO[]>([]);
  const location = useLocation();
  const [showTosModal, setShowTosModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const options: LottieOptions = {
    animationData: AnimatedLogo,
    loop: true,
    autoPlay: true,
    height: '12.5rem',
    width: '12.5rem'
  };
  const { View, setSpeed, animationLoaded } = useLottie(options, {
    height: '12.5rem',
    width: '12.5rem'
  });
  setSpeed(2.25);

  const [userInfo, setUserInfo] = useState<UserEntityDTO>();
  const {
    getCurrentUser,
    getUserDaoMembershipByDaoId,
    getUserJoinRequestsByDao,
    getUserDaoMemberships
  } = useUserService();
  const { notify } = useNotification();
  const { logOut } = useAuth();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  const [inviteProps, setInviteProps] = useState<InviteProperties | undefined>();

  const fetchUserDaoMemberships = useCallback(async () => {
    const memberships = await getUserDaoMemberships({
      includeFinancials: true
    });
    setDaoMemberships([...memberships.data]);
  }, [currentDao.id, getUserDaoMembershipByDaoId, getUserJoinRequestsByDao, ldClient]);

  const handleRedirect = () => {
    const redirectUrl = localStorage.getItem('redirectUrl');
    if (redirectUrl) {
      // when redirecting back into the application if we have redirect in local storage
      // but not in context we can get the data we need from the url
      if (!inviteProps) {
        const inviteIds = parseUrlForInviteCredentials(redirectUrl);
        setInviteProps(inviteIds);
      }
      navigate(redirectUrl);
      localStorage.removeItem('redirectUrl');
    }
  };

  /**
   * @description This function performs multiple actions
   *  1. Fetches the data for the current user
   *  2. Fetches the Dao membership for the current dao for the user
   *  3. Routes to crypto asset verification if necessary
   *
   * @param showLoadingState {boolean} defaults to true and will show the global loading state
   * @param initiateCascadeLoading {boolean} defaults to true and will begin loading cascading information such as memberships and seasons
   */
  const fetchAndSetUser = useCallback(
    async (showLoadingState = true, initiateCascadeLoading = true) => {
      try {
        setLoading(showLoadingState);
        const { data: userResponse } = await getCurrentUser();

        if (!userResponse.id) throw new Error('User not found');

        const userMustAcceptTos = userResponse.termsOfServiceAcceptances?.length === 0;
        if (userMustAcceptTos) {
          setShowTosModal(true);
          return;
        } else {
          setShowTosModal(false);
        }

        if (initiateCascadeLoading) {
          await fetchUserDaoMemberships();
        }

        await ldClient?.waitUntilReady();
        await ldClient?.waitForInitialization();
        await ldClient?.identify({
          key: userResponse.id,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          email: userResponse.email,
          avatar: userResponse.profileThumbnail,
          kind: 'user'
        });
        setUserInfo({ ...userResponse });

        return userResponse;
      } catch (error) {
        logOut(false);
        navigateRef.current(ROOT_ROUTE.AUTH);
        console.error(error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 750);
      }
    },
    [getCurrentUser, notify]
  );

  /**
   * @description assumes the url is in the following format:
   *  coops/eb454233-deb1-4fa0-ab0e-0bce05984e84/home/manageable-entities/d9437b38-7f34-4685-bcfc-06f3ae388efe/invites/1b1cd324-66f3-477a-9999-b6b4fbfdc580
   * then parses the url by '/' and returns the ids
   *
   * @param url {string} EX: coops/eb454233-deb1-4fa0-ab0e-0bce05984e84/home/manageable-entities/d9437b38-7f34-4685-bcfc-06f3ae388efe/invites/1b1cd324-66f3-477a-9999-b6b4fbfdc580
   * @returns {InviteProperties} returns if the ids are found in the url
   * @returns {undefined} returns if the ids are not found in the url
   */
  const parseUrlForInviteCredentials = (url: string): InviteProperties | undefined => {
    let parts = url.split('/');

    let manageableEntityId = parts[4];
    let inviteId = parts[6];
    let tempInvitationId = parts[8];

    if (!manageableEntityId || !inviteId) return;

    const res: {
      manageableEntityId: string;
      inviteId: string;
      temporaryInvitationId?: string;
    } = {
      manageableEntityId: manageableEntityId,
      inviteId: inviteId
    };

    if (tempInvitationId && tempInvitationId !== '-') {
      res.temporaryInvitationId = tempInvitationId;
    }

    return res;
  };

  const deleteInviteProps = () => {
    setInviteProps(undefined);
  };

  const fetchUserData = useCallback(async () => {
    if (registeringUser) return;
    // if the url is an invite then attempt to parse it for the ids
    if (window.location.pathname.includes('invite')) {
      if (!inviteProps) {
        const inviteIds = parseUrlForInviteCredentials(window.location.pathname);
        setInviteProps(inviteIds);
        localStorage.setItem('redirectUrl', `${location.pathname}${location.search}`);
      }
    }

    if (authProviderUser) {
      const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';
      if (!isLocal && (!authProviderUser?.emailVerified || !isEnrolledInMfa)) {
        setLoading(false);
        const isVerifyingEmail = window.location.pathname.includes(ROUTES.USER_ACCOUNT_MANAGEMENT);
        if (!isVerifyingEmail) navigate(ROUTES.VERIFICATION);
        return;
      }

      if (token) {
        const data = await fetchAndSetUser();
        if (data?.id) {
          handleRedirect();
        }
      } else {
        setLoading(false);
        navigate(ROOT_ROUTE.AUTH);
        return;
      }
    } else {
      setLoading(false);
      if (!token) {
        if (!window.location.pathname.includes('/auth')) navigate(ROOT_ROUTE.AUTH);
      }
    }
  }, [
    registeringUser,
    inviteProps,
    token,
    loading,
    fetchAndSetUser,
    window.location.pathname,
    auth,
    authProviderUser,
    isEnrolledInMfa
  ]);

  useEffect(() => {
    fetchUserData();

    return () => {
      setUserInfo(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, fetchAndSetUser, registeringUser, authProviderUser, isEnrolledInMfa]);

  const providerValue = useMemo(
    () => ({
      userInfo,
      daoMemberships,
      fetchAndSetUser,
      deleteInviteProps,
      fetchUserDaoMemberships,
      inviteProperties: inviteProps
    }),
    [
      userInfo,
      daoMemberships,
      fetchAndSetUser,
      deleteInviteProps,
      inviteProps,
      fetchUserDaoMemberships
    ]
  );

  if (loading) {
    return (
      <Stack style={{ justifyContent: 'center', height: '100vh', alignItems: 'center' }}>
        {animationLoaded ? (
          View
        ) : (
          <Stack spacing={2} justifyContent={'center'}>
            <WordMarkLogo sx={{ height: '3rem', width: '7rem', margin: '0 auto' }} />
            <CircularProgress size={'small'} sx={{ color: theme.palette.common.black }} />
          </Stack>
        )}
      </Stack>
    );
  }

  if (showTosModal && !registeringUser) {
    return <TosModal onConfirm={fetchAndSetUser} open={true} />;
  }

  return <UserContext.Provider value={providerValue}>{children}</UserContext.Provider>;
};

export default withLDConsumer()(UserProvider);
export { UserContext };
