import { Card, cardClasses, CardProps, styled } from '@mui/material';

const CommentCard = styled(Card)<CardProps>(({ theme }) => ({
  borderLeft: 'none',
  borderRight: 'none',
  marginTop: '-0.0625rem',
  [`&.${cardClasses.root}`]: {
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  }
}));

export default CommentCard;
