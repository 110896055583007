enum CampaignStatus {
  PreRelease = 'PRE_RELEASE',
  Successful = 'SUCCESSFUL',
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

const formatCampaignStatus = (status: string): string => {
  switch (status) {
    case Object.keys(CampaignStatus)[0]:
      return 'Pre release';
    default:
      return status;
  }
};

export default CampaignStatus;
export { formatCampaignStatus };
