import { Stack, styled } from '@mui/material';

const GettingStartedWrapper = styled(Stack)(({ theme }) => ({
  maxHeight: '40rem',
  height: 'auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '.125rem',
    opacity: 0.4
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey['500']
  }
}));

export { GettingStartedWrapper };
