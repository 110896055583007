import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import BaseTitledSectionWrapperProps from './BaseTitledSectionWrapper.props';

const BaseTitledSectionWrapper = ({
  title,
  children
}: BaseTitledSectionWrapperProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      borderTop={`1px solid ${theme.palette.divider}`}
      padding={{ xs: '1.25rem 0', sm: '1.75rem 0', md: '2rem 0' }}
    >
      <Typography
        variant="subtitle2"
        fontWeight={'bold'}
        width={{ xs: 'unset', sm: 'unset', md: '25%' }}
      >
        {title}
      </Typography>
      <Stack
        direction={'row'}
        width={{ xs: '100%', sm: '100%', md: '75%' }}
        paddingTop={{ xs: '1.5rem', sm: '1.5rem', md: 'unset' }}
        position={'relative'}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default BaseTitledSectionWrapper;
