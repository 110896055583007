import { UserRoomRole } from 'types/enum';

// This list is not ideal for long term and should look for a better solution
export const roomRoleTiers = {
  [UserRoomRole.MEMBER]: [UserRoomRole.MEMBER.toString()]
};

/**
 *
 * @description Verifies the roleProvided is at least the role required
 * @param roleRequired {@link RoomRole} the minimum role to check required
 * @param roleProvided {@link RoomRole} the role to compare to the required
 * @returns {boolean}
 */
const compareRoomRoles = (roleRequired: UserRoomRole, roleProvided: UserRoomRole): boolean => {
  const requiredRoles = roomRoleTiers[roleRequired];
  const providedMeetsRequired = requiredRoles.includes(roleProvided.toString());
  return providedMeetsRequired;
};

export default compareRoomRoles;
