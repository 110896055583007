import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ChevronLeft from 'assets/icons/arrows/ChevronLeft';
import SeasonZeroGlobalIndicator from 'components/profile/tab-profile/dao-actions/season-zero-global-indicator/SeasonZeroGlobalIndicator';
import { HELP_LABELS } from 'constants/help.labels';
import { useDao } from 'hooks';
import InnerPageContentBoxProps from './InnerPageContentBox.props';
import { BoxStyled, GridStyled, StyledLink } from './InnerPageContentBox.style';

const InnerPageContentBox = ({
  children,
  title,
  titleActions,
  titleContainerProps,
  titleTypographyProps,
  backActions,
  header,
  sx
}: InnerPageContentBoxProps): React.ReactElement => {
  const theme = useTheme();
  const { BETA } = HELP_LABELS;
  const { currentActiveSeasonForDao } = useDao();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <BoxStyled data-testid="content-box" sx={sx}>
        <Stack direction="column" style={{ width: '100%' }}>
          <GridStyled
            container
            justifyContent="center"
            style={{
              borderTop: header ? `0.063rem solid ${theme.palette.grey[300]}` : '',
              width: '100%'
            }}
          >
            <Grid item md={12} xs={12} alignContent="flex-start">
              {header}
              {title && (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={'0.25rem'}
                  marginTop={header || isMobile ? 'inherit' : '1.5rem'}
                  {...titleContainerProps}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    gap=".5rem"
                    onClick={
                      backActions?.showBackButton ? backActions?.toggleBackButton : undefined
                    }
                    style={{ cursor: backActions?.showBackButton ? 'pointer' : 'unset' }}
                  >
                    {backActions?.showBackButton && (
                      <ChevronLeft {...backActions.backButtonProps} />
                    )}
                    <Typography
                      variant={titleTypographyProps?.variant || 'h6'}
                      {...titleTypographyProps}
                      data-testid="content-box-title"
                    >
                      {title}
                    </Typography>
                  </Stack>
                  {titleActions && titleActions}
                </Stack>
              )}
              {children}
            </Grid>
          </GridStyled>
        </Stack>
      </BoxStyled>
      {currentActiveSeasonForDao?.seasonNumber === 0 && <SeasonZeroGlobalIndicator />}
      <StyledLink
        href={'/help'}
        position={'fixed'}
        right={'1rem'}
        bottom={'0rem'}
        color={theme.palette.secondary.main}
      >
        {BETA}
      </StyledLink>
    </>
  );
};

export default InnerPageContentBox;
