import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AwsmTextIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon width="55" height="14" viewBox="0 0 55 14" fill="none" {...props}>
      <path
        d="M9.37455 10.1008H5.1308L4.24058 12.8476H0.792908L5.11173 0.162354H9.29827L13.7684 12.8476H10.2826L9.37335 10.1008H9.37455ZM8.78702 7.84958L7.78359 4.77877C7.55597 4.01557 7.41653 3.22116 7.36648 2.39436H7.04471C7.01969 3.25956 6.89337 4.05397 6.66575 4.77877L5.73739 7.84958H8.78702Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M28.1652 3.31006L25.3992 12.8477H21.9134L20.9278 8.53728C20.8265 8.08008 20.7574 7.61207 20.7193 7.13567C20.6811 6.65927 20.6501 6.02687 20.6251 5.23727H20.3034C20.2783 6.01367 20.2461 6.63287 20.2092 7.09727C20.1711 7.56167 20.096 8.04168 19.9816 8.53728L18.9781 12.8477H15.4733L12.7073 3.31006H16.0787L16.8938 7.18247C17.007 7.76807 17.0642 8.32728 17.0642 8.86128C17.0642 9.39528 17.0142 9.98088 16.9129 10.5785L17.2537 10.6553C17.5695 9.23088 17.8472 8.06088 18.0867 7.14527L18.977 3.31126H21.9134L22.8036 7.16447C23.0944 8.47488 23.3399 9.63768 23.5425 10.6553L23.8833 10.5785C23.8201 10.0697 23.7892 9.58008 23.7892 9.10968C23.7892 8.68968 23.8392 8.17488 23.9405 7.56407L24.8307 3.31006H28.1652Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M32.2007 10.6816C32.4783 10.9036 32.8323 11.0152 33.2613 11.0152C33.6141 11.0152 33.9084 10.942 34.142 10.7956C34.3756 10.6492 34.4924 10.4368 34.4924 10.1572C34.4924 9.90281 34.3851 9.70241 34.1706 9.55601C33.9561 9.40961 33.6462 9.28601 33.2422 9.18401L31.4427 8.72681C29.6241 8.24321 28.7149 7.3156 28.7149 5.9416C28.7149 5.3572 28.9067 4.8484 29.2928 4.41519C29.6778 3.98319 30.2021 3.65199 30.8647 3.42279C31.5273 3.19359 32.2698 3.07959 33.0909 3.07959C34.341 3.07959 35.3826 3.36519 36.2168 3.93759C37.0498 4.50999 37.536 5.3104 37.6755 6.3412H34.5114C34.4614 5.9344 34.2969 5.6164 34.0192 5.3872C33.7416 5.158 33.4198 5.044 33.0527 5.044C32.6857 5.044 32.3997 5.1172 32.1911 5.2636C31.9826 5.41 31.8789 5.6104 31.8789 5.8648C31.8789 6.0808 31.973 6.2596 32.1625 6.3988C32.352 6.5392 32.6547 6.6592 33.0718 6.7612L34.8904 7.2004C35.7866 7.4296 36.4849 7.76321 36.9831 8.20241C37.4824 8.64161 37.7315 9.23561 37.7315 9.98561C37.7315 10.9396 37.3275 11.6932 36.5195 12.2464C35.7115 12.7996 34.6246 13.0756 33.2613 13.0756C31.898 13.0756 30.8492 12.796 29.965 12.2368C29.0807 11.6776 28.5504 10.8376 28.374 9.71921H31.6894C31.7526 10.1392 31.923 10.4596 32.2007 10.6828V10.6816Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M53.559 3.92085C54.1334 4.48006 54.4206 5.30086 54.4206 6.38086V12.8477H51.1434V6.99167C51.1434 6.52126 51.0206 6.16846 50.7739 5.93326C50.5273 5.69806 50.2269 5.58046 49.8742 5.58046H49.7979C49.4058 5.58046 49.0841 5.71126 48.8314 5.97166C48.5788 6.23206 48.4525 6.61006 48.4525 7.10687V12.8489H45.1752V7.03127C45.1752 6.53566 45.0525 6.16966 44.8058 5.93446C44.5591 5.69926 44.2468 5.58166 43.8679 5.58166H43.8107C43.4317 5.58166 43.1159 5.71246 42.8633 5.97286C42.6106 6.23326 42.4843 6.62446 42.4843 7.14647V12.8501H39.207V3.31245H42.2567V3.37005C42.2567 3.64965 42.2126 3.94605 42.1244 4.25686C42.0362 4.56886 41.9039 4.94686 41.7263 5.39206C41.6882 5.50606 41.6441 5.63326 41.5941 5.77366C41.544 5.91406 41.4928 6.06646 41.4427 6.23206L41.8026 6.34606L41.9349 5.88766C42.2757 4.83286 42.7119 4.10086 43.2422 3.69405C43.7725 3.28725 44.3982 3.08325 45.118 3.08325C45.8378 3.08325 46.4063 3.25245 46.8603 3.58845C47.3144 3.92565 47.542 4.40566 47.542 5.02846C47.542 5.37166 47.4597 5.80366 47.2953 6.32566L47.6361 6.40246C48.2165 4.18966 49.4666 3.08325 51.3865 3.08325C52.2576 3.08325 52.981 3.36285 53.5554 3.92205L53.559 3.92085Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
};

export default AwsmTextIcon;
