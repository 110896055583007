import {
  Button,
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ReportCategory, Severity } from 'types/enum';
import ReportFormProps from './ReportEntityModal.props';
import BaseModal from 'components/modals';
import { REPORT_POST } from 'constants/post.labels';
import { BUTTON_LABELS } from 'constants/button.labels';
import { ReportPostParam } from 'model';
import { RadioStyled, ListItemButtonStyled, ListStyled } from './ReportEntityModal.style';
import { ErrorForm } from 'components/labels';
import { LoadingScreen } from 'components';
import { useReportFeedService } from 'hooks/services';
import { HTTP_STATUS } from 'constants/http-statuses';
import { useNotification } from 'hooks';
import { UI_LABELS } from 'constants/ui-labels';

const ReportEntityModal = ({
  entity: { id, daoId, roomId },
  entityType,
  onClose,
  onReportSubmitted
}: ReportFormProps): React.ReactElement => {
  const { reportFeed } = useReportFeedService();
  const { notify } = useNotification();
  const [modalOpen, setModalOpen] = useState(true);
  const { TITLE, HEADS_UP, SELECT_REASON, SEND_REPORT, CHOOSE_ONE_REASON } = REPORT_POST;
  const { CANCEL } = BUTTON_LABELS;

  const defaultValues: ReportPostParam = {
    reason: ''
  };
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    setValue,
    reset
  } = useForm<ReportPostParam>({
    defaultValues,
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });

  const handleReport = async (reportCategory: ReportCategory): Promise<void> => {
    try {
      if (id && daoId && roomId) {
        const response = await reportFeed(daoId, roomId, id, entityType, reportCategory);
        if (response.status === HTTP_STATUS.OK) {
          notify(UI_LABELS.THANKS_FOR_REPORTING, { severity: Severity.Success });
        } else {
          notify(UI_LABELS.ERROR, { severity: Severity.Error });
        }
      }
    } catch (error) {}
  };

  const onSubmit: SubmitHandler<ReportPostParam> = async (payload: ReportPostParam) => {
    handleReport(payload.reason as ReportCategory);
    onReportSubmitted();
  };

  const handleClose = (): void => {
    setModalOpen(false);
    onClose && onClose();
  };

  const selectValue = (item: string) => setValue('reason', item);

  useEffect(() => {
    // cleaning fields up after submit event
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <BaseModal title={TITLE} toggle={modalOpen} close={handleClose} width={500}>
      {!isSubmitting && (
        <Stack>
          <Typography variant="body1">{HEADS_UP}</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" spacing={8} marginTop="1rem">
              <FormControl fullWidth size="small">
                <Typography variant="body2" color="text.secondary" marginBottom="1rem">
                  {SELECT_REASON}
                </Typography>
                <Controller
                  name="reason"
                  control={control}
                  rules={{ required: CHOOSE_ONE_REASON }}
                  render={({ field }) => (
                    <RadioGroup id={field.name} {...field} aria-labelledby="report-reason-menu">
                      <ListStyled>
                        {(Object.keys(ReportCategory) as Array<keyof typeof ReportCategory>).map(
                          (item, index: number) => {
                            const id = `report-category-${index}`;

                            return (
                              <ListItem key={item} disablePadding>
                                <ListItemButtonStyled
                                  data-testid={id}
                                  onClick={() => selectValue(item)}
                                  dense
                                >
                                  <ListItemIcon>
                                    <RadioStyled
                                      value={item}
                                      data-testid={id}
                                      inputProps={{
                                        'aria-labelledby': id
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={id}
                                    primary={
                                      <Typography variant="body2">
                                        {ReportCategory[item]}
                                      </Typography>
                                    }
                                  />
                                </ListItemButtonStyled>
                              </ListItem>
                            );
                          }
                        )}
                      </ListStyled>
                    </RadioGroup>
                  )}
                />

                {errors.reason && <ErrorForm message={errors.reason.message} />}
              </FormControl>
              <Stack direction="row" spacing={2}>
                <Button
                  data-testid="send-report-button"
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!!errors.reason}
                >
                  {SEND_REPORT}
                </Button>
                <Button variant="outlined" color="primary" size="large" onClick={handleClose}>
                  {CANCEL}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      )}

      {isSubmitting && (
        <Stack direction="row" display="flex" justifyContent="center" padding="5rem">
          <LoadingScreen width="6.25rem" height="6.25rem" speed={1} />
        </Stack>
      )}
    </BaseModal>
  );
};

export default ReportEntityModal;
