/**
 * Hook to get session storage and return session value if session exists or undefined if session can't be found
 *
 * @param {string} sessionName Name of session (e.g. "globalSeasonZeroIndicator")
 * @returns {number|undefined} Session Storage returns session value or undefined
 */
const getSessionStorage = (sessionName: string): number | undefined => {
  // Get session storage values
  const initialSession = window?.sessionStorage?.getItem(sessionName);

  // If session exists, return json
  if (initialSession != null) return JSON?.parse(initialSession);

  // If no session exists, return undefined
  return undefined;
};

/**
 * Hook to set session storage and return session key/value pair
 *
 * @param {string} sessionName Name of session (e.g. "globalSeasonZeroIndicator")
 * @returns {null} Session Storage sets session and returns nothing
 */
const setSessionStorage = (sessionName: string): void => {
  // Set session storage values
  const initialSession = window?.sessionStorage?.getItem(sessionName);

  // If session exists, return json
  if (initialSession === null) {
    window?.sessionStorage?.setItem(sessionName, JSON?.stringify(true));
  }
};

/**
 * Hook to remove session storage key/value pair
 *
 * @param {string} sessionName Name of session (e.g. "globalSeasonZeroIndicator")
 * @returns {null} Session Storage removes session storage key/value pair and returns nothing
 */
const removeSessionStorage = (sessionName: string): void => {
  // Check if session storage values
  const initialSession = window?.sessionStorage?.getItem(sessionName);

  // If session exists, return json
  if (initialSession) {
    window?.sessionStorage?.removeItem(sessionName);
  }
};

export { getSessionStorage, setSessionStorage, removeSessionStorage };
