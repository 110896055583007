import { PAGINATION } from 'constants/app-config';
import { useDao } from 'hooks';
import { useDaoService } from 'hooks/services';
import {
  AdminMembershipContextType,
  initAdminMembershipContextType
} from 'model/contexts/admin-membership-context-type.model';
import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { DaoMemberCheckbox } from '../components/tables/dao-admin/DaoAdminMemberRow.props';
import { UserDaoRole } from '../types/enum';

const initialState: AdminMembershipContextType = initAdminMembershipContextType();

const AdminMembershipContext = createContext(initialState);

const AdminMembershipContextProvider = ({
  children
}: {
  children: ReactNode;
}): React.ReactElement => {
  const { getDaoMembershipsWithInvites } = useDaoService();
  const { currentDao } = useDao();
  const { activeDaoMembership } = useDao();
  const navigate = useNavigate();

  const [members, setMembers] = useState<DaoMemberCheckbox[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [isInMultiSelectState, setIsInMultiSelectState] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [tagManagerEnabled, setTagManagerEnabled] = useState<boolean>(false);

  const fetchMembers = useCallback(async () => {
    if (!currentDao) return;

    const results = await getDaoMembershipsWithInvites(currentDao.id, {
      page,
      size: PAGINATION.LARGE_PAGE_SIZE,
      roles: [UserDaoRole.ADMIN, UserDaoRole.OWNER, UserDaoRole.MEMBER]
    });

    const data = [...results.data];
    setMembers([
      ...(members as DaoMemberCheckbox[]),
      ...data.map((i) => ({ ...i, isSelected: false }))
    ]);

    if (data.length < PAGINATION.LARGE_PAGE_SIZE) setIsLastPage(true);
  }, [currentDao, getDaoMembershipsWithInvites, page]);

  const refreshList = useCallback(() => {
    if (page === 0) {
      fetchMembers();
    } else {
      setPage(0);
    }
  }, [page, fetchMembers]);

  useEffect(() => {
    if (!activeDaoMembership) return;

    fetchMembers();
  }, [activeDaoMembership, fetchMembers, navigate, page]);

  const handleMemberDelete = useCallback(
    (memberId: string) => {
      setMembers((prev) => prev.filter((mem) => mem.id !== memberId));
    },
    [members]
  );

  const increasePage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const handleDeselectAll = useCallback(() => {
    const deselectedMembers = members.map((mem) => ({
      ...mem,
      isSelected: false
    })) as DaoMemberCheckbox[];
    setIsInMultiSelectState(false);
    setMembers([...deselectedMembers]);
  }, [members]);

  const handleToggleMember = useCallback(
    (isSelected: boolean, member: DaoMemberCheckbox) => {
      const memCopy = [...members] as DaoMemberCheckbox[];
      const itemIndex = memCopy.findIndex((i) => i.id === member.id);
      const updatedItem = { ...memCopy[itemIndex], isSelected };
      memCopy.splice(itemIndex, 1, updatedItem);
      const selectedMembers = memCopy.filter((i) => i.isSelected);
      setIsInMultiSelectState(selectedMembers.length > 0);
      setMembers([...memCopy]);
    },
    [members]
  );

  const handleToggleTagManager = useCallback(
    (e?: any, element?: HTMLElement) => {
      if (tagManagerEnabled) {
        setAnchorEl(null);
        setTagManagerEnabled(false);
        return;
      }

      if (element) {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(element);
        setTagManagerEnabled(true);
      }
    },
    [setAnchorEl, tagManagerEnabled]
  );

  const updateMemberList = useCallback(
    (mems: DaoMemberCheckbox[]) => {
      setMembers([...mems].map((i) => Object.assign({}, i)));
    },
    [setMembers]
  );

  const providerValue = useMemo(
    () => ({
      members,
      isLastPage,
      isInMultiSelectState,
      updateMemberList,
      anchorEl,
      tagManagerEnabled,
      increasePage,
      setAnchorEl,
      deselectAll: handleDeselectAll,
      toggleMember: handleToggleMember,
      toggleTagManager: handleToggleTagManager,
      handleMemberDelete,
      refreshList
    }),
    [
      refreshList,
      handleMemberDelete,
      members,
      isLastPage,
      isInMultiSelectState,
      updateMemberList,
      anchorEl,
      tagManagerEnabled,
      increasePage,
      handleDeselectAll,
      handleToggleMember,
      handleToggleTagManager,
      setAnchorEl
    ]
  );
  return (
    <AdminMembershipContext.Provider value={providerValue}>
      {children}
    </AdminMembershipContext.Provider>
  );
};

export default AdminMembershipContext;
export { AdminMembershipContextProvider };
