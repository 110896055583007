import SeasonDTO from '@piefi-platform/types-lib/dtos/season.dto';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useHttp } from 'hooks';
import SeasonsService from './use-seasons-service.model';
import { seasonsUrl } from './use-seasons-service.url';
import { SeasonStatus } from 'types/enum';

const useSeasonsService = (): SeasonsService => {
  const { get, put } = useHttp();

  const getSeasons = useCallback(
    (daoId: string, status?: SeasonStatus): Promise<AxiosResponse<SeasonDTO[]>> => {
      return get<SeasonDTO[]>(
        `${seasonsUrl(daoId)}?page=0&size=10${status ? `&status=${status}` : ''}`
      );
    },
    [get]
  );

  const endCurrentSeason = useCallback(
    (daoId: string): Promise<AxiosResponse<SeasonDTO>> => {
      return put(`${seasonsUrl(daoId)}`);
    },
    [put]
  );

  return {
    getSeasons,
    endCurrentSeason
  };
};

export default useSeasonsService;
