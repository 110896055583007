import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function SettingsFilledIcon(props: SvgIconProps): React.ReactElement {
  const width = props.width ? `${props.width}px` : '21px';
  const height = props.height ? `${props.height}px` : '22px';

  return (
    <SvgIcon style={{ width, height }} viewBox="0 0 21 22" {...props}>
      <path
        d="M6.12988 4.07L10.8699 10.56L18.8299 10.39C18.8299 10.39 18.8299 10.33 18.8299 10.3C18.7299 9.75 18.5499 9.23 18.3499 8.74L19.5099 8.31C19.8999 8.17 20.0999 7.74 19.9599 7.35C19.8199 6.96 19.3799 6.76 18.9999 6.9L17.7499 7.36C17.4199 6.64 17.0199 5.88 16.3899 5.32C16.0899 5.05 15.7099 4.74 15.2799 4.43L16.0799 3.3C16.3199 2.96 16.2399 2.49 15.8999 2.26C15.5599 2.02 15.0899 2.1 14.8599 2.44L13.9899 3.67C13.8899 3.62 13.7999 3.57 13.7099 3.53C13.1699 3.3 12.5699 3.14 11.8799 3.04C11.6499 3.01 11.4199 2.98 11.1999 2.96C10.6699 2.92 10.1499 2.93 9.62988 2.98L9.25988 1.55C9.15988 1.15 8.73988 0.910003 8.34988 1.01C7.94988 1.11 7.70988 1.52 7.80988 1.92L8.14988 3.23C7.77988 3.33 7.40988 3.45 7.02988 3.6C6.72988 3.72 6.43988 3.87 6.14988 4.05L6.12988 4.07Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M19.8 14.15L18.55 13.63C18.72 13.08 18.84 12.49 18.88 11.89L10.9 12.06L6.45996 18.74C6.99996 19.03 7.54996 19.27 8.10996 19.46L7.77996 20.62C7.66996 21.02 7.89996 21.43 8.29996 21.55C8.36996 21.57 8.43996 21.58 8.49996 21.58C8.82996 21.58 9.12996 21.36 9.21996 21.03L9.56996 19.8C9.66996 19.81 9.75996 19.83 9.85996 19.84C10.03 19.86 10.19 19.86 10.35 19.86C10.8 19.86 11.23 19.8 11.66 19.74L12.06 19.69C12.65 19.62 13.35 19.51 14.03 19.22C14.17 19.16 14.29 19.09 14.41 19.02L15.71 20.64C15.86 20.83 16.08 20.92 16.29 20.92C16.46 20.92 16.62 20.87 16.76 20.75C17.08 20.49 17.14 20.02 16.88 19.7L15.68 18.2C15.85 18.07 16.03 17.93 16.2 17.77C16.49 17.48 16.73 17.15 16.93 16.87C17.21 16.48 17.63 15.83 18.01 15.03L19.23 15.53C19.32 15.57 19.42 15.59 19.52 15.59C19.81 15.59 20.09 15.42 20.21 15.13C20.37 14.75 20.19 14.31 19.8 14.15Z"
        fill={props.fill || '#181818'}
      />
      <path
        d="M9.58 11.34L4.94 4.98001C4.77 5.14001 4.61 5.29001 4.45 5.47001L3.32 4.40001C3.02 4.11001 2.54 4.13001 2.26 4.43001C1.97 4.73001 1.99 5.20001 2.29 5.49001L3.51 6.65001C3.51 6.65001 3.46 6.71001 3.44 6.74001C3.28 6.97001 3.12 7.22001 2.98 7.51001C2.87 7.75001 2.78 8.00001 2.7 8.25001L2.58 8.62001C2.36 9.26001 2.13 9.92001 2.08 10.67H0.75C0.34 10.67 0 11.01 0 11.42C0 11.83 0.34 12.17 0.75 12.17H2.12C2.16 12.53 2.21 12.89 2.26 13.23C2.35 13.83 2.45 14.45 2.71 15.06C2.87 15.42 3.1 15.79 3.39 16.16L2.27 17.29C1.98 17.58 1.98 18.06 2.27 18.35C2.42 18.5 2.61 18.57 2.8 18.57C2.99 18.57 3.18 18.5 3.33 18.35L4.41 17.27C4.49 17.35 4.56 17.44 4.65 17.51C4.82 17.66 5.01 17.8 5.2 17.94L9.58 11.35V11.34Z"
        fill={props.fill || '#181818'}
      />
    </SvgIcon>
  );
}
